//Libs
import Immutable from "immutable";
//Utils
import { GlobalUtils } from "utils";
import KEYWORDS from "./keywords";
import CoreUtils from "./utils";
const { ACTIONS } = KEYWORDS;

//Initial state
const initialState = {
  datasource: {
    isOpen: true,
    status: undefined,
    paging: {
      totalLastRowId: 0,
      totalRowCount: 0,
    },
    fields: [],
    data: [],
    search: {
      isOpen: false,
      value: undefined,
    },
    reload: false,
  },
  display: {
    isFetching: false,
    screens: [],
    selectedScreenId: undefined,
    selectedElementId: undefined,
  },
  projects: {
    isFetching: false,
    selectedProjectId: undefined,
    data: [],
  },
  orderTargetFilters: {
    isFetching: false,
    selectedOrderTargetFilterId: undefined,
    data: [],
  },
  services: {
    isFetching: false,
    selectedServiceIds: [],
    data: [],
  },
  dataFilters: {
    isFetching: false,
    lastRowId: 0,
    selectedDataFilterId: undefined,
    data: [],
    userParams: {},
    reloadSubDataFilters: false,
  },
  actionForm: {
    isOpen: false,
    formId: undefined,
  },
};

const feedReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.MUTATE_DIRECT_PROPS: {
      const keyValuePairs = action.payload;
      return {
        ...state,
        ...keyValuePairs,
      };
    }
    case ACTIONS.TOGGLE_DATASOURCE: {
      return {
        ...state,
        datasource: {
          ...state.datasource,
          isOpen: !state.datasource.isOpen,
        },
      };
    }
    case ACTIONS.MUTATE_1OBJECT: {
      const { obj1Name, keyValuePairs } = action.payload;
      const newObj = { ...state[obj1Name], ...keyValuePairs };
      return {
        ...state,
        [obj1Name]: newObj,
      };
    }
    case ACTIONS.MUTATE_DATA_FILTER_USER_PARAMS: {
      const keyValuePairs = action.payload;
      return {
        ...state,
        dataFilters: {
          ...state.dataFilters,
          userParams: {
            ...state.dataFilters.userParams,
            ...keyValuePairs,
          },
        },
      };
    }
    case ACTIONS.SET_DATASOURCE: {
      const { paging, fields, newData } = action.payload;

      const data = CoreUtils.appendOrResetDatasource(
        state.datasource.data,
        newData
      );
      return {
        ...state,
        datasource: {
          ...state.datasource,
          paging: CoreUtils.setDatasourcePaging({
            currentData: data,
            currentPaging: state.datasource.paging,
            newData,
            paging,
          }),
          fields: CoreUtils.setOrResetDatasourceFields(
            state.datasource.fields,
            fields
          ),
          data,
        },
      };
    }
    case ACTIONS.UPDATE_DATASOURCE_ITEM: {
      const { updateRecords } = action.payload;

      const datasource = Immutable.List(state.datasource.data).toJS();

      for (let record of updateRecords) {
        const datasourceIdx = datasource.findIndex(
          (_record) => _record.id === record.id
        );
        if (datasourceIdx !== -1) {
          for (let fieldId of Object.keys(record.fields)) {
            datasource[datasourceIdx][fieldId] = record.fields[fieldId];
          }
        }
      }
      return {
        ...state,
        datasource: {
          ...state.datasource,
          data: datasource,
        },
      };
    }
    case ACTIONS.SET_SEARCH_DATASOURCE: {
      return {
        ...state,
        datasource: {
          ...state.datasource,
          search: {
            ...state.datasource.search,
            ...action.payload,
          },
        },
      };
    }
    case ACTIONS.MUTATE_SUB_DATA_FILTER: {
      const { selectedDataFilterId, subDataFilterId, filterProps } =
        action.payload;
      const dataFiltersData = Immutable.List(state.dataFilters.data).toJS();
      const dataFilterIdx = dataFiltersData.findIndex(
        (df) => df.id === selectedDataFilterId
      );
      if (dataFilterIdx === -1) return state;
      const dataFilter = dataFiltersData[dataFilterIdx];

      const subDataFilterIdx = GlobalUtils.checkArray(
        dataFilter.filters
      ).findIndex((f) => f.id === subDataFilterId);
      if (subDataFilterIdx === -1) return state;

      dataFilter.filters[subDataFilterIdx] = {
        ...dataFilter.filters[subDataFilterIdx],
        ...filterProps,
      };
      dataFiltersData[dataFilterIdx] = dataFilter;

      return {
        ...state,
        dataFilters: {
          ...state.dataFilters,
          data: dataFiltersData,
        },
      };
    }
    // Resets
    case ACTIONS.RESET_DATASOURCE: {
      return {
        ...state,
        datasource: {
          ...initialState.datasource,
          search: {
            ...state.datasource.search,
          },
        },
      };
    }
    case ACTIONS.RESET_DISPLAY: {
      return {
        ...state,
        display: initialState.display,
      };
    }
    case ACTIONS.RESET_ACTION_FORM: {
      return {
        ...state,
        actionForm: initialState.actionForm,
      };
    }
    case ACTIONS.RESET_DATA_FILTERS: {
      const { payload = {} } = action;
      return {
        ...state,
        dataFilters: {
          ...initialState.dataFilters,
          ...payload,
        },
      };
    }
    case ACTIONS.RESET_DATA_FILTER_USER_PARAMS: {
      return {
        ...state,
        dataFilters: {
          ...state.dataFilters,
          userParams: {
            fromDateParam: state.dataFilters.userParams.fromDateParam,
            toDateParam: state.dataFilters.userParams.toDateParam,
          },
        },
      };
    }
    case ACTIONS.RESET_CUSTOM_FILTER_PROPS_IN_DATA_FILTER: {
      const { selectedDataFilterId } = action.payload;
      const dataFiltersData = Immutable.List(state.dataFilters.data).toJS();
      const dataFilterIdx = dataFiltersData.findIndex(
        (df) => df.id === selectedDataFilterId
      );
      if (dataFilterIdx === -1) return state;
      const dataFilter = dataFiltersData[dataFilterIdx];

      dataFilter.filters = GlobalUtils.checkArray(dataFilter.filters).map(
        (filter) => ({ ...filter, state: undefined })
      );
      dataFiltersData[dataFilterIdx] = dataFilter;

      return {
        ...state,
        dataFilters: {
          ...state.dataFilters,
          data: dataFiltersData,
        },
      };
    }
    case ACTIONS.RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default feedReducer;
