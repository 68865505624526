import { Divider } from "antd";
import styled from "styled-components";

const DividerStyled = styled(Divider)`
  .ant-divider-inner-text {
    color: ${(props) =>
      props.color ? props.color : props.theme.divider.color};
  }
  margin: ${(props) =>
    props.margin ? props.margin : props.theme.divider.margin};
`;

export default DividerStyled;
