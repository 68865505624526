//Libs
import React from "react";
//Components
import {
  Wrapper,
  Span,
  Caption,
  Button,
  Modal,
  CollectorUtils,
  Checkbox,
  Subtitle,
} from "components/components";

const Confirmation = ({ deleteElement, onConfirm, onCancel }) => {
  const [deleteCollectorValues, setDeleteCollectorValues] =
    React.useState(false);
  const { level, element } = deleteElement;
  const newNameLabel = CollectorUtils.getDeleteLabel(level);

  return (
    <>
      <Span>{`Realmente desea eliminar ${newNameLabel}:`}</Span>
      <Caption light>{element.name}</Caption>

      <Checkbox
        margin="16px 0 0 0"
        name="deleteCollectorValues"
        checked={deleteCollectorValues}
        onChange={(e) => setDeleteCollectorValues(e.target.checked)}
      >
        <Subtitle>Eliminar juntamente los datos ⚠</Subtitle>
      </Checkbox>
      <Wrapper
        width="100%"
        justifyContent="space-around"
        padding="10px 10px 0 10px"
      >
        <div style={{ marginRight: "10px" }}>
          <Button mode="secondary" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
        <div>
          <Button
            mode="primary"
            onClick={() => onConfirm(element, deleteCollectorValues)}
          >
            Confirmar
          </Button>
        </div>
      </Wrapper>
    </>
  );
};

const DeleteCollectorElement = ({
  visible,
  deleteElement,
  onCancel,
  onConfirm,
}) => {
  return (
    <Modal
      title="Eliminando elemento"
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <Wrapper
        height="165px"
        flexDirection="column"
        justifyContent="space-around"
        alignItems="flex-start"
      >
        <Confirmation
          deleteElement={deleteElement}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </Wrapper>
    </Modal>
  );
};

export default DeleteCollectorElement;
