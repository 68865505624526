//Libs
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import classNames from "classnames";
import { Wrapper, Subtitle, Caption, Avatar } from "components/components";
//Styles
import "./NotificationCard.css";
//TODO:Corregir los estilos (están hardcodeados: El backgroundImage y backgroundBlendMode) y Cambiar el Card de material al de antd
const styles = {
  card: {
    padding: "10px",
    minHeight: 123,
    borderRadius: 0,
  },
  newNotification: {},
  content: {
    padding: 0,
    minHeight: 100,
  },
};

function NotificationCard({ classes, notification, selectNotification }) {
  const {
    user_notification_id,
    data,
    dispatchable_id,
    dispatchable_type,
    created_by_name,
    created_at,
    seen,
  } = notification;
  const cardStyle = classNames({
    [classes.card]: true,
    [classes.newNotification]: !seen,
  });
  const titleContent = data.message.split("/");
  const title = titleContent[0];
  const subtitle = titleContent[1] || dispatchable_id;
  const createdBy = created_by_name.split(" ")[0];
  const createdByProfilePhoto = "";

  return (
    <CardActionArea
      onClick={() =>
        selectNotification(
          user_notification_id,
          dispatchable_id,
          dispatchable_type,
          seen
        )
      }
    >
      <Card className={cardStyle}>
        <Wrapper padding="0" margin="0 0 10px 0">
          <Avatar
            fit
            margin="0 10px 0 0"
            size="small"
            avatar={{
              src: createdByProfilePhoto,
            }}
          />
          <Subtitle>
            {createdBy} - {title}
          </Subtitle>
        </Wrapper>
        <CardContent className={classes.content}>
          <Wrapper padding="0" flexDirection="column" alignItems="stretch">
            <Caption light>
              {subtitle} - {created_at}
            </Caption>
            <Subtitle>{data.details}</Subtitle>
          </Wrapper>
        </CardContent>
      </Card>
    </CardActionArea>
  );
}

NotificationCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotificationCard);
