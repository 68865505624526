// Libs
import React from "react";
// Utils
import { formatDate } from "utils/libs/dateFormats";
//Components
import { Wrapper, Span, LogoIcon } from "components/components";

const CoordPreview = ({ coords, coordSync, manuallyDisabledGPS, profile }) => {
  return (
    <Wrapper
      padding="10px 16px"
      position="absolute"
      maxWidth="350px"
      bottom="53px"
      right="0"
      flexDirection="column"
      alignItems="flex-end"
      backgroundColor="rgba(0,0,0,.5)"
      borderRadius="40px 0 40px"
    >
      {coordSync && (
        <Wrapper padding="0">
          <Span color="white">Actualizando coordenadas...</Span>
          <LogoIcon spin />
        </Wrapper>
      )}
      {manuallyDisabledGPS && (
        <Span color="#ffa500" textAlign="right">
          Debe activar el GPS para poder tomar las fotos
        </Span>
      )}
      {!manuallyDisabledGPS && coords?.latitude && coords.longitude && (
        <>
          {/* COORDS */}
          <Wrapper padding="0">
            <Span color="white" margin="0 5px 0 0">
              {coords.latitude},
            </Span>
            <Span color="white">{coords.longitude}</Span>
          </Wrapper>

          {/* GEOCODE */}
          {coords.geocode && (
            <Span color="white" textAlign="right">
              {coords.geocode}
            </Span>
          )}
          {/* CREATED_AT */}
          {coords?.createdAt && (
            <Span color="white">
              {formatDate(coords.createdAt, profile, "dddd DD MMM YYYY HH:mm")}{" "}
              ({coords.createdAtSource})
            </Span>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default CoordPreview;
