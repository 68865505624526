//Libs
import { createSelector } from "reselect";
import Immutable from "immutable";

const selectUploadResource = (state) => state.uploadResource;
const selectCollector = (state) => state.collector;

export const selectCompleteOrderId = createSelector(
  [selectCollector],
  ({ reviewManage }) => reviewManage.orderId
);

export const selectResources = createSelector(
  [selectUploadResource],
  (uploadResource) => Immutable.List(uploadResource.resources).toJS()
);
export const selectUploadResourceAutoSync = createSelector(
  [selectUploadResource],
  (uploadResource) => uploadResource.autoSync
);
