import React, { memo } from "react";

import { TimePicker } from "components/components";

const TimeField = memo(({ title, value, onChange }) => (
  <TimePicker
    width="100%"
    allowClear={false}
    placeholder={title}
    value={value}
    onChange={(date) => onChange(date.utc().format())}
  />
));

export default TimeField;
