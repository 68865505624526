//Libs
import React from "react";
//Components
import IconButton from "@material-ui/core/IconButton";
import Icon from "./Close";
import SmallIcon from "./SmallClose";

const CloseIcon = ({ onClick, small, onlyIcon, color, fillOpacity }) =>
  onlyIcon ? (
    small ? (
      <SmallIcon fill={color} fillOpacity={fillOpacity} onClick={onClick} />
    ) : (
      <Icon fill={color} fillOpacity={fillOpacity} onClick={onClick} />
    )
  ) : (
    <IconButton onClick={onClick}>
      {small ? (
        <SmallIcon fill={color} fillOpacity={fillOpacity} />
      ) : (
        <Icon fill={color} fillOpacity={fillOpacity} />
      )}
    </IconButton>
  );

export default CloseIcon;
