//******** CONSTANTS ***********/
import GENERAL from '../../../utils/constants/general';
const { PAYMENTS } = GENERAL

//******** FUNCTIONS ***********/

export function mutate1Object(obj1Name, keyValuePairs) {
  return {
    type: PAYMENTS.MUTATE_1OBJECT,
    payload: { obj1Name, keyValuePairs }
  }
}

export function signPayment(payment_id) {
  return {
    type: PAYMENTS.SIGN_PAYMENT,
    payload: payment_id
  }
}

export function doReset() {
  return {
    type: PAYMENTS.RESET
  }
}