//Libs
import React from "react";
//Components
import MoreEventInfo, { useMoreEventInfo } from "./MoreEventInfo";
import { VirtualizedList } from "components/containers/Utils";
import { Wrapper, Empty } from "components/components";

const HistoryEventTab = ({ loading, history }) => {
  const [eventMoreInfo, onCloseMoreEventInfo, onOpenMoreEventInfo] =
    useMoreEventInfo();

  return !loading && history.length === 0 ? (
    <Wrapper height="300px" justifyContent="center">
      <Empty />
    </Wrapper>
  ) : (
    <>
      <VirtualizedList
        cardId="orderHistory"
        listStyle={{
          height: document.documentElement.clientHeight - 106,
          width: document.documentElement.clientWidth + 15,
        }}
        cardStyle={{
          height: 117,
        }}
        rowHeight={130}
        spinTitle="Consultando historial..."
        isSpinning={loading}
        rows={history}
        customProps={{
          eventMoreInfo,
          onOpenMoreEventInfo,
        }}
      />

      <MoreEventInfo
        eventMoreInfo={eventMoreInfo}
        onCloseMoreEventInfo={onCloseMoreEventInfo}
        history={history}
      />
    </>
  );
};

export default HistoryEventTab;
