//Libs
import React from "react";
//Utils
import KEYWORDS from "./keywords";
//Hooks
import { useNetwork } from "hooks";
//Components
import {
  AddIcon,
  LogoIcon,
  DeleteIcon,
  ErrorIcon,
  WarningIcon,
  CheckIcon,
} from "components/components";

const StatusIcon = ({
  familyType,
  inputValueOk,
  savedConsumedItem,
  currentValue,
  isSerialized,
  onAdd,
  onDelete,
}) => {
  const isOnline = useNetwork();

  if (familyType === KEYWORDS.TYPES.UNINSTALL_SERIALIZED && !inputValueOk)
    return null;

  // LOADING
  if (savedConsumedItem?.status === KEYWORDS.STATUS.LOADING) {
    return <LogoIcon spin={isOnline} />;
  }

  // ADD
  if (!currentValue && isSerialized) {
    return <AddIcon bold onClick={onAdd} />;
  }

  // ERROR || DENY
  if (
    savedConsumedItem?.status === KEYWORDS.STATUS.ERROR ||
    savedConsumedItem?.status === KEYWORDS.STATUS.DENY
  ) {
    return <ErrorIcon onClick={() => isSerialized && onAdd()} />;
  }

  // REJECT
  if (savedConsumedItem?.props?.[KEYWORDS.STATUS.REJECT]) {
    return <WarningIcon />;
  }

  // SUCCESS
  if (currentValue && savedConsumedItem?.status === KEYWORDS.STATUS.SUCCESS) {
    if (isSerialized) {
      return <DeleteIcon onClick={onDelete} />;
    }
    return <CheckIcon />;
  }

  return null;
};

export default StatusIcon;
