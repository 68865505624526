//******** CONSTANTS ***********/
import GENERAL from "utils/constants/general";
const { DASHBOARD } = GENERAL;

//******** FUNCTIONS ***********/
export function mutateDirectProps(keyValuePairs) {
  return {
    type: DASHBOARD.MUTATE_DIRECT_PROPS,
    payload: keyValuePairs,
  };
}

export function mutate1Object(obj1Name, keyValuePairs) {
  return {
    type: DASHBOARD.MUTATE_1OBJECT,
    payload: { obj1Name, keyValuePairs },
  };
}

export function showNavBar() {
  return {
    type: DASHBOARD.SHOW_NAV_BAR,
  };
}

export function setLink(linkValue, coords) {
  return {
    type: DASHBOARD.SET_LINK,
    payload: { linkValue, coords },
  };
}

export const setOnReceivedNotification = (payload) => ({
  type: DASHBOARD.SET_ON_RECEIVED_NOTIFICATION,
  payload,
});

export function markNotification(payload) {
  return {
    type: DASHBOARD.MARK_NOTIFICATION,
    payload,
  };
}

export function sendToast({ message, description, duration, type, onClick }) {
  return {
    type: DASHBOARD.TOAST_MESSAGE,
    payload: { message, description, duration, type, onClick },
  };
}

//  SEND DATA TO BACKEND
export function reportMe(id, name) {
  return {
    type: DASHBOARD.REPORT_ME,
    payload: { id, name },
  };
}

export function markNotificationSeen(user_notification_id) {
  return {
    type: DASHBOARD.MARK_NOTIFICATION_SEEN,
    payload: user_notification_id,
  };
}

export function getNotifications() {
  return {
    type: DASHBOARD.GET_NOTIFICATIONS,
  };
}

export function doReset() {
  return {
    type: DASHBOARD.RESET,
  };
}

//New unified structure
export const mutate1ObjectInRegions = (obj1Name, keyValuePairs) => ({
  type: DASHBOARD.MUTATE_1OBJECT_IN_REGIONS,
  payload: { obj1Name, keyValuePairs },
});
export const mutate1ObjectInAreas = (obj1Name, keyValuePairs) => ({
  type: DASHBOARD.MUTATE_1OBJECT_IN_AREAS,
  payload: { obj1Name, keyValuePairs },
});
export const mutate1ObjectInCentrals = (obj1Name, keyValuePairs) => ({
  type: DASHBOARD.MUTATE_1OBJECT_IN_CENTRALS,
  payload: { obj1Name, keyValuePairs },
});
export const mutate1ObjectInDealers = (obj1Name, keyValuePairs) => ({
  type: DASHBOARD.MUTATE_1OBJECT_IN_DEALERS,
  payload: { obj1Name, keyValuePairs },
});
export const mutate1ObjectInOrders = (obj1Name, keyValuePairs) => ({
  type: DASHBOARD.MUTATE_1OBJECT_IN_ORDERS,
  payload: { obj1Name, keyValuePairs },
});
export const mutate1ObjectInOrderFilters = (obj1Name, keyValuePairs) => ({
  type: DASHBOARD.MUTATE_1OBJECT_IN_ORDER_FILTERS,
  payload: { obj1Name, keyValuePairs },
});
export const mutate1ObjectInProjects = (obj1Name, keyValuePairs) => ({
  type: DASHBOARD.MUTATE_1OBJECT_IN_PROJECTS,
  payload: { obj1Name, keyValuePairs },
});
export const mutate1ObjectInServices = (obj1Name, keyValuePairs) => ({
  type: DASHBOARD.MUTATE_1OBJECT_IN_SERVICES,
  payload: { obj1Name, keyValuePairs },
});

export const getRegions = ({ projectId }) => ({
  type: DASHBOARD.GET_REGIONS,
  payload: { projectId },
});
export const getAreas = ({ projectId }) => ({
  type: DASHBOARD.GET_AREAS,
  payload: { projectId },
});
export const getCentrals = ({ projectId }) => ({
  type: DASHBOARD.GET_CENTRALS,
  payload: { projectId },
});
export const getDealers = ({ projectId, centralId }) => ({
  type: DASHBOARD.GET_DEALERS,
  payload: { projectId, centralId },
});
export const getOrders = ({ projectId, serviceId }) => ({
  type: DASHBOARD.GET_ORDERS,
  payload: { projectId, serviceId },
});
export const getProjects = () => ({
  type: DASHBOARD.GET_PROJECTS,
});
export const getServices = ({ projectId }) => ({
  type: DASHBOARD.GET_SERVICES,
  payload: { projectId },
});

export const resetFiltersQueryModal = () => ({
  type: DASHBOARD.RESET_FILTERS_QUERY_MODAL,
});
