export default class OrderUtils {
  static getOrderPropsFromOrder(key, order) {
    if (!key || !order) return {};

    const props = order["props"];
    if (!props) return {};

    if (typeof props === "object" && !props[key]) return {};
    if (typeof props[key] !== "object") return {};
    return props[key];
  }
}
