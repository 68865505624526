import styled from "styled-components";
import { Chip } from "@material-ui/core";

const TotalGains = styled(Chip)`
  font-family: ${(props) => props.theme.gains.fontFamily};
  font-style: ${(props) => props.theme.gains.fontStyle};
  font-weight: ${(props) => props.theme.gains.fontWeight};
  font-size: ${(props) => props.theme.gains.fontSize};
  line-height: ${(props) => props.theme.gains.lineHeight};
  text-align: ${(props) => props.theme.gains.textAlign};
  letter-spacing: ${(props) => props.theme.gains.letterSpacing};
  color: ${(props) => props.theme.gains.color};
`;

export default TotalGains;
