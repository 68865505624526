//Libs
import styled, { css } from "styled-components";

const ButtonStyled = styled.button`
  background-color: unset;
  border: unset;
  margin: ${(props) => props.margin ?? props.theme.button.margin};
  height: ${(props) => props.theme.button.height};
  padding: ${(props) => props.theme.button.padding};
  /* display: flex; */
  align-items: center;
  ${(props) =>
    props.mode === "primary" &&
    css`
      font-family: ${(props) => props.theme.button.primary.fontFamily};
      font-style: ${(props) => props.theme.button.primary.fontStyle};
      font-weight: ${(props) => props.theme.button.primary.fontWeight};
      font-size: ${(props) => props.theme.button.primary.fontSize};
      line-height: ${(props) => props.theme.button.primary.lineHeight};
      text-align: ${(props) => props.theme.button.primary.textAlign};
      letter-spacing: ${(props) => props.theme.button.primary.letterSpacing};
      text-transform: ${(props) => props.theme.button.primary.textTransform};
      color: ${(props) => props.theme.button.primary.color};
    `}
  ${(props) =>
    props.mode === "secondary" &&
    css`
      font-family: ${(props) => props.theme.button.primary.fontFamily};
      font-style: ${(props) => props.theme.button.primary.fontStyle};
      font-weight: ${(props) => props.theme.button.secondary.fontWeight};
      font-size: ${(props) => props.theme.button.primary.fontSize};
      line-height: ${(props) => props.theme.button.primary.lineHeight};
      text-align: ${(props) => props.theme.button.primary.textAlign};
      letter-spacing: ${(props) => props.theme.button.primary.letterSpacing};
      text-transform: ${(props) => props.theme.button.primary.textTransform};
      color: ${(props) => props.theme.button.secondary.color};
    `}
      ${(props) =>
    props.radius &&
    css`
      padding: 10px;
      border-radius: 50px;
    `}
      ${(props) =>
    props.disabled &&
    css`
      color: ${(props) => props.theme.colors.text.disabled};
    `}
    ${(props) =>
    props.boxShadow &&
    css`
      -webkit-box-shadow: -10px 0px 13px -7px #000000,
        10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
      box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
        5px 5px 15px 5px rgba(0, 0, 0, 0);
    `}
`;

export default ButtonStyled;

// .ant-btn {
//   background-color: transparent;
//   color: white;
// }

// .ant-btn:hover {
//   background-color: #3f3f49;
// }

// .ant-btn:active {
//   background-color: #3f3f49;
// }

// .ant-btn:focus {
//   background-color: #3f3f49;
// }

// .ant-btn[disabled] {
//   color: gray;
//   background-color: rgb(59, 68, 74);
// }
