//Libs
import React from "react";
import styled, { useTheme } from "styled-components";
//Utils
import { getFormattedValue } from "./utils";
//Components
import { Caption, Span } from "components/components";
import Chip from "@material-ui/core/Chip";

const NOT_CONFIGURED = "No configurado";

const Tag = styled(Chip)`
  &.MuiChip-root-108 {
    height: 24px;
  }
`;
export const TimeRemainingTag = ({ time, humanize }) => {
  const value = getFormattedValue({ time, humanize });
  return <Tag label={<Caption dark>Expira: {value}</Caption>} />;
};

export const TimeRemaining = ({ time, humanize }) => {
  const theme = useTheme();
  const value = getFormattedValue({ time, humanize });
  return (
    <Span
      size="m"
      color={value === NOT_CONFIGURED ? theme.colors.text.disabled : undefined}
    >
      {value}
    </Span>
  );
};
