//Libs
import React from "react";
//Utils
import { GlobalUtils } from "utils";
import { ExcelUtils } from "utils/libs";
//Components
import {
  Wrapper,
  Drawer,
  Span,
  Collapse as _Collapse,
  Empty,
  ExcelIcon,
  SearchIcon,
  WrapperView,
} from "components/components";
import {
  CustomToolbar,
  CustomSearchToolbar,
  BackComponent,
} from "components/containers/Dashboard/subs/Toolbar";
import { VirtualizedList } from "components/containers/Utils";
import GENERAL from "utils/constants/general";

const { ENV } = GENERAL;
const { Collapse, Panel } = _Collapse;

const SerieList = ({
  height,
  seriesManage,
  series,
  mutate1ObjectInApi,
  getSerieHistory,
  onPagination,
}) => {
  const { loading } = seriesManage;
  return (
    <VirtualizedList
      cardId="stockSerieItemCard"
      listStyle={{
        width: document.documentElement.clientWidth,
        height,
      }}
      cardStyle={{
        width: 300,
        height: 72,
      }}
      isSpinning={loading}
      rowHeight={82}
      rows={series}
      customProps={{
        mutate1ObjectInApi,
        getSerieHistory,
      }}
      onRowsRendered={({ overscanStopIndex }) =>
        GlobalUtils.reviewAutoPagination({
          overscanStopIndex,
          data: series,
          onPagination,
        })
      }
    />
  );
};

const Title = ({
  activeKey,
  getPeps,
  title,
  stockSeries,
  excelFilename,
  searchInData,
  mutate1ObjectInWarehouse,
  setOut,
  onClose,
}) =>
  searchInData.renderToolbar ? (
    <CustomSearchToolbar
      value={searchInData.data}
      onChange={(e) =>
        mutate1ObjectInWarehouse("searchInData", {
          ...searchInData,
          [e.target.name]: e.target.value,
        })
      }
      onClose={() =>
        mutate1ObjectInWarehouse("searchInData", {
          ...searchInData,
          renderToolbar: false,
          data: "",
        })
      }
    />
  ) : (
    <CustomToolbar
      title={title}
      left={
        <BackComponent
          handleOnClick={() => {
            setOut(true);
            setTimeout(() => onClose(), 400);
          }}
        />
      }
      right={
        <Wrapper padding="0">
          {(getPeps || activeKey) && stockSeries.length > 0 && (
            <>
              <SearchIcon
                onClick={() =>
                  mutate1ObjectInWarehouse("searchInData", {
                    ...searchInData,
                    renderToolbar: true,
                  })
                }
              />
              <Wrapper padding="0 0 3px 0">
                <ExcelIcon
                  button
                  onClick={() =>
                    ExcelUtils.exportStockSeries(stockSeries, excelFilename)
                  }
                />
              </Wrapper>
            </>
          )}
        </Wrapper>
      }
    />
  );

const SeriesFromItems = ({
  searchInData,
  selectedItemIds,
  states,
  stockSeries,
  seriesManage,
  mutate1ObjectInWarehouse,
  mutate1ObjectInStockSeriesManage,
  getStockSeries,
  mutate1ObjectInApi,
  getSerieHistory,
}) => {
  const [out, setOut] = React.useState(false);
  const [myState, setMyState] = React.useState({
    activeKey: undefined,
  });

  React.useEffect(() => {
    if (out) setTimeout(() => setOut(false), 800);
  }, [out]);

  //Filtered serie states
  const filteredStates = React.useMemo(() => {
    const stateId = parseInt(myState.activeKey);
    return isNaN(stateId) ? states : states.filter((s) => s.id === stateId);
  }, [myState.activeKey, states]);

  //Get
  const [getItems, getPeps] = React.useMemo(
    () => [
      seriesManage.get === ENV.INVENTORY.SERIALIZED.GET.ITEMS,
      seriesManage.get === ENV.INVENTORY.SERIALIZED.GET.PEPS,
    ],
    [seriesManage.get]
  );

  return (
    <Drawer
      key={"seriesFromItems"}
      className={out ? "animated slideOutDown faster" : ""}
      placement={"bottom"}
      width={`${document.documentElement.clientWidth}px`}
      height="100%"
      closable={false}
      visible={seriesManage.isOpen}
      title={
        <Title
          activeKey={myState.activeKey}
          getPeps={getPeps}
          title={getItems ? "Activos" : getPeps ? "PEPS" : ""}
          stockSeries={stockSeries}
          searchInData={searchInData}
          mutate1ObjectInWarehouse={mutate1ObjectInWarehouse}
          setOut={setOut}
          onClose={() => {
            setMyState({ activeKey: undefined });
            mutate1ObjectInStockSeriesManage("series", {
              isOpen: false,
              data: [],
            });
          }}
          excelFilename={
            getItems
              ? filteredStates.find((s) => s.id === Number(myState.activeKey))
                  ?.name ?? "Stock serializado"
              : getPeps
              ? "PEPS"
              : "Stock serializado"
          }
        />
      }
    >
      <WrapperView withToolbarHeight>
        {getPeps && !seriesManage.loading && stockSeries.length === 0 && (
          <Empty margin="20px 0 0 0" message="No hay series para mostrar" />
        )}
        {getItems &&
          myState.activeKey &&
          !seriesManage.loading &&
          stockSeries.length === 0 && (
            <Empty margin="20px 0 0 0" message="No hay series para mostrar" />
          )}

        {/* PEPS */}
        {getPeps && (
          <SerieList
            height={document.documentElement.clientHeight - 55}
            seriesManage={seriesManage}
            series={stockSeries}
            mutate1ObjectInApi={mutate1ObjectInApi}
            getSerieHistory={getSerieHistory}
          />
        )}
        {/* ITEMS */}
        {getItems && (
          <Collapse
            accordion
            bordered={false}
            defaultActiveKey={["-1"]}
            activeKey={myState.activeKey}
            onChange={(newActiveKey) => {
              setMyState({ activeKey: newActiveKey });
              const stateId = parseInt(newActiveKey);
              if (!isNaN(stateId)) {
                getStockSeries({
                  get: ENV.INVENTORY.SERIALIZED.GET.ITEMS,
                  itemIds: selectedItemIds,
                  stateId,
                  limit: 20,
                });
              }
            }}
          >
            {filteredStates.map((s) => (
              <Panel
                key={s.id}
                header={
                  <Wrapper padding="0" justifyContent="space-between">
                    <Span>{s.name}</Span>
                    <Span>
                      {GlobalUtils.getCurrentPagination({
                        show: s.id === Number(myState.activeKey),
                        data: stockSeries,
                      })}
                    </Span>
                  </Wrapper>
                }
              >
                <SerieList
                  height={document.documentElement.clientHeight - 107}
                  seriesManage={seriesManage}
                  series={stockSeries}
                  mutate1ObjectInApi={mutate1ObjectInApi}
                  getSerieHistory={getSerieHistory}
                  onPagination={() =>
                    getStockSeries({
                      get: ENV.INVENTORY.SERIALIZED.GET.ITEMS,
                      itemIds: selectedItemIds,
                      stateId: s.id,
                      lastRowId: stockSeries[stockSeries.length - 1].id,
                      limit: 20,
                    })
                  }
                />
              </Panel>
            ))}
          </Collapse>
        )}
      </WrapperView>
    </Drawer>
  );
};

export default SeriesFromItems;
