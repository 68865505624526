import styled from "styled-components";

const Caption = styled.span`
  margin: ${(props) => props.margin};
  font-family: ${(props) => props.theme.caption.fontFamily};
  font-style: ${(props) => props.theme.caption.fontStyle};
  font-weight: ${(props) => props.theme.caption.fontWeight};
  font-size: ${(props) => props.theme.caption.fontSize};
  line-height: ${(props) => props.theme.caption.lineHeight};
  letter-spacing: ${(props) => props.theme.caption.letterSpacing};
  color: ${(props) =>
    props.light ? props.theme.colors.text.medium : props.theme.caption.color};
  mix-blend-mode: ${(props) => props.theme.caption.mixBlendMode};
  display: inline-block;
  text-overflow: ellipsis;
  white-space: ${({ whiteSpace }) => whiteSpace || "nowrap"};
  overflow: hidden;
  max-width: ${(props) => props.maxWidth};
`;

export default Caption;
