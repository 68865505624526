import React, { memo } from "react";

import { Icon, Span, Switch, Wrapper } from "components/components";

const BooleanField = memo(({ title, value, onChange }) => (
  <Wrapper padding="0" width="100%">
    <Span margin="0 10px 0 0">{title}</Span>
    <Switch
      size="small"
      checked={value}
      checkedChildren={<Icon type="check" />}
      unCheckedChildren={<Icon type="close" />}
      onChange={onChange}
    />
  </Wrapper>
));

export default BooleanField;
