import React from "react";
import { Image } from "components/components";
import ImageLogo from "assets/media/img/logo-synapse.svg";

const Imagotype = ({ position, top, left, opacity }) => (
  <Image
    position={position}
    top={top}
    left={left}
    opacity={opacity}
    width="110px"
    height="110px"
    objectFit="cover"
    src={ImageLogo}
  />
);

export default Imagotype;
