//Libs
import React from "react";
import styled, { css } from "styled-components";
//Components
import IconButton from "@material-ui/core/IconButton";
import icon from "./add.svg";
import iconBold from "./add-bold.svg";

const Button = styled(IconButton)`
  ${(props) =>
    props.shadow &&
    css`
      -webkit-box-shadow: -10px 0px 13px -7px #000000,
        10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
      box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
        5px 5px 15px 5px rgba(0, 0, 0, 0);
    `}
`;

const Icon = styled.div`
  background-image: url(${(props) => (props.bold ? iconBold : icon)});
  width: 24px;
  height: 24px;
`;

const AddIcon = ({ button, bold, onClick, shadow }) =>
  button ? (
    <Button shadow={shadow} onClick={onClick}>
      <Icon bold={bold} />
    </Button>
  ) : (
    <Icon bold={bold} onClick={onClick} />
  );

export default AddIcon;
