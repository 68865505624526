export default class CheckUtils {
  //Collector
  static collectorExistsInCollectorValues = (collector, { id } = {}) =>
    collector.id === id;

  static excludeCollectorInCollectorValues = (collector, { id } = {}) =>
    collector.id !== id;

  static invalidIdx = (idx) => idx === -1;

  static showCollectorName = (collector) => {
    return !collector.layoutProps?.hideLabel;
  };

  static showCollectorGroupName = (collector) => collector.groupName !== null;
}
