// Libs
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
// Utils
import { CollectorUtils } from "components/components/Collector";
import { CollectorDynamics } from "components/components/Collector/utils";
import {
  ConsumeIntegrationUtils,
  ConsumeInventory,
  ConsumeInventoryUtils,
} from "../ConsumeInventory";
// Selectors
import {
  selectConsumedItems,
  selectToInvoiceInventory,
  selectToInvoiceSerializedInventory,
  selectToUninstallInventory,
} from "./selectors";

const CollectorEcosystemIntegration = React.memo(
  ({
    docId,
    order,
    collector,
    collectorValues,
    collectorEcosystemAutoSyncRunning,
    onCollectorValuesChange,
  }) => {
    const consumedItems = useSelector(selectConsumedItems);
    const toInvoiceInventory = useSelector(selectToInvoiceInventory);
    const toInvoiceSerializedInventory = useSelector(
      selectToInvoiceSerializedInventory
    );
    const toUninstallInventory = useSelector(selectToUninstallInventory);
    const [consumedItemValues, setConsumedItemValues] = React.useState({});

    const filteredCollectorValues = useMemo(
      () =>
        CollectorUtils.filterCollectorValues(
          collectorValues,
          docId,
          order.order_id
        ),
      [docId, order.order_id, collectorValues]
    );

    const getConsumedItemValues = () =>
      filteredCollectorValues.reduce((acc, cv) => {
        if (
          CollectorDynamics.getReferencedCollector(collector, cv) &&
          !!cv.inventoryItemId
        ) {
          const { key, value } =
            ConsumeIntegrationUtils.getControlledConsumedItemFormat(
              cv.inventoryItemId,
              cv.inventorySerieId,
              cv.inventoryAmount,
              cv.inventoryInitialAmount,
              cv.inventoryFinalAmount
            );
          acc[key] = value;
        }
        return acc;
      }, {});

    React.useEffect(() => {
      setConsumedItemValues(getConsumedItemValues());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order.order_id, docId, filteredCollectorValues, collector]);

    const consumedItemsResume = React.useMemo(() => {
      if (!Object.keys(consumedItemValues).length)
        return {
          articles: [],
          serialized: [],
          uninstallSerialized: [],
        };

      const _consumedItems = Object.keys(consumedItemValues).reduce(
        (acc, consumedItemKey) => {
          const [itemId, serieId] =
            ConsumeIntegrationUtils.getSplittedKey(consumedItemKey);
          const amount = ConsumeIntegrationUtils.getSplittedKey(
            consumedItemValues[consumedItemKey]
          )[1];
          const consumedItem = consumedItems.find((item) =>
            serieId
              ? Number(item.serieId) === Number(serieId)
              : Number(item.itemId) === Number(itemId)
          );
          if (consumedItem && amount)
            acc.push({ ...consumedItem, value: amount });
          return acc;
        },
        []
      );

      return ConsumeInventoryUtils.getConsumedItemsResume(
        order.order_id,
        false,
        _consumedItems,
        toInvoiceInventory
          .concat(toInvoiceSerializedInventory)
          .concat(toUninstallInventory)
      );
    }, [
      consumedItemValues,
      order.order_id,
      consumedItems,
      toInvoiceInventory,
      toInvoiceSerializedInventory,
      toUninstallInventory,
    ]);

    // Handlers
    const onConsumedItemChange = ({
      itemId,
      serieId,
      amount,
      initialAmount,
      finalAmount,
    }) => {
      const { key, value } =
        ConsumeIntegrationUtils.getControlledConsumedItemFormat(
          itemId,
          serieId,
          amount,
          initialAmount,
          finalAmount
        );
      setConsumedItemValues((current) => ({ ...current, [key]: value }));
      onCollectorValuesChange({
        value: "inventory",
        collectorProps: CollectorUtils.getCollectorProps(
          { order_id: order.order_id, docId },
          collector,
          { itemId, serieId, amount, initialAmount, finalAmount }
        ),
      });
    };

    return (
      <ConsumeInventory
        otdId={docId || order.otdId}
        order={order}
        collectorEcosystemIntegration={{
          controlledSyncRunning: collectorEcosystemAutoSyncRunning,
          controlledInventoryFilter: collector.inventoryFilter,
          controlledItemValues: consumedItemValues,
          controlledConsumedItemsResume: consumedItemsResume,
          onControlledConsumedItemChange: onConsumedItemChange,
        }}
      />
    );
  }
);

export default CollectorEcosystemIntegration;
