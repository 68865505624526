//  Libs
import React from "react";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import { Select, Input, Icon, Button } from "antd";
import { GlobalUtils, ProjectUtils, ServiceUtils } from "utils";
import { filterOption } from "utils/libs";
//  Styles
import "../../index.css";

const { Option } = Select,
  { TextArea } = Input;

export default function CompletedCodeModal(props) {
  const {
    order,
    completedCodes,
    invoice,
    sendInvoice,
    makeInvoicePersistInIDB,
  } = props;
  const {
    completed_code_id,
    completedAuthorizationCode,
    comment,
  } = invoice.completedCodeModal;
  const now = moment();
  const filteredCodesByDeparment = completedCodes.filter(
    (code) => code.departmentId === order.department_id
  );

  //Completed Code
  const completedCodeProps = GlobalUtils.selectCurrentProps([
    ProjectUtils.getProjectPropsFromOrder("completedCode", order),
    ServiceUtils.getServicePropsFromOrder("completedCode", order),
  ]);

  //Completed Authorization Code
  const completedAuthorizationCodeProps = GlobalUtils.selectCurrentProps([
    ProjectUtils.getProjectPropsFromOrder("completedAuthorizationCode", order),
    ServiceUtils.getServicePropsFromOrder("completedAuthorizationCode", order),
  ]);

  return (
    <div className="modal-update-event-orders">
      {/* ORDER EVENT */}
      <Select
        showSearch
        placeholder="Código*"
        className="select-custom"
        value={completed_code_id}
        filterOption={filterOption}
        onChange={(value) =>
          makeInvoicePersistInIDB({
            completedCodeModal: {
              ...invoice.completedCodeModal,
              completed_code_id: value,
            },
          })
        }
      >
        {filteredCodesByDeparment.map((code, idx) => (
          <Option key={idx} value={code.id}>
            {code.name}
          </Option>
        ))}
      </Select>

      {/* COMPLETED AUTHORIZATION CODE */}
      {!GlobalUtils.isEmptyObject(completedAuthorizationCodeProps) && (
        <Input
          type="text"
          className="select-custom"
          required={!!completedAuthorizationCodeProps.required}
          name="completedAuthorizationCode"
          value={completedAuthorizationCode}
          placeholder={`${
            completedAuthorizationCodeProps.label ?? "Código de Autorización"
          }${!!completedAuthorizationCodeProps.required ? "*" : ""}`}
          onChange={(e) =>
            makeInvoicePersistInIDB({
              completedCodeModal: {
                ...invoice.completedCodeModal,
                completedAuthorizationCode: e.target.value,
              },
            })
          }
        />
      )}

      {/* COMMENT */}
      <TextArea
        style={{ width: "100%" }}
        className="select-custom"
        placeholder="Comentario..."
        rows={3}
        value={comment}
        onChange={(e) =>
          makeInvoicePersistInIDB({
            completedCodeModal: {
              ...invoice.completedCodeModal,
              comment: e.target.value,
            },
          })
        }
      />

      {/* SEND INVOICE */}
      <div
        style={{ margin: "5px 0", display: "flex", justifyContent: "center" }}
      >
        {invoice.status === "sending" ? (
          <IconButton>
            <Icon type="loading" className="style-toolbar-buttons" />
          </IconButton>
        ) : (
          <Button
            disabled={
              (!!completedCodeProps.required && !completed_code_id) ||
              (!!completedAuthorizationCodeProps.required &&
                !completedAuthorizationCode)
            }
            className="button-style-primary"
            onClick={() => sendInvoice(invoice, now)}
          >
            Enviar
          </Button>
        )}
      </div>
    </div>
  );
}
