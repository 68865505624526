//Libs
import React from "react";
import { useTheme } from "styled-components";
//Utils
import { checkAddZero, calculateDifference } from "./utils";
//Components
import { Span } from "components/components";

const NOT_CONFIGURED = "No configurado";
const UnAvailableTime = ({ theme }) => (
  <Span size="m" color={theme.colors.text.disabled}>
    {NOT_CONFIGURED}
  </Span>
);

const TimeElapsed = ({ time }) => {
  const theme = useTheme();
  const { timeElapsed } = time;

  if (Object.keys(time).length === 0) {
    return <UnAvailableTime theme={theme} />;
  }

  //Time remaining is object?
  const calculateDiff = calculateDifference(timeElapsed);
  const { days, hours, minutes, seconds } = calculateDiff;
  const sumHours = days * 24 + hours;
  return (
    <Span size="m">
      {checkAddZero(sumHours)}:{checkAddZero(minutes)}:{checkAddZero(seconds)}
    </Span>
  );
};

export default TimeElapsed;
