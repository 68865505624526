//Types
import CONSUME_INVENTORY_TYPES from "./types";

export function getCurrentConsumedItems(orderId) {
  return {
    type: CONSUME_INVENTORY_TYPES.GET_CURRENT_CONSUMED_ITEMS,
    payload: { orderId },
  };
}

export const mutateConsumeInventoryControl = (payload) => ({
  type: CONSUME_INVENTORY_TYPES.MUTATE_CONSUME_INVENTORY_CONTROL,
  payload,
});

export const mutateUninstallInventoryControl = (payload) => ({
  type: CONSUME_INVENTORY_TYPES.MUTATE_UNINSTALL_INVENTORY_CONTROL,
  payload,
});

export function saveConsumedItems(consumedItems) {
  return {
    type: CONSUME_INVENTORY_TYPES.SAVE_CONSUMED_INVENTORY,
    payload: { consumedItems },
  };
}

export function setAutoSync(payload) {
  return {
    type: CONSUME_INVENTORY_TYPES.SET_AUTO_SYNC,
    payload,
  };
}

export function autoSyncConsumedItems(consumedItems) {
  return {
    type: CONSUME_INVENTORY_TYPES.START_AUTO_SYNC_CONSUMED_ITEMS,
    payload: { consumedItems },
  };
}

export function generateResume() {
  return {
    type: CONSUME_INVENTORY_TYPES.GENERATE_RESUME,
  };
}

export function resetResumeControl() {
  return {
    type: CONSUME_INVENTORY_TYPES.RESET_RESUME_CONTROL,
  };
}
