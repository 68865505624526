import AbsAuthorization from "utils/ManagePermissions/manager";

export default class CollectorEcosystemAuthorization extends AbsAuthorization {
  // eslint-disable-next-line no-useless-constructor
  constructor(profile) {
    super(profile);
  }

  checkEditCollectors() {
    return !!this.roles[this.checkL2Expert()];
  }
}
