//  Constants
import GENERAL from "../../../utils/constants/general";
const { LOGIN } = GENERAL;

//  Functions
export const doLogin = (idc, password) => {
	return {
		type: LOGIN.LOGIN,
		payload: { idc, password },
	};
};

export function getMyProfile() {
	return {
		type: LOGIN.GET_MY_PROFILE,
	};
}

export const setMyProfile = (user) => {
	return {
		type: LOGIN.SET_MY_PROFILE,
		payload: user,
	};
};

export const doLogout = () => {
	return {
		type: LOGIN.LOGOUT,
		payload: "",
	};
};

//  Event Handlers
export const handleOnChangeInput = (event) => {
	let { name, value } = event.target;

	return {
		type: LOGIN.CHANGE_INPUT,
		payload: { name, value: value.trim() },
	};
};

//  Action Handlers
export const doShowPassword = (namePasswordInput) => {
	return {
		type: LOGIN.SHOW_PASSWORD,
		payload: namePasswordInput,
	};
};

export const doReset = () => {
	return {
		type: LOGIN.RESET,
	};
};
