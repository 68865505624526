//Libs
import React from "react";
import styled from "styled-components";
//Components
import IconButton from "@material-ui/core/IconButton";
import icon from "./filter.svg";

const Icon = styled.div`
  background-image: url(${icon});
  width: 24px;
  height: 24px;
`;

const FilterIcon = ({ onClick }) => (
  <IconButton onClick={onClick}>
    <Icon />
  </IconButton>
);

export default FilterIcon;
