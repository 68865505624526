//Libs
import { notification } from "antd";
//Utils
import GENERAL from "../../../utils/constants/general";
import { lsHandler } from "../../../utils/libs";
import registerPushNotificationServiceWorker from "../../../registerPushNotificationSW";

const { LOGIN } = GENERAL;

const initialState = {
  user: "",
  password: "",
  profile: null,
  loading: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN.CHANGE_INPUT: {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    case LOGIN.MUTATE_DIRECT_PROPS: {
      const keyValuePairs = action.payload;
      return {
        ...state,
        ...keyValuePairs,
      };
    }
    case LOGIN.LOGGED: {
      const { logged } = action.payload;

      if (logged) {
        //Register Push Notification Service Worker
        registerPushNotificationServiceWorker().catch((error) =>
          console.error(error)
        );
      }

      return {
        ...state,
        logged,
      };
    }
    case LOGIN.LOGOUT: {
      lsHandler.del("synapse_token");
      lsHandler.del("synapse_linked");
      return initialState;
    }
    case LOGIN.SHOW_PASSWORD: {
      return {
        ...state,
        [action.payload]: !state[action.payload],
      };
    }
    case LOGIN.SET_MY_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      };
    }
    case LOGIN.RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default loginReducer;
