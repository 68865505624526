import styled from "styled-components";

const ToolbarTitle = styled.span`
  display: inline-block;
  font-family: ${(props) => props.theme.toolbar.fontFamily};
  font-style: ${(props) => props.theme.toolbar.fontStyle};
  font-weight: ${(props) => props.theme.toolbar.fontWeight};
  font-size: ${(props) => props.theme.toolbar.fontSize};
  line-height: ${(props) => props.theme.toolbar.lineHeight};
  letter-spacing: ${(props) => props.theme.toolbar.letterSpacing};
  mix-blend-mode: ${(props) => props.theme.toolbar.mixBlendMode};
  opacity: ${(props) => props.theme.toolbar.opacity};
  color: ${(props) => props.theme.toolbar.color};
  background-color: ${(props) => props.theme.toolbar.backgroundColor};
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default ToolbarTitle;
