//Libs
import React from "react";
import styled from "styled-components";
//Components
import IconButton from "@material-ui/core/IconButton";
import icon from "./excel.svg";

const Icon = styled.div`
  margin: ${(props) => props.margin};
  background-image: url(${icon});
  width: 24px;
  height: 24px;
`;

const ExcelIcon = ({ button, onClick, margin }) =>
  button ? (
    <IconButton margin={margin} onClick={onClick}>
      <Icon />
    </IconButton>
  ) : (
    <Icon margin={margin} onClick={onClick} />
  );

export default ExcelIcon;
