//Libs
import React from "react";
import styled from "styled-components";
//Components
import icon from "./direction.svg";

const Icon = styled.div`
  background-image: url(${icon});
  width: 24px;
  height: 24px;
  margin: 0 12px;
`;

const DirectionIcon = ({ onClick }) => <Icon onClick={onClick} />;

export default DirectionIcon;
