//Utils
import GENERAL from "utils/constants/general";
const { UPLOAD_RESOURCE } = GENERAL;

export function uploadResource(payload) {
  return {
    type: UPLOAD_RESOURCE.UPLOAD,
    payload,
  };
}

export function updateResources(payload) {
  return {
    type: UPLOAD_RESOURCE.UPDATE_RESOURCES,
    payload,
  };
}

export function updateAutoSync(payload) {
  return {
    type: UPLOAD_RESOURCE.UPDATE_AUTO_SYNC,
    payload,
  };
}
export function autoSyncResource(payload) {
  return {
    type: UPLOAD_RESOURCE.AUTO_SYNC,
    payload,
  };
}
