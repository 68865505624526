//Libs
import React, { useCallback, useEffect, useState } from "react";
//Utils
import { debounce } from "utils/libs";
//Components
import { Wrapper, Caption, InputNumber } from "components/components";

const InitialFinalInput = ({
  amount,
  amountTransit,
  initialAmount,
  finalAmount,
  step,
  min,
  isDecimal,
  onChange,
}) => {
  const [state, _setState] = useState({
    initial: undefined,
    final: undefined,
    disabledInitial: false,
  });
  const setState = (newProps) =>
    _setState((current) => ({ ...current, ...newProps }));

  const _checkInitFinalValues = (current, key, value) => {
    if (isNaN(value)) return "";
    if (isDecimal && String(value).endsWith(".")) return value;

    let numValue = Number(value);
    if (key === "final" && numValue >= current.initial)
      numValue = current.initial - 1;
    if (numValue <= 0) numValue = "";
    return numValue;
  };

  const _checkUpdateState = ({ initial, final }) =>
    (initial === null && final === null) ||
    (initial !== undefined &&
      final !== undefined &&
      (initial !== initialAmount || final !== finalAmount) &&
      initial !== final);

  const getResultAmount = (initial, final) => {
    if (!initial || !final) return 0.0;
    if (isDecimal) return (initial - final).toFixed(2);
    return initial - final;
  };

  const debouncedOnChange = useCallback(
    debounce((callback) => callback(), 3500),
    []
  );

  const onChangeInput = (key, value) =>
    _setState((current) => {
      const newState = {
        ...current,
        [key]: _checkInitFinalValues(current, key, value),
      };
      if (key === "initial") {
        if (value) newState.final = undefined;
        else {
          newState.initial = null;
          newState.final = null;
        }
      } else if (key === "final" && current.disabledInitial) {
        if (value) newState.initial = Number(amount);
      }
      const _resultAmount = getResultAmount(newState.initial, newState.final);
      debouncedOnChange(() => {
        if (!_checkUpdateState(newState)) return;
        onChange(_resultAmount, newState.initial, newState.final);
      });

      return newState;
    });

  //Hydrate state
  useEffect(() => {
    if (initialAmount && finalAmount)
      setState({ initial: Number(initialAmount), final: Number(finalAmount) });
    else if (state.disabledInitial && !finalAmount)
      setState({ initial: Number(amount) });
  }, [amount, initialAmount, finalAmount]);

  return (
    <Wrapper padding="0">
      <InputNumber
        placeholder="Inicial"
        disabled={state.disabledInitial}
        step={step}
        min={min}
        value={state.initial}
        onChange={(value) => onChangeInput("initial", value)}
        onDoubleClick={() =>
          setState({ disabledInitial: !state.disabledInitial })
        }
        parser={(value) => _checkInitFinalValues(state, "initial", value)}
      />
      <InputNumber
        placeholder="Final"
        disabled={!state.disabledInitial && !state.initial}
        step={step}
        min={min}
        value={state.initial ? state.final : ""}
        onChange={(value) => onChangeInput("final", value)}
        parser={(value) => _checkInitFinalValues(state, "final", value)}
      />
      <Caption margin="0 0 0 8px" style={{ display: "inline-table" }}>
        {getResultAmount(state.initial, state.final)}
      </Caption>
    </Wrapper>
  );
};

export default InitialFinalInput;
