//Libs
import { takeLatest, takeEvery, put, call, all } from "redux-saga/effects";
import axios from "axios";
//Utils
import asyncErrorsHandler from "store/asyncErrorsHandler";
import AuthService from "utils/libs/auth/AuthService";
import GENERAL from "utils/constants/general";
import UploadResourceUtils from "./UploadResourceUtils";
import { PhotoUtils } from "utils/libs";
import { CollectorUtils } from "../Collector";
//Selectors
import { updateResources } from "./actions";
import { mutate1Object as mutate1ObjectInCollector } from "components/components/Collector/actions";

const { UPLOAD_RESOURCE, ENV } = GENERAL;
const auth = new AuthService();

// WATCHERS
function* uploadResourceWatcher() {
  yield takeEvery(UPLOAD_RESOURCE.UPLOAD, uploadResourceWorker);
}
function* autoSyncResourceWatcher() {
  yield takeLatest(UPLOAD_RESOURCE.AUTO_SYNC, autoSyncResourceWorker);
}

// WORKERS
function* uploadResourceWorker(action) {
  const { file, fileProps } = action.payload;

  //Get URI
  const uri = UploadResourceUtils.getDynamicApiUrlByResourceType(
    fileProps.resourceType
  );
  if (!uri) return;

  try {
    //Update resources
    yield UploadResourceUtils.combineResources([
      {
        ...fileProps,
        file,
        status: ENV.UPLOAD_RESOURCE.STATUS.LOADING,
      },
    ]);
    //Update state
    let segmentedResources =
      yield UploadResourceUtils.getResourcesToSaveInState({
        docId: fileProps.docId,
        orderId: fileProps.auditOrderId,
        serviceTaskId: fileProps.serviceTaskId,
        reviewId: fileProps.reviewId,
        groupId: fileProps.groupId,
        subgroupId: fileProps.subgroupId,
      });
    yield put(updateResources(segmentedResources));

    const formData = new FormData();
    if (file) {
      //Convert base64 code to blob file
      const blobFile = yield call(PhotoUtils.dataURItoBlob, file);
      formData.append("collectorPhoto", blobFile);
    }

    //Add blob file and fileProps to formData
    formData.append("fileProps", JSON.stringify(fileProps));

    //Call API
    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_API_URL.concat(uri),
      formData,
      {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const duplicatedCollectorLayout =
      yield CollectorUtils.updateOfflineDuplicatedCollectorLayout(
        fileProps.docId,
        fileProps.auditOrderId,
        [data]
      );
    yield put(
      mutate1ObjectInCollector("reviewManage", { duplicatedCollectorLayout })
    );
    yield UploadResourceUtils.combineResources([data], {
      format: "afterMatch",
    });

    //Update state
    segmentedResources = yield UploadResourceUtils.getResourcesToSaveInState({
      docId: fileProps.docId,
      orderId: fileProps.auditOrderId,
      serviceTaskId: fileProps.serviceTaskId,
      reviewId: fileProps.reviewId,
      groupId: fileProps.groupId,
      subgroupId: fileProps.subgroupId,
    });
    yield put(updateResources(segmentedResources));
  } catch (err) {
    yield asyncErrorsHandler(err, function* () {
      yield UploadResourceUtils.combineResources([
        {
          ...fileProps,
          file,
          status: ENV.UPLOAD_RESOURCE.STATUS.ERROR,
        },
      ]);
      //Update state
      const segmentedResources =
        yield UploadResourceUtils.getResourcesToSaveInState({
          docId: fileProps.docId,
          orderId: fileProps.auditOrderId,
          serviceTaskId: fileProps.serviceTaskId,
          reviewId: fileProps.reviewId,
          groupId: fileProps.groupId,
          subgroupId: fileProps.subgroupId,
        });
      yield put(updateResources(segmentedResources));
    });
  }
}

function* autoSyncResourceWorker(action) {
  const { file, ...fileProps } = action.payload;

  //Get URI
  const uri = UploadResourceUtils.getDynamicApiUrlByResourceType(
    fileProps.resourceType
  );
  if (!uri) return;

  try {
    yield put({
      type: UPLOAD_RESOURCE.UPDATE_AUTO_SYNC,
      payload: { active: false },
    });

    //Update resources
    yield UploadResourceUtils.combineResources([
      {
        ...fileProps,
        file,
        status: ENV.UPLOAD_RESOURCE.STATUS.LOADING,
      },
    ]);

    const formData = new FormData();
    if (file) {
      //Convert base64 code to blob file
      const blobFile = yield call(PhotoUtils.dataURItoBlob, file);
      formData.append("collectorPhoto", blobFile);
    }
    //Add blob file and fileProps to formData
    formData.append("fileProps", JSON.stringify(fileProps));

    //Call API
    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_API_URL.concat(uri),
      formData,
      {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const duplicatedCollectorLayout =
      yield CollectorUtils.updateOfflineDuplicatedCollectorLayout(
        fileProps.docId,
        fileProps.auditOrderId,
        [data]
      );
    yield put(
      mutate1ObjectInCollector("reviewManage", { duplicatedCollectorLayout })
    );
    yield UploadResourceUtils.combineResources([data], {
      format: "afterMatch",
    });
    yield put({
      type: UPLOAD_RESOURCE.UPDATE_AUTO_SYNC,
      payload: { active: true },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, function* () {
      //Update resources
      yield UploadResourceUtils.combineResources([
        { ...fileProps, file, status: ENV.UPLOAD_RESOURCE.STATUS.ERROR },
      ]);
      yield put({
        type: UPLOAD_RESOURCE.UPDATE_AUTO_SYNC,
        payload: { active: true },
      });
    });
  }
}

//  Export default Root Saga
export default function* rootSaga() {
  yield all([uploadResourceWatcher(), autoSyncResourceWatcher()]);
}
