import { normalize } from "./normalize";

const formatNumber = {
  separador: ",", // separador para los miles
  sepDecimal: ".", // separador para los decimales
  formatear: function (num) {
    num += "";
    let splitStr = num.split(",");
    let splitLeft = splitStr[0];
    let splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : "";
    let regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, "$1" + this.separador + "$2");
    }
    return this.simbol + splitLeft + splitRight;
  },
  new: function (num, simbol, fixed) {
    this.simbol = simbol || "";
    return this.formatear(Number(num).toFixed(fixed));
  },
};

function filterOption(input, option) {
  if (typeof option?.props?.children === "string") {
    return (
      normalize(option.props.children)
        .toLowerCase()
        .indexOf(normalize(input).toLowerCase()) >= 0
    );
  }
  if (typeof option?.props?.children?.props?.children === "string") {
    return (
      normalize(option.props.children.props.children)
        .toLowerCase()
        .indexOf(normalize(input).toLowerCase()) >= 0
    );
  }
  if (Array.isArray(option?.props?.children)) {
    return (
      option.props.children.filter(
        (opt) =>
          normalize(opt)
            .toLowerCase()
            .indexOf(normalize(input).toLowerCase()) >= 0
      ).length > 0
    );
  }
}

function isRecordSigned(signed, userId) {
  return !signed
    ? false
    : !!signed.filter((sign) => sign.user_id === userId)[0];
}

export { filterOption, formatNumber, isRecordSigned };
