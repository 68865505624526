//Libs
import styled from "styled-components";
//Components
import icon from "./backButton.svg";

const Icon = styled.div`
  background-image: url(${icon});
  width: 24px;
  height: 24px;
  margin: ${(props) => props.margin};
`;

export default Icon;
