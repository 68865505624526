//Libs
import React from "react";
import styled, { css, useTheme } from "styled-components";
//Components
import { Wrapper, Span } from "components/components";
import { Avatar as _Avatar } from "antd";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DefaultAvatarImg from "assets/media/img/default-avatar.png";

const BORDER_WIDTH = 4;
const BORDER_HEIGHT = 4;

const SkeletonAvatar = ({ size, name }) => {
  const theme = useTheme();
  const { xlarge, large, medium, normal } = theme.sizes;

  let width = xlarge.width;
  let height = xlarge.height;
  if (size === large.name) {
    width = large.width;
    height = large.height;
  } else if (size === medium.name) {
    width = medium.width;
    height = medium.height;
  } else if (size === normal.name) {
    width = normal.width;
    height = normal.height;
  }

  return (
    <SkeletonTheme
      color={theme.avatar.skeleton.color}
      highlightColor={theme.avatar.skeleton.highlightColor}
    >
      <Skeleton circle={true} width={width} height={height} />
      {name && (
        <div style={{ marginTop: "5px" }}>
          <Skeleton />
        </div>
      )}
    </SkeletonTheme>
  );
};

const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvatarBorder = styled.div`
  position: absolute;
  width: ${({ theme }) => `${theme.sizes.xlarge.width}px`};
  height: ${({ theme }) => `${theme.sizes.xlarge.height}px`};
  border-radius: ${(props) => props.theme.avatar.border.borderRadius};
  ${(props) =>
    props.bgTransparent
      ? css`
          background: "transparent";
        `
      : css`
          background: ${(props) => props.theme.avatar.border.background1};
          background: ${(props) => props.theme.avatar.border.background2};
        `}
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme, size }) =>
    size === theme.sizes.large.name &&
    css`
      width: ${({ theme }) => `${theme.sizes.large.width}px`};
      height: ${({ theme }) => `${theme.sizes.large.height}px`};
    `};
  ${({ theme, size }) =>
    size === theme.sizes.medium.name &&
    css`
      width: ${({ theme }) => `${theme.sizes.medium.width}px`};
      height: ${({ theme }) => `${theme.sizes.medium.height}px`};
    `};
  ${({ theme, size }) =>
    size === theme.sizes.normal.name &&
    css`
      width: ${({ theme }) => `${theme.sizes.normal.width}px`};
      height: ${({ theme }) => `${theme.sizes.normal.height}px`};
    `};
  ${({ spin }) =>
    spin &&
    css`
      animation: spin 1s linear infinite;

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}
`;

const AvatarPhoto = styled(_Avatar)`
  width: ${({ theme }) => `${theme.sizes.xlarge.width - BORDER_WIDTH}px`};
  height: ${({ theme }) => `${theme.sizes.xlarge.height - BORDER_HEIGHT}px`};
  ${({ theme, size }) =>
    size === theme.sizes.large.name &&
    css`
      width: ${({ theme }) => `${theme.sizes.large.width - BORDER_WIDTH}px`};
      height: ${({ theme }) => `${theme.sizes.large.height - BORDER_HEIGHT}px`};
    `};
  ${({ theme, size }) =>
    size === theme.sizes.medium.name &&
    css`
      width: ${({ theme }) => `${theme.sizes.medium.width - BORDER_WIDTH}px`};
      height: ${({ theme }) =>
        `${theme.sizes.medium.height - BORDER_HEIGHT}px`};
    `};
  ${({ theme, size }) =>
    size === theme.sizes.normal.name &&
    css`
      width: ${({ theme }) => `${theme.sizes.normal.width - BORDER_WIDTH}px`};
      height: ${({ theme }) =>
        `${theme.sizes.normal.height - BORDER_HEIGHT}px`};
    `};
`;

const AvatarName = styled(Span)`
  margin-top: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme, isActiveAvatar }) =>
    isActiveAvatar ? theme.colors.text.high : theme.colors.text.medium};
  width: ${({ theme }) => `${theme.sizes.xlarge.width}px`};
  ${({ theme, size }) =>
    size === theme.sizes.large.name &&
    css`
      width: ${({ theme }) => `${theme.sizes.large.width}px`};
    `};
  ${({ theme, size }) =>
    size === theme.sizes.medium.name &&
    css`
      width: ${({ theme }) => `${theme.sizes.medium.width}px`};
    `};
  ${({ theme, size }) =>
    size === theme.sizes.normal.name &&
    css`
      width: ${({ theme }) => `${theme.sizes.normal.width}px`};
    `};
`;

const AvatarGroup = ({
  src,
  name,
  spin,
  size,
  isActiveAvatar,
  bgTransparent,
}) => (
  <Wrapper padding="0" flexDirection="column">
    <AvatarWrapper className="animated fadeIn">
      <AvatarBorder spin={spin} size={size} bgTransparent={bgTransparent} />
      <AvatarPhoto src={src} size={size} />
    </AvatarWrapper>
    {name && (
      <AvatarName
        ellipsis
        size={size}
        fontSize="xs"
        textAlign="center"
        isActiveAvatar={isActiveAvatar}
      >
        {name}
      </AvatarName>
    )}
  </Wrapper>
);

//Fetch remote image url
const getRemoteImageResource = async ({ src, noCached }) => {
  //Check loaded image
  function checkImage(path) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve({ path, status: "ok" });
      img.onerror = () => resolve({ path, status: "error" });

      img.src = path;
    });
  }
  const imageSrc = noCached ? `${src}?t=${new Date().getMilliseconds()}` : src;
  const fetchedImage = await checkImage(imageSrc);
  if (fetchedImage.status === "error") return DefaultAvatarImg;
  return imageSrc;
};

const Avatar = ({
  avatar,
  skeleton,
  spin,
  size,
  selectedAvatarId,
  bgTransparent,
  fit,
  margin,
  noCached,
  onClickEvent = () => { }
}) => {
  const [myState, setMyState] = React.useState({
    isFetching: false,
    src: undefined,
  });
  const { id: avatarId, name: avatarName, src: avatarSrc } = avatar;
  const isActiveAvatar = avatarId && avatarId === selectedAvatarId;

  //Update avatar?
  React.useEffect(() => {
    let isMounted = true;
    setMyState({ isFetching: true });
    if (avatarSrc) {
      getRemoteImageResource({
        src: avatarSrc,
        noCached,
      }).then((src) => isMounted && setMyState({ isFetching: false, src }));
    }
    return () => (isMounted = false);
  }, [avatarSrc]);

  //Set default avatar?
  React.useEffect(() => {
    let isMounted = true;
    if (!myState.isFetching && !myState.src) {
      if (isMounted) setMyState({ src: DefaultAvatarImg });
    }
    return () => (isMounted = false);
  }, [myState.isFetching, myState.src]);

  return (
    <Wrapper padding={fit ? "0px" : "10px"} margin={margin} onClick={onClickEvent}>
      {skeleton || myState.isFetching ? (
        <SkeletonAvatar size={size} name={avatarName} />
      ) : (
        <AvatarGroup
          src={myState.src}
          name={avatarName}
          spin={spin && isActiveAvatar}
          size={size}
          isActiveAvatar={isActiveAvatar}
          bgTransparent={bgTransparent}
        />
      )}
    </Wrapper>
  );
};

export default Avatar;
