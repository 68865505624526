//Libs
import React from "react";
//Components
import {
  ConsumeInventory,
  LogoIcon,
  ResumeInventory,
  Title,
  Wrapper,
} from "components/components";
import { EmptyIllustration } from "components/components/Illustrations";

const Empty = () => (
  <Wrapper
    height="300px"
    flexDirection="column"
    width="100%"
    alignItems="center"
    justifyContent="center"
  >
    <EmptyIllustration />
    <Title margin="16px 0 0 0" textAlign="center">
      No se encontró ningún inventario
    </Title>
  </Wrapper>
);

const Loading = () => (
  <Wrapper width="100%" height="400px" justifyContent="center">
    <LogoIcon size="l" spin={true} />
  </Wrapper>
);

const ConsumedInventoryTab = ({
  isFetching,
  wtd,
  order,
  consumedItemsResume,
  editableInventory,
}) => {
  if (isFetching) return <Loading />;
  if (!wtd) return <Empty />;

  return editableInventory ? (
    <ConsumeInventory order={order} />
  ) : (
    <Wrapper>
      <ResumeInventory consumedItemsResume={consumedItemsResume} />
    </Wrapper>
  );
};

export default ConsumedInventoryTab;
