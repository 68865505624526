// Libs
import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
// Contexts
import { RealtimeContext, RealtimeUtils } from "context/RealtimeContext";
// Selectors
import { selectProfile } from "components/containers/Login/selectors";
// Hooks
import usePrevious from "./usePrevious";

export default function useRealtime({ topics, qos } = {}) {
  const {
    // isConnected,
    payload: mqttPayload,
    mqttSub,
    mqttUnSub,
    mqttPublish,
  } = useContext(RealtimeContext);
  const profile = useSelector(selectProfile);
  const prevProfile = usePrevious(profile);

  const [payload, setPayload] = useState({});

  const _subscribe = () => {
    Object.keys(topics).map((topic) =>
      mqttSub(topic, RealtimeUtils.getMyProfileSubscriber(profile), { qos })
    );
  };

  const _unsubscribe = (_topics, _profile) => {
    if (!_topics) return;
    Object.keys(_topics).map((topic) =>
      mqttUnSub(topic, RealtimeUtils.getMyProfileSubscriber(_profile))
    );
  };

  const publish = ({ topic, subscriber, payload, qos, retain }) => {
    if (!topic) return;
    const _subscriber =
      subscriber || RealtimeUtils.getMyProfileSubscriber(profile);
    mqttPublish(topic, _subscriber, payload, {
      qos,
      retain,
    });
  };

  const _onRestartSubscriptions = () => {
    if (!topics) return;
    if (prevProfile?.user?.profileId === profile?.user?.profileId) {
      //Subscribe
      if (profile) _subscribe();
    } else {
      //Unsubscribe
      if (prevProfile) _unsubscribe(topics, prevProfile);
      //Subscribe
      if (profile) _subscribe();
    }
  };

  const _onReceiveNewPayloadMessage = () => {
    if (!mqttPayload || !topics) return;
    const { action, message } = mqttPayload;
    const subscribedTopic = RealtimeUtils.findTopicInSubscribedTopics(
      action,
      topics
    );
    if (!subscribedTopic) return;
    setPayload({ action, message });
  };

  // Auto refresh Subscribe/Unsubscribe on Login with other user
  useEffect(() => {
    _onRestartSubscriptions();
    return () => {
      _unsubscribe(topics, profile);
    };
  }, [profile?.user?.profileId, JSON.stringify(topics)]);

  useEffect(() => {
    _onReceiveNewPayloadMessage();
  }, [mqttPayload, JSON.stringify(topics)]);

  return {
    payload,
    publish,
  };
}
