//Libs
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
//Utils
import GENERAL from "utils/constants/general";
import npmPackage from "../../../../../package.json";
import { getGeolocation } from "components/CoordManager/actions";
import { onKeyPressedSearchStockSeries } from "components/containers/Warehouse/utils";
//Actions
import { sendToast, showNavBar, mutateDirectProps, setLink } from "../actions";
import { mutate1Object as mutate1ObjectInWarehouse } from "components/containers/Warehouse/actions";
import { doLogout } from "components/containers/Login/actions";
//Selectors
import {
  selectDashboardMenuIsOpen,
  selectLinked,
  selectSelectedProjectId,
} from "../selectors";
import { selectStockSeriesManage } from "components/containers/Warehouse/selectors";
import { selectProfile } from "components/containers/Login/selectors";
//Components
import { SplitterSide, Page } from "react-onsenui";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
  Wrapper,
  LogoutIcon,
  Avatar,
  Syn4pseLogo,
  Version,
  Title,
  Subtitle,
  DashboardItem,
  DashboardSubItem,
  OrderIcon,
  PaymentIcon,
  TransactionIcon,
  InventoryIcon,
  ExpandedIcon,
  CollapsedIcon,
  SearchIcon,
  CloseIcon,
  TextField,
} from "components/components";
// Utils
import { Modal } from "components/containers/Utils";

const { ENV } = GENERAL;
const { Input } = TextField;

const UserInformationContainer = ({ linked, profile, setLink, sendToast }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <Wrapper margin="16px 0 0 0" padding="0" justifyContent="space-between">
      {/* Avatar */}
      <Avatar
        fit
        size="medium"
        avatar={{
          src: profile?.user?.profile?.photo,
        }}
        onClickEvent={() => setIsOpenModal(true)}
      />
      {/* User Name & Company */}
      <Wrapper
        padding="0"
        margin="0 12px 0 12px"
        flexDirection="column"
        alignItems="baseline"
      >
        <Title width="150px" ellipsis>
          {profile?.user?.name}
        </Title>
        <Subtitle ellipsis width="150px" whiteSpace="nowrap">
          {profile?.user?.profile?.emails?.[0]}
        </Subtitle>
      </Wrapper>
      {/* Connect button */}
      <FormControlLabel
        control={
          <Switch
            color="primary"
            value="connected"
            checked={linked.connected}
            onChange={(e) => {
              const checked = e.target.checked;
              getGeolocation()
                .then(({ coords = {}, message, type }) => {
                  if (message) sendToast({ message, type });
                  setLink(checked, { ...coords, createdAt: new Date() });
                })
                .catch(({ message, type }) => sendToast({ message, type }));
            }}
          />
        }
      />
      <Modal
        modalId="UserProfileData"
        title="Datos del Perfil"
        visible={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        onOk={() => setIsOpenModal(false)}
        isSpinning={false}
        footer={null}
        customProps={{
          userId: profile?.user?.id,
          userName: profile?.user?.name,
        }}
      />
    </Wrapper>
  );
};

const DashboardCardPresentation = ({ linked, profile, setLink, sendToast }) => {
  return (
    <Wrapper
      padding="16px 16px 0 24px"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Wrapper width="100%" padding="0" justifyContent="space-between">
        <Syn4pseLogo />
        <Version>v{npmPackage.version}</Version>
      </Wrapper>
      <Subtitle ellipsis padding="0 0 0 48px" light>
        {profile?.organization?.name}
      </Subtitle>
      <UserInformationContainer
        linked={linked}
        profile={profile}
        setLink={setLink}
        sendToast={sendToast}
      />
    </Wrapper>
  );
};

const ItemDashboard = ({
  route,
  expandedDashboardItem,
  onChangeExpandedItem,
  mutateDirectProps,
  mutate1ObjectInWarehouse,
  history,
  stockSeriesManage,
}) => {
  const { item, id, routes, sub } = route;

  return !sub ? (
    <LinkItemDashboard
      {...{
        route,
        mutateDirectProps,
        mutate1ObjectInWarehouse,
        history,
        stockSeriesManage,
      }}
    />
  ) : (
    <div className="dashboard-head-sublist-items">
      {/* CONTAINER ITEM */}
      <ListItem
        button
        style={{ paddingLeft: 0 }}
        onClick={() => onChangeExpandedItem(id)}
      >
        {expandedDashboardItem === id ? <ExpandedIcon /> : <CollapsedIcon />}

        <ListItemText
          style={{ paddingLeft: 0 }}
          primary={<DashboardItem>{item.toUpperCase()}</DashboardItem>}
        />
      </ListItem>

      {/* CONTAINER COLLAPSE */}
      <Collapse in={expandedDashboardItem === id} timeout="auto" unmountOnExit>
        <List component="div">
          {routes.map(
            (route, idxL2) =>
              route.item && (
                <LinkItemDashboard
                  key={idxL2}
                  {...{
                    route,
                    mutateDirectProps,
                    mutate1ObjectInWarehouse,
                    history,
                    stockSeriesManage,
                  }}
                />
              )
          )}
        </List>
      </Collapse>
    </div>
  );
};

const LinkItemDashboard = ({
  route,
  mutateDirectProps,
  mutate1ObjectInWarehouse,
  history,
  stockSeriesManage,
}) => {
  let { path, item, id } = route;

  return (
    <div className="dashboard-item">
      {id === ENV.ROUTES.IDS.WAREHOUSE_STOCK &&
      stockSeriesManage.searchInData.renderToolbar ? (
        <div style={{ width: "100%" }}>
          <Input
            id="toolbar-search-order-input"
            style={{ width: "95%", marginLeft: "17px", marginTop: "5px" }}
            autoFocus
            name="data"
            placeholder="Buscar series"
            value={stockSeriesManage.searchInData.data}
            onKeyPress={(e) =>
              onKeyPressedSearchStockSeries(
                e,
                stockSeriesManage.searchInData,
                history,
                mutate1ObjectInWarehouse,
                mutateDirectProps
              )
            }
            onChange={(e) =>
              mutate1ObjectInWarehouse("stockSeriesManage", {
                searchInData: {
                  ...stockSeriesManage.searchInData,
                  [e.target.name]: e.target.value,
                },
              })
            }
            prefix={<SearchIcon small onlyIcon />}
            suffix={
              <CloseIcon
                small
                onlyIcon
                onClick={() =>
                  mutate1ObjectInWarehouse("stockSeriesManage", {
                    searchInData: {
                      ...stockSeriesManage.searchInData,
                      renderToolbar: false,
                      searchBy: "all",
                      data: "",
                    },
                  })
                }
              />
            }
          />
        </div>
      ) : (
        <Link className="link-slidemenu-item" to={path}>
          {/* Container ITEM */}
          <ListItem
            button
            style={{ width: "100%" }}
            onClick={() => mutateDirectProps({ isOpen: false })}
          >
            {/* Container ICON and TEXT item */}
            <Wrapper padding="0" alignItems="center">
              <ListItemIcon>{getIcon(id)}</ListItemIcon>
              <DashboardSubItem>{item}</DashboardSubItem>
            </Wrapper>
          </ListItem>
        </Link>
      )}

      {id === ENV.ROUTES.IDS.WAREHOUSE_STOCK &&
        !stockSeriesManage.searchInData.renderToolbar && (
          <SearchIcon
            small
            onClick={() =>
              mutate1ObjectInWarehouse("stockSeriesManage", {
                searchInData: {
                  ...stockSeriesManage.searchInData,
                  renderToolbar: true,
                  searchBy: "scan",
                },
              })
            }
          />
        )}
    </div>
  );
};

const getIcon = (id) => {
  return id === ENV.ROUTES.IDS.ORDERS ? (
    <OrderIcon />
  ) : id === ENV.ROUTES.IDS.PAYMENTS ? (
    <PaymentIcon />
  ) : id === ENV.ROUTES.IDS.WAREHOUSE_STOCK ? (
    <InventoryIcon />
  ) : (
    id === ENV.ROUTES.IDS.WAREHOUSE_TRANSACTIONS && <TransactionIcon />
  );
};

const Menu = ({
  //Props,
  routes,
  //State
  isOpen,
  linked,
  profile,
  stockSeriesManage,
  selectedProjectId,
  //Actions
  mutateDirectProps,
  mutate1ObjectInWarehouse,
  showNavBar,
  setLink,
  sendToast,
  doLogout,
}) => {
  const history = useHistory();
  const [myState, setMyState] = useState({
    expandedDashboardItem: "wrs",
  });
  const mutateMyState = (newState) => setMyState({ ...myState, ...newState });

  return (
    <SplitterSide
      style={{
        boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
      }}
      side="left"
      width={306}
      collapse={true}
      swipeable={false}
      isOpen={isOpen}
      onOpen={showNavBar}
      onClose={() => mutateDirectProps({ isOpen: false })}
    >
      <Page className="page__background">
        <div className="dashboard" style={{ overflowX: "hidden" }}>
          <DashboardCardPresentation
            linked={linked}
            profile={profile}
            setLink={setLink}
            sendToast={sendToast}
          />

          <hr className="dashboard-line-separator" />

          {/* <Wrapper
            className="animated fadeIn"
            padding="0"
            width="100%"
            height="65px"
            overflowX="auto"
          >
            <ProjectPicker />
            {selectedProjectId && (
              <>
                <Separator width="2px" height="30px" />
                <OrderTargetFilterPicker />
                <Separator width="2px" height="30px" />
                <ServicePicker />
              </>
            )}
          </Wrapper> */}

          <div className="dashboard-items-container">
            <List component="nav">
              {routes.map(
                (route, idxL1) =>
                  route.item && (
                    <ItemDashboard
                      key={idxL1}
                      {...{
                        route,
                        expandedDashboardItem: myState.expandedDashboardItem,
                        onChangeExpandedItem: (routeId) =>
                          mutateMyState({
                            expandedDashboardItem:
                              myState.expandedDashboardItem !== routeId
                                ? routeId
                                : undefined,
                          }),
                        mutateDirectProps,
                        mutate1ObjectInWarehouse,
                        history,
                        stockSeriesManage,
                      }}
                    />
                  )
              )}
            </List>

            <List>
              {/* EXIT BUTTON */}
              <ListItem
                button
                onClick={() => {
                  doLogout();
                  history.replace("/login");
                }}
              >
                <Wrapper padding="0">
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={<DashboardItem>Salir</DashboardItem>}
                  />
                </Wrapper>
              </ListItem>
            </List>
          </div>
        </div>
      </Page>
    </SplitterSide>
  );
};

const mapStateToProps = (state) => ({
  isOpen: selectDashboardMenuIsOpen(state),
  linked: selectLinked(state),
  profile: selectProfile(state),
  stockSeriesManage: selectStockSeriesManage(state),
  selectedProjectId: selectSelectedProjectId(state),
});
const actions = {
  mutateDirectProps,
  showNavBar,
  setLink,
  sendToast,
  mutate1ObjectInWarehouse,
  doLogout,
};

export default connect(mapStateToProps, actions)(Menu);
