// Libs
import { useMemo, useEffect } from "react";
import Immutable from "immutable";
// Utils
import { CollectorUtils } from "components/components";
import { CollectorDynamics } from "components/components/Collector/utils";
// Hooks
import { useCollectorData, useCollectorValues } from "hooks";
//Actions
import UploadResourceUtils from "components/components/UploadResource/UploadResourceUtils";

const useCollectorLayout = ({ docId, orderId } = {}) => {
  const accessGranted = docId || orderId;
  const {
    readOnlyCollector,
    collectorLayout,
    duplicatedCollectorLayout,
    collectorValues,
    resources,
    mutate1ObjectInCollector,
    setAutoFillCollector,
  } = useCollectorData();
  const { onCollectorValuesFilter } = useCollectorValues();

  //Reset collector value by filter list value
  const filterListValues = (collector, toDeleteCollectorValues) => {
    collector.listValues.forEach((listValue) => {
      const absoluteReferences = CollectorDynamics.getAbsoluteReferences(
        listValue.filters,
        { collectorLayout }
      );
      const replacedReferences = CollectorDynamics.replaceDuplicatedReferences(
        absoluteReferences,
        collector
      );
      const referencedFilter = CollectorDynamics.getReferencedFilter(
        replacedReferences,
        collector
      );
      if (!referencedFilter) return;

      const referencedCollectorValue =
        CollectorDynamics.getReferencedCollectorValue(
          referencedFilter,
          collectorValues
        );
      const checkedlistValue = CollectorDynamics.checkFilteredCollector(
        referencedFilter,
        referencedCollectorValue,
        collectorLayout
      );
      if (!checkedlistValue) {
        const toDeleteCollectorValue = CollectorUtils.getToDeleteCollectorValue(
          { order_id: orderId, docId },
          collector,
          collectorValues
        );
        if (toDeleteCollectorValue)
          toDeleteCollectorValues.push(toDeleteCollectorValue);
      }
    });
  };

  const filterPhotos = (collector) =>
    collector.photos.reduce((photos, photo) => {
      if (!photo.filters?.length) {
        photos.push(photo);
        return photos;
      }
      const absoluteReferences = CollectorDynamics.getAbsoluteReferences(
        photo.filters,
        { collectorLayout }
      );
      const replacedReferences = CollectorDynamics.replaceDuplicatedReferences(
        absoluteReferences,
        collector
      );
      const referencedFilter = CollectorDynamics.getReferencedFilter(
        replacedReferences,
        collector
      );
      if (!referencedFilter) return photos;

      const referencedCollectorValue =
        CollectorDynamics.getReferencedCollectorValue(
          referencedFilter,
          collectorValues
        );
      const checkedPhoto = CollectorDynamics.checkFilteredCollector(
        referencedFilter,
        referencedCollectorValue,
        collectorLayout
      );
      if (!checkedPhoto) {
        const fileProps = UploadResourceUtils.getCollectorResourceFileProps(
          { order_id: orderId, docId },
          collector,
          photo
        );
        UploadResourceUtils.removeResourceFromResources(fileProps);
        return photos;
      }
      return [...photos, photo];
    }, []);

  const filterCollectors = (review, toDeleteCollectorValues) =>
    review.collectors.reduce((acc, collector) => {
      if (!collector.filters?.length) {
        filterListValues(collector, toDeleteCollectorValues);
        collector.photos = filterPhotos(collector);
        acc.push(collector);
        return acc;
      }
      const absoluteReferences = CollectorDynamics.getAbsoluteReferences(
        collector.filters,
        { collectorLayout }
      );
      const replacedReferences = CollectorDynamics.replaceDuplicatedReferences(
        absoluteReferences,
        collector
      );
      const referencedFilter = CollectorDynamics.getReferencedFilter(
        replacedReferences,
        collector
      );
      if (!referencedFilter) return acc;

      const referencedCollectorValue =
        CollectorDynamics.getReferencedCollectorValue(
          referencedFilter,
          collectorValues
        );
      const checkedCollector = CollectorDynamics.checkFilteredCollector(
        referencedFilter,
        referencedCollectorValue,
        collectorLayout
      );
      if (!checkedCollector) {
        const toDeleteCollectorValue = CollectorUtils.getToDeleteCollectorValue(
          { order_id: orderId, docId },
          collector,
          collectorValues
        );
        if (toDeleteCollectorValue)
          toDeleteCollectorValues.push(toDeleteCollectorValue);
        return acc;
      }

      filterListValues(collector, toDeleteCollectorValues);
      collector.photos = filterPhotos(collector);

      return [...acc, collector];
    }, []);

  const filteredCollectorLayout = useMemo(() => {
    if (!accessGranted) return;
    let toDeleteCollectorValues = [];
    const _filteredCollectorLayout = Immutable.List(collectorLayout)
      .toJS()
      .map((review) => {
        review.collectors = filterCollectors(review, toDeleteCollectorValues);
        return review;
      });
    if (!!toDeleteCollectorValues.length)
      onCollectorValuesFilter(toDeleteCollectorValues);
    return _filteredCollectorLayout;
  }, [docId, orderId, collectorLayout, collectorValues]);

  //Reload offlineCollectorValues and sync with onlineCollectorValues
  useEffect(() => {
    if (!orderId) return;
    CollectorUtils.getOfflineReviewManage().then(
      ({
        offlineCollectorValues,
        offlineAutoFillCollector,
        // offlineDuplicatedCollectorLayout,
      }) => {
        mutate1ObjectInCollector("reviewManage", {
          collectorValues: offlineCollectorValues,
          // duplicatedCollectorLayout: offlineDuplicatedCollectorLayout,
        });
        setAutoFillCollector(offlineAutoFillCollector);
      }
    );
  }, [orderId]);

  return {
    readOnlyCollector,
    collectorLayout,
    duplicatedCollectorLayout,
    filteredCollectorLayout,
    collectorValues,
    resources,
  };
};

export default useCollectorLayout;
