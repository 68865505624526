export default class ServiceUtils {
  static getServicePropsFromOrder(key, order) {
    if (!key || !order) return {};

    const props = order["service_props"];
    if (!props) return {};

    if (typeof props === "object" && !props[key]) return {};
    if (typeof props[key] !== "object") return {};
    return { ...props[key] };
  }
}
