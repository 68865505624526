//Libs
import React from "react";
import { useTheme } from "styled-components";
//Utils
import COLLECTOR_KEYWORDS from "./keywords";
import { GlobalUtils } from "utils";
//Components
import { Wrapper, Span, CollectorUtils } from "components/components";
import { CollectorDynamics } from "./utils";

/**
 * auto_calc_placeholder:
 * [{
 *  "level": "review" || "group" || "subgroup",
 *  "valueFn": "(rcv) => `${rcv.collectorName}: ${rcv.value}`",
 *  "style": "() => ({fontWeight: 'bold'})",
 *  "sort": 1
 * }]
 */

const DynamicHeaderTitle = ({
  level,
  highlightElement,
  defaultTitle,
  highlightRequired,
  selectedCurrentCollector,
  collectors,
  collectorValues,
  customStyle = {},
}) => {
  const theme = useTheme();
  const isHighlightRequired = CollectorUtils.getHighlightCollector(
    level,
    highlightRequired,
    highlightElement
  );
  const isHighlightCurrentSelection = CollectorUtils.getHighlightCollector(
    level,
    selectedCurrentCollector,
    highlightElement
  );
  const titleFontWeight =
    isHighlightRequired ||
    isHighlightCurrentSelection ||
    level === COLLECTOR_KEYWORDS.COLLECTORS.DUPLICATION.LEVELS.SERVICE_TASK
      ? "bold"
      : theme.span.fontWeight;
  const titleColor = isHighlightRequired
    ? theme.colors.error.color
    : isHighlightCurrentSelection
    ? theme.colors.text.high
    : theme.colors.text.medium;
  const spanProps = {
    fontSize: "l",
    fontWeight: titleFontWeight,
    color: titleColor,
    style: { ...customStyle, letterSpacing: "0.1em" },
  };

  const referencedCollectorValues = React.useMemo(() => {
    if (!collectors || !collectorValues) return [];

    const referencedCollectors = CollectorDynamics.getReferencedCollectors(
      null,
      { collectors },
      { level, poolReference: "autoCalcPlaceholder" }
    );

    return CollectorDynamics.getReferencedCollectorValues(
      referencedCollectors,
      collectorValues,
      {
        inheritReferenceProps: ({ name, style, valueFn }) => ({
          collectorName: name,
          style,
          valueFn,
        }),
      }
    );
  }, [collectors, collectorValues]);

  return (
    <Wrapper
      width="100%"
      padding="0"
      flexDirection="column"
      alignItems="flex-start"
    >
      {!referencedCollectorValues.length ? (
        <Span {...spanProps}>{defaultTitle}</Span>
      ) : (
        referencedCollectorValues.map((rcv, idx) => (
          <Span
            key={String(idx)}
            {...spanProps}
            style={{
              ...spanProps.style,
              ...GlobalUtils.executeStringifyCustomFunction(
                rcv.style,
                null,
                {}
              ),
            }}
            maxHeight="40px"
            ellipsis
            whiteSpace="unset"
          >
            {GlobalUtils.executeStringifyCustomFunction(
              rcv.valueFn,
              rcv,
              rcv.value
            )}
          </Span>
        ))
      )}
    </Wrapper>
  );
};

export default DynamicHeaderTitle;
