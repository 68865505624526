import React, { memo } from "react";

import { TextField } from "components/components";

const { Input } = TextField;

const StringField = memo(({ title, value, onChange }) => (
  <Input
    width="100%"
    placeholder={title}
    value={value}
    onChange={(e) => onChange(e.target.value)}
  />
));

export default StringField;
