//Utils
import GENERAL from "../../../utils/constants/general";

const { PAYMENTS } = GENERAL,
  //******** INITIAL STATE ***********/
  initialState = {
    paymentsManage: {
      // payId: undefined,
      // paidTo: undefined,
      selected: {
        items: [],
      },
      searchInData: {
        renderToolbar: false,
        showPopover: false,
        data: "",
        searchBy: "all",
      },
    },
    getPaymentsModal: {
      last_row_id: 0,
      limit: 50,
    },
    signPaymentModal: {
      isOpen: false,
      loading: false,
      groupOrders: false,
      showSummary: false,
    },
  },
  paymentReducer = (state = initialState, action) => {
    switch (action.type) {
      case PAYMENTS.MUTATE_1OBJECT: {
        const { obj1Name, keyValuePairs } = action.payload;
        const newObj = { ...state[obj1Name], ...keyValuePairs };

        return {
          ...state,
          [obj1Name]: newObj,
        };
      }
      case PAYMENTS.RESET: {
        return initialState;
      }
      default:
        return state;
    }
  };

export default paymentReducer;
