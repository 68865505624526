//Libs
import React from "react";
import { connect } from "react-redux";
//Actions
import { getSavedCollectorValuesByOrder } from "../actions";
//Selectors
import { selectReviewManage } from "../selectors";
//Components
import { IconButton } from "components/components";

const ReloadDatabaseCollectors = ({
  //Redux
  reviewManage,
  getSavedCollectorValuesByOrder,
}) => {
  const { orderId, isFetching } = reviewManage;
  return isFetching ? (
    <IconButton type="loading" size="m" />
  ) : (
    <IconButton
      size="m"
      type="reload"
      onClick={() => getSavedCollectorValuesByOrder(orderId)}
    />
  );
};
const mapStateToProps = (state) => ({
  reviewManage: selectReviewManage(state),
});
const actions = {
  getSavedCollectorValuesByOrder,
};

export default connect(mapStateToProps, actions)(ReloadDatabaseCollectors);
