//Libs
import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
//Services
import { SentryService } from "services";
//Actions
import * as warehouseActions from "./actions";
import { sendToast } from "components/containers/Dashboard/actions";
import {
  mutate1Object as mutate1ObjectInApi,
  getIncomeMethods,
  getWarehouses,
  getSerieHistory,
} from "store/api/actions";
//Selectors
import { selectTransactionRows, selectWarehouseState } from "./selectors";
//Components
import { WrapperView } from "components/components";
import ModalWarehouse from "./Modals";
//Styles
import "./index.css";
import { selectProfile } from "../Login/selectors";

class Warehouse extends React.Component {
  constructor(...props) {
    super(...props);
  }

  /*********************************** LIFECYCLES **************************************/
  componentDidMount() {
    this.props.getIncomeMethods();
    this.props.getStockArticles();
    this.props.getTransactionDocs(this.props.getTransactionsModal);
    this.props.getWarehouses();
  }

  componentDidCatch(error, errorInfo) {
    SentryService.sendError(error, { errorInfo });
  }

  componentWillUnmount() {
    this.props.doReset();
  }

  /*********************************** RENDER **************************************/
  render() {
    const { routes } = this.props;

    return (
      <WrapperView withoutSubToolbarHeight>
        {routes.map((route, i) => {
          return (
            <Route
              key={i}
              path={route.path}
              render={(oProps) => (
                <route.component
                  {...oProps}
                  {...this.props}
                  routes={route.routes}
                />
              )}
            />
          );
        })}
        <ModalWarehouse />
      </WrapperView>
    );
  }
}

const mapStateToProps = (state) => ({
  ...selectWarehouseState(state),
  profile: selectProfile(state),
  transactionRows: selectTransactionRows(state),
});

const actions = {
  //WAREHOUSE
  ...warehouseActions,
  //DASHBOARD
  sendToast,
  //API
  mutate1ObjectInApi,
  getIncomeMethods,
  getWarehouses,
  getSerieHistory,
};

export default connect(mapStateToProps, actions)(Warehouse);
