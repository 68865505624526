import styled, { css } from "styled-components";

const Subtitle = styled.span`
  ${(props) =>
    props.ellipsis &&
    css`
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}
  text-align: ${(props) => props.textAlign};
  font-family: ${(props) => props.theme.subtitle.fontFamily};
  font-style: ${(props) => props.theme.subtitle.fontStyle};
  font-weight: ${(props) => props.theme.subtitle.fontWeight};
  font-size: ${(props) => props.theme.subtitle.fontSize};
  letter-spacing: ${(props) => props.theme.subtitle.letterSpacing};
  color: ${(props) =>
    props.light ? props.theme.colors.text.medium : props.theme.subtitle.color};
  mix-blend-mode: ${(props) => props.theme.subtitle.mixBlendMode};
  //Props
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  ${({ warning }) =>
    warning &&
    css`
      color: ${({ theme }) => theme.colors.error.color};
    `}
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
`;

export default Subtitle;
