//Libs
import React from "react";
//Components
import { Empty } from "components/components";
import { VirtualizedList } from "components/containers/Utils";

export default function SerieHistoryModal({ rect, serieHistory, sendToast }) {
  const { loading, data } = serieHistory;
  const width = React.useMemo(() => rect?.width - 20, [rect]);

  return (
    <div className="container-info-orders-income">
      {!loading && data.length === 0 ? (
        <Empty />
      ) : (
        <VirtualizedList
          cardId="serieHistoryCard"
          listStyle={{
            width,
            height: document.documentElement.clientHeight * 0.66,
          }}
          cardStyle={{
            width: width - 10,
            height: 130,
          }}
          rowHeight={145}
          rows={data}
          isSpinning={loading}
          customProps={{
            sendToast,
          }}
        />
      )}
    </div>
  );
}
