// Libs
import React from "react";
import { Card } from "antd";
import { useTheme } from "styled-components";
// Utils
import ConsumeInventoryUtils from "./ConsumeInventoryUtils";
import ConsumeIntegrationUtils from "./IntegrationUtils";
// Components
import StatusIcon from "./StatusIcon";
import { Wrapper, Title, Caption, InputNumber } from "components/components";
import InitialFinalInput from "./InitialFinalInput";
import ArticleTitle from "./ArticleTitle";

const { Meta } = Card;

const SerializedTitle = ({
  inventoryItem,
  savedConsumedItem,
  currentValue,
  onChange,
  mutate1ObjectInApi,
  getSerieHistory,
}) => {
  return (
    <Wrapper
      padding="0"
      width="300px"
      height="60px"
      flexDirection="column"
      alignItems="stretch"
    >
      <Title
        ellipsis
        margin="0 20px 0 0"
        width="250px"
        onClick={() => {
          mutate1ObjectInApi("serieHistory", { isOpen: true });
          getSerieHistory(inventoryItem.serie);
        }}
      >
        {inventoryItem.serie}
      </Title>
      <Wrapper width="280px" padding="0" justifyContent="space-between">
        <Wrapper padding="0" flexDirection="column" alignItems="stretch">
          <Caption maxWidth="250px">{inventoryItem.item_name}</Caption>
          <Caption>ID: {inventoryItem.item_code}</Caption>
        </Wrapper>

        <StatusIcon
          savedConsumedItem={savedConsumedItem}
          currentValue={currentValue}
          isSerialized={true}
          onAdd={() => onChange(inventoryItem.serie)}
          onDelete={() => onChange(null)}
        />
      </Wrapper>
    </Wrapper>
  );
};

const ConsumeInventoryItemCard = (inventoryItem) => {
  const theme = useTheme();
  const {
    otdId,
    order,
    ownerId,
    entityId,
    consumedItems,
    controlledItemValues,
    onChangeState,
    mutate1ObjectInApi,
    getSerieHistory,
  } = inventoryItem.customProps;

  const {
    consumedItemProps,
    savedConsumedItemIdx,
    savedConsumedItem,
    consumedItemValue,
    consumedInitialAmount,
    consumedFinalAmount,
  } = ConsumeInventoryUtils.getConsumedItem({
    otdId,
    orderId: order.order_id,
    ownerId,
    entityId,
    inventoryItem,
    consumedItems,
  });

  const { controlledAmount, controlledInitialAmount, controlledFinalAmount } =
    ConsumeIntegrationUtils.getControlledConsumedItemValues(
      controlledItemValues,
      {
        itemId: consumedItemProps.itemId,
        serieId: consumedItemProps.serieId,
      }
    );

  const [currentValue, currentInitialAmount, currentFinalAmount] =
    React.useMemo(() => {
      if (controlledAmount !== undefined)
        return [
          controlledAmount,
          controlledInitialAmount,
          controlledFinalAmount,
        ];
      return [consumedItemValue, consumedInitialAmount, consumedFinalAmount];
    }, [
      controlledAmount,
      controlledInitialAmount,
      controlledFinalAmount,
      consumedItemValue,
      consumedInitialAmount,
      consumedFinalAmount,
    ]);

  //On Change
  const onChange = (value, initialAmount, finalAmount) =>
    onChangeState({
      value,
      initialAmount,
      finalAmount,
      consumedItemProps,
      savedConsumedItemIdx,
      savedConsumedItem,
    });

  return (
    <Meta
      id="meta-card-container"
      style={{
        background: !!currentValue ? theme.colors.selected[12] : undefined,
      }}
      title={
        !ConsumeInventoryUtils.checkInventoryItemIsSerialized(inventoryItem) ? (
          <ArticleTitle inventoryItem={inventoryItem} />
        ) : (
          <SerializedTitle
            inventoryItem={inventoryItem}
            savedConsumedItem={savedConsumedItem}
            currentValue={currentValue}
            onChange={onChange}
            mutate1ObjectInApi={mutate1ObjectInApi}
            getSerieHistory={getSerieHistory}
          />
        )
      }
      description={
        !ConsumeInventoryUtils.checkInventoryItemIsSerialized(
          inventoryItem
        ) && (
          <Wrapper padding="0">
            <Wrapper
              padding="0 10px"
              margin="0 10px 0 0"
              width="250px"
              height="50px"
              flexDirection="column"
              alignItems="stretch"
            >
              <Wrapper padding="0" justifyContent="space-between">
                <Caption>Disponible: {inventoryItem.amount}</Caption>
                <Caption>Tránsito: {inventoryItem.amount_transit}</Caption>
              </Wrapper>
              {inventoryItem.is_initial_final ? (
                <InitialFinalInput
                  amount={inventoryItem.amount}
                  amountTransit={inventoryItem.amount_transit}
                  initialAmount={currentInitialAmount || ""}
                  finalAmount={currentFinalAmount || ""}
                  step={ConsumeInventoryUtils.getInputProp({
                    inventoryItem,
                    prop: "step",
                  })}
                  min={ConsumeInventoryUtils.getInputProp({
                    inventoryItem,
                    prop: "min",
                  })}
                  isDecimal={ConsumeInventoryUtils.getInputProp({
                    inventoryItem,
                    prop: "decimal",
                  })}
                  onChange={onChange}
                />
              ) : (
                <InputNumber
                  placeholder="Cantidad"
                  step={ConsumeInventoryUtils.getInputProp({
                    inventoryItem,
                    prop: "step",
                  })}
                  min={ConsumeInventoryUtils.getInputProp({
                    inventoryItem,
                    prop: "min",
                  })}
                  value={currentValue || ""}
                  onChange={onChange}
                  parser={
                    ConsumeInventoryUtils.getInputProp({
                      inventoryItem,
                      prop: "decimal",
                    })
                      ? undefined
                      : (value) => ConsumeInventoryUtils.getIntegerValue(value)
                  }
                />
              )}
            </Wrapper>

            <StatusIcon
              savedConsumedItem={savedConsumedItem}
              currentValue={currentValue}
            />
          </Wrapper>
        )
      }
    />
  );
};

export default React.memo(ConsumeInventoryItemCard);
