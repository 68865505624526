// Libs
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
// Utils
import { lsHandler } from "utils/libs";
// Actions
import { mutateDirectProps } from "components/containers/Dashboard/actions";
// Selectors
import { selectNavigatorInstance } from "components/containers/Dashboard/selectors";

const useNavigatorInstance = () => {
  const dispatch = useDispatch();
  const navigatorInstance = useSelector(selectNavigatorInstance);

  const syncNavigatorInstance = () => {
    if (navigatorInstance) return;

    // Use saved navigator instance
    const savedNavigatorInstance = lsHandler.getNavigatorInstance();
    if (savedNavigatorInstance) {
      dispatch(
        mutateDirectProps({ navigatorInstance: savedNavigatorInstance })
      );
      return;
    }

    // Create new navigator instance
    const newNavigatorInstance = uuidv4();
    lsHandler.saveNavigatorInstance(newNavigatorInstance);
    dispatch(mutateDirectProps({ navigatorInstance: newNavigatorInstance }));
  };

  useEffect(() => {
    syncNavigatorInstance();
  }, [navigatorInstance]);

  return { navigatorInstance };
};
export default useNavigatorInstance;
