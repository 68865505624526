//Libs
import React from "react";
import { connect } from "react-redux";
//Actions
import {
  mutate1ObjectInStockSeriesManage,
  getMyFamilies,
  getStockSeries,
} from "../../actions";
import {
  mutate1Object as mutate1ObjectInApi,
  getSerieHistory,
} from "store/api/actions";
//Selectors
import {
  selectStockArticles,
  selectStockFamilies,
  selectStockSeries,
} from "../../selectors";
//Components
import { Wrapper, Tabs } from "components/components";
import Articles from "./Articles";
import Serialized from "./Serialized";

const { TabWrap, TabPane } = Tabs;

function Stock({
  //Props
  searchInData,
  stockArticlesManage,
  stockSeriesManage,
  stockArticles,
  stockFamilies,
  stockSeries,
  //Actions
  mutate1Object,
  mutate1ObjectInStockSeriesManage,
  getMyFamilies,
  getStockSeries,
  getSerieHistory,
  mutate1ObjectInApi,
}) {
  return (
    <Wrapper padding="0" className="animated fadeIn">
      <TabWrap tabPosition="top" smallTabs>
        <TabPane tab="Materiales" key="1">
          <Articles
            searchInData={searchInData}
            stockArticles={stockArticles}
            stockArticlesManage={stockArticlesManage}
          />
        </TabPane>
        <TabPane tab="Equipos" key="2">
          <Serialized
            searchInData={searchInData}
            stockSeriesManage={stockSeriesManage}
            stockSeries={stockSeries}
            stockFamilies={stockFamilies}
            getMyFamilies={getMyFamilies}
            mutate1Object={mutate1Object}
            mutate1ObjectInStockSeriesManage={mutate1ObjectInStockSeriesManage}
            getStockSeries={getStockSeries}
            getSerieHistory={getSerieHistory}
            mutate1ObjectInApi={mutate1ObjectInApi}
          />
        </TabPane>
      </TabWrap>
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  stockArticles: selectStockArticles(state),
  stockFamilies: selectStockFamilies(state),
  stockSeries: selectStockSeries(state),
});

const actions = {
  mutate1ObjectInStockSeriesManage,
  getMyFamilies,
  getStockSeries,
  getSerieHistory,
  mutate1ObjectInApi,
};

export default connect(mapStateToProps, actions)(Stock);
