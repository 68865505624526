//Libs
import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
//Utils
import RouteWithSubRoutes from "../utils/routes/RouteWithSubRoutes";
//Global and multiuse Styles
import "animate.css/animate.min.css";
import "onsenui/css/onsenui.min.css";
import "onsenui/css/onsen-css-components.min.css";
import "react-virtualized/styles.css";
import "antd/dist/antd.css";
import "moment/locale/es";
import "dayjs/locale/es";
import "./Badges.css";

const App = ({ routes, history }) => (
  <div>
    {routes.map((route, i) => (
      <RouteWithSubRoutes key={i} {...route} />
    ))}

    {/* Automatic redirect to [MapView] component */}
    {history.location.pathname === "/" ||
    history.location.pathname === "/dashboard" ? (
      <Redirect to="/dashboard/orders/manage" />
    ) : null}
  </div>
);

App.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default App;
