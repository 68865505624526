// Libs
import React from "react";
// Services
import MQTTService from "services/mqttService";
// Realtime context
import { RealtimeConsumer } from "../RealtimeContext";
// Hooks
import { usePrevious } from "hooks";

//Single subscriber to wrap a children
const Subscriber = ({
  //Realtime context
  children,
  isConnected,
  payloadMessage,
  mqttSub,
  mqttUnSub,
  mqttPublish,
  //Props
  subscriber,
  topic, //Topic relative path
  qos,
}) => {
  const [payload, setPayload] = React.useState(null);

  //Subscriptions/Unsubscriptions
  const prevAbsoluteTopic = usePrevious(
    MQTTService.setTopic(topic, subscriber)
  );

  //Subscribe/Unsubscribe
  React.useEffect(() => {
    const absoluteTopic = MQTTService.setTopic(topic, subscriber);
    if (prevAbsoluteTopic !== absoluteTopic) {
      //Unsubscribe
      mqttUnSub(topic, subscriber);
      //Subscribe
      mqttSub(topic, subscriber, { qos });
    } else {
      //Subscribe
      mqttSub(topic, subscriber, { qos });
    }

    return () => {
      mqttUnSub(topic, subscriber);
    };
  }, [topic, subscriber]);

  //Receive new payload message
  React.useEffect(() => {
    if (!payloadMessage) return;
    const { action, message } = payloadMessage;
    if (action !== topic) return;

    setPayload(message);
  }, [payloadMessage, topic, subscriber]);

  return children({
    payload,
    mqttPublish,
  });
};

const RealtimeSubscriber = ({ subscriber, topic, qos, children }) => (
  <RealtimeConsumer>
    {({ isConnected, payload, mqttSub, mqttUnSub, mqttPublish }) => (
      <Subscriber
        isConnected={isConnected}
        payloadMessage={payload}
        mqttSub={mqttSub}
        mqttUnSub={mqttUnSub}
        mqttPublish={mqttPublish}
        subscriber={subscriber}
        topic={topic}
        qos={qos}
      >
        {children}
      </Subscriber>
    )}
  </RealtimeConsumer>
);

export default RealtimeSubscriber;
