//Libs
import React from "react";
import { connect } from "react-redux";
import moment from "moment";
//Services
import { SentryService } from "services";
//Utils
import GENERAL from "utils/constants/general";
import WithAuth from "utils/libs/auth/WithAuth";
import RouteWithSubRoutes from "utils/routes/RouteWithSubRoutes";
import { lsHandler } from "utils/libs";
//Actions
import * as dashboardActions from "./actions";
import * as loginActions from "../Login/actions";
import * as apiActions from "store/api/actions";
import * as orderActions from "../Orders/actions";
import * as paymentActions from "../Payments/actions";
import * as warehouseActions from "../Warehouse/actions";
import {
  getCurrentConsumedItems,
  mutateConsumeInventoryControl,
} from "components/components/ConsumeInventory/actions";
import {
  mutate1Object as mutate1ObjectInCollector,
  updateCollectorRequiredValidation,
} from "components/components/Collector/actions";
import {
  getAuditedTransactionDocs,
  doReset as doResetCollector,
  resetAuditedTransactionDocs,
} from "components/components/Collector/actions";
//Context
import { RealtimeSubscriber, RealtimeUtils } from "context/RealtimeContext";
import { CoordProvider } from "components/CoordManager";
//Components
import { Splitter, Page, SplitterContent } from "react-onsenui";
import { notification, Spin } from "antd";
import { Toolbar, SubToolbar, ShowErrors } from "./subs";
import {
  CollectorUtils,
  OrderInformation,
  ConsumeInventoryUtils,
  AutoSyncOfflineData,
} from "components/components";
import { DashboardMenu } from "./components";
//Styles
import "./index.css";

const now = moment();
const { ENV } = GENERAL;

notification.config({
  placement: "bottomRight",
  bottom: 0,
});

class Dashboard extends React.Component {
  constructor(...props) {
    super(...props);

    this.selectNotification = this.selectNotification.bind(this);
    this.loadLocalStorage = this.loadLocalStorage.bind(this);
    this.handleOnClickOrderInformationTab =
      this.handleOnClickOrderInformationTab.bind(this);
  }

  /*********************************** LIFECYCLES **************************************/
  componentDidMount() {
    const { profile, reportMe, getMyProfile, getOrganizationUsers } =
      this.props;

    //Load Local Storage
    this.loadLocalStorage();
    //Report me
    reportMe(profile.user.id, profile.user.name);
    //Get my profile
    getMyProfile();
    //Get users
    setTimeout(() => getOrganizationUsers(), 600);
  }

  componentWillUnmount() {
    this.props.doReset();
  }

  componentDidCatch(error, errorInfo) {
    SentryService.sendError(error, { errorInfo });
  }

  /*********************************** FUNCTIONS **************************************/
  selectNotification(
    user_notification_id,
    dispatchable_id,
    dispatchable_type,
    seen
  ) {
    const {
        getOrder,
        mutate1Object,
        history,
        markNotificationSeen,
        mutate1ObjectInWarehouse,
        getTransactionDocs,
        sendToast,
        mutate1ObjectInOrders,
      } = this.props,
      orders = this.props.orders.control.orders;

    switch (dispatchable_type) {
      case "Orders":
        const order = orders.find((ord) => ord.order_id === dispatchable_id);
        if (!order) getOrder(dispatchable_id);
        mutate1ObjectInOrders("getOrderInformationModal", {
          order_id: Number(dispatchable_id),
          isOpen: true,
        });
        history.push(ENV.ROUTES.PATHS.ORDERS_MANAGE);
        break;
      case "WarehouseTransactionDocs":
        mutate1ObjectInWarehouse("transactionsManage", {
          docId: dispatchable_id,
        });
        mutate1ObjectInWarehouse("getTransactionsModal", {
          mode: "document",
          docNumber: dispatchable_id,
          fromDate: now,
          toDate: now,
        });
        getTransactionDocs({
          mode: "document",
          docNumber: dispatchable_id,
          fromDate: now,
          toDate: now,
        });
        // getItemsTransactionDocs(dispatchable_id);
        history.push(ENV.ROUTES.PATHS.WAREHOUSE_TRANSACTIONS_MANAGE);
        break;
      default:
        sendToast({
          description: "Este tipo de notificación no tiene una acción asignada",
          type: "error",
        });
        break;
    }

    if (!seen) markNotificationSeen(user_notification_id);
    mutate1Object("notificationControl", { show: false });
  }

  loadLocalStorage() {
    const { mutateDirectPropsInApi, setMyProfile } = this.props;

    setMyProfile(lsHandler.get("synapse_profile"));
    mutateDirectPropsInApi({ gains: lsHandler.get("synapse_gains") });
  }

  handleOnClickOrderInformationTab({ order, wtd, activeKey, activeTab }) {
    const orderId = order.order_id;
    if (activeTab !== activeKey) {
      if (activeKey === "3") {
        this.props.getCurrentConsumedItems(orderId);
        //TODO: DEPRECATED. Se debe llamar al hook:
        //const initializeCollectorEcosystem = useInitializeCollectorEcosystem();
        //Y ejecutar initializeCollectorEcosystem({orderId})
        //Cuando esto se pueda mover a un functional component
        CollectorUtils.enterToCollectorEcosystem({
          orderId,
          mutate1ObjectInCollector: this.props.mutate1ObjectInCollector,
          updateCollectorRequiredValidation:
            this.props.updateCollectorRequiredValidation,
        });
        this.props.mutateConsumeInventoryControl({
          editableInventory: ConsumeInventoryUtils.checkIsEditableInventory(
            order,
            wtd
          ),
        });
      }
      if (activeKey !== "5" && activeKey !== "3") {
        CollectorUtils.leftFromCollectorEcosystem(this.props.doResetCollector);
        this.props.resetAuditedTransactionDocs();
      }
      this.props.mutate1ObjectInOrders("getOrderInformationModal", {
        activeTab: activeKey,
        viewComments: false,
      });
    }
    //History
    if (activeKey === "1") this.props.getOrder(orderId);
    if (activeKey === "2") this.props.getOrderHistory(orderId);
    //Inventory
    else if (activeKey === "3") this.props.getBillingMaterials(orderId);
    //Rep.Photograpy
    else if (activeKey === "4") this.props.getPhotoReport(orderId);
    //Reviews
    else if (activeKey === "5") {
      this.props.getAuditedTransactionDocs(orderId);
    }

    // else if (activeKey === '5') getExpedient(order_id); //Expedient
  }

  /*********************************** JSX RENDERS **************************************/
  render() {
    const {
      //Login
      profile,
      //Dashboard
      showNavBar,
      linked,
      mutateDirectProps,
      mutate1Object,
      sendToast,
      showErrors,
      //Orders
      orders,
      mutate1ObjectInOrders,
      getOrders,
      makeInvoicePersistInIDB,
      resetInvoice,
      autoSyncPhotoReports,
      transferPhotoReport,
      //Payments
      payments,
      mutate1ObjectInPayments,
      //Warehouse
      warehouse,
      mutate1ObjectInWarehouse,
      getStockArticles,
      getStockSeries,
      resetCreateTransaction,
      getTransactionDocs,
      getItemsTransactionDocs,
      sendTransferTransaction,
      getInventoryToTransfer,
      //Api
      api,
      getInventoryToInvoice,
      getInventoryToUninstall,
      getPaymentResume,
      getPayments,
      //Globals
      history,
      routes,
    } = this.props;

    return (
      <Splitter className="splitter-container animated fadeIn">
        <DashboardMenu routes={routes} />

        {/* CONTENT SIDE PAGE */}
        <SplitterContent>
          <Page
            className="subpages-container"
            renderToolbar={() => (
              <Toolbar
                //General
                history={history}
                //Dashboard
                showNavBar={showNavBar}
                mutateDirectProps={mutateDirectProps}
                selectNotification={this.selectNotification}
                //Orders
                orders={orders}
                mutate1ObjectInOrders={mutate1ObjectInOrders}
                makeInvoicePersistInIDB={makeInvoicePersistInIDB}
                resetInvoice={resetInvoice}
                autoSyncPhotoReports={autoSyncPhotoReports}
                getOrders={getOrders}
                //Payments
                payments={payments}
                mutate1ObjectInPayments={mutate1ObjectInPayments}
                //Warehouse
                warehouse={warehouse}
                mutate1ObjectInWarehouse={mutate1ObjectInWarehouse}
                resetCreateTransaction={resetCreateTransaction}
              />
            )}
          >
            <section className="section-container">
              <Spin
                spinning={orders.control.synchronizingPhotos}
                size="large"
                delay={50}
                tip={"Sincronizando... Espere por favor"}
              >
                <SubToolbar
                  //General
                  history={history}
                  profile={profile}
                  //Api
                  api={api}
                  getInventoryToInvoice={getInventoryToInvoice}
                  getInventoryToUninstall={getInventoryToUninstall}
                  //Dashboard
                  linked={linked}
                  sendToast={sendToast}
                  //Orders
                  orders={orders}
                  mutate1ObjectInOrders={mutate1ObjectInOrders}
                  getOrders={getOrders}
                  makeInvoicePersistInIDB={makeInvoicePersistInIDB}
                  transferPhotoReport={transferPhotoReport}
                  //Payments
                  payments={payments}
                  mutate1ObjectInPayments={mutate1ObjectInPayments}
                  getPaymentResume={getPaymentResume}
                  getPayments={getPayments}
                  //Warehouse
                  warehouse={warehouse}
                  mutate1ObjectInWarehouse={mutate1ObjectInWarehouse}
                  getStockArticles={getStockArticles}
                  getStockSeries={getStockSeries}
                  getTransactionDocs={getTransactionDocs}
                  getItemsTransactionDocs={getItemsTransactionDocs}
                  sendTransferTransaction={sendTransferTransaction}
                  getInventoryToTransfer={getInventoryToTransfer}
                />

                {routes.map((route, i) => (
                  <RouteWithSubRoutes
                    key={i}
                    {...route}
                    handleOnClickOrderInformationTab={
                      this.handleOnClickOrderInformationTab
                    }
                  />
                ))}
              </Spin>
            </section>
          </Page>
        </SplitterContent>
        {/* Show Errors Drawer */}
        <ShowErrors showErrors={showErrors} mutate1Object={mutate1Object} />
        <AutoSyncOfflineData />

        {/* GET ORDER INFORMATION */}
        <OrderInformation
          handleOnClickOrderInformationTab={
            this.handleOnClickOrderInformationTab
          }
        />
      </Splitter>
    );
  }
}

/*********************************** PROPTYPES **************************************/

const DashboardWrappers = (props) => (
  <RealtimeSubscriber>
    {({ mqttPublish }) => (
      <CoordProvider
        connectedLink={props.linked?.connected}
        timeout={
          props.profile?.user?.settings?.coords?.timeout ||
          props.profile?.entity?.settings?.coords?.timeout ||
          props.profile?.organization?.settings?.coords?.timeout
        }
        syncDelay={
          props.profile?.user?.settings?.coords?.syncDelay ||
          props.profile?.entity?.settings?.coords?.syncDelay ||
          props.profile?.organization?.settings?.coords?.syncDelay
        }
        onDisconnectedLink={() =>
          mqttPublish(
            "currentGeolocation",
            RealtimeUtils.getMyProfileSubscriber(props.profile),
            {
              userId: props.profile?.user?.id,
              connected: false,
              coords: null,
            }
          )
        }
        onSyncCoords={(coords) => {
          //Send new coords to mqtt
          mqttPublish(
            "currentGeolocation",
            RealtimeUtils.getMyProfileSubscriber(props.profile),
            {
              userId: props.profile?.user?.id,
              connected: true,
              coords,
            }
          );
        }}
        errorHandler={SentryService.sendError}
      >
        <Dashboard {...props} mqttPublish={mqttPublish} />
      </CoordProvider>
    )}
  </RealtimeSubscriber>
);

const actions = {
  //DASHBOARD
  ...dashboardActions,
  //LOGIN
  getMyProfile: loginActions.getMyProfile,
  setMyProfile: loginActions.setMyProfile,
  doLogout: loginActions.doLogout,
  //API
  mutateDirectPropsInApi: apiActions.mutateDirectProps,
  mutate1ObjectInApi: apiActions.mutate1Object,
  getInventoryToInvoice: apiActions.getInventoryToInvoice,
  getInventoryToUninstall: apiActions.getInventoryToUninstall,
  getPaymentResume: apiActions.getPaymentResume,
  getPayments: apiActions.getPayments,
  //USERS
  getOrganizationUsers: apiActions.getOrganizationUsers,
  //ORDERS
  mutate1ObjectInOrders: orderActions.mutate1Object,
  getOrders: orderActions.getOrders,
  getOrder: orderActions.getOrder,
  makeInvoicePersistInIDB: orderActions.makeInvoicePersistInIDB,
  resetInvoice: orderActions.resetInvoice,
  autoSyncPhotoReports: orderActions.autoSyncPhotoReports,
  transferPhotoReport: orderActions.transferPhotoReport,
  getOrderHistory: orderActions.getOrderHistory,
  getBillingMaterials: orderActions.getBillingMaterials,
  getPhotoReport: orderActions.getPhotoReport,
  //PAYMENTS
  mutate1ObjectInPayments: paymentActions.mutate1Object,
  //WAREHOUSE
  mutate1ObjectInWarehouse: warehouseActions.mutate1Object,
  getStockArticles: warehouseActions.getStockArticles,
  getStockSeries: warehouseActions.getStockSeries,
  resetCreateTransaction: warehouseActions.resetCreateTransaction,
  getTransactionDocs: warehouseActions.getTransactionDocs,
  getItemsTransactionDocs: warehouseActions.getItemsTransactionDocs,
  getInventoryToTransfer: warehouseActions.getInventoryToTransfer,
  sendTransferTransaction: warehouseActions.sendTransferTransaction,
  //COLLECTOR
  getAuditedTransactionDocs,
  doResetCollector,
  resetAuditedTransactionDocs,
  //MANAGE INVENTORY
  getCurrentConsumedItems,
  mutate1ObjectInCollector,
  updateCollectorRequiredValidation,
  mutateConsumeInventoryControl,
};

export default WithAuth(
  connect(
    (state) => ({
      ...state.dashboard,
      //-------------------------------------EXTERNAL PROPS------------------------------------
      //LOGIN
      profile: state.login.profile,
      //ORDERS
      orders: {
        control: {
          ...state.orders.control,
        },
        invoice: {
          ...state.orders.invoice,
        },
        importPhotoReport: {
          ...state.orders.importPhotoReport,
        },
        getOrdersModal: {
          ...state.orders.getOrdersModal,
        },
        reviewManage: {
          ...state.orders.reviewManage,
        },
      },
      //PAYMENTS
      payments: {
        paymentsManage: {
          ...state.payments.paymentsManage,
        },
        getPaymentsModal: {
          ...state.payments.getPaymentsModal,
        },
      },
      //WAREHOUSE
      warehouse: {
        searchInData: {
          ...state.warehouse.searchInData,
        },
        stockArticlesManage: {
          ...state.warehouse.stockArticlesManage,
        },
        stockSeriesManage: {
          ...state.warehouse.stockSeriesManage,
        },
        transactionsManage: {
          ...state.warehouse.transactionsManage,
        },
        inventoryToTransferManage: {
          ...state.warehouse.inventoryToTransferManage,
        },
        itemsTransactionDoc: {
          ...state.warehouse.itemsTransactionDoc,
        },
        getTransactionsModal: {
          ...state.warehouse.getTransactionsModal,
        },
        transactions: {
          ...state.warehouse.transactions,
        },
        createTransaction: {
          ...state.warehouse.createTransaction,
        },
      },
      //APIDATA
      api: {
        templates: state.api.templates,
        warehouse: {
          ...state.api.warehouse,
        },
        payments: {
          ...state.api.payments,
        },
        gains: {
          ...state.api.gains,
        },
      },
    }),
    actions
  )(DashboardWrappers)
);
