//Libs
import { createSelector } from "reselect";
import Immutable from "immutable";
//Utils
import ModuleUtils from "./utils";

const selectModules = (state) => state.modules;

export const selectModulesIsFetching = createSelector(
  [selectModules],
  ({ accessControl }) => accessControl.isFetching
);
export const selectAccessControl = createSelector(
  [selectModules],
  ({ accessControl }) => ({
    ...accessControl,
    modules: Immutable.List(accessControl.modules).toJS(),
  })
);
export const selectAccessControlState = createSelector(
  [selectAccessControl],
  ({ state }) => state
);
export const selectSelectedModuleId = createSelector(
  [selectAccessControl],
  ({ selectedModuleId }) => selectedModuleId
);
export const selectSelectedSubModuleId = createSelector(
	[selectAccessControl],
	({ selectedSubModuleId }) => selectedSubModuleId,
);
export const selectAccessControlModules = createSelector(
  [selectAccessControl],
  ({ modules }) => ModuleUtils.getModules(modules)
);
export const selectMarketplace = createSelector(
  [selectModules],
  ({ marketplace }) => ({
    ...marketplace,
    banners: {
      ...marketplace.banners,
      data: Immutable.List(marketplace.banners.data).toJS(),
    },
    modules: {
      ...marketplace.modules,
      data: Immutable.List(marketplace.modules.data).toJS(),
    },
    items: {
      ...marketplace.items,
      data: Immutable.List(marketplace.items.data).toJS(),
    },
  })
);
export const selectMarketplaceBanners = createSelector(
  [selectMarketplace],
  (marketplace) => marketplace.banners
);
export const selectMarketplaceModules = createSelector(
  [selectMarketplace],
  (marketplace) => marketplace.modules
);
export const selectMarketplaceItems = createSelector(
  [selectMarketplace],
  (marketplace) => marketplace.items
);
