import styled from "styled-components";

const Image = styled.img`
  position: ${(props) => props.position || props.theme.image.position};
  top: ${(props) => props.top || props.theme.image.top};
  left: ${(props) => props.left || props.theme.image.left};
  opacity: ${(props) => props.opacity || props.theme.image.opacity};
  width: ${(props) => (props.width ? props.width : props.theme.image.width)};
  max-width: ${(props) =>
    props.maxWidth ? props.maxWidth : props.theme.image.maxWidth};
  height: ${(props) =>
    props.height ? props.height : props.theme.image.height};
  object-fit: ${(props) =>
    props.objectFit ? props.objectFit : props.theme.image.objectFit};
`;

export default Image;
