//Libs
import React from "react";
import { useTheme } from "styled-components";
//Components
import { Toolbar } from "react-onsenui";
import ToolbarNotifications from "../Notifications";
import FormControl from "@material-ui/core/FormControl";
import { BackComponent } from "..";
import {
  ToolbarTitle,
  SearchIcon,
  TextField,
  CloseIcon,
  LogoIcon,
} from "components/components";

const { Input } = TextField;

export function TransactionsItems({
  title,
  itemsTransactionDoc,
  mutate1ObjectInWarehouse,
  selectNotification,
  handleOnClick,
  isTransactionItemLoading,
  transactionsManage,
  getItemsTransactionDocs,
}) {
  return (
    <Toolbar>
      {/* LEFT */}
      <div className="left toolbar__left toolbar--material__left hamburger-button-container">
        {/* Back Button */}
        <BackComponent handleOnClick={handleOnClick} />
      </div>

      {/* CENTER */}
      <div className="center toolbar__center toolbar__title toolbar--material__center">
        {/* Title */}
        <ToolbarTitle>{title}</ToolbarTitle>
      </div>

      {/* RIGHT */}
      <div className="right toolbar__right toolbar--material__right">
        <LogoIcon
          button
          spin={isTransactionItemLoading || undefined}
          onClick={() =>
            !isTransactionItemLoading &&
            getItemsTransactionDocs(transactionsManage.docId)
          }
        />

        {/* Search Button */}
        <SearchIcon
          onClick={() =>
            mutate1ObjectInWarehouse("itemsTransactionDoc", {
              searchInData: {
                ...itemsTransactionDoc.searchInData,
                renderToolbar: true,
              },
            })
          }
        />

        {/* Notifications */}
        <ToolbarNotifications selectNotification={selectNotification} />
      </div>
    </Toolbar>
  );
}

export function SearchTransactionsItems({
  mutate1ObjectInWarehouse,
  searchInData,
}) {
  const theme = useTheme();

  return (
    <Toolbar
      style={
        searchInData.renderToolbar
          ? { backgroundColor: theme.colors.background.medium }
          : undefined
      }
    >
      {/* FILTER ORDER */}
      <FormControl
        id="toolbar-search-order-container"
        className="center animated fadeIn"
      >
        <Input
          id="toolbar-search-order-input"
          height="32px"
          autoFocus
          name="data"
          placeholder="Buscar en transacción"
          value={searchInData.data}
          onChange={(e) =>
            mutate1ObjectInWarehouse("itemsTransactionDoc", {
              searchInData: {
                ...searchInData,
                [e.target.name]: e.target.value,
              },
            })
          }
          prefix={<SearchIcon small onlyIcon />}
          suffix={
            <CloseIcon
              small
              onlyIcon
              onClick={() =>
                mutate1ObjectInWarehouse("itemsTransactionDoc", {
                  searchInData: {
                    ...searchInData,
                    renderToolbar: false,
                    data: "",
                    searchBy: "all",
                  },
                })
              }
            />
          }
        />
      </FormControl>
    </Toolbar>
  );
}
