export default Object.freeze({
  //New version
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.15px",
    /* Black — High Emphasis */
    color: "white",
    mixBlendMode: "normal",
  },
  caption: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    /* Black — Medium Emphasis */
    color: "white",
    mixBlendMode: "normal",
  },
  ////////////////////////////// Old version //////////////////////////////////////
  fonts: {
    family: "Raleway",
    size: {
      default: "15px",
      xs: "11px",
      s: "12px",
      m: "13px",
      l: "14px",
      xl: "15px",
      s16: "16px",
      s18: "18px",
    },
    colors: {
      default: "rgba(255, 255, 255, 0.54)",
      primary: "white",
      secondary: "gray",
      warn: "orangered",
      concept: "lightblue",
      value: "lightgray",
      emphasis_1: "burlywood",
    },
  },
  colors: {
    primary: "#3f3f49",
    secondary: "transparent", //"#2c2c36",
    selected: "rgba(0,0,0,.2)",
  },
  backgroundIMG: {
    // 135 Slick Carbon
    default:
      "linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.25) 200%)",
    backgroundBlendMode: "multiply",
  },
  toolbar: {
    borders: {
      bottom: "1px solid #2c2c36",
    },
  },
  wrapperView: {
    width: "100%",
    position: "absolute",
    top: "48px",
    withoutSubToolbarHeight: {
      // top: "56px",
      top: "0",
    },
  },
  wrapper: {
    position: "unset",
    width: "auto",
    height: "auto",
    maxWidth: "unset",
    maxHeight: "unset",
    margin: "0px",
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "unset",
    backgroundColor: "transparent",
    borderRadius: "unset",
    boxShadow: "unset",
    transition: "unset",
    border: "none",
  },
  grid: {
    margin: 0,
    padding: 0,
    display: "grid",
    position: "static",
    top: 0,
    left: 0,
    width: "auto",
    height: "auto",
    minHeight: 0,
    columns: "none",
    rows: "none",
    gridAutoFlow: "row",
    gap: 0,
    overflowX: "visible",
    overflowY: "visible",
    border: "none",
    zIndex: 0,
  },
  span: {
    width: "auto",
    minWidth: "unset",
    maxWidth: "unset",
    height: "auto",
    maxHeight: "unset",
    margin: 0,
    padding: 0,
    color: "white",
    fontWeight: "unset",
    backgroundColor: "transparent",
    cursor: "unset",
    overflow: "unset",
    hover: {
      color: "unset",
    },
    active: {
      color: "unset",
    },
    textAlign: "left",
  },
  checkbox: {
    margin: "0px",
    color: "rgba(255, 255, 255, 0.6)",
  },
  checkboxGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  button: {
    margin: 0,
    height: "32px",
    padding: "0px 15px",
    color: "unset",
    primary: {
      fontSize: "unset",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.13,
      letterSpacing: "1.25px",
      textAlign: "center",
      backgroundColor: "#1eb980",
      color: "rgba(0, 0, 0, 0.87)",
    },
    secondary: {
      focus: {
        color: "#40a9ff",
        backgroundColor: "#3f3f49",
        borderColor: "#40a9ff",
      },
    },
  },
  iconButton: {
    size: {
      default: {
        height: "48px",
        width: "48px",
        fontSize: "22px",
        padding: "12px",
      },
      s: {
        height: "32px",
        width: "32px",
        fontSize: "14px",
        padding: "9px",
      },
      m: {
        height: "40px",
        width: "40px",
        fontSize: "20px",
        padding: "10px",
      },
      l: {
        height: "48px",
        width: "48px",
        fontSize: "22px",
        padding: "12px",
      },
    },
    margin: 0,
    color: "rgba(255, 255, 255, 0.54)",
    cursor: "pointer",
    borderRadius: "50%",
    hover: {
      backgroundColor: "rgba(0, 0, 0, .08)",
    },
    active: {
      backgroundColor: "rgba(0, 0, 0, .12)",
    },
    transition: "background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    touchAction: "manipulation",
  },
  icon: {
    margin: 0,
    color: "rgba(255, 255, 255, 0.54)",
  },
  link: {
    margin: 0,
    padding: 0,
    color: "white",
    backgroundColor: "transparent",
    hover: {
      color: "lightskyblue",
    },
    active: {
      color: "lightskyblue",
    },
  },
  popover: {
    backgroundColor: "rgba(0,0,0,.85)",
    inner: {
      maxHeight: "none",
      borderRadius: "5px",
      boxShadow: "0 0 4px white",
      overflow: "visible",
    },
  },
  tooltip: {
    backgroundColor: "rgba(0,0,0,.85)",
  },
  collapse: {
    width: "100%",
    padding: 0,
    borders: {
      bottom: "1px solid #3f3f49",
    },
  },
  table: {
    borderRadius: "1px",
    backgroundColor: "rgb(63,63,73)",
    header: {
      margin: 0,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: "1px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.41",
      letterSpacing: "0.25px",
      textTransform: "capitalize",
      color: "rgba(255, 255, 255, 0.6)",
      backgroundColor: "rgb(63,63,73)",
    },
    column: {},
    row: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      borderBottom: "0.4px rgba(224,224,225, 0.7) solid",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.41",
      letterSpacing: "0.25px",
      color: "rgba(255, 255, 255, 0.6)",
      hover: {
        fontWeight: "bold",
      },
      active: {
        fontWeight: "normal",
      },
    },
    cell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "unset",
    },
  },
  spin: {
    width: "100%",
    height: "100%",
    color: "white",
    borderRadius: "20px",
  },
  flag: {
    minWidth: "5px",
    height: "20px",
    backgroundColor: "transparent",
    borderRadius: "50%",
    absolute: {
      position: "absolute",
      left: 0,
    },
  },
  textField: {
    width: "100%",
    margin: 0,
    height: "33px",
    minHeight: "33px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.48,
    letterSpacing: "0.15px",
    color: "rgba(255, 255, 255, 0.6)",
    backgroundColor: "rgba(204, 186, 186, 0.08)",
  },
  radio: {
    group: {
      padding: 0,
      backgroundColor: "#2c2c36",
    },
    item: {
      display: "block",
      margin: "5px 5px",
      color: "white",
      disabled: {
        color: "gray",
      },
    },
    button: {
      display: "inline-block",
    },
  },
  dropdown: {
    button: {},
  },
  menu: {
    height: "auto",
    overflow: "auto",
    color: "white",
    backgroundColor: "transparent",
    borderRight: "none",
    group: {
      color: "gray",
    },
    submenu: {
      backgroundColor: "transparent",
    },
    active: {
      backgroundColor: "#3f3f49",
    },
    item: {
      height: "25px",
      color: "rgba(255, 255, 255, 0.9)",
      backgroundColor: "transparent",
      hover: {
        backgroundColor: "#3f3f49",
      },
      selected: {
        backgroundColor: "#2c2c36",
      },
      disabled: {
        color: "gray",
      },
    },
  },
  tabs: {
    width: "100%",
    height: "auto",
    tabPane: {
      color: "white",
      backgroundColor: "transparent",
    },
  },
  divider: {
    color: "gray",
    margin: "unset",
  },
  steps: {
    title: {
      waiting: {
        color: "gray",
      },
      process: {
        color: "lightskyblue",
      },
      finish: {
        color: "white",
      },
      error: {
        color: "orange",
      },
    },
    icon: {
      waiting: {
        color: "gray",
      },
    },
  },
  select: {
    width: "auto",
    padding: 0,
  },
  chip: {
    color: "springgreen",
    backgroundColor: "darkslategray",
  },
  card: {
    title: {
      color: "mediumaquamarine",
    },
    description: {
      fontSize: "12px",
      color: "mediumaquamarine",
    },
    backgroundColor: "transparent",
    padding: "10px",
    actions: {
      margin: "10px 0 0 0",
      borderTop: "none",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      items: {
        colors: {
          default: "rgba(255, 255, 255, 0.54)",
          primary: "white",
          secondary: "orange",
        },
        borderRight: "1px solid #3f3f49",
      },
    },
  },
  drawer: {
    padding: 0,
    header: {
      fontWeight: "unset",
      color: "white",
      backgroundColor: "#2c2c36",
    },
    content: {
      border: "unset",
      backgroundColor: "#2c2c36",
    },
  },
  avatar: {
    sizes: {
      large: {
        width: 66,
        height: 66,
      },
      medium: {
        width: 50,
        height: 50,
      },
      small: {
        width: 32,
        height: 32,
      },
    },
    border: {
      borderRadius: "50%",
      background1: "rgb(41,38,96)",
      background2:
        "linear-gradient(90deg, rgba(41,38,96,1) 12%, rgba(241,169,109,1) 48%, rgba(0,212,255,1) 100%)",
    },
    skeleton: {
      color: "#202020",
      highlightColor: "#444",
    },
  },
  inputNumber: {
    width: "auto",
  },
  timePicker: {
    width: "auto",
  },
  datePicker: {
    width: "auto",
  },
  image: {
    position: "static",
    top: "auto",
    left: "auto",
    opacity: 1,
    width: "100%",
    maxWidth: "unset",
    height: "100%",
    objectFit: "unset",
  },
  scrollbar: {
    width: "0px",
    height: "0px",
    thumb: {
      background: "#201c29",
      borderRadius: "10px",
      boxShadow:
        "inset 2px 2px 2px hsla(0, 0%, 100%, 0.6), inset -2px -2px 2px rgba(0, 0, 0, 0.25)",
    },
    track: {
      background:
        "linear-gradient(90deg, #201c29, #201c29 1px, #17141d 0, #17141d)",
    },
  },
  grid: {
    margin: 0,
    padding: 0,
    display: "grid",
    position: "static",
    top: 0,
    left: 0,
    width: "auto",
    height: "auto",
    minHeight: 0,
    columns: "none",
    rows: "none",
    gridAutoFlow: "row",
    gap: 0,
    overflowX: "visible",
    overflowY: "visible",
    border: "none",
    zIndex: 0,
  },
});
