class PrivateIntegrationUtils {
  static checkIsInventoryItemsDifferent(p) {
    return (
      Number(p.inventoryItemId) !== Number(p.searchedItemId) ||
      Number(p.inventorySerieId) !== Number(p.searchedSerieId)
    );
  }
  static checkIsInventoryItemsMatch(p) {
    return (
      Number(p.inventoryItemId) === Number(p.searchedItemId) &&
      Number(p.inventorySerieId) === Number(p.searchedSerieId)
    );
  }
}

export default class IntegrationUtils {
  static getControlledConsumedItemFormat(
    itemId,
    serieId,
    amount,
    initialAmount,
    finalAmount
  ) {
    return {
      key: `${itemId}/${serieId || null}`,
      value: `${serieId || null}/${amount || null}/${initialAmount || null}/${
        finalAmount || null
      }`,
    };
  }
  static getSplittedKey(key) {
    let [value1, value2, value3, value4] = key.split("/");
    if (value1 === "null") value1 = null;
    if (value2 === "null") value2 = null;
    if (value3 === "null") value3 = null;
    if (value4 === "null") value4 = null;
    return [value1, value2, value3, value4];
  }
  static checkIsInventoryItem(p) {
    if (!p.inventoryItemId) p.inventoryItemId = null;
    if (!p.inventorySerieId) p.inventorySerieId = null;

    if (p.checking === "isDifferent")
      return PrivateIntegrationUtils.checkIsInventoryItemsDifferent(p);
    if (p.checking === "isEquals")
      return PrivateIntegrationUtils.checkIsInventoryItemsMatch(p);
  }
  static getFilteredInventory({ inventory, filter }) {
    if (!filter?.length) return inventory;
    return inventory.filter(
      (inventoryItem) =>
        !!filter.find(({ itemId }) => inventoryItem.item_id === itemId)
    );
  }

  static getControlledConsumedItemValues(
    controlledItemValues,
    { itemId, serieId }
  ) {
    if (!controlledItemValues)
      return {
        controlledAmount: undefined,
        controlledSerie: undefined,
      };
    const keyValues =
      controlledItemValues[
        this.getControlledConsumedItemFormat(itemId, serieId).key
      ];
    if (!keyValues)
      return {
        controlledAmount: "",
        controlledSerie: null,
      };
    const [_serieId, amount, initialAmount, finalAmount] =
      this.getSplittedKey(keyValues);

    return {
      controlledSerie: Number(_serieId),
      controlledAmount: Number(amount),
      controlledInitialAmount: Number(initialAmount),
      controlledFinalAmount: Number(finalAmount),
    };
  }
}
