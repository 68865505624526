//Libs
import { Collapse as _Collapse } from "antd";
import styled from "styled-components";

const { Panel: _Panel } = _Collapse;

const Collapse = styled(_Collapse)`
  width: ${(props) => (props.width ? props.width : props.theme.collapse.width)};
  padding: ${(props) =>
    props.padding ? props.padding : props.theme.collapse.padding};
  &.ant-collapse-borderless {
    background-color: ${(props) => props.theme.colors.background.high};
    border: none;
  }
`;

const Panel = styled(_Panel)`
  .ant-collapse-content-box {
    background: ${(props) => props.theme.colors.background.high};
  }
`;

export default {
  Collapse,
  Panel,
};
