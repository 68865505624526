import styled from "styled-components";
import { Input } from "antd";

const { Search, TextArea } = Input;

const InputStyled = styled(Input)`
  margin: ${(props) =>
    props.margin ? props.margin : props.theme.textField.margin};
  min-height: ${(props) =>
    props.minHeight ? props.minHeight : props.theme.textField.minHeight};
  width: ${(props) =>
    props.width ? props.width : props.theme.textField.width};
  height: ${(props) =>
    props.height ? props.height : props.theme.textField.height};
  .ant-input {
    font-size: ${(props) =>
      props.fontSize
        ? props.theme.fonts.size[props.fontSize]
        : props.theme.fonts.size.default};
    font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : props.theme.fonts.family};
    font-weight: ${(props) =>
      props.fontWeight ? props.fontWeight : props.theme.textField.fontWeight};
    font-stretch: ${(props) =>
      props.fontStretch
        ? props.fontStretch
        : props.theme.textField.fontStretch};
    font-style: ${(props) =>
      props.fontStyle ? props.fontStyle : props.theme.textField.fontStyle};
    line-height: ${(props) =>
      props.lineHeight ? props.lineHeight : props.theme.textField.lineHeight};
    letter-spacing: ${(props) =>
      props.letterSpacing
        ? props.letterSpacing
        : props.theme.textField.letterSpacing};
    color: ${(props) =>
      props.color ? props.color : props.theme.textField.color};
    background-color: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : props.theme.textField.backgroundColor};
    border: 0px;
  }
`;

const SearchStyled = styled(Search)`
  margin: ${(props) =>
    props.margin ? props.margin : props.theme.textField.margin};
  min-height: ${(props) =>
    props.minHeight ? props.minHeight : props.theme.textField.minHeight};
  .ant-input {
    height: ${(props) =>
      props.height ? props.height : props.theme.textField.height};
    font-size: ${(props) =>
      props.fontSize
        ? props.theme.fonts.size[props.fontSize]
        : props.theme.fonts.size.default};
    font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : props.theme.fonts.family};
    font-weight: ${(props) =>
      props.fontWeight ? props.fontWeight : props.theme.textField.fontWeight};
    font-stretch: ${(props) =>
      props.fontStretch
        ? props.fontStretch
        : props.theme.textField.fontStretch};
    font-style: ${(props) =>
      props.fontStyle ? props.fontStyle : props.theme.textField.fontStyle};
    line-height: ${(props) =>
      props.lineHeight ? props.lineHeight : props.theme.textField.lineHeight};
    letter-spacing: ${(props) =>
      props.letterSpacing
        ? props.letterSpacing
        : props.theme.textField.letterSpacing};
    color: ${(props) =>
      props.color ? props.color : props.theme.textField.color};
    background-color: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : props.theme.textField.backgroundColor};
  }
  .ant-input-search-icon {
    color: ${(props) =>
      props.color ? props.color : props.theme.textField.color};
  }
`;
const TextAreaStyled = styled(TextArea)`
  margin: ${(props) =>
    props.margin ? props.margin : props.theme.textField.margin};
  min-height: ${(props) =>
    props.minHeight ? props.minHeight : props.theme.textField.minHeight};
  .ant-input {
    height: ${(props) =>
      props.height ? props.height : props.theme.textField.height};
    font-size: ${(props) =>
      props.fontSize
        ? props.theme.fonts.size[props.fontSize]
        : props.theme.fonts.size.default};
    font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : props.theme.fonts.family};
    font-weight: ${(props) =>
      props.fontWeight ? props.fontWeight : props.theme.textField.fontWeight};
    font-stretch: ${(props) =>
      props.fontStretch
        ? props.fontStretch
        : props.theme.textField.fontStretch};
    font-style: ${(props) =>
      props.fontStyle ? props.fontStyle : props.theme.textField.fontStyle};
    line-height: ${(props) =>
      props.lineHeight ? props.lineHeight : props.theme.textField.lineHeight};
    letter-spacing: ${(props) =>
      props.letterSpacing
        ? props.letterSpacing
        : props.theme.textField.letterSpacing};
    color: ${(props) =>
      props.color ? props.color : props.theme.textField.color};
    background-color: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : props.theme.textField.backgroundColor};
  }
`;

export default {
  Input: InputStyled,
  Search: SearchStyled,
  TextArea: TextAreaStyled,
};
