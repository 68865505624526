import styled, { css } from "styled-components";

const Title = styled.span`
  ${({ ellipsis }) =>
    ellipsis &&
    css`
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}
  font-family: ${(props) => props.theme.title.fontFamily};
  font-style: ${(props) => props.theme.title.fontStyle};
  font-weight: ${(props) => props.theme.title.fontWeight};
  font-size: ${(props) => props.theme.title.fontSize};
  line-height: ${(props) => props.theme.title.lineHeight};
  letter-spacing: ${(props) => props.theme.title.letterSpacing};
  color: ${(props) => props.color || props.theme.title.color};
  mix-blend-mode: ${(props) => props.theme.title.mixBlendMode};
  //Props
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  text-align: ${({ textAlign }) => textAlign};
`;

export default Title;
