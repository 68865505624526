//Libs
import { takeLatest, put, call, all } from "redux-saga/effects";
import axios from "axios";
//Utils
import asyncErrorsHandler from "store/asyncErrorsHandler";
import AuthService from "utils/libs/auth/AuthService";
import GENERAL from "utils/constants/general";

const { PAYMENTS, APIDATA, DASHBOARD } = GENERAL;
const auth = new AuthService();

//******** WATCHER SAGAS ***********/

function* signPaymentWatcher() {
  yield takeLatest(PAYMENTS.SIGN_PAYMENT, signPaymentWorker);
}

//******** WORKERS SAGAS ***********/

function* signPaymentWorker(action) {
  const payment_id = action.payload;

  try {
    yield put({
      type: PAYMENTS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "signPaymentModal",
        keyValuePairs: { loading: true },
      },
    });
    yield call(
      axios.put,
      process.env.REACT_APP_API_URL.concat("/payments/sign"),
      { payment_id },
      auth.sendToken()
    );
    yield put({
      type: PAYMENTS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "signPaymentModal",
        keyValuePairs: { loading: false, isOpen: false },
      },
    });
    yield put({
      type: DASHBOARD.TOAST_MESSAGE,
      payload: { message: `Pago firmado`, duration: 1.5, type: "success" },
    });
    yield put({ type: APIDATA.GET_PAYMENTS });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: PAYMENTS.MUTATE_1OBJECT,
          payload: {
            obj1Name: "signPaymentModal",
            keyValuePairs: { loading: false },
          },
        });
      },
      function* () {
        yield signPaymentWorker(action);
      }
    );
  }
}

//  Export default Root Saga
export default function* rootSaga() {
  yield all([signPaymentWatcher()]);
}
