//Libs
import React from "react";
import styled from "styled-components";
//Components
import IconButton from "@material-ui/core/IconButton";
import icon from "./delete.svg";
import ligthDeleteIcon from "./light_delete.svg";

const Icon = styled.div`
  background-image: url(${icon});
  width: 24px;
  height: 24px;
`;
const LightIcon = styled.div`
  background-image: url(${ligthDeleteIcon});
  width: 24px;
  height: 24px;
`;

const DeleteIcon = ({ button, light, onClick }) =>
  button ? (
    <IconButton onClick={onClick}>
      {light ? <LightIcon /> : <Icon />}
    </IconButton>
  ) : light ? (
    <LightIcon onClick={onClick} />
  ) : (
    <Icon onClick={onClick} />
  );

export default DeleteIcon;
