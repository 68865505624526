//  Libs
import React from "react";
import { List } from "react-virtualized";
import { WrapperView } from "components/components";
//  Components
import NotificationCard from "./NotificationCard";
//  Styles
import "./NotificationList.css";

function NotificationList({ isFetching, notifications, selectNotification }) {
  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) => (
    <div key={key} style={{ ...style }}>
      <NotificationCard
        key={key}
        notification={notifications[index]}
        selectNotification={selectNotification}
      />
    </div>
  );

  return (
    <WrapperView withToolbarHeight>
      <div className="container-notifications">
        <List
          className="container-list-notifications"
          width={document.documentElement.clientWidth}
          height={document.documentElement.clientHeight - 56}
          rowCount={notifications.length}
          rowHeight={123}
          rowRenderer={rowRenderer}
        />
      </div>
    </WrapperView>
  );
}

export default NotificationList;
