//Libs
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "styled-components";
//Utils
import { onKeyPressedSearchOrder } from "components/containers/Orders/utils";
import ManagePermissions from "../../../Authorization";
//Actions
import { mutate1Object as _mutate1ObjectInCore } from "core/actions";
//Selectors
import { selectProfile } from "components/containers/Login/selectors";
//Components
import { Toolbar } from "react-onsenui";
import ToolbarNotifications from "../Notifications";
import FormControl from "@material-ui/core/FormControl";
import { HamburguerComponent } from "..";
import {
	ToolbarTitle,
	SearchIcon,
	CloseIcon,
	TextField,
	SyncIcon,
	AddIcon,
} from "components/components";

const { Input } = TextField;

export function Orders({
	title,
	showNavBar,
	orders,
	selectNotification,
	mutate1ObjectInOrders,
	// autoSyncPhotoReports,
}) {
	const dispatch = useDispatch();
	const profile = useSelector(selectProfile);
	const { searchInData, synchronizingPhotos } = orders.control;

	const mutate1ObjectInCore = (obj1Name, keyValuePairs) =>
		dispatch(_mutate1ObjectInCore(obj1Name, keyValuePairs));

	return (
		<Toolbar>
			{/* LEFT */}
			<div className="left toolbar__left toolbar--material__left hamburger-button-container">
				{/* Hamburger */}
				<HamburguerComponent
					handleOnClick={showNavBar}
					synchronizingPhotos={synchronizingPhotos}
				/>
			</div>

			{/* CENTER */}
			<div className="center toolbar__center toolbar__title toolbar--material__center">
				<ToolbarTitle>{title}</ToolbarTitle>
			</div>

			{/* RIGHT */}
			<div
				className="right toolbar__right toolbar--material__right"
				style={{ display: "flex", alignItems: "center" }}>
				{/* Search Button */}
				<SearchIcon
					onClick={() =>
						mutate1ObjectInOrders("control", {
							searchInData: {
								...searchInData,
								renderToolbar: true,
								searchBy: "deepSearch",
							},
						})
					}
				/>

				<SyncIcon
					onClick={() =>
						mutate1ObjectInOrders("autoSyncOfflineData", {
							isOpen: true,
						})
					}
				/>

				{new ManagePermissions(profile).checkCreateOrder() && (
					<AddIcon
						button
						onClick={(e) => {
							e.stopPropagation();
							mutate1ObjectInCore("actionForm", {
								isOpen: true,
								formId: 1,
							});
						}}
					/>
				)}

				{/* Notifications */}
				<ToolbarNotifications selectNotification={selectNotification} />
			</div>
		</Toolbar>
	);
}

export function SearchOrder({
	mutate1ObjectInOrders,
	orders,
	history,
	mutateDirectPropsInDashboard,
	getOrders,
}) {
	const theme = useTheme();
	const { searchInData } = orders.control;
	const { getOrdersModal } = orders;

	return (
		<Toolbar
			style={
				searchInData.renderToolbar
					? { backgroundColor: theme.colors.background.medium }
					: undefined
			}>
			{/* FILTER ORDER */}
			<FormControl
				id="toolbar-search-order-container"
				className="center animated fadeIn">
				<Input
					id="toolbar-search-order-input"
					height="32px"
					autoFocus
					name="data"
					placeholder="Buscar orden"
					value={searchInData.data}
					onKeyPress={(e) =>
						onKeyPressedSearchOrder(
							e,
							searchInData,
							history,
							mutate1ObjectInOrders,
							mutateDirectPropsInDashboard
						)
					}
					onChange={(e) =>
						mutate1ObjectInOrders("control", {
							searchInData: {
								...searchInData,
								[e.target.name]: e.target.value,
							},
						})
					}
					prefix={<SearchIcon small onlyIcon />}
					suffix={
						<CloseIcon
							small
							onlyIcon
							onClick={() => {
								mutate1ObjectInOrders("control", {
									searchInData: {
										...searchInData,
										renderToolbar: false,
										searchBy: "all",
										data: "",
									},
								});
								getOrders(getOrdersModal);
							}}
						/>
					}
				/>
			</FormControl>
		</Toolbar>
	);
}
