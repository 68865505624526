//Libs
import React from "react";
import styled from "styled-components";
//Components
import IconButton from "@material-ui/core/IconButton";
// import icon from "./error.svg";
import icon2 from "./error2.svg";

const Icon = styled.div`
  background-image: url(${icon2});
  width: 24px;
  height: 24px;
`;

const ErrorIcon = ({ button, margin, bold, onClick }) =>
  button ? (
    <IconButton onClick={onClick}>
      <Icon bold={bold} />
    </IconButton>
  ) : (
    <Icon bold={bold} onClick={onClick} />
  );

export default ErrorIcon;
