//Libs
import { combineReducers } from "redux";
//Reducers
import api from "./api/reducer";
import login from "components/containers/Login/reducer";
import signup from "components/containers/Signup/reducer";
import dashboard from "components/containers/Dashboard/reducer";
import orders from "components/containers/Orders/reducer";
import payments from "components/containers/Payments/reducer";
import warehouse from "components/containers/Warehouse/reducer";
import collector from "components/components/Collector/reducer";
import uploadResource from "components/components/UploadResource/reducer";
import manageInventory from "components/components/ConsumeInventory/reducer";
import modules from "components/components/Modules/reducer";
import autoscan from "components/components/Autoscan/reducer";
import feed from "core/reducer";

//Root Reducer
const rootReducer = combineReducers({
  api,
  login,
  signup,
  dashboard,
  orders,
  payments,
  warehouse,
  collector,
  uploadResource,
  manageInventory,
  modules,
  autoscan,
  feed,
});

export default rootReducer;
