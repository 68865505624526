import React from "react";
import { useDispatch, useSelector } from "react-redux";
//Selectors
import {
  selectConsumeInventoryAutoSync,
  selectConsumedItems,
  selectEditableInventoryData,
  selectManageInventorySending,
} from "../ConsumeInventory/selectors";
//Components
import { CollectorUtils, Progress } from "components/components";
import {
  ConsumeIntegrationUtils,
  ConsumeInventoryUtils,
} from "../ConsumeInventory";
import { selectProfile } from "components/containers/Login/selectors";
import {
  mutateConsumeInventoryControl,
  saveConsumedItems,
} from "../ConsumeInventory/actions";
import { selectCollectorEcosystemAutoSync } from "./selectors";

const AutoSyncInventoryCollectorEcosystem = () => {
  const editableInventoryData = useSelector(selectEditableInventoryData);
  const profile = useSelector(selectProfile);
  const consumedItems = useSelector(selectConsumedItems);
  const collectorEcosystemAutoSync = useSelector(
    selectCollectorEcosystemAutoSync
  );
  const manageInventorySending = useSelector(selectManageInventorySending);
  const consumeInventoryAutoSync = useSelector(selectConsumeInventoryAutoSync);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (collectorEcosystemAutoSync.active) return;
    if (collectorEcosystemAutoSync.running) return;
    if (consumeInventoryAutoSync.running) return;

    CollectorUtils.reloadOfflineCollectorValues().then(
      (offlineCollectorValues) => {
        const successInventoryCollectorValues =
          CollectorUtils.getSuccessInventoryCollectorValues(
            offlineCollectorValues
          );
        if (!successInventoryCollectorValues.length) return;

        const orderMap = successInventoryCollectorValues.reduce((acc, cv) => {
          acc[`${cv.docId}/${cv.auditOrderId}`] = true;
          return acc;
        }, {});

        Object.keys(orderMap).forEach((key) => {
          const [docIdStr, auditOrderIdStr] = key.split("/");
          const docId = Number(docIdStr);
          const auditOrderId = Number(auditOrderIdStr);

          const filteredCollectorValues = CollectorUtils.filterCollectorValues(
            successInventoryCollectorValues,
            docId,
            auditOrderId
          );

          const consumedItemValues = filteredCollectorValues.reduce(
            (acc, cv) => {
              const { key, value } =
                ConsumeIntegrationUtils.getControlledConsumedItemFormat(
                  cv.inventoryItemId,
                  cv.inventorySerieId,
                  cv.inventoryAmount,
                  cv.inventoryInitialAmount,
                  cv.inventoryFinalAmount
                );
              acc[key] = value;
              return acc;
            },
            {}
          );

          const totalConsumedItemValues = Object.keys(
            consumedItemValues
          ).reduce((totalValues, consumedItemKey) => {
            const [searchedItemId, searchedSerieId] =
              ConsumeIntegrationUtils.getSplittedKey(consumedItemKey);

            totalValues[consumedItemKey] = filteredCollectorValues.reduce(
              (totalValue, cv) => {
                if (
                  ConsumeIntegrationUtils.checkIsInventoryItem({
                    checking: "isDifferent",
                    searchedItemId,
                    searchedSerieId,
                    inventoryItemId: cv.inventoryItemId,
                    inventorySerieId: cv.inventorySerieId,
                  })
                )
                  return totalValue;

                const totalAmount =
                  Number(
                    ConsumeIntegrationUtils.getSplittedKey(totalValue)[1]
                  ) || 0;

                totalValue =
                  ConsumeIntegrationUtils.getControlledConsumedItemFormat(
                    cv.inventoryItemId,
                    cv.inventorySerieId,
                    totalAmount + Number(cv.inventoryAmount),
                    cv.inventoryInitialAmount,
                    cv.inventoryFinalAmount
                  ).value;
                return totalValue;
              },
              ""
            );

            return totalValues;
          }, {});

          if (!Object.keys(totalConsumedItemValues).length) return;

          const stock = editableInventoryData.toInvoiceInventory.concat(
            editableInventoryData.toInvoiceSerializedInventory.concat(
              editableInventoryData.uninstallConsumedItems
            )
          );

          const getInventoryItem = (totalValueKey) => {
            const [searchedItemId, searchedSerieId] =
              ConsumeIntegrationUtils.getSplittedKey(totalValueKey);

            return stock.find((inventoryItem) =>
              ConsumeIntegrationUtils.checkIsInventoryItem({
                checking: "isEquals",
                searchedItemId,
                searchedSerieId,
                inventoryItemId: inventoryItem.item_id,
                inventorySerieId: inventoryItem.serie_id,
              })
            );
          };

          Object.keys(totalConsumedItemValues).forEach((totalValueKey) => {
            const inventoryItem = getInventoryItem(totalValueKey);
            if (!inventoryItem) return;

            const {
              consumedItemProps,
              savedConsumedItemIdx,
              savedConsumedItem,
              consumedItemValue,
            } = ConsumeInventoryUtils.getConsumedItem({
              otdId: docId,
              orderId: auditOrderId,
              ownerId: profile.user.id,
              entityId: profile.entity.id,
              inventoryItem,
              consumedItems,
            });

            const [serieId, amount] = ConsumeIntegrationUtils.getSplittedKey(
              totalConsumedItemValues[totalValueKey]
            );
            const value = consumedItemProps.isSerialized ? serieId : amount;
            if (!value && !consumedItemValue) return;
            if (Number(value) === Number(consumedItemValue)) return;

            ConsumeInventoryUtils.onChangeConsumedItem(
              { value, initialAmount: null, finalAmount: null },
              {
                consumedItemProps,
                savedConsumedItemIdx,
                savedConsumedItem,
                consumedItems,
              },
              (payload) => dispatch(mutateConsumeInventoryControl(payload)),
              (consumedItems) =>
                dispatch(
                  saveConsumedItems(
                    ConsumeInventoryUtils.getUnsuccessConsumedItems({
                      consumedItems,
                      max: 10,
                    })
                  )
                )
            );
          });
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    collectorEcosystemAutoSync.active,
    collectorEcosystemAutoSync.running,
    consumedItems,
    consumeInventoryAutoSync.running,
    editableInventoryData.toInvoiceInventory,
    editableInventoryData.toInvoiceSerializedInventory,
    editableInventoryData.uninstallConsumedItems,
    profile.entity.id,
    profile.user.id,
  ]);

  return (
    ConsumeInventoryUtils.checkAutoSyncActive(
      consumeInventoryAutoSync.active,
      manageInventorySending
    ) && (
      <Progress
        style={{ top: "-9px", opacity: 0.3 }}
        percent={100}
        status="active"
        showInfo={false}
        size="small"
      />
    )
  );
};

export default AutoSyncInventoryCollectorEcosystem;
