// Dependencies
import decode from 'jwt-decode';

// Helpers
const DIVISION_ID_EXP = 3

export default class AuthService {
  constructor() {
    this.fetch = this.fetch.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.loggedIn = this.loggedIn.bind(this);
  }

  /** FETCH */
  fetch(url, options) {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    if (this.loggedIn())
      headers['Authorization'] = `Bearer ${this.getToken()}`;

    return fetch(url, {
      headers,
      ...options
    })
      .then(this._checkStatus)
      .then(response => response.json())
  }
  /** TOKEN */
  getToken() {
    return localStorage.getItem('synapse_token');
  }

  setToken(idToken) {
    return localStorage.setItem('synapse_token', idToken);
  }

  loggedIn() {
    const token = this.getToken();
    //  Token doesn't exist?
    if (!token) return false
    //  Token is expired?
    if (this.isTokenExpired(token)) return false
    //  Token division_id Incorrect?
    const decoded = decode(token);
    
    if (decoded.user.division_id !== DIVISION_ID_EXP) return false

    return true
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  logout() {
    localStorage.removeItem('synapse_token');
  }

  getProfile() {
    return decode(this.getToken());
  }

  _checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      let error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }

  sendToken() {
    return {
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      }
    }
  }
}