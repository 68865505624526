//Constants
import GENERAL from "../../utils/constants/general";

const { APIDATA } = GENERAL,
  initialState = {
    //USERS
    users: [],
    //ORDERS
    states: [],
    events: [],
    completedCodes: [],
    //TEMPLATES
    templates: [],
    //PAYMENTS
    payments: {
      pays: {
        loading: false,
        data: [],
      },
      resume: {
        loading: false,
        payments: [],
        orders: [],
        lines: [],
      },
    },
    gains: {
      gain_orders: 0.0,
      gain_sales: 0.0,
      coin_symbol: null,
    },
    coins: [],
    //WAREHOUSE
    warehouse: {
      toInvoice: {
        loading: false,
        data: [],
      },
      toUninstall: [],
    },
    serieHistory: {
      serie: undefined,
      isOpen: false,
      loading: false,
      data: [],
    },
    incomeMethods: [],
    warehouses: [],
    //CITIES
    cities: {
      loading: false,
      data: [],
    },
    //TOWNSHIPS
    townships: {
      loading: false,
      data: [],
    },
    //ZONES
    zones: {
      loading: false,
      data: [],
    },
    //DEPARTMENTS
    departments: {
      loading: false,
      data: [],
    },
    //CONTRACTS
    contractTypes: {
      loading: false,
      data: [],
    },
  },
  apiReducer = (state = initialState, action) => {
    switch (action.type) {
      //GENERAL
      case APIDATA.MUTATE_DIRECT_PROPS: {
        const keyValuePairs = action.payload;
        return {
          ...state,
          ...keyValuePairs,
        };
      }
      case APIDATA.MUTATE_1OBJECT: {
        const { obj1Name, keyValuePairs } = action.payload,
          newObj = { ...state[obj1Name], ...keyValuePairs };
        return {
          ...state,
          [obj1Name]: newObj,
        };
      }
      //ORDERS
      //RESET MODULE DATA
      case APIDATA.RESET_MODULE_DATA: {
        const resetModuleData = action.payload;

        switch (resetModuleData) {
          case "ORDERS":
            return {
              ...state,
              states: [],
              events: [],
            };
        }
      }
      default:
        return state;
    }
  };

export default apiReducer;
