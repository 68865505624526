//Libs
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
//Services
import { SentryService } from "services";
//Utils
import GENERAL from "utils/constants/general";
//Actions
import * as paymentActions from "./actions";
import * as orderActions from "components/containers/Orders/actions";
import * as apiActions from "store/api/actions";
import * as dashboardActions from "components/containers/Dashboard/actions";
//Components
import { WrapperView } from "components/components";
//Styles
import "./index.css";

const { ENV } = GENERAL;
const actions = {
  ...paymentActions,
  //ORDERS
  mutate1ObjectInOrders: orderActions.mutate1Object,
  resetOrderInformation: orderActions.resetOrderInformation,
  sendCommentReport: orderActions.sendCommentReport,
  getOrder: orderActions.getOrder,
  //API
  getPayments: apiActions.getPayments,
  getPaymentResume: apiActions.getPaymentResume,
  doResetApiData: apiActions.doResetApiData,
  getCoins: apiActions.getCoins,
  //DASHBOARD
  sendToast: dashboardActions.sendToast,
};

class Payments extends Component {
  constructor(...props) {
    super(...props);
  }

  /*********************************** LIFECYCLES **************************************/
  componentDidMount() {
    const { getPayments, getCoins } = this.props;

    getPayments();
    getCoins();
  }

  componentWillUnmount() {
    this.props.doReset();
    this.props.doResetApiData("PAYMENTS");
  }

  componentDidCatch(error, errorInfo) {
    SentryService.sendError(error, { errorInfo });
  }

  /*********************************** FUNCTIONS **************************************/

  render() {
    const { history, routes } = this.props;
    if (history.location.pathname === ENV.ROUTES.PATHS.PAYMENTS) {
      return <Redirect to={ENV.ROUTES.PATHS.PAYMENTS_MANAGE} />;
    }

    return (
      <WrapperView>
        {routes.map((route, i) => {
          return (
            <Route
              key={i}
              path={route.path}
              render={(oProps) => (
                <route.component
                  {...oProps}
                  {...this.props}
                  routes={route.routes}
                  //Class methods
                />
              )}
            />
          );
        })}
      </WrapperView>
    );
  }
}

Payments.propTypes = {
  //Props
  //Functions
};

export default connect(
  (state) => ({
    //PAYMENTS
    ...state.payments,
    //-------------------------------------EXTERNAL PROPS------------------------------------
    //ORDERS
    ordersState: {
      control: {
        orders: state.orders.control.orders,
      },
      getOrderInformationModal: {
        ...state.orders.getOrderInformationModal,
      },
    },
    //API
    users: state.api.users,
    payments: state.api.payments,
    coins: state.api.coins,
    templates: state.api.templates,
    //LOGIN
    profile: state.login.profile,
  }),
  actions
)(Payments);
