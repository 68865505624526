// Libs
import React from "react";
import { useSelector } from "react-redux";
// Services
import { SentryService } from "services";
// Selectors
import { selectProfile } from "components/containers/Login/selectors";
// Hooks
import { useScreenOrientation } from "hooks";
// Utils
import { PhotoUtils } from "utils/libs";

//Init Context
const PhotoContext = React.createContext({});
const { Provider } = PhotoContext;

const CONTROL_PANEL_LSKEY = "synapse_CollectorControlPanel";

const _getPersistedControlPanel = () => {
  const persistedControlPanel = localStorage.getItem(CONTROL_PANEL_LSKEY);
  if (!persistedControlPanel) return {};
  return JSON.parse(persistedControlPanel);
};

const _setPersistedControlPanel = async ({
  orientation,
  devices,
  deviceId,
  isPhotoBackupActive,
  isSynapseCameraActive,
  clientLogo,
}) => {
  try {
    const persistedControlPanel = _getPersistedControlPanel();
    const newState = {
      ...persistedControlPanel,
      orientation,
      devices,
      deviceId,
      isPhotoBackupActive,
      isSynapseCameraActive,
      clientLogo,
    };
    localStorage.setItem(CONTROL_PANEL_LSKEY, JSON.stringify(newState));
  } catch (err) {
    console.error(err);
  }
};

const PhotoProvider = ({ children }) => {
  const profile = useSelector(selectProfile);
  const { orientation } = useScreenOrientation({ onChangeListener: true });
  const [state, _setState] = React.useState(() => ({
    orientation,
    devices: [],
    deviceId: undefined,
    isPhotoTaken: false,
    isPhotoBackupActive: true,
    isSynapseCameraActive: true,
    clientLogo: null,
  }));
  const setState = (newState) => {
    _setState((prev) => {
      const combinedState = { ...prev, ...newState };
      _setPersistedControlPanel(combinedState);
      return combinedState;
    });
  };

  const setSetting = (key, value) => {
    if (Object.keys(state).indexOf(key) === -1) return; //If key doesn't exist on initial state.
    const setting = { [key]: value };
    setState(setting);
  };

  const onManualOrientationToggle = () =>
    setSetting(
      "orientation",
      state.orientation !== "portrait" ? "portrait" : "landscape"
    );

  //Load persisted control panel state
  React.useEffect(() => {
    const persistedControlPanel = _getPersistedControlPanel();
    setState(persistedControlPanel);
    console.log("Persisted control panel state: Loaded!");
  }, []);

  //Enumerate Devices
  React.useEffect(() => {
    if (!navigator.mediaDevices.enumerateDevices) return;
    navigator.mediaDevices.enumerateDevices().then((devices) =>
      setSetting(
        "devices",
        devices
          .map((d) => d.toJSON())
          .reduce((acc, d) => {
            if (d.deviceId && !acc.find((_d) => _d.deviceId === d.deviceId))
              acc.push(d);
            return acc;
          }, [])
      )
    );
  }, []);

  //On screen orientation change
  React.useEffect(() => {
    setSetting("orientation", orientation);
  }, [orientation]);

  //Get client logo
  React.useEffect(() => {
    let isMounted = true;
    fetch(
      PhotoUtils.getLogo({
        level: "organization",
        size: 200,
        profile,
      })
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Convert blob to base64
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64Img = reader.result;
          // Get width & height from img
          const img = new Image();
          img.onload = () =>
            isMounted &&
            setSetting("clientLogo", {
              base64Img,
              width: img.width,
              height: img.height,
            });
          img.src = base64Img;
        };
      })
      .catch(SentryService.sendError);
    return () => {
      isMounted = false;
    };
  }, []);

  const value = React.useMemo(
    () => ({
      ...state,
      setSetting,
      onManualOrientationToggle,
    }),
    [state]
  );

  return <Provider value={value}>{children}</Provider>;
};

export { PhotoProvider, PhotoContext };
