//Libs
import React from "react";
import { connect } from "react-redux";
//Utils
import { debounce } from "utils/libs";
import orderBy from "utils/libs/orderBy";
import ConsumeInventoryUtils from "./ConsumeInventoryUtils";
import KEYWORDS from "./keywords";
//Actions
import {
  mutateConsumeInventoryControl,
  mutateUninstallInventoryControl,
  saveConsumedItems,
} from "./actions";
import {
  mutate1Object as mutate1ObjectInApi,
  getSerieHistory,
} from "store/api/actions";
import { mutateDirectProps as mutateDirectPropsInAutoscan } from "components/components/Autoscan/actions";
//Selectors
import {
  selectConsumedItems,
  selectConsumedItemsResume,
  selectEditableInventoryData,
  selectUninstallInventoryControl,
} from "./selectors";
import { selectAutoscanControl } from "components/components/Autoscan/selectors";
//Components
import {
  AddIcon,
  Chip,
  Wrapper,
  Empty,
  ResumeInventory,
  ShoppingCartIcon,
  Title,
} from "components/components";
import { VirtualizedList } from "components/containers/Utils";
import ModalWarehouse from "components/containers/Warehouse/Modals";
import UninstallInventoryList from "./UninstallInventoryList";
import Autoscan from "../Autoscan";
import IntegrationUtils from "./IntegrationUtils";
import { selectProfile } from "components/containers/Login/selectors";

const Chips = ({
  showInventoryResume,
  setState,
  onShowInventoryResumeToggle,
  mutateDirectPropsInAutoscan,
}) => (
  <Wrapper justifyContent="space-between" padding="0">
    {!showInventoryResume && (
      <>
        <Chip
          label="Materiales"
          onClick={() => {
            setState({ view: KEYWORDS.TYPES.ARTICLE });
            mutateDirectPropsInAutoscan({ show: false });
          }}
        />
        <Chip
          label="Equipos"
          onClick={() => {
            setState({ view: KEYWORDS.TYPES.SERIALIZED });
            mutateDirectPropsInAutoscan({ show: true });
          }}
        />
        <Chip
          label="Desinstalar"
          onClick={() => {
            setState({ view: KEYWORDS.TYPES.UNINSTALL_SERIALIZED });
            mutateDirectPropsInAutoscan({ show: false });
          }}
        />
      </>
    )}
    <>
      {showInventoryResume && <Title>Resumen</Title>}
      <ShoppingCartIcon button onClick={onShowInventoryResumeToggle} />
    </>
  </Wrapper>
);

const ConsumeInventory = ({
  //Props
  otdId,
  order,
  collectorEcosystemIntegration = {},
  //Redux
  autoscanControl,
  uninstallInventoryControl,
  editableInventoryData,
  consumedItems,
  totalConsumedItemsResume,
  profile,
  mutateConsumeInventoryControl,
  mutateUninstallInventoryControl,
  mutateDirectPropsInAutoscan,
  saveConsumedItems,
  mutate1ObjectInApi,
  getSerieHistory,
}) => {
  const [state, _setState] = React.useState({
    view: KEYWORDS.TYPES.ARTICLE,
    showInventoryResume: false,
  });
  const setState = (newProps) =>
    _setState((current) => ({ ...current, ...newProps }));
  const onShowInventoryResumeToggle = () =>
    _setState((current) => {
      const showInventoryResume = !current.showInventoryResume;
      mutateConsumeInventoryControl({
        editableInventory: !showInventoryResume,
      });
      return {
        ...current,
        showInventoryResume,
      };
    });

  //Send unsuccess consumed items
  const sendUnsuccessConsumedItems = React.useCallback(
    debounce(
      (consumedItems) =>
        saveConsumedItems(
          ConsumeInventoryUtils.getUnsuccessConsumedItems({
            consumedItems,
            max: 10,
          })
        ),
      2500
    ),
    []
  );

  //On Change
  const onChangeState = React.useCallback(
    ({
      value,
      initialAmount,
      finalAmount,
      consumedItemProps,
      savedConsumedItemIdx,
      savedConsumedItem,
    }) =>
      collectorEcosystemIntegration.onControlledConsumedItemChange
        ? collectorEcosystemIntegration.onControlledConsumedItemChange({
            itemId: consumedItemProps.itemId,
            serieId: consumedItemProps.serieId,
            amount: !consumedItemProps.isSerialized ? value : null,
            initialAmount: !consumedItemProps.isSerialized
              ? initialAmount
              : null,
            finalAmount: !consumedItemProps.isSerialized ? finalAmount : null,
          })
        : ConsumeInventoryUtils.onChangeConsumedItem(
            { value, initialAmount, finalAmount },
            {
              consumedItemProps,
              savedConsumedItemIdx,
              savedConsumedItem,
              consumedItems,
            },
            mutateConsumeInventoryControl,
            sendUnsuccessConsumedItems
          ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [consumedItems]
  );

  //Get list rows
  const rows = React.useMemo(() => {
    let _rows = ConsumeInventoryUtils.getCorrespondingInventory({
      view: state.view,
      toInvoiceInventory: editableInventoryData.toInvoiceInventory,
      toInvoiceSerializedInventory:
        editableInventoryData.toInvoiceSerializedInventory,
      uninstallConsumedItems: editableInventoryData.uninstallConsumedItems,
    });
    _rows = IntegrationUtils.getFilteredInventory({
      inventory: _rows,
      filter: collectorEcosystemIntegration.controlledInventoryFilter,
    });
    orderBy(_rows, "object", { objectKey: "item_name" });
    return _rows;
  }, [
    state.view,
    editableInventoryData.toInvoiceInventory,
    editableInventoryData.toInvoiceSerializedInventory,
    editableInventoryData.uninstallConsumedItems,
    collectorEcosystemIntegration.controlledInventoryFilter,
  ]);

  //Get scanned rows
  const scannedRows = React.useMemo(
    () =>
      consumedItems.filter(
        (i) => i.type === KEYWORDS.TYPES.SERIALIZED && i.value
      ),
    [consumedItems]
  );

  const consumedItemsResume = React.useMemo(() => {
    const emptyResume = {
      articles: [],
      serialized: [],
      uninstallSerialized: [],
    };
    if (!state.showInventoryResume) return emptyResume;

    if (!!collectorEcosystemIntegration.controlledConsumedItemsResume)
      return collectorEcosystemIntegration.controlledConsumedItemsResume;

    return totalConsumedItemsResume;
  }, [
    state.showInventoryResume,
    collectorEcosystemIntegration.controlledConsumedItemsResume,
    totalConsumedItemsResume,
  ]);

  if (!order || !order.order_id) return null;

  if (autoscanControl.open) {
    return (
      <Autoscan
        title="Instalando"
        cardId="consumeInventoryItem"
        rows={rows}
        scannedRows={scannedRows}
        listStyle={ConsumeInventoryUtils.getDesignProps({
          key: "listStyle",
          view: state.view,
        })}
        cardStyle={ConsumeInventoryUtils.getDesignProps({
          key: "cardStyle",
          view: state.view,
        })}
        rowHeight={ConsumeInventoryUtils.getDesignProps({
          key: "rowHeight",
          view: state.view,
        })}
        customProps={{
          otdId,
          order,
          ownerId: profile.user.id,
          entityId: profile.entity.id,
          consumedItems,
          onChangeState,
          mutate1ObjectInApi,
          getSerieHistory,
        }}
        onGettedValue={(value) => {
          //Validate consumed item (No repeat)
          const alreadyConsumedItem = consumedItems.find(
            (i) => i.value && i.value.toLowerCase() === value.toLowerCase()
          );
          if (alreadyConsumedItem) return;
          //Validate available serie
          const row = rows.find(
            (r) => r.serie.toLowerCase() === value.toLowerCase()
          );
          if (!row) return;

          const { consumedItemProps, savedConsumedItemIdx, savedConsumedItem } =
            ConsumeInventoryUtils.getConsumedItem({
              otdId,
              orderId: order.order_id,
              ownerId: profile.user.id,
              entityId: profile.entity.id,
              inventoryItem: row,
              consumedItems,
            });

          onChangeState({
            value,
            consumedItemProps,
            savedConsumedItemIdx,
            savedConsumedItem,
            consumedItems,
          });

          return {
            status: "success",
          };
        }}
        onClose={() => mutateDirectPropsInAutoscan({ open: false })}
      />
    );
  }

  return (
    <Wrapper
      width="100%"
      padding={
        !!collectorEcosystemIntegration.controlledConsumedItemsResume
          ? "0"
          : "10px"
      }
      flexDirection="column"
      alignItems="stretch"
    >
      <Chips
        showInventoryResume={state.showInventoryResume}
        setState={setState}
        onShowInventoryResumeToggle={onShowInventoryResumeToggle}
        mutateDirectPropsInAutoscan={mutateDirectPropsInAutoscan}
      />

      {rows.length === 0 ? (
        <Empty margin="20px 0 40px 0" message="No tiene inventario" />
      ) : state.showInventoryResume ? (
        <ResumeInventory consumedItemsResume={consumedItemsResume} />
      ) : (
        <VirtualizedList
          cardId="consumeInventoryItem"
          listStyle={ConsumeInventoryUtils.getDesignProps({
            key: "listStyle",
            view: state.view,
          })}
          cardStyle={ConsumeInventoryUtils.getDesignProps({
            key: "cardStyle",
            view: state.view,
          })}
          rowHeight={ConsumeInventoryUtils.getDesignProps({
            key: "rowHeight",
            view: state.view,
          })}
          rows={rows}
          customProps={{
            otdId,
            order,
            ownerId: profile.user.id,
            entityId: profile.entity.id,
            consumedItems,
            controlledItemValues:
              collectorEcosystemIntegration.controlledItemValues,
            onChangeState,
            mutate1ObjectInApi,
            getSerieHistory,
          }}
        />
      )}
      {state.view === KEYWORDS.TYPES.UNINSTALL_SERIALIZED && (
        <Wrapper padding="5px 0" justifyContent="center">
          <AddIcon
            button
            shadow
            onClick={() => mutateUninstallInventoryControl({ show: true })}
          />
        </Wrapper>
      )}
      {uninstallInventoryControl.show && (
        <UninstallInventoryList
          otdId={otdId}
          order={order}
          consumedItems={consumedItems}
          onChangeState={onChangeState}
        />
      )}
      <ModalWarehouse />
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  autoscanControl: selectAutoscanControl(state),
  editableInventoryData: selectEditableInventoryData(state),
  consumedItems: selectConsumedItems(state),
  totalConsumedItemsResume: selectConsumedItemsResume(state),
  uninstallInventoryControl: selectUninstallInventoryControl(state),
  profile: selectProfile(state),
});

const actions = {
  mutateConsumeInventoryControl,
  mutateUninstallInventoryControl,
  mutateDirectPropsInAutoscan,
  saveConsumedItems,
  mutate1ObjectInApi,
  getSerieHistory,
};

export default connect(mapStateToProps, actions)(ConsumeInventory);
