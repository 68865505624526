//Libs
import React from "react";
import styled, { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
//Utils
import GENERAL from "utils/constants/general";
import formatNumber from "utils/libs/formatNumber";
import { GlobalUtils, ProjectUtils, ServiceUtils } from "utils";
import { getOrderActions } from "components/containers/Orders/utils";
//Hooks
import { useInitializeCollectorEcosystem } from "hooks";
//Components
import { Card } from "antd";
import {
  Wrapper,
  Dropdown,
  Divider,
  Select,
  Menu,
  Span,
  Timer,
  TimeRemainingTag,
  Title,
  Subtitle,
  Caption,
  Button,
  OrderPosition,
  DirectionIcon,
  CommentIcon,
} from "components/components";
//Styles
import "./OrderCard.css";

const { ROUTES, DEPARTMENTS, MANAGE_PROPS } = GENERAL.ENV;
const { Meta } = Card;
const { Option } = Select;

const OrderEvent = styled.div`
  margin: 0px 12px;
  padding: 4px 8px;
  border: 1px solid rgba(46, 125, 50, 0.24);
  box-sizing: border-box;
  border-radius: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #2e7d32;
`;

const TitleCard = (props) => {
  const theme = useTheme();
  const {
    order_id,
    service_props,
    client_name,
    odt_id,
    created_at,
    price,
    event_name,
    event_props,
    priority,
    warranty,
  } = props;
  const { profile } = props.customProps;
  const orderPrice = formatNumber.new({
    num: warranty ? 0.0 : price.price,
    coinId: price.coinId,
  });

  return (
    <Wrapper
      padding="10px 5px 0 5px"
      flexDirection="column"
      alignItems="stretch"
    >
      <div className="order_card-title">
        <div
          className="order_card-flagpriority"
          style={priority && priority.style}
        />
        <div className="order_card-title-avatar">
          {service_props && service_props.initial}
        </div>
        <div>
          <Title width="200px" ellipsis>
            {client_name}
          </Title>
          <div>
            <Caption>
              {odt_id || order_id} - {created_at}
            </Caption>
          </div>
          <Wrapper padding="0" alignItems="center">
            <Subtitle>
              {profile?.user?.settings?.orders?.price?.viewIndividual &&
                orderPrice}
            </Subtitle>
            <OrderEvent
              disabled={event_props?.freezeEvent}
              style={
                event_props?.freezeEvent && {
                  color: theme.colors.text.disabled,
                  borderColor: theme.colors.text.disabled,
                }
              }
            >
              {event_name}
            </OrderEvent>
          </Wrapper>
        </div>
      </div>
    </Wrapper>
  );
};

const OrderActionsMenu = ({ actions, onBillClick, onReviewClick }) => (
  <Menu.Wrapper>
    {actions[DEPARTMENTS.PROPS.EXP.ACTIONS.ORDERS.BILL_ORDER.NAME] && (
      <Menu.Item onClick={onBillClick}>Facturar</Menu.Item>
    )}
    {actions[DEPARTMENTS.PROPS.EXP.ACTIONS.ORDERS.AUDIT_ORDER.NAME] && (
      <Menu.Item onClick={onReviewClick}>Completadas</Menu.Item>
    )}
  </Menu.Wrapper>
);

const Description = (props) => {
  const {
    order_id,
    zone,
    address,
    att_position,
    event_props,
    start_at,
    time_remaining,
    coords,
    counter,
  } = props;
  const {
    mutate1Object,
    createNewInvoice,
    availablePositions,
    setPositionInOrder,
    getOrdersModal,
    mutateConsumeInventoryControl,
  } = props.customProps;
  const notSeen = props.props && !props.props.seen;
  const contractLocation =
    coords?.latitude && coords?.longitude
      ? `waze://?ll=${coords.latitude},${coords.longitude}&z=13`
      : null;

  const theme = useTheme();
  const history = useHistory();
  const initializeCollectorEcosystem = useInitializeCollectorEcosystem();

  //Collector photo config
  const configOrder = GlobalUtils.selectCurrentProps([
    ProjectUtils.getProjectPropsFromOrder(DEPARTMENTS.PROPS.EXP.NAME, props),
    ServiceUtils.getServicePropsFromOrder(DEPARTMENTS.PROPS.EXP.NAME, props),
  ]);
  const { actions, count: actionCount } = getOrderActions(configOrder);

  const onReviewClick = React.useCallback(() => {
    initializeCollectorEcosystem({ orderId: order_id });
    mutateConsumeInventoryControl({ editableInventory: true });
    history.push(ROUTES.PATHS.ORDERS_COMPLETE);
  }, [order_id, history]);

  return (
    <Wrapper
      padding="0px 10px"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="stretch"
    >
      {notSeen ? (
        <Wrapper width="100%" justifyContent="center">
          <Span textAlign="center">
            Pulsa sobre la cinta negra para abrir la orden
          </Span>
        </Wrapper>
      ) : (
        <>
          {/* DETAILS */}
          <Wrapper
            margin="7px 0"
            padding="0"
            height="48px"
            wordBreak="break-word"
            overflow="auto"
            alignItems="baseline"
          >
            {!notSeen && <Subtitle>{zone} - </Subtitle>}
            {!notSeen && (
              <Subtitle bold warning>
                {address}
              </Subtitle>
            )}
          </Wrapper>

          {/* REMAINING */}
          <Wrapper width="100%" padding="0" justifyContent="flex-end">
            <Wrapper width="100%" padding="0">
              <Timer
                startAt={start_at}
                timeRemaining={time_remaining}
                freezeTimer={event_props?.[MANAGE_PROPS.FLAGS.FREEZE_TIMER]}
                render={({ timeRemaining }) => (
                  <TimeRemainingTag time={timeRemaining} />
                )}
              />
            </Wrapper>
            <Wrapper padding="0">
              <CommentIcon
                onClick={() =>
                  (!event_props || !event_props.freezeEvent) &&
                  mutate1Object("sendEventModal", {
                    isOpen: true,
                    orderId: order_id,
                  })
                }
              />
              {contractLocation && (
                <a href={contractLocation}>
                  <DirectionIcon />
                </a>
              )}
            </Wrapper>
          </Wrapper>

          <Wrapper
            padding="0"
            margin="12px 0 0 0"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            {!notSeen && (
              <Wrapper padding="0">
                {getOrdersModal.category === "routing" && (
                  <Select
                    value={
                      att_position < 10 ? `0${att_position}` : att_position
                    }
                    onChange={(value) => setPositionInOrder(order_id, value)}
                  >
                    {availablePositions.map((position, idx) => (
                      <Option key={idx} value={position.value}>
                        <em>{position.label}</em>
                      </Option>
                    ))}
                  </Select>
                )}

                {(getOrdersModal.category === "dailyTracking" ||
                  getOrdersModal.category === "totalTracking") && (
                  <OrderPosition>
                    {att_position < 10 ? `0${att_position}` : `${att_position}`}
                  </OrderPosition>
                )}

                {(getOrdersModal.category === "billed" ||
                  getOrdersModal.category === "auditedOrder") && (
                  <OrderPosition>
                    {counter < 10 ? `0${counter}` : `${counter}`}
                  </OrderPosition>
                )}

                <Divider
                  type="vertical"
                  style={{
                    border: `1px solid ${theme.colors.border.color}`,
                    margin: "0 10px",
                    height: "20px",
                  }}
                />

                <Wrapper padding="0" width="100%" justifyContent="space-around">
                  {/* INVOICE */}
                  {(getOrdersModal.category === "dailyTracking" ||
                    getOrdersModal.category === "totalTracking") &&
                    event_props &&
                    !event_props.billed &&
                    !event_props.photoPending &&
                    !event_props.inventoryValidation &&
                    (actionCount === 1 &&
                    actions[
                      DEPARTMENTS.PROPS.EXP.ACTIONS.ORDERS.BILL_ORDER.NAME
                    ] ? (
                      <Button
                        mode="primary"
                        onClick={() => createNewInvoice(props)}
                      >
                        Facturar
                      </Button>
                    ) : actionCount === 1 &&
                      actions[
                        DEPARTMENTS.PROPS.EXP.ACTIONS.ORDERS.AUDIT_ORDER.NAME
                      ] ? (
                      <Button mode="primary" onClick={onReviewClick}>
                        Completar
                      </Button>
                    ) : (
                      <Dropdown.Wrapper
                        trigger={["click"]}
                        overlay={
                          <OrderActionsMenu
                            actions={actions}
                            onBillClick={() => createNewInvoice(props)}
                            onReviewClick={onReviewClick}
                          />
                        }
                      >
                        <Button mode="primary">Acciones</Button>
                      </Dropdown.Wrapper>
                    ))}

                  {/* DETAILS */}
                  <Button
                    margin="0 0 0 12px"
                    mode="primary"
                    onClick={() =>
                      mutate1Object("getOrderInformationModal", {
                        order_id,
                        isOpen: true,
                      })
                    }
                  >
                    Ver detalle
                  </Button>
                </Wrapper>
              </Wrapper>
            )}
          </Wrapper>
        </>
      )}
    </Wrapper>
  );
};

export default function OrderCard(props) {
  return (
    <Meta
      id="order_card-meta"
      title={<TitleCard {...props} />}
      description={<Description {...props} />}
    />
  );
}
