export default class RealtimeUtils {
  static getMyProfileSubscriber(
    profile = {},
    { companyId, organizationId, entityId, userId } = {}
  ) {
    const { company, organization, entity, user } = profile;
    if (!company?.id || !organization?.id || !entity?.id || !user?.id) return;

    return {
      companyId: companyId || company.id,
      organizationId: organizationId || organization.id,
      entityId: entityId || entity.id,
      userId: userId || user.id,
    };
  }

  static applySubscribedTopicLevelMask(relativeTopic, subscribedTopics) {
    const relativeTopicLevels = relativeTopic.split("/");
    for (let subscribedTopic of subscribedTopics) {
      const subscribedTopicLevels = subscribedTopic.split("/");
      const maskedRelativeTopic = relativeTopicLevels
        .map((level, idx) => {
          const subTopicLevel = subscribedTopicLevels[idx];
          if (subTopicLevel === "#" || subTopicLevel === "+")
            return subTopicLevel;
          return level;
        })
        .join("/");

      if (maskedRelativeTopic === subscribedTopic) return maskedRelativeTopic;
    }
  }

  static findTopicInSubscribedTopics(relativeTopic, subscribedTopics = {}) {
    return Object.keys(subscribedTopics).find(
      (subscribedTopic) =>
        subscribedTopic === relativeTopic ||
        !!this.applySubscribedTopicLevelMask(relativeTopic, [subscribedTopic])
    );
  }
}
