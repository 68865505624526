//Libs
import React from "react";
import { useTheme } from "styled-components";
//Components
import { Toolbar } from "react-onsenui";
import FormControl from "@material-ui/core/FormControl";
import {
  SearchIcon,
  ToolbarTitle,
  CloseIcon,
  TextField,
  LogoIcon,
} from "components/components";

const { Input } = TextField;

export function TransactionsInventory({
  isTransactionInventoryLoading,
  title,
  inventoryToTransferManage,
  mutate1ObjectInWarehouse,
  handleOnClick,
  getInventoryToTransfer,
}) {
  return (
    <Toolbar>
      {/* LEFT */}
      <div className="left toolbar__left toolbar--material__left hamburger-button-container">
        {/* Back Button */}
        <CloseIcon
          onlyIcon
          style={{ display: "flex", marginTop: "-10px", marginLeft: "-8px" }}
          onClick={handleOnClick}
        />
      </div>

      {/* CENTER */}
      <div className="center toolbar__center toolbar__title toolbar--material__center">
        {/* Title */}
        <ToolbarTitle>{title}</ToolbarTitle>
      </div>

      {/* RIGHT */}
      <div
        className="right toolbar__right toolbar--material__right"
        style={{ display: "flex", alignItems: "center" }}
      >
        {/* Update inventory */}
        <LogoIcon
          button
          spin={isTransactionInventoryLoading || undefined}
          onClick={() =>
            !isTransactionInventoryLoading && getInventoryToTransfer()
          }
        />

        {/* Search */}
        <SearchIcon
          onClick={() =>
            mutate1ObjectInWarehouse("inventoryToTransferManage", {
              searchInData: {
                ...inventoryToTransferManage.searchInData,
                renderToolbar: true,
              },
            })
          }
        />
      </div>
    </Toolbar>
  );
}

export function SearchTransactionsInventory({
  mutate1ObjectInWarehouse,
  searchInData,
}) {
  const theme = useTheme();
  return (
    <Toolbar
      style={
        searchInData.renderToolbar
          ? { backgroundColor: theme.colors.background.medium }
          : undefined
      }
    >
      {/* FILTER ORDER */}
      <FormControl
        id="toolbar-search-order-container"
        className="center animated fadeIn"
      >
        <Input
          id="toolbar-search-order-input"
          height="32px"
          autoFocus
          name="data"
          placeholder="Buscar inventario"
          value={searchInData.data}
          onChange={(e) =>
            mutate1ObjectInWarehouse("inventoryToTransferManage", {
              searchInData: {
                ...searchInData,
                [e.target.name]: e.target.value,
              },
            })
          }
          prefix={<SearchIcon small onlyIcon />}
          suffix={
            <CloseIcon
              small
              onlyIcon
              onClick={() =>
                mutate1ObjectInWarehouse("inventoryToTransferManage", {
                  searchInData: {
                    ...searchInData,
                    renderToolbar: false,
                    data: "",
                    searchBy: "all",
                  },
                })
              }
            />
          }
        />
      </FormControl>
    </Toolbar>
  );
}
