import styled from "styled-components";
import { Button } from "antd";

const OnboardMainButton = styled(Button)`
  width: ${(props) => props.theme.onboardMainButton.width};
  height: ${(props) => props.theme.onboardMainButton.height};
  color: ${(props) => props.theme.onboardMainButton.color};
  background-color: ${(props) => props.theme.onboardMainButton.backgroundColor};
  text-transform: ${(props) => props.theme.onboardMainButton.textTransform};
  text-align: center;
`;

export default OnboardMainButton;
