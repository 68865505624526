//Libs
import React from "react";
//Utils
import { GlobalUtils } from "utils";
import { filterOption } from "utils/libs";
//Components
import { Wrapper, Select, TextField, Title } from "components/components";

const { Option } = Select;
const { Input } = TextField;

const CompletedAuthorizationCodes = ({
  completedCodeId,
  completedAuthorizationCode,
  completedCodeProps,
  completedAuthorizationCodeProps,
  completedCodes,
  changeMyState,
}) => {
  const completedCodePlaceholder = `Selecciona un item${
    !!completedCodeProps.required ? "*" : ""
  }`;
  const completedAuthorizationCodePlaceholder = `Ingresa un valor${
    !!completedAuthorizationCodeProps.required ? "*" : ""
  }`;

  return (
    <>
      {/* COMPLETED CODE ID */}
      {!GlobalUtils.isEmptyObject(completedCodeProps) && (
        <Wrapper
          margin="10px 0 0 0"
          padding="0"
          flexDirection="column"
          alignItems="stretch"
        >
          <Title>{completedCodeProps.label ?? "Código de completado"}</Title>
          <Wrapper
            width="100%"
            padding="0 10px"
            flexDirection="column"
            alignItems="stretch"
          >
            <Select
              margin="10px 0 0 0"
              showSearch
              placeholder={completedCodePlaceholder}
              value={completedCodeId}
              filterOption={filterOption}
              onChange={(value) => changeMyState({ completedCodeId: value })}
            >
              {completedCodes.map(({ id, name }, idx) => (
                <Option key={idx} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Wrapper>
        </Wrapper>
      )}

      {/* COMPLETED AUTHORIZATION CODE */}
      {!GlobalUtils.isEmptyObject(completedAuthorizationCodeProps) && (
        <Wrapper
          margin="10px 0 0 0"
          padding="0"
          flexDirection="column"
          alignItems="stretch"
        >
          <Title>
            {completedAuthorizationCodeProps.label ?? "Código de Autorización"}
          </Title>
          <Wrapper
            padding="0 10px"
            width="100%"
            padding="0 10px"
            flexDirection="column"
            alignItems="stretch"
          >
            <Input
              padding="0 10px"
              margin="10px 0 0 0"
              placeholder={completedAuthorizationCodePlaceholder}
              value={completedAuthorizationCode}
              onChange={(e) =>
                changeMyState({ completedAuthorizationCode: e.target.value })
              }
            />
          </Wrapper>
        </Wrapper>
      )}
    </>
  );
};

export default CompletedAuthorizationCodes;
