//Libs
import { createSelector } from "reselect";
//Selectors
import { selectCompletedCodes } from "store/api/selectors";
import { GlobalUtils, ProjectUtils, ServiceUtils } from "utils";
import { selectPendingCollectorCountFromOrder } from "components/components/Collector/selectors";
import { selectPendingConsumedItemCountFromOrder } from "components/components/ConsumeInventory/selectors";

const selectOrders = (state) => state.orders;
const selectCollector = (state) => state.collector;

export const selectCompleteOrderId = createSelector(
  [selectCollector],
  ({ reviewManage }) => reviewManage.orderId
);
export const selectCompleteOrder = createSelector(
  [selectOrders, selectCompleteOrderId],
  ({ control }, orderId) =>
    control.orders.find((ord) => ord.order_id === orderId)
);
export const selectControlOrders = createSelector(
  [selectOrders],
  ({ control }) => control
);
export const selectGetOrdersModal = createSelector(
  [selectOrders],
  ({ getOrdersModal }) => getOrdersModal
);
export const selectAutoSyncOfflineData = createSelector(
  [selectOrders],
  ({ autoSyncOfflineData }) => autoSyncOfflineData
);
export const selectCompleteOrderControl = createSelector(
  [selectOrders],
  ({ completeOrderControl }) => completeOrderControl
);
export const getOrderInformation = createSelector(
  [selectOrders],
  (orders) => orders.getOrderInformationModal
);
export const selectOrderFromOrderInformation = createSelector(
  [selectOrders],
  ({ control, getOrderInformationModal }) =>
    control.orders.find(
      (ord) => ord.order_id === getOrderInformationModal.order_id
    )
);
export const selectSynchronizingPhotos = createSelector(
  [selectOrders],
  (orders) => orders.control.synchronizingPhotos
);
export const selectCompletedCodesFromDepartment = createSelector(
  [selectCompleteOrder, selectCompletedCodes],
  (order, completedCodes) =>
    completedCodes.filter((code) => code.departmentId === order.department_id)
);
export const selectIsCollectorAndConsumedItemSuccess = createSelector(
  [
    selectPendingCollectorCountFromOrder,
    selectPendingConsumedItemCountFromOrder,
  ],
  (pendingCollectorCountFromOrder, pendingConsumedItemCountFromOrder) => {
    return (
      pendingCollectorCountFromOrder === 0 &&
      pendingConsumedItemCountFromOrder === 0
    );
  }
);

//Completed Code Props
export const selectCompletedCodeProps = createSelector(
  [selectCompleteOrder],
  (order) =>
    GlobalUtils.selectCurrentProps([
      ProjectUtils.getProjectPropsFromOrder("completedCode", order),
      ServiceUtils.getServicePropsFromOrder("completedCode", order),
    ])
);

//Completed Authorization Code Props
export const selectCompletedAuthorizationCodeProps = createSelector(
  [selectCompleteOrder],
  (order) =>
    GlobalUtils.selectCurrentProps([
      ProjectUtils.getProjectPropsFromOrder(
        "completedAuthorizationCode",
        order
      ),
      ServiceUtils.getServicePropsFromOrder(
        "completedAuthorizationCode",
        order
      ),
    ])
);
