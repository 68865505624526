//Libs
import React from "react";
import { useTheme } from "styled-components";
// Utils
import orderBy from "utils/libs/orderBy";
//Styles
import styles from "./SignPaymentModal.module.css";
import {
  Bonification,
  Discounts,
  Production,
  Resume,
  Signed,
} from "./CommonSignPayment";
//Utils
import {
  getTotalProduction,
  getTotalBonifications,
  getTotalDiscounts,
  getTotalToPay,
} from "../../../../Payments/utils";
// Components
import { Wrapper } from "../../../../../components";
// Utils
import { coinsUtils } from "../../../../../../utils/libs";

export default function SignPaymentModal(props) {
  const {
    pay,
    totalUsers,
    signPaymentModal,
    signPayment,
    resume,
    coins,
    orders,
    profile,
    getOrder,
    mutate1ObjectInOrders,
  } = props;

  const theme = useTheme();
  // Production
  const coinSymbol = coinsUtils.getDataByCoinId(pay.coin_id, "symbol", coins);
  const prodAmounts = getTotalProduction(resume.orders, pay.iva, coinSymbol);

  // Bonification
  const bonifications = resume.lines.filter((line) => line.action === "credit");
  const bonusAmounts = getTotalBonifications(
    bonifications,
    pay.iva,
    coinSymbol
  );

  // Discounts
  const discounts = resume.lines.filter((line) => line.action === "debit");
  const discountsAmounts = getTotalDiscounts(discounts, pay.iva, coinSymbol);
  // Resume
  const totalToPay = getTotalToPay(
    prodAmounts.productionTotal,
    bonusAmounts.bonificationTotal,
    discountsAmounts.discountTotal,
    coinSymbol
  );
  // Signed
  const signed = resume.payments.reduce((acc, payment) => {
    if (Array.isArray(payment.signed)) acc = acc.concat(payment.signed);
    return acc;
  }, []);
  orderBy(signed, "objectDate", {
    objectKey: "created_at",
    dateFormat: profile?.user?.settings?.date_format,
  });

  return (
    <Wrapper width="100%" padding="0" flexDirection="column" overflow="auto">
      <div className={styles.container}>
        <div className={styles.body}>
          {/* production */}
          <Production
            coinSymbol={coinSymbol}
            prodAmounts={prodAmounts}
            resume={resume}
            signPaymentModal={signPaymentModal}
            orders={orders}
            getOrder={getOrder}
            mutate1ObjectInOrders={mutate1ObjectInOrders}
          />
          {/* bonification */}
          <Bonification
            bonusAmounts={bonusAmounts}
            bonifications={bonifications}
            coinSymbol={coinSymbol}
            orders={orders}
            getOrder={getOrder}
            mutate1ObjectInOrders={mutate1ObjectInOrders}
            signPaymentModal={signPaymentModal}
          />
          {/* Discounts */}
          <Discounts
            discountsAmounts={discountsAmounts}
            discounts={discounts}
            coinSymbol={coinSymbol}
            orders={orders}
            getOrder={getOrder}
            mutate1ObjectInOrders={mutate1ObjectInOrders}
            signPaymentModal={signPaymentModal}
          />
          {/* Resume */}
          <Resume
            prodAmounts={prodAmounts}
            bonusAmounts={bonusAmounts}
            discountsAmounts={discountsAmounts}
            pay={pay}
            totalToPay={totalToPay}
            theme={theme}
          />
          {/* Signed */}
          <Signed
            signed={signed}
            totalUsers={totalUsers}
            resume={resume}
            signPaymentModal={signPaymentModal}
            signPayment={signPayment}
            pay={pay}
          />
        </div>
      </div>
    </Wrapper>
  );
}
