//Libs
import React from "react";
//Components
import { Modal, VirtualizedList } from "components/containers/Utils";
import { Wrapper, Empty } from "components/components";
import { SignPaymentHeader } from "components/containers/Utils/Modal/subs/Payments/CommonSignPayment";
//Utils
import { getSelectedPayments } from "utils/libs/filters/payments";
import { filterPaymentsSearchInData } from "../utils";

function PaymentsManage(props) {
  const {
    //BILLINGS
    paymentsManage,
    signPaymentModal,
    mutate1Object,
    signPayment,
    getPaymentResume,
    //ORDERS
    mutate1ObjectInOrders,
    ordersState,
    // handleOnClickOrderInformationTab,
    getOrder,
    //API
    payments,
    coins,
    //GENERAL
    profile,
  } = props;
  const allPayments = payments.pays.data;
  const { control } = ordersState;
  const totalUsers = props.users;

  const selectedPayments = getSelectedPayments(
    allPayments,
    paymentsManage.selected.items
  );
  const firstSelectedPayment = selectedPayments[0];

  let filteredPays = payments.pays.data;
  filteredPays = filterPaymentsSearchInData(
    filteredPays,
    paymentsManage.searchInData.searchBy,
    paymentsManage.searchInData.data
  );

  return (
    <Wrapper padding="8px" justifyContent="center" className="animated fadeIn">
      {!payments.pays.loading && filteredPays.length === 0 ? (
        <Empty style={{ marginTop: "20px" }} />
      ) : (
        <>
          <VirtualizedList
            cardId="payCard"
            cardStyle={{
              height: "fit-content",
              width: 328,
              boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.4)",
            }}
            listStyle={{
              height: document.documentElement.clientHeight,
              width: document.documentElement.clientWidth,
            }}
            rowHeight={370}
            spinTitle="Consultando Pagos..."
            isSpinning={payments.pays.loading}
            rows={filteredPays}
            customProps={{
              getPaymentResume,
              paymentsManage,
              mutate1ObjectInPayments: mutate1Object,
              profile,
            }}
          />
        </>
      )}

      {/* GET ORDER INFORMATION */}
      {/* <OrderInformation
        handleOnClickOrderInformationTab={handleOnClickOrderInformationTab}
      /> */}

      {/* SIGN PAYMENT */}
      {paymentsManage.selected.items.length >= 1 && firstSelectedPayment && (
        <Modal
          modalId="SignPaymentModal"
          title={
            <SignPaymentHeader
              resume={payments.resume}
              pay={firstSelectedPayment}
              getPaymentResume={getPaymentResume}
              signPaymentModal={signPaymentModal}
              mutate1ObjectInPayments={mutate1Object}
            />
          }
          visible={signPaymentModal.isOpen}
          onCancel={() => mutate1Object("signPaymentModal", { isOpen: false })}
          footer={null}
          isSpinning={payments.resume.loading}
          sizeSpinning="large"
          delaySpinning={50}
          tipSpinning="Un momento porfavor..."
          customProps={{
            totalUsers,
            pay: firstSelectedPayment,
            signPaymentModal,
            signPayment,
            resume: payments.resume,
            coins,
            orders: control.orders,
            profile,
            getOrder,
            mutate1ObjectInOrders,
          }}
        />
      )}
    </Wrapper>
  );
}

export default PaymentsManage;
