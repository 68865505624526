import { Drawer } from "antd";
import styled from "styled-components";

const DrawerStyled = styled(Drawer)`
  .ant-drawer-title {
    font-weight: ${(props) =>
      props.header_font_weight
        ? props.header_font_weight
        : props.theme.drawer.header.fontWeight};
    color: ${(props) =>
      props.headerColor ? props.headerColor : props.theme.drawer.header.color};
  }
  .ant-drawer-header {
    background-color: ${(props) =>
      props.headerBackgroundColor
        ? props.headerBackgroundColor
        : props.theme.drawer.header.backgroundColor};
    background-image: ${(props) => props.header_background_image};
    padding: 3px 10px;
  }
  .ant-drawer-content {
    background-color: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : props.theme.drawer.content.backgroundColor};
    border: ${(props) =>
      props.content_border
        ? props.content_border
        : props.theme.drawer.content.border};
    background-image: ${(props) => props.content_background_image};
  }
  .ant-drawer-body {
    padding: ${(props) =>
      props.padding ? props.padding : props.theme.drawer.padding};
  }
`;

export default DrawerStyled;
