//Libs
import React, { useContext } from "react";
//Context
import { PhotoContext } from "context";
//Components
import { Wrapper, Subtitle, Select } from "components/components";

const { Option } = Select;

const SelectDevices = () => {
  const { deviceId, devices, setSetting } = useContext(PhotoContext);
  return (
    <Wrapper padding="0" flexDirection="column" alignItems="flex-start">
      <Subtitle color="white">Cámara</Subtitle>
      <Select
        value={deviceId}
        width="300px"
        onChange={(value) => setSetting("deviceId", value)}
        color="white"
      >
        {devices.map((d, idx) => (
          <Option key={d.deviceId}>
            {d.label ? d.label : `Cámara ${idx}`}
          </Option>
        ))}
      </Select>
    </Wrapper>
  );
};

export default SelectDevices;
