//  Constants
import GENERAL from "utils/constants/general";
const { UPLOAD_RESOURCE } = GENERAL;

//  InitialState
const initialState = {
  resources: [],
  autoSync: {
    active: true,
  },
};

const uploadResourceReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPLOAD_RESOURCE.UPDATE_RESOURCES: {
      return {
        ...state,
        resources: Array.isArray(payload) ? payload : state.resources,
      };
    }
    case UPLOAD_RESOURCE.UPDATE_AUTO_SYNC: {
      return {
        ...state,
        autoSync: {
          ...state.autoSync,
          ...payload,
        },
      };
    }
    default:
      return state;
  }
};

export default uploadResourceReducer;
