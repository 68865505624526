//Libs
import React from "react";
//Components
import { Wrapper, LogoIcon, Title } from "components/components";

const FetchingModules = () => {
  return (
    <Wrapper flexDirection="column" alignItems="center">
      <LogoIcon margin="0 0 20px 0" spin={true} size="l" />
      <Title>Inicializando</Title>
    </Wrapper>
  );
};

export default FetchingModules;
