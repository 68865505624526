//Libs
import _ from "underscore";
import React from "react";
//Components
import { Card } from "antd";
import {
  Wrapper,
  Title,
  Caption,
  InputNumber,
  ConsumeInventoryUtils,
  AddIcon,
  DeleteIcon,
} from "components/components";
import {
  ArticleTitle,
  InitialFinalInput,
} from "components/components/ConsumeInventory";
//Styles
import "./InventoryTransactionCard.css";
import { GlobalUtils } from "utils";

const { Meta } = Card;

function isSerieInTransaction(serie, serialized) {
  let idx = _.findIndex(serialized, (row) => row.serie === serie);
  return idx !== -1;
}

function getSelectedArticle(item_id, serie_id, articles) {
  const article = GlobalUtils.checkArray(articles).find((a) =>
    a.serie_id ? a.serie_id === serie_id : a.item_id === item_id
  );
  if (!article)
    return {
      amount: undefined,
      initialAmount: undefined,
      finalAmount: undefined,
    };
  return {
    amount: article.amount,
    initialAmount: article.initialAmount,
    finalAmount: article.finalAmount,
  };
}

const SerializedTitle = ({
  inventoryItem,
  serializedInTransaction,
  mutate1ObjectInApi,
  getSerieHistory,
  onAddSerie,
  onDeleteSerie,
}) => {
  return (
    <Wrapper
      padding="0"
      width="300px"
      height="60px"
      flexDirection="column"
      alignItems="stretch"
    >
      <Title
        ellipsis
        margin="0 20px 0 0"
        width="230px"
        onClick={() => {
          mutate1ObjectInApi("serieHistory", { isOpen: true });
          getSerieHistory(inventoryItem.serie);
        }}
      >
        {inventoryItem.serie}
      </Title>
      <Wrapper width="280px" padding="0" justifyContent="space-between">
        <Wrapper padding="0" flexDirection="column" alignItems="stretch">
          <Caption>{inventoryItem.item_name}</Caption>
          <Caption>ID: {inventoryItem.item_code}</Caption>
        </Wrapper>

        {isSerieInTransaction(inventoryItem.serie, serializedInTransaction) ? (
          <DeleteIcon onClick={() => onDeleteSerie(inventoryItem.serie)} />
        ) : (
          <AddIcon
            bold
            onClick={() =>
              onAddSerie(
                inventoryItem.item_id,
                inventoryItem.serie,
                inventoryItem.state_id,
                inventoryItem.warehouse_package_id
              )
            }
          />
        )}
      </Wrapper>
    </Wrapper>
  );
};

function ItemTransactionCard(inventoryItem) {
  const {
    articlesInTransaction,
    serializedInTransaction,
    mutate1ObjectInApi,
    getSerieHistory,
    onChangeInput,
    onAddSerie,
    onDeleteSerie,
  } = inventoryItem.customProps;

  const transactionArticle = getSelectedArticle(
    inventoryItem.item_id,
    inventoryItem.serie_id,
    articlesInTransaction
  );

  return (
    <Meta
      id="meta-card-container"
      title={
        !ConsumeInventoryUtils.checkInventoryItemIsSerialized(inventoryItem) ? (
          <ArticleTitle inventoryItem={inventoryItem} />
        ) : (
          <SerializedTitle
            inventoryItem={inventoryItem}
            serializedInTransaction={serializedInTransaction}
            mutate1ObjectInApi={mutate1ObjectInApi}
            getSerieHistory={getSerieHistory}
            onAddSerie={onAddSerie}
            onDeleteSerie={onDeleteSerie}
          />
        )
      }
      description={
        !ConsumeInventoryUtils.checkInventoryItemIsSerialized(
          inventoryItem
        ) && (
          <Wrapper padding="0">
            <Wrapper
              padding="0 10px"
              margin="0 10px 0 0"
              width="250px"
              height="50px"
              flexDirection="column"
              alignItems="stretch"
            >
              <Wrapper padding="0" justifyContent="space-between">
                <Caption>Disponible: {inventoryItem.amount}</Caption>
              </Wrapper>
              {inventoryItem.is_initial_final ? (
                <InitialFinalInput
                  amount={inventoryItem.amount}
                  amountTransit={inventoryItem.amount_transit}
                  initialAmount={transactionArticle.initialAmount || ""}
                  finalAmount={transactionArticle.finalAmount || ""}
                  step={ConsumeInventoryUtils.getInputProp({
                    inventoryItem,
                    prop: "step",
                  })}
                  min={ConsumeInventoryUtils.getInputProp({
                    inventoryItem,
                    prop: "min",
                  })}
                  isDecimal={ConsumeInventoryUtils.getInputProp({
                    inventoryItem,
                    prop: "decimal",
                  })}
                  onChange={(amount, initialAmount, finalAmount) =>
                    onChangeInput(
                      { amount, initialAmount, finalAmount },
                      inventoryItem
                    )
                  }
                />
              ) : (
                <InputNumber
                  placeholder="Cantidad"
                  step={ConsumeInventoryUtils.getInputProp({
                    inventoryItem,
                    prop: "step",
                  })}
                  min={0}
                  max={inventoryItem.amount}
                  value={transactionArticle.amount}
                  onChange={(amount) =>
                    onChangeInput({ amount }, inventoryItem)
                  }
                  parser={
                    ConsumeInventoryUtils.getInputProp({
                      inventoryItem,
                      prop: "decimal",
                    })
                      ? undefined
                      : (value) => ConsumeInventoryUtils.getIntegerValue(value)
                  }
                />
              )}
            </Wrapper>
          </Wrapper>
        )
      }
    />
  );
}

export default React.memo(ItemTransactionCard);
