//Libs
import React from "react";

export default ({ fill = "white", fillOpacity = "0.54" }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor="pointer"
  >
    <mask
      id="residentialMediumIcon"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="0"
      width="31"
      height="31"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66659 8V12H3.99992V6.66667H1.33325L8.66659 0L15.9098 6.58471L15.813 6.66667H13.3333V8.7677L10.6666 11.0271V8H6.66659ZM22.6665 13.3333V13.7637L18.8502 10.4805L24.6665 5.33333L31.9999 12H29.3948V17.3333H26.8159L26.6665 17.2048V13.3333H22.6665ZM13.1199 24L13.3333 30.6667H7.99992V22.6644H3.99992L15.9999 12L28.0213 22.6644H23.9999V30.6667H18.6666V24H13.1199Z"
        fill="white"
      />
    </mask>
    <g mask="url(#residentialMediumIcon)">
      <rect width="32" height="32" fill={fill} fillOpacity={fillOpacity} />
    </g>
  </svg>
);
