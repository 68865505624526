//Libs
import React from "react";
import { useTheme } from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
//Components
import { Wrapper, Span } from ".";

const LineDescription = ({
  wrapperStyle,
  conceptStyle,
  valueStyle,
  concept,
  value,
  padding,
  conceptEllipsis,
  valueEllipsis,
  textAlign = "end",
  fontSize,
  onClickValue,
}) => {
  const theme = useTheme();

  return (
    <Wrapper
      style={{ ...wrapperStyle }}
      padding={padding ? padding : "2px"}
      justifyContent="space-between"
      alignItems="baseline"
    >
      <Span
        fontSize={fontSize}
        color={theme.colors.text.medium}
        ellipsis={conceptEllipsis}
        style={{ marginRight: "10px", ...conceptStyle }}
      >
        {concept}
      </Span>
      <CopyToClipboard text={value}>
        <Span
          activeColor={theme.colors.text.medium}
          cursor="pointer"
          textAlign={textAlign}
          fontSize={fontSize}
          ellipsis={valueEllipsis}
          style={{ ...valueStyle }}
          onClick={onClickValue}
        >
          {value}
        </Span>
      </CopyToClipboard>
    </Wrapper>
  );
};

export default LineDescription;
