//Libs
import React from "react";
import { connect } from "react-redux";
//Utils
import MODULE_KEYWORDS from "components/components/Modules/keywords";
//Actions
import { mutate1Object as mutate1ObjectInCommandCenter } from "../actions";
import { getDynamicMyActiveModuleItems } from "components/components/Modules/actions";
//Selectors
import {
  selectOrderTargetFiltersIsFetching,
  selectSelectedProjectId,
  selectOrderTargetFilters,
  selectSelectedOrderTargetFilterId,
} from "../selectors";
//Components
import {
  Wrapper,
  LogoIcon,
  Tooltip,
  CentralIcon,
  CabinetIcon,
  ResidentialIcon,
} from "components/components";

const SelectedIcon = ({ type, value, isSelected }) => {
  const { ICONS, TYPES } = MODULE_KEYWORDS.MODULES.ITEMS.ICON;
  if (type === TYPES.ICON) {
    if (value === ICONS.CENTRALS)
      return <CentralIcon selected={isSelected} size="normal" />;
    if (value === ICONS.CABINETS)
      return <CabinetIcon selected={isSelected} size="normal" />;
    if (value === ICONS.RESIDENTIAL)
      return <ResidentialIcon selected={isSelected} size="normal" />;
    return null;
  }
};
const OrderTargetFilterItem = React.memo(
  ({ idx, margin, filterItem, selectedOrderTargetFilterId, onClick }) => {
    const { id, name, icon } = filterItem;
    const { type, value } = icon;

    const isSelected = React.useMemo(
      () => id === selectedOrderTargetFilterId,
      [selectedOrderTargetFilterId]
    );
    return (
      <Wrapper key={idx} padding="0" margin={margin} onClick={onClick}>
        <Tooltip title={name}>
          <div>
            <SelectedIcon type={type} value={value} isSelected={isSelected} />
          </div>
        </Tooltip>
      </Wrapper>
    );
  }
);

const OrderTargetFilterPicker = React.memo(
  ({
    selectedOrderTargetFilterId,
    selectedProjectId,
    isFetching,
    orderTargetFilters,
    getDynamicMyActiveModuleItems,
    mutate1ObjectInCommandCenter,
  }) => {
    React.useEffect(() => {
      if (selectedProjectId)
        getDynamicMyActiveModuleItems({
          component: MODULE_KEYWORDS.MODULES.ITEMS.COMPONENTS.FILTER.ITEM,
          key: MODULE_KEYWORDS.MODULES.ITEMS.KEYS.ORDERS.FILTERS.TARGETS,
          projectId: selectedProjectId,
          onLoading: () =>
            mutate1ObjectInCommandCenter("orderTargetFilters", {
              isFetching: true,
              selectedOrderTargetFilterId: undefined,
              data: [],
            }),
          onSuccess: (data) =>
            mutate1ObjectInCommandCenter("orderTargetFilters", {
              isFetching: false,
              data,
            }),
        });
    }, [selectedProjectId]);

    return (
      <Wrapper
        padding="0 10px"
        justifyContent={isFetching ? "center" : "flex-start"}
      >
        {isFetching && <LogoIcon spin={true} />}
        {orderTargetFilters.map((filterItem, idx) => (
          <OrderTargetFilterItem
            idx={idx}
            margin={idx + 1 !== orderTargetFilters.length ? "0 10px 0 0" : ""}
            filterItem={filterItem}
            selectedOrderTargetFilterId={selectedOrderTargetFilterId}
            onClick={() =>
              mutate1ObjectInCommandCenter("orderTargetFilters", {
                selectedOrderTargetFilterId: filterItem.id,
              })
            }
          />
        ))}
      </Wrapper>
    );
  }
);

const mapStateToProps = (state) => ({
  selectedProjectId: selectSelectedProjectId(state),
  selectedOrderTargetFilterId: selectSelectedOrderTargetFilterId(state),
  isFetching: selectOrderTargetFiltersIsFetching(state),
  orderTargetFilters: selectOrderTargetFilters(state),
});
const actions = {
  getDynamicMyActiveModuleItems,
  mutate1ObjectInCommandCenter,
};
export default connect(mapStateToProps, actions)(OrderTargetFilterPicker);
