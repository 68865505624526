//Libs
import React from "react";
//Utils
import { SourcePhotos } from "components/AppConfig.json";
//Components
import {
  Wrapper,
  LineDescription,
  Avatar,
  Subtitle,
  Caption,
  EyeIcon,
} from "components/components";
import { haveMoreEventInfo } from "components/components/OrderInformation/Tabs/HistoryEventTab/MoreEventInfo";
import { Card } from "antd";
//Styles
import "./OrderHistoryCard.css";

const { Meta } = Card;

const OrderHistoryCard = (event) => {
  const {
    id,
    event_name,
    expert_name,
    creator_name,
    created_at,
    created_by,
    customProps,
  } = event;
  return (
    <Meta
      id="order_history_card-meta"
      avatar={
        <Avatar
          bgTransparent
          fit
          size="small"
          avatar={{
            src: SourcePhotos.concat(
              `/syn4pse/users/avatars/${created_by}.jpg`
            ),
          }}
        />
      }
      title={
        <Wrapper
          padding="0"
          margin="0 0 10px 0"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Wrapper padding="0">
            <Subtitle ellipsis width="220px" whiteSpace="nowrap">
              {creator_name}
            </Subtitle>
          </Wrapper>
          <Caption light>{created_at}</Caption>
        </Wrapper>
      }
      description={
        <Wrapper padding="0 10px 0 10px" justifyContent="space-between">
          <Wrapper padding="0" flexDirection="column" alignItems="flex-start">
            <LineDescription
              fontSize="m"
              textAlign="left"
              concept="Evento"
              value={event_name}
            />
            <LineDescription
              valueEllipsis
              fontSize="m"
              textAlign="left"
              concept="Asignación"
              value={expert_name ?? ""}
              valueStyle={{ width: "120px" }}
            />
          </Wrapper>
          {haveMoreEventInfo(event) && (
            <EyeIcon
              button
              selected={id === customProps.eventMoreInfo.historyId}
              onClick={() => customProps?.onOpenMoreEventInfo(id)}
            />
          )}
        </Wrapper>
      }
    />
  );
};

export default OrderHistoryCard;
