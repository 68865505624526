//Utils
import GENERAL from "utils/constants/general";
import KEYWORDS from "./keywords";
import ModuleUtils from "./utils";
//Components
import React from "react";
import App from "components/App";
import {
  Login,
  Signup,
  Dashboard,
  Orders,
  Warehouse,
  RecoveryPassword,
} from "components/containers";
// import {
//   //PAYMENTS
//   Payments,
//   PaymentsPaycutsMobile,
//   PaymentsPaycutsDesktop,
//   PaymentsPaysMobile,
//   PaymentsPaysDesktop,
//   PaymentsLinesMobile,
//   PaymentsLinesDesktop,
//   PaymentsOrdersMobile,
//   PaymentsOrdersDesktop,
//   //INVOICES
//   Invoices,
//   InvoicesPaycutsMobile,
//   InvoicesPaycutsDesktop,
//   InvoicesPaysMobile,
//   InvoicesPaysDesktop,
//   InvoicesLinesMobile,
//   InvoicesLinesDesktop,
//   InvoicesOrdersMobile,
//   InvoicesOrdersDesktop,
// } from "components/containers/Billings/subs";
// import {
//   WarehousesList,
//   WarehousesListMobile,
//   WarehousesListDesktop,
//   StockArticlesMobile,
//   StockArticlesDesktop,
//   StockSeriesMobile,
//   StockSeriesDesktop,
//   Articles,
//   ArticlesMobile,
//   ArticlesDesktop,
//   Transactions,
//   TransactionsMobile,
//   TransactionsDesktop,
//   TransactionNewEntryMobile,
//   TransactionNewEntryDesktop,
//   TransactionInventoryMobile,
//   TransactionInventoryDesktop,
//   TransactionItemsMobile,
//   TransactionItemsDesktop,
//   TransactionReception,
//   ControlSeries,
//   ControlSeriesMobile,
//   ControlSeriesDesktop,
// } from "components/containers/Warehouses/subs";
// import { Marketplace } from "components/components";
//Screens
import FetchingModules from "./FetchingModules";
import NoModuleEstablished from "./NoModuleEstablished";

const { ENV } = GENERAL;
const { ROUTES } = ENV;

const ONBOARDING_ROUTES = KEYWORDS.ROUTES.ONBOARDING;

const AppRoute = {
  path: ROUTES.PATHS.ROOT,
  component: App,
  routes: [],
};

//FETCHING MODULES
export const fetchingModulesRoute = [
  {
    ...AppRoute,
    routes: [
      {
        path: ONBOARDING_ROUTES.FETCHING_MODULES,
        component: FetchingModules,
      },
    ],
  },
];

//NO MODULE ESTABLISHED
export const noModuleEstablishedRoute = [
  {
    ...AppRoute,
    routes: [
      //FETCHING MODULES
      {
        path: ONBOARDING_ROUTES.NO_MODULE_ESTABLISHED,
        component: NoModuleEstablished,
      },
    ],
  },
];

//UNAUTHORIZED
export const unauthorizedRoutes = [
  {
    ...AppRoute,
    routes: [
      //LOGIN
      {
        path: ROUTES.PATHS.LOGIN,
        component: Login,
      },
      //SIGNUP
      {
        path: ROUTES.PATHS.SIGNUP,
        component: Signup,
      },
      //RECOVERY_PASSWORD
      {
        path: ROUTES.PATHS.RECOVERY_PASSWORD,
        component: RecoveryPassword,
      },
    ],
  },
];

//AUTHORIZED
export const authorizedRoutes = ({ modules }) => {
  const authorizedRoutes = [];

  //COMMAND_CENTER
  // const geolocationModule = ModuleUtils.findModuleByKey(
  //   KEYWORDS.MODULES.KEYS.COMMAND_CENTER,
  //   modules
  // );
  // const geolocationControlItem = ModuleUtils.findModuleItemByComponentKey(
  //   KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
  //   KEYWORDS.MODULES.ITEMS.KEYS.COMMAND_CENTER.CONTROL,
  //   geolocationModule?.moduleItems
  // );
  // if (geolocationModule && geolocationControlItem) {
  //   authorizedRoutes.push({
  //     id: ROUTES.IDS.COMMAND_CENTER,
  //     item: ROUTES.ITEMS.COMMAND_CENTER,
  //     title: ROUTES.TITLES.COMMAND_CENTER,
  //     path: ROUTES.PATHS.COMMAND_CENTER,
  //     component: CommandCenter,
  //   });
  // }

  //ANALYTICS
  // const analyticsModule = ModuleUtils.findModuleByKey(
  //   KEYWORDS.MODULES.KEYS.ANALYTICS,
  //   modules
  // );
  // const analyticsControlItem = ModuleUtils.findModuleItemByComponentKey(
  //   KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
  //   KEYWORDS.MODULES.ITEMS.KEYS.ANALYTICS.CONTROL,
  //   analyticsModule?.moduleItems
  // );
  // if (analyticsModule && analyticsControlItem) {
  //   authorizedRoutes.push({
  //     id: ROUTES.IDS.ANALYTICS,
  //     item: ROUTES.ITEMS.ANALYTICS,
  //     title: ROUTES.TITLES.ANALYTICS,
  //     path: ROUTES.PATHS.ANALYTICS,
  //     component: Analytics,
  //   });
  // }

  //USERS
  // authorizedRoutes.push({
  //   id: ROUTES.IDS.USERS,
  //   item: ROUTES.ITEMS.USERS,
  //   path: ROUTES.PATHS.USERS,
  //   title: ROUTES.TITLES.USERS,
  //   component: Users,
  // });

  //ORDERS
  const ordersModule = ModuleUtils.findModuleByKey(
    KEYWORDS.MODULES.KEYS.ORDERS,
    modules
  );
  const ordersControlItem = ModuleUtils.findModuleItemByComponentKey(
    KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
    KEYWORDS.MODULES.ITEMS.KEYS.ORDERS.CONTROL,
    ordersModule?.moduleItems
  );

  if (ordersModule && ordersControlItem) {
    authorizedRoutes.push({
      id: ROUTES.IDS.ORDERS,
      item: ROUTES.ITEMS.ORDERS,
      path: ROUTES.PATHS.ORDERS,
      title: ROUTES.TITLES.ORDERS_MANAGE,
      component: Orders,
    });
  }

  //SUPERVISOR
  // const supervisorModule = ModuleUtils.findModuleByKey(
  //   KEYWORDS.MODULES.KEYS.SUPERVISOR,
  //   modules
  // );
  // const supervisorControlItem = ModuleUtils.findModuleItemByComponentKey(
  //   KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
  //   KEYWORDS.MODULES.ITEMS.KEYS.SUPERVISOR.CONTROL,
  //   supervisorModule?.moduleItems
  // );
  // if (supervisorModule && supervisorControlItem) {
  //   authorizedRoutes.push({
  //     id: ROUTES.IDS.SUPERVISOR,
  //     item: ROUTES.ITEMS.SUPERVISOR,
  //     path: ROUTES.PATHS.SUPERVISOR,
  //     title: ROUTES.TITLES.SUPERVISOR,
  //     component: Supervisor,
  //   });
  //   authorizedRoutes.push({
  //     id: ROUTES.IDS.SUPERVISOR_TARGET,
  //     path: ROUTES.PATHS.SUPERVISOR_TARGET,
  //     title: ROUTES.TITLES.SUPERVISOR_TARGET,
  //     component: Supervisor,
  //   });
  // }

  //REPORTS
  // const reportsModule = ModuleUtils.findModuleByKey(
  //   KEYWORDS.MODULES.KEYS.REPORTS,
  //   modules
  // );
  // const reportsControlItem = ModuleUtils.findModuleItemByComponentKey(
  //   KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
  //   KEYWORDS.MODULES.ITEMS.KEYS.REPORTS.CONTROL,
  //   reportsModule?.moduleItems
  // );
  // if (reportsModule && reportsControlItem) {
  //   authorizedRoutes.push({
  //     id: ROUTES.IDS.REPORTS,
  //     item: ROUTES.ITEMS.REPORTS,
  //     path: ROUTES.PATHS.REPORTS,
  //     title: ROUTES.TITLES.REPORTS,
  //     component: Reports,
  //   });
  // }

  //BILLINGS
  // const billingsModule = ModuleUtils.findModuleByKey(
  //   KEYWORDS.MODULES.KEYS.BILLINGS,
  //   modules
  // );
  // if (billingsModule) {
  //   authorizedRoutes.push({
  //     id: ROUTES.IDS.BILLINGS,
  //     item: ROUTES.ITEMS.BILLINGS,
  //     path: ROUTES.PATHS.BILLINGS,
  //     component: Billings,
  //     routes: [
  //       //PAYMENTS
  //       !!ModuleUtils.findModuleItemByComponentKey(
  //         KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
  //         KEYWORDS.MODULES.ITEMS.KEYS.BILLINGS.PAYMENTS,
  //         billingsModule?.moduleItems
  //       ) && {
  //         id: ROUTES.IDS.BILLINGS_PAYMENTS,
  //         item: ROUTES.ITEMS.BILLINGS_PAYMENTS,
  //         path: ROUTES.PATHS.BILLINGS_PAYMENTS,
  //         component: Payments,
  //         routes: [
  //           //PAYCUTS MOBILE
  //           {
  //             id: ROUTES.IDS.BILLINGS_PAYMENTS_PAYCUTS_MOBILE,
  //             title: ROUTES.TITLES.BILLINGS_PAYMENTS_PAYCUTS_MOBILE,
  //             path: ROUTES.PATHS.BILLINGS_PAYMENTS_PAYCUTS_MOBILE,
  //             component: PaymentsPaycutsMobile,
  //           },
  //           //PAYCUTS DESKTOP
  //           {
  //             id: ROUTES.IDS.BILLINGS_PAYMENTS_PAYCUTS_DESKTOP,
  //             title: ROUTES.TITLES.BILLINGS_PAYMENTS_PAYCUTS_DESKTOP,
  //             path: ROUTES.PATHS.BILLINGS_PAYMENTS_PAYCUTS_DESKTOP,
  //             component: PaymentsPaycutsDesktop,
  //           },
  //           //PAYS MOBILE
  //           {
  //             id: ROUTES.IDS.BILLINGS_PAYMENTS_PAYS_MOBILE,
  //             title: ROUTES.TITLES.BILLINGS_PAYMENTS_PAYS_MOBILE,
  //             path: ROUTES.PATHS.BILLINGS_PAYMENTS_PAYS_MOBILE,
  //             component: PaymentsPaysMobile,
  //           },
  //           //PAYS DESKTOP
  //           {
  //             id: ROUTES.IDS.BILLINGS_PAYMENTS_PAYS_DESKTOP,
  //             title: ROUTES.TITLES.BILLINGS_PAYMENTS_PAYS_DESKTOP,
  //             path: ROUTES.PATHS.BILLINGS_PAYMENTS_PAYS_DESKTOP,
  //             component: PaymentsPaysDesktop,
  //           },
  //           //LINES MOBILE
  //           {
  //             id: ROUTES.IDS.BILLINGS_PAYMENTS_LINES_MOBILE,
  //             title: ROUTES.TITLES.BILLINGS_PAYMENTS_LINES_MOBILE,
  //             path: ROUTES.PATHS.BILLINGS_PAYMENTS_LINES_MOBILE,
  //             component: PaymentsLinesMobile,
  //           },
  //           //LINES DESKTOP
  //           {
  //             id: ROUTES.IDS.BILLINGS_PAYMENTS_LINES_DESKTOP,
  //             title: ROUTES.TITLES.BILLINGS_PAYMENTS_LINES_DESKTOP,
  //             path: ROUTES.PATHS.BILLINGS_PAYMENTS_LINES_DESKTOP,
  //             component: PaymentsLinesDesktop,
  //           },
  //           //ORDERS MOBILE
  //           {
  //             id: ROUTES.IDS.BILLINGS_PAYMENTS_ORDERS_MOBILE,
  //             title: ROUTES.TITLES.BILLINGS_PAYMENTS_ORDERS_MOBILE,
  //             path: ROUTES.PATHS.BILLINGS_PAYMENTS_ORDERS_MOBILE,
  //             component: PaymentsOrdersMobile,
  //           },
  //           //ORDERS DESKTOP
  //           {
  //             id: ROUTES.IDS.BILLINGS_PAYMENTS_ORDERS_DESKTOP,
  //             title: ROUTES.TITLES.BILLINGS_PAYMENTS_ORDERS_DESKTOP,
  //             path: ROUTES.PATHS.BILLINGS_PAYMENTS_ORDERS_DESKTOP,
  //             component: PaymentsOrdersDesktop,
  //           },
  //         ],
  //       },
  //       //INVOICES
  //       !!ModuleUtils.findModuleItemByComponentKey(
  //         KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
  //         KEYWORDS.MODULES.ITEMS.KEYS.BILLINGS.INVOICES,
  //         billingsModule?.moduleItems
  //       ) && {
  //         id: ROUTES.IDS.BILLINGS_INVOICES,
  //         item: ROUTES.ITEMS.BILLINGS_INVOICES,
  //         path: ROUTES.PATHS.BILLINGS_INVOICES,
  //         component: Invoices,
  //         routes: [
  //           //PAYCUTS MOBILE
  //           {
  //             id: ROUTES.IDS.BILLINGS_INVOICES_PAYCUTS_MOBILE,
  //             title: ROUTES.TITLES.BILLINGS_INVOICES_PAYCUTS_MOBILE,
  //             path: ROUTES.PATHS.BILLINGS_INVOICES_PAYCUTS_MOBILE,
  //             component: InvoicesPaycutsMobile,
  //           },
  //           //PAYCUTS DESKTOP
  //           {
  //             id: ROUTES.IDS.BILLINGS_INVOICES_PAYCUTS_DESKTOP,
  //             title: ROUTES.TITLES.BILLINGS_INVOICES_PAYCUTS_DESKTOP,
  //             path: ROUTES.PATHS.BILLINGS_INVOICES_PAYCUTS_DESKTOP,
  //             component: InvoicesPaycutsDesktop,
  //           },
  //           //PAYS MOBILE
  //           {
  //             id: ROUTES.IDS.BILLINGS_INVOICES_PAYS_MOBILE,
  //             title: ROUTES.TITLES.BILLINGS_INVOICES_PAYS_MOBILE,
  //             path: ROUTES.PATHS.BILLINGS_INVOICES_PAYS_MOBILE,
  //             component: InvoicesPaysMobile,
  //           },
  //           //PAYS DESKTOP
  //           {
  //             id: ROUTES.IDS.BILLINGS_INVOICES_PAYS_DESKTOP,
  //             title: ROUTES.TITLES.BILLINGS_INVOICES_PAYS_DESKTOP,
  //             path: ROUTES.PATHS.BILLINGS_INVOICES_PAYS_DESKTOP,
  //             component: InvoicesPaysDesktop,
  //           },
  //           //LINES MOBILE
  //           {
  //             id: ROUTES.IDS.BILLINGS_INVOICES_LINES_MOBILE,
  //             title: ROUTES.TITLES.BILLINGS_INVOICES_LINES_MOBILE,
  //             path: ROUTES.PATHS.BILLINGS_INVOICES_LINES_MOBILE,
  //             component: InvoicesLinesMobile,
  //           },
  //           //LINES DESKTOP
  //           {
  //             id: ROUTES.IDS.BILLINGS_INVOICES_LINES_DESKTOP,
  //             title: ROUTES.TITLES.BILLINGS_INVOICES_LINES_DESKTOP,
  //             path: ROUTES.PATHS.BILLINGS_INVOICES_LINES_DESKTOP,
  //             component: InvoicesLinesDesktop,
  //           },
  //           //ORDERS MOBILE
  //           {
  //             id: ROUTES.IDS.BILLINGS_INVOICES_ORDERS_MOBILE,
  //             title: ROUTES.TITLES.BILLINGS_INVOICES_ORDERS_MOBILE,
  //             path: ROUTES.PATHS.BILLINGS_INVOICES_ORDERS_MOBILE,
  //             component: InvoicesOrdersMobile,
  //           },
  //           //ORDERS DESKTOP
  //           {
  //             id: ROUTES.IDS.BILLINGS_INVOICES_ORDERS_DESKTOP,
  //             title: ROUTES.TITLES.BILLINGS_INVOICES_ORDERS_DESKTOP,
  //             path: ROUTES.PATHS.BILLINGS_INVOICES_ORDERS_DESKTOP,
  //             component: InvoicesOrdersDesktop,
  //           },
  //         ],
  //       },
  //     ],
  //   });
  // }

  //WAREHOUSE
  const warehousesModule = ModuleUtils.findModuleByKey(
    KEYWORDS.MODULES.KEYS.WAREHOUSES,
    modules
  );
  if (warehousesModule) {
    authorizedRoutes.push({
      id: ROUTES.IDS.WAREHOUSE,
      item: ROUTES.ITEMS.WAREHOUSE,
      path: ROUTES.PATHS.WAREHOUSE,
      component: Warehouse,
      // routes: [
      //   //ARTICLES
      //   {
      //     id: ROUTES.IDS.WAREHOUSES_ARTICLES,
      //     item: ROUTES.ITEMS.WAREHOUSES_ARTICLES,
      //     path: ROUTES.PATHS.WAREHOUSES_ARTICLES,
      //     component: Articles,
      //     routes: [
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_ARTICLES_MOBILE,
      //         title: ROUTES.TITLES.WAREHOUSES_ARTICLES_MOBILE,
      //         path: ROUTES.PATHS.WAREHOUSES_ARTICLES_MOBILE,
      //         component: ArticlesMobile,
      //       },
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_ARTICLES_DESKTOP,
      //         title: ROUTES.TITLES.WAREHOUSES_ARTICLES_DESKTOP,
      //         path: ROUTES.PATHS.WAREHOUSES_ARTICLES_DESKTOP,
      //         component: ArticlesDesktop,
      //       },
      //     ],
      //   },
      //   //CONTROL WAREHOUSE
      //   {
      //     id: ROUTES.IDS.WAREHOUSES_MANAGE,
      //     item: ROUTES.ITEMS.WAREHOUSES_MANAGE,
      //     path: ROUTES.PATHS.WAREHOUSES_MANAGE,
      //     component: WarehousesList,
      //     routes: [
      //       //WAREHOUSE LIST
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_MANAGE_MOBILE,
      //         title: ROUTES.TITLES.WAREHOUSES_MANAGE_MOBILE,
      //         path: ROUTES.PATHS.WAREHOUSES_MANAGE_MOBILE,
      //         component: WarehousesListMobile,
      //       },
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_MANAGE_DESKTOP,
      //         title: ROUTES.TITLES.WAREHOUSES_MANAGE_DESKTOP,
      //         path: ROUTES.PATHS.WAREHOUSES_MANAGE_DESKTOP,
      //         component: WarehousesListDesktop,
      //       },
      //       //STOCK ARTICLES
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_STOCK_ARTICLES_MOBILE,
      //         title: ROUTES.TITLES.WAREHOUSES_STOCK_ARTICLES_MOBILE,
      //         path: ROUTES.PATHS.WAREHOUSES_STOCK_ARTICLES_MOBILE,
      //         component: StockArticlesMobile,
      //       },
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_STOCK_ARTICLES_DESKTOP,
      //         title: ROUTES.TITLES.WAREHOUSES_STOCK_ARTICLES_DESKTOP,
      //         path: ROUTES.PATHS.WAREHOUSES_STOCK_ARTICLES_DESKTOP,
      //         component: StockArticlesDesktop,
      //       },
      //       //STOCK SERIES
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_STOCK_SERIES_MOBILE,
      //         title: ROUTES.TITLES.WAREHOUSES_STOCK_SERIES_MOBILE,
      //         path: ROUTES.PATHS.WAREHOUSES_STOCK_SERIES_MOBILE,
      //         component: StockSeriesMobile,
      //       },
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_STOCK_SERIES_DESKTOP,
      //         title: ROUTES.TITLES.WAREHOUSES_STOCK_SERIES_DESKTOP,
      //         path: ROUTES.PATHS.WAREHOUSES_STOCK_SERIES_DESKTOP,
      //         component: StockSeriesDesktop,
      //       },
      //     ],
      //   },
      //   //CONTROL SERIES
      //   !!ModuleUtils.findModuleItemByComponentKey(
      //     KEYWORDS.MODULES.ITEMS.COMPONENTS.MENU.ITEM,
      //     KEYWORDS.MODULES.ITEMS.KEYS.WAREHOUSES.SERIES.CONTROL,
      //     warehousesModule.moduleItems
      //   ) && {
      //     id: ROUTES.IDS.WAREHOUSES_CONTROL_SERIES,
      //     item: ROUTES.ITEMS.WAREHOUSES_CONTROL_SERIES,
      //     path: ROUTES.PATHS.WAREHOUSES_CONTROL_SERIES,
      //     component: ControlSeries,
      //     routes: [
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_CONTROL_SERIES_MOBILE,
      //         title: ROUTES.TITLES.WAREHOUSES_CONTROL_SERIES_MOBILE,
      //         path: ROUTES.PATHS.WAREHOUSES_CONTROL_SERIES_MOBILE,
      //         component: ControlSeriesMobile,
      //       },
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_CONTROL_SERIES_DESKTOP,
      //         title: ROUTES.TITLES.WAREHOUSES_CONTROL_SERIES_DESKTOP,
      //         path: ROUTES.PATHS.WAREHOUSES_CONTROL_SERIES_DESKTOP,
      //         component: ControlSeriesDesktop,
      //       },
      //     ],
      //   },
      //   //TRANSACTIONS
      //   {
      //     id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS,
      //     item: ROUTES.ITEMS.WAREHOUSES_TRANSACTIONS,
      //     path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS,
      //     component: Transactions,
      //     routes: [
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_MOBILE,
      //         title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_MOBILE,
      //         path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_MOBILE,
      //         component: TransactionsMobile,
      //       },
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_DESKTOP,
      //         title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_DESKTOP,
      //         path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_DESKTOP,
      //         component: TransactionsDesktop,
      //       },
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_MOBILE,
      //         title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_MOBILE,
      //         path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_MOBILE,
      //         component: TransactionNewEntryMobile,
      //       },
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_DESKTOP,
      //         title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_DESKTOP,
      //         path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_NEW_ENTRY_DESKTOP,
      //         component: TransactionNewEntryDesktop,
      //       },
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_INVENTORY_MOBILE,
      //         title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_INVENTORY_MOBILE,
      //         path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_INVENTORY_MOBILE,
      //         component: TransactionInventoryMobile,
      //       },
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_INVENTORY_DESKTOP,
      //         title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_INVENTORY_DESKTOP,
      //         path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_INVENTORY_DESKTOP,
      //         component: TransactionInventoryDesktop,
      //       },
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_ITEMS_MOBILE,
      //         title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_ITEMS_MOBILE,
      //         path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_ITEMS_MOBILE,
      //         component: TransactionItemsMobile,
      //       },
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_ITEMS_DESKTOP,
      //         title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_ITEMS_DESKTOP,
      //         path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_ITEMS_DESKTOP,
      //         component: TransactionItemsDesktop,
      //       },
      //       {
      //         id: ROUTES.IDS.WAREHOUSES_TRANSACTIONS_RECEPTION,
      //         title: ROUTES.TITLES.WAREHOUSES_TRANSACTIONS_RECEPTION,
      //         path: ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_RECEPTION,
      //         component: TransactionReception,
      //       },
      //     ],
      //   },
      // ],
    });
  }

  //MARKETPLACE
  // authorizedRoutes.push({
  //   id: ROUTES.IDS.MARKETPLACE,
  //   path: ROUTES.PATHS.MARKETPLACE,
  //   title: ROUTES.TITLES.MARKETPLACE,
  //   component: Marketplace,
  // });

  return [
    {
      ...AppRoute,
      routes: [
        //DASHBOARD
        {
          title: ROUTES.TITLES.DASHBOARD,
          path: ROUTES.PATHS.DASHBOARD,
          component: Dashboard,
          routes: authorizedRoutes,
        },
      ],
    },
  ];
};
