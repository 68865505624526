//Libs
import { InputNumber } from "antd";
import styled from "styled-components";

const InputNumberStyled = styled(InputNumber)`
  width: ${(props) =>
    props.width ? props.width : props.theme.inputNumber.width};
`;

export default InputNumberStyled;
