//Libs
import React from "react";
import axios from "axios";
import useDynamicRefs from "use-dynamic-refs";
import { AtomSpinner, FingerprintSpinner } from "react-epic-spinners";
//Services
import { SentryService } from "services";
//Components
import { VirtualizedList } from "components/containers/Utils";
import { Wrapper, Drawer, Title } from "components/components";
import {
  CustomToolbar,
  BackComponent,
} from "components/containers/Dashboard/subs/Toolbar";
import { GlobalUtils } from "utils";

const STATUS = {
  PENDING: "pending",
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

const DrawerTitle = ({ title, setOut, onClose }) => (
  <CustomToolbar
    title={title}
    left={
      <BackComponent
        handleOnClick={() => {
          setOut(true);
          setTimeout(onClose, 400);
        }}
      />
    }
  />
);

const Thumbnail = React.memo(
  ({
    thumbnail,
    myRef,
    canvasRef,
    originalCanvas,
    appendBase64ToThumbnail,
  }) => {
    const width = document.documentElement.clientWidth;
    const height = 400;

    //Reading Photo
    React.useEffect(() => {
      //Get thumbnail canvas
      const canvas = canvasRef.current;
      if (canvas) {
        //Resize original image
        GlobalUtils.resampleSingle(originalCanvas, width, height, canvas);
        //Get pixels
        const ctx = canvas.getContext("2d");
        const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        //Set pixels to grayscale
        GlobalUtils.grayscale(imgData.data);
        //Put grayscale image into new canvas
        ctx.putImageData(imgData, 0, 0);
        //Get base64 code
        const base64Scanned = canvas.toDataURL();
        //Set base64 code into thumbnail
        appendBase64ToThumbnail(thumbnail.id, base64Scanned);
      }
    }, []);

    return (
      <canvas
        ref={myRef}
        width={width}
        height={height}
        style={{ display: "none" }}
      />
    );
    // return (
    //   <Wrapper padding="0" className="animated fadeIn" position="relative">
    //     <canvas
    //       ref={myRef}
    //       width={width}
    //       height={height}
    //       style={{ display: "none" }}
    //     />
    //     <Wrapper padding="5px" position="absolute" bottom="0" right="0">
    //       {thumbnail.status === STATUS.LOADING && (
    //         <LogoIcon spin={true} size="s" />
    //       )}
    //       {thumbnail.status === STATUS.ERROR && <CloseIcon onlyIcon />}
    //     </Wrapper>
    //   </Wrapper>
    // );
  }
);

const Scanning = ({
  maxShots,
  shotSpeed,
  canPlay,
  onGettedValue,
  setCanPlay,
}) => {
  const [scanning, setScanning] = React.useState(false);
  const [getRef, setRef] = useDynamicRefs();
  const [thumbnails, setThumbnails] = React.useState([
    /**
     * @param {string} id -Thumbnail ID
     * @param {string} status -Status determined by OCR service
     * @param {string} value -Value receive from OCR service
     * @param {string} message -Message to display to user
     *  initialValue: {
     *    id: ...,
     *    status: LOADING | ERROR | SUCCESS,
     *    value: null,
     *    message: null,
     * }
     */
  ]);
  const videoRef = React.useRef();
  const canvasRef = React.useRef();
  const width = document.documentElement.clientWidth;
  const height = 400;
  let ctx;
  let currentStream;

  const pendingThumbnails = React.useMemo(
    () =>
      thumbnails.filter(
        (th) => th.status === STATUS.PENDING && th.base64Scanned
      ),
    [thumbnails]
  );

  function showCamera() {
    ctx = canvasRef.current.getContext("2d");
    const options = {
      audio: false,
      video: {
        width,
        height,
      },
    };

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(options)
        .then((stream) => {
          currentStream = stream;
          videoRef.current.srcObject = stream;
          processCamera();
        })
        .catch(SentryService.sendError);
    } else {
      console.log("getUserMedia unavailable");
    }
  }

  function processCamera() {
    if (!videoRef.current) return;
    ctx.drawImage(videoRef.current, 0, 0);
    setTimeout(processCamera, 20);
  }

  const takePhoto = React.useCallback(() => {
    const now = new Date();
    const _thumbnails = [...thumbnails];
    const thumbnailId = `scanned_${now.toISOString()}${now.getMilliseconds()}`;
    const thumbnail = {
      id: thumbnailId,
      status: STATUS.PENDING,
      value: null,
      message: null,
    };
    _thumbnails.push(thumbnail);
    setThumbnails(_thumbnails);
  }, [thumbnails]);

  const appendBase64ToThumbnail = React.useCallback(
    (thumbnailId, base64Scanned) => {
      const _thumbnails = [...thumbnails];
      const idx = _thumbnails.findIndex((th) => th.id === thumbnailId);
      if (idx === -1) return;
      _thumbnails[idx].base64Scanned = base64Scanned;
      setThumbnails(_thumbnails);
    },
    [thumbnails]
  );

  //Show camera
  React.useEffect(() => {
    if (videoRef.current && canvasRef.current) {
      showCamera();
    }
    return () => {
      if (currentStream?.active) {
        currentStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
    };
  }, []);

  //Play & Pause control
  React.useEffect(() => {
    if (!videoRef.current) return;
    if (scanning) videoRef.current.play();
    else videoRef.current.pause();
  }, [scanning, videoRef.current]);

  //Auto take photo
  React.useEffect(() => {
    let isMounted = true;
    let t1;
    if (scanning && thumbnails.length < maxShots) {
      t1 = setTimeout(() => {
        if (isMounted && scanning) takePhoto();
      }, shotSpeed);
    }
    return () => {
      isMounted = false;
      clearTimeout(t1);
    };
  }, [scanning, thumbnails]);

  //Show start/finalize button
  React.useEffect(() => {
    if (!videoRef.current) return;
    videoRef.current.oncanplay = function () {
      setCanPlay(true);
    };
  }, [videoRef.current]);

  //Send scanned thumbnails to ocr service
  React.useEffect(() => {
    if (pendingThumbnails.length < maxShots) return;
    const _thumbnails = [...thumbnails];
    pendingThumbnails.map((pth) => {
      const idx = _thumbnails.findIndex((th) => th.id === pth.id);
      if (idx === -1) return;
      _thumbnails[idx].status = STATUS.LOADING;
    });
    setThumbnails(_thumbnails);
    sendScannedThumbnailsToOCRService(pendingThumbnails)
      .then((ocrResponses) =>
        ocrResponses.reduce((acc, arr) => [...acc, ...arr], [])
      )
      .then((ocrResponses) => ocrResponses.filter((rth) => !!rth.value))
      .then((responses) => {
        const groupedValues = responses.reduce((acc, rth) => {
          acc[rth.value] = true;
          return acc;
        }, {});
        Object.keys(groupedValues).map((value) => onGettedValue(value));
        setThumbnails([]);
      })
      .catch(() => setThumbnails([]));
  }, [thumbnails, pendingThumbnails]);

  const sendScannedThumbnailsToOCRService = React.useCallback(
    (pendingThumbnails) =>
      Promise.all(
        pendingThumbnails.map(
          (pth) =>
            axios({
              method: "POST",
              url: process.env.REACT_APP_OCR_SERVICE_URL,
              data: {
                Sample: pth.base64Scanned,
                Prefix: "",
              },
              headers: {
                "Content-Type": "application/json",
                "Auth-Token": process.env.REACT_APP_OCR_SERVICE_AUTH_TOKEN,
              },
            }).then((res) => {
              const values = res.data;
              if (!values || !Array.isArray(values)) return;

              return values.reduce((acc, value) => {
                acc.push({
                  ...pth,
                  value,
                });
                return acc;
              }, []);
            })
          // .catch((err) => ({
          //   ...pth,
          //   message: err.message,
          // }))
        )
      ),
    []
  );

  return (
    <Wrapper padding="0" flexDirection="column" alignItems="flex-start">
      <Wrapper padding="0" flexDirection="column" position="relative">
        <video ref={videoRef} muted playsInline style={{ height: "0.5px" }} />
        <canvas
          ref={canvasRef}
          width={width}
          height={height}
          // style={{ maxWidth: "100%" }}
        />
        {!scanning && (
          <Wrapper
            padding="0"
            position="absolute"
            top={`${height / 3}px`}
            borderRadius="50%"
            flexDirection="column"
          >
            <AtomSpinner
              color={!canPlay ? "black" : "white"}
              onClick={() => {
                if (canPlay) {
                  setScanning(true);
                }
              }}
            />
            <Title color="white">Pulsa para escanear</Title>
          </Wrapper>
        )}
        {scanning && (
          <Wrapper
            padding="0"
            position="absolute"
            bottom="0"
            right="0"
            alignItems="baseline"
          >
            <Title color="white" margin="0 10px 0 0">
              Pulsa para detenerse
            </Title>
            <Wrapper
              padding="0"
              backgroundColor="rgba(0,0,0,.5)"
              boxShadowInner1
              borderRadius="50%"
            >
              <FingerprintSpinner
                color={!canPlay ? "black" : "white"}
                onClick={() => {
                  if (canPlay) {
                    setScanning(false);
                  }
                }}
              />
            </Wrapper>
          </Wrapper>
        )}
      </Wrapper>

      {canPlay && (
        <Title padding="0 0 0 10px" margin="10px 0">
          Muestras: {thumbnails.length} / {maxShots}
        </Title>
      )}
      {canvasRef.current &&
        thumbnails.map((thumbnail, idx) => (
          <div key={idx}>
            <Thumbnail
              thumbnail={thumbnail}
              myRef={setRef(thumbnail.id)}
              canvasRef={getRef(thumbnail.id)}
              originalCanvas={canvasRef.current}
              appendBase64ToThumbnail={appendBase64ToThumbnail}
            />
          </div>
        ))}
      {/* <Grid
        maxHeight="320px"
        width="100%"
        justifyContent="center"
        gridAutoFlow="row"
        overflowX="auto"
        columns="repeat(auto-fit, minmax(100px, 100px))"
        rows="repeat(auto-fit, minmax(100px, 100px))"
        gap=".3em"
      >
        {canvasRef.current &&
          thumbnails.map((thumbnail, idx) => (
            <div key={idx}>
              <Thumbnail
                thumbnail={thumbnail}
                myRef={setRef(thumbnail.id)}
                canvasRef={getRef(thumbnail.id)}
                originalCanvas={canvasRef.current}
                appendBase64ToThumbnail={appendBase64ToThumbnail}
              />
            </div>
          ))}
      </Grid> */}
    </Wrapper>
  );
};

const Autoscan = ({
  //Props
  maxShots = 3, //Max number of photos by lot
  shotSpeed = 30, //Milliseconds to delay to take photo
  title,
  cardId,
  rows,
  scannedRows,
  listStyle,
  cardStyle,
  rowHeight,
  customProps,
  onGettedValue,
  onClose,
}) => {
  const [canPlay, setCanPlay] = React.useState(false);
  const [out, setOut] = React.useState(false);

  //Exit drawer
  React.useEffect(() => {
    let isMounted = true;
    if (out) {
      var st = setTimeout(() => isMounted && setOut(false), 400);
    }
    return () => {
      isMounted = false;
      clearTimeout(st);
    };
  }, [out]);

  //Filtered rows
  const filteredRows = React.useMemo(() => {
    return scannedRows.reduce((acc, srow) => {
      const row = rows.find((r) => r.serie === srow.serie);
      if (row) acc.push(row);
      return acc;
    }, []);
  }, [rows, scannedRows]);

  return (
    <Drawer
      key={"autoscan"}
      className={out ? "animated slideOutDown faster" : ""}
      placement={"bottom"}
      width={`${document.documentElement.clientWidth}px`}
      height="100%"
      closable={false}
      visible={!out}
      title={
        <DrawerTitle
          title={!canPlay ? "Inicializando..." : title}
          setOut={setOut}
          onClose={onClose}
        />
      }
    >
      <Wrapper padding="0" flexDirection="column" alignItems="stretch">
        <Scanning
          maxShots={maxShots}
          shotSpeed={shotSpeed}
          canPlay={canPlay}
          onGettedValue={onGettedValue}
          setCanPlay={setCanPlay}
        />
        {canPlay && (
          <>
            <Title padding="0 0 0 10px" margin="10px 0">
              Registros: {filteredRows.length}
            </Title>
            <VirtualizedList
              cardId={cardId}
              listStyle={listStyle}
              cardStyle={cardStyle}
              rowHeight={rowHeight}
              rows={filteredRows}
              customProps={customProps}
            />
          </>
        )}
      </Wrapper>
    </Drawer>
  );
};

export default Autoscan;
