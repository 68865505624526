//Utils
import GENERAL from "utils/constants/general";
import KEYWORDS from "./keywords";

const { MODULES } = GENERAL;

const initialState = {
  accessControl: {
    state: KEYWORDS.STATE.LOADING,
    selectedModuleId: undefined,
    modules: [],
  },
  marketplace: {
    //Banners
    banners: {
      isFetching: false,
      data: [],
    },
    //Modules
    modules: {
      selectedModuleId: undefined,
      isFetching: false,
      data: [],
    },
    //Items
    items: {
      isFetching: false,
      data: [],
    },
  },
};

const moduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODULES.MUTATE_DIRECT_PROPS: {
      const keyValuePairs = action.payload;
      return {
        ...state,
        ...keyValuePairs,
      };
    }
    case MODULES.MUTATE_1OBJECT: {
      const { obj1Name, keyValuePairs } = action.payload,
        newObj = { ...state[obj1Name], ...keyValuePairs };
      return {
        ...state,
        [obj1Name]: newObj,
      };
    }
    case MODULES.MUTATE_MARKETPLACE: {
      const { obj1Name, keyValuePairs } = action.payload;
      const newObj = { ...state.marketplace[obj1Name], ...keyValuePairs };
      return {
        ...state,
        marketplace: {
          ...state.marketplace,
          [obj1Name]: newObj,
        },
      };
    }
    default:
      return state;
  }
};

export default moduleReducer;
