//Libs
import React from "react";
import styled, { css } from "styled-components";
//Components
import BackgroundIcon from "assets/media/img/background-order-img.png";

const Icon = styled.div`
  background: url(${BackgroundIcon});
  background-size: contain;
  min-width: ${({ theme }) => `${theme.sizes.normal.width}px`};
  height: ${({ theme }) => `${theme.sizes.normal.height}px`};
  ${({ size, theme }) =>
    size === theme.sizes.medium.name &&
    css`
      min-width: ${({ theme }) => `${theme.sizes.medium.width}px`};
      height: ${({ theme }) => `${theme.sizes.medium.height}px`};
    `};
  ${({ size, theme }) =>
    size === theme.sizes.medium.name &&
    css`
      min-width: ${({ theme }) => `${theme.sizes.medium.width}px`};
      height: ${({ theme }) => `${theme.sizes.medium.height}px`};
    `};
  ${({ size, theme }) =>
    size === theme.sizes.xlarge.name &&
    css`
      min-width: ${({ theme }) => `${theme.sizes.xlarge.width}px`};
      height: ${({ theme }) => `${theme.sizes.xlarge.height}px`};
    `};
  color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ theme, selected }) =>
    selected &&
    css`
      box-shadow: inset 0px 0px 10px -3px ${theme.colors.focused.color};
    `}
`;

const InitialResourceIcon = ({ initial, size, selected }) => (
  <Icon size={size} selected={selected}>
    {initial}
  </Icon>
);

export default InitialResourceIcon;
