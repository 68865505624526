import _ from "underscore";
import GENERAL from "utils/constants/general";
import { ExcelUtils } from "utils/libs";

const { ENV } = GENERAL;

export function onKeyPressedSearchStockSeries(
  e,
  searchInData,
  history,
  mutate1ObjectInWarehouse,
  mutateDirectPropsInDashboard
) {
  const { data, searchBy } = searchInData;

  if (e.key === "Enter" && searchBy === "scan" && data && data !== "") {
    mutate1ObjectInWarehouse("stockSeriesManage", {
      searchInData: { ...searchInData, pressEnter: true },
    });
    mutateDirectPropsInDashboard({ isOpen: false });
    history.push(ENV.ROUTES.PATHS.WAREHOUSE_STOCK);
  }
}

export function onScanStockSeries(
  searchInData,
  getSearchingSeries,
  mutate1Object
) {
  const { data, pressEnter } = searchInData,
    series = [];

  if (pressEnter && data && data !== "") {
    if (data.includes(",")) {
      data.split(",").map((serie) => {
        if (serie && serie !== "") series.push(serie.trim());
      });
    } else series.push(data.trim());

    mutate1Object("stockSeriesManage", {
      searchInData: { ...searchInData, pressEnter: false },
    });
    if (series.length > 0) getSearchingSeries(series);
  }
}

export function startCompareSeries(
  scannedSeries,
  data,
  mutate1ObjectInWarehouse,
  sendToast
) {
  const externalSeries = [],
    scannedSeriesInconsistencies = [],
    externalSeriesInconsistencies = [];

  if (data && data !== "") {
    mutate1ObjectInWarehouse("inventoryToTransferManage", { comparing: true });

    //Build externalSeries array
    if (data.includes(",")) {
      data.split(",").map((serie) => {
        if (serie && serie !== "") externalSeries.push(serie.trim());
      });
    } else externalSeries.push(data.trim());

    //Comparing scanned series
    for (let scanSerie of scannedSeries) {
      let idx = _.findIndex(
        externalSeries,
        (extSerie) => extSerie === scanSerie.serie
      );
      if (idx === -1)
        scannedSeriesInconsistencies.push({ serie: scanSerie.serie });
    }

    //Comparing external series
    for (let extSerie of externalSeries) {
      let idx = _.findIndex(
        scannedSeries,
        (scanSerie) => scanSerie.serie === extSerie
      );
      if (idx === -1) externalSeriesInconsistencies.push({ serie: extSerie });
    }

    if (
      scannedSeriesInconsistencies.length === 0 &&
      externalSeriesInconsistencies.length === 0
    )
      sendToast({ message: "Todo correcto!", type: "success", duration: 2 });
    else
      sendToast({
        message: "Se encontraron inconsistencias. Haz click aquí para verlas",
        type: "warn",
        duration: 20,
        onClick: () =>
          ExcelUtils.exportToExcelMultiSheet("Inconsistencias", [
            {
              name: "Escaneadas",
              data: scannedSeriesInconsistencies,
            },
            {
              name: "Externas",
              data: externalSeriesInconsistencies,
            },
          ]),
      });

    mutate1ObjectInWarehouse("inventoryToTransferManage", {
      comparing: false,
      compareSeries: undefined,
    });
  }
}

// export function filterStockView(stockArticles, view) {
//   if (view === "articles")
//     return stockArticles.filter((article) => !article.is_serialized);
//   if (view === "series-grouped")
//     return stockArticles.filter((article) => article.is_serialized);
// }

export function filterWarehouseSearchInData(rows, searchedValue) {
  if (searchedValue === "") return rows;
  return rows.filter(
    (item) =>
      String(item?.id).toUpperCase().includes(searchedValue.toUpperCase()) ||
      item?.item_code?.toUpperCase().includes(searchedValue.toUpperCase()) ||
      item?.item_name?.toUpperCase().includes(searchedValue.toUpperCase()) ||
      item?.name?.toUpperCase().includes(searchedValue.toUpperCase()) ||
      item?.serie?.toUpperCase().includes(searchedValue.toUpperCase())
  );
}

// export function filterEmptyItems(articles) {
//   return articles.filter(
//     ({ amount, amount_transit }) =>
//       Number(amount) > 0 || Number(amount_transit) > 0
//   );
// }

export function filterStockSeriesSearchInData(stockSeries, searchBy, data) {
  if (data === "") return [...stockSeries];

  switch (searchBy) {
    case "code":
      return stockSeries.filter((item) =>
        item.item_code.toUpperCase().includes(data.toUpperCase())
      );
    case "name":
      return stockSeries.filter((item) =>
        item.item_name.toUpperCase().includes(data.toUpperCase())
      );
    case "serie":
    case "scan":
      return stockSeries.filter((item) =>
        item.serie.toUpperCase().includes(data.toUpperCase())
      );
    case "all":
      return stockSeries.filter(
        (item) =>
          item.item_code.toUpperCase().includes(data.toUpperCase()) ||
          item.item_name.toUpperCase().includes(data.toUpperCase()) ||
          item.serie.toUpperCase().includes(data.toUpperCase())
      );
    default:
      return stockSeries;
  }
}

export function filterReconditionedSeries(series, filter) {
  if (filter === "all") return series;
  if (filter === "new")
    return series.filter(
      (serie) => !serie.item_props || !serie.item_props.reconditioned
    );
  if (filter === "reconditioned")
    return series.filter(
      (serie) => serie.item_props && serie.item_props.reconditioned
    );
}

export function setFifoFlag(series) {
  let counter = {};
  const newInventory = filterReconditionedSeries(series, "new").map((serie) => {
    if (serie.fifo_amount === null) serie.fifoOk = true;
    else {
      if (!counter[serie.family_id]) counter[serie.family_id] = 1;
      else counter[serie.family_id]++;

      if (counter[serie.family_id] <= serie.fifo_amount) serie.fifoOk = true;
      serie.position = counter[serie.family_id];
    }
    return serie;
  });

  counter = {};
  const reconditionedInventory = filterReconditionedSeries(
    series,
    "reconditioned"
  ).map((serie) => {
    if (serie.fifo_amount === null) serie.fifoOk = true;
    else {
      if (!counter[serie.family_id]) counter[serie.family_id] = 1;
      else counter[serie.family_id]++;

      if (counter[serie.family_id] <= serie.fifo_amount) serie.fifoOk = true;
      serie.position = counter[serie.family_id];
    }
    return serie;
  });

  return newInventory.concat(reconditionedInventory);
}

export function filterTransactionsSearchInData(transactions, searchBy, data) {
  if (!data || data === "") return [...transactions];

  switch (searchBy) {
    case "doc_number":
      return transactions.filter((transaction) =>
        transaction.doc_number.toUpperCase().includes(data.toUpperCase())
      );
    case "transaction_type":
      return transactions.filter((transaction) =>
        transaction.transaction_type_name
          .toUpperCase()
          .includes(data.toUpperCase())
      );
    case "method":
      return transactions.filter((transaction) =>
        transaction.method_name.toUpperCase().includes(data.toUpperCase())
      );
    case "owarehouse":
      return transactions.filter((transaction) =>
        transaction.owarehouse_name.toUpperCase().includes(data.toUpperCase())
      );
    case "dwarehouse":
      return transactions.filter((transaction) =>
        transaction.dwarehouse_name.toUpperCase().includes(data.toUpperCase())
      );
    case "creator":
      return transactions.filter((transaction) =>
        transaction.creator_name.toUpperCase().includes(data.toUpperCase())
      );
    case "entity":
      return transactions.filter((transaction) =>
        transaction.entity_name.toUpperCase().includes(data.toUpperCase())
      );
    case "all":
      return transactions.filter(
        (transaction) =>
          transaction.doc_number.toUpperCase().includes(data.toUpperCase()) ||
          transaction.transaction_type_name
            .toUpperCase()
            .includes(data.toUpperCase()) ||
          transaction.method_name.toUpperCase().includes(data.toUpperCase()) ||
          transaction.owarehouse_name
            .toUpperCase()
            .includes(data.toUpperCase()) ||
          transaction.dwarehouse_name
            .toUpperCase()
            .includes(data.toUpperCase()) ||
          transaction.creator_name.toUpperCase().includes(data.toUpperCase()) ||
          transaction.entity_name.toUpperCase().includes(data.toUpperCase())
      );
  }
}

export function filterInventoryToTransferSearchInData(
  inventory,
  searchBy,
  data
) {
  if (!data || data === "") return [...inventory];

  switch (searchBy) {
    case "code":
      return inventory.filter((item) =>
        item.item_code.toUpperCase().includes(data.toUpperCase())
      );
    case "name":
      return inventory.filter((item) =>
        item.item_name.toUpperCase().includes(data.toUpperCase())
      );
    case "serie":
      return inventory.filter(
        (item) =>
          item.serie && item.serie.toUpperCase().includes(data.toUpperCase())
      );
    case "all":
      return inventory.filter(
        (item) =>
          item.item_code.toUpperCase().includes(data.toUpperCase()) ||
          item.item_name.toUpperCase().includes(data.toUpperCase()) ||
          (item.serie && item.serie.toUpperCase().includes(data.toUpperCase()))
      );
  }
}

export function filterItemsTransactionDocsSearchInData(items, searchBy, data) {
  if (!data || data === "") return [...items];

  switch (searchBy) {
    case "code":
      return items.filter((item) =>
        item.item_code.toUpperCase().includes(data.toUpperCase())
      );
    case "name":
      return items.filter((item) =>
        item.item_name.toUpperCase().includes(data.toUpperCase())
      );
    case "serie":
      return items.filter(
        (item) =>
          item.serie && item.serie.toUpperCase().includes(data.toUpperCase())
      );
    case "all":
      return items.filter(
        (item) =>
          item.item_code.toUpperCase().includes(data.toUpperCase()) ||
          item.item_name.toUpperCase().includes(data.toUpperCase()) ||
          (item.serie && item.serie.toUpperCase().includes(data.toUpperCase()))
      );
  }
}

export function getDestinyWarehouses(warehouses, profile) {
  return warehouses.filter(
    (warehouse) =>
      warehouse.owner_id !== profile.user.id &&
      (!warehouse.props ||
        (!warehouse.props.provider && !warehouse.props.fClient))
  );
}

export function viewOnlyAmountsItemsTransactionDoc(items) {
  const onlyArticles = items.filter((item) => !item.is_serialized),
    amountSerialized = items.reduce((acc, item) => {
      if (item.is_serialized) {
        let idx = _.findIndex(
          acc,
          (record) =>
            record.item_id === item.item_id && record.state_id === item.state_id
        );
        if (idx === -1) {
          acc.push({
            item_id: item.item_id,
            item_code: item.item_code,
            item_name: item.item_name,
            amount: 1,
            state_name: item.state_name,
            state_id: item.state_id,
          });
        } else acc[idx].amount += 1;
      }
      return acc;
    }, []);

  return onlyArticles.concat(amountSerialized);
}

export function getPreview(preview, inventory, articles, serialized) {
  if (!preview) return inventory;

  const serieSummary = serialized.reduce((summary, item) => {
    const inventoryItem = inventory.find(
      (invItem) => invItem.serie === item.serie
    );
    if (inventoryItem) {
      summary.push({
        item_name: inventoryItem.item_name,
        is_serialized: inventoryItem.is_serialized,
        unavailable: inventoryItem.unavailable,
        ...item,
      });
    }
    return summary;
  }, []);

  const articleSummary = articles.reduce((summary, item) => {
    const inventoryItem = inventory.find(
      (invItem) => invItem.item_id === item.item_id
    );
    if (inventoryItem) {
      summary.push({
        item_name: inventoryItem.item_name,
        ...item,
      });
    }
    return summary;
  }, []);

  return articleSummary.concat(serieSummary);
}

export function getFamilySeries(series) {
  const families = [];

  series.map((serie) => {
    let idx = _.findIndex(families, (family) => family.id === serie.family_id);

    if (idx === -1)
      families.push({
        id: serie.family_id,
        name: serie.family_name,
        count: 1,
      });
    else families[idx].count++;
  });

  return families;
}
