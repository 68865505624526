//Libs
import Immutable from "immutable";
import { createSelector } from "reselect";

const selectApi = (state) => state.api;

export const selectTemplates = createSelector(
  [selectApi],
  (api) => api.templates
);
export const selectWarehouse = createSelector(
  [selectApi],
  (api) => api.warehouse
);
export const selectSerieHistory = createSelector(
  [selectApi],
  ({ serieHistory }) => serieHistory
);
export const selectUsers = createSelector([selectApi], ({ users }) =>
  Immutable.List(users).toJS()
);
export const selectIncomeMethods = createSelector(
  [selectApi],
  ({ incomeMethods }) => Immutable.List(incomeMethods).toJS()
);
export const selectWarehouses = createSelector([selectApi], ({ warehouses }) =>
  Immutable.List(warehouses).toJS()
);
export const selectCompletedCodes = createSelector(
  [selectApi],
  ({ completedCodes }) => completedCodes
);
