//Libs
import React from "react";
//Components
import ToolbarNotifications from "../Notifications";
import { Wrapper, SearchIcon } from "components/components";
import {
  CustomToolbar,
  CustomSearchToolbar,
  HamburguerComponent,
} from "components/containers/Dashboard/subs/Toolbar";

export function Stock({
  title,
  searchInData,
  showNavBar,
  mutate1ObjectInWarehouse,
  //Notifications
  selectNotification,
}) {
  return (
    <CustomToolbar
      title={title}
      left={<HamburguerComponent handleOnClick={showNavBar} />}
      right={
        <Wrapper padding="0">
          {/* SEARCH WAREHOUSE */}
          <SearchIcon
            onClick={() =>
              mutate1ObjectInWarehouse("searchInData", {
                ...searchInData,
                renderToolbar: true,
              })
            }
          />

          {/* Notifications */}
          <ToolbarNotifications selectNotification={selectNotification} />
        </Wrapper>
      }
    />
  );
}

export function SearchStock({ searchInData, mutate1ObjectInWarehouse }) {
  return (
    <CustomSearchToolbar
      value={searchInData.data}
      onChange={(e) =>
        mutate1ObjectInWarehouse("searchInData", {
          ...searchInData,
          [e.target.name]: e.target.value,
        })
      }
      onClose={() =>
        mutate1ObjectInWarehouse("searchInData", {
          ...searchInData,
          renderToolbar: false,
          data: "",
        })
      }
    />
  );
}
