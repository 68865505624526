import styled from "styled-components";

const OrderPosition = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.inactive.color};
`;

export default OrderPosition;
