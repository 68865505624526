//Libs
import React from "react";
import { connect } from "react-redux";
//Utils
import { Modal } from "components/containers/Utils";
//Actions
import {
  getInventoryToTransfer,
  getTransactionDocs,
  mutate1Object as mutate1ObjectInWarehouse,
} from "./actions";
import { sendToast } from "components/containers/Dashboard/actions";
import { mutate1Object as mutate1ObjectInApi } from "store/api/actions";
//Selectors
import {
  selectIncomeMethods,
  selectSerieHistory,
  selectUsers,
  selectWarehouses,
} from "store/api/selectors";
import {
  selectCreateTransaction,
  selectGetTransactionsModal,
  selectTransactionLoading,
} from "./selectors";
import { selectProfile } from "../Login/selectors";

const ModalWarehouse = ({
  //State
  isTransactionLoading,
  getTransactionsModal,
  createTransaction,
  serieHistory,
  users,
  warehouses,
  incomeMethods,
  profile,
  //Actions
  mutate1ObjectInWarehouse,
  mutate1ObjectInApi,
  sendToast,
  getInventoryToTransfer,
  getTransactionDocs,
}) => {
  return (
    <>
      {/* SERIE HISTORY */}
      <Modal
        modalId="SerieHistoryModal"
        title={serieHistory.serie ?? ""}
        visible={serieHistory.isOpen}
        onCancel={() => mutate1ObjectInApi("serieHistory", { isOpen: false })}
        footer={null}
        isSpinning={serieHistory.loading}
        sizeSpinning="large"
        delaySpinning={50}
        tipSpinning="Un momento porfavor..."
        customProps={{
          serieHistory,
          sendToast,
        }}
      />

      {/* NEW TRANSACTION */}
      <Modal
        modalId="CreateTransactionModal"
        title="Nueva Transacción"
        visible={createTransaction.isOpen}
        onCancel={() =>
          mutate1ObjectInWarehouse("createTransaction", {
            isOpen: false,
            loading: false,
            preview: false,
            incomeMethod: undefined,
            dwarehouseId: undefined,
            dwhOwnerId: undefined,
            signer: undefined,
            comment: undefined,
            articles: [],
            serialized: [],
          })
        }
        footer={null}
        isSpinning={createTransaction.loading}
        sizeSpinning="large"
        delaySpinning={50}
        tipSpinning="Un momento porfavor..."
        customProps={{
          users,
          createTransaction,
          incomeMethods,
          warehouses,
          mutate1ObjectInWarehouse,
          getInventoryToTransfer,
          profile,
        }}
      />

      {/* GET TRANSACTIONS */}
      <Modal
        modalId="GetTransactionsModal"
        title="Consultar Transacciones"
        visible={getTransactionsModal.isOpen}
        onCancel={() =>
          mutate1ObjectInWarehouse("getTransactionsModal", {
            isOpen: false,
            mode: "assignment",
          })
        }
        footer={null}
        isSpinning={false}
        sizeSpinning="large"
        delaySpinning={50}
        tipSpinning="Un momento porfavor..."
        customProps={{
          isTransactionLoading,
          getTransactionsModal,
          getTransactionDocs,
          mutate1ObjectInWarehouse,
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isTransactionLoading: selectTransactionLoading(state),
  getTransactionsModal: selectGetTransactionsModal(state),
  createTransaction: selectCreateTransaction(state),
  serieHistory: selectSerieHistory(state),
  users: selectUsers(state),
  warehouses: selectWarehouses(state),
  incomeMethods: selectIncomeMethods(state),
  profile: selectProfile(state),
});
const actions = {
  mutate1ObjectInWarehouse,
  mutate1ObjectInApi,
  sendToast,
  getInventoryToTransfer,
  getTransactionDocs,
};

export default connect(mapStateToProps, actions)(ModalWarehouse);
