//Libs
import React from "react";

export default ({ fill = "white", fillOpacity = "0.54" }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor="pointer"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66675 4H4.66675H5.33341H14.6667H17.3334H26.6667H27.3334H29.3334V6V8H27.3334H26.6667V28H5.33341V8H4.66675H2.66675V6V4ZM8.00008 8H14.6667V14.6667H12.0001V17.3333H14.6667V25.3333H8.00008V8ZM17.3334 17.3333V25.3333H24.0001V8H17.3334V14.6667H20.0001V17.3333H17.3334Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);
