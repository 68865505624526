//Libs
import GENERAL from "../../../utils/constants/general";
const { DASHBOARD, WAREHOUSE } = GENERAL;

//****************** FUNCTIONS ***********************/
export function mutateDirectProps(keyValuePairs) {
  return {
    type: WAREHOUSE.MUTATE_DIRECT_PROPS,
    payload: keyValuePairs,
  };
}

export function mutate1Object(obj1Name, keyValuePairs) {
  return {
    type: WAREHOUSE.MUTATE_1OBJECT,
    payload: { obj1Name, keyValuePairs },
  };
}

export function mutate1ObjectInStockSeriesManage(obj1Name, keyValuePairs) {
  return {
    type: WAREHOUSE.MUTATE_STOCK_SERIES_MANAGE,
    payload: { obj1Name, keyValuePairs },
  };
}

export function resetCreateTransaction() {
  return {
    type: WAREHOUSE.RESET_CREATE_TRANSACTION,
  };
}

export function deleteTransaction(document_id) {
  return {
    type: WAREHOUSE.DELETE_TRANSACTION,
    payload: document_id,
  };
}

//****************** GETTERS ***********************/
export function getStockArticles() {
  return {
    type: WAREHOUSE.GET_STOCK_ARTICLES,
  };
}

export function getStockSeries(payload) {
  return {
    type: WAREHOUSE.GET_STOCK_SERIES,
    payload,
  };
}

export function getMyFamilies() {
  return {
    type: WAREHOUSE.GET_MY_FAMILIES,
  };
}

export function getTransactionDocs(getTransactions) {
  let { mode, docNumber, fromDate, toDate } = getTransactions;
  if (docNumber) docNumber = docNumber.trim();

  if (
    !mode ||
    (mode === "document" && !docNumber) ||
    (mode === "completed" && (!fromDate || !toDate))
  )
    return {
      type: DASHBOARD.TOAST_MESSAGE,
      payload: { message: "Debe completar todos los campos", type: "warn" },
    };

  return {
    type: WAREHOUSE.GET_TRANSACTION_DOCS,
    payload: getTransactions,
  };
}

export function getItemsTransactionDocs(wtdId) {
  return {
    type: WAREHOUSE.GET_ITEMS_TRANSACTION_DOCS,
    payload: wtdId,
  };
}

export function getInventoryToTransfer() {
  return {
    type: WAREHOUSE.GET_INVENTORY_TO_TRANSFER,
  };
}

export function getSearchingSeries(series) {
  return {
    type: WAREHOUSE.GET_SEARCHING_SERIES,
    payload: series,
  };
}

//****************** SETTERS ***********************/
export function signWTD(document_id, nextSigner, complete) {
  if (!document_id || (!complete && !nextSigner))
    return {
      type: DASHBOARD.TOAST_MESSAGE,
      payload: {
        message: "Por favor seleccione el próximo firmante",
        type: "warn",
      },
    };

  return {
    type: WAREHOUSE.SIGN_WTD,
    payload: { document_id, nextSigner, complete },
  };
}

export function sendTransferTransaction(props) {
  const {
    owarehouseId,
    dwarehouseId,
    signer,
    incomeMethod,
    comment,
    articles,
    serialized,
  } = props;

  //Validate Transaction
  if (articles.length <= 0 && serialized.length <= 0)
    return {
      type: DASHBOARD.TOAST_MESSAGE,
      payload: {
        message: "Por favor indique los artículos que desea transferir",
        type: "warn",
      },
    };

  const transaction = {
    doc_number: null,
    transaction_type_id: 26,
    warehouse_inv_income_method_id: incomeMethod,
    comment: comment && comment !== "" ? comment : null,
    owarehouse_id: owarehouseId,
    dwarehouse_id: dwarehouseId,
    signer,
    inventory: {
      articles: articles.reduce((acc, row) => {
        acc.push({
          item_id: row.item_id,
          serie_id: row.serie_id,
          amount: row.amount,
          initial_amount: row.initialAmount,
          final_amount: row.finalAmount,
          warehouse_package_id: row.warehouse_package_id,
        });
        return acc;
      }, []),
      serialized: serialized.reduce((acc, row) => {
        acc.push({
          item_id: row.item_id,
          serie: row.serie,
          state_id: row.state_id,
          warehouse_package_id: row.warehouse_package_id,
        });
        return acc;
      }, []),
    },
  };

  return {
    type: WAREHOUSE.SEND_TRANSFER_TRANSACTION,
    payload: transaction,
  };
}
//****************** RESET ***********************/
export function doReset() {
  return {
    type: WAREHOUSE.RESET,
  };
}
