//Libs
import React from "react";
//Utils
import GENERAL from "utils/constants/general";
//Subtoolbars
import { Orders } from "./Orders";
import { Pays } from "./Payments";
import { Transactions } from "./Warehouse";
//  Styles
import "./index.css";

const { ENV } = GENERAL;

export default function Subtoolbar(props) {
  const {
    //General
    history,
    profile,
    //Dashboard
    linked,
    //Orders
    orders,
    mutate1ObjectInOrders,
    getOrders,
    //Warehouse
    warehouse,
    mutate1ObjectInWarehouse,
    getTransactionDocs,
    //Api
    api,
    getPayments,
  } = props;

  let id;
  for (let path in ENV.ROUTES.PATHS) {
    if (history.location.pathname === ENV.ROUTES.PATHS[path]) {
      id = ENV.ROUTES.IDS[path];
      break;
    }
  }

  switch (id) {
    case ENV.ROUTES.IDS.ORDERS_MANAGE:
      return (
        <div id="subtoolbar-container">
          <Orders
            orders={orders}
            gains={api.gains}
            linked={linked}
            mutate1ObjectInOrders={mutate1ObjectInOrders}
            getOrders={getOrders}
            profile={profile}
          />
        </div>
      );
    case ENV.ROUTES.IDS.PAYMENTS_MANAGE:
      return (
        <div id="subtoolbar-container">
          <Pays payments={api.payments} getPayments={getPayments} />
        </div>
      );
    case ENV.ROUTES.IDS.WAREHOUSE_TRANSACTIONS_MANAGE:
      return (
        <div id="subtoolbar-container">
          <Transactions
            warehouse={warehouse}
            getTransactionDocs={getTransactionDocs}
            mutate1ObjectInWarehouse={mutate1ObjectInWarehouse}
          />
        </div>
      );
    default:
      return <div />;
  }
}
