//Libs
import React from "react";
//Components
import { Wrapper, Span, Button, Modal } from "components/components";

const ConfirmationButtons = ({ onConfirm, onCancel }) => {
  return (
    <>
      <Span>¿Realmente desea completar la orden?</Span>
      <Wrapper width="100%" justifyContent="space-evenly">
        <div style={{ marginRight: "10px" }}>
          <Button mode="secondary" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
        <div>
          <Button mode="primary" boxShadow radius onClick={onConfirm}>
            Confirmar
          </Button>
        </div>
      </Wrapper>
    </>
  );
};

const ConfirmOrder = ({ visible, onConfirm, onCancel }) => {
  return (
    <Modal
      visible={visible}
      title={`Confirmación de Envío`}
      footer={null}
      onCancel={onCancel}
    >
      <Wrapper
        height="160px"
        flexDirection="column"
        justifyContent="space-around"
      >
        <ConfirmationButtons onConfirm={onConfirm} onCancel={onCancel} />
      </Wrapper>
    </Modal>
  );
};

export default ConfirmOrder;
