//Libs
import React from "react";
//Utils
import { SourcePhotos } from "components/AppConfig.json";
//Components
import { Card } from "antd";
import {
  Wrapper,
  CheckIcon,
  TextField,
  IconButton,
  Avatar,
  Subtitle,
  Caption,
  LineDescription,
} from "components/components";
//Styles
import "../../VirtualizedList.css";
import "./OrderHistoryCard.css";
import "./BillingMaterialCard.css";

const { Meta } = Card;
const { TextArea } = TextField;

export default function CommentsCard(props) {
  const { user_id, user_name, created_at, comment, newComment, customProps } =
    props;
  const { mutate1ObjectInOrders, getOrderInformationModal, sendCommentReport } =
    customProps;

  return newComment ? (
    <Meta
      id="meta-card-container"
      description={
        <Wrapper padding="0">
          <TextArea
            margin="0 10px 0 0"
            autoFocus
            value={getOrderInformationModal.newComment}
            placeholder="Escribe tu comentario"
            onChange={(e) =>
              mutate1ObjectInOrders("getOrderInformationModal", {
                newComment: e.target.value,
              })
            }
          />

          {getOrderInformationModal.sendingComment ? (
            <IconButton type="loading" />
          ) : (
            <CheckIcon
              button
              onClick={() =>
                sendCommentReport(
                  getOrderInformationModal.newComment,
                  getOrderInformationModal.order_id,
                  getOrderInformationModal.activeTab
                )
              }
            />
          )}
        </Wrapper>
      }
    />
  ) : (
    <Meta
      id="meta-card-container"
      avatar={
        <Avatar
          bgTransparent
          fit
          size="small"
          avatar={{
            src: SourcePhotos.concat(`/syn4pse/users/avatars/${user_id}.jpg`),
          }}
        />
      }
      title={
        <Wrapper
          padding="0"
          margin="0 0 10px 0"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Wrapper padding="0">
            <Subtitle ellipsis width="220px" whiteSpace="nowrap">
              {user_name}
            </Subtitle>
          </Wrapper>
          <Caption light>{created_at}</Caption>
        </Wrapper>
      }
      description={
        <Wrapper padding="0 10px 0 10px" justifyContent="space-between">
          <LineDescription
            valueEllipsis
            fontSize="m"
            textAlign="left"
            value={comment}
            valueStyle={{ width: "220px" }}
          />
        </Wrapper>
      }
    />
  );
}
