import axios from 'axios';
import AuthService from './utils/libs/auth/AuthService';

const pushServerPublicKey = 'BBShbOdExDxy9rG7P7lSnTUv0xoaaL_EKttxn0C5SPnaP_KIeRNGavjylWjb3qKpRPYPKjFtZvxpPqN9NDBCubA',
  auth = new AuthService();

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window && 'showNotification' in ServiceWorkerRegistration.prototype;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
function askUserPermissionNecessary() {
  return Notification['permission'] !== 'granted';
}

async function verifyUserPermission() {
  if (!askUserPermissionNecessary()) return true;
  const permission = await Notification.requestPermission();
  return permission === 'granted';
}

export default async function registerPushNotificationServiceWorker() {
  if (isPushNotificationSupported()) {
    const register = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/assets/push-notifications-sw.js`);

    if (verifyUserPermission()) {
      const subscriber = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(pushServerPublicKey),
      });
      await axios.post(`${process.env.REACT_APP_API_URL.concat('/notifications/push/subscribe')}`, subscriber, auth.sendToken());
    }
  } else alert('Las notificaciones push no están soportadas en éste navegador');
};