//Libs
import { put, delay } from "redux-saga/effects";
//Utils
import APPCONFIG from "components/AppConfig.json";
import GENERAL from "utils/constants/general";
const { DASHBOARD } = GENERAL;

export default function* asyncErrorsHandler(
  err,
  onErrorExecute,
  onTimeoutExecute,
  timeToRetry
) {
  //On receive explicit error
  if (err?.response?.data) {
    //Execute cutomized actions
    if (onErrorExecute) yield onErrorExecute();
    //Must show error?
    if (err.response.data.showError || err.response.status >= 500) {
      //Show multiple error?
      if (
        Array.isArray(err.response.data.errors) &&
        err.response.data.errors.length > 0
      ) {
        yield put({
          type: DASHBOARD.SHOW_MULTIPLE_ERRORS,
          payload: err.response.data.errors,
        });
      }
      //Show one errors
      else {
        yield put({
          type: DASHBOARD.TOAST_MESSAGE,
          payload: {
            message:
              err.response.data.message ||
              "Tuve un problema al procesar su solicitud. Por favor inténtalo más tarde",
            type: err.response.data.type || "warn",
          },
        });
      }
    }
  } else {
    if (onTimeoutExecute) {
      yield delay(timeToRetry || APPCONFIG.Times.TimeToReSendRequest);
      yield onTimeoutExecute();
    }
  }
}
