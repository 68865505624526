export default Object.freeze({
  //REQUIRED VALIDATION
  REQUIRED_VALIDATION: {
    STATE: {
      WAITING: "waiting",
      VALIDATING: "validating",
      REQUIRED: "required",
      APPROVED: "approved",
    },
  },
  //COLLECTORS
  COLLECTORS: {
    WORKFLOW: {
      OTD: "otd", //default
      UTD: "utd",
      WTD: "wtd",
    },
    STATUS: {
      LOADING: "loading",
      ERROR: "error",
      SUCCESS: "success",
    },
    DEFAULT_VALUES: {
      NA_VALUE: "na",
      NA_RESPONSE: "NA",
      EMPTY_VALUE: "",
      EMPTY_RESPONSE: "",
      UNDEFINED_VALUE: "nd",
      UNDEFINED_RESPONSE: "ND",
      YES_RESPONSE: "Si",
      NO_RESPONSE: "No",
    },
    TYPES: {
      STRING: "string",
      NUMBER: "number",
      TIME: "time",
      DATE: "date",
      DATETIME: "datetime",
      BOOLEAN: "boolean",
      LIST: "list",
      VOID: "void",
      SIGNER_CANVAS: "signercanvas",
      COORDS: "coords",
      FILE: "file",
      CONSUME_INVENTORY: "consumeInventory",
    },
    DUPLICATION: {
      LEVELS: {
        SERVICE_TASK: "SERVICE_TASK",
        REVIEW: "REVIEW",
        GROUP: "GROUP",
        SUBGROUP: "SUBGROUP",
        COLLECTOR: "COLLECTOR",
        PHOTO: "PHOTO",
      },
      NEW_NAME_LABEL: {
        REVIEW: "de la nueva revisión",
        GROUP: "del nuevo agrupador",
        SUBGROUP: "del nuevo sub-agrupador",
        COLLECTOR: "del nuevo recolector",
        PHOTO: "de la nueva fotografía",
      },
      DELETE_NAME_LABEL: {
        REVIEW: "la revisión",
        GROUP: "el agrupador",
        SUBGROUP: "el sub-agrupador",
        COLLECTOR: "el recolector",
        PHOTO: "la fotografía",
      },
    },
    AUTO_FILL_PROCESS: {
      NA: "na",
      DUP: "dup",
    },
  },
  TRANSACTION_DOCS: {
    STATE: {
      OPEN: "OPEN",
      CLOSED: "CLOSED",
      CONFIRM: "CONFIRM",
      APPROVED: "APPROVED",
      DENIED: "DENIED",
    },
    STATUS: {
      LOADING: "loading",
      SUCCESS: "success",
      ERROR: "error",
    },
    URI_CONTEXT: {
      GET_AUDITED_TRANSACTION_DOCS: "getAuditedTransactionDocs",
      GET_SAVED_COLLECTOR_VALUES_BY_TRANSACTION_DOC:
        "getSavedCollectorValuesByTransactionDoc",
      GET_SAVED_COLLECTOR_VALUES_BY_ORDER: "getSavedCollectorValuesByOrder",
      UPDATE_STATE_AUDITED_TRANSACTION_DOC: "updateStateAuditedTransactionDoc",
      SAVE_COLLECTOR_VALUES: "saveCollectorValues",
      AUTO_SYNC_COLLECTOR_VALUES: "autoSyncCollectorValues",
      COMPLETE_AUDITED_REPORT: "completeAuditedReport",
      GENERATE_REPORT: "generatedReports",
    },
  },
  REPORTS: {
    OUTPUT_FORMATS: {
      PDF: "PDF",
      XLSX: "XLSX",
    },
    STATUS: {
      LOADING: "loading",
      SUCCESS: "success",
      ERROR: "error",
    },
  },
});
