//Libs
import React from "react";
import { useTheme } from "styled-components";
//Components
import { IconButton } from "@material-ui/core";
import Icon from "./Icon";

const SettingsIcon = ({ color, button, onClick }) => {
  const theme = useTheme();
  return button ? (
    <IconButton onClick={onClick}>
      <Icon fill={color || theme.colors.text.high} />
    </IconButton>
  ) : (
    <div onClick={onClick} style={{ height: "24px" }}>
      <Icon fill={color || theme.colors.text.high} />
    </div>
  );
};

export default SettingsIcon;
