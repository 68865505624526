//Libs
import React from "react";
//Components
import { FilterIcon, LogoIcon, Wrapper } from "components/components";
//Styles
import "../index.css";

export default function Transactions({
  warehouse,
  getTransactionDocs,
  mutate1ObjectInWarehouse,
}) {
  const { getTransactionsModal, transactions } = warehouse;

  return (
    <Wrapper padding="0" justifyContent="flex-end">
      <div className="subtoolbar-content">
        {/* Left */}
        <div className="subtoolbar-subcontent-left"></div>

        {/* Center */}
        <div className="subtoolbar-subcontent-center"></div>

        {/* Right */}
        <div className="subtoolbar-subcontent-right">
          <LogoIcon
            button
            spin={transactions.loading || undefined}
            onClick={() =>
              !transactions.loading && getTransactionDocs(getTransactionsModal)
            }
          />

          {/* Filter */}
          <FilterIcon
            onClick={() =>
              mutate1ObjectInWarehouse("getTransactionsModal", {
                isOpen: true,
                mode: undefined,
              })
            }
          />
        </div>
      </div>
    </Wrapper>
  );
}
