// Services
import { ApiServiceV2 } from "services";

const attempts = 3;
const sendCoords = (coords, tried = 0) => {
  if (!coords.latitude || !coords.longitude || tried >= attempts) return;
  try {
    ApiServiceV2.put({
      url: "/users_connected_records/update_my_coords",
      data: coords,
    });
  } catch (err) {
    sendCoords(coords, tried + 1);
  }
};

export default sendCoords;
