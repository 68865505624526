// Libs
import React from "react";

const VideoStream = ({
  videoRef,
  previewVideoCanvasRef,
  takenPhotoConfirmationHeight,
  takenPhotoImgRef,
  videoPreviewSettings,
  isPhotoTaken,
}) => (
  <>
    {/* Video stream canvas */}
    <video ref={videoRef} muted playsInline style={{ display: "none" }} />
    <canvas
      ref={previewVideoCanvasRef}
      width={videoPreviewSettings.width}
      height={videoPreviewSettings.height}
      style={isPhotoTaken ? { display: "none" } : null}
    />
    <div
      style={
        !isPhotoTaken
          ? { display: "none" }
          : {
              width: videoPreviewSettings.width,
              height: videoPreviewSettings.height,
            }
      }
    >
      <img
        ref={takenPhotoImgRef}
        alt="takenPhotoImg"
        width={videoPreviewSettings.width}
        height={videoPreviewSettings.height - takenPhotoConfirmationHeight}
        style={{ objectFit: "contain" }}
      />
    </div>
  </>
);

export default VideoStream;
