//Libs
import React from "react";
import { useTheme } from "styled-components";
//Components
import SmallIcon from "./SmallIcon";

const SelectedIcon = ({ theme, size, fill }) => {
  if (size === theme.sizes.small.name) return <SmallIcon fill={fill} />;
};

const InfoIcon = ({ size, selected, hoverable }) => {
  const theme = useTheme();
  const [hover, setHover] = React.useState(false);

  const fill = React.useMemo(
    () =>
      hover || selected ? theme.colors.hover.color : theme.colors.text.high,
    [hover, selected]
  );

  return (
    <div
      style={{ height: theme.sizes[size].height }}
      onMouseEnter={() => hoverable && setHover(true)}
      onMouseLeave={() => hoverable && setHover(false)}
    >
      <SelectedIcon theme={theme} size={size} fill={fill} />
    </div>
  );
};

export default InfoIcon;
