import PhotoUtils from "./PhotoUtils";

export default class PhotonImage {
  static instance;
  constructor() {
    this.photon = null;
  }

  static async getInstance() {
    if (!this.instance) {
      this.instance = new PhotonImage();
      await this.instance.loadWasm();
    }
    return this.instance;
  }

  async loadWasm() {
    try {
      this.photon = await import("@silvia-odwyer/photon");
    } finally {
      console.log("loaded wasm successfully");
    }
  }

  //Reference: https://silvia-odwyer.github.io/photon/guide/using-photon-web/
  async compress(
    img,
    {
      resizeWidth,
      resizeHeight,
      samplingFilter = 5, //https://docs.rs/photon-rs/latest/photon_rs/transform/enum.SamplingFilter.html
      format,
    }
  ) {
    const { canvas, imgWidth, imgHeight } = PhotoUtils.processImgOrCanvas(img);
    const isLandscape = imgWidth > imgHeight;
    const resizedWidth = Math.min(
      isLandscape ? resizeWidth : resizeHeight,
      imgWidth
    );
    const resizedHeight = Math.min(
      isLandscape ? resizeHeight : resizeWidth,
      imgHeight
    );

    // Draw the image element onto the canvas
    const ctx = canvas.getContext("2d");
    if (PhotoUtils.isImageElement(img)) ctx.drawImage(img, 0, 0);

    // Convert the ImageData found in the canvas to a PhotonImage (so that it can communicate with the core Rust library)
    let image = this.photon.open_image(canvas, ctx);

    // Resize image
    const resizedImage = this.photon.resize(
      image,
      resizedWidth,
      resizedHeight,
      samplingFilter
    );
    const resizedCanvas = document.createElement("canvas");
    resizedCanvas.width = resizedWidth;
    resizedCanvas.height = resizedHeight;
    const resizedCtx = resizedCanvas.getContext("2d");

    // Place the modified image back on the canvas
    this.photon.putImageData(resizedCanvas, resizedCtx, resizedImage);

    return resizedCanvas.toDataURL(format);
  }

  async rotate(canvas, ctx, { width, height, rotateAngle = -90 }) {
    let image = this.photon.open_image(canvas, ctx);
    const rotatedImage = this.photon.rotate(image, rotateAngle);
    ctx.canvas.width = height;
    ctx.canvas.height = width;
    this.photon.putImageData(canvas, ctx, rotatedImage);
  }
}
