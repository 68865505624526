//Libs
import React from "react";
//Utils
import UploadResourceUtils from "./UploadResourceUtils";
//Components
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  Wrapper,
  Span,
  Button,
  Modal,
  DeleteIcon,
  LogoIcon,
} from "components/components";
import defaultImage from "assets/media/img/noImage.png";

const PreviewResource = ({
  //Props
  width,
  height,
  file,
  src,
  fileProps,
  authOptions,
  isLoading,
  isError,
  isSuccess,
  duplicableButtons,
  uploadResource,
}) => {
  //My State
  const [myState, setMyState] = React.useState({
    fetchedFile: undefined,
    preview: false,
    showPhotoDeleteConfirmation: false,
  });
  //Set state preview button
  const setPreviewButton = (newState) =>
    setMyState({ ...myState, ...newState });
  //Handle Preview Image
  const onOpenPreview = () =>
    setPreviewButton({ preview: !!myState.fetchedFile });
  const onClosePreview = () =>
    setPreviewButton({ preview: false, showPhotoDeleteConfirmation: false });
  //Handle Delete Image
  // const handleDeletePhoto = () =>
  //   UploadResourceUtils.removeResourceFromResources(fileProps).then(() =>
  //     UploadResourceUtils.getResourcesToSaveInState({
  //       docId: fileProps.docId,
  //       orderId: fileProps.auditOrderId,
  //       serviceTaskId: fileProps.serviceTaskId,
  //       reviewId: fileProps.reviewId,
  //       groupId: fileProps.groupId,
  //     }).then((resources) => updateResources(resources))
  //   );
  const handleDeletePhoto = () =>
    uploadResource(
      UploadResourceUtils.addFileResource({
        fileProps,
      })
    );

  //Fetch Image Resource
  React.useEffect(() => {
    let isMounted = true;
    UploadResourceUtils.getRemoteImageResource({
      src,
      file,
      noCached: true,
    }).then((fetchedFile) => {
      if (!isMounted) return;
      setPreviewButton({ fetchedFile });
    });

    return () => {
      isMounted = false;
    };
  }, [src, file]);

  return (
    <>
      <Wrapper
        position="relative"
        padding="0"
        width={width}
        height={height}
        justifyContent="center"
      >
        <Wrapper padding="0" position="relative">
          <LazyLoadImage
            style={{ width, height, objectFit: "cover" }}
            alt=""
            src={myState.fetchedFile || defaultImage}
            onClick={onOpenPreview}
          />

          {/* DELETE PHOTO */}
          {authOptions.showActions && (
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgba(0,0,0,.5)",
                bottom: 0,
                right: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DeleteIcon
                light
                onClick={() =>
                  setPreviewButton({
                    preview: true,
                    showPhotoDeleteConfirmation: true,
                  })
                }
              />
            </div>
          )}
        </Wrapper>

        {(isLoading || isError) && (
          <Wrapper padding="0" position="absolute" top="5px" right="5px">
            <LogoIcon spin={true} />
          </Wrapper>
        )}

        {duplicableButtons && duplicableButtons()}
      </Wrapper>

      {myState.preview && (
        <Modal visible={true} footer={null} onCancel={onClosePreview}>
          <img
            alt="Foto"
            style={{ width: "100%", maxHeight: "540px" }}
            src={
              `${src}?t=${new Date().getMilliseconds()}` || file || defaultImage
            }
          />
          {myState.showPhotoDeleteConfirmation && (
            <Wrapper padding="1em 0" flexDirection="column">
              <Span margin="0 0 0.5em 0">¿Desea borrar ésta foto?</Span>
              <Button onClick={handleDeletePhoto}>
                <Span>Confirmar</Span>
              </Button>
            </Wrapper>
          )}
        </Modal>
      )}
    </>
  );
};

export default React.memo(PreviewResource);
