import React, { memo } from "react";

import { InputNumber } from "components/components";

const NumberField = memo(({ title, value, onChange }) => (
  <InputNumber
    width="100%"
    type="number"
    placeholder={title}
    value={value}
    // step={getInputProp({ collector, prop: "step" })}
    // min={getInputProp({ collector, prop: "min" })}
    // max={getInputProp({ collector, prop: "max" })}
    onChange={onChange}
  />
));

export default NumberField;
