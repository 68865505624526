//Libs
import React from "react";
//Components
import Icon from "./Icon";
import { IconButton } from "@material-ui/core";

const ReloadIcon = ({ selected, onClick, button, fill }) => {
  return button ? (
    <IconButton onClick={onClick}>
      <Icon selected={selected} fill={fill} />
    </IconButton>
  ) : (
    <Icon selected={selected} fill={fill} onClick={onClick} />
  );
};

export default ReloadIcon;
