//Libs
import React from "react";
// Components
import { Card, Tooltip } from "antd";
import { Divider, Span } from "components/components";
//Styles
import styles from "./PaymentLineResumeCard.module.css";
//Utils
import { formatNumber } from "../../../../../../utils/libs";

const { Meta } = Card;

export default function PaymentLineResumeCard(props) {
  const {
      line,
      order_id,
      odt_id,
      description,
      amount,
      tracts,
      action_label,
      creator_name,
      entity_name,
      created_at,
      updated_at,
      customProps,
    } = props,
    { coinSymbol, orders, getOrder, mutate1ObjectInOrders } = customProps,
    amountPrice = formatNumber.new(amount, coinSymbol, 2);

  return (
    <Meta
      id="payment_line-card-container"
      title={
        <div className={styles.container}>
          <div>
            <Tooltip
              placement="top"
              className="payment_line-title-line"
              title={description}
            >
              <Span>{line}</Span>
            </Tooltip>
          </div>
          <div className={styles.body}>
            <div className={styles.leftSectionConcepts}>
              <Span opacity="0.6">Creado por:</Span>
              <Span opacity="0.6">Empresa:</Span>
              <Span opacity="0.6">Fecha:</Span>
              <Span opacity="0.6">Actualizado:</Span>
              <Span opacity="0.6">Tractos:</Span>
              <Span opacity="0.6">Orden:</Span>
            </div>
            <div className={styles.rightSectionValues}>
              <Span ellipsis>{creator_name}</Span>
              <Span ellipsis>{entity_name}</Span>
              <Span ellipsis>{created_at}</Span>
              <Span ellipsis>{updated_at}</Span>
              <Span>#{tracts}</Span>
              {order_id && (
                <Span
                  ellipsis
                  onClick={() => {
                    if (!orders.filter((ord) => ord.order_id === order_id)[0])
                      getOrder(order_id);
                    mutate1ObjectInOrders("getOrderInformationModal", {
                      order_id,
                      isOpen: true,
                    });
                  }}
                >
                  {odt_id}
                </Span>
              )}
            </div>
          </div>
          <Divider style={{ background: "lightgray" }} />
          <div className={styles.footerPriceAction}>
            <Span>{amountPrice}</Span>
            <Span>{action_label}</Span>
          </div>
        </div>
      }
    />
  );
}
