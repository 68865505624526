//Libs
import { Checkbox } from "antd";
import styled from "styled-components";

const CheckboxStyled = styled(Checkbox)`
  margin: ${(props) =>
    props.margin ? props.margin : props.theme.checkbox.margin};
  font-size: ${(props) =>
    props.fontSize
      ? props.theme.fonts.size[props.fontSize]
      : props.theme.fonts.size.default};
  color: ${(props) => (props.color ? props.color : props.theme.checkbox.color)};
  display: flex;
  align-items: center;
`;

export default CheckboxStyled;
