// Libs
import React from "react";
//Components
import { Wrapper, CloseIcon } from "components/components";

const PhotoManagerCloseButton = ({ onClose }) => (
  <Wrapper
    position="absolute"
    top="0"
    right="0"
    padding="0"
    backgroundColor="rgb(0,0,0)"
    borderRadius="15px 0 15px"
    onClick={onClose}
  >
    <CloseIcon color="white" fillOpacity="1" />
  </Wrapper>
);

export default PhotoManagerCloseButton;
