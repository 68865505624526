//Libs
import React from "react";
import { useTheme } from "styled-components";
import { TotalGains, Popover } from "components/components";
//Utils
import { formatNumber } from "utils/libs";
//Components
import { FilterIcon, Menu } from "components/components";
//Styles
import "../index.css";
import LogoIcon from "components/components/LogoIcon";

function WorkedTime(props) {
  const { linked } = props;

  return (
    <div className="worked-time-container">
      <div className="worked-time-icon" />
      <span className="worked-time">
        {linked.total_time_connected || "00:00:00"}
      </span>
    </div>
  );
}

function GetOrdersContent(props) {
  const { getOrdersModal, mutate1ObjectInOrders, getOrders } = props;
  const theme = useTheme();

  return (
    <Menu.Wrapper>
      <Menu.Item disabled>Seguimiento</Menu.Item>
      <Menu.Item
        backgroundColor={
          getOrdersModal.category === "dailyTracking"
            ? theme.menu.item.selected.backgroundColor
            : undefined
        }
        onClick={() => {
          mutate1ObjectInOrders("getOrdersModal", {
            category: "dailyTracking",
          });
          getOrders({ ...getOrdersModal, category: "dailyTracking" });
        }}
      >
        Diario
      </Menu.Item>
      <Menu.Item
        backgroundColor={
          getOrdersModal.category === "totalTracking"
            ? theme.menu.item.selected.backgroundColor
            : undefined
        }
        onClick={() => {
          mutate1ObjectInOrders("getOrdersModal", {
            category: "totalTracking",
          });
          getOrders({ ...getOrdersModal, category: "totalTracking" });
        }}
      >
        Total
      </Menu.Item>
      <Menu.Item disabled>Acciones</Menu.Item>
      <Menu.Item
        backgroundColor={
          getOrdersModal.category === "routing"
            ? theme.menu.item.selected.backgroundColor
            : undefined
        }
        onClick={() => {
          mutate1ObjectInOrders("getOrdersModal", {
            showPopoverGetOrders: false,
            category: "routing",
          });
          getOrders({ ...getOrdersModal, category: "routing" });
        }}
      >
        Enrutar
      </Menu.Item>
      <Menu.Item disabled>Historial</Menu.Item>
      <Menu.Item
        backgroundColor={
          getOrdersModal.category === "billed"
            ? theme.menu.item.selected.backgroundColor
            : undefined
        }
        onClick={() => {
          mutate1ObjectInOrders("getOrdersModal", {
            showPopoverGetOrders: false,
            isOpen: true,
            category: "billed",
          });
        }}
      >
        Facturadas
      </Menu.Item>
      <Menu.Item
        backgroundColor={
          getOrdersModal.category === "auditedOrder"
            ? theme.menu.item.selected.backgroundColor
            : undefined
        }
        onClick={() => {
          mutate1ObjectInOrders("getOrdersModal", {
            showPopoverGetOrders: false,
            isOpen: true,
            category: "auditedOrder",
          });
        }}
      >
        Completadas
      </Menu.Item>
    </Menu.Wrapper>
  );
}

export default function Orders(props) {
  const { orders, linked, mutate1ObjectInOrders, getOrders, profile } = props;
  const { getOrdersModal } = orders;
  const gains = formatNumber.new(
    Number(props.gains.gain_sales) + Number(props.gains.gain_orders),
    props.gains.coin_symbol,
    2
  );

  return (
    <div className="subtoolbar-head">
      <div className="subtoolbar-content">
        {/* Left */}
        <div className="subtoolbar-subcontent-left">
          <WorkedTime linked={linked} />
        </div>

        {/* Center */}
        <div className="subtoolbar-subcontent-center">
          {profile?.user?.settings?.orders?.price?.viewGains && (
            <TotalGains label={gains} />
          )}
        </div>

        {/* Right */}
        <div className="subtoolbar-subcontent-right">
          {/* UPDATE ORDERS */}
          <LogoIcon
            button
            spin={orders.getOrdersModal.loading || undefined}
            onClick={() => getOrders(getOrdersModal)}
          />

          {/* GET ORDERS */}
          <Popover
            content={
              <GetOrdersContent
                mutate1ObjectInOrders={mutate1ObjectInOrders}
                getOrdersModal={getOrdersModal}
                getOrders={getOrders}
              />
            }
            placement="bottomRight"
            title={null}
            trigger="click"
            visible={getOrdersModal.showPopoverGetOrders}
            onVisibleChange={() =>
              mutate1ObjectInOrders("getOrdersModal", {
                showPopoverGetOrders: !getOrdersModal.showPopoverGetOrders,
              })
            }
          >
            <FilterIcon />
          </Popover>
        </div>
      </div>
    </div>
  );
}
