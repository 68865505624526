//  Libs
import React from "react";
//Styles
import "./Pays.css";
import { LogoIcon } from "components/components";

export default function Pays(props) {
  const { payments, getPayments } = props;

  return (
    <div className="pays-subtoolbar-head">
      <div className="pays-subtoolbar-content">
        <div className="pays-subtoolbar-subcontent-left" />

        <div className="pays-subtoolbar-subcontent-center" />

        <div className="pays-subtoolbar-subcontent-right">
          {!payments.pays.loading ? (
            <LogoIcon button onClick={getPayments} />
          ) : (
            <LogoIcon spin button />
          )}
        </div>
      </div>
    </div>
  );
}
