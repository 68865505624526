//Libs
import React from "react";
import styled from "styled-components";
//Components
import icon from "./location.svg";

const Icon = styled.div`
	background-image: url(${icon});
	width: 24px;
	height: 24px;
`;

const LocationIcon = ({ onClick }) => <Icon onClick={onClick} />;

export default LocationIcon;
