//Libs
import React from "react";
import { Wrapper, DatePicker } from "components/components";
//Styles
import "../../index.css";

export default function GetOrdersModal(props) {
  const { getOrdersModal, mutate1Object } = props;
  const { fromDate, toDate } = getOrdersModal;

  return (
    <Wrapper width="100%" flexDirection="column">
      <DatePicker
        style={{ marginBottom: "10px" }}
        autoFocus
        allowClear={false}
        placeholder="Desde*"
        value={fromDate}
        onChange={(value) =>
          mutate1Object("getOrdersModal", { fromDate: value })
        }
      />
      <DatePicker
        placeholder="Hasta*"
        allowClear={false}
        value={toDate}
        onChange={(value) => mutate1Object("getOrdersModal", { toDate: value })}
      />
    </Wrapper>
  );
}
