// Libs
import { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// Actions
import { getOrders as _getOrders } from "components/containers/Orders/actions";
// Selectors
import { selectGetOrdersModal } from "components/containers/Orders/selectors";
// Services
import ApiServiceV2 from "services/apiServiceV2";
// Selectors
import {
  selectSelectedProjectId,
  selectSelectedOrderTargetFilterId,
} from "core/selectors";
import useToastMessage from "./useToastMessage";
import { selectSelectedSubModuleId } from "components/components/Modules/selectors";

const initialValue = {};
const useForm = ({ formId }) => {
  const dispatch = useDispatch();
  const getOrders = (props) => dispatch(_getOrders(props));

  //Select from datasource
  const sendToast = useToastMessage();
  const getOrdersModal = useSelector(selectGetOrdersModal);
  const selectedModuleItemId = useSelector(selectSelectedSubModuleId);
  const selectedProjectId = useSelector(selectSelectedProjectId);
  const selectedOrderTargetFilterId = useSelector(
    selectSelectedOrderTargetFilterId
  );

  const [form, setForm] = useState({
    isFetching: true,
    sending: false,
    title: undefined,
    components: [],
  });
  const [values, setValues] = useState(initialValue);
  const check = !!formId;

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      selectedModuleItemId,
      selectedProjectId,
      selectedOrderTargetFilterId,
    }));
  }, [selectedModuleItemId, selectedProjectId, selectedOrderTargetFilterId]);

  //Get form details
  useEffect(() => {
    let isMounted = true;

    (async () => {
      if (!check) return;

      const data = await ApiServiceV2.get({
        url: `/feedManager/v1/form/${JSON.stringify({ formId })}`,
      });
      if (!isMounted) return;

      setForm((prev) => ({
        ...prev,
        ...data,
        isFetching: false,
      }));
    })();

    return () => {
      isMounted = false;
    };
  }, [check, formId]);

  //Send form
  const submitForm = useCallback(() => {
    if (!check) return;

    setForm((prev) => ({
      ...prev,
      sending: true,
    }));
    ApiServiceV2.post({
      url: `/feedManager/v1/form/submit/${JSON.stringify({ formId })}`,
      data: { values },
    })
      .then(() => {
        setForm((prev) => ({
          ...prev,
          sending: false,
        }));
        sendToast({
          message: "Proceso completado",
          type: "success",
        });
        getOrders(getOrdersModal); //TODO: Esto está hardcodeado para Hermatix, se debe pensar bien donde ponerlo, ya que no todos los formularios van a ser: CreateOrder
      })
      .catch((err) => {
        setForm((prev) => ({
          ...prev,
          sending: false,
        }));
        sendToast({
          message: err.message,
          type: "error",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check, formId, getOrdersModal, values]);

  //Get processed component sql data
  const getProcessedComponentSqlData = useCallback(
    async ({ componentId, searchValue, paramValues, returnKey }) => {
      if (!check) return null;
      return ApiServiceV2.post({
        url: `/feedManager/v1/form/component/${JSON.stringify({
          formId,
          componentId,
        })}/processedSqlData`,
        data: {
          selectedModuleItemId,
          selectedProjectId,
          selectedOrderTargetFilterId,
          userParams: {
            ...paramValues,
            searchValue,
          },
        },
      })
        .then((data) => {
          const returnData = data[returnKey];
          delete data[returnKey];
          setValues((prev) => ({ ...prev, ...data }));
          return returnData;
        })
        .catch(() => null);
    },
    [
      check,
      formId,
      selectedModuleItemId,
      selectedProjectId,
      selectedOrderTargetFilterId,
    ]
  );

  return {
    form,
    values,
    setValues,
    submitForm,
    getProcessedComponentSqlData,
  };
};

export default useForm;
