//Libs
import React from "react";
import { connect } from "react-redux";
//Utils
import GENERAL from "utils/constants/general";
//Actions
import {
  getServices,
  mutate1Object as mutate1ObjectInCommandCenter,
} from "../actions";
import {
  selectSelectedProjectId,
  selectSelectedServiceId,
  selectServices,
  selectServicesIsFetching,
} from "../selectors";
//Components
import {
  Wrapper,
  Avatar,
  InitialResourceIcon,
  LogoIcon,
  Tooltip,
} from "components/components";

const { SERVICES } = GENERAL.ENV;

const ServiceIcon = React.memo(({ service, selectedServiceId }) => {
  const { type, value } = service.icon;
  const isSelected = React.useMemo(
    () => service.id === selectedServiceId,
    [selectedServiceId]
  );
  return (
    <Tooltip title={service.name}>
      {(!type || type === SERVICES.ICON.TYPES.INITIAL) && (
        <InitialResourceIcon
          selected={isSelected}
          initial={value ?? "ND"}
          size="normal"
        />
      )}
      {type === SERVICES.ICON.TYPES.IMAGE && (
        <Avatar size="normal" fit avatar={{ src: value }} />
      )}
    </Tooltip>
  );
});

const ServicePicker = React.memo(
  ({
    //Projects
    selectedProjectId,
    //Services
    selectedServiceId,
    isFetching,
    services,
    getServices,
    //Command Center
    mutate1ObjectInCommandCenter,
  }) => {
    React.useEffect(() => {
      if (selectedProjectId) getServices({ projectId: selectedProjectId });
    }, [selectedProjectId]);

    return (
      <Wrapper
        padding="0 10px"
        justifyContent={isFetching ? "center" : "flex-start"}
      >
        {isFetching && <LogoIcon spin={true} />}
        {services.map((service, idx) => (
          <Wrapper
            key={idx}
            padding="0"
            margin={idx + 1 !== services.length ? "0 10px 0 0" : ""}
            onClick={() =>
              mutate1ObjectInCommandCenter("services", {
                selectedServiceId: service.id,
              })
            }
          >
            <ServiceIcon
              service={service}
              selectedServiceId={selectedServiceId}
            />
          </Wrapper>
        ))}
      </Wrapper>
    );
  }
);

const mapStateToProps = (state) => ({
  //Projects
  selectedProjectId: selectSelectedProjectId(state),
  //Services
  selectedServiceId: selectSelectedServiceId(state),
  isFetching: selectServicesIsFetching(state),
  services: selectServices(state),
});
const actions = {
  getServices,
  mutate1ObjectInCommandCenter,
};
export default connect(mapStateToProps, actions)(ServicePicker);
