// !function(){function e(t,o){return n?void(n.transaction("s").objectStore("s").get(t).onsuccess=function(e){var t=e.target.result&&e.target.result.v||null;o(t)}):void setTimeout(function(){e(t,o)},100)}var t=window.indexedDB||window.mozIndexedDB||window.webkitIndexedDB||window.msIndexedDB;if(!t)return void console.error("indexDB not supported");var n,o={k:"",v:""},r=t.open("synapse",1);r.onsuccess=function(e){n=this.result},r.onerror=function(e){console.error("indexedDB request error"),console.log(e)},r.onupgradeneeded=function(e){n=null;var t=e.target.result.createObjectStore("s",{keyPath:"k"});t.transaction.oncomplete=function(e){n=e.target.db}},window.synapseIdb={get:e,set:function(e,t){o.k=e,o.v=t,n.transaction("s","readwrite").objectStore("s").put(o)}}}();

(function () {
  var indexedDB =
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB;
  if (!indexedDB) {
    console.error("indexDB not supported");
    return;
  }
  var db;
  var keyValue = {
    k: "",
    v: "",
  };
  var request = indexedDB.open("synapse", 1);

  request.onsuccess = function (evt) {
    db = this.result;
  };
  request.onerror = function (e) {
    console.error("indexedDB request error");
    console.log(e);
  };

  request.onupgradeneeded = function (e) {
    db = null;
    var store = e.target.result.createObjectStore("orders", {
      keyPath: "k",
    });
    var store = e.target.result.createObjectStore("invoices", {
      keyPath: "k",
    });
    var store = e.target.result.createObjectStore("warehouse", {
      keyPath: "k",
    });
    var store = e.target.result.createObjectStore("templates", {
      keyPath: "k",
    });
    var store = e.target.result.createObjectStore("uploadResources", {
      keyPath: "k",
    });
    var store = e.target.result.createObjectStore("reviewManage", {
      keyPath: "k",
    });
    var store = e.target.result.createObjectStore("manage_inventory", {
      keyPath: "k",
    });

    store.transaction.oncomplete = function (e) {
      db = e.target.db;
    };
  };

  function getValue(key, objStore, callback) {
    if (!db) {
      setTimeout(function () {
        getValue(key, objStore, callback);
      }, 100);
      return;
    }
    db.transaction(objStore).objectStore(objStore).get(key).onsuccess =
      function (e) {
        var result = (e.target.result && e.target.result.v) || null;
        callback(result);
      };
  }

  function removeObject(key, objStore) {
    if (!db) {
      setTimeout(function () {
        removeObject(key, objStore);
      }, 100);
      return;
    }
    db.transaction(objStore, "readwrite").objectStore(objStore).delete(key);
  }

  function clear(objStore) {
    if (!db) {
      setTimeout(function () {
        clear(objStore);
      }, 100);
      return;
    }
    db.transaction(objStore, "readwrite").objectStore(objStore).clear();
  }

  // if using proxy mode comment this
  window["synapseIdb"] = {
    get: getValue,
    del: removeObject,
    clear: clear,
    set: function (key, value, objStore) {
      // no callback for set needed because every next transaction will be anyway executed after this one
      keyValue.k = key;
      keyValue.v = value;
      db.transaction(objStore, "readwrite").objectStore(objStore).put(keyValue);
    },
  };

  // Use only for apps that will only work on latest devices only
  // window.synapseIdb = new Proxy({}, {
  //   get: function(func, key, callback) {
  //     return (key === 'get') ? getValue : function(callback) {
  //       return getValue(key, callback)
  //     };
  //   },
  //   set: function(func, key, value) {
  //     keyValue.k = key;
  //     keyValue.v = value;
  //     db.transaction('str', 'readwrite').objectStore('str').put(keyValue);
  //   }
  // });
})();
