//Libs
import React from "react";
import { Table, Column } from "react-virtualized";
import styled from "styled-components";
//Utils
import {
  getFilterOptionsList,
  onChangeFilter,
  getSearchInputProps,
  getCheckProps,
  getCheckAllProps,
} from "../../utils/libs/filters";
//Components
import IconButton from "./IconButton";
import Popover from "./Popover";
import Wrapper from "./Wrapper";
import Checkbox from "./Checkbox";
import CheckboxGroup from "./CheckboxGroup";
import TextField from "./TextField";

const { Input } = TextField;

//EVENTS
/**onClickRow:
 * Propósito: Seleccionar o deseleccionar el id del item que reciba como parámetro
 * Parámetros(
 *  id: Id del item seleccionado que sera incluido o excluido del array <items>
 *  selected: { Objeto donde que contiene el array de ids de los items que se ha seleccionado
 *    items: []
 *  },
 *  mutate1Object: Funcion que se encarga de actualizar el estado
 *  mutate1ObjectKey: Objeto principal que sera actualizado por <mutate1Object>
 * )
 */
function onClickRow(id, selected, mutate1Object, mutate1ObjectKey) {
  if (selected.items.indexOf(id) === -1) {
    const items = [...selected.items];
    items.push(id);
    mutate1Object(mutate1ObjectKey, { selected: { ...selected, items } });
  } else {
    mutate1Object(mutate1ObjectKey, {
      selected: {
        ...selected,
        items: selected.items.filter((item) => item !== id),
      },
    });
  }
}

/**onDoubleClickRow:
 * Propósito: Seleccionar todos los id de los items de la data que reciba como parámetro
 * Parámetros(
 *  key: Nombre de la propiedad que será evaluada en cada item
 *  selected: { Objeto donde que contiene el array de ids de los items que se ha seleccionado
 *    items: []
 *  },
 *  data: La data de todos los items que seran incluidos o excluidos
 *  mutate1Object: Funcion que se encarga de actualizar el estado
 *  mutate1ObjectKey: Objeto principal que sera actualizado por <mutate1Object>
 * )
 */
function onDoubleClickRow(
  key,
  selected,
  data,
  mutate1Object,
  mutate1ObjectKey
) {
  const items = data.reduce(
    (acc, item) => {
      if (acc.indexOf(item[key]) === -1) {
        acc.push(item[key]);
      }
      return acc;
    },
    [...selected.items]
  );

  mutate1Object(mutate1ObjectKey, { selected: { ...selected, items } });
}

/**onRightClickRow:
 * Propósito: Deseleccionar todos los id de los items de la data que reciba como parámetro
 * Parámetros(
 *  key: Nombre de la propiedad que será evaluada en cada item
 *  selected: { Objeto donde que contiene el array de ids de los items que se ha seleccionado
 *    items: []
 *  },
 *  data: La data de todos los items que seran incluidos o excluidos
 *  mutate1Object: Funcion que se encarga de actualizar el estado
 *  mutate1ObjectKey: Objeto principal que sera actualizado por <mutate1Object>
 * )
 */
function onRightClickRow(
  e,
  key,
  selected,
  data,
  mutate1Object,
  mutate1ObjectKey
) {
  e.preventDefault();
  const items = data.reduce(
    (acc, item) => {
      acc = acc.filter((itm) => itm !== item[key]);
      return acc;
    },
    [...selected.items]
  );

  mutate1Object(mutate1ObjectKey, { selected: { ...selected, items } });
}

//JSX RENDER
const TableStyled = styled(Table)`
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : props.theme.table.borderRadius};
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.table.backgroundColor};
`;

const HeaderStyled = styled.div`
  margin: ${(props) =>
    props.margin ? props.margin : props.theme.table.header.margin};
  padding: ${(props) =>
    props.padding ? props.padding : props.theme.table.header.padding};
  display: ${(props) => props.theme.table.header.display};
  justify-content: ${(props) =>
    props.justifyContent
      ? props.justifyContent
      : props.theme.table.header.justifyContent};
  align-items: ${(props) => props.theme.table.header.alignItems};
  border-radius: ${(props) =>
    props.borderRadius
      ? props.borderRadius
      : props.theme.table.header.borderRadius};
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : props.theme.fonts.family};
  font-size: ${(props) =>
    props.fontSize
      ? props.theme.fonts.size[props.fontSize]
      : props.theme.fonts.size.default};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.table.header.fontWeight};
  font-stretch: ${(props) =>
    props.fontStretch
      ? props.fontStretch
      : props.theme.table.header.fontStretch};
  font-style: ${(props) =>
    props.fontStyle ? props.fontStyle : props.theme.table.header.fontStyle};
  line-height: ${(props) =>
    props.lineHeight ? props.lineHeight : props.theme.table.header.lineHeight};
  letter-spacing: ${(props) =>
    props.letterSpacing
      ? props.letterSpacing
      : props.theme.table.header.letterSpacing};
  text-transform: ${(props) =>
    props.textTransform
      ? props.textTransform
      : props.theme.table.header.textTransform};
  color: ${(props) =>
    props.color ? props.color : props.theme.table.header.color};
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.table.header.backgroundColor};
`;

const HeaderFilterPopoverContent = ({
  dataKey,
  data,
  orderType,
  dateFormat,
  filters,
  mutate1Object,
  mutate1ObjectKey,
  filterProps,
}) => {
  const options = getFilterOptionsList(
    dataKey,
    filters,
    data,
    orderType,
    dateFormat
  );
  return (
    <Wrapper flexDirection="column" alignItems="flex-start">
      <Input
        autoFocus
        placeholder="Buscar"
        size="small"
        margin="0 0 5px 0"
        value={filterProps ? filterProps.inputSearch : undefined}
        onChange={(e) =>
          onChangeFilter(
            getSearchInputProps(e, options),
            dataKey,
            filters,
            mutate1Object,
            mutate1ObjectKey
          )
        }
      />
      <Checkbox
        indeterminate={filterProps ? filterProps.indeterminate : false}
        onChange={(e) =>
          onChangeFilter(
            getCheckAllProps(e, options),
            dataKey,
            filters,
            mutate1Object,
            mutate1ObjectKey
          )
        }
        checked={filterProps ? filterProps.checkAll : true}
      >
        Todos
      </Checkbox>
      <Wrapper
        width="100%"
        display="list-item"
        maxHeight="350px"
        overflow="auto"
      >
        <CheckboxGroup
          flex_direction="column"
          options={options}
          value={filterProps ? filterProps.checkedList : options}
          onChange={(checkedList) =>
            onChangeFilter(
              getCheckProps(checkedList, options),
              dataKey,
              filters,
              mutate1Object,
              mutate1ObjectKey
            )
          }
        />
      </Wrapper>
    </Wrapper>
  );
};

function Header(props) {
  const { children, filter } = props;

  if (!filter) {
    return <HeaderStyled {...props}>{children}</HeaderStyled>;
  } else {
    const { dataKey, filters } = filter;
    const filterProps = filters[dataKey];

    return (
      <HeaderStyled {...props}>
        {children}
        <Popover
          content={
            <HeaderFilterPopoverContent {...filter} filterProps={filterProps} />
          }
          placement="bottom"
        >
          <IconButton
            type="filter"
            size="s"
            style={
              filterProps &&
              (filterProps.indeterminate ||
                (!filterProps.checkAll && !filterProps.indeterminate))
                ? { color: "floralwhite", backgroundColor: "rgba(0,0,0,.15)" }
                : undefined
            }
          />
        </Popover>
      </HeaderStyled>
    );
  }
}

const RowStyled = styled.div`
  display: ${(props) =>
    props.display ? props.display : props.theme.table.row.display};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : props.theme.table.row.alignItems};
  cursor: ${(props) =>
    props.cursor ? props.cursor : props.theme.table.row.cursor};
  border-bottom: ${(props) =>
    props.borderBottom
      ? props.borderBottom
      : props.theme.table.row.borderBottom};
  font-size: ${(props) =>
    props.fontSize
      ? props.theme.fonts.size[props.fontSize]
      : props.theme.fonts.size.default};
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : props.theme.fonts.family};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.table.row.fontWeight};
  font-stretch: ${(props) =>
    props.fontStretch ? props.fontStretch : props.theme.table.row.fontStretch};
  font-style: ${(props) =>
    props.fontStyle ? props.fontStyle : props.theme.table.row.fontStyle};
  line-height: ${(props) =>
    props.lineHeight ? props.lineHeight : props.theme.table.row.lineHeight};
  letter-spacing: ${(props) =>
    props.letterSpacing
      ? props.letterSpacing
      : props.theme.table.row.letterSpacing};
  color: ${(props) =>
    props.color ? props.color : props.theme.table.row.color};
  &:hover {
    font-size: ${(props) =>
      props.fontSizeHover
        ? props.fontSizeHover
        : props.theme.fonts.size.default};
    font-weight: ${(props) =>
      props.fontWeightHover
        ? props.fontWeightHover
        : props.theme.table.row.hover.fontWeight};
  }
  &:active {
    font-size: ${(props) =>
      props.fontSizeActive
        ? props.fontSizeActive
        : props.theme.fonts.size.default};
    font-weight: ${(props) =>
      props.fontWeightActive
        ? props.fontWeightActive
        : props.theme.table.row.active.fontWeight};
  }
`;

const CellStyled = styled.div`
  display: ${(props) =>
    props.display ? props.display : props.theme.table.cell.display};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : props.theme.table.cell.alignItems};
  justify-content: ${(props) =>
    props.justifyContent
      ? props.justifyContent
      : props.theme.table.cell.justifyContent};
`;

export default {
  Table: TableStyled,
  Column,
  Header,
  Row: RowStyled,
  Cell: CellStyled,
  onClickRow,
  onDoubleClickRow,
  onRightClickRow,
};
