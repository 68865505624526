//  Constants
import GENERAL from "../../../utils/constants/general";

const { SIGNUP, DASHBOARD } = GENERAL;

const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
};

//  Functions
export const doSignup = (
  idc,
  name,
  phone,
  email,
  password,
  entity_id,
  organization_id
) => {
  if (idc) idc = idc.trim();
  if (name) name = name.trim();
  if (
    !idc ||
    idc === "" ||
    !name ||
    name === "" ||
    !phone ||
    phone === "" ||
    !email ||
    email === "" ||
    !password ||
    password === "" ||
    !entity_id ||
    !organization_id
  ) {
    return {
      type: DASHBOARD.TOAST_MESSAGE,
      payload: {
        message: "Debe completar todos los campos",
        type: "warn",
      },
    };
  }
  if (!validateEmail(email)) {
    return {
      type: DASHBOARD.TOAST_MESSAGE,
      payload: {
        message: "Debe ingresar un correo electrónico valido",
        type: "warn",
      },
    };
  }
  return {
    type: SIGNUP.SIGNUP,
    payload: { idc, name, phone, email, password, entity_id, organization_id },
  };
};

export function mutateDirectProps(keyValuePairs) {
  return {
    type: SIGNUP.MUTATE_DIRECT_PROPS,
    payload: keyValuePairs,
  };
}

export function getSignupDataWorker() {
  return {
    type: SIGNUP.GET_SIGNUP_DATA,
  };
}

export const doReset = () => {
  return {
    type: SIGNUP.RESET,
  };
};
