//Libs
import React from "react";
import { useTheme } from "styled-components";
//Components
import { Toolbar } from "react-onsenui";
import ToolbarNotifications from "../Notifications";
import FormControl from "@material-ui/core/FormControl";
import { HamburguerComponent } from "..";
import {
  ToolbarTitle,
  SearchIcon,
  AddIcon,
  TextField,
  CloseIcon,
} from "components/components";

const { Input } = TextField;

export function Transactions({
  title,
  showNavBar,
  transactionsManage,
  mutate1ObjectInWarehouse,
  //Notifications
  selectNotification,
}) {
  return (
    <Toolbar>
      {/* LEFT */}
      <div className="left toolbar__left toolbar--material__left hamburger-button-container">
        {/* Hamburger */}
        <HamburguerComponent handleOnClick={showNavBar} />
      </div>

      {/* CENTER */}
      <div className="center toolbar__center toolbar__title toolbar--material__center">
        {/* Title */}
        <ToolbarTitle>{title}</ToolbarTitle>
      </div>

      {/* RIGHT */}
      <div
        className="right toolbar__right toolbar--material__right"
        style={{ display: "flex", alignItems: "center" }}
      >
        {/* Search Button*/}
        <SearchIcon
          onClick={() =>
            mutate1ObjectInWarehouse("transactionsManage", {
              searchInData: {
                ...transactionsManage.searchInData,
                renderToolbar: true,
              },
            })
          }
        />

        {/* Create */}
        <AddIcon
          button
          onClick={() =>
            mutate1ObjectInWarehouse("createTransaction", { isOpen: true })
          }
        />

        {/* Notifications */}
        <ToolbarNotifications selectNotification={selectNotification} />
      </div>
    </Toolbar>
  );
}

export function SearchTransactions({ mutate1ObjectInWarehouse, searchInData }) {
  const theme = useTheme();

  return (
    <Toolbar
      style={
        searchInData.renderToolbar
          ? { backgroundColor: theme.colors.background.medium }
          : undefined
      }
    >
      {/* FILTER ORDER */}
      <FormControl
        id="toolbar-search-order-container"
        className="center animated fadeIn"
      >
        <Input
          id="toolbar-search-order-input"
          height="32px"
          autoFocus
          name="data"
          placeholder="Buscar transacción"
          value={searchInData.data}
          onChange={(e) =>
            mutate1ObjectInWarehouse("transactionsManage", {
              searchInData: {
                ...searchInData,
                [e.target.name]: e.target.value,
              },
            })
          }
          prefix={<SearchIcon small onlyIcon />}
          suffix={
            <CloseIcon
              small
              onlyIcon
              onClick={() =>
                mutate1ObjectInWarehouse("transactionsManage", {
                  searchInData: {
                    ...searchInData,
                    renderToolbar: false,
                    data: "",
                    searchBy: "all",
                  },
                })
              }
            />
          }
        />
      </FormControl>
    </Toolbar>
  );
}
