//Libs
import React from "react";
import styled, { useTheme } from "styled-components";
//Components
import { Empty as _Empty } from "antd";
import { Span } from "components/components";

const EmptyStyled = styled(_Empty)`
  .ant-empty-description {
    /* color: white; */
  }
  &.ant-empty {
    margin: ${(props) => props.margin};
  }

  .ant-empty-normal .ant-empty-image {
    /* height: 25px; */
  }
`;

const EMPTY_ICON =
  "https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg";

const Empty = ({ message, ...rest }) => {
  const theme = useTheme();
  return (
    <EmptyStyled
      {...rest}
      image={EMPTY_ICON}
      description={
        <Span color={theme.colors.text.disabled}>
          {message ?? "No hay datos"}
        </Span>
      }
    />
  );
};

export default Empty;
