//Libs
import React from "react";
import { connect } from "react-redux";
import { useTheme } from "styled-components";
import { mutate1Object as mutate1ObjectInOrders } from "components/containers/Orders/actions";
//Utils
import CONSUME_INVENTORY_KEYWORDS from "components/components/ConsumeInventory/keywords";
import ORDER_KEYWORDS from "components/containers/Orders/keywords";
//Actions
import { getOrders, completeOrder } from "components/containers/Orders/actions";
//Selectors
import {
  selectCompletedCodesFromDepartment,
  selectCompletedCodeProps,
  selectCompletedAuthorizationCodeProps,
  selectCompleteOrderControl,
} from "components/containers/Orders/selectors";
import {
  selectConsumedItemsResume,
  selectResumeControl,
} from "components/components/ConsumeInventory/selectors";
//Hooks
import { useNetwork } from "hooks";
//Components
import {
  Wrapper,
  Title,
  NoConnected,
  LogoIcon,
  Button,
  TextField,
  ResumeInventory,
  ResumeReporting,
  RealisticConfeti,
} from "components/components";
import CompletedAuthorizationCodes from "./CompletedAuthorizationCodes";
import ConfirmOrder from "./Confirm";
import ResumeOrderData from "./ResumeOrderData";

const { TextArea } = TextField;

const VIEWS = {
  CONSUME_INVENTORY_VALIDATING: "dataConsulting",
  DATA_GENERATING: "dataGenerating",
  RESUME: "resume",
  COMPLETING_ORDER: "completingOrder",
  COMPLETED_ORDER: "completedOrder",
};

const ConsumeInventoryValidating = () => {
  return (
    <Wrapper
      flexDirection="column"
      justifyContent="space-evenly"
      height={`${document.documentElement.clientHeight / 2}px`}
    >
      <Title>Validando Datos</Title>
      <LogoIcon size="l" spin={false} />
    </Wrapper>
  );
};

const CompletingOrder = () => {
  return (
    <Wrapper
      flexDirection="column"
      justifyContent="space-evenly"
      height={`${document.documentElement.clientHeight / 2}px`}
    >
      <Title>Completando orden</Title>
      <LogoIcon size="l" spin={false} />
    </Wrapper>
  );
};

const CompletedOrder = () => {
  return (
    <Wrapper
      flexDirection="column"
      justifyContent="space-evenly"
      height={`${document.documentElement.clientHeight / 2}px`}
    >
      <Title>Orden completada</Title>
      <LogoIcon size="l" />
      <RealisticConfeti start={true} />
    </Wrapper>
  );
};

const GeneratingSummary = () => {
  return (
    <Wrapper
      flexDirection="column"
      justifyContent="space-evenly"
      height={`${document.documentElement.clientHeight / 2}px`}
    >
      <Title>Generando Resumen</Title>
      <LogoIcon size="l" spin={false} />
    </Wrapper>
  );
};

const DataResume = ({
  order,
  consumedItemsResume,
  completedCodes,
  completedCodeProps,
  completedAuthorizationCodeProps,
  mutate1ObjectInOrders,
  handleOnConfirm,
  completeOrder,
}) => {
  const [myState, setMyState] = React.useState({
    completedCodeId: undefined,
    completedAuthorizationCode: undefined,
    comment: undefined,
    showConfirm: false,
  });

  const changeMyState = React.useCallback(
    (newState) => setMyState({ ...myState, ...newState }),
    [myState]
  );

  const canComplete = React.useMemo(() => {
    const reviewCompletedCode =
      !completedCodeProps.required ||
      (completedCodeProps.required && myState.completedCodeId);
    const reviewCompletedAutorizationCode =
      !completedAuthorizationCodeProps.required ||
      (completedAuthorizationCodeProps.required &&
        myState.completedAuthorizationCode);

    return reviewCompletedCode && reviewCompletedAutorizationCode;
  }, [myState.completedCodeId, myState.completedAuthorizationCode]);

  return (
    <Wrapper
      padding="0"
      width="100%"
      minHeight={`${(document.documentElement.clientHeight - 105) / 2}px`}
      flexDirection="column"
      alignItems="stretch"
      justifyContent="space-between"
    >
      {/* ORDER DATA */}
      <ResumeOrderData
        order={order}
        mutate1ObjectInOrders={mutate1ObjectInOrders}
      />

      {/* REPORTING */}
      <ResumeReporting />

      {/* INVENTORY */}
      <Title>Inventario</Title>
      <ResumeInventory consumedItemsResume={consumedItemsResume} />

      {/* COMPLETED AUTHORIZATION CODES */}
      <CompletedAuthorizationCodes
        completedCodeId={myState.completedCodeId}
        completedAuthorizationCode={myState.completedAuthorizationCode}
        completedCodeProps={completedCodeProps}
        completedAuthorizationCodeProps={completedAuthorizationCodeProps}
        completedCodes={completedCodes}
        changeMyState={changeMyState}
      />

      {/* COMMENT */}
      <Title margin="10px 0 10px 0">Comentario</Title>
      <Wrapper
        padding="0 10px"
        width="100%"
        flexDirection="column"
        alignItems="stretch"
      >
        <TextArea
          placeholder="Escriba un comentario..."
          rows={3}
          value={myState.comment}
          onChange={(e) => changeMyState({ comment: e.target.value })}
        />
      </Wrapper>

      {/* COMPLETE */}
      <Button
        mode="primary"
        margin="20px 0 0 0"
        onClick={() => canComplete && changeMyState({ showConfirm: true })}
        disabled={!canComplete}
      >
        Completar
      </Button>

      {/* CONFIRM COMPLETE */}
      <ConfirmOrder
        visible={myState.showConfirm}
        onCancel={() => changeMyState({ showConfirm: false })}
        onConfirm={() => {
          changeMyState({ showConfirm: false });
          handleOnConfirm();
          setTimeout(
            () =>
              completeOrder({
                orderId: order.order_id,
                completedCodeId: myState.completedCodeId,
                completedAuthorizationCode: myState.completedAuthorizationCode,
                comment: myState.comment,
              }),
            1500
          );
        }}
      />
    </Wrapper>
  );
};

const Resume = ({
  //Props
  order,
  //Redux
  consumedItemsResume,
  completedCodes,
  completedCodeProps,
  completedAuthorizationCodeProps,
  resumeControl,
  completeOrderControl,
  mutate1ObjectInOrders,
  completeOrder,
  getOrders,
}) => {
  const theme = useTheme();
  const isOnline = useNetwork();
  const [myState, setMyState] = React.useState({
    view: VIEWS.CONSUME_INVENTORY_VALIDATING,
    show: true,
  });

  const setView = (ms, cb) =>
    new Promise((resolve) => setTimeout(() => resolve(cb()), ms));

  const handleOnConfirm = () =>
    setMyState({
      show: false,
      view: VIEWS.RESUME,
    });

  //Generating resume process
  React.useEffect(() => {
    let t1, t2, t3, t4, t5, t6;
    let isMounted = true;
    const generatingResumeTimers = async () => {
      t1 = await setView(
        500,
        () =>
          isMounted &&
          setMyState({
            show: false,
            view: VIEWS.CONSUME_INVENTORY_VALIDATING,
          })
      );
      t2 = await setView(
        1000,
        () =>
          isMounted &&
          setMyState({
            show: false,
            view: VIEWS.DATA_GENERATING,
          })
      );
      t3 = await setView(
        500,
        () =>
          isMounted && setMyState({ show: true, view: VIEWS.DATA_GENERATING })
      );
      t4 = await setView(
        1000,
        () =>
          isMounted &&
          setMyState({
            show: false,
            view: VIEWS.DATA_GENERATING,
          })
      );
      t5 = await setView(
        1000,
        () => isMounted && setMyState({ show: false, view: VIEWS.RESUME })
      );
      t6 = await setView(
        500,
        () => isMounted && setMyState({ show: true, view: VIEWS.RESUME })
      );
    };
    if (resumeControl.status === CONSUME_INVENTORY_KEYWORDS.STATUS.LOADING) {
      setMyState({ show: true, view: VIEWS.CONSUME_INVENTORY_VALIDATING });
    }
    if (resumeControl.status === CONSUME_INVENTORY_KEYWORDS.STATUS.SUCCESS) {
      generatingResumeTimers();
    }
    return () => {
      isMounted = false;
      clearTimeout(t1);
      clearTimeout(t2);
      clearTimeout(t3);
      clearTimeout(t4);
      clearTimeout(t5);
      clearTimeout(t6);
    };
  }, [resumeControl.status]);

  //Order completion process
  React.useEffect(() => {
    let t1, t2, t3;
    let isMounted = true;

    const completingOrderTimers = async () => {
      t1 = await setView(
        100,
        () =>
          isMounted &&
          setMyState({
            show: false,
            view: VIEWS.COMPLETING_ORDER,
          })
      );
      t2 = await setView(
        100,
        () =>
          isMounted &&
          setMyState({
            show: true,
            view: VIEWS.COMPLETING_ORDER,
          })
      );
    };
    const completedOrderTimers = async () => {
      isMounted &&
        setMyState({
          show: false,
          view: VIEWS.COMPLETING_ORDER,
        });
      t1 = await setView(
        1000,
        () =>
          isMounted &&
          setMyState({
            show: false,
            view: VIEWS.COMPLETED_ORDER,
          })
      );
      t2 = await setView(
        100,
        () =>
          isMounted && setMyState({ show: true, view: VIEWS.COMPLETED_ORDER })
      );
      t1 = await setView(
        3000,
        () =>
          isMounted &&
          setMyState({
            show: false,
            view: VIEWS.COMPLETED_ORDER,
          })
      );
      t3 = setTimeout(() => getOrders(), 1000);
    };
    if (
      completeOrderControl.status ===
      ORDER_KEYWORDS.COMPLETE_ORDER.STATUS.LOADING
    ) {
      completingOrderTimers();
    }
    if (
      completeOrderControl.status ===
      ORDER_KEYWORDS.COMPLETE_ORDER.STATUS.SUCCESS
    ) {
      completedOrderTimers();
    }
    return () => {
      isMounted = false;
      clearTimeout(t1);
      clearTimeout(t2);
      clearTimeout(t3);
    };
  }, [completeOrderControl.status]);

  if (!isOnline) {
    return (
      <NoConnected message="Debe tener acceso a internet para poder ver el resumen" />
    );
  }

  if (!myState.view) {
    return null;
  }

  return (
    <Wrapper
      height={`${document.documentElement.clientHeight - 102}px`}
      justifyContent="center"
      alignItems="flex-start"
      overflow="auto"
      backgroundColor={theme.colors.background.high}
      className={myState.show ? "animated fadeIn" : "animated fadeOut fast"}
    >
      {myState.view === VIEWS.CONSUME_INVENTORY_VALIDATING && (
        <ConsumeInventoryValidating />
      )}
      {myState.view === VIEWS.DATA_GENERATING && <GeneratingSummary />}
      {myState.view === VIEWS.RESUME && (
        <DataResume
          order={order}
          consumedItemsResume={consumedItemsResume}
          completedCodes={completedCodes}
          completedCodeProps={completedCodeProps}
          completedAuthorizationCodeProps={completedAuthorizationCodeProps}
          mutate1ObjectInOrders={mutate1ObjectInOrders}
          handleOnConfirm={handleOnConfirm}
          completeOrder={completeOrder}
        />
      )}
      {myState.view === VIEWS.COMPLETING_ORDER && <CompletingOrder />}
      {myState.view === VIEWS.COMPLETED_ORDER && <CompletedOrder />}
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  consumedItemsResume: selectConsumedItemsResume(state),
  completedCodes: selectCompletedCodesFromDepartment(state),
  completedCodeProps: selectCompletedCodeProps(state),
  completedAuthorizationCodeProps: selectCompletedAuthorizationCodeProps(state),
  resumeControl: selectResumeControl(state),
  completeOrderControl: selectCompleteOrderControl(state),
});
const actions = {
  mutate1ObjectInOrders,
  completeOrder,
  getOrders,
};

export default connect(mapStateToProps, actions)(Resume);
