//Libs
import React, { useContext } from "react";
import UploadResourceUtils from "components/components/UploadResource/UploadResourceUtils";
//Context
import { CoordContext } from "components/CoordManager";
//Components
import { Wrapper, IconButton, Spin } from "components/components";
import SignatureCanvas from "react-signature-canvas";

const Signature = ({ fileProps, isLoading, setResource, uploadResource }) => {
  const { coords } = useContext(CoordContext);
  const signatureRef = React.useRef();
  const [myState, setMyState] = React.useState({
    show: false,
  });

  const clear = () => signatureRef.current.clear();
  const handleShow = () =>
    setMyState((prev) => ({ ...prev, show: !myState.show }));

  const handleChangeSignature = React.useCallback(() => {
    if (!signatureRef?.current) return;

    const signImg = signatureRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    setResource((prev) => ({ ...prev, isLoading: true }));
    setTimeout(() => {
      setResource((prev) => ({
        ...prev,
        file: signImg,
      }));
      uploadResource(
        UploadResourceUtils.addFileResource({
          file: signImg,
          fileProps,
          coords,
        })
      );
    }, 300);
  }, [fileProps, signatureRef?.current, coords.createdAt]);

  return (
    <Spin height="unset" spinning={isLoading} size="small" delay={50}>
      <Wrapper padding="0">
        <IconButton type="eye" size="s" onClick={handleShow} />
        {myState.show && <IconButton type="delete" size="s" onClick={clear} />}
        {myState.show && (
          <IconButton type="save" size="s" onClick={handleChangeSignature} />
        )}
      </Wrapper>
      {myState.show && (
        <Wrapper
          boxShadowInner1
          padding="0"
          width="100%"
          height="200px"
          flexDirection="column"
          alignItems="stretch"
        >
          <SignatureCanvas
            ref={signatureRef}
            penColor="#000"
            canvasProps={{
              width: 270,
              height: 200,
            }}
          />
        </Wrapper>
      )}
    </Spin>
  );
};

export default Signature;
