// Libs
import React from "react";
//Components
import { Wrapper } from "components/components";
import Capturing from "./Capturing";
import ControlPanel from "./ControlPanel";

const Switchable = ({
  videoSettings,
  videoPreviewSettings,
  onTakenPhotoConfirm,
  onPhotoManagerClose,
  profile,
}) => {
  const [state, _setState] = React.useState({
    isControlPanelOpen: false,
    isControlPanelHide: true,
  });
  const setState = (newState) =>
    _setState((prev) => ({ ...prev, ...newState }));

  const onControlPanelOpen = () =>
    setState({ isControlPanelOpen: true, isControlPanelHide: false });
  const onControlPanelHide = () => {
    setState({ isControlPanelHide: true });
    setTimeout(() => setState({ isControlPanelOpen: false }), 400);
  };

  return (
    <Wrapper position="relative" padding="0">
      {/* TODO: Hacer que se reinicie el capturing cuando se detecta un cambio de orientación del dispositivo */}
      <Capturing
        videoSettings={videoSettings}
        videoPreviewSettings={videoPreviewSettings}
        onTakenPhotoConfirm={onTakenPhotoConfirm}
        onControlPanelOpen={onControlPanelOpen}
        onPhotoManagerClose={onPhotoManagerClose}
        profile={profile}
      />
      {state.isControlPanelOpen && (
        <ControlPanel
          hide={state.isControlPanelHide}
          onHide={onControlPanelHide}
        />
      )}
    </Wrapper>
  );
};

export default Switchable;
