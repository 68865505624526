export default Object.freeze({
  MUTATE_1OBJECT: "CI_MUTATE_1OBJECT",
  GET_CURRENT_CONSUMED_ITEMS: "CI_GET_CURRENT_CONSUMED_ITEMS",
  GET_CURRENT_CONSUMED_ITEMS_SUCCESS: "CI_GET_CURRENT_CONSUMED_ITEMS_SUCCESS",
  MUTATE_CONSUME_INVENTORY_CONTROL: "CI_MUTATE_CONSUME_INVENTORY_CONTROL",
  MUTATE_UNINSTALL_INVENTORY_CONTROL: "CI_MUTATE_UNINSTALL_INVENTORY_CONTROL",
  MUTATE_RESUME_CONTROL: "CI_MUTATE_RESUME_CONTROL",
  SAVE_CONSUMED_INVENTORY: "CI_SAVE_CONSUMED_INVENTORY",
  SAVE_CONSUMED_INVENTORY_SUCCESS: "CI_SAVE_CONSUMED_INVENTORY_SUCCESS",
  SET_AUTO_SYNC: "CI_SET_AUTO_SYNC",
  START_AUTO_SYNC_CONSUMED_ITEMS: "CI_START_AUTO_SYNC_CONSUMED_ITEMS",
  GENERATE_RESUME: "CI_GENERATE_RESUME",
  RESET_RESUME_CONTROL: "CI_RESET_RESUME_CONTROL",
});
