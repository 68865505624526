//Libs
import moment from "moment";
//Utils
import GENERAL from "utils/constants/general";

const { WAREHOUSE } = GENERAL;

/****** INITIALSTATE *******/
const initialState = {
  searchInData: {
    renderToolbar: false,
    data: "",
    pressEnter: false,
  },
  stockArticlesManage: {
    view: "articles", //articles, series-grouped, series-peps
    selectedItems: [],
    articles: {
      loading: false,
      data: [],
    },
  },
  stockSeriesManage: {
    filter: "all", //all, new, reconditioned
    selectedSeries: [],
    itemId: undefined,
    stateId: undefined,
    searchInData: {
      renderToolbar: false,
      showPopover: false,
      data: "",
      searchBy: "all", //all, name, serie, code
      pressEnter: false,
    },
    //New structure (my families)
    selectedFamilyId: undefined,
    selectedItemIds: [],
    // selectedStateIds: [],
    families: {
      loading: false,
      data: [],
    },
    series: {
      isOpen: false,
      get: undefined,
      loading: false,
      data: [],
    },
  },
  //////////////////////////////////
  transactionsManage: {
    docId: undefined,
    deleting: false,
    selectedTransactions: [],
    showPopoverCreate: false,
    searchInData: {
      renderToolbar: false,
      showPopover: false,
      data: "",
      searchBy: "all", //doc_number, oWarehouse, dWarehouse
    },
  },
  createTransaction: {
    isOpen: false,
    loading: false,
    preview: false,
    owarehouseId: undefined,
    dwarehouseId: undefined,
    dwhOwnerId: undefined,
    signer: undefined,
    incomeMethod: undefined,
    comment: undefined,
    articles: [],
    serialized: [],
  },
  inventoryToTransferManage: {
    //Compare new entry series with external series
    comparing: false,
    compareSeries: undefined,
    showPopoverCompareSeries: false,
    selectedItems: [],
    searchInData: {
      renderToolbar: false,
      showPopover: false,
      data: "",
      searchBy: "all", //code, name, serie
      pressEnter: false,
    },
  },
  itemsTransactionDoc: {
    // viewSeries: false,
    updating: false,
    operation: undefined, //editing
    selectedItems: [],
    searchInData: {
      renderToolbar: false,
      showPopover: false,
      data: "",
      searchBy: "all", //code, name, serie
    },
  },
  transactions: {
    loading: false,
    data: [],
    items: {
      loading: false,
      data: [],
    },
    inventory: {
      loading: false,
      data: [],
    },
  },
  getTransactionsModal: {
    isOpen: false,
    mode: "assignment", //assignment, document, completed
    docNumber: undefined,
    fromDate: moment(),
    toDate: moment(),
  },
};

const warehouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case WAREHOUSE.MUTATE_1OBJECT: {
      const { obj1Name, keyValuePairs } = action.payload,
        newObj = { ...state[obj1Name], ...keyValuePairs };
      return {
        ...state,
        [obj1Name]: newObj,
      };
    }
    case WAREHOUSE.MUTATE_DIRECT_PROPS: {
      const keyValuePairs = action.payload;
      return {
        ...state,
        ...keyValuePairs,
      };
    }
    case WAREHOUSE.MUTATE_STOCK_SERIES_MANAGE: {
      const { obj1Name, keyValuePairs } = action.payload;
      const newObj = { ...state.stockSeriesManage[obj1Name], ...keyValuePairs };

      return {
        ...state,
        stockSeriesManage: {
          ...state.stockSeriesManage,
          [obj1Name]: newObj,
        },
      };
    }
    case WAREHOUSE.RESET_CREATE_TRANSACTION: {
      return {
        ...state,
        createTransaction: {
          isOpen: false,
          loading: false,
          preview: false,
          owarehouseId: undefined,
          dwarehouseId: undefined,
          dwhOwnerId: undefined,
          signer: undefined,
          incomeMethod: undefined,
          comment: undefined,
          articles: [],
          serialized: [],
        },
      };
    }
    case WAREHOUSE.RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default warehouseReducer;
