//Libs
import React from "react";
import { useTheme } from "styled-components";
//Components
import IconButton from "@material-ui/core/IconButton";
import Icon from "./Icon";

const PersonIcon = ({ button, onClick }) => {
	const theme = useTheme();
	return button ? (
		<IconButton onClick={onClick}>
			<Icon fill={theme.colors.text.high} />
		</IconButton>
	) : (
		<Icon fill={theme.colors.text.high} onClick={onClick} />
	);
};

export default PersonIcon;
