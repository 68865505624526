//Libs
import React from "react";
//Utils
import { filterWarehouseSearchInData } from "components/containers/Warehouse/utils";
//Components
import { VirtualizedList } from "components/containers/Utils";
import SeriesFromItems from "./SeriesFromItems";

const Serialized = ({
  //Props
  searchInData,
  stockSeriesManage,
  stockFamilies,
  stockSeries,
  //Actions
  mutate1Object,
  mutate1ObjectInStockSeriesManage,
  getMyFamilies,
  getStockSeries,
  getSerieHistory,
  mutate1ObjectInApi,
}) => {
  React.useEffect(() => {
    getMyFamilies();
  }, []);

  const {
    families,
    selectedFamilyId,
    selectedItemIds,
    series: seriesManage,
  } = stockSeriesManage;

  //Item States
  const states = React.useMemo(() => {
    const family = stockFamilies.find((f) => f.id === selectedFamilyId);
    if (!family) return [];

    return family?.items
      .filter((i) => selectedItemIds.indexOf(i.id) !== -1)
      .reduce((acc, i) => {
        i.states.map(
          ({ id, name }) =>
            !acc.find((st) => st.id === id) && acc.push({ id, name })
        );
        return acc;
      }, []);
  }, [selectedFamilyId, selectedItemIds, stockFamilies]);

  return (
    <>
      <VirtualizedList
        cardId="stockFamilyItemCard"
        listStyle={{
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight - 104,
        }}
        cardStyle={{
          width: 300,
          height: 140,
        }}
        rowHeight={150}
        rows={stockFamilies}
        isSpinning={families.loading}
        customProps={{
          selectedItemIds: selectedItemIds,
          selectedFamilyId: selectedFamilyId,
          mutate1Object,
          mutate1ObjectInStockSeriesManage,
          getStockSeries,
        }}
      />

      <SeriesFromItems
        selectedItemIds={selectedItemIds}
        states={states}
        stockSeries={stockSeries}
        seriesManage={seriesManage}
        searchInData={searchInData}
        mutate1ObjectInWarehouse={mutate1Object}
        mutate1ObjectInStockSeriesManage={mutate1ObjectInStockSeriesManage}
        getStockSeries={getStockSeries}
        mutate1ObjectInApi={mutate1ObjectInApi}
        getSerieHistory={getSerieHistory}
      />
    </>
  );
};
export default Serialized;
