//Types
import KEYWORDS from "./keywords";
const { ACTIONS } = KEYWORDS;

//******** INITIAL STATE ***********/
const initialState = {
  show: false,
  open: false,
};

const autoscanReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.MUTATE_DIRECT_PROPS: {
      const keyValuePairs = action.payload;
      return {
        ...state,
        ...keyValuePairs,
      };
    }
    case ACTIONS.RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default autoscanReducer;
