//Libs
import React from "react";
import { Card } from "antd";
// Components
import { Span } from "components/components";
//Styles
import styles from "./PaymentOrderResumeCard.module.css";
//Utils
import { formatNumber } from "../../../../../../utils/libs";

const { Meta } = Card;

export default function PaymentOrderResumeCard(props) {
  const {
      order_id,
      odt_id,
      expert_name,
      task_name,
      amount,
      bonus,
      service_name,
      counter,
      totalCount,
      customProps,
      unitPrice,
    } = props,
    { coinSymbol, orders, mutate1ObjectInOrders, getOrder, groupOrders } =
      customProps,
    amountPrice = formatNumber.new(amount, coinSymbol, 2),
    bonusPrice = formatNumber.new(bonus, coinSymbol, 2),
    flagBonus =
      bonus && bonus >= 0.01 ? { backgroundColor: "white" } : undefined;
  return (
    <div>
      <div className="order_resume-flag-payment-state" style={flagBonus} />
      <Meta
        id="order_resume-card-container"
        title={
          groupOrders ? (
            <div className={styles.container}>
              <div className={styles.header}>
                <Span id={styles.title} fontSize="14px" width="208" ellipsis>
                  {task_name}
                </Span>
              </div>
              <div className={styles.body}>
                <div className={styles.conceptAndValue}>
                  <Span opacity="0.6">Unidades:</Span>
                  <Span opacity="0.6">Monto:</Span>
                </div>
                <div className={styles.conceptAndValue}>
                  <Span>{totalCount}</Span>
                  <Span ellipsis>
                    {formatNumber.new(unitPrice, coinSymbol, 2)} /{" "}
                    {formatNumber.new(amount + bonus, coinSymbol, 2)}
                  </Span>
                  <div className={styles.counterContainer}>
                    <Span id={styles.counter}>{counter}</Span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.container}>
              <div className={styles.body}>
                <div className={styles.conceptAndValue}>
                  <Span opacity="0.6">ID:</Span>
                  <Span opacity="0.6">Precios:</Span>
                  <Span opacity="0.6">Tipo:</Span>
                  <Span opacity="0.6">Técnico:</Span>
                </div>
                <div className={styles.conceptAndValue}>
                  <Span
                    onClick={() => {
                      if (!orders.filter((ord) => ord.order_id === order_id)[0])
                        getOrder(order_id);
                      mutate1ObjectInOrders("getOrderInformationModal", {
                        order_id,
                        isOpen: true,
                      });
                    }}
                  >
                    {odt_id}
                  </Span>
                  <Span>{`${amountPrice} / ${bonusPrice}`}</Span>
                  <Span ellipsis>{service_name}</Span>
                  <Span ellipsis>{expert_name}</Span>
                  <div className={styles.counterContainer}>
                    <Span id={styles.counter}>{counter}</Span>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      />
    </div>
  );
}
