// Libs
import { useEffect, useState } from "react";
// Services
import { SentryService } from "services";

const checkOrientation = !!window.screen.orientation;
const rootElement = document.getElementById("root");
const getOrientation = (orientation) => orientation.match(/\w+/)[0];

const useScreenOrientation = ({ onChangeListener } = {}) => {
  const [state, _setState] = useState(() => ({
    requestFullscreen: !!rootElement?.requestFullscreen,
    orientation: checkOrientation
      ? getOrientation(window.screen.orientation.type)
      : "portrait",
  }));

  const setState = (newState) =>
    _setState((prev) => ({ ...prev, ...newState }));

  const onLock = (_orientation = "portrait") => {
    if (!checkOrientation || !state.requestFullscreen) return;

    rootElement
      .requestFullscreen()
      .then(() => window.screen.orientation.lock(_orientation))
      .catch(SentryService.sendError);
  };
  const onUnlock = () => {
    if (!checkOrientation) return;

    document
      .exitFullscreen()
      .then(window.screen.orientation.unlock)
      .catch(SentryService.sendError);
  };

  //On Change orientation
  useEffect(() => {
    if (!checkOrientation || !onChangeListener) return;
    window.screen.orientation.onchange = function () {
      setState({ orientation: getOrientation(window.screen.orientation.type) });
    };
  }, [onChangeListener]);

  return {
    orientation: state.orientation,
    onLock,
    onUnlock,
  };
};
export default useScreenOrientation;
