// Libs
import React from "react";
//Components
import { Wrapper, Title, CloseIcon } from "components/components";
import SelectDevices from "./SelectDevices";
import PhotoBackupCheck from "./PhotoBackupCheck";

const TitlePanel = ({ onHide }) => (
  <Wrapper width="100%" padding="0" justifyContent="space-between">
    <Title>Configuración</Title>
    <CloseIcon fillOpacity="1" onClick={onHide} />
  </Wrapper>
);

const SettingList = ({ children }) => (
  <Wrapper
    padding="0 10px"
    flexDirection="column"
    justifyContent="flex-start"
    alignItems="flex-start"
  >
    {children}
  </Wrapper>
);

const SettingItem = ({ children }) => (
  <Wrapper width="100%" margin="16px 0 0 0" padding="0">
    {children}
  </Wrapper>
);

const ControlPanel = ({ hide, onHide }) => {
  return (
    <Wrapper
      className={hide ? "animated zoomOut faster" : "animated zoomIn faster"}
      position="absolute"
      width="100%"
      height="300px"
      bottom="0"
      right="0"
      backgroundColor="white"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <TitlePanel onHide={onHide} />
      <SettingList>
        <SettingItem>
          <SelectDevices />
        </SettingItem>
        <SettingItem>
          <PhotoBackupCheck />
        </SettingItem>
      </SettingList>
    </Wrapper>
  );
};

export default ControlPanel;
