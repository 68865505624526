//Libs
import moment from "moment";
import _ from "underscore";
//Utils
import { idbHandler } from "utils/libs";
import GENERAL from "utils/constants/general";

const { ORDERS } = GENERAL;
const now = moment();

//******** INITIAL STATE ***********/
const initialState = {
  control: {
    activeTab: "1",
    orders: [],
    role: "view", // view, routing
    routing: false,
    photosNeededSync: 0,
    synchronizingPhotos: false,
    searchInData: {
      renderToolbar: false,
      data: "",
      searchBy: "all", //deepSearch, all
      showPopover: false,
      pressEnter: false,
    },
  },
  //AutoSync
  autoSyncOfflineData: {
    isOpen: false,
  },
  invoice: {
    order_id: undefined,
    preview: false,
    status: "invoice", //invoice, sending
    isOpenUninstallModal: false,
    searchInData: {
      renderToolbar: false,
      data: "",
      searchBy: "all", //all
    },
    uninstallModal: {
      isOpen: false,
      item_id: undefined,
      serie: undefined,
    },
    completedCodeModal: {
      isOpen: false,
      completed_code_id: undefined,
      comment: undefined,
    },
    photoReport: {
      complete: false,
      compressingPhoto: false,
      template_id: undefined,
      comment: undefined,
      isOpenCommentModal: false,
      frames: [],
    },
    articles: [],
    serialized: [],
    uninstalled: [],
  },
  importPhotoReport: {
    isOpen: false,
    loading: false,
    originOrderId: undefined,
    path: null,
  },
  //Modals
  getOrdersModal: {
    isOpen: false,
    loading: false,
    showPopoverGetOrders: false,
    category: "totalTracking", //dailyTracking, totalTracking, routing, billed, auditedOrder
    fromDate: now,
    toDate: now,
  },
  sendEventModal: {
    isOpen: false,
    loading: false,
    orderId: undefined,
    eventId: undefined,
    reprogramDate: undefined,
    reprogramTime: now,
    comment: "",
  },
  getOrderInformationModal: {
    activeTab: "1",
    order_id: null,
    isOpen: false,
    loading: false,
    history: [],
    //Comments
    viewComments: false,
    newComment: undefined,
    sendingComment: false,
    //Reports
    billingReport: {
      id: undefined,
      order_id: undefined,
      inventory: [],
      comments: [],
    },
    photoReport: {
      path: {},
      comments: [],
    },
    expedients: [],
  },
  completeOrderControl: {
    status: undefined,
  },
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDERS.MUTATE_DIRECT_PROPS: {
      const keyValuePairs = action.payload;
      return {
        ...state,
        ...keyValuePairs,
      };
    }
    case ORDERS.MUTATE_1OBJECT: {
      const { obj1Name, keyValuePairs } = action.payload,
        newObj = { ...state[obj1Name], ...keyValuePairs };
      return {
        ...state,
        [obj1Name]: newObj,
      };
    }
    case ORDERS.MAKE_INVOICE_PERSIST_IN_INDEXEDDB: {
      const keyValuePairs = action.payload,
        invoice = { ...state.invoice, ...keyValuePairs };

      idbHandler.getInvoices().then((invoices) => {
        let idx = _.findIndex(
          invoices,
          (inv) => inv.order_id === invoice.order_id
        );
        if (idx === -1) invoices.unshift(invoice);
        else invoices[idx] = invoice;
        idbHandler.setInvoices(invoices);
      });

      return {
        ...state,
        invoice,
      };
    }
    case ORDERS.SEND_ONE_PHOTO_TO_BACKEND_STATE: {
      const { status, photoId } = action.payload,
        invoice = { ...state.invoice };

      invoice.photoReport.frames.map((frame) => {
        if (frame.id === photoId) frame.status = status;
      });

      idbHandler.getInvoices().then((invoices) => {
        let idx = _.findIndex(
          invoices,
          (inv) => inv.order_id === invoice.order_id
        );
        if (idx === -1) invoices.unshift(invoice);
        else invoices[idx] = invoice;
        idbHandler.setInvoices(invoices);
      });

      return {
        ...state,
        invoice,
      };
    }
    case ORDERS.RESET_EVENT_MODAL: {
      return {
        ...state,
        sendEventModal: {
          isOpen: false,
          loading: false,
          orderId: undefined,
          eventId: undefined,
          reprogramDate: undefined,
          reprogramTime: now,
          comment: "",
        },
      };
    }
    case ORDERS.RESET_ORDER_INFORMATION: {
      return {
        ...state,
        getOrderInformationModal: {
          order_id: null,
          isOpen: false,
          loading: false,
          activeTab: "1",
          history: [],
          //Comments
          viewComments: false,
          newComment: undefined,
          sendingComment: false,
          //Reports
          billingReport: {
            inventory: [],
            comments: [],
          },
          photoReport: {
            path: {},
            comments: [],
          },
          expedients: [],
        },
      };
    }
    case ORDERS.RESET_INVOICE: {
      return {
        ...state,
        invoice: {
          order_id: undefined,
          preview: false,
          status: "invoice", //invoice, sending
          isOpenUninstallModal: false,
          searchInData: {
            renderToolbar: false,
            data: "",
            searchBy: "all", //all
          },
          uninstallModal: {
            isOpen: false,
            item_id: undefined,
            serie: undefined,
          },
          completedCodeModal: {
            isOpen: false,
            completed_code_id: undefined,
            comment: undefined,
          },
          photoReport: {
            complete: false,
            compressingPhoto: false,
            template_id: undefined,
            comment: undefined,
            isOpenCommentModal: false,
            frames: [],
          },
          articles: [],
          serialized: [],
          uninstalled: [],
        },
      };
    }
    case ORDERS.RESET_COMPLETE_ORDER_CONTROL: {
      return {
        ...state,
        completeOrderControl: {
          ...initialState.completeOrderControl,
        },
      };
    }
    case ORDERS.RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default orderReducer;
