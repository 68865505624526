//Libs
import React, { useMemo } from "react";
import { useTheme } from "styled-components";
//Utils
import { getDivisorsFromTemplateDataStructure } from "components/containers/Orders/utils";
import { formatNumber } from "utils/libs";
import GENERAL from "utils/constants/general";
//Components
import {
  Wrapper,
  Chip,
  Card,
  Span,
  Timer,
  StartAt,
  TimeElapsed,
  TimeRemaining,
  LineDescription,
} from "components/components";

const { ENV } = GENERAL;

function SectionDescription({ children }) {
  return (
    <Wrapper
      margin="5px 0 0 0"
      padding="2px"
      flexDirection="column"
      alignItems="inherit"
    >
      {children}
    </Wrapper>
  );
}

function OrderSection({ order, profile, theme }) {
  const actions = [];
  const orderPrice = formatNumber.new(
    order.warranty ? 0.0 : order.price?.price,
    order.price?.symbol,
    2
  );
  const completedAuthorizationCodeRequired =
    order.service_props?.completedAuthorizationCode?.required !== undefined
      ? order.service_props?.completedAuthorizationCode?.required
      : order.departmentProps?.completedAuthorizationCode?.required;
  const completedAuthorizationCodeLabel =
    order.service_props?.completedAuthorizationCode?.label !== undefined
      ? order.service_props?.completedAuthorizationCode?.label
      : order.departmentProps?.completedAuthorizationCode?.label;

  //IS WARRANTY?
  if (order.warranty) {
    actions.push(
      <Span color="orange" fontSize="m">
        Garantía
      </Span>
    );
  }

  //VIEW PRICE?
  if (profile?.user?.settings?.orders?.price?.viewIndividual) {
    actions.push(
      <Chip
        id="chip"
        size="small"
        style={{ height: "22px" }}
        label={orderPrice}
      />
    );
  }
  return (
    <Card
      style={{ width: "100%", marginBottom: "3px" }}
      actions={actions}
      title={
        // ORDER ID
        <LineDescription
          conceptStyle={{ color: theme.colors.text.disabled, fontSize: "16px" }}
          valueStyle={{ color: theme.colors.secondary[800], fontSize: "16px" }}
          padding="0"
          concept="Orden"
          value={order.order_id}
        />
      }
      description={
        <SectionDescription>
          {/* ODT */}
          <LineDescription concept="ODT" value={order.odt_id} />
          {/* SERVICE NAME */}
          <LineDescription concept="Servicio" value={order.service_name} />
          {/* SERVICE NAME */}
          <LineDescription concept="Proyecto" value={order.department_name} />
          {/* CREATED BY */}
          <LineDescription concept="Creada por" value={order.creator_name} />
          {/* CREATED AT */}
          <LineDescription concept="Creada el" value={order.created_at} />
          {/* COMPLETED CODE INTERNAL SYN4PSE */}
          {order.completed_code_name && (
            <LineDescription
              concept="Cod. Completado Int"
              value={order.completed_code_name}
            />
          )}
          {/* COMPLETED CODE EXTERNAL SYN4PSE */}
          {order.completed_code && (
            <LineDescription
              concept="Cod. Completado Ext"
              value={order.completed_code}
            />
          )}
          {/* COMPLETED CODE EXTERNAL SYN4PSE */}
          {(completedAuthorizationCodeRequired ||
            order.completed_authorization_code) && (
            <LineDescription
              conceptStyle={
                completedAuthorizationCodeRequired
                  ? {
                      fontWeight: "bold",
                    }
                  : null
              }
              concept={completedAuthorizationCodeLabel}
              value={order.completed_authorization_code}
            />
          )}
          {/* ROUTE POSITION */}
          <LineDescription
            concept="Enrutada en posición"
            value={order.att_position}
          />
          {/* ROUTE UPDATED */}
          <LineDescription
            concept="Enrutada el"
            value={order.formatLastAttPositionAt}
          />
        </SectionDescription>
      }
    />
  );
}

function ClientSection({ order, theme }) {
  return (
    <Card
      style={{ width: "100%", marginBottom: "3px" }}
      title={
        // CLIENT ID
        <LineDescription
          conceptStyle={{ color: theme.colors.text.disabled, fontSize: "16px" }}
          valueStyle={{ color: theme.colors.secondary[800], fontSize: "16px" }}
          padding="0"
          concept="Cliente"
          value={order.client_id}
        />
      }
      description={
        <>
          {/* DESCRIPTION CONTAINER */}
          <SectionDescription>
            {/* NAME */}
            <LineDescription concept="Nombre" value={order.client_name} />
            {/* IDENTIFICATION */}
            <LineDescription concept="Identidad" value={order.client_idc} />
            {/* SUSCRIPTION NUMBER */}
            <LineDescription
              concept="Nº Suscriptor"
              value={order.client_suscription_number}
            />
            {/* SERVICE NAME */}
            <LineDescription concept="Teléfono" value={order.client_phones} />
          </SectionDescription>
        </>
      }
    />
  );
}

function ContractSection({ order, theme }) {
  return (
    <Card
      style={{ width: "100%", marginBottom: "3px" }}
      title={
        // CONTRACT ID
        <LineDescription
          conceptStyle={{ color: theme.colors.text.disabled, fontSize: "16px" }}
          valueStyle={{ color: theme.colors.secondary[800], fontSize: "16px" }}
          padding="0"
          concept="Contrato"
          value={order.contract_id}
        />
      }
      description={
        <>
          {/* DESCRIPTION CONTAINER */}
          <SectionDescription>
            {/* CONTRACT NUMBER */}
            <LineDescription
              concept="Nº Externo"
              value={order.contract_number}
            />
            {/* CONTRACT TYPE */}
            <LineDescription concept="Tipo" value={order.suscription_type} />
            {/* DEVICES */}
            <LineDescription concept="Equipos" value={order.devices} />
            {/* CITY */}
            <LineDescription concept="Ciudad" value={order.city} />
            {/* TOWNSHIP */}
            <LineDescription concept="Municipio" value={order.township} />
            {/* ZONE */}
            <LineDescription concept="Zona" value={order.zone} />
            {/* DISTANCE */}
            <LineDescription concept="Distancia" value={order.distance} />
            {/* ADDRESS */}
            <LineDescription
              concept="Dirección"
              value={order.address?.toLowerCase()}
            />
          </SectionDescription>
        </>
      }
    />
  );
}

function StateSection({ order, theme }) {
  return (
    <Card
      style={{
        width: "100%",
        marginBottom: "3px",
      }}
      actionsPadding="0 0 0 1em"
      title={
        // STATE
        <LineDescription
          conceptStyle={{ color: theme.colors.text.disabled, fontSize: "16px" }}
          valueStyle={{ color: theme.colors.secondary[800], fontSize: "16px" }}
          padding="0"
          concept="Estado"
          value={`${order.state_name} / ${order.event_name}`}
        />
      }
      description={
        <>
          {/* DESCRIPTION CONTAINER */}
          <SectionDescription>
            {/* ASSIGNED */}
            <LineDescription concept="Asignada a" value={order.expert_name} />
            {/* RECEIVED */}
            <LineDescription
              concept="Leída"
              value={order.props?.seen ? "Si" : "No"}
            />
            {/* EVENT AT */}
            <LineDescription
              concept="Fecha Evento"
              value={order.formatLastEventAt}
            />
            {/* REPROGRAM AT */}
            <LineDescription
              concept="Fecha Reprogramación"
              value={order.formatReprogramAt}
            />
            {/* COMMENTS */}
            <LineDescription concept="Comentario" value={order.event_comment} />
            {/* FAULT NOTES */}
            <LineDescription concept="Notas" value={order.fault_notes} />
          </SectionDescription>
        </>
      }
    />
  );
}

function PriorityAndIndicatorsSection({ order, profile, theme }) {
  return (
    <Card
      style={{ width: "100%", marginBottom: "3px" }}
      title={
        // KPIS
        <LineDescription
          padding="0"
          conceptStyle={{ color: theme.colors.text.disabled, fontSize: "16px" }}
          concept="KPIs"
        />
      }
      description={
        <>
          {/* DESCRIPTION CONTAINER */}
          <SectionDescription>
            {/* ATTENTION PRIORITY */}
            <LineDescription
              wrapperStyle={{ ...order.priority?.style }}
              conceptStyle={{ ...order.priority?.style }}
              valueStyle={{ ...order.priority?.style }}
              concept="Prioridad de atención"
              value={order.priority?.label}
            />
            {/* PRIORITY REASON */}
            <LineDescription
              concept="Razón de prioridad"
              value={order.priority?.reason}
            />
            {/* WARRANTY ORDER ID ASSOCIATED */}
            {order.warranty_order_id && (
              <LineDescription
                concept="Orden Afectada"
                value={order.warranty_order_id}
              />
            )}
            {/* WARRANTY RESPONSABLE EXPERT */}
            {order.warranty_expert_name && (
              <LineDescription
                concept="Responsable"
                value={order.warranty_expert_name}
              />
            )}
            {/* WARRANTY RESPONSABLE ENTITY */}
            {order.warranty_entity_name && (
              <LineDescription
                concept="Entidad"
                value={order.warranty_entity_name}
              />
            )}
          </SectionDescription>
          {/* TIMERS */}
          <SectionDescription>
            <Timer
              startAt={order.start_at}
              timeElapsed={order.time_elapsed}
              timeRemaining={order.time_remaining}
              freezeTimer={
                order.event_props?.[ENV.MANAGE_PROPS.FLAGS.FREEZE_TIMER]
              }
              render={({ timeElapsed, timeRemaining }) => (
                <>
                  {/* START AT */}
                  <LineDescription
                    concept="Temporizador activo"
                    value={
                      <StartAt startAt={order.start_at} profile={profile} />
                    }
                  />
                  {/* ELAPSED */}
                  <LineDescription
                    concept="Tiempo transcurrido"
                    value={<TimeElapsed time={timeElapsed} />}
                  />
                  {/* REMAINING */}
                  <LineDescription
                    concept="Expira en"
                    value={
                      <TimeRemaining time={timeRemaining} humanize={true} />
                    }
                  />
                </>
              )}
            />
          </SectionDescription>
          <SectionDescription>
            {/* RESERVATION AT */}
            <LineDescription
              concept="Fecha Reserva"
              value={order.formatReservationAt}
            />
            {/* PROGRAM AT */}
            <LineDescription
              concept="Fecha Programación"
              value={order.formatProgramAt}
            />
            {/* EXECUTION AT */}
            <LineDescription
              concept="Fecha Ejecución"
              value={order.formatExecutionAt}
            />
            {/* DILATION */}
            <LineDescription
              concept="Dilación"
              value={`${order.dilation} días`}
            />
            {/* RANGE */}
            <LineDescription
              concept="Rango"
              value={`${order.external_range} días`}
            />
          </SectionDescription>
        </>
      }
    />
  );
}

function MoreInfoSection({ order, template }) {
  const divisionIsNotEmpty = (order, fields) => {
    const foundKey = fields.find((field) => order.dynamicData[field.id]);
    return !!foundKey;
  };

  const divisors = useMemo(
    () => getDivisorsFromTemplateDataStructure(template),
    [template?.id]
  );

  if (!template) return null;

  return (
    <Card
      style={{ width: "100%", marginBottom: "3px" }}
      title="Más Información"
      description={
        // DESCRIPTION CONTAINER
        <SectionDescription>
          {divisors.map(
            (divisor, idx) =>
              divisionIsNotEmpty(order, divisor.fields) && (
                <div key={idx}>
                  <LineDescription concept={divisor.name} />
                  {divisor.fields.map(
                    (field, idx) =>
                      order.dynamicData[field.id] && (
                        <div key={idx}>
                          <LineDescription
                            concept={field.name}
                            value={order.dynamicData[field.id]}
                          />
                        </div>
                      )
                  )}
                </div>
              )
          )}
        </SectionDescription>
      }
    />
  );
}

export default function DetailsTab({ order, templates, profile }) {
  const theme = useTheme();

  const template = templates.find(
    (template) => template.id === order.templateId
  );

  return (
    order && (
      <Wrapper
        padding="0"
        flexDirection="column"
        overflow="auto"
        height={`${document.documentElement.clientHeight - 106}px`}
        // height={`${Math.round(rect?.height) - 60}px`}
      >
        {/* ORDER */}
        <OrderSection order={order} profile={profile} theme={theme} />
        {/* STATE */}
        <StateSection order={order} theme={theme} />
        {/* PRIORITY AND INDICATORS */}
        <PriorityAndIndicatorsSection
          order={order}
          profile={profile}
          theme={theme}
        />
        {/* CLIENT */}
        <ClientSection order={order} theme={theme} />
        {/* CONTRACT */}
        <ContractSection order={order} theme={theme} />
        {/* MORE INFORMATION */}
        {template && order.dynamicData && (
          <MoreInfoSection order={order} template={template} />
        )}
      </Wrapper>
    )
  );
}
