//Libs
import React from "react";
//Components
import { VirtualizedList } from "components/containers/Utils";

const Articles = ({ stockArticles, stockArticlesManage }) => {
  const { articles } = stockArticlesManage;

  return (
    <VirtualizedList
      cardId="stockArticleItemCard"
      listStyle={{
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight - 104,
      }}
      cardStyle={{
        width: 300,
        height: 140,
      }}
      rowHeight={150}
      rows={stockArticles}
      isSpinning={articles.loading}
    />
  );
};

export default Articles;
