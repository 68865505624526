//Utils
import GENERAL from "utils/constants/general";
import MODULE_KEYWORDS from "components/components/Modules/keywords";
import { lsHandler } from "utils/libs";
import { notification } from "antd";

const { DASHBOARD, ENV, NOTIFICATIONS } = GENERAL;

//  InitialState
const initialState = {
  //Dashboard
  isOpen: false,
  linked: lsHandler.getLinked(),
  navigatorInstance: undefined,
  modeView: ENV.DEVICE_TYPE === "Mobile" ? "cards" : "table", // table, cards
  //Notifications
  notificationControl: {
    show: false,
    isFetching: false,
    notifications: [],
  },
  //Toast
  toastMessage: {
    key: NOTIFICATIONS.TYPES.GLOBAL,
    message: "Synapse",
    description: "",
    duration: 5,
    type: "info",
    className: "notification-dark-style",
  },
  //Show errors
  showErrors: {
    isOpen: false,
    placement: "right",
    title: "Hay conflictos",
    errors: [],
  },
  //New unified structure
  regions: {
    isFetching: false,
    data: [],
  },
  areas: {
    isFetching: false,
    data: [],
  },
  centrals: {
    isFetching: false,
    data: [],
  },
  dealers: {
    isFetching: false,
    data: [],
  },
  dataFilters: {
    isFetching: false,
    selectedDataFilterId: undefined,
    selectedDataFilterKey:
      MODULE_KEYWORDS.MODULES.ITEMS.KEYS.ORDERS.FILTERS.ORDERS,
    data: [],
  },
  orderTargetFilters: {
    isFetching: false,
    selectedOrderTargetFilterId: undefined,
    data: [],
  },
  projects: {
    isFetching: false,
    selectedProjectId: undefined,
    data: [],
  },
  services: {
    isFetching: false,
    selectedServiceId: undefined,
    data: [],
  },
  //Modals
  filtersQueryModal: {
    isOpen: false,
    isLoading: false,
  },
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD.SHOW_NAV_BAR: {
      return {
        ...state,
        isOpen: true,
      };
    }
    case DASHBOARD.MUTATE_DIRECT_PROPS: {
      const keyValuePairs = action.payload;
      return {
        ...state,
        ...keyValuePairs,
      };
    }
    case DASHBOARD.MUTATE_1OBJECT: {
      const { obj1Name, keyValuePairs } = action.payload,
        newObj = { ...state[obj1Name], ...keyValuePairs };
      return {
        ...state,
        [obj1Name]: newObj,
      };
    }
    case DASHBOARD.MARK_NOTIFICATION_SEEN_SUCCESS: {
      const user_notification_id = action.payload,
        notifications = state.notifications.filter(
          (notification) =>
            notification.user_notification_id !== user_notification_id
        );

      return {
        ...state,
        notifications,
      };
    }
    case DASHBOARD.MARK_NOTIFICATION_SUCCESS: {
      const { userNotificationId, seen, read } = action.payload;

      const notifications = [...state.notificationControl.notifications];
      const notificationIdx = notifications.findIndex(
        (n) => n.userNotificationId === userNotificationId
      );
      if (notificationIdx !== -1) {
        notifications[notificationIdx].seen = !!seen;
        notifications[notificationIdx].read = !!read;
      }

      return {
        ...state,
        notificationControl: {
          ...state.notificationControl,
          notifications,
        },
      };
    }
    case DASHBOARD.SET_ON_RECEIVED_NOTIFICATION: {
      const notification = action.payload;

      const notifications = [...state.notificationControl.notifications];
      const idx = notifications.findIndex(
        (n) => n.userNotificationId === notification.userNotificationId
      );
      if (idx === -1) notifications.unshift(notification);
      return {
        ...state,
        notificationControl: {
          ...state.notificationControl,
          notifications,
        },
      };
    }
    case DASHBOARD.TOAST_MESSAGE: {
      const { message, description, duration, type, onClick } = action.payload,
        toastMessage = {
          ...state.toastMessage,
          message: message ? message : state.toastMessage.message,
          description: description
            ? description
            : state.toastMessage.description,
          duration: duration ? duration : state.toastMessage.duration,
          type: type ? type : state.toastMessage.type,
          onClick,
        };

      switch (type) {
        case "error":
          notification.error({ ...toastMessage });
          break;
        case "warn":
          notification.warn({ ...toastMessage });
          break;
        case "warning":
          notification.warning({ ...toastMessage });
          break;
        case "success":
          notification.success({ ...toastMessage });
          break;
        default:
          notification.info({ ...toastMessage });
          break;
      }

      return {
        ...state,
      };
    }
    case DASHBOARD.SET_LINK_SUCCESS: {
      const linked = {
        ...state.linked,
        ...action.payload,
      };
      return {
        ...state,
        linked,
      };
    }
    case DASHBOARD.SHOW_MULTIPLE_ERRORS: {
      const errors = action.payload;

      return {
        ...state,
        showErrors: {
          ...state.showErrors,
          isOpen: true,
          errors,
        },
      };
    }
    case DASHBOARD.RESET: {
      return initialState;
    }
    //New unified structure
    case DASHBOARD.MUTATE_1OBJECT_IN_REGIONS: {
      const { obj1Name, keyValuePairs } = action.payload;
      const newObj = { ...state[obj1Name], ...keyValuePairs };

      return {
        ...state,
        regions: {
          ...state.regions,
          [obj1Name]: newObj,
        },
      };
    }
    case DASHBOARD.MUTATE_1OBJECT_IN_AREAS: {
      const { obj1Name, keyValuePairs } = action.payload;
      const newObj = { ...state[obj1Name], ...keyValuePairs };

      return {
        ...state,
        areas: {
          ...state.areas,
          [obj1Name]: newObj,
        },
      };
    }
    case DASHBOARD.MUTATE_1OBJECT_IN_CENTRALS: {
      const { obj1Name, keyValuePairs } = action.payload;
      const newObj = { ...state[obj1Name], ...keyValuePairs };

      return {
        ...state,
        centrals: {
          ...state.centrals,
          [obj1Name]: newObj,
        },
      };
    }
    case DASHBOARD.MUTATE_1OBJECT_IN_DEALERS: {
      const { obj1Name, keyValuePairs } = action.payload;
      const newObj = { ...state[obj1Name], ...keyValuePairs };

      return {
        ...state,
        dealers: {
          ...state.dealers,
          [obj1Name]: newObj,
        },
      };
    }
    case DASHBOARD.MUTATE_1OBJECT_IN_ORDERS: {
      const { obj1Name, keyValuePairs } = action.payload;
      const newObj = { ...state[obj1Name], ...keyValuePairs };

      return {
        ...state,
        orders: {
          ...state.orders,
          [obj1Name]: newObj,
        },
      };
    }
    case DASHBOARD.MUTATE_1OBJECT_IN_ORDER_FILTERS: {
      const { obj1Name, keyValuePairs } = action.payload;
      const newObj = { ...state[obj1Name], ...keyValuePairs };

      return {
        ...state,
        dataFilters: {
          ...state.dataFilters,
          [obj1Name]: newObj,
        },
      };
    }
    case DASHBOARD.MUTATE_1OBJECT_IN_PROJECTS: {
      const { obj1Name, keyValuePairs } = action.payload;
      const newObj = { ...state[obj1Name], ...keyValuePairs };

      return {
        ...state,
        projects: {
          ...state.projects,
          [obj1Name]: newObj,
        },
      };
    }
    case DASHBOARD.MUTATE_1OBJECT_IN_SERVICES: {
      const { obj1Name, keyValuePairs } = action.payload;
      const newObj = { ...state[obj1Name], ...keyValuePairs };

      return {
        ...state,
        services: {
          ...state.services,
          [obj1Name]: newObj,
        },
      };
    }
    case DASHBOARD.RESET_FILTERS_QUERY_MODAL: {
      return {
        ...state,
        filtersQueryModal: initialState.filtersQueryModal,
      };
    }
    default:
      return state;
  }
};

export default dashboardReducer;
