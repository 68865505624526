//Libs
import moment from "moment";
import _ from "underscore";
//Utils
import { filterReconditionedSeries } from "../Warehouse/utils";
import GENERAL from "../../../utils/constants/general";
import { GlobalUtils } from "utils";
const { ENV } = GENERAL,
  today = moment().format("YYYY-MM-DD"),
  dateNow = moment(today);

export function onKeyPressedSearchOrder(
  e,
  searchInData,
  history,
  mutate1ObjectInOrders,
  mutateDirectPropsInDashboard
) {
  const { data, searchBy } = searchInData;

  if (e.key === "Enter" && searchBy === "deepSearch" && data && data !== "") {
    mutate1ObjectInOrders("control", {
      searchInData: { ...searchInData, pressEnter: true },
    });
    mutateDirectPropsInDashboard({ isOpen: false });
    history.push(ENV.ROUTES.PATHS.ORDERS_MANAGE);
  }
}

export function onDeepSearchOrder(
  searchInData,
  makeDeepSearchOrder,
  mutate1Object
) {
  const { data, pressEnter } = searchInData;

  if (pressEnter && data && data !== "") {
    mutate1Object("control", {
      searchInData: { ...searchInData, pressEnter: false },
    });
    makeDeepSearchOrder(data.trim());
  }
}

export function handleOnClickCheckSortBtn(orders, sorting) {
  let updateOrders = [];

  for (let expertId in sorting.selectedUsers) {
    updateOrders = updateOrders.concat(orders[expertId]);
  }

  return updateOrders;
}

export function filterRoutedOrUnroutedOrders(orders, get) {
  if (get === "routed") return orders.filter((order) => order.att_position > 0);
  else if (get === "unrouted")
    return orders.filter((order) => order.att_position === 0);
}

export function filterCategory(orders, category, dateFormat) {
  if (category === "totalTracking")
    return orders.filter(
      (order) => order.event_props && order.event_props.totalTracking
    );
  else if (category === "dailyTracking") {
    return orders.filter((order) => {
      let momReprogramAt = moment(order.reprogram_at);
      let momLastEventAt = moment(order.last_event_at);

      if (order.priority && order.priority.highPriority) return order;
      if (order.event_props) {
        if (
          order.event_props.affectation &&
          !order.event_props.reprogram &&
          momLastEventAt.isValid()
        ) {
          let dateLastEventAt = moment(momLastEventAt.format("YYYY-MM-DD"));
          if (!dateLastEventAt.isSame(dateNow, "day")) return order;
        } else if (order.event_props.reprogram || momReprogramAt.isValid()) {
          let dateReprogramAt = moment(momReprogramAt.format("YYYY-MM-DD"));
          if (
            dateReprogramAt.isBefore(dateNow, "day") ||
            dateReprogramAt.isSame(dateNow, "day")
          )
            return order;
        } else if (order.event_props.dailyTracking) return order;
      }
    });
  }
  return orders;
}

export function filterOrdersToRouting(orders) {
  return orders.filter((order) => {
    if (order.state_props && order.state_props.pending && order.event_props) {
      let momReprogramAt = moment(order.reprogram_at);
      if (order.event_props.reprogram || momReprogramAt.isValid()) {
        let dateReprogramAt = moment(momReprogramAt.format("YYYY-MM-DD"));
        if (
          dateReprogramAt.isBefore(dateNow, "day") ||
          dateReprogramAt.isSame(dateNow, "day")
        )
          return order;
        if (
          dateReprogramAt.isAfter(dateNow, "day") &&
          dateReprogramAt.diff(dateNow, "days") === 1
        )
          return order;
      } else if (order.event_props.routing) return order;
    }
  });
}

export function filterOrdersSearchInData(orders, data) {
  if (data === "") return [...orders];
  return orders.filter(
    (order) =>
      order.odt_id.includes(data) ||
      order.contract_number.includes(data) ||
      order.client_idc.toUpperCase().includes(data.toUpperCase()) ||
      order.client_suscription_number
        .toUpperCase()
        .includes(data.toUpperCase()) ||
      order.client_name.toUpperCase().includes(data.toUpperCase()) ||
      order.city?.toUpperCase().includes(data.toUpperCase()) ||
      order.township?.toUpperCase().includes(data.toUpperCase()) ||
      order.zone?.toUpperCase().includes(data.toUpperCase()) ||
      order.service_name.toUpperCase().includes(data.toUpperCase()) ||
      order.expert_name.toUpperCase().includes(data.toUpperCase()) ||
      order.event_name.toUpperCase().includes(data.toUpperCase()) ||
      order.state_name.toUpperCase().includes(data.toUpperCase()) ||
      order.external_range.toUpperCase().includes(data.toUpperCase())
  );
}

export function filterToInvoicedItemSearchInData(items, searchBy, data) {
  if (!data || data === "") return items;
  switch (searchBy) {
    case "all":
      return items.filter(
        (item) =>
          item.item_code.toUpperCase().includes(data.toUpperCase()) ||
          item.item_name.toUpperCase().includes(data.toUpperCase()) ||
          (item.serie && item.serie.toUpperCase().includes(data.toUpperCase()))
      );
  }
}

function validateRequireInventory(order, series) {
  const { props, service_props } = order,
    newInventory = filterReconditionedSeries(series, "new"),
    reconditionedInventory = filterReconditionedSeries(series, "reconditioned");

  if (
    !props ||
    !props.requireInventory ||
    props.requireInventory === "default"
  ) {
    //Revisamos la configuración del servicio
    if (service_props && service_props.requireInventory === "new") {
      return newInventory.concat(reconditionedInventory);
    } else if (
      service_props &&
      service_props.requireInventory === "reconditioned"
    ) {
      return reconditionedInventory.concat(newInventory);
    } else return series;
  } else if (props && props.requireInventory === "new") {
    return newInventory.concat(reconditionedInventory);
  } else if (props && props.requireInventory === "reconditioned") {
    return reconditionedInventory.concat(newInventory);
  } else if (props && props.requireInventory === "any") {
    return series;
  }
  return series;
}

export function validatePeps(inventory, order) {
  if (order.props && order.props.requireInventory === "any") return inventory;

  const articles = inventory.filter((article) => !article.is_serialized),
    series = inventory.filter((serie) => serie.is_serialized),
    counter = {};

  let filteredSeries = validateRequireInventory(order, series);

  filteredSeries = filteredSeries.filter((record) => {
    if (!record.is_serialized) return record;
    else if (record.fifo_amount !== null) {
      if (!counter[record.family_id]) counter[record.family_id] = 1;
      else counter[record.family_id]++;

      if (counter[record.family_id] <= record.fifo_amount) return record;
    }
  });

  return articles.concat(filteredSeries);
}

export function validateMatchUninstalledInventory(
  articles,
  serialized,
  uninstalled,
  order,
  toInvoice,
  toUninstall,
  sendToast
) {
  const { props, service_props } = order;

  function validSerialized() {
    let valid = true;

    const counterFamiliesToUninstall = serialized.reduce((acc, serie) => {
      let item = toInvoice.filter((itm) => itm.item_id === serie.item_id)[0];
      if (!item)
        sendToast({
          description: `Oops! No se encontró el item [${serie.item_id}] en el inventario disponible`,
          type: "error",
        });

      if (item.uninstalled_family_id) {
        if (!acc[item.uninstalled_family_id])
          acc[item.uninstalled_family_id] = 1;
        else acc[item.uninstalled_family_id]++;
      }
      return acc;
    }, {});

    const counterFamiliesUninstalled = uninstalled.reduce((acc, serie) => {
      let item = toUninstall.filter((itm) => itm.item_id === serie.item_id)[0];
      if (!item)
        sendToast({
          description: `Oops! No se encontró el item [${serie.item_id}] en el inventario desinstalable`,
          type: "error",
        });
      if (!item.family_id)
        sendToast({
          description: `El item [${serie.item_id}] debe tener una familia asociada`,
          type: "error",
        });

      if (!acc[item.family_id]) acc[item.family_id] = 1;
      else acc[item.family_id]++;
      return acc;
    }, {});

    for (let familyId in counterFamiliesToUninstall) {
      if (
        counterFamiliesToUninstall[familyId] !==
        counterFamiliesUninstalled[familyId]
      ) {
        sendToast({
          message:
            "La cantidad del inventario instalado y desinstalado debe ser la misma",
          type: "warn",
        });
        valid = false;
        return;
      }
    }

    for (let familyId in counterFamiliesUninstalled) {
      if (
        counterFamiliesUninstalled[familyId] !==
        counterFamiliesToUninstall[familyId]
      ) {
        sendToast({
          message:
            "La cantidad del inventario instalado y desinstalado debe ser la misma",
          type: "warn",
        });
        valid = false;
        return;
      }
    }

    return valid;
  }

  if (!service_props.requireMatchUninstalledInventory) return true;

  return validSerialized();
}

export function getAvailablePositions(orders, maxOrdersRouted) {
  const availablePositions = [],
    routedCount = orders.filter((order) => order.att_position > 0).length;

  let value = routedCount + 1;
  if (routedCount < maxOrdersRouted)
    availablePositions.push({
      value,
      label: value < 10 ? `0${value}` : value,
    });

  return availablePositions;
}

export function canCreateNewComment(getOrderInformationModal) {
  const { activeTab, billingReport, photoReport, viewComments } =
    getOrderInformationModal;

  if (activeTab === "3") {
    return (
      viewComments &&
      (!billingReport.comments ||
        _.findIndex(billingReport.comments, (comment) => comment.newComment) ===
          -1)
    );
  } else if (activeTab === "4") {
    return (
      viewComments &&
      (!photoReport.comments ||
        _.findIndex(photoReport.comments, (comment) => comment.newComment) ===
          -1)
    );
  }
}

export function getCommentsCount(getOrderInformationModal) {
  const { activeTab, billingReport, photoReport } = getOrderInformationModal;

  if (activeTab === "3") {
    if (billingReport && billingReport.comments)
      return billingReport.comments.length;
  } else if (activeTab === "4") {
    if (photoReport && photoReport.comments) return photoReport.comments.length;
  }
  return 0;
}

export function onAddComment(getOrderInformationModal, mutate1Object) {
  const { activeTab, billingReport, photoReport } = getOrderInformationModal;

  if (activeTab === "3") {
    const comments = billingReport.comments ? [...billingReport.comments] : [];

    if (_.findIndex(comments, (comment) => comment.newComment) === -1) {
      comments.push({ newComment: true });
      mutate1Object("getOrderInformationModal", {
        billingReport: { ...billingReport, comments },
      });
    }
  } else if (activeTab === "4") {
    const comments = photoReport.comments ? [...photoReport.comments] : [];

    if (_.findIndex(comments, (comment) => comment.newComment) === -1) {
      comments.push({ newComment: true });
      mutate1Object("getOrderInformationModal", {
        photoReport: { ...photoReport, comments },
      });
    }
  }
}

export function onViewComments(getOrderInformationModal, mutate1Object) {
  const { activeTab, billingReport, photoReport, viewComments } =
    getOrderInformationModal;

  if (activeTab === "3") {
    if (!viewComments)
      mutate1Object("getOrderInformationModal", {
        viewComments: !getOrderInformationModal.viewComments,
      });
    else {
      mutate1Object("getOrderInformationModal", {
        viewComments: !getOrderInformationModal.viewComments,
        billingReport: {
          ...billingReport,
          comments: billingReport.comments
            ? billingReport.comments.filter((comment) => !comment.newComment)
            : [],
        },
      });
    }
  } else if (activeTab === "4") {
    if (!viewComments)
      mutate1Object("getOrderInformationModal", {
        viewComments: !getOrderInformationModal.viewComments,
      });
    else {
      mutate1Object("getOrderInformationModal", {
        viewComments: !getOrderInformationModal.viewComments,
        photoReport: {
          ...photoReport,
          comments: photoReport.comments
            ? photoReport.comments.filter((comment) => !comment.newComment)
            : [],
        },
      });
    }
  }
}

export function getDivisorsFromTemplateDataStructure(template) {
  if (Array.isArray(template?.data_structure_object?.divisors)) {
    return template.data_structure_object.divisors.reduce(
      (_divisors, divisor) => {
        _divisors.push({
          name: divisor.text,
          fields: GlobalUtils.checkArray(divisor.keys)
            .concat(GlobalUtils.checkArray(divisor.columns))
            .reduce((_fields, fieldId) => {
              _fields.push({
                id: fieldId,
                name: fieldId,
              });
              return _fields;
            }, []),
        });
        return _divisors;
      },
      []
    );
  }
  if (Array.isArray(template?.data_structure_object?.fields)) {
    const divisors = {};
    return template.data_structure_object.fields
      .reduce((_divisors, field) => {
        const name = field.groupName || `Agrupación general`;
        if (divisors[name]) return _divisors;
        divisors[name] = true;
        _divisors.push({
          name,
          fields: [],
        });
        return _divisors;
      }, [])
      .map((divisor) => {
        divisor.fields = template.data_structure_object.fields.reduce(
          (_fields, field) => {
            if (!field.groupName || field.groupName === divisor.name)
              _fields.push({
                id: field.id,
                name: field.name,
              });
            return _fields;
          },
          []
        );
        return divisor;
      });
  }
  return [];
}

export function getOrderActions(configOrder) {
  if (!configOrder?.actions?.orders) {
    return {
      actions: {},
      count: 0,
    };
  }
  const actions = { ...configOrder.actions.orders };
  const count = Object.keys(actions).length;
  return {
    actions,
    count,
  };
}
