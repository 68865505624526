//Libs
import { createSelector } from "reselect";
import Immutable from "immutable";

const selectDashboardState = (state) => state.dashboard;

export const selectNotificationControl = createSelector(
  [selectDashboardState],
  (dashboard) => dashboard.notificationControl
);

export const selectModeView = createSelector(
  [selectDashboardState],
  ({ modeView }) => modeView
);
export const selectDashboardMenuIsOpen = createSelector(
  [selectDashboardState],
  ({ isOpen }) => isOpen
);
export const selectLinked = createSelector(
  [selectDashboardState],
  ({ linked }) => linked
);
export const selectNavigatorInstance = createSelector(
  [selectDashboardState],
  ({ navigatorInstance }) => navigatorInstance
);
//New unified structure
export const selectRegionsIsFetching = createSelector(
  [selectDashboardState],
  ({ regions }) => regions.isFetching
);
export const selectAreasIsFetching = createSelector(
  [selectDashboardState],
  ({ areas }) => areas.isFetching
);
export const selectCentralsIsFetching = createSelector(
  [selectDashboardState],
  ({ centrals }) => centrals.isFetching
);
export const selectDealersIsFetching = createSelector(
  [selectDashboardState],
  ({ dealers }) => dealers.isFetching
);
export const selectOrdersIsFetching = createSelector(
  [selectDashboardState],
  ({ orders }) => orders.isFetching
);
export const selectDataFiltersIsFetching = createSelector(
  [selectDashboardState],
  ({ dataFilters }) => dataFilters.isFetching
);
export const selectOrderTargetFiltersIsFetching = createSelector(
  [selectDashboardState],
  ({ orderTargetFilters }) => orderTargetFilters.isFetching
);
export const selectProjectsIsFetching = createSelector(
  [selectDashboardState],
  ({ projects }) => projects.isFetching
);
export const selectServicesIsFetching = createSelector(
  [selectDashboardState],
  ({ services }) => services.isFetching
);
export const selectRegions = createSelector(
  [selectDashboardState],
  ({ regions }) => Immutable.List(regions.data).toJS()
);
export const selectAreas = createSelector([selectDashboardState], ({ areas }) =>
  Immutable.List(areas.data).toJS()
);
export const selectCentrals = createSelector(
  [selectDashboardState],
  ({ centrals }) => Immutable.List(centrals.data).toJS()
);
export const selectDealers = createSelector(
  [selectDashboardState],
  ({ dealers }) => Immutable.List(dealers.data).toJS()
);
export const selectOrders = createSelector(
  [selectDashboardState],
  ({ orders }) => Immutable.List(orders.data).toJS()
);
export const selectDataFilters = createSelector(
  [selectDashboardState],
  ({ dataFilters }) => Immutable.List(dataFilters.data).toJS()
);
export const selectOrderTargetFilters = createSelector(
  [selectDashboardState],
  ({ orderTargetFilters }) => Immutable.List(orderTargetFilters.data).toJS()
);
export const selectProjects = createSelector(
  [selectDashboardState],
  ({ projects }) => Immutable.List(projects.data).toJS()
);
export const selectServices = createSelector(
  [selectDashboardState],
  ({ services }) => Immutable.List(services.data).toJS()
);
export const selectSelectedProjectId = createSelector(
  [selectDashboardState],
  ({ projects }) => projects.selectedProjectId
);
export const selectSelectedServiceId = createSelector(
  [selectDashboardState],
  ({ services }) => services.selectedServiceId
);
export const selectSelectedDataFilterId = createSelector(
  [selectDashboardState],
  ({ dataFilters }) => dataFilters.selectedDataFilterId
);
export const selectSelectedOrderTargetFilterId = createSelector(
  [selectDashboardState],
  ({ orderTargetFilters }) => orderTargetFilters.selectedOrderTargetFilterId
);
export const selectSelectedDataFilterKey = createSelector(
  [selectDashboardState],
  ({ dataFilters }) => dataFilters.selectedDataFilterKey
);

export const selectFiltersQueryModal = createSelector(
  [selectDashboardState],
  ({ filtersQueryModal }) => filtersQueryModal
);
