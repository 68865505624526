//Libs
import { Checkbox } from "antd";
import styled, { css } from "styled-components";

const CheckboxGroup = Checkbox.Group;

const CheckboxGroupStyled = styled(CheckboxGroup)`
  .ant-checkbox + span {
    font-size: ${(props) =>
      props.fontSize
        ? props.theme.fonts.size[props.fontSize]
        : props.theme.fonts.size.default};
  }
  display: ${(props) =>
    props.display ? props.display : props.theme.checkboxGroup.display};
  flex-direction: ${(props) =>
    props.flex_direction
      ? props.flex_direction
      : props.theme.checkboxGroup.flexDirection};
  justify-content: ${(props) =>
    props.justifyContent
      ? props.justifyContent
      : props.theme.checkboxGroup.justifyContent};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : props.theme.checkboxGroup.alignItems};
`;

export default CheckboxGroupStyled;
