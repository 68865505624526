//  Components
import GENERAL from "../constants/general";
import App from "components/App";
import {
  Login,
  Signup,
  Dashboard,
  Orders,
  Payments,
  Warehouse,
  RecoveryPassword,
} from "components/containers";
import { OrdersManage, CompleteOrder } from "components/containers/Orders/subs";
import { PaymentsManage } from "components/containers/Payments/subs";
import {
  Stock,
  Transactions,
  TransactionsManage,
  TransactionsInventory,
  TransactionsItems,
} from "components/containers/Warehouse/subs";

const { ENV } = GENERAL;
const { ROUTES } = ENV;
const routes = [
  {
    path: ROUTES.PATHS.ROOT,
    component: App,
    routes: [
      //  LOGIN
      {
        path: ROUTES.PATHS.LOGIN,
        component: Login,
      },
      //  SIGNUP
      {
        path: ROUTES.PATHS.SIGNUP,
        component: Signup,
      },
      //RECOVERY_PASSWORD
      {
        path: ROUTES.PATHS.RECOVERY_PASSWORD,
        component: RecoveryPassword,
      },
      //  DASHBOARD
      {
        title: ROUTES.TITLES.DASHBOARD,
        path: ROUTES.PATHS.DASHBOARD,
        component: Dashboard,
        routes: [
          //  ORDERS
          {
            id: ROUTES.IDS.ORDERS,
            item: ROUTES.ITEMS.ORDERS,
            path: ROUTES.PATHS.ORDERS,
            component: Orders,
            routes: [
              {
                id: ROUTES.IDS.ORDERS_MANAGE,
                title: ROUTES.TITLES.ORDERS_MANAGE,
                path: ROUTES.PATHS.ORDERS_MANAGE,
                component: OrdersManage,
              },
              {
                id: ROUTES.IDS.ORDERS_COMPLETE,
                title: ROUTES.TITLES.ORDERS_COMPLETE,
                path: ROUTES.PATHS.ORDERS_COMPLETE,
                component: CompleteOrder,
              },
            ],
          },
          //  PAYMENTS
          {
            id: ROUTES.IDS.PAYMENTS,
            item: ROUTES.ITEMS.PAYMENTS,
            path: ROUTES.PATHS.PAYMENTS,
            component: Payments,
            routes: [
              {
                id: ROUTES.IDS.PAYMENTS_MANAGE,
                title: ROUTES.TITLES.PAYMENTS_MANAGE,
                path: ROUTES.PATHS.PAYMENTS_MANAGE,
                component: PaymentsManage,
              },
            ],
          },
          //  WAREHOUSE
          {
            id: ROUTES.IDS.WAREHOUSE,
            item: ROUTES.ITEMS.WAREHOUSE,
            path: ROUTES.PATHS.WAREHOUSE,
            sub: true,
            component: Warehouse,
            routes: [
              //  STOCK
              {
                id: ROUTES.IDS.WAREHOUSE_STOCK,
                item: ROUTES.ITEMS.WAREHOUSE_STOCK,
                title: ROUTES.TITLES.WAREHOUSE_STOCK,
                path: ROUTES.PATHS.WAREHOUSE_STOCK,
                component: Stock,
              },
              //  TRANSACTIONS
              {
                id: ROUTES.IDS.WAREHOUSE_TRANSACTIONS,
                item: ROUTES.ITEMS.WAREHOUSE_TRANSACTIONS,
                path: ROUTES.PATHS.WAREHOUSE_TRANSACTIONS,
                component: Transactions,
                routes: [
                  //  MANAGE
                  {
                    id: ROUTES.IDS.WAREHOUSE_TRANSACTIONS_MANAGE,
                    title: ROUTES.TITLES.WAREHOUSE_TRANSACTIONS_MANAGE,
                    path: ROUTES.PATHS.WAREHOUSE_TRANSACTIONS_MANAGE,
                    component: TransactionsManage,
                  },
                  //  INVENTORY
                  {
                    id: ROUTES.IDS.WAREHOUSE_TRANSACTIONS_INVENTORY,
                    title: ROUTES.TITLES.WAREHOUSE_TRANSACTIONS_INVENTORY,
                    path: ROUTES.PATHS.WAREHOUSE_TRANSACTIONS_INVENTORY,
                    component: TransactionsInventory,
                  },
                  //  ITEMS
                  {
                    id: ROUTES.IDS.WAREHOUSE_TRANSACTIONS_ITEMS,
                    title: ROUTES.TITLES.WAREHOUSE_TRANSACTIONS_ITEMS,
                    path: ROUTES.PATHS.WAREHOUSE_TRANSACTIONS_ITEMS,
                    component: TransactionsItems,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
