//Libs
import React from "react";
import { useTheme } from "styled-components";
import { List } from "react-virtualized";
import { Card } from "antd";
import { Wrapper, Spin } from "components/components";
//Utils
import { isRecordSigned } from "../../../../utils/libs";
//Components
import {
  //Dashboard
  ShowErrorCard,
  //Orders
  OrderCard,
  OrderHistoryCard,
  BillingMaterialCard,
  ToInvoicedItemCard,
  ConsumeInventoryItemCard,
  UninstallInventoryFamilyCard,
  CommentsCard,
  //Payments
  PayCard,
  PaymentOrderResumeCard,
  PaymentLineResumeCard,
  //Warehouse
  StockArticleItemCard,
  StockSerieItemCard,
  StockFamilyItemCard,
  TransactionCard,
  InventoryTransactionCard,
  SerieHistoryCard,
} from "./cards";
//Styles
import "./VirtualizedList.css";

function getCustomCard(cardId, props) {
  switch (cardId) {
    //Dashboard
    case "showErrorCard":
      return <ShowErrorCard {...props} />;
    //Orders
    case "order":
      return <OrderCard {...props} />;
    case "orderHistory":
      return <OrderHistoryCard {...props} />;
    case "billingMaterial":
      return <BillingMaterialCard {...props} />;
    case "toInvoiceItem":
      return <ToInvoicedItemCard {...props} />;
    case "consumeInventoryItem":
      return <ConsumeInventoryItemCard {...props} />;
    case "uninstallInventoryFamilyCard":
      return <UninstallInventoryFamilyCard {...props} />;
    case "comments":
      return <CommentsCard {...props} />;
    //Payments
    case "payCard":
      return <PayCard {...props} />;
    case "paymentOrderResumeCard":
      return <PaymentOrderResumeCard {...props} />;
    case "paymentLineResumeCard":
      return <PaymentLineResumeCard {...props} />;
    //Warehouse
    case "stockArticleItemCard":
      return <StockArticleItemCard {...props} />;
    case "stockSerieItemCard":
      return <StockSerieItemCard {...props} />;
    case "stockFamilyItemCard":
      return <StockFamilyItemCard {...props} />;
    case "transactionCard":
      return <TransactionCard {...props} />;
    case "inventoryTransactionCard":
      return <InventoryTransactionCard {...props} />;
    case "serieHistoryCard":
      return <SerieHistoryCard {...props} />;
    default:
      return null;
  }
}

function showRibbon(cardId, record, customProps = {}) {
  const { profile } = customProps;

  switch (cardId) {
    //Orders
    case "order":
      return record.props && !record.props.seen;
    //Payments
    case "payCard":
      return !isRecordSigned(record.signed, profile.user.id);
    default:
      return false;
  }
}

function getRibbon(cardId, record, customProps = {}) {
  switch (cardId) {
    //Orders
    case "order":
      return (
        <div
          className="wrap"
          onClick={() => customProps.markOrder(record.order_id, "seen")}
        >
          <span className="ribbon6">Nueva</span>
        </div>
      );
    //Payments
    case "payCard":
      return <></>;
    default:
      return false;
  }
}

function VirtualizedList(props) {
  const theme = useTheme();
  const {
    cardId,
    interpolatedRowStyle,
    listStyle,
    cardStyle,
    rowHeight,
    Actions,
    Cover,
    rows,
    isSpinning = false,
    spinTitle = "Cargando...",
    spinSize = "large",
    spinDelay = 50,
    onRowsRendered,
    customProps,
  } = props;

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    const record = rows[index];
    const ribbon = showRibbon(cardId, record, customProps);
    record.counter = index + 1;
    return (
      <Wrapper
        key={key}
        padding="0"
        justifyContent="center"
        style={{ ...style, width: "100%", height: "auto" }}
      >
        <div
          id={interpolatedRowStyle ? "interpolated-style" : undefined}
          className={ribbon ? "ribbon" : undefined}
          style={{
            width: cardStyle?.width ?? "100%",
            height: cardStyle?.height ?? "auto",
            boxShadow: cardStyle?.boxShadow ?? theme.card.boxShadow,
          }}
        >
          {ribbon && getRibbon(cardId, record, customProps)}
          <Card
            id="card-container"
            key={key}
            style={{
              width: cardStyle?.width ?? "100%",
              height: cardStyle?.height ?? "auto",
            }}
            cover={Cover}
            actions={Actions}
          >
            {getCustomCard(cardId, { ...record, customProps })}
          </Card>
        </div>
      </Wrapper>
    );
  }

  return (
    <div>
      <Spin
        spinning={isSpinning}
        size={spinSize}
        delay={spinDelay}
        tip={spinTitle}
      >
        <List
          className="container-list-rows" //static
          width={listStyle.width}
          height={listStyle.height}
          rowCount={rows.length}
          rowHeight={rowHeight}
          rowRenderer={rowRenderer}
          style={{ width: listStyle.width, background: listStyle.background }}
          onRowsRendered={onRowsRendered}
        />
      </Spin>
    </div>
  );
}

export default VirtualizedList;
