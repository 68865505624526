//Libs
import Immutable from "immutable";
import dayjs from "dayjs";
//Utils
import { idbHandler } from "utils/libs";
import { filterReconditionedSeries } from "components/containers/Warehouse/utils";
import KEYWORDS from "./keywords";
import GENERAL from "utils/constants/general";
import { GlobalUtils, ProjectUtils, ServiceUtils } from "utils";

const { ENV } = GENERAL;
const { DEPARTMENTS } = ENV;

//Check is array
function checkArray(arr) {
  return Array.isArray(arr) ? arr : [];
}
//Validate require inventory
const filterWithRequireInventoryValidation = (order, serialized) => {
  const { props, service_props } = order;
  const series = checkArray(serialized);
  const newInventory = filterReconditionedSeries(series, "new");
  const reconditionedInventory = filterReconditionedSeries(
    series,
    "reconditioned"
  );

  if (
    !props ||
    !props.requireInventory ||
    props.requireInventory === "default"
  ) {
    //Revisamos la configuración del servicio
    if (service_props?.requireInventory === "new") {
      return newInventory.concat(reconditionedInventory);
    } else if (service_props?.requireInventory === "reconditioned") {
      return reconditionedInventory.concat(newInventory);
    } else return series;
  } else if (props?.requireInventory === "new") {
    return newInventory.concat(reconditionedInventory);
  } else if (props?.requireInventory === "reconditioned") {
    return reconditionedInventory.concat(newInventory);
  } else if (props?.requireInventory === "any") {
    return series;
  }
  return series;
};

export default class ConsumeInventoryUtils {
  //CHECKS
  //Check invalid idx
  static invalidIdx = (idx) => idx === -1;
  //Check unsucess consumed items
  static checkUnsuccessConsumedItem = (consumedItem) =>
    consumedItem.status !== KEYWORDS.STATUS.SUCCESS &&
    consumedItem.status !== KEYWORDS.STATUS.DENY;
  //Check inventory item is serialized
  static checkInventoryItemIsSerialized = (item) => item.is_serialized;
  //Check inventory item is unavailable (Uninstallable)
  static checkInventoryItemIsUninstallable = (item) => item.unavailable;
  //Check item have fifo amount
  static checkInventoryItemHaveFifoAmount = (item) => item.fifo_amount !== null;
  //Check item is available in peps list
  static checkItemIsAvailableInPepsList = (item, counter) => {
    if (!counter[item.family_id]) counter[item.family_id] = 1;
    else counter[item.family_id]++;

    const itemIsAvailable = counter[item.family_id] <= item.fifo_amount;
    return itemIsAvailable;
  };
  //Check item exists in consumed items
  static checkItemExistsInConsumedItems = (item, { id } = {}) => item.id === id;
  //Check input value
  static checkInputValue(value) {
    if (
      !value ||
      value === "0" ||
      value === "NaN" //No utilizar: isNaN(Number(value)) porque value puede ser alfanumérico y en este caso daría erróneamente un NaN
    )
      return null;
    return value;
  }
  //Check exists consumed items
  static checkExistsConsumedItems(inventory) {
    return checkArray(inventory).length > 0;
  }
  //Check autoSync active
  static checkAutoSyncActive(autoSyncActive, sendingConsumedItems) {
    return autoSyncActive || sendingConsumedItems;
  }
  static checkConsumedItemResume(item, editableInventory) {
    if (editableInventory) return true;
    return (
      this.checkInputValue(item.value) &&
      item.status === KEYWORDS.STATUS.SUCCESS
    );
  }
  //Check is editable inventory
  static checkIsEditableInventory(order, wtd) {
    const editableInventoryConfig = GlobalUtils.selectCurrentProps([
      ProjectUtils.getProjectPropsFromOrder(
        DEPARTMENTS.PROPS.EDITABLE_INVENTORY.NAME,
        order
      ),
      ServiceUtils.getServicePropsFromOrder(
        DEPARTMENTS.PROPS.EDITABLE_INVENTORY.NAME,
        order
      ),
    ]);

    if (GlobalUtils.isEmptyObject(editableInventoryConfig)) return;
    if (!order.props?.editableInventory && !wtd?.created_at) return;

    const grantedDate = new Date(
      order.props?.editableInventory || wtd?.created_at
    );

    const grantExpirationDate = dayjs(grantedDate).add(
      editableInventoryConfig.interval,
      editableInventoryConfig.unit
    );

    return grantExpirationDate - new Date() > 0;
  }

  //GETTERS
  //Get input prop
  static getInputProp({ inventoryItem, prop }) {
    if (prop === "step") {
      const itemStep = inventoryItem.item_props?.step;
      const measuredStep = inventoryItem.measured_unit_props?.step;
      const decimal =
        inventoryItem.item_props?.decimal ??
        inventoryItem.measured_unit_props?.decimal;

      if (itemStep || measuredStep) {
        return itemStep ?? measuredStep;
      }
      if (decimal) {
        return 0.1;
      }
      return 1.0;
    }
    if (prop === "min") {
      return (
        inventoryItem.item_props?.min ??
        inventoryItem.measured_unit_props?.min ??
        0
      );
    }
    if (prop === "decimal") {
      return (
        inventoryItem.item_props?.decimal ??
        inventoryItem.measured_unit_props?.decimal
      );
    }
  }
  //Get integer value (cast)
  static getIntegerValue(value) {
    if (!value || isNaN(value)) return "";
    const intValue = Math.floor(value);
    return intValue;
  }
  //Get consumed item value
  static getConsumedItemValue(savedConsumedItem) {
    switch (savedConsumedItem?.type) {
      case KEYWORDS.TYPES.ARTICLE:
        const val = parseFloat(savedConsumedItem?.value);
        if (!isNaN(val) && val > 0) return val;
        break;
      case KEYWORDS.TYPES.SERIALIZED:
      case KEYWORDS.TYPES.UNINSTALL_SERIALIZED:
        if (savedConsumedItem?.value && savedConsumedItem?.value !== "null")
          return savedConsumedItem?.value;
        break;
      default:
        return;
    }
  }
  //Get article inventory items
  static getArticleInventoryItems = (inventory) =>
    checkArray(inventory).filter((article) => !article.is_serialized);
  //Get serialized inventory items
  static getSerializedInventoryItems = (inventory) =>
    checkArray(inventory).filter((serie) => serie.is_serialized);
  //Get article consumed items
  // static getArticleConsumedItems = (consumedItems) =>
  //   checkArray(consumedItems).filter(
  //     (consumedItem) => consumedItem.type === KEYWORDS.TYPES.ARTICLE
  //   );

  //Get serialized consumed items
  // static getSerializedConsumedItems = (consumedItems) =>
  //   checkArray(consumedItems).filter(
  //     (consumedItem) => consumedItem.type === KEYWORDS.TYPES.SERIALIZED
  //   );

  //Get uninstall consumed items
  static getUninstallConsumedItems = (consumedItems) =>
    checkArray(consumedItems).filter(
      (consumedItem) =>
        consumedItem.type === KEYWORDS.TYPES.UNINSTALL_SERIALIZED
    );
  //Get combine inventory with uninstall consumed items
  // static combineInventoryWithUninstallInventory(
  //   inventory,
  //   toUninstallInventory,
  //   consumedItems
  // ) {
  //   //Get uninstall consumed items
  //   const uninstallConsumedItems =
  //     this.getUninstallConsumedItems(consumedItems);
  //   //Get uninstall inventory
  //   const uninstallInventory = uninstallConsumedItems.reduce(
  //     (acc, consumedItem) => {
  //       const toUninstallItem = checkArray(toUninstallInventory).find(
  //         (toUninstallItem) => toUninstallItem.item_id === consumedItem.itemId
  //       );
  //       if (toUninstallItem) {
  //         acc.push({ ...toUninstallItem, serie: consumedItem.serie });
  //       }
  //       return acc;
  //     },
  //     []
  //   );
  //   return inventory.concat(uninstallInventory);
  // }
  //Get Design props by type item card
  static getDesignProps({ view, key }) {
    const styles = {
      listStyle: {
        [KEYWORDS.TYPES.ARTICLE]: {
          width: document.documentElement.clientWidth - 26,
          height: document.documentElement.clientHeight - 155,
        },
        [KEYWORDS.TYPES.SERIALIZED]: {
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight - 155,
        },
        [KEYWORDS.TYPES.UNINSTALL_SERIALIZED]: {
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight - 215,
        },
      },
      cardStyle: {
        [KEYWORDS.TYPES.ARTICLE]: {
          width: 300,
          height: 190,
        },
        [KEYWORDS.TYPES.SERIALIZED]: {
          width: 300,
          height: 72,
        },
        [KEYWORDS.TYPES.UNINSTALL_SERIALIZED]: {
          width: 300,
          height: 72,
        },
      },
      rowHeight: {
        [KEYWORDS.TYPES.ARTICLE]: 200,
        [KEYWORDS.TYPES.SERIALIZED]: 82,
        [KEYWORDS.TYPES.UNINSTALL_SERIALIZED]: 82,
      },
    };

    return styles[key][view];
  }
  //Get corresponding inventory
  static getCorrespondingInventory({
    view,
    toInvoiceInventory,
    toInvoiceSerializedInventory,
    uninstallConsumedItems,
  }) {
    if (view === KEYWORDS.TYPES.ARTICLE) {
      return toInvoiceInventory;
    }
    if (view === KEYWORDS.TYPES.SERIALIZED) {
      return toInvoiceSerializedInventory;
    }
    if (view === KEYWORDS.TYPES.UNINSTALL_SERIALIZED) {
      return uninstallConsumedItems;
    }
  }
  //Check new consumed items in offline consumed items
  static checkNewConsumedItemsInOfflineConsumedItems = (
    offlineConsumedItem,
    newConsumedItems
  ) => {
    //Get new consumed item object
    const newConsumedItem = checkArray(newConsumedItems).find(
      (nci) => nci.id === offlineConsumedItem.id
    );
    //Get offline consumed item created at
    const offlineConsumedItemCreatedAt = new Date(
      offlineConsumedItem?.createdAt
    );
    //Get new consumed item created at
    const newConsumedItemCreatedAt = new Date(newConsumedItem?.createdAt);

    //Importante
    //El siguiente return, retornará el offline consumed item.
    //El offline consumed item sólo se debe retornar si su fecha de creación es superior a
    //la fecha de creación del nuevo consumed item
    return (
      !newConsumedItem ||
      offlineConsumedItemCreatedAt - newConsumedItemCreatedAt > 0 //Si la fecha de creación del offline consumed item - la fecha de creación del nuevo consumed item es superior a 0, significa que el offline consumed item se creó después que el nuevo consumed item
    );
  };
  //Get URI from consumed items
  static getURI({ orderId, workflow, context }) {
    switch (context) {
      case KEYWORDS.URI_CONTEXT.GET_CURRENT_CONSUMED_ITEMS:
        if (workflow === KEYWORDS.WORKFLOW.CONSUME) {
          if (!orderId) return null;
          return `/inventoryManager/v1/consume/${KEYWORDS.URI_CONTEXT.GET_CURRENT_CONSUMED_ITEMS}`;
        }
        break;
      case KEYWORDS.URI_CONTEXT.SAVE_CONSUMED_INVENTORY:
        if (workflow === KEYWORDS.WORKFLOW.CONSUME) {
          return `/inventoryManager/v1/consume/${KEYWORDS.URI_CONTEXT.SAVE_CONSUMED_INVENTORY}`;
        }
        break;
      case KEYWORDS.URI_CONTEXT.AUTO_SYNC_CONSUMED_ITEMS:
        if (workflow === KEYWORDS.WORKFLOW.CONSUME) {
          return `/inventoryManager/v1/consume/${KEYWORDS.URI_CONTEXT.SAVE_CONSUMED_INVENTORY}`;
        }
        break;
      case KEYWORDS.URI_CONTEXT.GENERATE_RESUME:
        if (workflow === KEYWORDS.WORKFLOW.CONSUME) {
          if (!orderId) return null;
          return `/inventoryManager/v1/consume/${KEYWORDS.URI_CONTEXT.GENERATE_RESUME}/${orderId}`;
        }
        break;
      default:
        return null;
    }
  }
  //Combine success items
  static combineConsumedItems(offlineConsumedItems, newConsumedItems) {
    const filteredOfflineConsumedItems = checkArray(
      offlineConsumedItems
    ).filter((oci) =>
      this.checkNewConsumedItemsInOfflineConsumedItems(oci, newConsumedItems)
    );
    return [...filteredOfflineConsumedItems, ...newConsumedItems];
  }
  //Get saved DB collector values
  static getFormattedTransactionItemsToConsumedItems(
    { otdId, orderId, ownerId, entityId },
    transactionItems
  ) {
    return transactionItems.reduce((acc, item) => {
      const consumedItemProps = this.getConsumedItemProps(
        { otdId, orderId, ownerId, entityId },
        item
      );
      acc.push({
        ...consumedItemProps,
        value: item.value,
        status: item.status,
        props: item.props,
        createdAt: item.created_at,
      });
      return acc;
    }, []);
  }
  //Reload offline consumed inventory
  static async reloadOfflineConsumedItems() {
    const offlineConsumedItems = await idbHandler.getConsumedItems();
    return Immutable.List(checkArray(offlineConsumedItems)).toJS();
  }
  //Get reviewManage
  static async getOfflineManageInventory() {
    const response = await Promise.all([this.reloadOfflineConsumedItems()]);
    return {
      offlineConsumedItems: response[0],
    };
  }
  //Get unsuccess consumed items
  static getUnsuccessConsumedItems({ consumedItems, max = 50 }) {
    return checkArray(consumedItems)
      .filter((c) => this.checkUnsuccessConsumedItem(c))
      .slice(0, max);
  }
  //Get unsuccess consumed items from order id
  static getUnsuccessConsumedItemsCountFromOrder(orderId, consumedItems) {
    return checkArray(consumedItems)
      .filter((c) => c.orderId === orderId)
      .filter((c) => this.checkUnsuccessConsumedItem(c)).length;
  }
  //Get non-removable consumedItems
  static getNonRemovableConsumedItems(consumedItems) {
    return checkArray(consumedItems).filter((c) =>
      this.checkUnsuccessConsumedItem(c)
    );
  }
  //Get item IDX from consumed items
  static getConsumedItemIdxFromConsumedItems(consumedItems, consumedItemProps) {
    return checkArray(consumedItems).findIndex((item) =>
      this.checkItemExistsInConsumedItems(item, consumedItemProps)
    );
  }
  //Get segmented inventory
  static getSegmentedInventory(inventory) {
    const inv = checkArray(inventory);

    const articles = checkArray(inv).filter(
      ({ type }) => type === KEYWORDS.TYPES.ARTICLE
    );

    const serialized = checkArray(inv).filter(
      ({ type }) => type === KEYWORDS.TYPES.SERIALIZED
    );

    const uninstallSerialized = checkArray(inv).filter(
      ({ type }) => type === KEYWORDS.TYPES.UNINSTALL_SERIALIZED
    );

    return [articles, serialized, uninstallSerialized];
  }
  //Get segmented transfered items
  static getSegmentedInventoryV2(inventory) {
    return inventory.reduce(
      (acc, item) => {
        const type = this.getConsumedItemType(item);
        if (type === KEYWORDS.TYPES.ARTICLE) {
          acc.articles.push(item);
        } else if (type === KEYWORDS.TYPES.SERIALIZED) {
          acc.serialized.push(item);
        } else if (type === KEYWORDS.TYPES.UNINSTALL_SERIALIZED) {
          acc.uninstallSerialized.push(item);
        }
        return acc;
      },
      {
        articles: [],
        serialized: [],
        uninstallSerialized: [],
      }
    );
  }
  //Get segmented serie item amounts
  static getSegmentedSerieItemAmounts(series) {
    return series.reduce((acc, serie) => {
      const idx = acc.findIndex(({ item_id }) => item_id === serie.item_id);
      if (idx !== -1) {
        acc[idx].amount += 1;
      } else {
        acc.push({
          item_id: serie.item_id,
          item_name: serie.item_name,
          amount: 1,
        });
      }
      return acc;
    }, []);
  }
  //Get item ID
  static getConsumedItemId(order, item) {
    return `order${order.orderId}itemId${item.item_id}serie${
      item.serie
    }serieId${item.serie_id || null}`;
  }
  //Get item type
  static getConsumedItemType(item) {
    if (this.checkInventoryItemIsSerialized(item)) {
      if (this.checkInventoryItemIsUninstallable(item))
        return KEYWORDS.TYPES.UNINSTALL_SERIALIZED;
      return KEYWORDS.TYPES.SERIALIZED;
    }
    return KEYWORDS.TYPES.ARTICLE;
  }
  //Get consumed item props
  static getConsumedItemProps = (
    { otdId, orderId, ownerId, entityId } = {},
    item = {}
  ) => ({
    id: this.getConsumedItemId({ orderId }, item),
    otdId,
    orderId,
    ownerId,
    entityId,
    itemId: item.item_id,
    itemName: item.item_name,
    itemCode: item.item_code,
    serieId: item.serie_id || null,
    serie: item.serie,
    isSerialized: item.is_serialized,
    familyId: item.family_id,
    familyName: item.family_name,
    mainDevice: item.main_device,
    uninstalledFamilyId: item.uninstalled_family_id,
    unavailable: item.unavailable,
    matchWith: item.match_with,
    stateId: item.state_id,
    type: this.getConsumedItemType(item),
  });
  //Get to invoice item format
  static getToInvoiceItemFormatFromConsumedItemProps = (item = {}) => ({
    item_id: item.itemId,
    item_name: item.itemName,
    item_code: item.itemCode,
    serie: item.serie,
    is_serialized: item.isSerialized,
    family_id: item.familyId,
    family_name: item.familyName,
    main_device: item.mainDevice,
    unavailable: item.unavailable,
    match_with: item.matchWith,
    state_id: item.stateId,
    uninstalled_family_id: item.uninstalledFamilyId,
  });
  //Get consumed item from IDX
  static getConsumedItemFromIdx(consumedItems, idx) {
    if (this.invalidIdx(idx)) return null;
    return consumedItems[idx];
  }
  //Get uninstall families
  static getUninstallFamiliesAndMembers(toUninstallInventory) {
    return toUninstallInventory.reduce((families, item) => {
      const familyIdx = families.findIndex((f) => f.id === item.family_id);
      const family = families[familyIdx];
      if (!family) {
        families.push({
          id: item.family_id,
          name: item.family_name,
          items: [item],
          type: this.getConsumedItemType(item),
        });
      } else {
        const item = family.items.find((i) => i.item_id === item.item_id);
        if (!item) {
          family.items.push(item);
          families[familyIdx] = family;
        }
      }
      return families;
    }, []);
  }
  //Get consumed items resume
  static getConsumedItemsResume(
    orderId,
    editableInventory,
    consumedItems,
    inventory
  ) {
    const [articles, serialized, uninstallSerialized] =
      this.getSegmentedInventory(
        checkArray(consumedItems)
          .filter(
            (item) =>
              item.orderId === orderId &&
              this.checkConsumedItemResume(item, editableInventory)
          )
          .reduce((acc, item) => {
            const dataItem = inventory.find((i) =>
              item.serieId
                ? Number(i.serie_id) === Number(item.serieId)
                : Number(i.item_id) === Number(item.itemId)
            );
            const values = {
              serie: dataItem?.serie ?? item.serie,
              serie_id: dataItem?.serie_id ?? item.serieId,
              value: item.value,
              type: item.type,
            };
            //Se va a agregar al array del resumen sólo si:

            //Si no encuentra el item en el inventario a utilizar
            if (!dataItem) {
              acc.push({
                ...this.getToInvoiceItemFormatFromConsumedItemProps(item),
                ...values,
                amount: 0,
                amount_transit: 0,
              });
            } else if (
              //Si encuentra el item en el inventario a utilizar pero el item consumido es un item desinstalable
              (item.type === KEYWORDS.TYPES.UNINSTALL_SERIALIZED &&
                values.value) ||
              //Si no está editando va a agregar todos los items consumidos
              !editableInventory
            ) {
              acc.push({
                ...dataItem,
                ...values,
              });
            }
            return acc;
          }, [])
      );
    return { articles, serialized, uninstallSerialized };
  }
  static getCorrespondingState(state) {
    if (state === KEYWORDS.STATE.OPEN) return KEYWORDS.STATE.CLOSED;
    if (state === KEYWORDS.STATE.CLOSED) return KEYWORDS.STATE.OPEN;
  }

  static getConsumedItem({
    otdId,
    orderId,
    ownerId,
    entityId,
    inventoryItem,
    consumedItems,
  }) {
    //Get consumed item props
    const consumedItemProps = this.getConsumedItemProps(
      { otdId, orderId, ownerId, entityId },
      inventoryItem
    );
    //Get saved consumed item idx
    const savedConsumedItemIdx = this.getConsumedItemIdxFromConsumedItems(
      consumedItems,
      consumedItemProps
    );
    //Saved consumed item
    const savedConsumedItem = this.getConsumedItemFromIdx(
      consumedItems,
      savedConsumedItemIdx
    );
    //Consumed item value
    const consumedItemValue = this.getConsumedItemValue(savedConsumedItem);

    return {
      consumedItemProps,
      savedConsumedItemIdx,
      savedConsumedItem,
      consumedItemValue,
      consumedInitialAmount: savedConsumedItem?.inventoryInitialAmount,
      consumedFinalAmount: savedConsumedItem?.inventoryFinalAmount,
    };
  }

  //FILTERS
  //Filter non serialized (article) inventory
  static filterArticleInventory(inventory) {
    return inventory.filter((item) => !item.is_serialized);
  }
  //Filter serialized inventory
  static filterSerializedInventory(inventory) {
    return inventory.filter((item) => item.is_serialized);
  }
  //Filter serialized in transit
  static filterSerializedInTransit(orderId, inventory) {
    return inventory.filter(
      (item) => !item.transit || item.transitOrderId === orderId
    );
  }
  //Filter inventory with peps validation (http://blog.algebasa.com/que-es-el-metodo-peps#:~:text=El%20m%C3%A9todo%20PEPS%20(o%20FIFO,primeras%20entradas%2C%20primeras%20salidas%E2%80%9D.)
  static filterInventoryWithPepsValidation(order, inventory) {
    if (!order || order.props?.requireInventory === "any") return inventory;

    const articles = this.getArticleInventoryItems(inventory);
    const serialized = this.getSerializedInventoryItems(inventory);
    const counter = {};
    const filteredSeries = filterWithRequireInventoryValidation(
      order,
      serialized
    ).filter((item) => {
      return (
        //Return if item is not serialized
        !this.checkInventoryItemIsSerialized(item) ||
        //Return if item is transit
        item.transit ||
        //Return available series in PEPS list
        (this.checkInventoryItemHaveFifoAmount(item) && this.checkItemIsAvailableInPepsList(item, counter))
      );
    });
    return articles.concat(filteredSeries);
  }
  //Filter data with searching value
  static filterInventoryWithSearchingValue(searchingValue, inventory) {
    if (!searchingValue || searchingValue === "") return checkArray(inventory);
    return checkArray(inventory).filter(
      (item) =>
        item.item_code?.toUpperCase().includes(searchingValue.toUpperCase()) ||
        item.item_name?.toUpperCase().includes(searchingValue.toUpperCase()) ||
        item.name?.toUpperCase().includes(searchingValue.toUpperCase()) ||
        item.serie?.toUpperCase().includes(searchingValue.toUpperCase())
    );
  }
  static filterToUninstallInventoryItemsWithTemplateProp(
    inventoryValidationTemplate,
    toUninstallInventory
  ) {
    //Get filtered items in template
    const filteredItems =
      inventoryValidationTemplate?.data_structure_object?.[
        ENV.TEMPLATES.DATA_STRUCTURE_OBJECT.FILTER_TO_UNINSTALL_INVENTORY_ITEMS
      ];

    //Array filtered items exists?
    if (Array.isArray(filteredItems) && filteredItems.length > 0) {
      return toUninstallInventory.filter(
        (item) => filteredItems.indexOf(item.item_id) !== -1
      );
    } else {
      return toUninstallInventory;
    }
  }

  //SETTERS
  //Add consumed item
  static addConsumedItem(
    { value, initialAmount, finalAmount },
    consumedItems,
    consumedItemProps
  ) {
    consumedItems.push({
      ...consumedItemProps,
      value: this.checkInputValue(value),
      initialAmount: this.checkInputValue(initialAmount),
      finalAmount: this.checkInputValue(finalAmount),
      status: KEYWORDS.STATUS.LOADING,
      createdAt: new Date().toISOString(),
    });
  }
  //Update consumed item
  static updateConsumedItem(
    { value, initialAmount, finalAmount },
    consumedItems,
    savedConsumedItemIdx,
    savedConsumedItem
  ) {
    savedConsumedItem.value = this.checkInputValue(value);
    savedConsumedItem.initialAmount = this.checkInputValue(initialAmount);
    savedConsumedItem.finalAmount = this.checkInputValue(finalAmount);
    savedConsumedItem.status = KEYWORDS.STATUS.LOADING;
    savedConsumedItem.createdAt = new Date().toISOString();
    consumedItems[savedConsumedItemIdx] = savedConsumedItem;
  }
  //Change consumed items
  static getUpdatedConsumedItems(
    { value, initialAmount, finalAmount },
    consumedItemProps,
    savedConsumedItemIdx,
    savedConsumedItem,
    consumedItems
  ) {
    const newConsumedItems = [...consumedItems];

    if (savedConsumedItem) {
      this.updateConsumedItem(
        { value, initialAmount, finalAmount },
        newConsumedItems,
        savedConsumedItemIdx,
        savedConsumedItem
      );
    } else {
      this.addConsumedItem(
        { value, initialAmount, finalAmount },
        newConsumedItems,
        consumedItemProps
      );
    }
    return newConsumedItems;
  }
  static onChangeConsumedItem(
    { value, initialAmount, finalAmount },
    {
      consumedItemProps,
      savedConsumedItemIdx,
      savedConsumedItem,
      consumedItems,
    },
    mutateConsumeInventoryControl,
    sendUnsuccessConsumedItems
  ) {
    const newConsumedItems = this.getUpdatedConsumedItems(
      { value, initialAmount, finalAmount },
      consumedItemProps,
      savedConsumedItemIdx,
      savedConsumedItem,
      consumedItems
    );
    mutateConsumeInventoryControl({ consumedItems: newConsumedItems });
    this.mutateOfflineConsumedItems(newConsumedItems);
    sendUnsuccessConsumedItems(newConsumedItems);
  }
  //Set status to consumed items
  static setStatusToConsumedItems(consumedItems, status) {
    return consumedItems.map((ci) => {
      ci.status = status;
      return ci;
    });
  }
  //Mutate Offline Consumed Items
  static mutateOfflineConsumedItems(consumedItems) {
    if (!Array.isArray(consumedItems)) return;
    idbHandler.setManageInventoryControl({ consumedItems });
  }
}
