/****** CONSTANTS *******/
import GENERAL from "../../utils/constants/general";
const { APIDATA } = GENERAL;

//GENERAL
export function mutateDirectProps(keyValuePairs) {
  return {
    type: APIDATA.MUTATE_DIRECT_PROPS,
    payload: keyValuePairs,
  };
}

export function mutate1Object(obj1Name, keyValuePairs) {
  return {
    type: APIDATA.MUTATE_1OBJECT,
    payload: { obj1Name, keyValuePairs },
  };
}

//USERS
export function getOrganizationUsers() {
  return {
    type: APIDATA.GET_ORGANIZATION_USERS,
  };
}

//ORDERS
export function getOrderStates() {
  return {
    type: APIDATA.GET_ORDER_STATES,
  };
}

export function getOrderEvents() {
  return {
    type: APIDATA.GET_ORDER_EVENTS,
  };
}
export function getCompletedCodes() {
  return {
    type: APIDATA.GET_COMPLETED_CODES,
  };
}

//PAYMENTS
export function getPayments() {
  return {
    type: APIDATA.GET_PAYMENTS,
  };
}

export function getPaymentResume(payIds) {
  return {
    type: APIDATA.GET_PAYMENT_RESUME,
    payload: payIds,
  };
}

export function getCoins() {
  return {
    type: APIDATA.GET_COINS,
  };
}

//TEMPLATES
export function getMyTemplates() {
  return {
    type: APIDATA.GET_MY_TEMPLATES,
  };
}

//WAREHOUSE
export function getIncomeMethods() {
  return {
    type: APIDATA.GET_INCOME_METHODS,
  };
}

export function getInventoryToInvoice() {
  return {
    type: APIDATA.GET_INVENTORY_TO_INVOICE,
  };
}

export function getInventoryToUninstall() {
  return {
    type: APIDATA.GET_INVENTORY_TO_UNINSTALL,
  };
}

export function getWarehouses() {
  return {
    type: APIDATA.GET_WAREHOUSES,
  };
}

export function getSerieHistory(serie) {
  return {
    type: APIDATA.GET_SERIE_HISTORY,
    payload: serie,
  };
}

export function getCities() {
  return {
    type: APIDATA.GET_CITIES,
  };
}

export function getTownships(cityId) {
  return {
    type: APIDATA.GET_TOWNSHIPS,
    payload: cityId,
  };
}

export function getZones(townshipId) {
  return {
    type: APIDATA.GET_ZONES,
    payload: townshipId,
  };
}

export function getDepartments() {
  return {
    type: APIDATA.GET_DEPARTMENTS,
  };
}

export function getContractTypes(departmentId) {
  return {
    type: APIDATA.GET_CONTRACT_TYPES,
    payload: departmentId,
  };
}

//RESET MODULE DATA
export function doResetApiData(resetModuleData) {
  return {
    type: APIDATA.RESET_MODULE_DATA,
    payload: resetModuleData,
  };
}
