//Libs
import React from "react";
import { connect } from "react-redux";
//Utils
import KEYWORDS from "./keywords";
//Context
import { PhotoProvider, PhotoContext } from "context";
//Actions
//Actions
import { mutateConsumeInventoryControl } from "components/components/ConsumeInventory/actions";
import { sendToast } from "components/containers/Dashboard/actions";
import {
  mutate1Object as mutate1ObjectInCollector,
  saveCollectorValues,
  updateCollectorRequiredValidation,
  onGenerateReport,
  completeAuditedReport,
  setAutoSync,
} from "./actions";
import { uploadResource } from "components/components/UploadResource/actions";
//Selectors
import {
  selectReviewManage,
  selectAvailableReports,
  selectGeneratedReports,
  selectCollectorEcosystemAutoSync,
} from "./selectors";
import { selectProfile } from "components/containers/Login/selectors";
//Components
import {
  Wrapper,
  Collapse as _Collapse,
  ServiceTaskSection,
  ServiceTaskHeader,
  CollectorUtils,
} from "components/components";
import CollectorModals from "./modals";

const { Collapse, Panel } = _Collapse;

const initialState = {
  serviceTaskActiveKey: undefined,
  reviewActiveKey: undefined,
  duplicateElement: null,
  deleteElement: null,
};

const CollectorLayout = React.memo(
  ({
    //Props
    collectorEcosystemAutoSync,
    order,
    docId,
    readOnlyCollector,
    collectorLayout,
    duplicatedCollectorLayout,
    filteredCollectorLayout,
    collectorValues,
    resources,
    //Redux
    profile,
    reviewManage,
    availableReports,
    generatedReports,
    mutate1ObjectInCollector,
    saveCollectorValues,
    completeAuditedReport,
    updateCollectorRequiredValidation,
    uploadResource,
    onGenerateReport,
    setAutoSync,
    sendToast,
    mutateConsumeInventoryControl,
  }) => {
    const {
      orderId,
      showSendConfirmationModal,
      selectedCurrentCollector,
      collectorRequiredValidation,
      autoFillCollector,
      transactionDocs,
    } = reviewManage;
    const { highlight: highlightRequired } = collectorRequiredValidation;
    const { isPhotoBackupActive, isSynapseCameraActive } =
      React.useContext(PhotoContext);
    const [state, _setState] = React.useState(initialState);
    const setState = (newState) =>
      _setState((current) => ({ ...current, ...newState }));

    //Reset Collapse
    React.useEffect(() => {
      setState(initialState);
    }, [transactionDocs.docId]);

    //Get service tasks
    const serviceTasks = React.useMemo(
      () => CollectorUtils.getServiceTasks(filteredCollectorLayout),
      [filteredCollectorLayout, duplicatedCollectorLayout]
    );

    React.useEffect(() => {
      const hasInventory = !!collectorLayout.find(
        (review) =>
          !!review.collectors.find(
            (collector) =>
              collector.typeKey === KEYWORDS.COLLECTORS.TYPES.CONSUME_INVENTORY
          )
      );
      mutateConsumeInventoryControl({ controlledInventory: hasInventory });
    }, [collectorLayout]);

    if (!orderId || !order) return null;

    return (
      <Wrapper padding="0" flexDirection="column" className="animated fadeIn">
        <Collapse
          accordion
          bordered={false}
          defaultActiveKey={["-1"]}
          activeKey={state.serviceTaskActiveKey}
          destroyInactivePanel={true}
          onChange={(serviceTaskActiveKey) =>
            setState({ reviewActiveKey: undefined, serviceTaskActiveKey })
          }
        >
          {/* SERVICE TASKS */}
          {serviceTasks.map(
            (task, idx) =>
              (!state.serviceTaskActiveKey ||
                Number(state.serviceTaskActiveKey) === idx) && (
                <Panel
                  key={idx}
                  header={
                    <ServiceTaskHeader
                      task={task}
                      order={order}
                      docId={docId}
                      readOnlyCollector={readOnlyCollector}
                      highlightRequired={highlightRequired}
                      selectedCurrentCollector={selectedCurrentCollector}
                      autoFillCollector={autoFillCollector}
                      availableReports={availableReports}
                      generatedReports={generatedReports}
                      profile={profile}
                      mutate1ObjectInCollector={mutate1ObjectInCollector}
                      onGenerateReport={onGenerateReport}
                    />
                  }
                >
                  <ServiceTaskSection
                    collectorEcosystemAutoSync={collectorEcosystemAutoSync}
                    task={task}
                    profile={profile}
                    order={order}
                    docId={docId}
                    readOnlyCollector={readOnlyCollector}
                    reviewActiveKey={state.reviewActiveKey}
                    highlightRequired={highlightRequired}
                    selectedCurrentCollector={selectedCurrentCollector}
                    isPhotoBackupActive={isPhotoBackupActive}
                    isSynapseCameraActive={isSynapseCameraActive}
                    collectorLayout={collectorLayout}
                    duplicatedCollectorLayout={duplicatedCollectorLayout}
                    filteredCollectorLayout={filteredCollectorLayout}
                    collectorValues={collectorValues}
                    resources={resources}
                    handleOnDuplicateElement={(duplicateElement) =>
                      setState({ duplicateElement })
                    }
                    handleOnDeleteElement={(deleteElement) =>
                      setState({ deleteElement })
                    }
                    onChangeCollapse={(activeKey) =>
                      setState({ ...state, reviewActiveKey: activeKey })
                    }
                  />
                </Panel>
              )
          )}
        </Collapse>

        {/* Modals */}
        <CollectorModals
          //State
          profile={profile}
          order={order}
          docId={docId}
          showSendConfirmationModal={showSendConfirmationModal}
          autoFillCollector={autoFillCollector}
          serviceTasks={serviceTasks}
          collectorLayout={collectorLayout}
          duplicatedCollectorLayout={duplicatedCollectorLayout}
          filteredCollectorLayout={filteredCollectorLayout}
          collectorValues={collectorValues}
          resources={resources}
          saveCollectorValues={saveCollectorValues}
          duplicateElement={state.duplicateElement}
          deleteElement={state.deleteElement}
          //Actions
          handleOnCloseDuplicateElement={() =>
            setState({ duplicateElement: null })
          }
          handleOnCloseDeleteElement={() => setState({ deleteElement: null })}
          mutate1ObjectInCollector={mutate1ObjectInCollector}
          updateCollectorRequiredValidation={updateCollectorRequiredValidation}
          uploadResource={uploadResource}
          completeAuditedReport={completeAuditedReport}
          setAutoSync={setAutoSync}
          sendToast={sendToast}
        />
      </Wrapper>
    );
  }
);

const CollectorLayoutWrapper = (props) => (
  <PhotoProvider>
    <CollectorLayout {...props} />
  </PhotoProvider>
);

const mapStateToProps = (state) => ({
  profile: selectProfile(state),
  reviewManage: selectReviewManage(state),
  availableReports: selectAvailableReports(state),
  generatedReports: selectGeneratedReports(state),
  collectorEcosystemAutoSync: selectCollectorEcosystemAutoSync(state),
});

const actions = {
  mutate1ObjectInCollector,
  saveCollectorValues,
  completeAuditedReport,
  updateCollectorRequiredValidation,
  uploadResource,
  onGenerateReport,
  setAutoSync,
  sendToast,
  mutateConsumeInventoryControl,
};

export default connect(mapStateToProps, actions)(CollectorLayoutWrapper);
