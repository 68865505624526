//Libs
import { ConsumeInventoryUtils } from "components/components";
import Immutable from "immutable";
import { createSelector } from "reselect";
import {
  filterInventoryToTransferSearchInData,
  filterItemsTransactionDocsSearchInData,
  filterTransactionsSearchInData,
  filterWarehouseSearchInData,
  getPreview,
} from "./utils";

const selectWarehouse = (state) => state.warehouse;

export const selectWarehouseState = createSelector(
  [selectWarehouse],
  (warehouse) => warehouse
);

export const selectTransactionState = createSelector(
  [selectWarehouse],
  ({ transactions }) => ({
    ...transactions,
    data: Immutable.List(transactions.data).toJS(),
    items: {
      ...transactions.items,
      data: Immutable.List(transactions.items.data).toJS(),
    },
    inventory: {
      ...transactions.inventory,
      data: Immutable.List(transactions.inventory.data).toJS(),
    },
  })
);

export const selectStockArticles = createSelector(
  [selectWarehouse],
  (warehouse) =>
    filterWarehouseSearchInData(
      Immutable.List(warehouse.stockArticlesManage.articles.data).toJS(),
      warehouse.searchInData.data
    )
);

export const selectStockSeriesManage = createSelector(
  [selectWarehouse],
  ({ stockSeriesManage }) => stockSeriesManage
);

export const selectStockFamilies = createSelector(
  [selectWarehouse],
  (warehouse) =>
    filterWarehouseSearchInData(
      Immutable.List(warehouse.stockSeriesManage.families.data).toJS(),
      warehouse.searchInData.data
    )
);

export const selectStockSeries = createSelector(
  [selectWarehouse],
  (warehouse) =>
    filterWarehouseSearchInData(
      Immutable.List(warehouse.stockSeriesManage.series.data).toJS(),
      warehouse.searchInData.data
    )
);

export const selectCreateTransaction = createSelector(
  [selectWarehouse],
  (warehouse) => ({
    ...warehouse.createTransaction,
    articles: Immutable.List(warehouse.createTransaction.articles).toJS(),
    serialized: Immutable.List(warehouse.createTransaction.serialized).toJS(),
  })
);

export const selectArticlesInTransaction = createSelector(
  [selectCreateTransaction],
  ({ articles }) => articles
);

export const selectSerializedInTransaction = createSelector(
  [selectCreateTransaction],
  ({ serialized }) => serialized
);

export const selectGetTransactionsModal = createSelector(
  [selectWarehouse],
  ({ getTransactionsModal }) => getTransactionsModal
);

export const selectTransactionManage = createSelector(
  [selectWarehouse],
  ({ transactionsManage }) => transactionsManage
);

export const selectInventoryToTransferManage = createSelector(
  [selectWarehouse],
  ({ inventoryToTransferManage }) => inventoryToTransferManage
);

export const selectItemsTransactionDoc = createSelector(
  [selectWarehouse],
  ({ itemsTransactionDoc }) => itemsTransactionDoc
);

export const selectTransactionLoading = createSelector(
  [selectTransactionState],
  ({ loading }) => loading
);

export const selectTransactionInventoryLoading = createSelector(
  [selectTransactionState],
  ({ inventory }) => inventory.loading
);

export const selectTransactionItemLoading = createSelector(
  [selectTransactionState],
  ({ items }) => items.loading
);

export const selectTransactionRows = createSelector(
  [selectTransactionState, selectTransactionManage],
  (transactions, { searchInData }) =>
    filterTransactionsSearchInData(
      transactions.data,
      searchInData.searchBy,
      searchInData.data
    )
);

export const selectSelectedTransaction = createSelector(
  [selectTransactionRows, selectTransactionManage],
  (transactionsRows, { docId }) =>
    transactionsRows.find(({ id }) => id === docId)
);

export const selectInventoryToTransfer = createSelector(
  [
    selectTransactionState,
    selectInventoryToTransferManage,
    selectCreateTransaction,
  ],
  (transactions, { searchInData }, { preview, articles, serialized }) =>
    getPreview(
      preview,
      filterInventoryToTransferSearchInData(
        transactions.inventory.data,
        searchInData.searchBy,
        searchInData.data
      ),
      articles,
      serialized
    )
);

export const selectSegmentedInventoryToTransfer = createSelector(
  [selectInventoryToTransfer],
  (inventoryToTransfer) =>
    ConsumeInventoryUtils.getSegmentedInventoryV2(inventoryToTransfer)
);

export const selectSegmentedTransferedItems = createSelector(
  [selectTransactionState, selectItemsTransactionDoc],
  (transactions, { searchInData }) =>
    ConsumeInventoryUtils.getSegmentedInventoryV2(
      filterItemsTransactionDocsSearchInData(
        transactions.items.data,
        searchInData.searchBy,
        searchInData.data
      )
    )
);
