// Utils
import { PhotonImage } from "utils/libs";
import { formatDate } from "utils/libs/dateFormats";
// Components
import SynapseLogo from "assets/media/img/logo-synapse-text.png";

const SETTINGS = {
  MARGIN: 10,
  WATERMARKS: {
    SYNAPSE_LOGO: {
      POS_X: 10,
      POS_Y: 10,
    },
    CLIENT_LOGO: {
      POS_X: (image, width) => image.get_width() - SETTINGS.MARGIN - width,
      POS_Y: (image, height) =>
        image.get_height() -
        SETTINGS.WATERMARKS.COORDS.HEIGHT -
        SETTINGS.MARGIN -
        height,
    },
    COORDS: {
      POS_X: 0,
      POS_Y: (image) => image.get_height() - SETTINGS.WATERMARKS.COORDS.HEIGHT,
      WIDTH: (image) => image.get_width(),
      HEIGHT: 140,
    },
  },
};

const addCoordWatermark = async ({ photon, image, coords, profile }) => {
  //Build Watermark
  const canvas = document.createElement("canvas");
  canvas.width = SETTINGS.WATERMARKS.COORDS.WIDTH(image);
  canvas.height = SETTINGS.WATERMARKS.COORDS.HEIGHT;
  const ctx = canvas.getContext("2d");
  const posX = SETTINGS.WATERMARKS.COORDS.POS_X;
  const posY = SETTINGS.WATERMARKS.COORDS.POS_Y(image);

  const fillRect = () => {
    const posY = 0;
    const width = canvas.width;
    const height = canvas.height;
    ctx.fillStyle = "rgba(0, 0, 0, 0.7)";
    ctx.fillRect(posX, posY, width, height);
  };
  const fillText = () => {
    const posX = canvas.width - SETTINGS.MARGIN;
    const posY = canvas.height - SETTINGS.MARGIN;
    //Text
    ctx.fillStyle = "#FFF";
    ctx.font = "32px Roboto";
    ctx.textAlign = "right";
    ctx.fillText(`${coords.latitude}, ${coords.longitude}`, posX, posY - 90);
    if (coords.geocode) ctx.fillText(coords.geocode, posX, posY - 45);
    ctx.fillText(
      `${formatDate(coords.createdAt, profile, "dddd DD MMM YYYY HH:mm")} (${
        coords.createdAtSource
      })`,
      posX,
      posY
    );
  };

  fillRect();
  fillText();

  //Set Coord Watermark
  const watermarkImage = await photon.open_image(canvas, ctx);
  photon.watermark(image, watermarkImage, posX, posY);
};

const addLogoWatermark = async ({
  base64Watermark,
  photon,
  image,
  posX,
  posY,
}) => {
  if (!base64Watermark) return;
  const watermarkImage = await photon.PhotonImage.new_from_base64(
    base64Watermark.replace(/data:image\/.*;\w*,/, "")
  );
  photon.watermark(image, watermarkImage, posX, posY);
};

const addClientLogoWatermark = async ({ clientLogo, photon, image }) => {
  const { base64Img, width, height } = clientLogo;
  return addLogoWatermark({
    base64Watermark: base64Img,
    photon,
    image,
    posX: SETTINGS.WATERMARKS.CLIENT_LOGO.POS_X(image, width),
    posY: SETTINGS.WATERMARKS.CLIENT_LOGO.POS_Y(image, height),
  });
};

export default class Watermark {
  static async addWatermarkText({ canvas, coords, profile, clientLogo }) {
    try {
      const photon = await PhotonImage.getInstance().then(
        ({ photon }) => photon
      );
      const ctx = canvas.getContext("2d");
      const image = await photon.open_image(canvas, ctx);
      await addCoordWatermark({
        photon,
        image,
        coords,
        profile,
      });
      await addLogoWatermark({
        base64Watermark: SynapseLogo,
        photon,
        image,
        posX: SETTINGS.WATERMARKS.SYNAPSE_LOGO.POS_X,
        posY: SETTINGS.WATERMARKS.SYNAPSE_LOGO.POS_Y,
      });
      await addClientLogoWatermark({
        clientLogo,
        photon,
        image,
      });
      return image.get_base64();
    } catch (err) {
      throw err;
    }
  }
}
