//Libs
import styled from "styled-components";

const Version = styled.span`
  font-family: ${(props) => props.theme.version.fontFamily};
  font-style: ${(props) => props.theme.version.fontStyle};
  font-weight: ${(props) => props.theme.version.fontWeight};
  font-size: ${(props) => props.theme.version.fontSize};
  line-height: ${(props) => props.theme.version.lineHeight};
  text-align: ${(props) => props.theme.version.textAlign};
  letter-spacing: ${(props) => props.theme.version.letterSpacing};
  color: ${(props) => props.theme.version.color};
`;

export default Version;
