//Libs
import React from "react";

export default ({ fill = "white", fillOpacity = "0.54" }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor="pointer"
  >
    <path
      d="M20 14.6666V6.66663L16 2.66663L12 6.66663V9.33329H4V28H28V14.6666H20ZM9.33333 25.3333H6.66667V22.6666H9.33333V25.3333ZM9.33333 20H6.66667V17.3333H9.33333V20ZM9.33333 14.6666H6.66667V12H9.33333V14.6666ZM17.3333 25.3333H14.6667V22.6666H17.3333V25.3333ZM17.3333 20H14.6667V17.3333H17.3333V20ZM17.3333 14.6666H14.6667V12H17.3333V14.6666ZM17.3333 9.33329H14.6667V6.66663H17.3333V9.33329ZM25.3333 25.3333H22.6667V22.6666H25.3333V25.3333ZM25.3333 20H22.6667V17.3333H25.3333V20Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);
