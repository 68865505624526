//Libs
import React from "react";
//Components
import IconButton from "@material-ui/core/IconButton";
import Icon from "./Icon";

const EyeIcon = ({ button, selected, onClick, fill }) => {
  return button ? (
    <IconButton onClick={onClick}>
      <Icon selected={selected} fill={fill} />
    </IconButton>
  ) : (
    <Icon onClick={onClick} fill={fill} selected={selected} />
  );
};

export default EyeIcon;
