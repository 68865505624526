import AbsAuthorization from "utils/ManagePermissions/manager";

export default class DashboardAuthorization extends AbsAuthorization {
  // eslint-disable-next-line no-useless-constructor
  constructor(profile) {
    super(profile);
  }

  checkCreateOrder() {
    return !!this.roles[this.checkL2Expert()];
  }
}
