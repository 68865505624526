//Libs
import React from "react";
import styled from "styled-components";
//Utils
import { formatNumber, isRecordSigned } from "../../../../../../utils/libs";
// Components
import { Span, Title } from "components/components";
//Styles
import "./PayCard.css";

const State = styled.div`
  margin: 12px 0px;
  padding: 4px 8px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  border-radius: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: background: rgba(0, 0, 0, 0.87);
  width: 98px;
  gap: 2px;
`;

const Header = ({ id, paidToName, created_at, signed, profile }) => {
  return (
    <div className="header">
      <div id="title">
        <Title>{paidToName}</Title>
      </div>
      <Span color="rgba(0, 0, 0, 0.6)">{id} - </Span>
      <Span color="rgba(0, 0, 0, 0.6)">{created_at}</Span>
      <div className="status-container">
        {!isRecordSigned(signed, profile.user.id) ? (
          <State>No firmado</State>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const Body = ({
  creatorName,
  entityName,
  paycutFromDate,
  paycutToDate,
  productionAmount,
  netPrice,
  templateName,
  stateLabel,
}) => {
  return (
    <div className="body">
      <div>
        <Span color="rgba(0, 0, 0, 0.6)">Creado por:</Span>
        <Span color="rgba(0, 0, 0, 0.6)">Desde:</Span>
        <Span color="rgba(0, 0, 0, 0.6)">Hasta:</Span>
        <Span color="rgba(0, 0, 0, 0.6)">Producción:</Span>
        <Span color="rgba(0, 0, 0, 0.6)">Neto:</Span>
        <Span color="rgba(0, 0, 0, 0.6)">Template:</Span>
        <Span color="rgba(0, 0, 0, 0.6)">Estado:</Span>
      </div>
      <div>
        <Span ellipsis>
          {creatorName} - {entityName}
        </Span>
        <Span>{paycutFromDate}</Span>
        <Span>{paycutToDate}</Span>
        <Span>{productionAmount}</Span>
        <Span>{netPrice}</Span>
        <Span>{templateName}</Span>
        <Span>{stateLabel}</Span>
      </div>
    </div>
  );
};

const Footer = ({
  paymentsManage,
  payIds,
  mutate1ObjectInPayments,
  getPaymentResume,
}) => {
  return (
    <div className="footer">
      <Span
        fontWeight="700"
        fontSize="14px"
        onClick={() => {
          mutate1ObjectInPayments("paymentsManage", {
            selected: { ...paymentsManage.selected, items: payIds },
          });
          mutate1ObjectInPayments("signPaymentModal", {
            isOpen: true,
            showSummary: true,
          });
          getPaymentResume(payIds);
        }}
      >
        RESUMEN
      </Span>
    </div>
  );
};

export default function PayCard(props) {
  const {
    id,
    production_amount,
    paid_amount,
    state_label,
    paid_to_name,
    creator_name,
    entity_name,
    paycut_from_date,
    paycut_to_date,
    template_name,
    coin_symbol,
    customProps,
    created_at,
    signed,
  } = props;
  const { getPaymentResume, paymentsManage, mutate1ObjectInPayments, profile } =
    customProps;
  const productionAmount = formatNumber.new(production_amount, coin_symbol, 2);
  const netPrice = formatNumber.new(paid_amount, coin_symbol, 2);

  const payIds = Array.isArray(id) ? [...id] : [id];
  return (
    <div className="container">
      <Header
        profile={profile}
        signed={signed}
        id={id}
        paidToName={paid_to_name}
        created_at={created_at}
      />
      <Body
        productionAmount={productionAmount}
        netPrice={netPrice}
        templateName={template_name}
        stateLabel={state_label}
        creatorName={creator_name}
        entityName={entity_name}
        paycutFromDate={paycut_from_date}
        paycutToDate={paycut_to_date}
      />
      <Footer
        paymentsManage={paymentsManage}
        payIds={payIds}
        mutate1ObjectInPayments={mutate1ObjectInPayments}
        getPaymentResume={getPaymentResume}
      />
    </div>
  );
}
