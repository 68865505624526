//Libs
import React from "react";
import { useTheme } from "styled-components";
//Utils
import ConsumeInventoryUtils from "./ConsumeInventoryUtils";
//Components
import {
  Wrapper,
  Title,
  LineDescription,
  Button,
  VerticalDividerIcon,
  Span,
  Confirm,
  LogoIcon,
} from "components/components";

const SummaryInventory = ({
  minHeight,
  articles,
  serialized,
  uninstallSerialized,
  canSign,
  isSigning,
  onConfirm,
  confirmTitle,
  confirmMessage,
  signButtonTitle,
}) => {
  const theme = useTheme();
  const [myState, setMyState] = React.useState({
    viewInSerialized: "amounts",
    viewInUninstallSerialized: "amounts",
    showConfirm: false,
  });
  const changeMyState = React.useCallback(
    (newState) => setMyState({ ...myState, ...newState }),
    [myState]
  );

  //Serialized rows
  const serializedRows = React.useMemo(
    () =>
      myState.viewInSerialized === "series"
        ? serialized
        : ConsumeInventoryUtils.getSegmentedSerieItemAmounts(serialized),
    [myState.viewInSerialized, serialized]
  );

  //Uninstall serialized rows
  const uninstallSerializedRows = React.useMemo(
    () =>
      myState.viewInUninstallSerialized === "series"
        ? uninstallSerialized
        : ConsumeInventoryUtils.getSegmentedSerieItemAmounts(
            uninstallSerialized
          ),
    [myState.viewInUninstallSerialized, uninstallSerialized]
  );

  return (
    <Wrapper
      minHeight={minHeight}
      flexDirection="column"
      alignItems="stretch"
      justifyContent="space-between"
      backgroundColor={theme.colors.background.high}
    >
      <div>
        {articles.length > 0 && (
          <Wrapper
            padding="0 0 10px 0"
            flexDirection="column"
            alignItems="stretch"
          >
            <Title>Materiales</Title>
            {articles.map((item, idx) => (
              <div key={idx}>
                <LineDescription
                  conceptEllipsis
                  concept={`${item.item_name} ${
                    item.serie ? ` [${item.serie}]` : ""
                  }`}
                  value={`${item.amount} ${
                    item.initialAmount && item.finalAmount
                      ? `[De ${parseInt(item.initialAmount)} a ${parseInt(
                          item.finalAmount
                        )}]`
                      : ""
                  }`}
                />
              </div>
            ))}
          </Wrapper>
        )}

        {serializedRows.length > 0 && (
          <Wrapper
            padding="0 0 10px 0"
            flexDirection="column"
            alignItems="stretch"
          >
            <Wrapper
              padding="0"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Title>Equipos</Title>
              <Wrapper padding="0" justifyContent="space-between">
                <Span
                  color={
                    myState.viewInSerialized === "amounts"
                      ? theme.colors.selected[500]
                      : undefined
                  }
                  onClick={() => changeMyState({ viewInSerialized: "amounts" })}
                >
                  Cantidades
                </Span>
                <VerticalDividerIcon />
                <Span
                  color={
                    myState.viewInSerialized === "series"
                      ? theme.colors.selected[500]
                      : undefined
                  }
                  onClick={() => changeMyState({ viewInSerialized: "series" })}
                >
                  Series
                </Span>
              </Wrapper>
            </Wrapper>
            {serializedRows.map(({ item_name, serie, amount }, idx) => (
              <div key={idx}>
                <LineDescription
                  conceptEllipsis
                  concept={item_name}
                  value={serie || amount}
                />
              </div>
            ))}
          </Wrapper>
        )}

        {uninstallSerializedRows.length > 0 && (
          <Wrapper
            padding="0 0 10px 0"
            flexDirection="column"
            alignItems="stretch"
          >
            <Wrapper
              padding="0"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Title>Desinstalados</Title>
              <Wrapper padding="0" justifyContent="space-between">
                <Span
                  color={
                    myState.viewInUninstallSerialized === "amounts"
                      ? theme.colors.selected[500]
                      : undefined
                  }
                  onClick={() =>
                    changeMyState({ viewInUninstallSerialized: "amounts" })
                  }
                >
                  Cantidades
                </Span>
                <VerticalDividerIcon />
                <Span
                  color={
                    myState.viewInUninstallSerialized === "series"
                      ? theme.colors.selected[500]
                      : undefined
                  }
                  onClick={() =>
                    changeMyState({ viewInUninstallSerialized: "series" })
                  }
                >
                  Series
                </Span>
              </Wrapper>
            </Wrapper>
            {uninstallSerializedRows.map(
              ({ item_name, serie, amount }, idx) => (
                <div key={idx}>
                  <LineDescription
                    conceptEllipsis
                    concept={item_name}
                    value={serie || amount}
                  />
                </div>
              )
            )}
          </Wrapper>
        )}
      </div>

      {canSign && (
        <>
          {isSigning ? (
            <Wrapper padding="0" justifyContent="center">
              <LogoIcon spin={true} />
            </Wrapper>
          ) : (
            <Button
              mode="primary"
              onClick={() => changeMyState({ showConfirm: true })}
            >
              {signButtonTitle}
            </Button>
          )}
        </>
      )}
      <Confirm
        title={confirmTitle}
        confirmMessage={confirmMessage}
        visible={myState.showConfirm}
        onCancel={() => changeMyState({ showConfirm: false })}
        onConfirm={() => {
          changeMyState({ showConfirm: false });
          onConfirm();
        }}
      />
    </Wrapper>
  );
};

export default SummaryInventory;
