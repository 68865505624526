import React from "react";

export default ({ fill = "black", fillOpacity = "0.54", onClick }) => (
  <svg
    onClick={onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="closeIcon24"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="5"
      y="5"
      width="14"
      height="14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 6.4L17.6 5L12 10.6L6.4 5L5 6.4L10.6 12L5 17.6L6.4 19L12 13.4L17.6 19L19 17.6L13.4 12L19 6.4Z"
        fill="white"
      />
    </mask>
    <g mask="url(#closeIcon24)">
      <rect width="24" height="24" fill={fill} fillOpacity={fillOpacity} />
    </g>
  </svg>
);
