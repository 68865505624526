//Libs
import React from "react";
import styled from "styled-components";
//Components
import { Wrapper } from "components/components";
import noConnectionImg from "./noConnection.svg";

const Label = styled.span`
  font-family: ${(props) => props.theme.noConnected.label.fontFamily};
  font-style: ${(props) => props.theme.noConnected.label.fontStyle};
  font-weight: ${(props) => props.theme.noConnected.label.fontWeight};
  font-size: ${(props) => props.theme.noConnected.label.fontSize};
  line-height: ${(props) => props.theme.noConnected.label.lineHeight};
  text-align: ${(props) => props.theme.noConnected.label.textAlign};
  color: ${(props) => props.theme.noConnected.label.color};
`;

const NoConnectedImage = styled.div`
  background-image: url(${noConnectionImg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 300px;
  height: 300px;
`;

const NoConnected = ({ message }) => (
  <Wrapper
    margin="50px 0 0 0"
    flexDirection="column"
    className="animated fadeIn"
  >
    <Label>{message}</Label>
    <NoConnectedImage />
  </Wrapper>
);

export default NoConnected;
