import styled from "styled-components";

const DashboardItem = styled.span`
  height: ${(props) => props.theme.dashboard.item.height};
  font-family: ${(props) => props.theme.dashboard.item.fontFamily};
  font-style: ${(props) => props.theme.dashboard.item.fontStyle};
  font-weight: ${(props) => props.theme.dashboard.item.fontWeight};
  font-size: ${(props) => props.theme.dashboard.item.fontSize};
  line-height: ${(props) => props.theme.dashboard.item.lineHeight};
  letter-spacing: ${(props) => props.theme.dashboard.item.letterSpacing};
  text-transform: ${(props) => props.theme.dashboard.item.textTransform};
  color: ${(props) => props.theme.dashboard.item.color};
`;

export default DashboardItem;
