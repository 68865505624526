//Libs
import {
  takeLatest,
  takeEvery,
  put,
  call,
  all,
  select,
  delay,
} from "redux-saga/effects";
import axios from "axios";
import _ from "underscore";
import moment from "moment";
import { List } from "immutable";
//Utils
import asyncErrorsHandler from "store/asyncErrorsHandler";
import AuthService from "utils/libs/auth/AuthService";
import GENERAL from "utils/constants/general";
import { getGeolocation } from "components/CoordManager/actions";
import { formatData, idbHandler } from "utils/libs";
import ORDER_KEYWORDS from "./keywords";

const { APIDATA, ORDERS, DASHBOARD, LOGIN } = GENERAL;
const auth = new AuthService();

//Selectors
const getProfile = (state) => state.login.profile;
const getControl = (state) => state.orders.control;
const getOrdersModal = (state) => state.orders.getOrdersModal;
const getInvoice = (state) => state.orders.invoice;
const getTemplates = (state) => List(state.api.templates);
const getUsers = (state) => state.api.users;

//******** WATCHER SAGAS ***********/

function* getOrdersWatcher() {
  yield takeLatest(ORDERS.GET_ORDERS, getOrdersWorker);
}

function* markOrderWatcher() {
  yield takeLatest(ORDERS.MARK_ORDER, markOrderWorker);
}

function* sendEventWatcher() {
  yield takeLatest(ORDERS.SEND_EVENT, sendEventWorker);
}

function* getOrderHistoryWatcher() {
  yield takeLatest(ORDERS.GET_ORDER_HISTORY, getOrderHistoryWorker);
}

function* getBillingMaterialsWatcher() {
  yield takeLatest(ORDERS.GET_BILLING_MATERIALS, getBillingMaterialsWorker);
}

function* getPhotoReportWatcher() {
  yield takeLatest(ORDERS.GET_PHOTO_REPORT, getPhotoReportWorker);
}

function* getOrderWatcher() {
  yield takeLatest(ORDERS.GET_ORDER, getOrderWorker);
}

function* makeDeepSearchWatcher() {
  yield takeLatest(ORDERS.MAKE_DEEP_SEARCH, makeDeepSearchWorker);
}

function* createNewInvoiceWatcher() {
  yield takeLatest(ORDERS.CREATE_NEW_INVOICE, createNewInvoiceWorker);
}

function* sendOnePhotoToBackendWatcher() {
  yield takeEvery(
    ORDERS.SEND_ONE_PHOTO_TO_BACKEND,
    sendOnePhotoToBackendWorker
  );
}

function* autoSyncPhotoReportsWatcher() {
  yield takeEvery(ORDERS.AUTO_SYNC_PHOTO_REPORTS, autoSyncPhotoReportsWorker);
}

function* sendInvoiceWatcher() {
  yield takeLatest(ORDERS.SEND_INVOICE, sendInvoiceWorker);
}

function* sendCommentReportWatcher() {
  yield takeLatest(ORDERS.SEND_COMMENT_REPORT, sendCommentReportWorker);
}

function* transferPhotoReportWatcher() {
  yield takeLatest(ORDERS.IMPORT_PHOTO_REPORT, transferPhotoReportWorker);
}

function* sendRoutingOrdersWatcher() {
  yield takeLatest(ORDERS.SEND_ROUTING_ORDERS, sendRoutingOrdersWorker);
}

function* completeOrderWatcher() {
  yield takeLatest(ORDERS.COMPLETE_ORDER, completeOrderWorker);
}

//******** WORKERS SAGAS ***********/

function* getOrdersWorker(action) {
  const profile = yield select(getProfile);
  if (action.payload) {
    var { category, fromDate, toDate } = action.payload;
  } else {
    const getOrdersModalData = yield select(getOrdersModal);
    var { fromDate, toDate, category } = getOrdersModalData;
  }

  try {
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: { obj1Name: "getOrdersModal", keyValuePairs: { loading: true } },
    });
    yield put({ type: APIDATA.GET_GAINS });
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(
        `/orders/exp/${category}/${fromDate.format(
          "YYYY-MM-DD"
        )}/${toDate.format("YYYY-MM-DD")}`
      ),
      auth.sendToken()
    );
    const orders = response.data.map((record) =>
      formatData(
        record,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    );
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: { obj1Name: "control", keyValuePairs: { orders } },
    });
    if (
      category !== "billed" &&
      category !== "auditedOrder" &&
      category !== "routing"
    ) {
      idbHandler.setOrders(orders);
    }
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "getOrdersModal",
        keyValuePairs: { isOpen: false, loading: false },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: ORDERS.MUTATE_1OBJECT,
          payload: {
            obj1Name: "getOrdersModal",
            keyValuePairs: { loading: false },
          },
        });
      },
      function* () {
        yield getOrdersWorker(action);
      }
    );
  }
}

function* markOrderWorker(action) {
  const { orderId, markType } = action.payload;
  const control = yield select(getControl);

  function* updateLocalOrders() {
    let orders = control.orders.map((order) => {
      if (order.order_id === orderId) {
        if (!order.props) order.props = {};
        order.props.seen = true;
      }
      return order;
    });
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: { obj1Name: "control", keyValuePairs: { orders } },
    });
    idbHandler.setOrders(orders);
  }

  try {
    yield updateLocalOrders();

    yield call(
      axios.put,
      process.env.REACT_APP_API_URL.concat(`/orders/mark`),
      { orderId, markType },
      auth.sendToken()
    );
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield markOrderWorker(action);
    });
  }
}

function* sendEventWorker(action) {
  const coords = yield call(getGeolocation);
  if (coords === "DENIED") {
    yield put({
      type: DASHBOARD.TOAST_MESSAGE,
      payload: {
        message: "Por favor active el GPS de su dispositivo",
        type: "warn",
      },
    });
    return;
  }

  const getOrdersModalData = yield select(getOrdersModal);
  const { category, fromDate, toDate } = getOrdersModalData;
  const sendEventModal = action.payload;
  const event = {
    eventId: sendEventModal.eventId,
    reprogramDate: moment(sendEventModal.reprogramDate).format("YYYY-MM-DD"),
    reprogramTime: moment(sendEventModal.reprogramTime).format("HH:mm"),
    comment: sendEventModal.comment,
    coords,
  };

  try {
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: { obj1Name: "sendEventModal", keyValuePairs: { loading: true } },
    });
    yield call(
      axios.post,
      process.env.REACT_APP_API_URL.concat(
        `/orders/event/${sendEventModal.orderId}`
      ),
      event,
      auth.sendToken()
    );
    yield put({ type: ORDERS.RESET_EVENT_MODAL });
    yield put({
      type: ORDERS.GET_ORDERS,
      payload: { category, fromDate, toDate },
    });
    yield put({
      type: DASHBOARD.TOAST_MESSAGE,
      payload: { description: "Evento reportado!", type: "success" },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: ORDERS.MUTATE_1OBJECT,
          payload: {
            obj1Name: "sendEventModal",
            keyValuePairs: { loading: false },
          },
        });
      },
      function* () {
        yield sendEventWorker(action);
      }
    );
  }
}

function* getOrderHistoryWorker(action) {
  const orderId = action.payload;
  const profile = yield select(getProfile);

  try {
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "getOrderInformationModal",
        keyValuePairs: { loading: true, orderId },
      },
    });
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(`/orders/history/${orderId}`),
      auth.sendToken()
    );
    const history = response.data.map((record) =>
      formatData(
        record,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    );
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "getOrderInformationModal",
        keyValuePairs: { history, loading: false },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: ORDERS.MUTATE_1OBJECT,
          payload: {
            obj1Name: "getOrderInformationModal",
            keyValuePairs: { history: [], loading: false },
          },
        });
      },
      function* () {
        yield getOrderHistoryWorker(action);
      }
    );
  }
}

function* getBillingMaterialsWorker(action) {
  const orderId = action.payload;
  const profile = yield select(getProfile);
  const users = yield select(getUsers);

  try {
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "getOrderInformationModal",
        keyValuePairs: { loading: true },
      },
    });
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(
        `/warehouses_transactions_docs/getBillingMaterials/${orderId}`
      ),
      auth.sendToken()
    );
    const billingReport = {
      id: response.data.id,
      order_id: response.data.order_id,
      inventory:
        response.data.inventory &&
        response.data.inventory.map((record) =>
          formatData(
            record,
            profile.organization.tz,
            profile.user.settings.date_format
          )
        ),
      comments:
        response.data.comments &&
        response.data.comments.map((record) =>
          formatData(
            record,
            profile.organization.tz,
            profile.user.settings.date_format,
            users
          )
        ),
      completed: response.data.completed,
    };
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "getOrderInformationModal",
        keyValuePairs: { billingReport, loading: false },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getBillingMaterialsWorker(action);
    });
  }
}

function* getPhotoReportWorker(action) {
  const order_id = action.payload;
  const profile = yield select(getProfile);
  const users = yield select(getUsers);

  try {
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "getOrderInformationModal",
        keyValuePairs: { loading: true },
      },
    });
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(`/photo_reports/${order_id}`),
      auth.sendToken()
    );
    const photoReport = {
      path: response.data.path,
      comments:
        response.data.comments &&
        response.data.comments.map((record) =>
          formatData(
            record,
            profile.organization.tz,
            profile.user.settings.date_format,
            users
          )
        ),
    };
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "getOrderInformationModal",
        keyValuePairs: { photoReport, loading: false },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getPhotoReportWorker(action);
    });
  }
}

function* getOrderWorker(action) {
  const profile = yield select(getProfile);
  const control = yield select(getControl);
  const orders = [...control.orders];
  const orderId = action.payload;

  try {
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "getOrderInformationModal",
        keyValuePairs: { loading: true },
      },
    });
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(`/orders/order/exp/v1/${orderId}`),
      auth.sendToken()
    );
    const order = [response.data].map((record) =>
      formatData(
        record,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    )[0];

    if (order) {
      const idx = _.findIndex(orders, (ord) => ord.order_id === order.order_id);
      if (idx === -1) orders.push(order);
      else if (idx !== -1) orders[idx] = order;

      idbHandler.setOrders(orders);
      yield put({
        type: ORDERS.MUTATE_1OBJECT,
        payload: { obj1Name: "control", keyValuePairs: { orders } },
      });
    }
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "getOrderInformationModal",
        keyValuePairs: { loading: false },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: ORDERS.MUTATE_1OBJECT,
          payload: {
            obj1Name: "getOrderInformationModal",
            keyValuePairs: { loading: false },
          },
        });
      },
      function* () {
        yield getOrderWorker(action);
      }
    );
  }
}

function* makeDeepSearchWorker(action) {
  const profile = yield select(getProfile);
  const control = yield select(getControl);
  const searchInData = { ...control.searchInData, data: "" };
  const data = action.payload;

  try {
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: { obj1Name: "getOrdersModal", keyValuePairs: { loading: true } },
    });
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(
        `/orders/deep-search/exp/v1/${data}`
      ),
      auth.sendToken()
    );
    const orders = response.data.map((record) =>
      formatData(
        record,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    );
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: { obj1Name: "control", keyValuePairs: { orders, searchInData } },
    });
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "getOrdersModal",
        keyValuePairs: { loading: false },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: ORDERS.MUTATE_1OBJECT,
          payload: {
            obj1Name: "getOrdersModal",
            keyValuePairs: { loading: false },
          },
        });
      },
      function* () {
        yield makeDeepSearchWorker(action);
      }
    );
  }
}

function* createNewInvoiceWorker(action) {
  const profile = yield select(getProfile);
  const { order_id, department_id } = action.payload;
  const myAssignTemplateId =
    profile?.user?.assignment?.templates?.photoWorkedOrder?.[department_id];
  const myAssignTemplateIdNum = parseInt(myAssignTemplateId);

  yield put({ type: ORDERS.RESET_INVOICE });

  const invoiceGetted = yield select(getInvoice);
  const invoices = yield call(idbHandler.getInvoices);
  const idx = _.findIndex(invoices, (inv) => inv.order_id === order_id);
  const templatesGetted = yield select(getTemplates);
  const template = templatesGetted.toJS().filter((record) => {
    if (!isNaN(myAssignTemplateIdNum)) {
      return record.id === myAssignTemplateId;
    } else {
      return (
        record.template_type_id === 1 && record.department_id === department_id
      );
    }
  })[0];

  if (!template) {
    yield put({
      type: DASHBOARD.TOAST_MESSAGE,
      payload: {
        message: "Plantilla de reporte fotográfico no encontrada",
        type: "warn",
      },
    });
    return;
  }

  if (idx === -1) {
    var invoice = {
      ...invoiceGetted,
      order_id,
      photoReport: {
        ...invoiceGetted.photoReport,
        template_id: template.id,
        frames: template.data_structure_object.frames,
      },
    };
    invoices.unshift(invoice);
    idbHandler.setInvoices(invoices);
  } else {
    var invoice = invoices[idx];
  }

  yield put({ type: ORDERS.MUTATE_DIRECT_PROPS, payload: { invoice } });
}

function* sendOnePhotoToBackendWorker(action) {
  const getOrdersModalData = yield select(getOrdersModal);
  const { category, fromDate, toDate } = getOrdersModalData;
  const { photo, invoice } = action.payload;
  const data = {
    id: photo.id,
    order_id: invoice.order_id,
    template_id: invoice.photoReport.template_id,
    compressFormat: photo.compressFormat,
    src: photo.src,
    coords: photo.coords,
    comment: invoice.photoReport.comment,
    totalPhotoRequired: invoice.photoReport.frames.reduce((acc, frame) => {
      if (frame.required) return ++acc;
    }, 0),
  };

  try {
    yield put({
      type: ORDERS.SEND_ONE_PHOTO_TO_BACKEND_STATE,
      payload: { status: "sending", photoId: photo.id },
    });
    const response = yield call(
      axios.post,
      process.env.REACT_APP_API_URL.concat(`/photo_reports/photo`),
      data,
      auth.sendToken()
    );
    const { order_id, photoId, billCompleted, photoReportCompleted } =
      response.data;

    yield put({
      type: ORDERS.SEND_ONE_PHOTO_TO_BACKEND_STATE,
      payload: { status: "saved", photoId },
    });
    if (billCompleted && photoReportCompleted) {
      idbHandler.deleteInvoice(order_id);
      yield put({
        type: ORDERS.GET_ORDERS,
        payload: { category, fromDate, toDate },
      });
      yield put({ type: LOGIN.GET_MY_PROFILE });
    }
  } catch (err) {
    yield put({
      type: ORDERS.SEND_ONE_PHOTO_TO_BACKEND_STATE,
      payload: { status: "retry", photoId: photo.id },
    });
    yield asyncErrorsHandler(err, undefined, undefined);
  }
}

function* autoSyncPhotoReportsWorker() {
  const getOrdersModalData = yield select(getOrdersModal);
  const control = yield select(getControl);
  const { category, fromDate, toDate } = getOrdersModalData;
  const invoices = yield call(idbHandler.getInvoices);

  yield put({
    type: ORDERS.MUTATE_1OBJECT,
    payload: {
      obj1Name: "control",
      keyValuePairs: { synchronizingPhotos: true },
    },
  });

  const invoicesToSave = [];
  let deletedInvoices = 0;

  for (let invoice of invoices) {
    let billCompleted, photoReportCompleted;

    for (let photo of invoice.photoReport.frames) {
      if (photo.status !== "saved") {
        try {
          const data = {
            id: photo.id,
            order_id: invoice.order_id,
            template_id: invoice.photoReport.template_id,
            compressFormat: photo.compressFormat,
            src: photo.src,
            coords: photo.coords,
            comments: null,
            totalPhotoRequired: invoice.photoReport.frames.reduce(
              (acc, frame) => {
                if (frame.required) return ++acc;
              },
              0
            ),
          };
          const response = yield call(
            axios.post,
            process.env.REACT_APP_API_URL.concat(`/photo_reports/photo`),
            data,
            auth.sendToken()
          );

          billCompleted = response.data.billCompleted;
          photoReportCompleted = response.data.photoReportCompleted;

          photo.status = "saved";

          yield put({
            type: ORDERS.MUTATE_1OBJECT,
            payload: {
              obj1Name: "control",
              keyValuePairs: { photosNeededSync: --control.photosNeededSync },
            },
          });
          yield delay(1000);
        } catch (err) {
          console.log(err);
        }
      }
    }
    if (!billCompleted || !photoReportCompleted) invoicesToSave.push(invoice);
    else deletedInvoices++;
  }
  idbHandler.setInvoices(invoicesToSave);
  if (deletedInvoices > 0) {
    yield put({
      type: ORDERS.GET_ORDERS,
      payload: { category, fromDate, toDate },
    });
    yield put({ type: LOGIN.GET_MY_PROFILE });
  }
  yield put({
    type: ORDERS.MUTATE_1OBJECT,
    payload: {
      obj1Name: "control",
      keyValuePairs: { synchronizingPhotos: false },
    },
  });
}

function* sendInvoiceWorker(action) {
  const getOrdersModalData = yield select(getOrdersModal);
  const { category, fromDate, toDate } = getOrdersModalData;
  const { invoice, now } = action.payload;
  const body = {
    order_id: invoice.order_id,
    completed_code_id: invoice.completedCodeModal.completed_code_id || null,
    comment:
      invoice.completedCodeModal.comment &&
      invoice.completedCodeModal.comment !== ""
        ? invoice.completedCodeModal.comment.trim()
        : null,
    completedAuthorizationCode:
      invoice.completedCodeModal.completedAuthorizationCode || null,
    articles: invoice.articles,
    serialized: invoice.serialized,
    uninstalled: invoice.uninstalled.reduce((acc, item) => {
      acc.push({
        item_id: item.item_id,
        serie: item.serie,
      });
      return acc;
    }, []),
  };

  try {
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: { obj1Name: "invoice", keyValuePairs: { status: "sending" } },
    });

    const response = yield call(
      axios.post,
      process.env.REACT_APP_API_URL.concat("/billings"),
      body,
      auth.sendToken()
    );
    const { orderId, billCompleted, photoReportCompleted } = response.data;

    yield put({ type: ORDERS.RESET_INVOICE });

    if (billCompleted && photoReportCompleted) {
      idbHandler.deleteInvoice(orderId);
      yield put({
        type: DASHBOARD.TOAST_MESSAGE,
        payload: {
          message: `Orden ${orderId}. Facturada correctamente (${now.format(
            "DD/MM/YYYY HH:mm"
          )})`,
          type: "success",
          autoClose: 300000,
        },
      });
    } else if (!billCompleted) {
      yield put({
        type: DASHBOARD.TOAST_MESSAGE,
        payload: {
          message: `Orden ${orderId}. Se requiere validación de materiales (${now.format(
            "DD/MM/YYYY HH:mm"
          )})`,
          type: "warn",
          autoClose: 300000,
        },
      });
    } else if (!photoReportCompleted) {
      yield put({
        type: DASHBOARD.TOAST_MESSAGE,
        payload: {
          message: `Orden ${orderId}. Registros fotográficos pendientes (${now.format(
            "DD/MM/YYYY HH:mm"
          )})`,
          type: "warn",
          autoClose: 300000,
        },
      });
    }
    yield put({
      type: ORDERS.GET_ORDERS,
      payload: { category, fromDate, toDate },
    });
    yield put({ type: APIDATA.GET_INVENTORY_TO_INVOICE });
    yield put({ type: LOGIN.GET_MY_PROFILE });
  } catch (err) {
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: { obj1Name: "invoice", keyValuePairs: { status: "invoice" } },
    });
    yield asyncErrorsHandler(err, undefined, function* () {
      yield put({
        type: DASHBOARD.TOAST_MESSAGE,
        payload: {
          message: "Sin conexión. Inténtalo mas tarde",
          type: "warn",
          onClose: 3000,
        },
      });
    });
  }
}

function* sendCommentReportWorker(action) {
  const { comment, order_id, report } = action.payload;
  const body = { comment, order_id };

  try {
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "getOrderInformationModal",
        keyValuePairs: { sendingComment: true },
      },
    });
    if (report === "billingReport") {
      yield call(
        axios.post,
        process.env.REACT_APP_API_URL.concat("/billings/v1/comments/add"),
        body,
        auth.sendToken()
      );
      yield put({ type: ORDERS.GET_BILLING_MATERIALS, payload: order_id });
    } else if (report === "photoReport") {
      yield call(
        axios.post,
        process.env.REACT_APP_API_URL.concat("/photo_reports/comments/add"),
        body,
        auth.sendToken()
      );
      yield put({ type: ORDERS.GET_PHOTO_REPORT, payload: order_id });
    }
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "getOrderInformationModal",
        keyValuePairs: {
          sendingComment: false,
          newComment: undefined,
          viewComments: true,
        },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, function* () {
      yield put({
        type: ORDERS.MUTATE_1OBJECT,
        payload: {
          obj1Name: "getOrderInformationModal",
          keyValuePairs: { sendingComment: false },
        },
      });
    });
  }
}

function* transferPhotoReportWorker(action) {
  const getOrdersModalData = yield select(getOrdersModal);
  const { category, fromDate, toDate } = getOrdersModalData;
  const { canceledOrderId, newOrderId } = action.payload;

  try {
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "importPhotoReport",
        keyValuePairs: { loading: true },
      },
    });
    const response = yield call(
      axios.put,
      process.env.REACT_APP_API_URL.concat(`/photo_reports/v1/transfer`),
      { canceledOrderId, newOrderId },
      auth.sendToken()
    );
    const path = response.data;
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "importPhotoReport",
        keyValuePairs: { loading: false, isOpen: false, path },
      },
    });
    yield put({
      type: ORDERS.GET_ORDERS,
      payload: { category, fromDate, toDate },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, function* () {
      yield put({
        type: ORDERS.MUTATE_1OBJECT,
        payload: {
          obj1Name: "importPhotoReport",
          keyValuePairs: { loading: false },
        },
      });
    });
  }
}

function* sendRoutingOrdersWorker(action) {
  const getOrdersModalData = yield select(getOrdersModal);
  const { fromDate, toDate } = getOrdersModalData;
  const routedOrders = action.payload;

  try {
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: { obj1Name: "control", keyValuePairs: { routing: true } },
    });
    yield call(
      axios.put,
      process.env.REACT_APP_API_URL.concat(
        `/orders/v1/exp/updateRoutingOrders`
      ),
      routedOrders,
      auth.sendToken()
    );
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "getOrdersModal",
        keyValuePairs: { category: "totalTracking" },
      },
    });
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: { obj1Name: "control", keyValuePairs: { routing: false } },
    });
    yield put({
      type: ORDERS.GET_ORDERS,
      payload: { category: "totalTracking", fromDate, toDate },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, function* () {
      yield put({
        type: ORDERS.MUTATE_1OBJECT,
        payload: { obj1Name: "control", keyValuePairs: { routing: false } },
      });
    });
  }
}

function* completeOrderWorker(action) {
  const data = action.payload;

  try {
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "completeOrderControl",
        keyValuePairs: { status: ORDER_KEYWORDS.COMPLETE_ORDER.STATUS.LOADING },
      },
    });
    yield call(
      axios.put,
      process.env.REACT_APP_API_URL.concat(`/orders/v1/completeOrder`),
      data,
      auth.sendToken()
    );
    yield put({
      type: ORDERS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "completeOrderControl",
        keyValuePairs: { status: ORDER_KEYWORDS.COMPLETE_ORDER.STATUS.SUCCESS },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: ORDERS.MUTATE_1OBJECT,
          payload: {
            obj1Name: "completeOrderControl",
            keyValuePairs: {
              status: ORDER_KEYWORDS.COMPLETE_ORDER.STATUS.ERROR,
            },
          },
        });
      },
      function* () {
        yield completeOrderWorker(action);
      }
    );
  }
}

//  Export default Root Saga
export default function* rootSaga() {
  yield all([
    getOrdersWatcher(),
    markOrderWatcher(),
    sendEventWatcher(),
    getOrderHistoryWatcher(),
    getBillingMaterialsWatcher(),
    getPhotoReportWatcher(),
    createNewInvoiceWatcher(),
    sendOnePhotoToBackendWatcher(),
    autoSyncPhotoReportsWatcher(),
    sendInvoiceWatcher(),
    sendCommentReportWatcher(),
    transferPhotoReportWatcher(),
    sendRoutingOrdersWatcher(),
    getOrderWatcher(),
    makeDeepSearchWatcher(),
    completeOrderWatcher(),
  ]);
}
