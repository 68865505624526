//Libs
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
//Services
import { SentryService } from "services";
//Utils
import { idbHandler } from "utils/libs";
import GENERAL from "utils/constants/general";
//Actions
import * as ordersActions from "./actions";
import * as apiActions from "store/api/actions";
import * as collectorActions from "components/components/Collector/actions";
import * as dashboardActions from "components/containers/Dashboard/actions";
import { mutateConsumeInventoryControl } from "components/components/ConsumeInventory/actions";
//Selectors
import { selectInventoryValidationTemplate } from "components/components/ConsumeInventory/selectors";
import { selectLinked } from "../Dashboard/selectors";
//Components
import { FormBuilder } from "core/FormBuilder";
import { WrapperView } from "components/components";
//Styles
import "./index.css";

const { ENV } = GENERAL;
const actions = {
  //ORDERS
  ...ordersActions,
  //API
  getMyTemplates: apiActions.getMyTemplates,
  getInventoryToInvoice: apiActions.getInventoryToInvoice,
  getInventoryToUninstall: apiActions.getInventoryToUninstall,
  getOrderEvents: apiActions.getOrderEvents,
  mutateDirectPropsInApi: apiActions.mutateDirectProps,
  mutate1ObjectInApi: apiActions.mutate1Object,
  getCompletedCodes: apiActions.getCompletedCodes,
  //DASHBOARD
  sendToast: dashboardActions.sendToast,
  //COLLECTOR
  mutate1ObjectInCollector: collectorActions.mutate1Object,
  updateCollectorRequiredValidation:
    collectorActions.updateCollectorRequiredValidation,
  mutateConsumeInventoryControl,
};

class Orders extends Component {
  constructor(...props) {
    super(...props);

    this.loadIndexedDB = this.loadIndexedDB.bind(this);
  }

  /*********************************** LIFECYCLES **************************************/
  componentDidMount() {
    this.loadIndexedDB();
    this.props.getOrders(this.props.getOrdersModal);
    this.props.getMyTemplates();
    this.props.getInventoryToInvoice();
    this.props.getInventoryToUninstall();
    this.props.getCompletedCodes();
    this.props.getOrderEvents();
    idbHandler.photosNeededSync().then((counter) => {
      if (counter > 0)
        this.props.mutate1Object("control", { photosNeededSync: counter });
    });
  }

  componentWillUnmount() {
    this.props.doReset();
  }

  componentDidCatch(error, errorInfo) {
    SentryService.sendError(error, { errorInfo });
  }

  /*********************************** FUNCTIONS **************************************/
  loadIndexedDB() {
    const {
      mutate1Object,
      mutateDirectPropsInApi,
      mutate1ObjectInApi,
      toInvoice,
    } = this.props;

    //Load orders
    idbHandler
      .getOrders()
      .then((orders) => mutate1Object("control", { orders }));
    //Load templates
    idbHandler
      .getTemplates()
      .then((templates) => mutateDirectPropsInApi({ templates }));
    //Load toInvoice
    idbHandler
      .getToInvoiceInventory()
      .then((data) =>
        mutate1ObjectInApi("warehouse", { toInvoice: { ...toInvoice, data } })
      );
    //Load toUninstall
    idbHandler
      .getToUninstallInventory()
      .then((toUninstall) => mutate1ObjectInApi("warehouse", { toUninstall }));
    //Load completedCodes
    idbHandler
      .getCompletedCodes()
      .then((completedCodes) => mutateDirectPropsInApi({ completedCodes }));
  }

  render() {
    const { history, routes } = this.props;

    if (history.location.pathname === ENV.ROUTES.PATHS.ORDERS) {
      return <Redirect to={ENV.ROUTES.PATHS.ORDERS_MANAGE} />;
    }

    const withoutSubToolbarHeight =
      history.location.pathname === ENV.ROUTES.PATHS.ORDERS_COMPLETE;

    return (
      <WrapperView withoutSubToolbarHeight={withoutSubToolbarHeight}>
        {routes.map((route, i) => {
          return (
            <Route
              key={i}
              path={route.path}
              render={(oProps) => (
                <route.component
                  {...oProps}
                  {...this.props}
                  routes={route.routes}
                />
              )}
            />
          );
        })}
        <FormBuilder />
      </WrapperView>
    );
  }
}

export default connect(
  (state) => ({
    //ORDERS
    ...state.orders,
    //-------------------------------------EXTERNAL PROPS------------------------------------
    //API
    users: state.api.users,
    events: state.api.events,
    inventoryValidationTemplate: selectInventoryValidationTemplate(state),
    //LOGIN
    profile: state.login.profile,
    //DASHBOARD
    linked: selectLinked(state),
  }),
  actions
)(Orders);
