export default class AbsAuthorization {
  static _ROLES = require("./roles");

  constructor(profile) {
    if (new.target === AbsAuthorization) {
      throw new Error("You cann't instantiate an abstract class");
    }
    this.roles = profile?.user?.roles || {};
  }

  checkL2Expert() {
    return AbsAuthorization._ROLES.EXPERT.L2_EXPERT;
  }
}
