const defaultFormat = ({ countryName, principalSubdivision, locality }) =>
  `${countryName} - ${principalSubdivision} - ${locality}`;

const geocode = async ({ latitude, longitude }, lang = "es") => {
  const url = `${process.env.REACT_APP_GEOCODE_API}?latitude=${latitude}&longitude=${longitude}&localityLanguage=${lang}`;
  return fetch(url)
    .then((res) => res?.json())
    .then(defaultFormat)
    .catch(() => undefined);
};

export default geocode;
