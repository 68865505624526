//Libs
import React from "react";
import { connect } from "react-redux";
//Actions
import { getMyProfile } from "components/containers/Login/actions";
import { sendToast } from "components/containers/Dashboard/actions";
import { resetOrderInformation } from "components/containers/Orders/actions";
import { mutateConsumeInventoryControl } from "components/components/ConsumeInventory/actions";
import { doReset as doResetCollector } from "components/components/Collector/actions";
//Selectors
import { selectTemplates } from "store/api/selectors";
import {
  getOrderInformation,
  selectOrderFromOrderInformation,
} from "components/containers/Orders/selectors";
import {
  selectConsumedItemsResume,
  selectControlledInventory,
  selectEditableInventory,
  selectIsFetching,
  selectIsFetchingWTD,
  selectWTD,
} from "components/components/ConsumeInventory/selectors";
//Utils
import CollectorUtils from "components/components/Collector/CollectorUtils";
//Components
import {
  Wrapper,
  WrapperView,
  Tabs,
  Drawer,
  CollectorOptionList,
  ConsumeInventoryOptionList,
} from "components/components";
import {
  CustomToolbar,
  BackComponent,
} from "components/containers/Dashboard/subs/Toolbar";
//Tabs
import {
  ConsumedInventoryTab,
  DetailsTab,
  HistoryEventTab,
  ReviewsTab,
} from "./Tabs";
//Styles
import "./index.css";

const { TabWrap, TabPane } = Tabs;

const HeadGetOrderInformationModal = ({
  getOrderInformationModal,
  order,
  setOut,
  onClose,
  mutateConsumeInventoryControl,
}) => {
  const { activeTab } = getOrderInformationModal;
  return (
    <CustomToolbar
      title={order.odt_id || order.order_id}
      left={
        <BackComponent
          handleOnClick={() => {
            setOut(true);
            setTimeout(() => onClose(), 400);
            mutateConsumeInventoryControl({ editableInventory: false });
          }}
        />
      }
      right={
        <Wrapper height="56px" padding="0">
          {/* INVENTORY */}
          {activeTab === "3" && <ConsumeInventoryOptionList order={order} />}

          {/* REVIEWS */}
          {activeTab === "5" && <CollectorOptionList />}
        </Wrapper>
      }
    />
  );
};

const OrderInformation = ({
  //Props
  handleOnClickOrderInformationTab,
  //Redux
  order,
  wtd,
  isFetching,
  isFetchingWTD,
  editableInventory,
  consumedItemsResume,
  controlledInventory,
  getOrderInformationModal,
  profile,
  templates,
  mutateConsumeInventoryControl,
  resetOrderInformation,
  doResetCollector,
}) => {
  const { isOpen, activeTab, history } = getOrderInformationModal;
  const [out, setOut] = React.useState(false);

  React.useEffect(() => {
    if (out) setTimeout(() => setOut(false), 800);
  }, [out]);

  return !order ? null : (
    <Drawer
      key={"orderInformation"}
      className={out ? "animated slideOutDown faster" : ""}
      width={`${document.documentElement.clientWidth}px`}
      height="100%"
      placement={"bottom"}
      closable={false}
      visible={isOpen}
      title={
        <HeadGetOrderInformationModal
          getOrderInformationModal={getOrderInformationModal}
          order={order}
          setOut={setOut}
          onClose={() => {
            resetOrderInformation();
            CollectorUtils.leftFromCollectorEcosystem(doResetCollector);
          }}
          mutateConsumeInventoryControl={mutateConsumeInventoryControl}
        />
      }
    >
      <WrapperView withToolbarHeight>
        <TabWrap
          tabPosition="top"
          activeKey={activeTab}
          smallTabs
          onChange={(activeKey) => {
            handleOnClickOrderInformationTab({
              order,
              wtd,
              activeKey,
              activeTab: getOrderInformationModal.activeTab,
            });
          }}
        >
          <TabPane tab="Detalle" key="1">
            <DetailsTab order={order} templates={templates} profile={profile} />
          </TabPane>
          <TabPane tab="Historial" key="2">
            <HistoryEventTab
              history={history}
              loading={history.length === 0 && getOrderInformationModal.loading}
            />
          </TabPane>
          <TabPane tab="Reportería" key="5">
            <ReviewsTab order={order} />
          </TabPane>
          {!controlledInventory && (
            <TabPane tab="Inventario" key="3">
              <ConsumedInventoryTab
                isFetching={isFetching && isFetchingWTD}
                wtd={wtd}
                order={order}
                consumedItemsResume={consumedItemsResume}
                editableInventory={editableInventory}
              />
            </TabPane>
          )}
        </TabWrap>
      </WrapperView>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  order: selectOrderFromOrderInformation(state),
  wtd: selectWTD(state),
  isFetching: selectIsFetching(state),
  isFetchingWTD: selectIsFetchingWTD(state),
  editableInventory: selectEditableInventory(state),
  consumedItemsResume: selectConsumedItemsResume(state),
  controlledInventory: selectControlledInventory(state),
  getOrderInformationModal: getOrderInformation(state),
  profile: getMyProfile(state),
  templates: selectTemplates(state),
});
const actions = {
  mutateConsumeInventoryControl,
  resetOrderInformation,
  sendToast,
  doResetCollector,
};
export default connect(mapStateToProps, actions)(OrderInformation);
