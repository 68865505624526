//Libs
import styled from "styled-components";
//Components
import { Modal as _Modal } from "antd";

const Modal = styled(_Modal)`
  .ant-modal-title {
    /* color: white; */
  }
  .ant-modal-close-x {
    /* width: 50px;
    height: 50px;
    line-height: 50px; */
    color: ${(props) => props.theme.colors.text.medium};
    display: ${(props) =>
      props.display ? props.display : "none"}; /* Hide the close icon */
  }
  .ant-modal-header {
    padding: ${(props) => props.padding || "16px 10px"};
    borderbottom: ${(props) => props.borderBottom || "1px solid #e8e8e8"};
    /* height: 50px;
     border-bottom: 1px solid #2c2c36; */
  }
  .ant-modal-body {
    min-height: 90px;
    padding: 0px;
  }
  .ant-modal-content {
    width: ${(props) => (props.width ? props.width : "")};
  }
`;

export default Modal;
