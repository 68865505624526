//Libs
import React, { useMemo } from "react";
import { connect } from "react-redux";
//Actions
import * as dashboardActions from "components/containers/Dashboard/actions";
//Selectors
import { selectNotificationControl } from "components/containers/Dashboard/selectors";
import { selectSynchronizingPhotos } from "components/containers/Orders/selectors";
//Components
import { Wrapper, Drawer, BackIcon, Title } from "components/components";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Badge } from "antd";
import NotificationList from "./NotificationList";
import {
  CustomToolbar,
  BackComponent,
} from "components/containers/Dashboard/subs/Toolbar";

//Notifications Component
export const ToolbarNotifications = ({
  //Props
  selectNotification,
  //State
  notificationControl,
  synchronizingPhotos,
  //Actions
  mutate1ObjectInDashboard,
  getNotifications,
}) => {
  const { show, isFetching, notifications } = notificationControl;
  const [out, setOut] = React.useState(false);

  React.useEffect(() => {
    if (out) setTimeout(() => setOut(false), 800);
  }, [out]);

  const numberUnreadNotifications = useMemo(() => {
    return notifications.reduce((acc, notification) => {
      if (!notification.seen) acc++;
      return acc;
    }, 0);
  }, [notifications]);

  return (
    <Wrapper padding="0">
      <Drawer
        key={"top"}
        className={out ? "animated slideOutUp faster" : ""}
        width={`${document.documentElement.clientWidth}px`}
        height="100%"
        placement={"top"}
        closable={false}
        visible={show}
        title={
          <CustomToolbar
            left={
              <BackComponent
                handleOnClick={() =>
                  mutate1ObjectInDashboard("notificationControl", {
                    show: !show,
                  })
                }
              />
            }
            title="Notificaciones"
          />
        }
      >
        <NotificationList
          isFetching={isFetching}
          notifications={notifications}
          selectNotification={selectNotification}
        />
      </Drawer>

      <IconButton
        disabled={synchronizingPhotos}
        onClick={() => {
          if (!show) getNotifications();
          mutate1ObjectInDashboard("notificationControl", {
            show: !show,
          });
        }}
      >
        <Badge count={numberUnreadNotifications} overflowCount={99}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  notificationControl: selectNotificationControl(state),
  synchronizingPhotos: selectSynchronizingPhotos(state),
});

const mapDispatchToProps = (dispatch) => ({
  mutate1ObjectInDashboard: (obj1Name, keyValuePairs) =>
    dispatch(dashboardActions.mutate1Object(obj1Name, keyValuePairs)),
  getNotifications: () => dispatch(dashboardActions.getNotifications()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ToolbarNotifications));
