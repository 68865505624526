// Libs
import { writeFile, read, utils } from "xlsx";

function validateInput(event) {
  if (!event.target || !event.target.files || event.target.files.length === 0) {
    event.target.value = "";
    throw new Error(
      "No se encontró ningún archivo. Por favor vuelva a intentarlo"
    );
  }
  return event.target.files;
}

function getExcelFormat(file) {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(
        new DOMException(
          `Tuve un problema al procesar el archivo. Por favor intentelo nuevamente`
        )
      );
    };

    fileReader.onload = (e) => {
      resolve(fileReader.result);
    };

    fileReader.readAsBinaryString(file);
  });
}

function parseExcelToJson(excelFile) {
  try {
    const workbook = read(excelFile, { type: "binary" });
    const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
    return utils.sheet_to_json(firstSheet, { headers: 1 });
  } catch (err) {
    throw new Error(
      "Tuve un problema al procesar el archivo. Por favor intentelo nuevamente"
    );
  }
}

export default class ExcelUtils {
  static exportToExcelMultiSheet(fileName, sheets) {
    //sheets: Array de objetos json que contienen 2 propiedades:
    //  name: Nombre de la hoja
    //  data: Array de los datos

    //Example: [{name: nombreHoja1, data: [{...}, {...}]}, {name: nombreHoja1, data: [{...}, {...}]}]

    const wb = utils.book_new();

    sheets.forEach((sheet) => {
      utils.book_append_sheet(wb, utils.json_to_sheet(sheet.data), sheet.name);
    });

    writeFile(wb, `${fileName}.xlsx`);
  }
  static exportOrdersToExcel(data, fileName, orderPrefix) {
    data = data.reduce((acc, row) => {
      acc.push({
        Orden: `${orderPrefix}${row.odt_id}`,
        ["Comentario Evento"]: row.event_comment,
        Evento: row.event_name,
        ["Programada Syn4pse"]: row.formatReprogramAt,
        ["Codigo de Trabajo"]: row.work_code,
        Fecha: row.formatExecutionAt,
        Cliente: row.client_name,
        Provincia: row.city,
        Canton: row.township,
        Zona: row.zone,
        ["Comentarios ETA"]: row.fault_notes,
        Tecnico: row.expert_name,
        Entidad: row.entity_name,
        Servicio: row.service,
        Posicion: row.att_position,
        ["Pos. Actualizada"]: row.formatLastAttPositionAt,
        ["Fecha Evento"]: row.formatLastEventAt,
        ["Estado ETA"]: row.order_state,
        ["Programada ETA"]: row.formatProgramAt,
        Reservada: row.formatReservationAt,
        Dilacion: row.dilation,
        ["Rango ETA"]: row.external_range,
        // ['Rango Syn4pse']: row.internal_range,
        Seguimiento: row.expertTrackingState,
        Creada: row.created_at,
        Prioridad: row.priority && row.priority.label,
        ["Razon de Prioridad"]: row.priority && row.priority.reason,
      });

      return acc;
    }, []);

    this.exportToExcelMultiSheet(fileName, [
      {
        name: fileName,
        data,
      },
    ]);
  }
  static exportStockSeries = (data, fileName) => {
    data = data.reduce((acc, row, idx) => {
      acc.push({
        ["Nº"]: idx + 1,
        ["Código"]: row.item_code,
        ["Nombre"]: row.item_name,
        ["Serie"]: row.serie,
      });

      return acc;
    }, []);

    this.exportToExcelMultiSheet(fileName, [
      {
        name: fileName,
        data,
      },
    ]);
  };
  static readExcel(event, latestFile) {
    event.preventDefault();
    return new Promise(async (resolve, reject) => {
      if (!event.target.value) {
        resolve(null);
      }
      try {
        const files = validateInput(event);

        if (latestFile) {
          const fileName = files[files.length - 1].name;
          const file = files.item(files.length - 1);
          const excelFile = await getExcelFormat(file);
          const jsonFile = parseExcelToJson(excelFile);
          resolve({ fileName, jsonFile });
        } else {
          const jsonFiles = [];
          for (let i = 0; i < files.length; i++) {
            const file = files.item(i);
            const excelFile = await getExcelFormat(file);
            const jsonFile = parseExcelToJson(excelFile);
            jsonFiles.push(jsonFile);
          }
          resolve(jsonFiles);
        }
      } catch (err) {
        reject(err);
      }
    });
  }
}
