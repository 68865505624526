//Libs
import React from "react";
import { Link } from "react-router-dom";
//Utils
import GENERAL from "utils/constants/general";
import { SourcePhotos } from "components/AppConfig.json";
//Components
import { Card } from "antd";
import {
  Wrapper,
  Subtitle,
  Caption,
  ArrowRightIcon,
  Button,
  Icon,
  Avatar,
  Flag,
  DeleteIcon,
} from "components/components";
//Styles
import "./TransactionCard.css";

const { ENV } = GENERAL;
const { Meta } = Card;

function TransactionCard(props) {
  const {
    id,
    doc_number,
    owarehouse_name,
    dwarehouse_name,
    creator_name,
    created_at,
    completed,
    created_by,
    customProps,
  } = props;
  const {
    mutate1Object,
    getItemsTransactionDocs,
    profile,
    transactionsManage,
    deleteTransaction,
  } = customProps;
  const canDelete = !completed && created_by === profile.user.id;

  return (
    <Meta
      id="transaction_meta-card-container"
      avatar={
        <Avatar
          bgTransparent
          fit
          size="large"
          avatar={{
            src: SourcePhotos.concat(
              `/syn4pse/users/avatars/${created_by}.jpg`
            ),
          }}
        />
      }
      title={
        <Wrapper padding="0" justifyContent="space-between" height="50px">
          <Wrapper padding="0" flexDirection="column" alignItems="flex-start">
            {!completed && <Flag absolute unsuccess height="30px" />}
            <Wrapper padding="0">
              <Subtitle
                ellipsis
                width={canDelete ? "200px" : "220px"}
                whiteSpace="nowrap"
              >
                {creator_name}
              </Subtitle>
            </Wrapper>
            <Caption light>
              {doc_number} - {created_at}
            </Caption>
          </Wrapper>
          {canDelete && (
            <>
              {transactionsManage.deleting ? (
                <Icon type="loading" />
              ) : (
                <DeleteIcon onClick={() => deleteTransaction(id)} />
              )}
            </>
          )}
        </Wrapper>
      }
      description={
        <>
          <Wrapper
            width="100%"
            padding="0 10px"
            position="absolute"
            left="0"
            justifyContent="space-around"
          >
            <Wrapper padding="0" flexDirection="column" alignItems="baseline">
              <Caption light>Origen</Caption>
              <Subtitle ellipsis maxWidth="110px" whiteSpace="nowrap">
                {owarehouse_name}
              </Subtitle>
            </Wrapper>
            <ArrowRightIcon />
            <Wrapper padding="0" flexDirection="column" alignItems="baseline">
              <Caption light>Destino</Caption>
              <Subtitle ellipsis maxWidth="110px" whiteSpace="nowrap">
                {dwarehouse_name}
              </Subtitle>
            </Wrapper>
          </Wrapper>
          <Wrapper
            width="100%"
            padding="0 20px"
            position="absolute"
            left="0"
            bottom="5px"
            justifyContent="flex-end"
          >
            <Link
              to={ENV.ROUTES.PATHS.WAREHOUSE_TRANSACTIONS_ITEMS}
              onClick={() => {
                mutate1Object("transactionsManage", { docId: id });
                getItemsTransactionDocs(id);
              }}
            >
              <Button mode="primary">Resumen</Button>
            </Link>
          </Wrapper>
        </>
      }
    />
  );
}

export default React.memo(TransactionCard);
