// Services
import { ApiServiceV2 } from "services";

const useCollectorApi = () => {
  return {
    //Upload binary file
    uploadFile: (formData) =>
      ApiServiceV2.post({
        url: `/collectorManager/v1/uploadFile`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
  };
};

export default useCollectorApi;
