//Libs
import React from "react";
//Components
import { Wrapper, Avatar } from "components/components";

const AvatarCarousel = ({
  size,
  selectedAvatarId,
  avatarList,
  skeleton,
  spin,
  onClick,
}) => {
  const skeletonItems = [{}, {}, {}, {}, {}];
  const avatars = skeleton ? skeletonItems : avatarList;

  return (
    <Wrapper padding="0 0 0 10px" overflow="auto">
      {avatars.map((avatar, idx) => (
        <div key={idx} onClick={() => onClick(avatar)}>
          <Avatar
            avatar={avatar}
            skeleton={skeleton}
            spin={spin}
            size={size}
            selectedAvatarId={selectedAvatarId}
          />
        </div>
      ))}
    </Wrapper>
  );
};

export default AvatarCarousel;
