//Libs
import React from "react";
//Utils
import GENERAL from "utils/constants/general";
//Components
import { Card } from "antd";
import {
  Wrapper,
  Caption,
  Title,
  Select,
  Chip,
  BackIcon,
} from "components/components";
import CheckIcon from "components/components/CheckIcon";

const { ENV } = GENERAL;

const { Meta } = Card;
const { Option } = Select;

const SelectItems = ({
  items,
  filteredSelectedItemIds,
  onFocus,
  onCheck,
  mutate1Object,
  changeMyState,
}) => (
  <Wrapper padding="0" justifyContent="space-between">
    <BackIcon
      margin="0 10px 0 0"
      onClick={() => changeMyState({ seeSelectorItems: false })}
    />
    <Select
      onFocus={onFocus}
      mode="multiple"
      style={{ width: "200px" }}
      value={filteredSelectedItemIds}
      placeholder="Seleccione los Items..."
      maxTagCount={1}
      onChange={(newValue) =>
        mutate1Object("stockSeriesManage", {
          selectedItemIds: newValue,
        })
      }
    >
      {items.map(({ id, name }) => (
        <Option key={id} value={id}>
          {name}
        </Option>
      ))}
    </Select>
    <CheckIcon margin="0 0 0 10px" onClick={onCheck} />
  </Wrapper>
);

const Chips = ({ changeMyState, onClickPeps }) => (
  <Wrapper padding="0">
    <Chip
      label="Activos"
      margin="0 10px 0 0"
      onClick={() => changeMyState({ seeSelectorItems: true })}
    />
    <Chip label="PEPS" onClick={onClickPeps} />
  </Wrapper>
);

function StockFamilyItemCard({ id: familyId, name, items, customProps }) {
  const [myState, changeMyState] = React.useState({ seeSelectorItems: false });
  const {
    selectedItemIds,
    selectedFamilyId,
    mutate1Object,
    mutate1ObjectInStockSeriesManage,
    getStockSeries,
  } = customProps;

  //On Focus
  const onSelectFamilyId = React.useCallback(
    (fId) =>
      mutate1Object("stockSeriesManage", {
        selectedFamilyId: fId,
        selectedItemIds: fId === selectedFamilyId ? selectedItemIds : [],
      }),
    [selectedFamilyId, selectedItemIds]
  );

  //On Blur
  const onSeeSeries = React.useCallback((get, fId) => {
    if (fId)
      mutate1ObjectInStockSeriesManage("series", {
        get,
        isOpen: true,
      });
  }, []);

  //Filtered selected item ids
  const filteredSelectedItemIds = React.useMemo(
    () => (familyId !== selectedFamilyId ? [] : selectedItemIds),
    [familyId, selectedFamilyId, selectedItemIds]
  );

  return (
    <Meta
      id="meta-card-container"
      title={
        <Wrapper
          padding="0"
          width="300px"
          height="60px"
          flexDirection="column"
          alignItems="stretch"
        >
          <Title width="280px" ellipsis>
            {name}
          </Title>
          <Caption margin="0 0 0 2px">ID: {familyId}</Caption>
        </Wrapper>
      }
      description={
        <Wrapper padding="5px 10px">
          {myState.seeSelectorItems ? (
            <SelectItems
              items={items}
              filteredSelectedItemIds={filteredSelectedItemIds}
              changeMyState={changeMyState}
              mutate1Object={mutate1Object}
              onFocus={() => onSelectFamilyId(familyId)}
              onCheck={() =>
                onSeeSeries(
                  ENV.INVENTORY.SERIALIZED.GET.ITEMS,
                  selectedFamilyId
                )
              }
            />
          ) : (
            <Chips
              changeMyState={changeMyState}
              onClickPeps={() => {
                onSelectFamilyId(familyId);
                onSeeSeries(ENV.INVENTORY.SERIALIZED.GET.PEPS, familyId);
                getStockSeries({
                  get: ENV.INVENTORY.SERIALIZED.GET.PEPS,
                  familyId,
                  limit: 200,
                });
              }}
            />
          )}
        </Wrapper>
      }
    />
  );
}
export default React.memo(StockFamilyItemCard);
