//Libs
import React from "react";
//Components
import { Card } from "antd";
import { Wrapper, Caption, Title, Flag } from "components/components";

const { Meta } = Card;

function StockSerieItemCard({
  serie,
  item_name,
  item_code,
  fifo_amount,
  fifoOk,
  position,
  customProps,
}) {
  const { mutate1ObjectInApi, getSerieHistory } = customProps;
  return (
    <Meta
      id="meta-card-container"
      title={
        <Wrapper
          padding="0"
          width="300px"
          height="60px"
          flexDirection="column"
          alignItems="stretch"
        >
          {fifoOk && <Flag absolute success height="30px" />}
          <Wrapper padding="0">
            <Title
              ellipsis
              margin="0 20px 0 0"
              width="230px"
              onClick={() => {
                mutate1ObjectInApi("serieHistory", { isOpen: true });
                getSerieHistory(serie);
              }}
            >
              {serie}
            </Title>
            {fifoOk && (
              <Caption>
                {position}/{fifo_amount}
              </Caption>
            )}
          </Wrapper>
          <Caption>{item_name}</Caption>
          <Caption>ID: {item_code}</Caption>
        </Wrapper>
      }
    />
  );
}
export default React.memo(StockSerieItemCard);
