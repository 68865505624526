//Libs
import { all } from "redux-saga/effects";
//Sagas
import login from "components/containers/Login/saga";
import signup from "components/containers/Signup/saga";
import api from "./api/saga";
import dashboard from "components/containers/Dashboard/saga";
import orders from "components/containers/Orders/saga";
import payments from "components/containers/Payments/saga";
import warehouse from "components/containers/Warehouse/saga";
import collector from "components/components/Collector/saga";
import uploadResource from "components/components/UploadResource/saga";
import manageInventory from "components/components/ConsumeInventory/saga";
import modules from "components/components/Modules/saga";
import feed from "core/saga";

//  Method 1:
export default function* rootSaga() {
  yield all([
    login(),
    signup(),
    api(),
    dashboard(),
    orders(),
    payments(),
    warehouse(),
    collector(),
    uploadResource(),
    manageInventory(),
    modules(),
    feed(),
  ]);
}

//  Method 2:
// function startSagas(...sagas) {
//   return function* rootSaga() {
//     yield sagas.map(saga => fork(saga))
//   }
// }

// const rootSaga = startSagas(login)

// export default rootSaga
