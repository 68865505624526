//Libs
import React from "react";
//Components
import { Card } from "antd";
import {
  Wrapper,
  Caption,
  Title,
  LineDescription,
} from "components/components";

const { Meta } = Card;

function StockArticleItemCard({
  item_name,
  item_code,
  amount,
  amount_transit,
  serie,
}) {
  return (
    <Meta
      id="meta-card-container"
      title={
        <Wrapper
          padding="0"
          width="100%"
          height="60px"
          flexDirection="column"
          alignItems="stretch"
        >
          <Title width="280px" ellipsis>
            {item_name}
          </Title>
          <Wrapper padding="0" justifyContent="space-between">
            <Caption margin="0 0 0 2px">ID: {item_code}</Caption>
            {serie && <Caption margin="0 0 0 2px">Serie: {serie}</Caption>}
          </Wrapper>
        </Wrapper>
      }
      description={
        <Wrapper
          padding="5px 10px"
          width="300px"
          height="70px"
          flexDirection="column"
          alignItems="flex-start"
        >
          <LineDescription concept="Disponible" value={amount} />
          <LineDescription concept="Tránsito" value={amount_transit} />
        </Wrapper>
      }
    />
  );
}

export default React.memo(StockArticleItemCard);
