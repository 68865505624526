// Utils
import { picaCompress, PhotonImage, PhotoUtils } from "utils/libs";

const picaStrategy = async (
  img,
  { width, height, compressFormat, quality }
) => {
  console.log("Compressed by: Pica");
  return picaCompress(img, compressFormat, quality, {
    width,
    height,
  });
};
const photonStrategy = async (
  img,
  { width, height, samplingFilter, compressFormat }
) => {
  console.log("Compressed by: Photon");
  return PhotonImage.getInstance().then((photon) =>
    photon.compress(img, {
      resizeWidth: width,
      resizeHeight: height,
      samplingFilter,
      format: compressFormat,
    })
  );
};
const STRATEGIES = {
  PICA: "pica",
  PHOTON: "photon",
};
const STRATEGY_FLOWS = {
  [STRATEGIES.PICA]: picaStrategy,
  [STRATEGIES.PHOTON]: photonStrategy,
};

export default class Zipper {
  constructor(strategy, authConfig) {
    this.authConfig = authConfig;
    this.strategy = strategy;
  }
  static getStrategies() {
    return STRATEGIES;
  }
  async zip(img) {
    const strategyFLow = STRATEGY_FLOWS[this.strategy];
    if (!strategyFLow)
      return PhotoUtils.isImageElement(img)
        ? img.src
        : PhotoUtils.isCanvasElement(img) && img.toDataURL();
    return strategyFLow(img, this.authConfig);
  }
}
