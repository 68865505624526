// Libs
import React from "react";
//Components
import { SettingsIcon } from "components/components/Icons";

const ControlPanelOpenButton = ({ onControlPanelOpen }) => (
  <SettingsIcon color="white" onClick={onControlPanelOpen} />
);

export default ControlPanelOpenButton;
