//Libs
import Immutable from "immutable";
//Utils
import sortBy from "utils/libs/orderBy";
import { normalize } from "utils/libs";
import { GlobalUtils } from "utils";

export default class Dynamics {
  static getReferencedCollector(c, ref) {
    return (
      c.serviceTaskId === ref.serviceTaskId &&
      c.reviewId === ref.reviewId &&
      (!ref.groupId || ref.groupId === c.groupId) &&
      (!ref.subgroupId || ref.subgroupId === c.subgroupId) &&
      (c.collectorId || c.id) === (ref.collectorId || ref.id)
    );
  }
  static checkFilteredCollector(filter, collectorValue, collectorLayout) {
    if (!filter) return;

    const getNormalizedValue = (value) =>
      normalize(String(value)).toLowerCase();

    const checkByClvIds = () => {
      if (!filter.clvIds?.length) return;
      const review = collectorLayout.find(
        (review) =>
          review.id === filter.reviewId &&
          review.serviceTaskId === filter.serviceTaskId
      );
      const collector = review?.collectors.find(
        (c) =>
          c.id === filter.collectorId &&
          (!c.subgroupId || c.subgroupId === filter.subgroupId) &&
          (!c.groupId || c.groupId === filter.groupId)
      );
      const clv = collector?.listValues?.find(
        (lv) =>
          getNormalizedValue(lv.name) === getNormalizedValue(collectorValue)
      );
      return !!filter.clvIds.find((fclv) => fclv === clv?.id);
    };
    const checkByValue = () => {
      return !!filter.values?.find(
        (v) => getNormalizedValue(v) === getNormalizedValue(collectorValue)
      );
    };
    return checkByClvIds() || checkByValue();
  }
  static getReferencedFilter(filters, collector) {
    return filters.find(
      (f) =>
        f.serviceTaskId === collector.serviceTaskId &&
        f.reviewId === collector.reviewId &&
        (!f.groupId || f.groupId === collector.groupId) &&
        (!f.subgroupId || f.subgroupId === collector.subgroupId)
    );
  }
  static getReferencedCollectorValue(filter, collectorValues) {
    if (!filter) return;
    const referencedCollectorValue = collectorValues.find((cv) =>
      this.getReferencedCollector(cv, filter)
    );
    if (!referencedCollectorValue) return;
    return referencedCollectorValue.value;
  }
  static getAbsoluteReferences(
    references,
    { collectorLayout, collectors },
    { rKey = "rliId", cKey = "layoutId" } = {}
  ) {
    if (!references) return [];
    const absoluteReferences = [];

    const findReferencedCollector = (ref, collectors) =>
      collectors.find((c) => c[cKey] === ref[rKey]);
    const addReference = (ref, c) =>
      absoluteReferences.push({
        ...ref,
        collectorId: c.id,
        subgroupId: c.subgroupId,
        groupId: c.groupId,
        reviewId: c.reviewId,
        serviceTaskId: c.serviceTaskId,
      });

    const inCollectorLayout = () => {
      for (let ref of references) {
        for (let review of collectorLayout) {
          const refCollector = findReferencedCollector(ref, review.collectors);
          if (!!refCollector) {
            addReference(ref, refCollector);
            break;
          }
        }
      }
    };
    const inCollectors = () => {
      for (let ref of references) {
        const refCollector = findReferencedCollector(ref, collectors);
        if (!!refCollector) addReference(ref, refCollector);
      }
    };

    if (collectorLayout) inCollectorLayout();
    else if (collectors) inCollectors();

    return absoluteReferences;
  }
  static getReferencedCollectors(
    references,
    { collectors, collectorLayout },
    { level, poolReference } = {}
  ) {
    const referencedCollectors = [];

    const findReferencedCollector = (ref, collectors) =>
      collectors.find((c) => this.getReferencedCollector(c, ref));
    const addCollector = (c, props = {}) =>
      referencedCollectors.push({
        ...props,
        id: c.id,
        name: c.name,
        subgroupId: c.subgroupId,
        subgroupName: c.subgroupName,
        groupId: c.groupId,
        groupName: c.groupName,
        reviewId: c.reviewId,
        reviewName: c.reviewName,
        serviceTaskId: c.serviceTaskId,
        layoutId: c.layoutId,
        listValues: c.listValues,
      });

    const inCollectorLayout = () => {
      for (let ref of references) {
        for (let review of collectorLayout) {
          const refCollector = findReferencedCollector(ref, review.collectors);
          if (!!refCollector) {
            addCollector(refCollector);
            break;
          }
        }
      }
    };
    const inCollectors = () => {
      for (let ref of references) {
        const refCollector = findReferencedCollector(ref, collectors);
        if (!!refCollector) addCollector(refCollector);
      }
    };
    const inPoolCollectors = () => {
      for (let c of collectors) {
        const reference = c[poolReference]?.find(
          (ref) =>
            String(ref.level).toLowerCase() === String(level).toLowerCase()
        );
        if (reference) addCollector(c, { ...reference });
      }

      sortBy(referencedCollectors, "object", { objectKey: "sort" });
    };

    if (collectorLayout && references) inCollectorLayout();
    else if (collectors && level && poolReference) inPoolCollectors();
    else if (collectors && references) inCollectors();

    return referencedCollectors;
  }
  static getReferencedCollectorValues(
    references,
    collectorValues,
    { inheritReferenceProps } = {}
  ) {
    if (!references) return [];
    const referencedCollectorValues = [];
    for (let ref of references) {
      const referencedCollectorValue = collectorValues.find((cv) =>
        this.getReferencedCollector(cv, ref)
      );
      if (!referencedCollectorValue?.value) continue;

      const inheritedReferenceProps =
        typeof inheritReferenceProps === "function"
          ? inheritReferenceProps(ref)
          : {};
      referencedCollectorValues.push({
        ...inheritedReferenceProps,
        serviceTaskId: referencedCollectorValue.serviceTaskId,
        reviewId: referencedCollectorValue.reviewId,
        groupId: referencedCollectorValue.groupId,
        subgroupId: referencedCollectorValue.subgroupId,
        collectorId: referencedCollectorValue.collectorId,
        layoutId: referencedCollectorValue.layoutId,
        value: referencedCollectorValue.value,
      });
    }
    return referencedCollectorValues;
  }
  static getAutoCalculatedCollectorValue(
    references,
    referencedCollectors,
    referencedCollectorValues
  ) {
    if (
      !references.length ||
      !referencedCollectors.length ||
      !referencedCollectorValues.length
    )
      return;

    const operateCalculator = (
      calculator,
      temporalCalculatedValue,
      referencedCollectorValue
    ) => {
      const temporalNumberValue = GlobalUtils.checkNumber(
        temporalCalculatedValue
      );
      const referencedNumberValue = GlobalUtils.checkNumber(
        referencedCollectorValue.value
      );

      const operate = {
        "+": () => (temporalNumberValue + referencedNumberValue).toFixed(2),
        "-": () => (temporalNumberValue - referencedNumberValue).toFixed(2),
        "*": () => (temporalNumberValue * referencedNumberValue).toFixed(2),
        "/": () => (temporalNumberValue / referencedNumberValue).toFixed(2),
        "**": () => (temporalNumberValue ** referencedNumberValue).toFixed(2),
        fn: () =>
          GlobalUtils.executeStringifyCustomFunction(
            calculator.fn,
            {
              accValueRef: temporalCalculatedValue,
              currentValueRef: referencedCollectorValue.value,
            },
            temporalCalculatedValue
          ),
        concat: () =>
          String(referencedCollectorValue.value) +
          String(temporalCalculatedValue),
      }[calculator.opt];

      if (!operate)
        return temporalCalculatedValue || referencedCollectorValue.value;
      return operate();
    };

    const autoCalcCount = references.length;
    let autoCalcPassCount = 0;
    let temporalCalculatedValue;

    return referencedCollectors.reduce(
      (calculatedValue, referencedCollector) => {
        const referencedCollectorValue = referencedCollectorValues.find((rcv) =>
          this.getReferencedCollector(referencedCollector, rcv)
        );
        if (
          referencedCollectorValue?.value === undefined ||
          referencedCollectorValue?.value === null ||
          referencedCollectorValue?.value === ""
        )
          return;

        references.map((calculator) => {
          if (this.getReferencedCollector(referencedCollector, calculator)) {
            temporalCalculatedValue = operateCalculator(
              calculator,
              temporalCalculatedValue,
              referencedCollectorValue
            );
            autoCalcPassCount++;
          }
        });
        if (autoCalcPassCount === autoCalcCount)
          calculatedValue = temporalCalculatedValue;

        return calculatedValue;
      },
      undefined
    );
  }
  static replaceDuplicatedReferences(references, collector) {
    if (!references) return [];
    let _references = Immutable.List(references).toJS();

    const isDuplicated = (value) =>
      String(value).includes("#") && !String(value).endsWith("#1");
    const mutateReference = ({ rkey, ckey }) => {
      _references = _references.map((ref) => {
        ref[rkey] = collector[ckey];
        return ref;
      });
    };

    if (isDuplicated(collector.reviewName))
      mutateReference({ rkey: "reviewId", ckey: "reviewId" });
    if (isDuplicated(collector.groupName))
      mutateReference({ rkey: "groupId", ckey: "groupId" });
    if (isDuplicated(collector.subgroupName))
      mutateReference({ rkey: "subgroupId", ckey: "subgroupId" });
    if (isDuplicated(collector.name))
      mutateReference({ rkey: "collectorId", ckey: "id" });

    return _references;
  }
}
