//Libs
import React from "react";
import styled from "styled-components";
//Components
import icon from "./from-av4cus-text.svg";

const Container = styled.div`
  position: absolute;
  bottom: -100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Text = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.54);
`;
const Icon = styled.div`
  background-image: url(${icon});
  width: 82px;
  height: 24px;
`;

export default () => (
  <Container>
    <Text>from</Text>
    <Icon />
  </Container>
);
