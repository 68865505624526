//Libs
import React from 'react';
import { Card } from 'antd';
//Styles
import './OrderHistoryCard.css';
import './BillingMaterialCard.css';

const { Meta } = Card;

export default function BillingMaterialCard(props) {
  const { item_id, item_name, item_code, amount, serie, wtd_items_props, wtd_items_edit, wtd_items_created_at, wtd_items_updated_at } = props;

  return (
    <Meta
      id="meta-card-container"
      title={
        <div className="container-title-card">
          <span className="card-creator">{item_name}</span>
          <span className="card-created-at">{wtd_items_created_at}</span>
        </div>
      }
      description={
        <div className="billing_mat-container-description-card scrollable">
          <div className="billing_mat-container-data">
            <div className="card-container-item"><div className="card-concept">Código: <b>{item_code || item_id}</b></div></div>
            {amount && <div className="card-container-item"><div className="card-concept">Cantidad: <b>{amount}</b></div></div>}
            {serie && <div className="card-container-item"><div className="card-concept">Serie: <b>{serie}</b></div></div>}
            {wtd_items_updated_at !== wtd_items_created_at && <div><span className="card-concept">Actualizado: </span><span className="card-value">{wtd_items_updated_at}</span></div>}
          </div>
          <div>
            {wtd_items_props && wtd_items_props.reject && <div><span className="card-concept">Validación: </span><q className="card-value">{wtd_items_props.reason}</q></div>}
          </div>
        </div>
      }
    />
  )
}