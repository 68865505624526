//Libs
import React from "react";
import { useTheme } from "styled-components";
//Utils
import { formatDate } from "utils/libs/dateFormats";
//Components
import { Span } from "components/components";

const NOT_CONFIGURED = "No configurado";

const StartAt = ({ startAt, profile }) => {
  const theme = useTheme();
  const value = !startAt ? NOT_CONFIGURED : formatDate(startAt, profile);
  return (
    <Span
      size="m"
      color={value === NOT_CONFIGURED ? theme.colors.text.disabled : undefined}
    >
      {value}
    </Span>
  );
};

export default StartAt;
