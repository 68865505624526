//Libs
import moment from "moment-timezone";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
import relativeTime from "dayjs/plugin/relativeTime";

import { capitalize } from ".";
import lsHandler from "../libs/localStorage/utils";
//Utils
import { getNameByUserId } from ".";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.locale("es");

const profile = lsHandler.get("synapse_profile"),
  defaultDateFormat = "YYYY-MM-DD",
  defaultDateTimeFormat = "YYYY-MM-DD HH:mm";

export const toMyTimezone = (utcDatetime, tz) => moment.tz(utcDatetime, tz);

export function toUTC({ date, time, datetime, tz }) {
  let dateFormatted, timeFormatted, dateTimeFormatted, myTimezone;

  if (datetime) {
    dateTimeFormatted = moment(datetime).format("YYYY-MM-DD HH:mm");
    myTimezone = moment.tz(dateTimeFormatted, tz);
    return myTimezone.utc();
  } else if (date && time) {
    dateFormatted = moment(date).format("YYYY-MM-DD");
    timeFormatted = moment(time).format("HH:mm");
    dateTimeFormatted = `${dateFormatted} ${timeFormatted}`;
    myTimezone = moment.tz(dateTimeFormatted, tz);
    return myTimezone.utc();
  }
  return null;
}

export function unifyDatetime(date, time, tz) {
  const dateFormatted = moment(date).format("YYYY-MM-DD"),
    timeFormatted = moment(time, "HH:mm").format("HH:mm"),
    dateTimeFormatted = `${dateFormatted} ${timeFormatted}`,
    myTimezone = moment.tz(dateTimeFormatted, tz);

  return myTimezone.format("YYYY-MM-DD HH:mm");
}

export function formatData(record, timezone, dateFormat, users = []) {
  const tz = timezone || profile.organization.tz;
  const date_format = dateFormat || profile.user.settings.date_format;

  //User
  if (record.created_at)
    record.created_at = toMyTimezone(record.created_at, tz).format(
      date_format || defaultDateFormat
    );
  if (record.createdAt)
    record.createdAt = toMyTimezone(record.createdAt, tz).format(
      date_format || defaultDateFormat
    );
  if (record.updated_at)
    record.updated_at = toMyTimezone(record.updated_at, tz).format(
      date_format || defaultDateFormat
    );
  if (record.application_status_at)
    record.application_status_at = toMyTimezone(
      record.application_status_at,
      tz
    ).format(date_format || defaultDateFormat);
  if (record.actived_at)
    record.actived_at = toMyTimezone(record.actived_at, tz).format(
      date_format || defaultDateFormat
    );
  if (record.last_logged_at)
    record.last_logged_at = toMyTimezone(record.last_logged_at, tz).format(
      date_format || defaultDateFormat
    );
  //Order
  if (record.execution_at)
    record.formatExecutionAt = toMyTimezone(record.execution_at, tz).format(
      date_format || defaultDateFormat
    );
  if (record.reservation_at)
    record.formatReservationAt = toMyTimezone(record.reservation_at, tz).format(
      date_format || defaultDateFormat
    );
  if (record.program_at)
    record.formatProgramAt = toMyTimezone(record.program_at, tz).format(
      date_format || defaultDateFormat
    );
  if (record.reprogram_at)
    record.formatReprogramAt = toMyTimezone(record.reprogram_at, tz).format(
      date_format || defaultDateFormat
    );
  if (record.last_event_at)
    record.formatLastEventAt = toMyTimezone(record.last_event_at, tz).format(
      date_format || defaultDateFormat
    );
  if (record.last_att_position_at)
    record.formatLastAttPositionAt = toMyTimezone(
      record.last_att_position_at,
      tz
    ).format(date_format || defaultDateFormat);
  if (record.client_name)
    record.client_name = capitalize(record.client_name, true);
  if (record.zone) record.zone = capitalize(record.zone, true);
  if (record.address) record.address = capitalize(record.address, true);
  //Billings
  if (record.from_date)
    record.from_date = moment(record.from_date).format(
      date_format || defaultDateTimeFormat
    );
  if (record.to_date)
    record.to_date = moment(record.to_date).format(
      date_format || defaultDateTimeFormat
    );
  if (record.paycut_from_date)
    record.paycut_from_date = toUTC({
      datetime: record.paycut_from_date,
      tz,
    }).format("YYYY-MM-DD");
  if (record.paycut_to_date)
    record.paycut_to_date = toUTC({
      datetime: record.paycut_to_date,
      tz,
    }).format("YYYY-MM-DD");
  if (record.amount) record.amount = Number(record.amount);
  if (record.bonus) record.bonus = Number(record.bonus);
  //Order History
  if (record.details && record.details.reprogramAt)
    record.details.reprogramAt = toMyTimezone(
      record.details.reprogramAt,
      tz
    ).format(date_format || defaultDateFormat);
  //Billing Material
  if (record.wtd_items_created_at)
    record.wtd_items_created_at = toMyTimezone(
      record.wtd_items_created_at,
      tz
    ).format(date_format || defaultDateFormat);
  if (record.wtd_items_updated_at)
    record.wtd_items_updated_at = toMyTimezone(
      record.wtd_items_updated_at,
      tz
    ).format(date_format || defaultDateFormat);
  if (record.comment && record.user_id)
    record.user_name = getNameByUserId(record.user_id, users);
  if (record.comment && record.entity_id)
    record.entity_name = getNameByUserId(record.entity_id, users);
  //City || Township || Zone
  if (record.name) record.name = capitalize(record.name, true);

  return record;
}

export function convertWarehousesDatesToMyTimezone(record, tz, date_format) {
  record.created_at =
    record.created_at &&
    toMyTimezone(record.created_at, tz).format(
      date_format || defaultDateFormat
    );
  record.updated_at =
    record.updated_at &&
    toMyTimezone(record.updated_at, tz).format(
      date_format || defaultDateFormat
    );
  return record;
}

export function formatArticlesData(record, tz, date_format) {
  record.created_at =
    record.created_at &&
    toMyTimezone(record.created_at, tz).format(
      date_format || defaultDateFormat
    );
  record.updated_at =
    record.updated_at &&
    toMyTimezone(record.updated_at, tz).format(
      date_format || defaultDateFormat
    );
  record.priceFormatted =
    record.price && `${record.coin_symbol} ${record.price}`;
  record.ivFormatted = record.iv && `${record.iv}%`;
  return record;
}

export function convertTransactionDocsDates(record, tz, date_format) {
  record.created_at =
    record.created_at &&
    toMyTimezone(record.created_at, tz).format(
      date_format || defaultDateFormat
    );
  record.updated_at =
    record.updated_at &&
    toMyTimezone(record.updated_at, tz).format(
      date_format || defaultDateFormat
    );
  return record;
}

export function convertStockDates(record, tz, date_format) {
  record.created_at =
    record.created_at &&
    toMyTimezone(record.created_at, tz).format(
      date_format || defaultDateFormat
    );
  record.updated_at =
    record.updated_at &&
    toMyTimezone(record.updated_at, tz).format(
      date_format || defaultDateFormat
    );
  return record;
}

export function convertPaymentsDates(record, tz, date_format) {
  record.created_at =
    record.created_at &&
    toMyTimezone(record.created_at, tz).format(
      date_format || defaultDateFormat
    );
  record.updated_at =
    record.updated_at &&
    toMyTimezone(record.updated_at, tz).format(
      date_format || defaultDateFormat
    );
  record.payment_from_date =
    record.payment_from_date &&
    toMyTimezone(record.payment_from_date, tz).format(
      date_format || defaultDateFormat
    );
  record.payment_to_date =
    record.payment_to_date &&
    toMyTimezone(record.payment_to_date, tz).format(
      date_format || defaultDateFormat
    );
  return record;
}

export function convertUsersDates(record, tz, date_format) {
  record.created_at =
    record.created_at &&
    toMyTimezone(record.created_at, tz).format(
      date_format || defaultDateFormat
    );
  record.updated_at =
    record.updated_at &&
    toMyTimezone(record.updated_at, tz).format(
      date_format || defaultDateFormat
    );
  record.application_status_at =
    record.application_status_at &&
    toMyTimezone(record.application_status_at, tz).format(
      date_format || defaultDateFormat
    );
  record.actived_at =
    record.actived_at &&
    toMyTimezone(record.actived_at, tz).format(
      date_format || defaultDateFormat
    );
  record.last_logged_at =
    record.last_logged_at &&
    toMyTimezone(record.last_logged_at, tz).format(
      date_format || defaultDateFormat
    );
  return record;
}

export function formatDate(value = new Date().toISOString(), profile, format) {
  dayjs.locale("es");
  try {
    const tz = profile.organization?.tz;
    const dateFormat =
      format || profile.user?.settings?.date_format || "dddd DD/MM HH:mm";

    return tz
      ? dayjs(value).tz(tz).format(dateFormat)
      : dayjs(value).format(dateFormat);
  } catch (err) {
    return "";
  }
}
export function getHumanize({ date, now = dayjs(), locale = "es" }) {
  const evaluatedDate = dayjs(date);
  return now.locale(locale).to(evaluatedDate);
}
