import React from 'react';
import AuthService from './AuthService';
import { connect } from 'react-redux'

import * as actions from '../../../components/containers/Login/actions'

export default function withAuth(AuthComponent) {

  const Auth = new AuthService();

  class AuthWrapped extends React.Component {
    constructor() {
      super();
    }

    componentWillMount() {
      if (!Auth.loggedIn()) {
        this.props.history.replace('/login');
      } else {
        try {

          //  Set Profile
          const profile = Auth.getProfile();
          this.props.setMyProfile(profile)

        } catch (err) {
          Auth.logout();
          this.props.history.replace('/login');
        }
      }
    }
    render() {
      const { profile } = this.props

      if (profile) {
        return (
          <AuthComponent {...this.props}{...this.state} />
        )
      } else {
        return null;
      }
    }
  }

  return connect(state => ({
    profile: state.login.profile
  }), actions)(AuthWrapped)
}