//Libs
import { takeLatest, put, call, all } from "redux-saga/effects";
import axios from "axios";
//Utils
import asyncErrorsHandler from "store/asyncErrorsHandler";
import { lsHandler } from "utils/libs";
import AuthService from "utils/libs/auth/AuthService";
import GENERAL from "utils/constants/general";
const { LOGIN, DIVISION_ID } = GENERAL;
const auth = new AuthService();

//Watcher Sagas
function* loginWatcher() {
	yield takeLatest(LOGIN.LOGIN, loginWorker);
}

function* getMyProfileWatcher() {
	yield takeLatest(LOGIN.GET_MY_PROFILE, getMyProfileWorker);
}

//Worker Sagas
function* loginWorker(action) {
	const { idc, password } = action.payload;

	try {
		yield put({ type: LOGIN.MUTATE_DIRECT_PROPS, payload: { loading: true } });

		const response = yield call(
			axios.post,
			process.env.REACT_APP_API_URL.concat("/users/login"),
			{ idc, password, division_id: DIVISION_ID.EXP },
			auth.sendToken()
		);
		yield auth.setToken(response.data);
		const logged = yield auth.loggedIn();

		if (logged) yield getMyProfileWorker();

		yield put({ type: LOGIN.MUTATE_DIRECT_PROPS, payload: { loading: false } });
		yield put({ type: LOGIN.LOGGED, payload: { logged } });
	} catch (err) {
		yield asyncErrorsHandler(
			err,
			function* () {
				yield put({
					type: LOGIN.MUTATE_DIRECT_PROPS,
					payload: { loading: false },
				});
				yield put({
					type: LOGIN.LOGGED,
					payload: { logged: false },
				});
			},
			function* () {
				yield loginWorker(action);
			}
		);
	}
}

function* getMyProfileWorker() {
	try {
		const response = yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat("/users/profile"),
			auth.sendToken()
		);
		const profile = response.data;

		yield put({ type: LOGIN.SET_MY_PROFILE, payload: profile });
		lsHandler.save("synapse_profile", profile);
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getMyProfileWorker();
		});
	}
}

//  Export default Root Saga
export default function* rootSaga() {
	yield all([loginWatcher(), getMyProfileWatcher()]);
}
