import React from "react";
import { Card } from "antd";
import styled from "styled-components";
import Avatar from "./Avatar";

const { Meta } = Card;

const CardStyled = styled(Card)`
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.card.backgroundColor};
  padding: ${(props) =>
    props.padding ? props.padding : props.theme.card.padding};
  .ant-card-meta-title {
    color: ${(props) =>
      props.titleColor ? props.titleColor : props.theme.card.title.color};
  }
  .ant-card-meta-description {
    color: ${(props) =>
      props.descriptionColor
        ? props.descriptionColor
        : props.theme.card.description.color};
    font-size: ${(props) =>
      props.descriptionFontSize
        ? props.descriptionFontSize
        : props.theme.card.description.fontSize};
  }
  .ant-card-actions {
    margin: ${(props) =>
      props.actionsMargin
        ? props.actionsMargin
        : props.theme.card.actions.margin};
    border-top: ${(props) =>
      props.actionsBorderTop
        ? props.actionsBorderTop
        : props.theme.card.actions.borderTop};
    background: ${(props) =>
      props.actionsBackgroundColor
        ? props.actionsBackgroundColor
        : props.theme.card.actions.backgroundColor};
  }
  .ant-card-actions > li:not(:last-child) {
    border-right: ${(props) =>
      props.actionsItemsBorderRight
        ? props.actionsItemsBorderRight
        : props.theme.card.actions.items.borderRight};
  }
  .ant-card-actions > li > span > .anticon {
    color: ${(props) =>
      props.actionsItemsColor
        ? props.actionsItemsColor
        : props.theme.card.actions.items.colors.default};
  }
`;

export default function WrapperCard({
  ImgCover,
  ImgAvatar,
  actions,
  title,
  description,
  extra,
  style,
}) {
  return (
    <CardStyled style={style} cover={ImgCover} extra={extra} actions={actions}>
      <Meta
        avatar={
          ImgAvatar && <Avatar avatar={{ src: ImgAvatar }} size="small" />
        }
        title={title}
        description={description}
      />
    </CardStyled>
  );
}
