//Libs
import React from "react";
//Components
import { Wrapper, Collector, CollectorUtils } from "components/components";

const UnsubgroupedCollectors = React.memo(
  ({
    profile,
    order,
    docId,
    unsubgroupedCollectors,
    readOnlyCollector,
    highlightRequired,
    selectedCurrentCollector,
    isPhotoBackupActive,
    isSynapseCameraActive,
    filteredCollectorLayout,
    collectorValues,
    resources,
    collectorEcosystemAutoSyncRunning,
    handleOnDuplicateElement,
    handleOnDeleteElement,
  }) => {
    if (unsubgroupedCollectors.length === 0) return null;

    return (
      <Wrapper padding="0" flexDirection="column" alignItems="flex-start">
        {CollectorUtils.checkArray(unsubgroupedCollectors).map(
          (collector, idx2) => (
            <Wrapper
              key={idx2}
              padding="5px"
              width="100%"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Collector
                profile={profile}
                order={order}
                docId={docId}
                collector={collector}
                readOnlyCollector={readOnlyCollector}
                highlightRequired={highlightRequired}
                selectedCurrentCollector={selectedCurrentCollector}
                isPhotoBackupActive={isPhotoBackupActive}
                isSynapseCameraActive={isSynapseCameraActive}
                filteredCollectorLayout={filteredCollectorLayout}
                collectorValues={collectorValues}
                resources={resources}
                collectorEcosystemAutoSyncRunning={
                  collectorEcosystemAutoSyncRunning
                }
                handleOnDuplicateElement={handleOnDuplicateElement}
                handleOnDeleteElement={handleOnDeleteElement}
              />
            </Wrapper>
          )
        )}
      </Wrapper>
    );
  }
);

export default UnsubgroupedCollectors;
