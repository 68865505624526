//Types
import KEYWORDS from "./keywords";
const { ACTIONS } = KEYWORDS;

//Actions
export function mutateDirectProps(keyValuePairs) {
  return {
    type: ACTIONS.MUTATE_DIRECT_PROPS,
    payload: keyValuePairs,
  };
}
