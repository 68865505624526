//  Libs
import React from 'react';
import { Input, } from 'antd';
//  Styles
import '../../index.css';

const { TextArea } = Input;

export default function CommentPhotoReportModal(props) {
  const { makeInvoicePersistInIDB, invoice } = props,
    { photoReport } = invoice;

  return (
    <div className="modal-update-event-orders">

      {/* COMMENT */}
      <TextArea
        style={{ width: '100%' }}
        className="select-custom"
        placeholder="Comentario..."
        rows={5}
        value={photoReport.comment}
        onChange={e => makeInvoicePersistInIDB({ photoReport: { ...photoReport, comment: e.target.value } })}
      />

    </div>
  )
}