//Libs
import React from "react";
//Components
import {
  Wrapper,
  Title,
  LineDescription,
  Subtitle,
  ConsumeInventoryUtils,
} from "components/components";
import { EmptyIllustration } from "../Illustrations";

const ResumeInventory = ({ consumedItemsResume }) => {
  const { articles, serialized, uninstallSerialized } = consumedItemsResume;
  return !ConsumeInventoryUtils.checkExistsConsumedItems(
    articles.concat(serialized).concat(uninstallSerialized)
  ) ? (
    <Wrapper
      height="300px"
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <EmptyIllustration />
      <Title margin="16px 0 0 0" textAlign="center">
        No vinculó ningún inventario
      </Title>
    </Wrapper>
  ) : (
    <Wrapper
      padding="0"
      flexDirection="column"
      alignItems="stretch"
      width="100%"
    >
      {articles.length > 0 && (
        <Wrapper flexDirection="column" alignItems="stretch">
          <Subtitle>Materiales Instalados</Subtitle>
          {articles.map((item, idx) => (
            <div key={idx}>
              <LineDescription
                conceptEllipsis
                concept={item.item_name}
                value={item.value}
              />
            </div>
          ))}
        </Wrapper>
      )}

      {serialized.length > 0 && (
        <Wrapper
          padding="0 10px 10px 10px"
          flexDirection="column"
          alignItems="stretch"
        >
          <Subtitle>Equipos Instalados</Subtitle>
          {serialized.map((item, idx) => (
            <div key={idx}>
              <LineDescription
                conceptEllipsis
                concept={item.item_name}
                value={item.value}
              />
            </div>
          ))}
        </Wrapper>
      )}

      {uninstallSerialized.length > 0 && (
        <Wrapper padding="0 10px" flexDirection="column" alignItems="stretch">
          <Subtitle>Equipos Desinstalados</Subtitle>
          {uninstallSerialized.map((item, idx) => (
            <div key={idx}>
              <LineDescription
                conceptEllipsis
                concept={item.item_name}
                value={item.value}
              />
            </div>
          ))}
        </Wrapper>
      )}
    </Wrapper>
  );
};

export default ResumeInventory;
