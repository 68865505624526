export default Object.freeze({
  STATE: {
    LOADING: "loading",
    SUCCESS: "success",
    UNAUTHORIZED: "unauthorized",
    ERROR: "error",
  },
  MODULES: {
    KEYS: {
      COMMAND_CENTER: "command_center",
      ANALYTICS: "analytics",
      ORDERS: "orders",
      REPORTS: "reports",
      SUPERVISOR: "supervisor",
      BILLINGS: "billings",
      WAREHOUSES: "warehouses",
    },
    ICON: {
      TYPES: {
        INITIAL: "initial",
        IMAGE: "image",
        ICON: "icon",
      },
    },
    ITEMS: {
      KEYS: {
        COMMAND_CENTER: {
          CONTROL: "command_center.control",
        },
        ANALYTICS: {
          CONTROL: "analytics.control",
        },
        ORDERS: {
          CONTROL: "orders.control",
          FILTERS: {
            ORDERS: "orders.control.filters.orders",
            TARGETS: "orders.control.filters.targets",
          },
        },
        REPORTS: {
          CONTROL: "reports.control",
        },
        SUPERVISOR: {
          CONTROL: "supervisor.control",
        },
        BILLINGS: {
          PAYMENTS: "billings.payments.control",
          INVOICES: "billings.invoices.control",
        },
        WAREHOUSES: {
          SERIES: {
            CONTROL: "warehouses.series.control",
          },
        },
      },
      COMPONENTS: {
        MENU: {
          ITEM: "menu.item",
        },
        FILTER: {
          ITEM: "filter.item",
        },
      },
      ICON: {
        TYPES: {
          INITIAL: "initial",
          IMAGE: "image",
          ICON: "icon",
        },
        ICONS: {
          CENTRALS: "centrals",
          RESIDENTIAL: "residential",
          CABINETS: "cabinets",
        },
      },
      STATUS: {
        INSTALL: "install",
        UNINSTALL: "uninstall",
        INSTALLED: "installed",
        NO_INSTALLED: "no_installed",
      },
      COVERAGE: {
        MY_USER: "user",
        USERS: "users",
        ENTITY: "entity",
        ORGANIZATION: "organization",
      },
    },
  },
  ROUTES: {
    ROOT: "/",
    ONBOARDING: {
      LOGIN: "/login",
      FETCHING_MODULES: "/fetchingModules",
      NO_MODULE_ESTABLISHED: "/noModuleEstablished",
    },
    DASHBOARD: {
      ORDERS: {
        CONTROL: "/dashboard/orders",
      },
    },
  },
});
