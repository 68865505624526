//Libs
import React from "react";
//Components
import {
  DatePicker,
  Select,
  TextField,
  Button,
  Wrapper,
  LogoIcon,
} from "components/components";

const { Option } = Select;
const { Input } = TextField;

export default function GetTransactionsModal({
  isTransactionLoading,
  getTransactionsModal,
  getTransactionDocs,
  mutate1ObjectInWarehouse,
}) {
  return (
    <Wrapper width="100%" flexDirection="column" alignItems="stretch">
      <Select
        margin="0 0 10px 0"
        placeholder="Buscar*"
        value={getTransactionsModal.mode}
        onChange={(value) =>
          mutate1ObjectInWarehouse("getTransactionsModal", { mode: value })
        }
      >
        <Option value="document">Documento</Option>
        <Option value="assignment">Pendientes</Option>
        <Option value="completed">Completadas</Option>
      </Select>

      {getTransactionsModal.mode === "document" && (
        <Input
          id="docNumber"
          margin="0 0 10px 0"
          autoFocus
          placeholder="Documento*"
          maxLength={25}
          value={getTransactionsModal.docNumber}
          onChange={(e) =>
            mutate1ObjectInWarehouse("getTransactionsModal", {
              [e.target.id]: e.target.value,
            })
          }
        />
      )}

      {getTransactionsModal.mode === "completed" && (
        <DatePicker
          placeholder="Desde*"
          style={{ marginBottom: "10px" }}
          value={getTransactionsModal.fromDate}
          onChange={(value) =>
            mutate1ObjectInWarehouse("getTransactionsModal", {
              fromDate: value,
            })
          }
        />
      )}

      {getTransactionsModal.mode === "completed" && (
        <DatePicker
          placeholder="Hasta*"
          style={{ marginBottom: "10px" }}
          value={getTransactionsModal.toDate}
          onChange={(value) =>
            mutate1ObjectInWarehouse("getTransactionsModal", { toDate: value })
          }
        />
      )}

      <Wrapper padding="0" justifyContent="center" height="32px">
        {isTransactionLoading ? (
          <LogoIcon spin={isTransactionLoading || undefined} />
        ) : (
          <Button
            mode="primary"
            onClick={() => getTransactionDocs(getTransactionsModal)}
          >
            Consultar
          </Button>
        )}
      </Wrapper>
    </Wrapper>
  );
}
