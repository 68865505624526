export default Object.freeze({
  GET_SAVED_COLLECTOR_VALUES_BY_ORDER:
    "COLLECTOR_GET_SAVED_COLLECTOR_VALUES_BY_ORDER",
  GET_SAVED_COLLECTOR_VALUES_SUCCESS:
    "COLLECTOR_GET_SAVED_COLLECTOR_VALUES_SUCCESS",
  GET_SAVED_COLLECTOR_VALUES_BY_TRANSACTION_DOC:
    "COLLECTOR_GET_SAVED_COLLECTOR_VALUES_BY_TRANSACTION_DOC",
  GET_SAVED_COLLECTOR_VALUES_BY_OTD_SUCCESS:
    "COLLECTOR_GET_SAVED_COLLECTOR_VALUES_BY_OTD_SUCCESS",
  MUTATE_DIRECT_PROPS: "COLLECTOR_MUTATE_DIRECT_PROPS",
  MUTATE_1OBJECT: "COLLECTOR_MUTATE_1OBJECT",
  SET_COLLECTOR_VALUES: "COLLECTOR_SET_COLLECTOR_VALUES",
  SAVE_COLLECTOR_VALUES: "COLLECTOR_SAVE_COLLECTOR_VALUES",
  SAVE_AUDITED_ORDER_COLLECTOR_VALUES_SUCCESS:
    "COLLECTOR_SAVE_AUDITED_ORDER_COLLECTOR_VALUES_SUCCESS",
  UPDATE_COLLECTOR_REQUIRED_VALIDATION:
    "COLLECTOR_UPDATE_COLLECTOR_REQUIRED_VALIDATION",
  SET_AUTO_SYNC: "COLLECTOR_SET_AUTO_SYNC",
  START_AUTO_SYNC_COLLECTOR_VALUES:
    "COLLECTOR_START_AUTO_SYNC_COLLECTOR_VALUES",
  COMPLETE_AUDITED_REPORT: "COLLECTOR_COMPLETE_AUDITED_REPORT",
  GET_AUDITED_TRANSACTION_DOCS: "COLLECTOR_GET_AUDITED_TRANSACTION_DOCS",
  SET_TRANSACTION_DOCS: "COLLECTOR_SET_TRANSACTION_DOCS",
  UPDATE_STATE_AUDITED_TRANSACTION_DOC:
    "COLLECTOR_UPDATE_STATE_AUDITED_TRANSACTION_DOC",
  SET_STATE_COLLECTOR_TRANSACTION_DOC:
    "COLLECTOR_SET_STATE_COLLECTOR_TRANSACTION_DOC",
  GENERATE_REPORT: "COLLECTOR_GENERATE_REPORT",
  GENERATE_REPORT_START: "COLLECTOR_GENERATE_REPORT_START",
  GENERATE_REPORT_FAILED: "COLLECTOR_GENERATE_REPORT_FAILED",
  GENERATE_REPORT_SUCCESS: "COLLECTOR_GENERATE_REPORT_SUCCESS",
  SET_AUTO_FILL_COLLECTOR: "COLLECTOR_SET_AUTO_FILL_COLLECTOR",
  RESET_AUDITED_TRANSACTION_DOCS: "COLLECTOR_RESET_AUDITED_TRANSACTION_DOCS",
  RESET_AUTOFILL_COLLECTOR: "COLLECTOR_RESET_AUTOFILL_COLLECTOR",
  RESET: "COLLECTOR_RESET",
});
