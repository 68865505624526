//Libs
import React from "react";
//Styles
import styles from "./SignPaymentModal.module.css";
//Components
import { VirtualizedList } from "../../..";
import {
  groupResumeOrders,
  recordsHaveAllowedStates,
} from "../../../../Payments/utils";
import {
  Span,
  Wrapper,
  Icon,
  IconButton,
  Button,
  CloseIcon,
  EyeIcon,
  Menu,
  Popover,
  ReloadIcon,
  Caption,
} from "../../../../../components";

function GetOptionsResumeContent(props) {
  const { mutate1ObjectInPayments } = props;

  return (
    <Menu.Wrapper>
      <Menu.Group title="Producción">
        <Menu.Item
          key="individual"
          onClick={() =>
            mutate1ObjectInPayments("signPaymentModal", {
              groupOrders: false,
              showSummary: false,
            })
          }
        >
          Individual
        </Menu.Item>
        <Menu.Item
          key="grouped"
          onClick={() =>
            mutate1ObjectInPayments("signPaymentModal", {
              groupOrders: true,
              showSummary: false,
            })
          }
        >
          Agrupadas
        </Menu.Item>
        <Menu.Item
          key="summary"
          onClick={() =>
            mutate1ObjectInPayments("signPaymentModal", {
              groupOrders: false,
              showSummary: true,
            })
          }
        >
          Resumen
        </Menu.Item>
      </Menu.Group>
    </Menu.Wrapper>
  );
}

export const SignPaymentHeader = ({
  resume,
  getPaymentResume,
  pay,
  signPaymentModal,
  mutate1ObjectInPayments,
}) => {
  return (
    <div className={styles.headerModal}>
      <Span maxHeight="20px" fontWeight="bold" fontSize={20}>
        Resumen
      </Span>
      <div className={styles.headerModalIcons}>
        <Popover
          trigger="click"
          placement="bottomRight"
          title={null}
          content={
            <GetOptionsResumeContent
              signPaymentModal={signPaymentModal}
              mutate1ObjectInPayments={mutate1ObjectInPayments}
            />
          }
        >
          <EyeIcon button="button" />
        </Popover>
        {resume.loading ? (
          <IconButton type="loading" />
        ) : (
          <ReloadIcon
            button="button"
            onClick={() => getPaymentResume(pay.id)}
          />
        )}
        <CloseIcon
          onClick={() =>
            mutate1ObjectInPayments("signPaymentModal", { isOpen: false })
          }
        />
      </div>
    </div>
  );
};

export const Production = ({
  prodAmounts,
  coinSymbol,
  resume,
  signPaymentModal,
  orders,
  getOrder,
  mutate1ObjectInOrders,
}) => {
  const rows = signPaymentModal.groupOrders
    ? groupResumeOrders(resume.orders)
    : resume.orders;

  return (
    <div className={styles.producctionContent}>
      <div
        className={
          resume.orders.length > 0 && !signPaymentModal.showSummary
            ? styles.titleContainer
            : ""
        }
      >
        <Span fontWeight="500" fontSize="14px">
          Producción ({rows.length})
        </Span>
      </div>
      {resume.orders.length > 0 && !signPaymentModal.showSummary && (
        <div className={styles.list}>
          <VirtualizedList
            cardId="paymentOrderResumeCard"
            listStyle={{
              height: 200,
              width: 280,
            }}
            cardStyle={{
              width: "98%",
              boxShadow: "1px 1px 5px 0px rgba(0, 0, 0, 0.4)",
            }}
            rowHeight={signPaymentModal.groupOrders === true ? 140 : 165}
            rows={rows}
            customProps={{
              coinSymbol,
              orders,
              mutate1ObjectInOrders,
              getOrder,
              groupOrders: signPaymentModal.groupOrders,
            }}
          />
        </div>
      )}
      <div
        className={
          resume.orders.length > 0 && !signPaymentModal.showSummary
            ? styles.ivaAndSubContainer
            : ""
        }
      >
        <div className={styles.IvaAndSub}>
          <Span opacity="0.6">Subtotal:</Span>
          <Span>{prodAmounts.prod}</Span>
        </div>
        <div className={styles.IvaAndSub}>
          <Span opacity="0.6">IVA:</Span>
          <Span>{prodAmounts.prodIva}</Span>
        </div>
      </div>
    </div>
  );
};

export const Bonification = ({
  bonusAmounts,
  bonifications,
  coinSymbol,
  orders,
  getOrder,
  mutate1ObjectInOrders,
  signPaymentModal,
}) => {
  return (
    <>
      <div
        className={!signPaymentModal.showSummary ? styles.titleContainer : ""}
      >
        <Span fontWeight="500" fontSize="14px" margin="10px 0px 0px 0px">
          Bonificacion
        </Span>
      </div>
      {bonifications.length > 0 && !signPaymentModal.showSummary && (
        <div className={styles.list}>
          <VirtualizedList
            cardId="paymentLineResumeCard"
            listStyle={{
              height: 300,
              width: 280,
            }}
            cardStyle={{
              width: "98%",
              boxShadow: "1px 1px 5px 0px rgba(0, 0, 0, 0.4)",
            }}
            rowHeight={255}
            spinTitle="Consultando registros..."
            rows={bonifications}
            customProps={{
              coinSymbol,
              orders,
              getOrder,
              mutate1ObjectInOrders,
            }}
          />
        </div>
      )}
      <div
        className={
          bonifications.length < 0 && !signPaymentModal.showSummary
            ? styles.ivaAndSubContainer
            : ""
        }
      >
        <div className={styles.IvaAndSub}>
          <Span opacity="0.6">Subtotal:</Span>
          <Span>{bonusAmounts.bonus}</Span>
        </div>
        <div className={styles.IvaAndSub}>
          <Span opacity="0.6">IVA:</Span>
          <Span>{bonusAmounts.bonusIva}</Span>
        </div>
      </div>
    </>
  );
};

export const Discounts = ({
  discountsAmounts,
  discounts,
  coinSymbol,
  orders,
  getOrder,
  mutate1ObjectInOrders,
  signPaymentModal,
}) => {
  return (
    <>
      <div
        className={
          discounts.length > 0 && !signPaymentModal.showSummary
            ? styles.titleContainer
            : ""
        }
      >
        <Span fontWeight="500" fontSize="14px" margin="10px 0px 0px 0px">
          Descuentos ({discounts.length})
        </Span>
      </div>

      {discounts.length > 0 && !signPaymentModal.showSummary && (
        <>
          <VirtualizedList
            cardId="paymentLineResumeCard"
            listStyle={{
              height: 300,
              width: 280,
            }}
            cardStyle={{
              boxShadow: "1px 1px 5px 0px rgba(0, 0, 0, 0.4)",
              width: "98%",
            }}
            rowHeight={255}
            spinTitle="Consultando registros..."
            rows={discounts}
            customProps={{
              coinSymbol,
              orders,
              getOrder,
              mutate1ObjectInOrders,
            }}
          />
        </>
      )}
      <div
        className={
          discounts.length > 0 && !signPaymentModal.showSummary
            ? styles.ivaAndSubContainer
            : ""
        }
      >
        <div className={styles.IvaAndSub}>
          <Span opacity="0.6">Subtotal:</Span>
          <Span>{discountsAmounts.disc}</Span>
        </div>
        <div className={styles.IvaAndSub}>
          <Span opacity="0.6">IVA:</Span>
          <Span>{discountsAmounts.discIva}</Span>
        </div>
      </div>
    </>
  );
};

export const Resume = ({
  prodAmounts,
  bonusAmounts,
  discountsAmounts,
  pay,
  totalToPay,
  theme,
}) => {
  return (
    <div className={styles.resumeContainer}>
      <Span fontWeight="500" fontSize="14px" margin="10px 0px 0px 0px">
        Resumen
      </Span>
      <div className={styles.resumeBody}>
        <div>
          <Span opacity="0.6">Producción:</Span>
          <Span opacity="0.6">Bonificación:</Span>
          <Span opacity="0.6">Descuentos:</Span>
          <Span opacity="0.6">IVA:</Span>
          <Span opacity="0.6">Total a Pagar:</Span>
        </div>
        <div>
          <Span>{prodAmounts.prodTotal}</Span>
          <Span>{`+ ${bonusAmounts.bonusTotal}`}</Span>
          <Span>{`- ${discountsAmounts.discTotal}`}</Span>
          <Span>{`${pay.iva}%`}</Span>
          <Span color={theme.colors.success.color}>{totalToPay}</Span>
        </div>
      </div>
    </div>
  );
};

export const Signed = ({
  signed,
  totalUsers,
  resume,
  signPaymentModal,
  signPayment,
  pay,
}) => {
  return (
    <>
      <Span fontWeight="500" fontSize="14px" margin="10px 0px 0px 0px">
        Firmas
      </Span>
      <div>
        {signed.map((sign, idx) => {
          const user = totalUsers.filter((user) => user.id === sign.user_id)[0];
          let style = { color: "rgba(255, 255, 255, 0.6)" };

          if (sign.paid) style = { color: "green" };
          else if (sign.released) style = { color: "yellow" };
          else if (sign.agreed) style = { color: "deepskyblue" };

          return (
            user && (
              <Wrapper
                key={idx}
                padding="0px"
                display="flex"
                alignItems="center"
                margin="0 0 8px 0"
              >
                <Icon type="user" style={{ ...style, fontSize: "15px" }} />
                <Wrapper
                  padding="0px"
                  flexDirection="column"
                  alignItems="baseline"
                >
                  <Span style={style} margin="0 10px">
                    {user.name} ({sign.payment_id})
                  </Span>
                  <Span margin="0 10px" color="cadetblue">
                    {sign.created_at}
                  </Span>
                </Wrapper>
              </Wrapper>
            )
          );
        })}

        {!signed.length && (
          <Caption id={styles.withoutSignLabel}>Aún no se ha firmado</Caption>
        )}
      </div>

      {recordsHaveAllowedStates(resume.payments, [
        "ready",
        // "agreed",
        "released",
      ]) && (
        <Wrapper width="100%" justifyContent="flex-end">
          {signPaymentModal.loading ? (
            <IconButton type="loading" />
          ) : (
            <Button mode="primary" onClick={() => signPayment(pay.id)}>
              Firmar
            </Button>
          )}
        </Wrapper>
      )}
    </>
  );
};
