//Libs
import React, { useContext } from "react";
import { useTheme } from "styled-components";
//Utils
import orderBy from "utils/libs/orderBy";
//Context
import { CoordContext } from "components/CoordManager";
//Components
import { Badge } from "antd";
import {
  Tabs,
  Button,
  NoConnected,
  Subtitle,
  Wrapper,
  IconButton,
} from "components/components";
import { Modal, VirtualizedList } from "components/containers/Utils";
//Utils
import {
  filterCategory,
  filterRoutedOrUnroutedOrders,
  filterOrdersToRouting,
  filterOrdersSearchInData,
  getAvailablePositions,
  onDeepSearchOrder,
} from "components/containers/Orders/utils";

const { TabWrap, TabPane } = Tabs;

function Manage(props) {
  const { manuallyDisabledGPS } = useContext(CoordContext);
  const theme = useTheme();

  const {
    profile,
    control,
    getOrdersModal,
    mutate1Object,
    getOrders,
    markOrder,
    sendEventModal,
    sendEvent,
    events,
    createNewInvoice,
    linked,
    sendRoutingOrders,
    makeDeepSearchOrder,
    mutate1ObjectInCollector,
    updateCollectorRequiredValidation,
    mutateConsumeInventoryControl,
  } = props;
  let orders = filterOrdersSearchInData(
    control.orders,
    control.searchInData.data
  );
  let routed = [];
  let unrouted = [];
  let availablePositions = [];

  if (control.searchInData.searchBy === "deepSearch") {
    onDeepSearchOrder(control.searchInData, makeDeepSearchOrder, mutate1Object);
  } else {
    if (
      getOrdersModal.category === "dailyTracking" ||
      getOrdersModal.category === "totalTracking"
    ) {
      orders = filterCategory(
        orders,
        getOrdersModal.category,
        profile.user?.settings.date_format
      );
      routed = filterRoutedOrUnroutedOrders(orders, "routed");
      unrouted = filterRoutedOrUnroutedOrders(orders, "unrouted");
    } else if (getOrdersModal.category === "routing") {
      orders = filterOrdersToRouting(orders);
      availablePositions = getAvailablePositions(
        orders,
        profile.organization?.settings.max_orders_routed
      );
    } else if (getOrdersModal.category === "billed") {
      orders = orders.filter(
        (ord) => ord.event_props && ord.event_props.billed
      );
    } else if (getOrdersModal.category === "auditedOrder") {
      orders = orders.filter(
        (ord) => ord.event_props && ord.event_props.auditedOrder
      );
    }
  }

  function getOrdersCount() {
    if (
      getOrdersModal.category === "billed" ||
      getOrdersModal.category === "auditedOrder" ||
      getOrdersModal.category === "routing" ||
      control.searchInData.searchBy === "deepSearch"
    ) {
      return orders.length;
    }
    if (
      getOrdersModal.category === "dailyTracking" ||
      getOrdersModal.category === "totalTracking"
    ) {
      return routed.length;
    }
    return 0;
  }

  function getPrimaryTabTitle() {
    function getTitlte() {
      if (control.searchInData.searchBy === "deepSearch") {
        return "Búsqueda";
      }
      if (
        getOrdersModal.category === "dailyTracking" ||
        getOrdersModal.category === "totalTracking"
      ) {
        return "Enrutadas";
      }
      if (getOrdersModal.category === "billed") {
        return "Facturadas";
      }
      if (getOrdersModal.category === "auditedOrder") {
        return "Completadas";
      }
      if (getOrdersModal.category === "routing") {
        return (
          <Wrapper padding="0">
            <Subtitle margin="0 18px 0 0">Enrutamiento</Subtitle>

            <Button
              mode="primary"
              margin="0 18px 0 0"
              style={{ height: "30px" }}
              onClick={resetPositionOrders}
            >
              Reiniciar
            </Button>

            {control.routing ? (
              <IconButton type="loading" />
            ) : (
              <Button
                mode="primary"
                style={{ height: "30px" }}
                onClick={() =>
                  sendRoutingOrders(
                    orders,
                    profile.organization?.settings.max_orders_routed
                  )
                }
              >
                Finalizar
              </Button>
            )}
          </Wrapper>
        );
      }
    }

    let badgeOffset = getOrdersModal.category === "routing" ? [7, 2] : [5, -5];

    return (
      <Badge count={getOrdersCount()} offset={badgeOffset} overflowCount={99}>
        <span>{getTitlte()}</span>
      </Badge>
    );
  }

  function getSecondaryTabTitle() {
    const title = "Desenrutadas";

    return (
      <Badge count={unrouted.length} offset={[5, -5]} overflowCount={99}>
        <span>{title}</span>
      </Badge>
    );
  }

  function getPrimaryTabRows() {
    if (
      getOrdersModal.category === "billed" ||
      getOrdersModal.category === "auditedOrder" ||
      getOrdersModal.category === "routing" ||
      control.searchInData.searchBy === "deepSearch"
    ) {
      orderBy(orders, "object", { objectKey: "order_id" });
      return orders;
    }
    if (
      getOrdersModal.category === "dailyTracking" ||
      getOrdersModal.category === "totalTracking"
    ) {
      return routed;
    }
    return [];
  }

  function setPositionInOrder(order_id, value) {
    orders.map((ord) => {
      if (ord.att_position === value) ord.att_position = 0;
      if (ord.order_id === order_id) ord.att_position = value;

      return ord;
    });

    mutate1Object("control", { orders });
  }

  function resetPositionOrders() {
    mutate1Object("control", {
      orders: orders.map((ord) => {
        ord.att_position = 0;
        return ord;
      }),
    });
  }

  //Tab auto switch
  const activeTab = React.useMemo(() => {
    if (getOrdersCount() > 0) return "1";
    if (unrouted.length > 0) return "2";
    return "1";
  }, [control.orders]);

  React.useEffect(() => {
    setTimeout(
      () =>
        mutate1Object("control", {
          activeTab,
        }),
      200
    );
  }, [control.orders]);

  orderBy(unrouted, "object", { objectKey: "order_id" });

  return getOrdersModal.category !== "billed" &&
    getOrdersModal.category !== "auditedOrder" &&
    (!linked.connected || manuallyDisabledGPS) ? (
    <NoConnected message="Debe estar conectado para poder trabajar las ordenes" />
  ) : (
    <div style={{ position: "fixed" }} className="animated fadeIn">
      <TabWrap
        tabPosition="top"
        activeKey={control.activeTab}
        onChange={(activeTab) => mutate1Object("control", { activeTab })}
      >
        {/* PRIMARY TAB */}
        <TabPane tab={getPrimaryTabTitle()} key="1">
          <VirtualizedList
            cardId="order"
            listStyle={{
              width: document.documentElement.clientWidth,
              height: document.documentElement.clientHeight - 155,
              background: theme.colors.background.medium,
            }}
            cardStyle={{
              height: 230,
              width: 300,
            }}
            rowHeight={245}
            spinTitle="Consultando registros..."
            rows={getPrimaryTabRows()}
            customProps={{
              markOrder,
              mutate1Object,
              createNewInvoice,
              availablePositions,
              setPositionInOrder,
              getOrdersModal,
              profile,
              mutate1ObjectInCollector,
              updateCollectorRequiredValidation,
              mutateConsumeInventoryControl,
            }}
          />
        </TabPane>

        {/* SECONDARY TAB */}
        {control.searchInData.searchBy !== "deepSearch" &&
          (getOrdersModal.category === "dailyTracking" ||
            getOrdersModal.category === "totalTracking") && (
            <TabPane tab={getSecondaryTabTitle()} key="2">
              <VirtualizedList
                cardId="order"
                listStyle={{
                  width: document.documentElement.clientWidth,
                  height: document.documentElement.clientHeight - 155,
                  background: theme.colors.background.medium,
                }}
                cardStyle={{
                  height: 230,
                  width: 300,
                }}
                rowHeight={245}
                spinTitle="Consultando registros..."
                rows={unrouted}
                customProps={{
                  markOrder,
                  mutate1Object,
                  createNewInvoice,
                  getOrdersModal,
                  profile,
                  mutate1ObjectInCollector,
                  updateCollectorRequiredValidation,
                  mutateConsumeInventoryControl,
                }}
              />
            </TabPane>
          )}
      </TabWrap>

      {/* SEND AN EVENT MODAL */}
      <Modal
        modalId="SendEventModal"
        title="Reportar evento"
        visible={sendEventModal.isOpen}
        onCancel={() => mutate1Object("sendEventModal", { isOpen: false })}
        onOk={() => sendEvent(sendEventModal, events)}
        isSpinning={sendEventModal.loading}
        sizeSpinning="large"
        delaySpinning={50}
        tipSpinning="Estableciendo evento... Un momento porfavor"
        customProps={{
          events,
          sendEventModal,
          mutate1Object,
        }}
      />

      {/* GET ORDERS QUERY MODAL */}
      <Modal
        modalId="GetOrdersModal"
        title="Consultar"
        visible={getOrdersModal.isOpen}
        onCancel={() =>
          mutate1Object("getOrdersModal", {
            isOpen: false,
            category: undefined,
          })
        }
        onOk={() => getOrders(getOrdersModal)}
        isSpinning={getOrdersModal.loading}
        sizeSpinning="large"
        delaySpinning={50}
        tipSpinning="Consultando. Un momento porfavor..."
        customProps={{
          getOrdersModal,
          mutate1Object,
        }}
      />
    </div>
  );
}

export default Manage;
