// Libs
import { useCallback } from "react";
import { useDispatch } from "react-redux";
// Utils
import { CollectorUtils } from "components/components";
//Actions
import {
  mutate1Object,
  getSavedCollectorValuesByOrder,
  getSavedCollectorValuesByTransactionDoc,
  updateCollectorRequiredValidation as _updateCollectorRequiredValidation,
} from "components/components/Collector/actions";

const useInitializeCollectorEcosystem = () => {
  //Actions
  const dispatch = useDispatch();
  const mutate1ObjectInCollector = (obj1Name, keyValuePairs) =>
    dispatch(mutate1Object(obj1Name, keyValuePairs));
  const updateCollectorRequiredValidation = (payload) =>
    dispatch(_updateCollectorRequiredValidation(payload));

  return useCallback(({ orderId, docId }) => {
    if (!orderId) return;

    if (docId)
      dispatch(getSavedCollectorValuesByTransactionDoc(docId, orderId));
    else dispatch(getSavedCollectorValuesByOrder(orderId));

    CollectorUtils.enterToCollectorEcosystem({
      orderId,
      mutate1ObjectInCollector,
      updateCollectorRequiredValidation,
    });
  }, []);
}

export default useInitializeCollectorEcosystem;