import React from "react";

export default ({ fill = "black", fillOpacity = "0.54", onClick }) => (
  <svg
    onClick={onClick}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="closeIcon20"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="4"
      width="12"
      height="12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8334 5.33332L14.6667 4.16666L10 8.83332L5.33335 4.16666L4.16669 5.33332L8.83335 9.99999L4.16669 14.6667L5.33335 15.8333L10 11.1667L14.6667 15.8333L15.8334 14.6667L11.1667 9.99999L15.8334 5.33332Z"
        fill="white"
      />
    </mask>
    <g mask="url(#closeIcon20)">
      <rect width="20" height="20" fill={fill} fillOpacity={fillOpacity} />
    </g>
  </svg>
);
