//Libs
import React from "react";
//Utils
import { SourcePhotos } from "components/AppConfig.json";
//Components
import { Card } from "antd";
import {
  Wrapper,
  LineDescription,
  Avatar,
  Subtitle,
  Caption,
  ArrowRightIcon,
} from "components/components";
//Styles
import "./SerieHistoryCard.css";

const { Meta } = Card;

const SerieHistoryCard = ({
  id,
  doc_number,
  order_id,
  odt_id,
  transaction_type_name,
  income_method_name,
  item_code,
  item_id,
  serie,
  unavailable,
  state_name,
  warehouse_package_id,
  owarehouse_id,
  dwarehouse_id,
  owarehouse_name,
  dwarehouse_name,
  created_by,
  entity_id,
  creator_name,
  entity_name,
  completed,
  created_at,
  completed_label,
  customProps,
}) => {
  return (
    <Meta
      id="serie_history-meta"
      avatar={
        <Avatar
          bgTransparent
          fit
          size="small"
          avatar={{
            src: SourcePhotos.concat(
              `/syn4pse/users/avatars/${created_by}.jpg`
            ),
          }}
        />
      }
      title={
        <Wrapper
          padding="0"
          margin="0 0 10px 0"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Wrapper padding="0">
            <Subtitle ellipsis width="220px" whiteSpace="nowrap">
              {creator_name}
            </Subtitle>
          </Wrapper>
          <Caption light>
            {doc_number} - {created_at}
          </Caption>
        </Wrapper>
      }
      description={
        <>
          <Wrapper
            width="100%"
            padding="0 10px"
            position="absolute"
            left="0"
            justifyContent="space-around"
          >
            <Wrapper padding="0" flexDirection="column" alignItems="baseline">
              <Caption light>Origen</Caption>
              <Subtitle ellipsis maxWidth="110px" whiteSpace="nowrap">
                {unavailable ? dwarehouse_name : owarehouse_name}
              </Subtitle>
            </Wrapper>
            <ArrowRightIcon />
            <Wrapper padding="0" flexDirection="column" alignItems="baseline">
              <Caption light>Destino</Caption>
              <Subtitle ellipsis maxWidth="110px" whiteSpace="nowrap">
                {unavailable ? owarehouse_name : dwarehouse_name}
              </Subtitle>
            </Wrapper>
          </Wrapper>
          <Wrapper
            width="100%"
            padding="0 10px"
            position="absolute"
            left="0"
            bottom="5px"
            justifyContent="space-around"
          >
            {state_name && (
              <LineDescription
                fontSize="m"
                textAlign="left"
                concept="Estado"
                value={state_name}
              />
            )}
            {(odt_id || income_method_name) && (
              <LineDescription
                valueEllipsis
                fontSize="m"
                textAlign="left"
                concept={odt_id ? "Orden" : "Método"}
                value={odt_id ?? income_method_name}
              />
            )}
          </Wrapper>
        </>
      }
    />
  );
};

export default SerieHistoryCard;
