const colors = Object.freeze({
  background: {
    high: "#FFFFFF",
    medium: "#FAFAFA",
    transparent: "transparent",
  },
  primary: {
    100: "#CFD8DC",
    500: "#37474F",
    900: "#000A12",
  },
  secondary: {
    100: "#BAE6D1",
    800: "#007A46",
    900: "#003008",
  },
  border: {
    color: "#E8E8E8",
  },
  text: {
    high: "rgba(0, 0, 0, 0.87)" /* Black — High Emphasis */,
    medium: "rgba(0, 0, 0, 0.6)" /* Black — Medium Emphasis */,
    disabled: "rgba(0, 0, 0, 0.38)" /* Black — Disabled */,
  },
  inactive: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  success: {
    color: "#2E7D32",
  },
  unsuccess: {
    color: "#37474F",
  },
  error: {
    color: "#D32F2F",
  },
  focused: {
    color: "rgba(0, 122, 70, 0.7)",
  },
  hover: {
    color: "rgba(0, 0, 0, 0.5)",
  },
  selected: {
    500: "#37474F",
    12: "rgba(55, 71, 79, 0.12)",
  },
});

const sizes = Object.freeze({
  small: {
    name: "small",
    width: 16,
    height: 16,
  },
  normal: {
    name: "normal",
    width: 24,
    height: 24,
  },
  medium: {
    name: "medium",
    width: 32,
    height: 32,
  },
  large: {
    name: "large",
    width: 50,
    height: 50,
  },
  xlarge: {
    name: "xlarge",
    width: 66,
    height: 66,
  },
  text: {
    default: "13px",
    xs: "11px",
    s: "12px",
    m: "13px",
    l: "14px",
    xl: "15px",
    s16: "16px",
    s18: "18px",
    s19: "19px",
    s20: "20px",
    em1: "1em",
    em2: "2em",
    em3: "3em",
  },
});

export default Object.freeze({
  colors,
  sizes,
  //New version
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.15px",
    color: colors.text.high,
    mixBlendMode: "normal",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    letterSpacing: "0.25px",
    color: colors.text.high,
    mixBlendMode: "normal",
  },
  caption: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: colors.text.high,
    mixBlendMode: "normal",
  },
  gains: {
    fontFamily: "Roboto Slab",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "28px",
    textAlign: "center",
    letterSpacing: "0.5px",
    color: colors.text.high,
  },
  noConnected: {
    label: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: "28px",
      textAlign: "center",
      color: colors.text.high,
    },
  },
  version: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "right",
    letterSpacing: "0.25px",
    color: colors.text.medium,
  },
  dashboard: {
    item: {
      height: "16px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: colors.inactive.color,
    },
    subitem: {
      height: "24px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "24px",
      color: colors.text.medium,
    },
  },
  onboardMainButton: {
    width: "198px",
    height: "36px",
    color: colors.background.high,
    backgroundColor: colors.primary[500],
    textTransform: "uppercase",
  },
  //Refactor
  toolbar: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.15px",
    mixBlendMode: "normal",
    opacity: 0.87,
    color: colors.text.high,
    backgroundColor: colors.background.high,
    borders: {
      bottom: `1px solid ${colors.border.color}`,
    },
  },
  subtoolbar: {
    borders: {
      bottom: `1px solid ${colors.background.medium}`,
    },
  },
  card: {
    title: {
      color: colors.text.high,
    },
    description: {
      fontSize: "12px",
      color: colors.text.medium,
    },
    backgroundColor: colors.background.high,
    boxShadow: "0px 10px 13px -7px #000000",
    padding: "10px",
    actions: {
      margin: "10px 0 0 0",
      borderTop: "none",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      items: {
        colors: {
          default: "rgba(255, 255, 255, 0.54)",
          primary: "white",
          secondary: "orange",
        },
        borderRight: "1px solid #3f3f49",
      },
    },
  },
  tabs: {
    width: "100%",
    height: "auto",
    tabPane: {
      color: colors.text.high,
      backgroundColor: "transparent",
    },
  },
  icon: {
    margin: 0,
    color: colors.text.disabled,
  },
  iconButton: {
    size: {
      default: {
        height: "48px",
        width: "48px",
        fontSize: "22px",
        padding: "12px",
      },
      s: {
        height: "32px",
        width: "32px",
        fontSize: "14px",
        padding: "9px",
      },
      m: {
        height: "40px",
        width: "40px",
        fontSize: "20px",
        padding: "10px",
      },
      l: {
        height: "48px",
        width: "48px",
        fontSize: "22px",
        padding: "12px",
      },
    },
    margin: 0,
    color: colors.text.disabled,
    cursor: "pointer",
    borderRadius: "50%",
    hover: {
      backgroundColor: colors.hover.color,
    },
    active: {
      backgroundColor: colors.focused.color,
    },
    transition: "background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    touchAction: "manipulation",
  },
  menu: {
    height: "auto",
    overflow: "auto",
    color: colors.background.high,
    backgroundColor: colors.background.high,
    borderRight: "none",
    group: {
      color: "gray",
    },
    submenu: {
      backgroundColor: "transparent",
    },
    active: {
      backgroundColor: colors.focused.color,
    },
    item: {
      height: "25px",
      color: colors.text.high,
      backgroundColor: "transparent",
      hover: {
        backgroundColor: colors.hover.color,
      },
      selected: {
        backgroundColor: colors.selected[12],
      },
      disabled: {
        color: colors.text.disabled,
      },
    },
  },
  span: {
    width: "auto",
    minWidth: "unset",
    maxWidth: "unset",
    height: "auto",
    maxHeight: "unset",
    margin: 0,
    padding: 0,
    color: colors.text.high,
    fontWeight: "unset",
    backgroundColor: "transparent",
    cursor: "unset",
    overflow: "unset",
    hover: {
      color: colors.hover.color,
    },
    active: {
      color: colors.focused.color,
    },
    textAlign: "left",
  },
  button: {
    margin: 0,
    height: "32px",
    padding: 0,
    primary: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "12.5px",
      lineHeight: "13px",
      textAlign: "center",
      letterSpacing: "0.75px",
      textTransform: "uppercase",
      color: colors.primary[500],
    },
    secondary: {
      color: colors.primary[500],
      fontWeight: "normal",
      focus: {
        color: "#40a9ff",
        backgroundColor: "#3f3f49",
        borderColor: "#40a9ff",
      },
    },
  },
  drawer: {
    padding: 0,
    header: {
      fontWeight: "unset",
      color: colors.text.high,
      backgroundColor: colors.background.high,
    },
    content: {
      border: "unset",
      backgroundColor: colors.background.high,
    },
  },
  avatar: {
    sizes: {
      large: {
        width: 66,
        height: 66,
      },
      medium: {
        width: 50,
        height: 50,
      },
      small: {
        width: 32,
        height: 32,
      },
    },
    border: {
      borderRadius: "50%",
      background1: "rgb(41,38,96)",
      background2:
        "linear-gradient(90deg, rgba(41,38,96,1) 12%, rgba(241,169,109,1) 48%, rgba(0,212,255,1) 100%)",
    },
    skeleton: {
      color: colors.primary[500],
      highlightColor: colors.primary[100],
    },
  },
  collapse: {
    width: "100%",
    padding: 0,
    borders: {
      bottom: `1px solid ${colors.border.color}`,
    },
  },
  flag: {
    minWidth: "5px",
    height: "20px",
    success: colors.success.color,
    unsuccess: colors.unsuccess.color,
    borderRadius: "50%",
    absolute: {
      position: "absolute",
      left: 0,
    },
  },
  ////////////////////////////// Old version //////////////////////////////////////
  fonts: {
    family: "Roboto",
    size: {
      default: "15px",
      xs: "11px",
      s: "12px",
      m: "13px",
      l: "14px",
      xl: "15px",
      s16: "16px",
      s18: "18px",
    },
    colors: {
      default: "rgba(255, 255, 255, 0.54)",
      primary: "white",
      secondary: "gray",
      warn: "orangered",
      concept: "lightblue",
      value: "lightgray",
      emphasis_1: "burlywood",
    },
  },
  backgroundIMG: {
    // 135 Slick Carbon
    default:
      "linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.25) 200%)",
    backgroundBlendMode: "multiply",
  },
  wrapperView: {
    width: "100%",
    position: "absolute",
    top: "48px",
    withoutSubToolbarHeight: {
      top: "0",
    },
    withToolbarHeight: {
      top: "56px",
    },
  },
  wrapper: {
    position: "unset",
    width: "auto",
    height: "auto",
    maxHeight: "unset",
    margin: "0px",
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "unset",
    backgroundColor: "transparent",
    borderRadius: "unset",
    boxShadow: "unset",
    transition: "unset",
    border: "none",
  },
  grid: {
    margin: 0,
    padding: 0,
    display: "grid",
    position: "static",
    top: 0,
    left: 0,
    width: "auto",
    height: "auto",
    minHeight: 0,
    columns: "none",
    rows: "none",
    gridAutoFlow: "row",
    gap: 0,
    overflowX: "visible",
    overflowY: "visible",
    border: "none",
    zIndex: 0,
  },
  checkbox: {
    margin: "0px",
    color: "rgba(255, 255, 255, 0.6)",
  },
  checkboxGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  link: {
    margin: 0,
    padding: 0,
    color: "white",
    backgroundColor: "transparent",
    hover: {
      color: "lightskyblue",
    },
    active: {
      color: "lightskyblue",
    },
  },
  popover: {
    backgroundColor: "rgba(0,0,0,.85)",
    inner: {
      maxHeight: "none",
      borderRadius: "5px",
      boxShadow: "0 0 4px white",
      overflow: "visible",
    },
  },
  tooltip: {
    backgroundColor: "rgba(0,0,0,.85)",
  },
  table: {
    borderRadius: "1px",
    backgroundColor: "rgb(63,63,73)",
    header: {
      margin: 0,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: "1px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.41",
      letterSpacing: "0.25px",
      textTransform: "capitalize",
      color: "rgba(255, 255, 255, 0.6)",
      backgroundColor: "rgb(63,63,73)",
    },
    column: {},
    row: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      borderBottom: "0.4px rgba(224,224,225, 0.7) solid",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.41",
      letterSpacing: "0.25px",
      color: "rgba(255, 255, 255, 0.6)",
      hover: {
        fontWeight: "bold",
      },
      active: {
        fontWeight: "normal",
      },
    },
    cell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "unset",
    },
  },
  spin: {
    width: "100%",
    height: "100%",
    color: "white",
    borderRadius: 0, //"20px",
  },
  textField: {
    width: "100%",
    margin: 0,
    height: "33px",
    minHeight: "33px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.48,
    letterSpacing: "0.15px",
    color: colors.text.high,
    backgroundColor: colors.background.high,
  },
  radio: {
    group: {
      padding: 0,
      backgroundColor: "#2c2c36",
    },
    item: {
      display: "block",
      margin: "5px 5px",
      color: "white",
      disabled: {
        color: "gray",
      },
    },
    button: {
      display: "inline-block",
    },
  },
  dropdown: {
    button: {},
  },

  divider: {
    color: "gray",
    margin: "unset",
  },
  steps: {
    title: {
      waiting: {
        color: "gray",
      },
      process: {
        color: "lightskyblue",
      },
      finish: {
        color: "white",
      },
      error: {
        color: "orange",
      },
    },
    icon: {
      waiting: {
        color: "gray",
      },
    },
  },
  select: {
    width: "auto",
    padding: 0,
  },
  chip: {
    color: "springgreen",
    backgroundColor: "darkslategray",
  },
  inputNumber: {
    width: "auto",
  },
  timePicker: {
    width: "auto",
  },
  datePicker: {
    width: "auto",
  },
  image: {
    position: "static",
    top: "auto",
    left: "auto",
    opacity: 1,
    width: "100%",
    maxWidth: "unset",
    height: "100%",
    objectFit: "unset",
  },
  scrollbar: {
    width: "0px",
    height: "0px",
    thumb: {
      background: "#201c29",
      borderRadius: "10px",
      boxShadow:
        "inset 2px 2px 2px hsla(0, 0%, 100%, 0.6), inset -2px -2px 2px rgba(0, 0, 0, 0.25)",
    },
    track: {
      background:
        "linear-gradient(90deg, #201c29, #201c29 1px, #17141d 0, #17141d)",
    },
  },
  grid: {
    margin: 0,
    padding: 0,
    display: "grid",
    position: "static",
    top: 0,
    left: 0,
    width: "auto",
    height: "auto",
    minHeight: 0,
    columns: "none",
    rows: "none",
    gridAutoFlow: "row",
    gap: 0,
    overflowX: "visible",
    overflowY: "visible",
    border: "none",
    zIndex: 0,
  },
});
