// Libs
import React from "react";
import { useSelector } from "react-redux";
//Selectors
import { selectIsControlPanelOpen } from "../selectors";
//Components
import { Wrapper, Modal } from "components/components";
import PhotoBackupCheck from "components/PhotoManager/ControlPanel/PhotoBackupCheck";
import SynapseCameraCheck from "components/PhotoManager/ControlPanel/SynapseCameraCheck";

const SettingList = ({ children }) => (
  <Wrapper
    flexDirection="column"
    justifyContent="flex-start"
    alignItems="flex-start"
  >
    {children}
  </Wrapper>
);

const SettingItem = ({ children }) => (
  <Wrapper width="100%" margin="16px 0 0 0" padding="0">
    {children}
  </Wrapper>
);

const Settings = ({ onClose }) => {
  const isControlPanelOpen = useSelector(selectIsControlPanelOpen);
  if (!isControlPanelOpen) return null;

  return (
    <Modal
      title="Panel de control"
      visible={true}
      footer={null}
      onCancel={onClose}
    >
      <Wrapper
        width="100%"
        height="300px"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <SettingList>
          <SettingItem>
            <SynapseCameraCheck />
          </SettingItem>
          <SettingItem>
            <PhotoBackupCheck />
          </SettingItem>
        </SettingList>
      </Wrapper>
    </Modal>
  );
};

export default Settings;
