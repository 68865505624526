// Libs
import React from "react";
// Components
import { Wrapper, Title, Caption } from "components/components";

const ArticleTitle = ({ inventoryItem }) => (
  <Wrapper padding="0" width="100%" flexDirection="column" alignItems="stretch">
    <Title
      style={{
        textWrap: "wrap",
        overflowY: "auto",
        maxHeight: "105px",
      }}
    >
      {inventoryItem.item_name}
    </Title>
    <Wrapper padding="0" justifyContent="space-between">
      <Caption>ID: {inventoryItem.item_code}</Caption>
      {inventoryItem.serie && <Caption>Serie: {inventoryItem.serie}</Caption>}
    </Wrapper>
  </Wrapper>
);
export default ArticleTitle;
