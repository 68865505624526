import React from "react";

const Icon = ({ fill, fillOpacity = "0.54" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="settingsIcon20"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="20"
      height="20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2293 12C19.2293 12.33 19.1993 12.65 19.1593 12.97L21.2693 14.63C21.4593 14.78 21.5093 15.05 21.3893 15.27L19.3893 18.73C19.2693 18.95 18.9993 19.03 18.7793 18.95L16.2893 17.94C15.7693 18.34 15.2293 18.67 14.5993 18.93L14.2293 21.58C14.1893 21.82 13.9793 22 13.7293 22H9.72933C9.47933 22 9.26933 21.82 9.22933 21.58L8.85933 18.93C8.22933 18.68 7.68933 18.34 7.16933 17.94L4.67933 18.95C4.45933 19.03 4.18933 18.95 4.06933 18.73L2.06933 15.27C1.93933 15.05 1.99933 14.78 2.18933 14.63L4.29933 12.97C4.25933 12.65 4.22933 12.33 4.22933 12C4.22933 11.67 4.25933 11.34 4.29933 11L2.18933 9.37C1.99933 9.22 1.93933 8.95 2.06933 8.73L4.06933 5.27C4.18933 5.05 4.45933 4.96 4.67933 5.05L7.16933 6.05C7.68933 5.66 8.22933 5.32 8.85933 5.07L9.22933 2.42C9.26933 2.18 9.47933 2 9.72933 2H13.7293C13.9793 2 14.1893 2.18 14.2293 2.42L14.5993 5.07C15.2293 5.32 15.7693 5.66 16.2893 6.05L18.7793 5.05C18.9993 4.96 19.2693 5.05 19.3893 5.27L21.3893 8.73C21.5093 8.95 21.4593 9.22 21.2693 9.37L19.1593 11C19.1993 11.34 19.2293 11.67 19.2293 12ZM8.22933 12C8.22933 13.933 9.79633 15.5 11.7293 15.5C13.6623 15.5 15.2293 13.933 15.2293 12C15.2293 10.067 13.6623 8.5 11.7293 8.5C9.79633 8.5 8.22933 10.067 8.22933 12Z"
        fill="white"
      />
    </mask>
    <g mask="url(#settingsIcon20)">
      <rect width="24" height="24" fill={fill} fillOpacity={fillOpacity} />
    </g>
  </svg>
);

export default Icon;
