//Services
import { SentryService } from "services";

const geolocation = ({
  enableHighAccuracy = true,
  maximumAge,
  timeout = 10000,
} = {}) => {
  return new Promise((resolve, reject) => {
    function success(position) {
      resolve({
        coords: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          altitude: position.coords.altitude,
          speed: position.coords.speed,
          heading: position.coords.heading,
          accuracy: position.coords.accuracy,
          altitudeAccuracy: position.coords.altitudeAccuracy,
        },
      });
    }

    function error(err) {
      if (err.code === err.TIMEOUT) {
        SentryService.sendError(err);
        resolve({ message: "GPS Timeout", type: "warn" });
      } else if (err.code === err.POSITION_UNAVAILABLE) {
        SentryService.sendError(err);
        resolve({ message: "GPS No disponible", type: "warn" });
      } else if (err.code === err.PERMISSION_DENIED) {
        reject({
          err,
          message: "Se debe activar el GPS de su dispositivo para continuar",
          type: "warn",
        });
      }
    }

    navigator.geolocation.getCurrentPosition(success, error, {
      enableHighAccuracy,
      maximumAge,
      timeout,
    });
  });
};

export default geolocation;
