//Libs
import React, { useContext } from "react";
//Context
import { CoordContext } from "components/CoordManager";
//Services
import { SentryService } from "services";
//Utils
import UploadResourceUtils from "./UploadResourceUtils";
import { formatDate } from "utils/libs/dateFormats";
//Components
import { Wrapper, AddIcon } from "components/components";
import { PhotoZipper } from "components/PhotoManager";
//Styles
import "./style.css";

const onDownloadPhoto = ({ filename, file, profile, isPhotoBackupActive }) => {
  if (!isPhotoBackupActive) return;
  return UploadResourceUtils.downloadPhoto(
    formatDate(filename, profile, "YYYY-MM-DD.HH.mm.ss"),
    file
  );
};

function UploadResourceV1({
  authConfig,
  isPhotoBackupActive,
  fileProps,
  profile,
  setResource,
  resetResource,
  uploadResource,
  sendToast,
}) {
  const { coords } = useContext(CoordContext);

  const handleChangeUpload = React.useCallback(
    (e) => {
      const file = e.target.files[0];
      if (!file) resetResource();
      else {
        setResource((prev) => ({ ...prev, isLoading: true }));
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          //Create & set image element
          const img = document.createElement("img");
          img.addEventListener("load", async () => {
            try {
              const imgWidth = img.naturalWidth || img.width;
              const imgHeight = img.naturalHeight || img.height;

              if (imgWidth === 0 || imgHeight === 0) {
                sendToast({
                  message: `Dimensión de foto inválida: ${imgWidth}/${imgHeight}`,
                  type: "warn",
                });
                return;
              }

              onDownloadPhoto({
                filename: fileProps.createdAt,
                file: img.src,
                profile,
                isPhotoBackupActive,
              });

              const zipStrategy = authConfig.isPicaStrategy
                ? PhotoZipper.getStrategies().PICA
                : authConfig.isPhotonStrategy
                ? PhotoZipper.getStrategies().PHOTON
                : null;

              await new PhotoZipper(zipStrategy, authConfig)
                .zip(img)
                .then((zippedBase64Photo) => {
                  setResource((prev) => ({
                    ...prev,
                    file: zippedBase64Photo,
                  }));
                  uploadResource(
                    UploadResourceUtils.addFileResource({
                      file: zippedBase64Photo,
                      fileProps,
                      coords,
                    })
                  );
                });
            } catch (err) {
              SentryService.sendError(err);
            }
          });
          img.src = reader.result;
        });
        setTimeout(() => {
          reader.readAsDataURL(file);
        }, 250);
      }
    },
    [fileProps]
  );

  return (
    <>
      <input
        id={fileProps.id}
        accept={authConfig.accept}
        capture={authConfig.capture}
        type="file"
        style={{ width: "70px", height: "70px", display: "none" }}
        onChange={handleChangeUpload}
      />

      <label htmlFor={fileProps.id}>
        <Wrapper
          padding="0"
          backgroundColor="rgba(33,33,33,.1)"
          borderRadius="50px"
        >
          <AddIcon />
        </Wrapper>
      </label>
    </>
  );
}

export default React.memo(UploadResourceV1);
