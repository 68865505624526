export default class PhotoUtils {
  static isImageElement(element) {
    return element.nodeName === "IMG";
  }
  static isCanvasElement(element) {
    return element.nodeName === "CANVAS";
  }
  static photonProcessImg = (img) => {
    const imgWidth = img.naturalWidth || img.width;
    const imgHeight = img.naturalHeight || img.height;

    const canvas = document.createElement("canvas");
    canvas.width = imgWidth;
    canvas.height = imgHeight;

    return { canvas, imgWidth, imgHeight };
  };
  static photonProcessCanvas = (img) => {
    const imgWidth = img.width;
    const imgHeight = img.height;
    return { canvas: img, imgWidth, imgHeight };
  };
  static processImgOrCanvas = (img) => {
    if (this.isImageElement(img)) return this.photonProcessImg(img);
    if (this.isCanvasElement(img)) return this.photonProcessCanvas(img);
  };
  static async dataURItoBlob(base64, mimeType) {
    const base64Response = !!mimeType
      ? await fetch(`data:${mimeType};base64,${base64}`)
      : await fetch(base64);
    const blob = await base64Response.blob();
    return blob;
  }
  static getThumbnail({ src, folder, size }) {
    return src?.replace(`/${folder}/`, `/${folder}/thumbnails/${size}/`);
  }
  static getLogo({ level, size, profile: p }) {
    const src = {
      company: p.company.profile?.logo,
      organization: p.organization.profile?.logo,
      entity: p.entity.profile?.logo,
      user: p.user.profile?.logo,
    }[level];
    if (!src) return;
    if (!size) return src;
    return this.getThumbnail({
      src,
      folder: "logos",
      size,
    });
  }
  static loadImageFromCanvas(canvas) {
    return new Promise((resolve) => {
      const img = document.createElement("img");
      img.addEventListener("load", () => resolve(img));
      img.src = canvas.toDataURL();
    });
  }
}
