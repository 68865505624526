// Libs
import { useCallback } from "react";
import { useDispatch } from "react-redux";
// Utils
import { debounce } from "utils/libs";
import { CollectorUtils } from "components/components";
// Actions
import {
  setCollectorValues,
  saveCollectorValues,
} from "components/components/Collector/actions";

const useCollectorValues = () => {
  const dispatch = useDispatch();
  const debounced = useCallback(
    debounce((callback) => callback(), 250), // Hold at 250 milliseconds
    []
  );

  const sendCollectorValues = (collectorValues) => {
    dispatch(
      saveCollectorValues(
        CollectorUtils.getUnsuccessCollectors({
          collectors: collectorValues,
        })
      )
    );
  };

  const onCollectorValuesChange = ({ value, collectorProps }) =>
    debounced(() => {
      dispatch(setCollectorValues([{ value, collectorProps }]));
      const _collectorValues = CollectorUtils.onCollectorValueChange([], {
        value,
        collectorProps,
      });
      sendCollectorValues(_collectorValues);
    });

  const onCollectorValuesFilter = (collectorValues) => {
    dispatch(setCollectorValues(collectorValues));
  };

  return { onCollectorValuesChange, onCollectorValuesFilter };
};

export default useCollectorValues;
