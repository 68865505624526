//Libs
import React from "react";
import { connect } from "react-redux";
//Utils
import GENERAL from "utils/constants/general";
//Actions
import {
  getProjects,
  mutate1Object as mutate1ObjectInDashboard,
} from "../actions";
import {
  selectProjects,
  selectProjectsIsFetching,
  selectSelectedProjectId,
} from "../selectors";
//Components
import {
  Wrapper,
  Avatar,
  InitialResourceIcon,
  LogoIcon,
  Tooltip,
} from "components/components";

const { DEPARTMENTS } = GENERAL.ENV;

const ProjectIcon = React.memo(({ project, selectedProjectId }) => {
  if (!project?.icon) return;
  const { type, value } = project.icon;

  const isSelected = React.useMemo(
    () => project.id === selectedProjectId,
    [selectedProjectId]
  );
  return (
    <Tooltip
      title={
        undefined
        // project.name
      }
    >
      {(!type || type === DEPARTMENTS.ICON.TYPES.INITIAL) && (
        <InitialResourceIcon
          selected={isSelected}
          initial={value ?? "ND"}
          size="normal"
        />
      )}
      {type === DEPARTMENTS.ICON.TYPES.IMAGE && (
        <Avatar size="normal" fit avatar={{ src: value, name: project.name }} />
      )}
    </Tooltip>
  );
});

const ProjectPicker = React.memo(
  ({
    selectedProjectId,
    isFetching,
    projects,
    getProjects,
    mutate1ObjectInDashboard,
  }) => {
    React.useEffect(() => {
      getProjects();
    }, []);

    return (
      <Wrapper
        padding="0 10px"
        justifyContent={isFetching ? "center" : "flex-start"}
      >
        {isFetching && <LogoIcon spin={true} />}
        {projects.map((project, idx) => (
          <Wrapper
            key={idx}
            padding="0"
            margin={idx + 1 !== projects.length ? "0 10px 0 0" : ""}
            onClick={() => {
              mutate1ObjectInDashboard("projects", {
                selectedProjectId: project.id,
              });
            }}
          >
            <ProjectIcon
              project={project}
              selectedProjectId={selectedProjectId}
            />
          </Wrapper>
        ))}
      </Wrapper>
    );
  }
);

const mapStateToProps = (state) => ({
  selectedProjectId: selectSelectedProjectId(state),
  isFetching: selectProjectsIsFetching(state),
  projects: selectProjects(state),
});
const actions = {
  getProjects,
  mutate1ObjectInDashboard,
};
export default connect(mapStateToProps, actions)(ProjectPicker);
