//Libs
import React from "react";
import _ from "underscore";
import {
  Wrapper,
  Select,
  TextField,
  DatePicker,
  TimePicker,
} from "components/components";
import { filterOption } from "../../../../../../utils/libs";
//Styles
import "../../index.css";

const { Option } = Select;
const { TextArea } = TextField;

export default function SendEventModal(props) {
  const { events, sendEventModal, mutate1Object } = props;
  const { eventId, reprogramDate, reprogramTime, comment } = sendEventModal;
  const idx = _.findIndex(events, (event) => event.id === eventId);

  return (
    <Wrapper width="100%" flexDirection="column" alignItems="stretch">
      {/* ORDER EVENT */}
      <Select
        // showSearch
        placeholder="Evento"
        className="select-custom"
        value={eventId}
        filterOption={filterOption}
        onChange={(value) =>
          mutate1Object("sendEventModal", { eventId: value })
        }
      >
        {events.map((event, idx) => (
          <Option key={idx} value={event.id}>
            {event.name}
          </Option>
        ))}
      </Select>

      {idx !== -1 && events[idx].props && events[idx].props.reprogram && (
        <>
          {/* PROGRAM DATE */}
          <DatePicker
            allowClear={false}
            className="select-custom"
            placeholder="Fecha*"
            value={reprogramDate}
            onChange={(value) =>
              mutate1Object("sendEventModal", { reprogramDate: value })
            }
          />

          {/* PROGRAM TIME */}
          <TimePicker
            allowClear={false}
            className="select-custom"
            placeholder="Hora*"
            value={reprogramTime}
            onChange={(value) =>
              mutate1Object("sendEventModal", { reprogramTime: value })
            }
          />
        </>
      )}

      {/* COMMENT */}
      <TextArea
        style={{ width: "100%" }}
        className="select-custom"
        placeholder="Escribe un comentario*"
        rows={3}
        value={comment}
        onChange={(e) =>
          mutate1Object("sendEventModal", { comment: e.target.value })
        }
      />
    </Wrapper>
  );
}
