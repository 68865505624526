//******** CONSTANTS ***********/
import GENERAL from "../../../utils/constants/general";
import _ from "underscore";
const { ORDERS, DASHBOARD } = GENERAL;

//******** FUNCTIONS ***********/
export function mutateDirectProps(keyValuePairs) {
  return {
    type: ORDERS.MUTATE_DIRECT_PROPS,
    payload: keyValuePairs,
  };
}

export function mutate1Object(obj1Name, keyValuePairs) {
  return {
    type: ORDERS.MUTATE_1OBJECT,
    payload: { obj1Name, keyValuePairs },
  };
}

export function getOrders(getOrdersModal) {
  return {
    type: ORDERS.GET_ORDERS,
    payload: getOrdersModal,
  };
}

export function markOrder(orderId, markType) {
  return {
    type: ORDERS.MARK_ORDER,
    payload: { orderId, markType },
  };
}

export function sendEvent(sendEventModal, events) {
  const { orderId, eventId, reprogramDate, reprogramTime, comment } =
      sendEventModal,
    idx = _.findIndex(events, (event) => event.id === eventId);

  if (!orderId || idx === -1) {
    return {
      type: DASHBOARD.TOAST_MESSAGE,
      payload: {
        message:
          "Error inesperado al procesar los eventos. Por favor sincroniza las ordenes y vuelva a intentarlo",
        type: "error",
      },
    };
  }

  if (comment === "") {
    return {
      type: DASHBOARD.TOAST_MESSAGE,
      payload: { message: "Debe completar todos los campos[*]", type: "error" },
    };
  }

  if (events[idx].props && events[idx].props.reprogram) {
    if (!reprogramDate || !reprogramTime) {
      return {
        type: DASHBOARD.TOAST_MESSAGE,
        payload: {
          message: "Debe completar todos los campos[*]",
          type: "error",
        },
      };
    }
  }

  return {
    type: ORDERS.SEND_EVENT,
    payload: sendEventModal,
  };
}

export function getOrderHistory(orderId) {
  return {
    type: ORDERS.GET_ORDER_HISTORY,
    payload: orderId,
  };
}

export function getBillingMaterials(orderId) {
  return {
    type: ORDERS.GET_BILLING_MATERIALS,
    payload: orderId,
  };
}

export function getPhotoReport(orderId) {
  return {
    type: ORDERS.GET_PHOTO_REPORT,
    payload: orderId,
  };
}

export function transferPhotoReport(canceledOrderId, newOrderId) {
  return {
    type: ORDERS.IMPORT_PHOTO_REPORT,
    payload: { canceledOrderId, newOrderId },
  };
}

export function createNewInvoice(order) {
  return {
    type: ORDERS.CREATE_NEW_INVOICE,
    payload: order,
  };
}

export function makeInvoicePersistInIDB(keyValuePairs) {
  return {
    type: ORDERS.MAKE_INVOICE_PERSIST_IN_INDEXEDDB,
    payload: keyValuePairs,
  };
}

export function resetOrderInformation() {
  return {
    type: ORDERS.RESET_ORDER_INFORMATION,
  };
}

export function sendOnePhotoToBackend(photo, invoice) {
  return {
    type: ORDERS.SEND_ONE_PHOTO_TO_BACKEND,
    payload: { photo, invoice },
  };
}

export function autoSyncPhotoReports() {
  return {
    type: ORDERS.AUTO_SYNC_PHOTO_REPORTS,
  };
}

export function sendInvoice(invoice, now) {
  return {
    type: ORDERS.SEND_INVOICE,
    payload: { invoice, now },
  };
}

export function sendCommentReport(comment, order_id, report) {
  if (comment) comment = comment.trim();

  if (!comment || comment === "") {
    return {
      type: DASHBOARD.TOAST_MESSAGE,
      payload: { message: "Ingresa el comentario", type: "warn" },
    };
  }

  if (report === "3") report = "billingReport";
  else if (report === "4") report = "photoReport";

  return {
    type: ORDERS.SEND_COMMENT_REPORT,
    payload: { comment, order_id, report },
  };
}

export function sendRoutingOrders(orders, maxOrdersRouted) {
  const totalOrders = orders.length,
    routedOrders = orders.filter((order) => order.att_position > 0),
    routedCount = routedOrders.length;

  if (routedCount < totalOrders && routedCount < maxOrdersRouted) {
    return {
      type: DASHBOARD.TOAST_MESSAGE,
      payload: {
        message: "Aún tiene órdenes pendientes de enrutar",
        type: "warn",
      },
    };
  }
  return {
    type: ORDERS.SEND_ROUTING_ORDERS,
    payload: routedOrders,
  };
}

export function makeDeepSearchOrder(data) {
  return {
    type: ORDERS.MAKE_DEEP_SEARCH,
    payload: data,
  };
}

export function getOrder(orderId) {
  return {
    type: ORDERS.GET_ORDER,
    payload: orderId,
  };
}

export function completeOrder(data) {
  return {
    type: ORDERS.COMPLETE_ORDER,
    payload: data,
  };
}

export function resetInvoice() {
  return {
    type: ORDERS.RESET_INVOICE,
  };
}

export function resetCompleteOrderControl() {
  return {
    type: ORDERS.RESET_COMPLETE_ORDER_CONTROL,
  };
}

export function doReset() {
  return {
    type: ORDERS.RESET,
  };
}
