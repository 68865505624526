//Libs
import React from "react";

export default function ParticlesContainer({
  content,
  width,
  height,
  backgroundColor,
  showUpFront,
  noParticles,
}) {
  return (
    <div
      className="container-index animated fadeIn"
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {content}
      </div>
    </div>
  );
}
