//Libs
import React from "react";
import _ from "underscore";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { useTheme } from "styled-components";
//Utils
import GENERAL from "utils/constants/general";
import MANAGE_INVENTORY_KEYWORDS from "components/components/ConsumeInventory/keywords";
//Actions
import {
  mutate1Object as mutate1ObjectInApi,
  getSerieHistory,
} from "store/api/actions";
import {
  mutate1Object as mutate1ObjectInWarehouse,
  sendTransferTransaction,
} from "../../actions";
//Selectors
import {
  selectArticlesInTransaction,
  selectCreateTransaction,
  selectSegmentedInventoryToTransfer,
  selectSerializedInTransaction,
  selectTransactionInventoryLoading,
} from "../../selectors";
//Components
import {
  Wrapper,
  Empty,
  Tabs,
  ConsumeInventoryUtils,
  SummaryInventory,
  Span,
} from "components/components";
import { VirtualizedList } from "components/containers/Utils";
import { debounce } from "utils/libs";

const { ENV } = GENERAL;
const { TabWrap, TabPane } = Tabs;

const InventoryList = ({
  view,
  isInventoryLoading,
  inventoryToTransfer,
  articlesInTransaction,
  serializedInTransaction,
  emptyMessage,
  mutate1ObjectInApi,
  getSerieHistory,
  onChangeInput,
  onAddSerie,
  onDeleteSerie,
}) => {
  return !isInventoryLoading && inventoryToTransfer.length === 0 ? (
    <Empty margin="20px 0 0 0" message={emptyMessage} />
  ) : (
    <VirtualizedList
      cardId="inventoryTransactionCard"
      listStyle={{
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight - 104,
      }}
      cardStyle={ConsumeInventoryUtils.getDesignProps({
        key: "cardStyle",
        view,
      })}
      rowHeight={ConsumeInventoryUtils.getDesignProps({
        key: "rowHeight",
        view,
      })}
      rows={inventoryToTransfer}
      customProps={{
        articlesInTransaction,
        serializedInTransaction,
        mutate1ObjectInApi,
        getSerieHistory,
        onChangeInput,
        onAddSerie,
        onDeleteSerie,
      }}
    />
  );
};

function TransactionsInventory({
  //State
  segmentedInventoryToTransfer,
  isInventoryLoading,
  createTransaction,
  articlesInTransaction,
  serializedInTransaction,
  //Actions
  mutate1ObjectInWarehouse,
  mutate1ObjectInApi,
  getSerieHistory,
  sendTransferTransaction,
}) {
  const [myState, setMyState] = React.useState({
    activeTab: "1",
  });
  const theme = useTheme();
  const { owarehouseId, dwarehouseId, incomeMethod, signer } =
    createTransaction;

  //Get serialized
  const totalSerialized = React.useMemo(
    () =>
      segmentedInventoryToTransfer.serialized.concat(
        segmentedInventoryToTransfer.uninstallSerialized
      ),
    [
      segmentedInventoryToTransfer.serialized,
      segmentedInventoryToTransfer.uninstallSerialized,
    ]
  );

  //Can view summary?
  const canViewSummary = React.useMemo(
    () => articlesInTransaction.length + serializedInTransaction.length > 0,
    [articlesInTransaction, serializedInTransaction]
  );

  //Send unsuccess consumed items
  const mutateDebouncedState = React.useCallback(
    debounce(
      (key, payload) =>
        mutate1ObjectInWarehouse("createTransaction", {
          [key]: payload,
        }),
      100
    ),
    []
  );

  const onChangeInput = React.useCallback(
    (
      { amount, initialAmount, finalAmount },
      { item_id, serie_id, warehouse_package_id }
    ) => {
      let idx = _.findIndex(articlesInTransaction, (article) =>
        serie_id ? article.serie_id === serie_id : article.item_id === item_id
      );

      if (!amount || amount === 0) {
        if (idx !== -1) {
          mutateDebouncedState(
            "articles",
            articlesInTransaction.filter((article) =>
              serie_id
                ? article.serie_id !== serie_id
                : article.item_id !== item_id
            )
          );
        }
      } else {
        if (idx !== -1) {
          articlesInTransaction[idx].amount = amount;
          articlesInTransaction[idx].initialAmount = initialAmount;
          articlesInTransaction[idx].finalAmount = finalAmount;
        } else {
          articlesInTransaction.push({
            item_id,
            serie_id,
            amount,
            initialAmount,
            finalAmount,
            warehouse_package_id,
          });
        }
        mutateDebouncedState("articles", articlesInTransaction);
      }
    },
    [articlesInTransaction]
  );

  const onAddSerie = React.useCallback(
    (item_id, serie, state_id, warehouse_package_id) => {
      serializedInTransaction.unshift({
        item_id,
        serie,
        state_id,
        warehouse_package_id,
      });
      mutate1ObjectInWarehouse("createTransaction", {
        serialized: serializedInTransaction,
      });
    },
    [serializedInTransaction]
  );

  const onDeleteSerie = React.useCallback(
    (serie) => {
      mutate1ObjectInWarehouse("createTransaction", {
        serialized: serializedInTransaction.filter(
          (item) => item.serie !== serie
        ),
      });
    },
    [serializedInTransaction]
  );

  React.useEffect(() => {
    if (myState.activeTab === "3") {
      setMyState({ activeTab: "1" });
      mutate1ObjectInWarehouse("createTransaction", {
        preview: false,
      });
    }
  }, [canViewSummary]);

  //Validate required data
  if (!owarehouseId || !dwarehouseId || !incomeMethod || !signer) {
    return <Redirect to={ENV.ROUTES.PATHS.WAREHOUSE_TRANSACTIONS_MANAGE} />;
  }
  return (
    <Wrapper padding="0" justifyContent="center" className="animated fadeIn">
      <TabWrap
        tabPosition="top"
        activeKey={myState.activeTab}
        smallTabs
        onChange={(activeTab) => {
          setMyState({ activeTab });
          mutate1ObjectInWarehouse("createTransaction", {
            preview: activeTab === "3",
          });
        }}
      >
        <TabPane key="1" tab="Materiales">
          {myState.activeTab === "1" && (
            <InventoryList
              view={MANAGE_INVENTORY_KEYWORDS.TYPES.ARTICLE}
              isInventoryLoading={isInventoryLoading}
              inventoryToTransfer={segmentedInventoryToTransfer.articles}
              articlesInTransaction={articlesInTransaction}
              emptyMessage="No se encontraron materiales"
              mutate1ObjectInApi={mutate1ObjectInApi}
              getSerieHistory={getSerieHistory}
              onChangeInput={onChangeInput}
            />
          )}
        </TabPane>
        <TabPane key="2" tab="Equipos">
          {myState.activeTab === "2" && (
            <InventoryList
              view={MANAGE_INVENTORY_KEYWORDS.TYPES.SERIALIZED}
              isInventoryLoading={isInventoryLoading}
              inventoryToTransfer={totalSerialized}
              serializedInTransaction={serializedInTransaction}
              emptyMessage="No se encontraron equipos"
              mutate1ObjectInApi={mutate1ObjectInApi}
              getSerieHistory={getSerieHistory}
              onAddSerie={onAddSerie}
              onDeleteSerie={onDeleteSerie}
            />
          )}
        </TabPane>
        <TabPane
          key="3"
          disabled={!canViewSummary}
          tab={
            <Span
              color={
                !canViewSummary
                  ? theme.colors.text.disabled
                  : theme.colors.text.high
              }
            >
              {!canViewSummary ? "Vacío" : "Resumen"}
            </Span>
          }
        >
          {myState.activeTab === "3" && (
            <SummaryInventory
              minHeight={`${document.documentElement.clientHeight - 104}px`}
              articles={segmentedInventoryToTransfer.articles}
              serialized={segmentedInventoryToTransfer.serialized}
              uninstallSerialized={
                segmentedInventoryToTransfer.uninstallSerialized
              }
              canSign={true}
              isSigning={createTransaction.loading}
              confirmTitle="Enviando transacción"
              confirmMessage="¿Desea firmar y enviar la transacción?"
              signButtonTitle="Enviar"
              onConfirm={() => {
                sendTransferTransaction(createTransaction);
              }}
            />
          )}
        </TabPane>
      </TabWrap>
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  segmentedInventoryToTransfer: selectSegmentedInventoryToTransfer(state),
  isInventoryLoading: selectTransactionInventoryLoading(state),
  createTransaction: selectCreateTransaction(state),
  articlesInTransaction: selectArticlesInTransaction(state),
  serializedInTransaction: selectSerializedInTransaction(state),
});

const actions = {
  mutate1ObjectInWarehouse,
  mutate1ObjectInApi,
  getSerieHistory,
  sendTransferTransaction,
};

export default connect(mapStateToProps, actions)(TransactionsInventory);
