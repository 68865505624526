//Libs
import React from "react";
//Components
import { VirtualizedList } from "components/containers/Utils";
import { Wrapper, Empty, WrapperView } from "components/components";

function TransactionsManage({
  transactionRows,
  mutate1Object,
  transactionsManage,
  profile,
  transactions,
  getItemsTransactionDocs,
  deleteTransaction,
}) {
  return (
    <WrapperView>
      <Wrapper padding="0" justifyContent="center" className="animated fadeIn">
        {!transactions.loading && transactionRows.length === 0 ? (
          <Empty
            margin="20px 0 0 0"
            message="No se encontraron transacciones"
          />
        ) : (
          <VirtualizedList
            cardId="transactionCard"
            listStyle={{
              height: document.documentElement.clientHeight - 104,
              width: document.documentElement.clientWidth * 1.04,
            }}
            cardStyle={{
              width: 300,
              height: 150,
            }}
            rowHeight={170}
            rows={transactionRows}
            isSpinning={false}
            customProps={{
              mutate1Object,
              getItemsTransactionDocs,
              profile,
              transactionsManage,
              deleteTransaction,
            }}
          />
        )}
      </Wrapper>
    </WrapperView>
  );
}

export default TransactionsManage;
