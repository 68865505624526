//Libs
import React from "react";
//Components
import { Drawer, Wrapper, WrapperView, Button } from "components/components";
import { VirtualizedList } from "components/containers/Utils";
import ImgBugFixing from "assets/media/img/bugFixing.svg";
import {
  CustomToolbar,
  BackComponent,
} from "components/containers/Dashboard/subs/Toolbar";

const Title = ({ title, onClose }) => (
  <CustomToolbar
    title={title}
    left={<BackComponent handleOnClick={onClose} />}
  />
);

export default function ShowErrors({ showErrors, mutate1Object }) {
  const [out, setOut] = React.useState(false);
  React.useEffect(() => {
    let isMounted = true;
    if (out) {
      var st = setTimeout(() => isMounted && setOut(false), 400);
    }
    return () => {
      isMounted = false;
      clearTimeout(st);
    };
  }, [out]);

  return (
    <Drawer
      key={"showErrors"}
      width={`${document.documentElement.clientWidth}px`}
      className={out ? "animated slideOutRight" : ""}
      content_border="1px solid red"
      title={
        <Title
          title={showErrors.title}
          onClose={() => {
            setOut(true);
            mutate1Object("showErrors", { isOpen: false, errors: [] });
          }}
        />
      }
      placement={showErrors.placement}
      closable={false}
      visible={showErrors.isOpen}
    >
      <WrapperView
        withToolbarHeight
        height={`${document.documentElement.clientHeight - 58}px`}
      >
        <Wrapper
          height="100%"
          padding="10px"
          flexDirection="column"
          justifyContent="space-between"
        >
          <VirtualizedList
            cardId="showErrorCard"
            listStyle={{
              height: document.documentElement.clientHeight / 1.5,
              width: document.documentElement.clientWidth,
            }}
            cardStyle={{
              width: document.documentElement.clientWidth,
              height: 70,
            }}
            rowHeight={70}
            spinTitle={undefined}
            rows={showErrors.errors}
          />

          <Wrapper
            padding="10px 20px 10px 10px"
            alignItems="flex-end"
            width="100%"
            style={{
              position: "absolute",
              bottom: 0,
            }}
            justifyContent="space-between"
          >
            <img src={ImgBugFixing} style={{ width: "200px" }} />
          </Wrapper>
        </Wrapper>
      </WrapperView>
    </Drawer>
  );
}
