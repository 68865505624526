import { Tabs } from "antd";
import styled, { css } from "styled-components";

const { TabPane: _TabPane } = Tabs;

const TabWrap = styled(Tabs)`
  width: ${(props) => (props.width ? props.width : props.theme.tabs.width)};
  height: ${(props) => (props.height ? props.height : props.theme.tabs.height)};
  .ant-tabs-tab {
    color: ${(props) =>
      props.colorTab ? props.colorTab : props.theme.tabs.tabPane.color};
  }
  .ant-tabs-bar {
    margin: 0;
  }
  ${(props) =>
    props.smallTabs &&
    css`
      .ant-tabs-nav .ant-tabs-tab {
        margin: 0 10px 0 0;
      }
    `}
`;

const TabPane = styled(_TabPane)`
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.tabs.tabPane.backgroundColor};
  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.text.disabled};
    `}
`;

export default {
  TabWrap,
  TabPane,
};
