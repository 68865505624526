//Libs
import React from "react";
import { Link } from "react-router-dom";
import _ from "underscore";
//Utils
import GENERAL from "utils/constants/general";
import { filterOption, getSigners } from "utils/libs";
import { getDestinyWarehouses } from "components/containers/Warehouse/utils";
//Components
import { Select, TextField, Button, Wrapper } from "components/components";

const { ENV } = GENERAL;
const { Option } = Select;
const { TextArea } = TextField;

export default function CreateTransactionModal(props) {
  const {
    users,
    createTransaction,
    incomeMethods,
    warehouses,
    mutate1ObjectInWarehouse,
    getInventoryToTransfer,
    profile,
  } = props;
  const signers = getSigners(users, createTransaction.dwhOwnerId);
  const dwarehouses = getDestinyWarehouses(warehouses, profile);

  return (
    <Wrapper width="100%" flexDirection="column" alignItems="stretch">
      <Select
        margin="0 0 10px 0"
        showSearch
        placeholder="Destino*"
        value={createTransaction.dwarehouseId}
        filterOption={filterOption}
        onChange={(value) => {
          let owhIdx = _.findIndex(
            warehouses,
            (warehouse) => warehouse.owner_id === profile.user.id
          );
          let dwhIdx = _.findIndex(
            warehouses,
            (warehouse) => warehouse.id === value
          );

          mutate1ObjectInWarehouse("createTransaction", {
            owarehouseId: warehouses[owhIdx].id,
            dwarehouseId: value,
            dwhOwnerId: warehouses[dwhIdx].owner_id,
            signer: undefined,
          });
        }}
      >
        {dwarehouses.map((warehouse, idx) => (
          <Option key={idx} value={warehouse.id}>
            {warehouse.name}
          </Option>
        ))}
      </Select>

      <Select
        margin="0 0 10px 0"
        showSearch
        placeholder="Autorizado Por*"
        value={createTransaction.signer}
        filterOption={filterOption}
        onChange={(value) =>
          mutate1ObjectInWarehouse("createTransaction", { signer: value })
        }
      >
        {signers.map((user, idx) => (
          <Option key={idx} value={user.id}>
            {user.name}
          </Option>
        ))}
      </Select>

      <Select
        margin="0 0 10px 0"
        placeholder="Método*"
        value={createTransaction.incomeMethod}
        onChange={(value) =>
          mutate1ObjectInWarehouse("createTransaction", { incomeMethod: value })
        }
      >
        {incomeMethods.map((method, idx) => (
          <Option key={idx} value={method.id}>
            {method.name}
          </Option>
        ))}
      </Select>

      <TextArea
        placeholder="Comentario"
        rows={3}
        value={createTransaction.comment}
        onChange={(e) =>
          mutate1ObjectInWarehouse("createTransaction", {
            comment: e.target.value,
          })
        }
      />

      <Wrapper padding="0" margin="15px 0 0 0" justifyContent="center">
        <Link
          disabled={
            !createTransaction.owarehouseId ||
            !createTransaction.dwarehouseId ||
            !createTransaction.signer ||
            !createTransaction.incomeMethod
          }
          to={ENV.ROUTES.PATHS.WAREHOUSE_TRANSACTIONS_INVENTORY}
        >
          <Button
            mode="primary"
            disabled={
              !createTransaction.owarehouseId ||
              !createTransaction.dwarehouseId ||
              !createTransaction.signer ||
              !createTransaction.incomeMethod
            }
            onClick={() => {
              getInventoryToTransfer();
              mutate1ObjectInWarehouse("createTransaction", { isOpen: false });
            }}
          >
            Ingresar inventario
          </Button>
        </Link>
      </Wrapper>
    </Wrapper>
  );
}
