//Keywords
import KEYWORDS from "./keywords";
//Routes
import {
  unauthorizedRoutes,
  authorizedRoutes,
  fetchingModulesRoute,
  // noModuleEstablishedRoute,
} from "./routes";
//Themes
import { dark, lightness } from "themes";
import { GlobalUtils } from "utils";

const { ROUTES, MODULES } = KEYWORDS;

export default class ModuleUtils {
  //CHECKS
  //Check is fetching modules
  static checkIsFetchingModules(accessControlState) {
    return accessControlState === KEYWORDS.STATE.LOADING;
  }
  //Check access control unauthorized
  static checkAccessControlUnauthorized({ accessControlState, profile }) {
    return !profile || accessControlState === KEYWORDS.STATE.UNAUTHORIZED;
  }

  //GETTERS
  static getDefaultRoute(profile) {
    return profile?.user?.profile?.modules?.defaultRoute;
  }
  //Get Screen Props
  static getScreenProps({ theme, routes }) {
    return { theme, routes };
  }
  //Get Routes
  static getRoutes({ modules, isFetchingModules, unauthorized }) {
    if (isFetchingModules) return fetchingModulesRoute;
    if (unauthorized) return unauthorizedRoutes;
    return authorizedRoutes({ modules });
  }
  //Get selected user theme
  static getSelectedTheme(profileTheme) {
    if (!profileTheme || profileTheme === "dark") {
      return dark;
    }
    return dark; //TODO: Debería ser: lightness
  }
  //Find module by key
  static findModuleByKey(key, modules) {
    return GlobalUtils.checkArray(modules).find((m) => m.key === key);
  }
  //Find module item by component
  static findModuleItemByComponentKey(component, key, moduleItems) {
    return GlobalUtils.checkArray(moduleItems).find(
      (item) => item.component === component && item.key === key
    );
  }
  //Get market item new status
  static getMarketItemNewStatus(currentStatus) {
    const { NO_INSTALLED, INSTALLED, INSTALL, UNINSTALL } =
      KEYWORDS.MODULES.ITEMS.STATUS;

    if (currentStatus === NO_INSTALLED) return INSTALL;
    if (currentStatus === INSTALLED) return UNINSTALL;
  }
  //Get modules
  static getModules(modules) {
    return modules.map(({ id, name, icon }) => ({ id, name, icon }));
  }
  //Get module item controls
  static getModuleItems(modules) {
    return modules
      .reduce((acc, module) => {
        if (!Array.isArray(module.moduleItems)) return acc;
        return [...acc, ...module.moduleItems];
      }, [])
      .filter(
        ({ key, component }) =>
          key.endsWith(".control") &&
          component === MODULES.ITEMS.COMPONENTS.MENU.ITEM
      );
  }
  //Translate market item status with a market item status key
  static getMarketplaceItemActionByStatusKey(status) {
    const { INSTALL, UNINSTALL } = KEYWORDS.MODULES.ITEMS.STATUS;

    if (status === INSTALL) return "instalar";
    if (status === UNINSTALL) return "desinstalar";
  }

  //SETTERS
  //Redirect Route
  static redirectRoute({ isFetchingModules, unauthorized, history, profile }) {
    //User default route
    const defaultRoute = this.getDefaultRoute(profile);
    //System default route
    let path = ROUTES.DASHBOARD.ORDERS.CONTROL;

    //Fetching modules screen
    if (isFetchingModules) path = ROUTES.ONBOARDING.FETCHING_MODULES;
    //Login screen
    else if (unauthorized) path = ROUTES.ONBOARDING.LOGIN;
    //User default route
    else if (defaultRoute) path = defaultRoute;

    history.replace(path);
  }
}
