export default Object.freeze({
  getSymbol: function (coinId) {
    switch (coinId) {
      case 188:
        return "₡";

      default:
        return "";
    }
  },
  getAcronym: function (coinId) {
    switch (coinId) {
      case 188:
        return "CRC";

      default:
        return "";
    }
  },
  format: function (num, separator, decimalSep, coin) {
    num += "";
    let splitStr = num.split(",");
    let splitLeft = splitStr[0];
    let splitRight = splitStr.length > 1 ? decimalSep + splitStr[1] : "";
    let regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, "$1" + separator + "$2");
    }
    return coin ? coin + " " + splitLeft + splitRight : splitLeft + splitRight;
  },
  new: function ({
    num,
    fixed = 2,
    separator = ",",
    decimalSep = ".",
    coinId = false,
    percent = false,
    coinFormat = "symbol",
  }) {
    let coin = null;

    if (coinId) {
      if (coinFormat === "symbol") coin = this.getSymbol(coinId);
      else if (coinFormat === "acronym") coin = this.getAcronym(coinId);
    }

    if (percent) return `${num}%`;

    return this.format(Number(num).toFixed(fixed), separator, decimalSep, coin);
  },
});
