import styled, { css } from "styled-components";

const WrapperStyled = styled.div`
	position: ${(props) =>
		props.position ? props.position : props.theme.wrapper.position};
	top: ${({ top }) => top};
	right: ${({ right }) => right};
	bottom: ${({ bottom }) => bottom};
	left: ${({ left }) => left};
	width: ${(props) => (props.width ? props.width : props.theme.wrapper.width)};
	min-width: ${({ minWidth }) => minWidth};
	height: ${(props) =>
		props.height ? props.height : props.theme.wrapper.height};
	min-height: ${(props) => props.minHeight};
	max-width: ${({ maxWidth, theme }) => maxWidth ?? theme.wrapper.maxWidth};
	max-height: ${(props) =>
		props.maxHeight ? props.maxHeight : props.theme.wrapper.maxHeight};
	margin: ${(props) =>
		props.margin ? props.margin : props.theme.wrapper.margin};
	padding: ${(props) =>
		props.padding ? props.padding : props.theme.wrapper.padding};
	display: ${(props) =>
		props.display ? props.display : props.theme.wrapper.display};
	flex-direction: ${(props) =>
		props.flexDirection
			? props.flexDirection
			: props.theme.wrapper.flexDirection};
	flex-wrap: ${({ flexWrap }) => flexWrap};
	gap: ${({ gap }) => gap};
	justify-content: ${(props) =>
		props.justifyContent
			? props.justifyContent
			: props.theme.wrapper.justifyContent};
	align-items: ${(props) =>
		props.alignItems ? props.alignItems : props.theme.wrapper.alignItems};
	overflow: ${(props) =>
		props.overflow ? props.overflow : props.theme.wrapper.overflow};
	overflow-x: ${({ overflowX }) => overflowX && overflowX};
	overflow-y: ${({ overflowY }) => overflowY && overflowY};
	background-color: ${(props) =>
		props.backgroundColor
			? props.backgroundColor
			: props.theme.wrapper.backgroundColor};
	border: ${(props) =>
		props.border ? props.border : props.theme.wrapper.border};
	border-radius: ${(props) =>
		props.borderRadius ? props.borderRadius : props.theme.wrapper.borderRadius};
	box-shadow: ${(props) =>
		props.boxShadow ? props.boxShadow : props.theme.wrapper.boxShadow};
	transition: ${(props) =>
		props.transition ? props.transition : props.theme.wrapper.transition};
	word-break: ${(props) => props.wordBreak};
	${(props) =>
		props.defaultGradientBackground &&
		css`
			background-image: ${(props) => props.theme.backgroundIMG.default};
			background-blend-mode: ${(props) =>
				props.theme.backgroundIMG.backgroundBlendMode};
		`}
	${(props) =>
		props.boxShadowInner1 &&
		css`
			-webkit-box-shadow: -10px 0px 13px -7px #000000,
				10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
			box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
				5px 5px 15px 5px rgba(0, 0, 0, 0);
		`}
  &:hover {
		box-shadow: ${(props) => props.hover?.boxShadow};
	}
`;

export default WrapperStyled;
