//Libs
import React from "react";
import { Route, Redirect } from "react-router-dom";
//Utils
import GENERAL from "utils/constants/general";

const { ENV } = GENERAL;

function Transactions(props) {
  const { routes, history } = props;
  if (history.location.pathname === ENV.ROUTES.PATHS.WAREHOUSE_TRANSACTIONS) {
    return <Redirect to={ENV.ROUTES.PATHS.WAREHOUSE_TRANSACTIONS_MANAGE} />;
  }

  return (
    <>
      {routes.map((route, i) => {
        return (
          <Route
            key={i}
            path={route.path}
            render={(oProps) => (
              <route.component {...oProps} {...props} routes={route.routes} />
            )}
          />
        );
      })}
    </>
  );
}

export default Transactions;
