//Libs
import React from "react";
//Utils
import KEYWORDS from "./keywords";
import { filterOption } from "utils/libs";
//Components
import { Card } from "antd";
import {
  Wrapper,
  Caption,
  Title,
  Select,
  TextField,
} from "components/components";
import ConsumeInventoryUtils from "./ConsumeInventoryUtils";
import StatusIcon from "./StatusIcon";

const { Meta } = Card;
const { Option } = Select;
const { Input } = TextField;

const SelectItems = ({
  items,
  familyType,
  myState,
  savedConsumedItem,
  consumedItemValue,
  onChangeSelect,
  onChangeInput,
  onAdd,
  onDelete,
}) => {
  const { selectedItemId, value } = myState;

  return (
    <Wrapper padding="0" justifyContent="space-between">
      <Wrapper margin="0 17px 0 0" padding="0" flexDirection="column">
        <Select
          width="220px"
          value={selectedItemId}
          placeholder="Selecciona el Item..."
          showSearch
          filterOption={filterOption}
          onChange={onChangeSelect}
        >
          {items.map(({ item_id, item_name, item_code }) => (
            <Option key={item_id} value={item_id}>
              {item_code}.{item_name}
            </Option>
          ))}
        </Select>

        {familyType === KEYWORDS.TYPES.UNINSTALL_SERIALIZED && (
          <Input
            margin="5px 0 0 0"
            width="220px"
            value={value}
            placeholder="Escriba la serie..."
            onChange={(e) => onChangeInput(e.target.value)}
          />
        )}
      </Wrapper>
      <StatusIcon
        familyType={familyType}
        inputValueOk={selectedItemId && value}
        savedConsumedItem={savedConsumedItem}
        currentValue={consumedItemValue}
        isSerialized={familyType === KEYWORDS.TYPES.UNINSTALL_SERIALIZED}
        onAdd={onAdd}
        onDelete={onDelete}
      />
    </Wrapper>
  );
};

function UninstallInventoryFamilyCard({
  id: familyId,
  name,
  items,
  type: familyType,
  customProps,
}) {
  const { otdId, orderId, ownerId, entityId, consumedItems, onChangeState } =
    customProps;
  const [myState, setMyState] = React.useState({
    selectedItemId: undefined,
    value: undefined,
  });
  const changeMyState = React.useCallback(
    (newState) => setMyState({ ...myState, ...newState }),
    [myState]
  );

  const inventoryItem = React.useMemo(
    () => items.find((i) => i.item_id === myState.selectedItemId),
    [myState, items]
  );

  //Get consumed item props
  const consumedItemProps = ConsumeInventoryUtils.getConsumedItemProps(
    { otdId, orderId, ownerId, entityId },
    {
      ...inventoryItem,
      serie: myState.value,
    }
  );
  //Get saved consumed item idx
  const savedConsumedItemIdx =
    ConsumeInventoryUtils.getConsumedItemIdxFromConsumedItems(
      consumedItems,
      consumedItemProps
    );
  //Saved consumed item
  const savedConsumedItem = ConsumeInventoryUtils.getConsumedItemFromIdx(
    consumedItems,
    savedConsumedItemIdx
  );
  //Consumed item value
  const consumedItemValue =
    ConsumeInventoryUtils.getConsumedItemValue(savedConsumedItem);

  //On Change
  const onChange = (value) =>
    onChangeState({
      value,
      consumedItemProps,
      savedConsumedItemIdx,
      savedConsumedItem,
      consumedItems,
    });

  return (
    <Meta
      id="meta-card-container"
      title={
        <Wrapper
          padding="0"
          width="300px"
          height="45px"
          flexDirection="column"
          alignItems="stretch"
        >
          <Title width="280px" ellipsis>
            {name}
          </Title>
          <Caption margin="0 0 0 2px">ID: {familyId}</Caption>
        </Wrapper>
      }
      description={
        <Wrapper padding="5px 10px">
          <SelectItems
            items={items}
            familyType={familyType}
            myState={myState}
            savedConsumedItem={savedConsumedItem}
            consumedItemValue={consumedItemValue}
            onChangeSelect={(selectedItemId) =>
              changeMyState({ selectedItemId })
            }
            onChangeInput={(value) => changeMyState({ value: value.trim() })}
            onAdd={() => onChange(myState.value)}
            onDelete={() => onChange(null)}
          />
        </Wrapper>
      }
    />
  );
}

export default React.memo(UninstallInventoryFamilyCard);
