//Libs
import React from "react";
import { Card, InputNumber, Button } from "antd";
import _ from "underscore";
//Styles
import "./OrderHistoryCard.css";
import "./ToInvoicedItemCard.css";

const { Meta } = Card;

export default function ToInvoicedItemCard(props) {
  const {
      item_id,
      item_name,
      item_code,
      amount,
      serie,
      is_serialized,
      unavailable,
    } = props,
    {
      makeInvoicePersistInIDB,
      articles,
      serialized,
      uninstalled,
    } = props.customProps,
    idxArticle = _.findIndex(articles, (art) => art.item_id === item_id),
    idxSerie = _.findIndex(serialized, (row) => row.serie === serie),
    idxUninstallSerie = _.findIndex(uninstalled, (row) => row.serie === serie);

  function onAddSerie() {
    serialized.unshift({
      item_id,
      serie,
    });
    makeInvoicePersistInIDB({ serialized });
  }

  function onAddArticle(value) {
    if (idxArticle === -1) {
      articles.push({
        item_id,
        amount: value,
      });
    } else {
      articles[idxArticle].amount = value;
    }
    makeInvoicePersistInIDB({ articles });
  }

  function onDeleteSerie() {
    if (unavailable) {
      makeInvoicePersistInIDB({
        uninstalled: uninstalled.filter((row) => row.serie !== serie),
      });
    } else {
      makeInvoicePersistInIDB({
        serialized: serialized.filter((row) => row.serie !== serie),
      });
    }
  }

  function onDeleteArticle() {
    makeInvoicePersistInIDB({
      articles: articles.filter((art) => art.item_id !== item_id),
    });
  }

  return (
    <Meta
      id="meta-card-container"
      title={
        <div className="container-title-card">
          <span className="card-creator">{item_name}</span>
        </div>
      }
      description={
        <div className="to_invoice-container-data-input">
          <div className="to_invoice-data">
            <div className="card-container-item">
              <div className="card-concept">
                Código: <b>{item_code}</b>
              </div>
            </div>
            {!is_serialized && (
              <div className="card-container-item">
                <div className="card-concept">
                  Cantidad: <b>{amount}</b>
                </div>
              </div>
            )}
            {is_serialized && (
              <div className="card-container-item">
                <div className="card-concept">
                  Serie: <b>{serie}</b>
                </div>
              </div>
            )}
          </div>

          <div>
            {!is_serialized ? (
              <InputNumber
                placeholder="Cantidad"
                min={0}
                max={Number(amount)}
                step={1}
                value={
                  idxArticle !== -1 ? articles[idxArticle].amount : undefined
                }
                onChange={(value) => {
                  if (!value || value === 0) {
                    if (idxArticle !== -1) onDeleteArticle();
                  } else onAddArticle(value);
                }}
              />
            ) : idxSerie !== -1 || idxUninstallSerie !== -1 ? (
              <Button
                className="to_invoice-button"
                style={{ color: "darkgray" }}
                onClick={onDeleteSerie}
              >
                Quitar
              </Button>
            ) : (
              <Button className="to_invoice-button" onClick={onAddSerie}>
                Agregar
              </Button>
            )}
          </div>
        </div>
      }
    />
  );
}
