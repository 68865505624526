//Setters
function save(name, content) {
  const isContentString = typeof content === "string";
  try {
    localStorage.setItem(
      name,
      isContentString ? content : JSON.stringify(content)
    );
    return true;
  } catch (err) {
    alert(err);
    return false;
  }
}

//Getters
function get(name) {
  const value = localStorage.getItem(name);
  try {
    return JSON.parse(value);
  } catch (err) {
    return value;
  }
}

//Deletters
function del(name) {
  localStorage.removeItem(name);
}

const KEYS = {
  LINKED: "synapse_linked",
  NAVIGATOR_INSTANCE: "synapse_navigatorInstance",
};
function getLinked() {
  let linked = get(KEYS.LINKED);
  if (linked) return linked;
  const _linked = {
    connected: false,
    end_time: "",
    start_at: "",
    start_time: "",
    total_time_connected: "00:00:00",
    coords: {},
  };
  saveLinked(_linked);
  return _linked;
}
function saveLinked(linked) {
  return save(KEYS.LINKED, linked);
}

// Navigator Instance
function getNavigatorInstance() {
  return get(KEYS.NAVIGATOR_INSTANCE);
}
function saveNavigatorInstance(navigatorInstance) {
  return save(KEYS.NAVIGATOR_INSTANCE, navigatorInstance);
}

export default {
  save,
  saveLinked,
  saveNavigatorInstance,
  get,
  getLinked,
  getNavigatorInstance,
  del,
};
