//Libs
import React from "react";
import { connect } from "react-redux";
//Actions
import { updateStateAuditedTransactionDoc } from "../actions";
import { selectProfile } from "components/containers/Login/selectors";
//Selectors
import { selectReviewManage } from "../selectors";
//Keywords
import CollectorUtils from "../CollectorUtils";
//Keywords
import COLLECTOR_KEYWORDS from "../keywords";
//Components
import {
  Wrapper,
  Dropdown,
  IconButton,
  Menu,
  SendReportButton,
} from "components/components";

const UpdateStateItem = ({
  doc,
  reviewManage,
  updateStateAuditedTransactionDoc,
}) => {
  const { orderId, transactionDocs } = reviewManage;
  const { docId, updateState } = transactionDocs;

  const onClick = (state) =>
    updateState[docId] !== COLLECTOR_KEYWORDS.TRANSACTION_DOCS.STATUS.LOADING &&
    updateStateAuditedTransactionDoc({ docId, orderId, state });

  if (doc.state === COLLECTOR_KEYWORDS.TRANSACTION_DOCS.STATE.OPEN) {
    return <SendReportButton itemStyle />;
  }
  if (doc.state === COLLECTOR_KEYWORDS.TRANSACTION_DOCS.STATE.CLOSED) {
    return (
      <Wrapper
        padding="0"
        onClick={() => onClick(COLLECTOR_KEYWORDS.TRANSACTION_DOCS.STATE.OPEN)}
      >
        Editar
        {updateState[docId] ===
          COLLECTOR_KEYWORDS.TRANSACTION_DOCS.STATUS.LOADING && (
          <IconButton type="loading" size="s" />
        )}
      </Wrapper>
    );
  }
};

const OptionMenu = ({
  doc,
  reviewManage,
  updateStateAuditedTransactionDoc,
}) => (
  <Menu.Wrapper>
    {/* EDIT */}
    <Menu.Item>
      <UpdateStateItem
        doc={doc}
        reviewManage={reviewManage}
        updateStateAuditedTransactionDoc={updateStateAuditedTransactionDoc}
      />
    </Menu.Item>
  </Menu.Wrapper>
);

const OptionList = ({
  //Redux
  reviewManage,
  updateStateAuditedTransactionDoc,
  profile,
}) => {
  const { transactionDocs } = reviewManage;
  const { docId, data } = transactionDocs;

  //Get selected transaction doc
  const doc = React.useMemo(
    () => CollectorUtils.getSelectedDocFromTransactionDocs(data, docId),
    [data, docId]
  );

  if (!doc || !CollectorUtils.checkEditOTDCollectors(doc, profile)) {
    return null;
  }

  return (
    <Dropdown.Wrapper
      trigger={["click"]}
      overlay={
        <OptionMenu
          doc={doc}
          reviewManage={reviewManage}
          updateStateAuditedTransactionDoc={updateStateAuditedTransactionDoc}
        />
      }
    >
      <IconButton size="m" type="more" />
    </Dropdown.Wrapper>
  );
};

const mapStateToProps = (state) => ({
  reviewManage: selectReviewManage(state),
  profile: selectProfile(state),
});
const actions = {
  updateStateAuditedTransactionDoc,
};

export default connect(mapStateToProps, actions)(OptionList);
