//Libs
import * as Sentry from "@sentry/react";
//NPM Package
import npmPackage from "../../package.json";

const tracesSampleRate = {
  production: 0.1,
  development: 1.0, // Capture 100% of the transactions, reduce in production!
};

const getUserProfile = () => {
  try {
    return JSON.parse(localStorage.getItem("synapse_profile"));
  } catch (err) {
    return {};
  }
};

export default class SentryService {
  static run() {
    try {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: npmPackage.version,
        integrations: [new Sentry.BrowserTracing()],
        // Performance Monitoring
        tracesSampleRate: tracesSampleRate[process.env.NODE_ENV],
        environment: process.env.NODE_ENV,
        ignoreErrors: [
          // onsen-ui
          "Another splitter-side action is already running",
          "Non-Error promise rejection captured with value: Another splitter-side action is already running.",
          //Play music
          "NotAllowedError: play() failed because the user didn't interact with the document first",
          //Abort music
          "AbortError: The play() request was interrupted by a call to pause()",
          //User not permission
          "NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission",
        ],
      });
      console.log(`Initialized Sentry in env: ${process.env.NODE_ENV}`);
    } catch (err) {
      console.error(err);
    }
  }
  static reduxMiddleware = () => (next) => (action) => {
    try {
      return next(action);
    } catch (error) {
      this.sendError(error);
      throw error;
    }
  };
  //Send error to sentry
  static sendError(err, props = {}) {
    const profile = getUserProfile();
    try {
      Sentry.captureException(err, {
        extra: {
          ...props,
          user: {
            id: profile.user?.id,
            name: profile.user?.name,
          },
          entity: {
            id: profile.entity?.id,
            name: profile.entity?.name,
          },
          organization: {
            id: profile.organization?.id,
            name: profile.organization?.name,
          },
          company: {
            id: profile.company?.id,
            name: profile.company?.name,
          },
        },
      });
    } catch (_err) {
      console.error(_err);
    }
  }
}
