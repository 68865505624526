// Libs
import React, { useEffect } from "react";
import Map, {
	MapContext,
	FlyToInterpolator,
	Marker,
	Source,
	Layer,
	ScaleControl,
} from "react-map-gl";
// conponents
import { LocationIcon, Wrapper } from "..";
import MapUtils from "./MapUtils";
// utils
import { circle as turfCircle, lineString } from "@turf/turf";

const MarkUser = ({ latitude, longitude }) => (
	<Marker
		latitude={latitude}
		longitude={longitude}
		offsetLeft={-20}
		offsetTop={-10}>
		<div>
			<Wrapper
				backgroundColor="rgba(0,0,0,.3)"
				borderRadius="50%"
				padding="0, 0, 25px">
				<LocationIcon />
			</Wrapper>
		</div>
	</Marker>
);

const WrapperMap = ({
	coords = {},
	setConfirmedCoords,
	isCloseMap,
	...rest
}) => {
	const isControllerCoords = !!rest.maxDistance;
	const { maxDistance = 100 } = rest;

	const { map } = React.useContext(MapContext);

	const { longitude = 0, latitude = 0 } = coords;
	const [viewport, _setViewport] = React.useState({
		longitude: Number(longitude),
		latitude: Number(latitude),
		zoom: 18,
		minZoom: 10,
		maxZoom: 20,
	});

	const setViewport = (newState) => {
		setConfirmedCoords({
			latitude: newState.latitude,
			longitude: newState.longitude,
		});

		_setViewport((current) => ({ ...current, ...newState }));
	};

	useEffect(() => {
		let isMounted = true;
		if (!map) return;

		const handleMapLoad = () => {
			if (!isMounted) return;

			setViewport({
				zoom: 10,
				transitionDuration: 2000,
				transitionInterpolator: new FlyToInterpolator(),
			});
		};

		map.on("load", handleMapLoad);

		return () => {
			isMounted = false;
			map.off("load", handleMapLoad);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [map]);

	const handleViewportChange = (newViewport) => {
		const distance = MapUtils.calculateDistance({
			lat1: latitude,
			lon1: longitude,
			lat2: newViewport.latitude,
			lon2: newViewport.longitude,
		});

		if (distance > maxDistance) {
			setViewport({
				...newViewport,
				transitionDuration: 1000,
				transitionInterpolator: new FlyToInterpolator(),
				latitude: viewport.latitude,
				longitude: viewport.longitude,
			});
		} else {
			setViewport(newViewport);
		}
	};

	const radius = maxDistance / 1000;
	const options = { steps: 50, units: "kilometers", properties: { foo: "bar" } };

	const circle = turfCircle([longitude, latitude], radius, options);
	const line = lineString(...circle.geometry.coordinates);

	if (!coords) return;
	return (
		<Wrapper
			padding="0"
			top="0"
			left="0"
			position="absolute"
			width="100%"
			height="100%">
			<Map
				{...viewport}
				width="100%"
				height="100%"
				mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
				mapStyle={MapUtils.getMapStyle()}
				onViewportChange={!!isControllerCoords && handleViewportChange}>
				<MarkUser latitude={viewport.latitude} longitude={viewport.longitude} />

				{!isCloseMap && (
					<>
						<div style={{ position: "absolute", top: 0, left: 10 }}>
							<ScaleControl maxWidth={100} unit={"metric"} />
						</div>
						<Source id="my-data" type="geojson" data={circle}>
							<Layer
								id="point-90-hi"
								type="fill"
								paint={{
									"fill-color": "#088",
									"fill-opacity": 0.4,
									"fill-outline-color": "yellow",
								}}
							/>
						</Source>
					</>
				)}
				<Source id="my-ata" type="geojson" data={line}>
					<Layer
						id="point-9-hi"
						type="line"
						paint={{
							"line-color": "red",
							"line-width": 2,
						}}
					/>
				</Source>
			</Map>
		</Wrapper>
	);
};

export default WrapperMap;
