//Libs
import styled, { css } from "styled-components";

const SpanStyled = styled.span`
  width: ${(props) => (props.width ? props.width : props.theme.span.width)};
  height: ${(props) => (props.height ? props.height : props.theme.span.height)};
  max-height: ${(props) =>
    props.maxHeight ? props.maxHeight : props.theme.span.maxHeight};
  min-width: ${(props) =>
    props.minWidth ? props.minWidth : props.theme.span.minWidth};
  max-width: ${(props) =>
    props.maxWidth ? props.maxWidth : props.theme.span.maxWidth};
  margin: ${(props) => (props.margin ? props.margin : props.theme.span.margin)};
  padding: ${(props) =>
    props.padding ? props.padding : props.theme.span.padding};
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : props.theme.fonts.family};
  font-size: ${(props) =>
    props.fontSize
      ? props.theme.fonts.size[props.fontSize]
      : props.theme.fonts.size.default};
  color: ${(props) => (props.color ? props.color : props.theme.span.color)};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.span.fontWeight};
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.span.backgroundColor};
  text-align: ${(props) =>
    props.textAlign ? props.textAlign : props.theme.span.textAlign};
  overflow: ${(props) =>
    props.overflow ? props.overflow : props.theme.span.overflow};
  word-break: ${(props) => props.wordBreak};
  opacity: ${(props) => props.opacity};
  cursor: ${(props) => (props.cursor ? props.cursor : props.theme.span.cursor)};
  ${(props) =>
    props.ellipsis &&
    css`
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    `}
  ${(props) =>
    props.whiteSpace &&
    css`
      white-space: ${props.whiteSpace};
    `}
  ${(props) =>
    props.hoverColor &&
    css`
      &:hover {
        color: ${(props) =>
          props.hoverColor ? props.hoverColor : props.theme.span.hover.color};
      }
    `}
  ${(props) =>
    props.activeColor &&
    css`
      &:active {
        color: ${(props) =>
          props.activeColor
            ? props.activeColor
            : props.theme.span.active.color};
      }
    `}
`;

export default SpanStyled;
