//Libs
import React from "react";
import styled from "styled-components";
//Components
import icon from "./synapseLogo.svg";

const Icon = styled.div`
  background-image: url(${icon});
  width: 164px;
  height: 40px;
`;

const Syn4pseLogo = () => <Icon />;

export default Syn4pseLogo;
