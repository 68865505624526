//Libs
import { Menu } from "antd";
import styled from "styled-components";

const { SubMenu, ItemGroup, Item } = Menu;

const MenuStyled = styled(Menu)`
  color: ${(props) => (props.color ? props.color : props.theme.menu.color)};
  height: ${(props) => (props.height ? props.height : props.theme.menu.height)};
  overflow: ${(props) =>
    props.overflow ? props.overflow : props.theme.menu.overflow};
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.menu.backgroundColor};
  border-right: ${(props) => props.theme.menu.borderRight};
  .ant-menu-item-group-title {
    color: ${(props) =>
      props.groupColor ? props.groupColor : props.theme.menu.group.color};
  }
  .ant-menu-item-disabled {
    color: ${(props) =>
      props.disabledColor
        ? props.disabledColor
        : props.theme.menu.item.disabled.color} !important;
  }
`;

const SubMenuStyled = styled(SubMenu)``;

const Group = styled(ItemGroup)``;

const ItemStyled = styled(Item)`
  color: ${(props) =>
    props.color ? props.color : props.theme.menu.item.color};
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.menu.item.backgroundColor};
  &:hover {
    background-color: ${(props) =>
      props.hoverBackgroundColor
        ? props.hoverBackgroundColor
        : props.theme.menu.item.hover.backgroundColor};
  }
`;

export default {
  Wrapper: MenuStyled,
  SubMenu: SubMenuStyled,
  Item: ItemStyled,
  Group,
};
