import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.fonts.family};
    box-sizing: border-box;
  }

  /* SCROLLBAR */
  *::-webkit-scrollbar {
    width: ${(props) => props.theme.scrollbar.width};
    height: ${(props) => props.theme.scrollbar.height};
  }
  *::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumb.background};
    border-radius: ${(props) => props.theme.scrollbar.thumb.borderRadius};
    box-shadow: ${(props) => props.theme.scrollbar.thumb.boxShadow};
  }
  *::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.track.background};
  }

  /* ANT COMPONENTS */

  /* MENU */
  .ant-menu-item > a {
    color: ${(props) => props.theme.link.color};
  }
  .ant-menu-item:active, .ant-menu-submenu-title:active {
    background-color: ${(props) => props.theme.menu.active.backgroundColor};
  }
  .ant-menu-vertical .ant-menu-item {
    height: ${(props) => props.theme.menu.item.height};
    line-height: ${(props) => props.theme.menu.item.height};
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: ${(props) =>
      props.theme.menu.item.selected.backgroundColor};
  }
  .ant-menu-submenu > .ant-menu{
    background-color: ${(props) => props.theme.menu.submenu.backgroundColor};
  }
  .ant-menu-submenu-popup{
    background-color: ${(props) => props.theme.menu.submenu.backgroundColor};
  }

  /* DROPDOWN */
  .ant-dropdown {
    padding: 5px;
  }
  .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
    line-height: 30px;
    height: 40px;
  }

  /* TOOLTIP */
  .ant-tooltip-inner{
    background-color: ${(props) => props.theme.tooltip.backgroundColor}
  }

  /* SPIN */
  .ant-spin-nested-loading {
    width: ${(props) => props.theme.spin.width};
  }

  /* COLLAPSE */
  .ant-collapse-borderless > .ant-collapse-item{
    border-bottom: ${(props) => props.theme.collapse.borders.bottom}
  }

  /* POPOVER */
  .ant-popover-inner {
    background-color: ${(props) => props.theme.colors.secondary};
    box-shadow: ${(props) => props.theme.popover.inner.boxShadow};
    border-radius: ${(props) => props.theme.popover.inner.borderRadius};
    max-height: ${(props) => props.theme.popover.inner.maxHeight};
    overflow: ${(props) => props.theme.popover.inner.overflow};
  }
  .ant-popover-inner-content {
    padding: 5px;
    background-color: ${(props) => props.theme.colors.background.medium};
  }
  .ant-popover-title {
    background-color: ${(props) => props.theme.colors.background.medium};
  }

  /* PAGE */
  .page__background {
    background-color: ${(props) => props.theme.colors.background.medium};
  }
  
  /* TOOLBAR */
  .toolbar {
    height: 56px;
    background-color: ${(props) => props.theme.colors.background.high};
    border-bottom: ${(props) => props.theme.toolbar.borders.bottom};
  }
  .toolbar+.page__background+.page__content{
    top: 56px;
  }
  .toolbar.toolbar--material+.page__background+.page__content{
    top: 56px;
  }
  .toolbar--material__left, .toolbar--material__right{
    min-width: 45px;
  }
  
  /* SUBTOOLBAR */
  #subtoolbar-container {
    width: 100%;
    height: 48px;
    position: fixed;
    top: 56px;
    z-index: 1;
    border-bottom: ${(props) => props.theme.subtoolbar.borders.bottom};
    background-color: ${(props) => props.theme.colors.background.medium};
  }

  /* SPLITTER CONTAINER */
  .splitter-container {
    background-color: ${(props) => props.theme.colors.background.high};
    border-color: black;
  }

  /* NOTIFICATIONS */
  .header-notifications{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    width: 320px;
    color: #ffffff;
    font-family: 'Raleway';
    font-size: medium;
    border-bottom: #3f3f49 solid 1px;
  }
`;

export default GlobalStyle;
