//Libs
import React from "react";
import styled from "styled-components";
//Components
import IconButton from "@material-ui/core/IconButton";
import smallIcon from "./smallSearch.svg";
import icon from "./search.svg";

const SmallIcon = styled.div`
  background-image: url(${smallIcon});
  width: 20px;
  height: 20px;
`;
const Icon = styled.div`
  background-image: url(${icon});
  width: 24px;
  height: 24px;
`;

const SearchIcon = ({ onClick, small, onlyIcon }) =>
  onlyIcon ? (
    small ? (
      <SmallIcon onClick={onClick} />
    ) : (
      <Icon onClick={onClick} />
    )
  ) : (
    <IconButton onClick={onClick}>
      {small ? <SmallIcon /> : <Icon />}
    </IconButton>
  );

export default SearchIcon;
