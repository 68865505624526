//Libs
import React from "react";
import { connect } from "react-redux";
//Actions
import { mutateUninstallInventoryControl } from "./actions";
//Selectors
import {
  selectUninstallInventoryControl,
  selectToUninstallFamilies,
} from "./selectors";
//Components
import {
  Wrapper,
  Drawer,
  Empty,
  WrapperView,
  SearchIcon,
} from "components/components";
import {
  CustomToolbar,
  CustomSearchToolbar,
  BackComponent,
} from "components/containers/Dashboard/subs/Toolbar";
import { VirtualizedList } from "components/containers/Utils";
import { selectProfile } from "components/containers/Login/selectors";

const Title = ({
  title,
  setOut,
  searchInData,
  mutateUninstallInventoryControl,
}) =>
  searchInData.show ? (
    <CustomSearchToolbar
      value={searchInData.data}
      onChange={(e) =>
        mutateUninstallInventoryControl({
          searchInData: {
            ...searchInData,
            data: e.target.value,
          },
        })
      }
      onClose={() =>
        mutateUninstallInventoryControl({
          searchInData: {
            ...searchInData,
            show: false,
            data: "",
          },
        })
      }
    />
  ) : (
    <CustomToolbar
      title={title}
      left={
        <BackComponent
          handleOnClick={() => {
            setOut(true);
            setTimeout(
              () =>
                mutateUninstallInventoryControl({
                  show: false,
                }),
              400
            );
          }}
        />
      }
      right={
        <Wrapper padding="0">
          <SearchIcon
            onClick={() =>
              mutateUninstallInventoryControl({
                searchInData: {
                  ...searchInData,
                  show: true,
                },
              })
            }
          />
        </Wrapper>
      }
    />
  );

const UninstallInventoryList = ({
  //Props
  otdId,
  order,
  consumedItems,
  onChangeState,
  //Redux
  profile,
  uninstallInventoryControl,
  toUninstallFamilies,
  mutateUninstallInventoryControl,
}) => {
  const [out, setOut] = React.useState(false);

  React.useEffect(() => {
    let isMounted = true;
    if (out) {
      var st = setTimeout(() => isMounted && setOut(false), 400);
    }
    return () => {
      isMounted = false;
      clearTimeout(st);
    };
  }, [out]);

  return (
    <Drawer
      key={"uninstallInventory"}
      className={out ? "animated slideOutDown faster" : ""}
      placement={"bottom"}
      width={`${document.documentElement.clientWidth}px`}
      height="100%"
      closable={false}
      visible={uninstallInventoryControl.show}
      title={
        <Title
          title="Desinstalando"
          setOut={setOut}
          searchInData={uninstallInventoryControl.searchInData}
          mutateUninstallInventoryControl={mutateUninstallInventoryControl}
        />
      }
    >
      <WrapperView withToolbarHeight>
        {toUninstallFamilies.length === 0 ? (
          <Empty
            margin="20px 0 0 0"
            message="No hay inventario para desinstalar"
          />
        ) : (
          <VirtualizedList
            cardId="uninstallInventoryFamilyCard"
            listStyle={{
              width: document.documentElement.clientWidth,
              height: document.documentElement.clientHeight - 56,
            }}
            cardStyle={{
              width: 300,
              height: 140,
            }}
            rowHeight={150}
            rows={toUninstallFamilies}
            customProps={{
              otdId,
              orderId: order.order_id,
              ownerId: profile.user.id,
              entityId: profile.entity.id,
              consumedItems,
              onChangeState,
            }}
          />
        )}
      </WrapperView>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  profile: selectProfile(state),
  uninstallInventoryControl: selectUninstallInventoryControl(state),
  toUninstallFamilies: selectToUninstallFamilies(state),
});

const actions = {
  mutateUninstallInventoryControl,
};

export default connect(mapStateToProps, actions)(UninstallInventoryList);
