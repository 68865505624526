export default Object.freeze({
  WORKFLOW: {
    CONSUME: "consume", //default
    TRANSFER: "transfer",
  },
  TYPES: {
    ARTICLE: "article",
    SERIALIZED: "serialized",
    UNINSTALL_SERIALIZED: "uninstall_serialized",
  },
  STATUS: {
    LOADING: "loading",
    SUCCESS: "success",
    ERROR: "error",
    REJECT: "reject",
    DENY: "deny",
  },
  STATE: {
    OPEN: "OPEN",
    CLOSED: "CLOSED",
  },
  URI_CONTEXT: {
    GET_CURRENT_CONSUMED_ITEMS: "getCurrentConsumedItems",
    SAVE_CONSUMED_INVENTORY: "saveConsumedItems",
    AUTO_SYNC_CONSUMED_ITEMS: "autoSyncConsumedItems",
    GENERATE_RESUME: "generateResume",
  },
});
