import { SentryService } from "services";

//ORDERS
function getOrders() {
  return new Promise((resolve, reject) => {
    window.synapseIdb.get("orders", "orders", (orders) => {
      resolve(Array.isArray(orders) ? orders : []);
    });
  }).catch((err) => SentryService.sendError(err));
}
function setOrders(orders) {
  try {
    window.synapseIdb.set("orders", orders, "orders");
  } catch (err) {
    SentryService.sendError(err);
  }
}

//COMPLETED CODES
function getCompletedCodes() {
  return new Promise((resolve, reject) => {
    window.synapseIdb.get("completedCodes", "orders", (codes) => {
      resolve(Array.isArray(codes) ? codes : []);
    });
  }).catch((err) => SentryService.sendError(err));
}
function setCompletedCodes(codes) {
  try {
    window.synapseIdb.set("completedCodes", codes, "orders");
  } catch (err) {
    SentryService.sendError(err);
  }
}

//INVOICES
function getInvoices() {
  return new Promise((resolve, reject) => {
    window.synapseIdb.get("invoices", "invoices", (invoices) => {
      resolve(Array.isArray(invoices) ? invoices : []);
    });
  }).catch((err) => SentryService.sendError(err));
}
function setInvoices(invoices) {
  try {
    window.synapseIdb.set("invoices", invoices, "invoices");
  } catch (err) {
    SentryService.sendError(err);
  }
}

function deleteInvoice(orderId) {
  getInvoices()
    .then((invois) => {
      const invoices = invois.filter((invoice) => invoice.order_id !== orderId);
      window.synapseIdb.set("invoices", invoices, "invoices");
    })
    .catch((err) => SentryService.sendError(err));
}

function deleteInvoices(invoicesToDelete) {
  getInvoices()
    .then((invois) => {
      const invoices = invois.filter((invoice) => {
        let idx = invoicesToDelete.findIndex(
          (inv) => inv.order_id === invoice.order_id
        );
        return idx === -1;
      });
      window.synapseIdb.set("invoices", invoices, "invoices");
    })
    .catch((err) => SentryService.sendError(err));
}

function deleteAllInvoices(sendToast) {
  try {
    window.synapseIdb.clear("invoices");
  } catch (err) {
    SentryService.sendError(err);
    sendToast({
      description: "Por favor reinicie la aplicación y vuelva a intentarlo",
      type: "error",
    });
  }
}

function photosNeededSync() {
  return new Promise((resolve, reject) => {
    getInvoices().then((invoices) => {
      let counter = 0;
      invoices.forEach((invoice) => {
        invoice.photoReport.frames.forEach((photo) => {
          if (photo.src && photo.status !== "saved") counter++;
        });
      });
      resolve(counter);
    });
  }).catch((err) => SentryService.sendError(err));
}

//TEMPLATES
function getTemplates() {
  return new Promise((resolve, reject) => {
    window.synapseIdb.get("templates", "templates", (templates) => {
      resolve(Array.isArray(templates) ? templates : []);
    });
  }).catch((err) => SentryService.sendError(err));
}

function setTemplates(templates) {
  try {
    window.synapseIdb.set("templates", templates, "templates");
  } catch (err) {
    SentryService.sendError(err);
  }
}

//WAREHOUSE
function getToInvoiceInventory() {
  return new Promise((resolve, reject) => {
    window.synapseIdb.get("toInvoice", "warehouse", (toInvoice) => {
      resolve(Array.isArray(toInvoice) ? toInvoice : []);
    });
  }).catch((err) => SentryService.sendError(err));
}

function setToInvoiceInventory(toInvoice) {
  try {
    window.synapseIdb.set("toInvoice", toInvoice, "warehouse");
  } catch (err) {
    SentryService.sendError(err);
  }
}

function getToUninstallInventory() {
  return new Promise((resolve, reject) => {
    window.synapseIdb.get("toUninstall", "warehouse", (toUninstall) => {
      resolve(Array.isArray(toUninstall) ? toUninstall : []);
    });
  }).catch((err) => SentryService.sendError(err));
}

function setToUninstallInventory(toUninstall) {
  try {
    window.synapseIdb.set("toUninstall", toUninstall, "warehouse");
  } catch (err) {
    SentryService.sendError(err);
  }
}

//UPLOAD RESOURCES
function getUploadResources() {
  return new Promise((resolve, reject) => {
    window.synapseIdb.get("resources", "uploadResources", (resources) => {
      resolve(Array.isArray(resources) ? resources : []);
    });
  }).catch(SentryService.sendError);
}
function setUploadResources(resources) {
  try {
    window.synapseIdb.set("resources", resources, "uploadResources");
  } catch (err) {
    SentryService.sendError(err);
  }
}
function deleteAllUploadResources(sendToast) {
  try {
    window.synapseIdb.clear("uploadResources");
  } catch (err) {
    SentryService.sendError(err);
    sendToast({
      description: "Por favor reinicie la aplicación y vuelva a intentarlo",
      type: "error",
    });
  }
}

//COLLECTORS
function getCollectorValues() {
  return new Promise((resolve, reject) => {
    window.synapseIdb.get("manage", "reviewManage", (reviewManage) => {
      resolve(
        Array.isArray(reviewManage?.collectorValues)
          ? reviewManage.collectorValues
          : []
      );
    });
  }).catch((err) => SentryService.sendError(err));
}
function getAutoFillCollector() {
  return new Promise((resolve, reject) => {
    window.synapseIdb.get(
      "autoFillCollector",
      "reviewManage",
      (autoFillCollector) => {
        resolve(autoFillCollector || {});
      }
    );
  }).catch((err) => SentryService.sendError(err));
}
function getCollectorDuplicatedLayout() {
  return new Promise((resolve, reject) => {
    window.synapseIdb.get(
      "duplicatedCollectorLayout",
      "reviewManage",
      (duplicatedCollectorLayout) => {
        resolve(
          Array.isArray(duplicatedCollectorLayout)
            ? duplicatedCollectorLayout
            : []
        );
      }
    );
  }).catch((err) => SentryService.sendError(err));
}

function setCollectorValues(collectorValues) {
  try {
    window.synapseIdb.set("manage", collectorValues, "reviewManage");
  } catch (err) {
    SentryService.sendError(err);
  }
}
function setAutoFillCollector(autoFillCollector) {
  try {
    window.synapseIdb.set(
      "autoFillCollector",
      autoFillCollector,
      "reviewManage"
    );
  } catch (err) {
    SentryService.sendError(err);
  }
}
function setCollectorDuplicatedLayout(duplicatedCollectorLayout) {
  try {
    window.synapseIdb.set(
      "duplicatedCollectorLayout",
      duplicatedCollectorLayout,
      "reviewManage"
    );
  } catch (err) {
    SentryService.sendError(err);
  }
}

//CONSUMED INVENTORY
function getConsumedItems() {
  return new Promise((resolve) => {
    window.synapseIdb.get("manageInventory", "manage_inventory", (control) => {
      resolve(
        Array.isArray(control?.consumedItems) ? control.consumedItems : []
      );
    });
  }).catch((err) => SentryService.sendError(err));
}

function setManageInventoryControl(control) {
  try {
    window.synapseIdb.set("manageInventory", control, "manage_inventory");
  } catch (err) {
    SentryService.sendError(err);
  }
}

export default {
  //ORDERS
  getOrders,
  setOrders,
  getCompletedCodes,
  setCompletedCodes,
  //INVOICES
  getInvoices,
  setInvoices,
  deleteInvoice,
  deleteInvoices,
  deleteAllInvoices,
  photosNeededSync,
  //TEMPLATES
  getTemplates,
  setTemplates,
  //WAREHOUSE
  getToInvoiceInventory,
  setToInvoiceInventory,
  getToUninstallInventory,
  setToUninstallInventory,
  //UPLOAD RESOURCES
  getUploadResources,
  setUploadResources,
  deleteAllUploadResources,
  //REVIEW MANAGE
  getCollectorValues,
  getAutoFillCollector,
  getCollectorDuplicatedLayout,
  setCollectorValues,
  setAutoFillCollector,
  setCollectorDuplicatedLayout,
  //CONSUME INVENTORY
  getConsumedItems,
  setManageInventoryControl,
};
