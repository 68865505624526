//Libs
import _ from "underscore";
//  Utils
import { formatNumber } from "../../../utils/libs";

export function filterPaymentsSearchInData(payments, searchBy, data) {
  switch (searchBy) {
    case "id":
      return payments.filter((payment) =>
        payment.id.toString().includes(data.toUpperCase())
      );
    case "paid_to_name":
      return payments.filter((payment) =>
        payment.paid_to_name.toUpperCase().includes(data.toUpperCase())
      );
    case "state":
      return payments.filter((payment) =>
        payment.state_label.toUpperCase().includes(data.toUpperCase())
      );
    case "creator":
      return payments.filter((payment) =>
        payment.creator_name.toUpperCase().includes(data.toUpperCase())
      );
    case "entity":
      return payments.filter((payment) =>
        payment.entity_name.toUpperCase().includes(data.toUpperCase())
      );
    case "all":
      return payments.filter(
        (payment) =>
          payment.id.toString().includes(data.toUpperCase()) ||
          payment.paid_to_name.toUpperCase().includes(data.toUpperCase()) ||
          payment.state_label.toUpperCase().includes(data.toUpperCase()) ||
          payment.creator_name.toUpperCase().includes(data.toUpperCase()) ||
          payment.entity_name.toUpperCase().includes(data.toUpperCase())
      );
  }
}

export function getTotalProduction(orders, iva, coin_symbol) {
  const production = orders.reduce((acc, order) => {
      acc += Number(order.amount) + Number(order.bonus);
      return acc;
    }, 0.0),
    productionIva = (production * iva) / 100,
    productionTotal = production + productionIva;

  return {
    prod: formatNumber.new(production, coin_symbol, 2),
    prodIva: formatNumber.new(productionIva, coin_symbol, 2),
    prodTotal: formatNumber.new(productionTotal, coin_symbol, 2),
    productionTotal,
  };
}

export function getTotalBonifications(lines, iva, coin_symbol) {
  const bonification = lines.reduce((acc, line) => {
      acc += Number(line.amount);
      return acc;
    }, 0.0),
    bonificationIva = (bonification * iva) / 100,
    bonificationTotal = bonification + bonificationIva;

  return {
    bonus: formatNumber.new(bonification, coin_symbol, 2),
    bonusIva: formatNumber.new(bonificationIva, coin_symbol, 2),
    bonusTotal: formatNumber.new(bonificationTotal, coin_symbol, 2),
    bonificationTotal,
  };
}

export function getTotalDiscounts(lines, iva, coin_symbol) {
  const discount = lines.reduce((acc, line) => {
      acc += Number(line.amount);
      return acc;
    }, 0.0),
    discountIva = (discount * iva) / 100,
    discountTotal = discount + discountIva;

  return {
    disc: formatNumber.new(discount, coin_symbol, 2),
    discIva: formatNumber.new(discountIva, coin_symbol, 2),
    discTotal: formatNumber.new(discountTotal, coin_symbol, 2),
    discountTotal,
  };
}

export function getTotalToPay(
  productionTotal,
  bonificationTotal,
  discountTotal,
  coin_symbol
) {
  return formatNumber.new(
    productionTotal + bonificationTotal - discountTotal,
    coin_symbol,
    2
  );
}

export function getPaymentColorState(state) {
  if (state === "open") return { backgroundColor: "white" };
  else if (state === "ready") return { backgroundColor: "darkcyan" };
  else if (state === "agreed") return { backgroundColor: "deepskyblue" };
  else if (state === "released") return { backgroundColor: "yellow" };
  else if (state === "paid") return { backgroundColor: "green" };
}

export function groupResumeOrders(orders) {
  const grouped = [];

  orders.map((order) => {
    let idx = _.findIndex(
      grouped,
      (ord) => ord.task_id === order.task_id && ord.unitPrice === order.amount
    );
    if (idx === -1) {
      grouped.push({
        service_id: order.service_id,
        service_name: order.service_name,
        task_id: order.task_id,
        task_name: order.task_name,
        amount: order.amount,
        bonus: order.bonus,
        unitPrice: order.amount,
        devices: order.devices,
        distance: order.distance,
        totalCount: 1,
      });
    } else {
      grouped[idx].amount += order.amount;
      grouped[idx].bonus += order.bonus;
      grouped[idx].totalCount++;
    }
  });

  grouped.sort(function (a, b) {
    if (a.service_name > b.service_name) {
      return 1;
    }
    if (a.service_name < b.service_name) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });

  // orders.map(order => {
  //   let idx = _.findIndex(grouped, ord => ord.service_id === order.service_id);
  //   if (idx === -1) {
  //     grouped.push({
  //       service_id: order.service_id,
  //       service_name: order.service_name,
  //       amount: order.amount,
  //       bonus: order.bonus,
  //       totalCount: 1
  //     });
  //   } else {
  //     grouped[idx].amount += order.amount;
  //     grouped[idx].bonus += order.bonus;
  //     grouped[idx].totalCount++;
  //   }
  // });

  return grouped;
}

//El estado de los records se encuentra en el array <allowedStates>?
export function recordsHaveAllowedStates(records, allowedStates) {
  if (records.length === 0) return false;
  return records.reduce((acc, record) => {
    if (allowedStates.indexOf(record.state) === -1) {
      acc = false;
    }
    return acc;
  }, true);
}
