//Libs
import React from "react";
//Components
import { Wrapper, Title, LineDescription } from "components/components";

const ResumeOrderData = ({ order, mutate1ObjectInOrders }) => {
  const { order_id, odt_id, service_name, client_name } = order;
  return (
    <>
      <Title>Orden</Title>
      <Wrapper flexDirection="column" alignItems="stretch">
        <LineDescription
          valueEllipsis
          concept="Número de Orden"
          value={odt_id}
          // onClickValue={() =>
          //   mutate1ObjectInOrders("getOrderInformationModal", {
          //     order_id,
          //     isOpen: true,
          //   })
          // }
        />
        <LineDescription valueEllipsis concept="Cliente" value={client_name} />
        <LineDescription
          valueEllipsis
          concept="Servicio"
          value={service_name}
        />
      </Wrapper>
    </>
  );
};

export default ResumeOrderData;
