// Libs
import React, { useContext, useEffect, useState } from "react";
// Context
import { CoordContext } from "components/CoordManager";
// Components
import CoordPreview from "./CoordPreview";
import TakePhotoButton from "./TakePhotoButton";
import TakenPhotoConfirmation from "./TakenPhotoConfirmation";
import ControlPanelAccess from "./ControlPanelAccess";
import { CoordsMap } from "components/components";

const VideoControl = ({
  isPhotoTaken,
  maxRadiusMeters,
  takenPhotoConfirmationHeight,
  profile,
  orientation,
  canPlay,
  confirming,
  takePhoto,
  onTakenPhotoConfirm,
  onTakenPhotoCancel,
  onControlPanelOpen,
  onManualOrientationToggle,
}) => {
  const {
    coords,
    coordSync,
    manuallyDisabledGPS,
    setState: setCoordManagerState,
  } = useContext(CoordContext);

  //Play auto sync coords
  useEffect(() => {
    setCoordManagerState({ stopAutoSync: isPhotoTaken }, false);
    return () => {
      setCoordManagerState({ stopAutoSync: false }, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhotoTaken]);
  const [confirmedCoords, _setConfirmedCoords] = useState(coords);

  const setConfirmedCoords = (newCoords) =>
    _setConfirmedCoords((currentCoords) => ({
      ...currentCoords,
      ...newCoords,
    }));

  return (
    <>
      {isPhotoTaken ? (
        <>
          <TakenPhotoConfirmation
            height={takenPhotoConfirmationHeight}
            confirming={confirming}
            onTakenPhotoCancel={onTakenPhotoCancel}
            onTakenPhotoConfirm={() => onTakenPhotoConfirm(confirmedCoords)}
          />
          <CoordsMap
            coords={coords}
            setConfirmedCoords={setConfirmedCoords}
            maxDistance={maxRadiusMeters}
          />
        </>
      ) : (
        <>
          <CoordPreview
            coords={coords}
            coordSync={coordSync}
            manuallyDisabledGPS={manuallyDisabledGPS}
            profile={profile}
          />
          <ControlPanelAccess
            orientation={orientation}
            onControlPanelOpen={onControlPanelOpen}
            onManualOrientationToggle={onManualOrientationToggle}
          />
          {!manuallyDisabledGPS && (
            <TakePhotoButton
              canPlay={canPlay}
              takePhoto={() => takePhoto(coords)}
              orientation={orientation}
            />
          )}
        </>
      )}
    </>
  );
};

export default VideoControl;
