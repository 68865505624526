//  Libs
import React from "react";
import _ from "underscore";
import { Select, Input, Button } from "antd";
//  Styles
import "../../index.css";

const { Option } = Select;

function checkFilterUninstalledDevices(inventoryValidationTemplate) {
  return (
    inventoryValidationTemplate?.data_structure_object
      ?.filterUninstalledDevices &&
    Array.isArray(
      inventoryValidationTemplate.data_structure_object.filterUninstalledDevices
    )
  );
}

function findInventoryValidationItem(inventoryValidationTemplate, value) {
  return (
    inventoryValidationTemplate.data_structure_object.filterUninstalledDevices.indexOf(
      value
    ) !== -1
  );
}

export default function UninstallItemModal(props) {
  const {
    toUninstall,
    invoice,
    makeInvoicePersistInIDB,
    sendToast,
    inventoryValidationTemplate,
  } = props;
  const { uninstallModal } = invoice;
  const uninstalledSeries = [...invoice.uninstalled];

  const filteredToUninstall = React.useMemo(
    () =>
      checkFilterUninstalledDevices(inventoryValidationTemplate)
        ? toUninstall.filter((item) =>
            findInventoryValidationItem(
              inventoryValidationTemplate,
              item.item_id
            )
          )
        : toUninstall,
    [toUninstall, inventoryValidationTemplate]
  );

  return (
    <div style={{ padding: "10px" }}>
      {/* ITEM */}
      <Select
        placeholder="Equipo"
        className="select-custom"
        value={uninstallModal.item_id}
        onChange={(value) =>
          makeInvoicePersistInIDB({
            uninstallModal: { ...uninstallModal, item_id: value },
          })
        }
      >
        {filteredToUninstall.map((item, idx) => (
          <Option key={idx} value={item.item_id}>
            {item.item_name}
          </Option>
        ))}
      </Select>

      {/* SERIE */}
      <Input
        placeholder="Serie"
        maxLength={25}
        value={uninstallModal.serie}
        onChange={(e) =>
          makeInvoicePersistInIDB({
            uninstallModal: { ...uninstallModal, serie: e.target.value },
          })
        }
      />

      <div
        style={{
          marginTop: "15px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={() => {
            let idx = _.findIndex(
              invoice.uninstalled,
              (item) => item.serie === uninstallModal.serie
            );
            if (idx !== -1) {
              sendToast({
                message: "Esta serie ya fue desinstalada",
                type: "warn",
              });
              return;
            }
            let item = filteredToUninstall.filter(
              (itm) => itm.item_id === uninstallModal.item_id
            )[0];
            if (item) {
              uninstalledSeries.push({
                item_id: uninstallModal.item_id,
                serie: uninstallModal.serie,
                item_name: item.item_name,
                item_code: item.item_code,
                is_serialized: item.is_serialized,
                unavailable: item.unavailable,
              });
              makeInvoicePersistInIDB({
                uninstalled: uninstalledSeries,
                uninstallModal: {
                  ...uninstallModal,
                  item_id: undefined,
                  serie: undefined,
                },
              });
            }
          }}
        >
          Desinstalar
        </Button>
      </div>
    </div>
  );
}
