import styled from "styled-components";

const DashboardSubItem = styled.span`
  height: ${(props) => props.theme.dashboard.subitem.height};
  font-family: ${(props) => props.theme.dashboard.subitem.fontFamily};
  font-style: ${(props) => props.theme.dashboard.subitem.fontStyle};
  font-weight: ${(props) => props.theme.dashboard.subitem.fontWeight};
  font-size: ${(props) => props.theme.dashboard.subitem.fontSize};
  line-height: ${(props) => props.theme.dashboard.subitem.lineHeight};
  color: ${(props) => props.theme.dashboard.subitem.color};
`;

export default DashboardSubItem;
