//Libs
import React from "react";

export default ({ fill = "white", fillOpacity = "0.54" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="infoIconSVG"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="14"
      height="14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 1.33337C4.31992 1.33337 1.33325 4.32004 1.33325 8.00004C1.33325 11.68 4.31992 14.6667 7.99992 14.6667C11.6799 14.6667 14.6666 11.68 14.6666 8.00004C14.6666 4.32004 11.6799 1.33337 7.99992 1.33337ZM7.33325 11.3334V7.33337H8.66659V11.3334H7.33325ZM7.33325 4.66671V6.00004H8.66659V4.66671H7.33325Z"
        fill="white"
      />
    </mask>
    <g mask="url(#infoIconSVG)">
      <rect width="16" height="16" fill={fill} fillOpacity={fillOpacity} />
    </g>
  </svg>
);
