import styled, { css } from "styled-components";

const WrapperView = styled.div`
  width: ${(props) =>
    props.width ? props.width : props.theme.wrapperView.width};
  height: ${(props) => props.height};
  position: ${(props) =>
    props.position ? props.position : props.theme.wrapperView.position};
  top: ${(props) => props.theme.wrapperView.top};
  ${(props) =>
    props.withoutSubToolbarHeight &&
    css`
      top: ${(props) => props.theme.wrapperView.withoutSubToolbarHeight.top};
    `}
  ${(props) =>
    props.withToolbarHeight &&
    css`
      top: ${(props) => props.theme.wrapperView.withToolbarHeight.top};
    `}
`;

export default WrapperView;
