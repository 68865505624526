//Libs
import React from "react";
//Components
import { Drawer, Wrapper, LineDescription } from "components/components";

const moreInfoInitialState = {
  isOpen: false,
  historyId: undefined,
};
export const useMoreEventInfo = () => {
  const [eventMoreInfo, setEventMoreInfo] =
    React.useState(moreInfoInitialState);

  const onCloseMoreEventInfo = () =>
    setEventMoreInfo({ ...eventMoreInfo, isOpen: false });
  const onOpenMoreEventInfo = (historyId) =>
    setEventMoreInfo({ historyId, isOpen: true });

  return [eventMoreInfo, onCloseMoreEventInfo, onOpenMoreEventInfo];
};

export const haveMoreEventInfo = ({ event_props, details, to_order_id }) => {
  return (
    (event_props && event_props.transferBill && to_order_id) ||
    (details && details.reprogramAt) ||
    (details && details.comment)
  );
};

const MoreEventInfo = ({ history, eventMoreInfo, onCloseMoreEventInfo }) => {
  const event = React.useMemo(
    () => history.find((item) => item.id === eventMoreInfo.historyId) ?? {},
    [eventMoreInfo.historyId]
  );
  const { order_id, to_order_id, details, event_props } = event;

  return !eventMoreInfo.isOpen ? null : (
    <Drawer
      key={"eventMoreInfo"}
      placement={"bottom"}
      width={`${document.documentElement.clientWidth}px`}
      height="60%"
      closable={true}
      onClose={onCloseMoreEventInfo}
      visible={eventMoreInfo.isOpen}
      title="Detalle del Evento"
    >
      <Wrapper flexDirection="column" alignItems="stretch">
        {event_props && event_props.transferBill && to_order_id && (
          <LineDescription
            concept="Transferencia"
            value={`Se transfieren datos de facturación, de la orden ${order_id} a la ${to_order_id}`}
          />
        )}
        {details && details.reprogramAt && (
          <LineDescription
            concept="Fecha de programación"
            value={details.reprogramAt}
          />
        )}
        {details && details.comment && (
          <LineDescription concept="Comentario" value={details.comment} />
        )}
      </Wrapper>
    </Drawer>
  );
};

export default MoreEventInfo;
