//Libs
import React, { useContext } from "react";
//Context
import { PhotoContext } from "context";
//Components
import { Caption, Checkbox, Subtitle, Wrapper } from "components/components";

const SynapseCameraCheck = () => {
  const { isSynapseCameraActive, setSetting } = useContext(PhotoContext);
  return (
    <Checkbox
      checked={isSynapseCameraActive}
      onChange={(e) => setSetting("isSynapseCameraActive", e.target.checked)}
    >
      <Wrapper padding="0" flexDirection="column" alignItems="flex-start">
        <Subtitle>Utilizar cámara de Synapse</Subtitle>
        <Caption whiteSpace="wrap">
          Seleccione esta opción si desea utilizar la cámara de Synapse.
        </Caption>
      </Wrapper>
    </Checkbox>
  );
};

export default SynapseCameraCheck;
