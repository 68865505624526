//Libs
import React from "react";
import styled from "styled-components";
//Components
import IconButton from "@material-ui/core/IconButton";
import icon from "./comment.svg";

const Icon = styled.div`
  background-image: url(${icon});
  width: 24px;
  height: 24px;
  margin: 0 12px;
`;

const CommentIcon = ({ button, onClick }) =>
  button ? (
    <IconButton onClick={onClick}>
      <Icon />
    </IconButton>
  ) : (
    <Icon onClick={onClick} />
  );

export default CommentIcon;
