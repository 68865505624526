//Libs
import {
  takeLatest,
  takeEvery,
  put,
  call,
  all,
  select,
} from "redux-saga/effects";
import axios from "axios";
//Utils
import asyncErrorsHandler from "store/asyncErrorsHandler";
import GENERAL from "utils/constants/general";
import AuthService from "utils/libs/auth/AuthService";
import KEYWORDS from "./keywords";
//Selectors
import { selectMarketplaceItems } from "./selectors";

const { MODULES } = GENERAL;
const auth = new AuthService();

//******** WATCHER SAGAS ***********/
function* getMyActiveModulesWatcher() {
  yield takeLatest(MODULES.GET_MY_ACTIVE_MODULES, getMyActiveModulesWorker);
}
function* getDynamicMyActiveModuleItemsWatcher() {
  yield takeEvery(
    MODULES.GET_DYNAMIC_MY_ACTIVE_MODULE_ITEMS,
    getDynamicMyActiveModuleItemsWorker
  );
}
function* getMarketBannersWatcher() {
  yield takeLatest(MODULES.GET_MARKET_BANNERS, getMarketBannersWorker);
}
function* getMarketModulesWatcher() {
  yield takeLatest(MODULES.GET_MARKET_MODULES, getMarketModulesWorker);
}
function* getMarketItemsWatcher() {
  yield takeLatest(MODULES.GET_MARKET_ITEMS, getMarketItemsWorker);
}
function* updateMarketItemInstallationStatusWatcher() {
  yield takeLatest(
    MODULES.UPDATE_MARKETITEM_INSTALLATION_STATUS,
    updateMarketItemInstallationStatusWorker
  );
}

//******** WORKERS SAGAS ***********/
function* getMyActiveModulesWorker() {
  try {
    yield put({
      type: MODULES.MUTATE_1OBJECT,
      payload: {
        obj1Name: "accessControl",
        keyValuePairs: { state: KEYWORDS.STATE.LOADING },
      },
    });
    const { data: modules } = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat("/modules"),
      auth.sendToken()
    );
    yield put({
      type: MODULES.MUTATE_1OBJECT,
      payload: {
        obj1Name: "accessControl",
        keyValuePairs: { state: KEYWORDS.STATE.SUCCESS, modules },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        let state = KEYWORDS.STATE.ERROR;
        if (err.response.status === 401) {
          state = KEYWORDS.STATE.UNAUTHORIZED;
        }
        yield put({
          type: MODULES.MUTATE_1OBJECT,
          payload: {
            obj1Name: "accessControl",
            keyValuePairs: { state },
          },
        });
      },
      function* () {
        yield getMyActiveModulesWorker();
      }
    );
  }
}

function* getDynamicMyActiveModuleItemsWorker(action) {
  const { projectId, key, component, onLoading, onSuccess } = action.payload;
  if (typeof onSuccess !== "function") return;
  try {
    if (onLoading) yield put(onLoading());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(
        `/modules/v1/dynamicModuleItems/${projectId}/${key}/${component}`
      ),
      auth.sendToken()
    );
    yield put(onSuccess(data));
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getDynamicMyActiveModuleItemsWorker(action);
    });
  }
}

function* getMarketBannersWorker() {
  try {
    yield put({
      type: MODULES.MUTATE_MARKETPLACE,
      payload: {
        obj1Name: "banners",
        keyValuePairs: { isFetching: true },
      },
    });
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat("/modules/banners"),
      auth.sendToken()
    );

    yield put({
      type: MODULES.MUTATE_MARKETPLACE,
      payload: {
        obj1Name: "banners",
        keyValuePairs: { isFetching: false, data },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getMarketBannersWorker();
    });
  }
}

function* getMarketModulesWorker() {
  try {
    yield put({
      type: MODULES.MUTATE_MARKETPLACE,
      payload: {
        obj1Name: "modules",
        keyValuePairs: { isFetching: true },
      },
    });
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat("/modules/marketModules"),
      auth.sendToken()
    );
    yield put({
      type: MODULES.MUTATE_MARKETPLACE,
      payload: {
        obj1Name: "modules",
        keyValuePairs: { isFetching: false, data },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getMarketModulesWorker();
    });
  }
}

function* getMarketItemsWorker(action) {
  const { moduleId, lastRowId, limit } = action.payload;
  try {
    yield put({
      type: MODULES.MUTATE_MARKETPLACE,
      payload: {
        obj1Name: "items",
        keyValuePairs: { isFetching: true },
      },
    });
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(
        `/modules/marketItems/${moduleId}/${lastRowId}/${limit}`
      ),
      auth.sendToken()
    );
    yield put({
      type: MODULES.MUTATE_MARKETPLACE,
      payload: {
        obj1Name: "items",
        keyValuePairs: { isFetching: false, data },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getMarketItemsWorker(action);
    });
  }
}

function* updateMarketItemInstallationStatusWorker(action) {
  const marketItems = yield select(selectMarketplaceItems);
  const {
    marketItemId: moduleItemId,
    newStatus,
    coverage,
    users,
  } = action.payload;
  try {
    yield put({
      type: MODULES.MUTATE_MARKETPLACE,
      payload: {
        obj1Name: "items",
        keyValuePairs: { isFetching: true },
      },
    });
    const { data: item } = yield call(
      axios.put,
      process.env.REACT_APP_API_URL.concat(
        `/modules/marketItems/installation/status/${moduleItemId}`
      ),
      {
        newStatus,
        coverage,
        users,
      },
      auth.sendToken()
    );
    yield put({
      type: MODULES.MUTATE_MARKETPLACE,
      payload: {
        obj1Name: "items",
        keyValuePairs: {
          isFetching: false,
          data: marketItems.data.map((i) => (i.id === item.id ? item : i)),
        },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield updateMarketItemInstallationStatusWorker(action);
    });
  }
}

//Export default Root Saga
export default function* rootSaga() {
  yield all([
    getMyActiveModulesWatcher(),
    getMarketBannersWatcher(),
    getMarketModulesWatcher(),
    getMarketItemsWatcher(),
    updateMarketItemInstallationStatusWatcher(),
    getDynamicMyActiveModuleItemsWatcher(),
  ]);
}
