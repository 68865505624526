// Libs
import Pica from "pica";
import { SentryService } from "services";

const pica = Pica();

function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();
      reader.addEventListener("load", () => {
        let base64Data = reader.result;
        resolve(base64Data);
      });
      reader.readAsDataURL(blob);
    } catch (err) {
      SentryService.sendError(err);
      reject(null);
    }
  });
}

export default async function picaCompress(
  img,
  compressFormat,
  quality,
  { width: resizeWidth, height: resizeHeight }
) {
  const cvs = document.createElement("canvas");

  const imgWidth = Math.min(img.naturalWidth, img.width);
  const imgHeight = Math.min(img.naturalHeight, img.height);

  const isLandscape = imgWidth > imgHeight;

  const resizedWidth = Math.min(
    isLandscape ? resizeWidth : resizeHeight,
    imgWidth
  );
  const resizedHeight = Math.min(
    isLandscape ? resizeHeight : resizeWidth,
    imgHeight
  );
  cvs.width = resizedWidth;
  cvs.height = resizedHeight;

  return pica
    .resize(img, cvs, {
      quality: 3,
      unsharpAmount: 80,
      unsharpRadius: 0.6,
      unsharpThreshold: 2,
    })
    .then((result) => pica.toBlob(result, compressFormat, quality))
    .then((blob) => blobToBase64(blob))
    .then((base64Data) => base64Data)
    .catch((err) => {
      SentryService.sendError(err);
      return null;
    });
}
