// Libs
import React, { useState } from "react";

// Conponents
import { CloseIcon, Wrapper } from "components/components";
import WrapperMap from "./WrapperMap";

// styles
import styles from "./CoordsMap.module.css";

const CloseButton = ({ onClick }) => (
  <Wrapper
    position="absolute"
    top="25%"
    right="0"
    padding="0"
    backgroundColor="rgb(0,0,0)"
    borderRadius="15px 0 15px"
    width="50px"
    height="50px"
    onClick={onClick}
  >
    <CloseIcon color="white" fillOpacity="1" />
  </Wrapper>
);

const CoordsMap = ({ coords, setConfirmedCoords, maxDistance }) => {
  const initialState = {
    isCloseMap: true,
    position: {
      width: "100px",
      height: "100px",
      bottom: "53px",
      left: "0",
    },
  };

  const [stateMap, _setStateMap] = useState(initialState);
  const setStateMap = (newState) =>
    _setStateMap((current) => ({ ...current, ...newState }));

  const onWrapperMap = () => {
    if (!stateMap.isCloseMap) {
      return setStateMap(initialState);
    }

    setStateMap({
      isCloseMap: !stateMap.isCloseMap,
      position: {
        width: "100%",
        height: "50%",
        top: "25%",
        left: "0",
        right: "0",
      },
    });
  };

  const { position } = stateMap;

  return (
    <div
      className={styles[!stateMap.isCloseMap ? "backgrounBlur" : "container"]}
    >
      <Wrapper
        position="relative"
        onClick={stateMap.isCloseMap && onWrapperMap}
        borderRadius="15px"
        overflow="hidden"
        // Position
        width={position.width}
        height={position.height}
        top={position.top}
        right={position.right}
        left={position.left}
        bottom={position.bottom}
      >
        <WrapperMap
          coords={coords}
          setConfirmedCoords={setConfirmedCoords}
          isCloseMap={stateMap.isCloseMap}
          maxDistance={maxDistance}
          isControllerCoords={!!maxDistance}
        />
      </Wrapper>
      {!stateMap.isCloseMap && <CloseButton onClick={onWrapperMap} />}
    </div>
  );
};
export default CoordsMap;
