//Libs
import axios from "axios";
//Services
import SentryService from "./sentryService";
import AuthService from "utils/libs/auth/AuthService";
//Utils
import { GlobalUtils } from "utils";
//Components
import { notification } from "antd";

const auth = new AuthService();

const apiService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const ERROR = {
  DEFAULT_MESSAGE:
    "Tuve un problema al procesar su solicitud. Por favor inténtalo más tarde",
};

const METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

/**
 * Step 1: Add your new authorized endpoint key
 * All authorized endpoint keys
 */
const ENDPOINT_KEYS = {
  //MANAGE COLLECTOR
  GENERATE_REPORT: "GENERATE_REPORT",
  //MANAGE COMMENTS
  GET_COMMENTS: "GET_COMMENTS",
  //MANAGE INVENTORY
  //Get
  GET_WTD: "GET_WTD",
  //Update
  UPDATE_WTD: "UPDATE_WTD",
};

/**
 * Step 2: Add your new endpoint configuration
 * Api service endpoint configuration
 */
const ENDPOINT_CONFIGS = {
  [ENDPOINT_KEYS.GENERATE_REPORT]: {
    method: METHODS.POST,
  },
  [ENDPOINT_KEYS.GET_COMMENTS]: {
    method: METHODS.GET,
  },
  [ENDPOINT_KEYS.GET_WTD]: {
    method: METHODS.GET,
  },
  [ENDPOINT_KEYS.UPDATE_WTD]: {
    method: METHODS.PUT,
  },
};

/**
 * Step 3: Add your new endpoint uri
 * @param {string} endpoint - Any ENDPOINT_KEYS prop
 * @param {Object} reqParams - Custom props to inject on request params
 * @usage // Props usage eg: [ENDPOINT_KEYS.GENERATE_REPORT]: `/collector_values/generateReportFromAuditedOrder/${reqParams.myParam}`
 * @returns Fully built endpoint
 */
const authorizedEndpoints = (endpoint, reqParams = {}) => {
  return {
    [ENDPOINT_KEYS.GENERATE_REPORT]: `/collector_values/generateReportFromAuditedOrder`,
    [ENDPOINT_KEYS.GET_COMMENTS]: `/commentManager/v1/${reqParams.resourceTable}/${reqParams.resourceId}/${reqParams.parentId}/${reqParams.lastRowId}/${reqParams.limit}`,
    [ENDPOINT_KEYS.GET_WTD]: `/inventoryManager/v1/wtd/${reqParams.transactionTypekey}/${reqParams.wtdId}/${reqParams.orderId}`,
    [ENDPOINT_KEYS.UPDATE_WTD]: `/inventoryManager/v1/wtd/${reqParams.wtdId}`,
  }[endpoint];
};

const checkBodyParams = (method, bodyParams) => {
  if (method !== METHODS.POST && method !== METHODS.PUT) return;
  try {
    return JSON.parse(JSON.stringify(bodyParams));
  } catch (err) {
    SentryService.sendError(err);
    return;
  }
};

const showErrorMessage = ({
  message = ERROR.DEFAULT_MESSAGE,
  type = "warn",
}) => {
  notification.warn({
    message,
    type,
    duration: 5,
    className: "notification-dark-style",
  });
};

const buildRequest = ({ authorizedEndpoint, config, bodyParams }) => ({
  method: config.method,
  url: authorizedEndpoint,
  data: checkBodyParams(config.method, bodyParams),
  headers: {
    Authorization: `Bearer ${auth.getToken()}`,
  },
});

export default class ApiService {
  /**
   * @returns all authorized endpoints to use
   */
  static endpoints() {
    return ENDPOINT_KEYS;
  }
  /**
   * Step 3: Call fetch method with:
   * @param {string} endpoint - Any ENDPOINT_KEYS prop
   * @param {Object} reqParams - Custom props to inject on request params
   * @param {Object} bodyParams - Custom props to inject on body params
   * @returns {Object || Object[]} return api response data object || Error[]
   */
  static async fetch(endpoint, { reqParams, bodyParams }) {
    return new Promise(async (resolve, reject) => {
      const authorizedEndpoint = authorizedEndpoints(endpoint, reqParams);
      const config = ENDPOINT_CONFIGS[endpoint];
      if (!authorizedEndpoint || !config) {
        showErrorMessage({ message: "Parámetros inválidos" });
        return;
      }
      try {
        const { data } = await apiService(
          buildRequest({
            authorizedEndpoint,
            config,
            bodyParams,
          })
        );
        return resolve(data);
      } catch (err) {
        const resData = err?.response?.data || {};
        const resStatus = err.response.status || 0;
        //Show multiple errors?
        if (
          (resData.showError || resStatus >= 500) &&
          GlobalUtils.checkArray(resData.errors).length > 0
        ) {
          return reject(resData.errors);
        }
        showErrorMessage({
          message: resData.message,
          type: resData.type,
        });
      }
    });
  }
}
