//Libs
import Immutable from "immutable";
import { createSelector } from "reselect";
//Selectors
import { selectTemplates } from "store/api/selectors";
//Components
import { ConsumeInventoryUtils } from "components/components";

const selectConsumeInventory = (state) => state.manageInventory;
const selectCollector = (state) => state.collector;
const selectApi = (state) => state.api;
const selectOrders = (state) => state.orders;

export const selectCompleteOrderId = createSelector(
  [selectCollector],
  ({ reviewManage }) => reviewManage.orderId
);
export const selectCompleteOrder = createSelector(
  [selectCompleteOrderId, selectOrders],
  (orderId, { control }) =>
    control.orders.find((ord) => ord.order_id === orderId)
);
export const selectInventoryValidationTemplate = createSelector(
  [selectTemplates, selectCompleteOrder],
  (templates, order) => {
    if (!order) return null;
    return templates.find(
      (t) => t.template_type_id === 2 && t.department_id === order.department_id
    );
  }
);

export const selectManageInventoryControl = createSelector(
  [selectConsumeInventory],
  ({ control }) => control
);

export const selectManageInventorySending = createSelector(
  [selectManageInventoryControl],
  ({ sending }) => sending
);

export const selectWorkflow = createSelector(
  [selectManageInventoryControl],
  ({ workflow }) => workflow
);
export const selectEditableInventory = createSelector(
  [selectManageInventoryControl],
  ({ editableInventory }) => editableInventory
);
export const selectControlledInventory = createSelector(
  [selectManageInventoryControl],
  ({ controlledInventory }) => controlledInventory
);

export const selectConsumeInventoryAutoSync = createSelector(
  [selectConsumeInventory],
  ({ control, autoSync }) => ({
    active: autoSync.active,
    running: autoSync.isFetching || control.isFetching || control.sending,
  })
);

export const selectUninstallInventoryControl = createSelector(
  [selectConsumeInventory],
  ({ control }) => control.uninstallInventoryControl
);

export const selectResumeControl = createSelector(
  [selectConsumeInventory],
  ({ control }) => control.resumeControl
);

export const selectSearchInDataUninstallFamilies = createSelector(
  [selectConsumeInventory],
  ({ control }) => control.uninstallInventoryControl.searchInData
);

export const selectConsumedItems = createSelector(
  [selectConsumeInventory],
  ({ control }) => Immutable.List(control.consumedItems).toJS()
);

export const selectWTD = createSelector(
  [selectConsumeInventory],
  ({ control }) => control.wtd
);

export const selectIsFetching = createSelector(
  [selectConsumeInventory],
  ({ control }) => control.isFetching
);
export const selectIsFetchingWTD = createSelector(
  [selectConsumeInventory],
  ({ control }) => control.isFetchingWTD
);

export const selectPendingConsumedItemCountFromOrder = createSelector(
  [selectCompleteOrderId, selectConsumedItems],
  (orderId, consumedItems) =>
    ConsumeInventoryUtils.getUnsuccessConsumedItemsCountFromOrder(
      orderId,
      consumedItems
    )
);

export const selectToInvoiceInventory = createSelector([selectApi], (api) =>
  ConsumeInventoryUtils.filterArticleInventory(
    Immutable.List(api.warehouse.toInvoice.data).toJS()
  )
);

export const selectToInvoiceSerializedInventory = createSelector(
  [selectApi, selectCompleteOrder],
  (api, order) =>
    ConsumeInventoryUtils.filterInventoryWithPepsValidation(
      order,
      ConsumeInventoryUtils.filterSerializedInTransit(
        order?.order_id,
        ConsumeInventoryUtils.filterSerializedInventory(
          Immutable.List(api.warehouse.toInvoice.data).toJS()
        )
      )
    )
);

export const selectToUninstallInventory = createSelector(
  [selectApi, selectInventoryValidationTemplate],
  (api, inventoryValidationTemplate) =>
    ConsumeInventoryUtils.filterToUninstallInventoryItemsWithTemplateProp(
      inventoryValidationTemplate,
      Immutable.List(api.warehouse.toUninstall).toJS()
    )
);

export const selectToUninstallFamilies = createSelector(
  [selectToUninstallInventory, selectSearchInDataUninstallFamilies],
  (toUninstallInventory, searchInData) =>
    ConsumeInventoryUtils.filterInventoryWithSearchingValue(
      searchInData.data,
      ConsumeInventoryUtils.getUninstallFamiliesAndMembers(toUninstallInventory)
    )
);

export const selectConsumedItemsResume = createSelector(
  [
    selectCompleteOrder,
    selectEditableInventory,
    selectToInvoiceInventory,
    selectToInvoiceSerializedInventory,
    selectToUninstallInventory,
    selectConsumedItems,
  ],
  (
    order,
    editableInventory,
    toInvoiceInventory,
    toInvoiceSerializedInventory,
    toUninstallInventory,
    consumedItems
  ) =>
    ConsumeInventoryUtils.getConsumedItemsResume(
      order?.order_id,
      editableInventory,
      consumedItems,
      toInvoiceInventory
        .concat(toInvoiceSerializedInventory)
        .concat(toUninstallInventory)
    )
);

export const selectEditableInventoryData = createSelector(
  [
    selectToInvoiceInventory,
    selectToInvoiceSerializedInventory,
    selectConsumedItemsResume,
    selectCollector,
  ],
  (
    toInvoiceInventory,
    toInvoiceSerializedInventory,
    consumedItemsResume,
    collectorEcosystem
  ) => ({
    toInvoiceInventory: ConsumeInventoryUtils.filterInventoryWithSearchingValue(
      collectorEcosystem.reviewManage.searchInData.data,
      toInvoiceInventory.concat(consumedItemsResume.articles)
    ),
    toInvoiceSerializedInventory:
      ConsumeInventoryUtils.filterInventoryWithSearchingValue(
        collectorEcosystem.reviewManage.searchInData.data,
        toInvoiceSerializedInventory.concat(consumedItemsResume.serialized)
      ),
    uninstallConsumedItems:
      ConsumeInventoryUtils.filterInventoryWithSearchingValue(
        collectorEcosystem.reviewManage.searchInData.data,
        consumedItemsResume.uninstallSerialized
      ),
  })
);
