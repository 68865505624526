//Libs
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// Actions
import { sendToast } from "components/containers/Dashboard/actions";
//Hooks
import { useTheme } from "styled-components";
import { useUserManager } from "hooks";
//Components
import {
  Wrapper,
  Title,
  Select,
  LogoIcon,
  Avatar,
} from "components/components";
// Utils
import { CloudStorage } from "components/AppConfig.json";

const { Option } = Select;

const SelectUserProfileActive = ({ userId }) => {
  const { getEntityAssignment, setUserProfileActive } = useUserManager();
  const dispatch = useDispatch();

  const [state, _setState] = useState({
    entityAssignment: [],
    isLoading: false,
    isOpen: false,
  });

  const setState = (newData) =>
    _setState((current) => ({ ...current, ...newData }));

  const onSetUserProfileActive = (entityId) => {
    setUserProfileActive({ userId, entityId }).then(() => {
      window.location.reload();
    });
  };

  useEffect(() => {
    let isMounted = true;
    setState({ isLoading: true });
    console.log(userId);
    getEntityAssignment(userId)
      .then((entityAssignment) => {
        if (isMounted) setState({ entityAssignment, isLoading: false });
      })
      .catch(({ message, type }) => {
        if (!isMounted) return;
        dispatch(
          sendToast({
            message:
              message ||
              "Tuve un problema al realizar la consulta de los datos. Por favor inténtalo más tarde",
            type: type || "warn",
          })
        );
        setState({ isLoading: false });
      });
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  if (!state.entityAssignment.length) return null;

  const currentEntity = state.entityAssignment.find(
    (entity) => !!entity.selected
  );

  return (
    <Wrapper width="100%" flexDirection="column" alignItems="stretch">
      <Select
        width="100%"
        value={
          !state.isOpen || state.isLoading
            ? currentEntity.name
            : currentEntity.id
        }
        onChange={onSetUserProfileActive}
        onDropdownVisibleChange={(visible) => setState({ isOpen: visible })}
        dropdownRender={(menu) => (
          <>
            {menu}
            {state.isLoading && (
              <Wrapper width="100%" justifyContent="center">
                <LogoIcon spin={true} />
              </Wrapper>
            )}
          </>
        )}
      >
        {state.entityAssignment.map((entity) => (
          <Option key={entity.id} value={entity.id}>
            {entity.name}
          </Option>
        ))}
      </Select>
    </Wrapper>
  );
};

export default function UserProfileData(props) {
  const { userId, userName } = props;
  const theme = useTheme();

  return (
    <Wrapper
      width="100%"
      padding="0"
      margin="0"
      justifyContent="center"
      flexDirection="column"
      backgroundColor={theme.colors.primary}
      overflow="auto"
    >
      <Wrapper
        width="100%"
        gap="15px"
        margin="0"
        justifyContent="center"
        flexDirection="column"
      >
        <Avatar
          fit
          avatar={{
            src: CloudStorage.concat(
              `/syn4pse/users/avatars/${
                userId ? `${userId}.jpg` : `default.svg`
              }`
            ),
          }}
        />

        <Title>{userName}</Title>
        <Wrapper
          padding="0 16px"
          width="50%"
          gap="5px"
          flexDirection="column"
          justifyContent="center"
        >
          <SelectUserProfileActive userId={userId} />
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
}
