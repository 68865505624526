import styled from "styled-components";
import { Select } from "antd";

const SelectStyled = styled(Select)`
  width: ${(props) => props.width ?? props.theme.select.width};
  padding: ${(props) => props.padding ?? props.theme.select.padding};
  margin: ${({ margin }) => margin};
  .ant-select-selection {
    background-color: transparent;
    color: ${({ color, theme }) => color || theme.select.color};
  }
`;

export default SelectStyled;
