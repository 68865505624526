//Libs
import React from "react";
import { useTheme } from "styled-components";
//Components
import NormalIcon from "./NormalIcon";

const SelectedIcon = ({ theme, size, fill }) => {
  if (size === theme.sizes.medium.name) return <NormalIcon fill={fill} />;
};

const ResidentialIcon = ({ size, selected }) => {
  const theme = useTheme();
  const [hover, setHover] = React.useState(false);

  const fill = React.useMemo(
    () =>
      hover || selected ? theme.colors.hover.color : theme.colors.text.high,
    [hover, selected]
  );

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <SelectedIcon theme={theme} size={size} fill={fill} />
    </div>
  );
};

export default ResidentialIcon;
