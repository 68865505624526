// Libs
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// Utils
import GENERAL from "utils/constants/general";
// Actions
import { sendToast } from "components/containers/Dashboard/actions";
// Hooks
import { useUserManager } from "hooks";
// Components
import { IconButton, Input, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { PersonIcon } from "components/components/Icons";
import { Button, Link, Logo, LogoIcon } from "components/components";
// Styles
import { withStyles } from "@material-ui/core/styles";
import styles from "./RecoveryPassword.module.css";

const { ENV } = GENERAL;

const customStyles = (theme) => ({
  button: {
    top: "10px",
    fontFamily: "Roboto",
  },
  textField: {
    width: "200px",
    minHeight: "48px",
    height: "48px",
    paddingLeft: "15px",
    backgroundColor: "rgba(255,255,255,.07)",
    color: "#000",
    marginBottom: "10px",
    fontFamily: "Roboto",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
});

const RecoveryPassword = ({ classes, sendToast }) => {
  const history = useHistory();
  const inputRef = useRef(null);

  const [state, setState] = useState({
    isLoading: false,
    step: 1,
    idc: "",
    recoveryCode: "",
    newPassword: "",
    showNewPassword: false,
    colorInput: "info",
  });

  const {
    recoveryPasswordRequest,
    recoveryPasswordConfirm,
    recoveryPasswordUpdate,
  } = useUserManager();

  const onInputFocus = () =>
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);

  useEffect(() => {
    // INFO
    if (state.step === 1) {
      sendToast({
        message: "Ingrese su usuario",
      });
      onInputFocus();
    } else if (state.step === 2) {
      sendToast({
        message: "Ingrese el codigo de recuperación que recibió en su correo",
      });
      setState((prev) => ({ ...prev }));
    } else if (state.step === 3) {
      sendToast({
        message: "Ingrese su nueva contraseña",
      });
      setState((prev) => ({ ...prev }));
    }
  }, [state.step, inputRef?.current]);

  const onChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value?.trim(),
    }));
  };

  const onFetch = async () => {
    // logo loading
    setState((prev) => ({ ...prev, isLoading: true }));
    // fetch
    if (state.step === 1) {
      return recoveryPasswordRequest(state.idc).then(() => {
        setState((prev) => ({
          ...prev,
          step: 2,
          isLoading: false,
          colorInput: "info",
        }));
        onInputFocus();
      });
    } else if (state.step === 2)
      return recoveryPasswordConfirm(state.idc, state.recoveryCode).then(() => {
        setState((prev) => ({ ...prev, step: 3, isLoading: false }));
        onInputFocus();
      });
    else if (state.step === 3)
      return recoveryPasswordUpdate(state.idc, state.newPassword).then(() => {
        // INFO SUCCESS
        sendToast({
          message: "Se ha actualizado la contraseña exitosamente",
          type: "success",
        });
        history.push(ENV.ROUTES.PATHS.LOGIN);
      });
  };

  const onClick = () => {
    let err = false;
    let message = "";

    //Validations
    if (!state.idc) {
      err = true;
      message = "Ingrese su usuario";
    } else if (state.step === 2 && !state.recoveryCode) {
      err = true;
      message = "Ingrese su codigo de recuperación";
    } else if (
      state.step === 3 &&
      (!state.newPassword || state.newPassword.length <= 6)
    ) {
      if (!state.newPassword) {
        err = true;
        message = "Ingrese su nueva contraseña";
      } else if (state.newPassword.length <= 6) {
        err = true;
        message = "Su contraseña debe de ser mayor a 6 dígitos";
      }
    }

    if (err) {
      onInputFocus();
      setState((prev) => ({ ...prev, colorInput: "error" }));
      sendToast({
        message,
        type: "warning",
      });
      return;
    }

    onFetch().catch((err) => {
      sendToast({
        message: err.message,
        type: "error",
      });
      setState((prev) => ({
        ...prev,
        colorInput: "error",
        isLoading: false,
      }));
      onInputFocus();
    });
  };

  return (
    <>
      <div>
        <div className={styles.card}>
          {/* LOGO */}
          <Logo position="absolute" left="100px" top="-55px" opacity=".7" />

          <div className={styles.inputs}>
            {/* IDC */}
            <Input
              inputRef={state.step === 1 ? inputRef : null}
              color={state.colorInput}
              className={classes.textField}
              name="idc"
              placeholder="Usuario"
              disabled={state.step !== 1}
              onChange={onChange}
              value={state.idc}
              type="text"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <PersonIcon className={classes.icon} />
                  </IconButton>
                </InputAdornment>
              }
            />

            {/* RECOVERY CODE */}
            {state.step === 2 && (
              <Input
                inputRef={state.step === 2 ? inputRef : null}
                color={state.colorInput}
                className={classes.textField}
                name="recoveryCode"
                placeholder="Código de recuperación"
                onChange={onChange}
                value={state.recoveryCode}
                type="text"
              />
            )}

            {/* NEW PASSWORD */}
            {state.step === 3 && (
              <Input
                inputRef={state.step === 3 ? inputRef : null}
                className={classes.textField}
                color={state.colorInput}
                name="newPassword"
                placeholder="Nueva contraseña"
                onChange={onChange}
                value={state.newPassword}
                type={state.showNewPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          showNewPassword: !prev.showNewPassword,
                        }))
                      }
                    >
                      {state.showNewPassword ? (
                        <VisibilityOff className={classes.icon} />
                      ) : (
                        <Visibility className={classes.icon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          </div>

          <div className={styles.bottoms}>
            {state.isLoading ? (
              <LogoIcon spin />
            ) : (
              <Button mode="primary" onClick={onClick}>
                Siguiente
              </Button>
            )}
            <div>
              <span style={{ marginRight: "7px", color: "black" }}>
                ¿Ya tiene cuenta?
              </span>
              <Link style={{ color: "#1890ff" }} to={ENV.ROUTES.PATHS.LOGIN}>
                Ingresar
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const actions = { sendToast };

export default connect(
  null,
  actions
)(withStyles(customStyles)(RecoveryPassword));
