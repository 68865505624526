//Libs
import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getItemsTransactionDocs,
  getInventoryToTransfer,
} from "components/containers/Warehouse/actions";
import {
  mutate1Object as mutate1ObjectInCollector,
  doReset as doResetCollector,
} from "components/components/Collector/actions";
import { mutateDirectProps as mutateDirectPropsInAutoscan } from "components/components/Autoscan/actions";
//Selectors
import { selectReviewManage } from "components/components/Collector/selectors";
import { selectAutoscanControl } from "components/components/Autoscan/selectors";
//Utils
import GENERAL from "utils/constants/general";
//Components
import { Toolbar } from "react-onsenui";
import FormControl from "@material-ui/core/FormControl";
import ToolbarNotifications from "./Notifications";
import {
  CollectorUtils,
  ToolbarTitle,
  BackIcon,
  TextField,
  SearchIcon,
  CloseIcon,
} from "components/components";
//Toolbars
import {
  Orders,
  SearchOrder,
  CompleteOrderToolbar,
  SearchInCompleteOrder,
} from "./Orders";
import { Pays, SearchPays } from "./Payments";
import {
  Stock,
  SearchStock,
  Transactions,
  SearchTransactions,
  TransactionsInventory,
  SearchTransactionsInventory,
  TransactionsItems,
  SearchTransactionsItems,
} from "./Warehouse";
import { useTheme } from "styled-components";
import {
  selectTransactionInventoryLoading,
  selectTransactionItemLoading,
} from "components/containers/Warehouse/selectors";
//Styles
import "./index.css";

//Global constants
const { ENV } = GENERAL;
const { Input } = TextField;

//----------------------------------------------- JSX RENDER COMPONENTS ------------------------------------------------
export const HamburguerComponent = ({ synchronizingPhotos, handleOnClick }) => (
  <div
    style={synchronizingPhotos ? { color: "gray" } : undefined}
    className="style-toolbar-buttons hamburger-icon"
    onClick={!synchronizingPhotos && handleOnClick}
  />
);

export const BackComponent = ({ handleOnClick }) => (
  <BackIcon onClick={handleOnClick} />
);

const MainToolbar = ({ showNavBar, title, selectNotification }) => {
  return (
    <Toolbar className="toolbar">
      {/* LEFT */}
      <div className="left toolbar__left toolbar--material__left hamburger-button-container">
        {/* Hamburger */}
        <HamburguerComponent handleOnClick={showNavBar} />
      </div>

      {/* CENTER */}
      <div className="center toolbar__center toolbar__title toolbar--material__center">
        <ToolbarTitle>{title}</ToolbarTitle>
      </div>

      {/* RIGHT */}
      <div className="right toolbar__right toolbar--material__right">
        {/* Notifications */}
        <ToolbarNotifications selectNotification={selectNotification} />
      </div>
    </Toolbar>
  );
};

export const CustomToolbar = ({ left, title, right }) => {
  return (
    <Toolbar className="toolbar">
      {/* LEFT */}
      <div className="left toolbar__left toolbar--material__left hamburger-button-container">
        {left}
      </div>

      {/* CENTER */}
      <div className="center toolbar__center toolbar__title toolbar--material__center">
        <ToolbarTitle>{title}</ToolbarTitle>
      </div>

      {/* RIGHT */}
      <div
        className="right toolbar__right toolbar--material__right"
        style={{ display: "flex", alignItems: "center" }}
      >
        {right}
      </div>
    </Toolbar>
  );
};

export function CustomSearchToolbar({ value, onChange, onClose }) {
  const theme = useTheme();

  return (
    <Toolbar
      className="toolbar"
      style={{ backgroundColor: theme.colors.background.medium }}
    >
      {/* FILTER ORDER */}
      <FormControl
        id="toolbar-search-order-container"
        className="center animated fadeIn"
      >
        <Input
          id="toolbar-search-order-input"
          placeholder="Buscar..."
          autoFocus
          name="data"
          value={value}
          onChange={onChange}
          prefix={<SearchIcon small onlyIcon />}
          suffix={<CloseIcon small onlyIcon onClick={onClose} />}
        />
      </FormControl>
    </Toolbar>
  );
}

function RenderToolbar({
  history,
  showNavBar,
  mutateDirectProps,
  selectNotification,
  mutate1ObjectInOrders,
  mutate1ObjectInWarehouse,
  orders,
  payments,
  warehouse,
  autoSyncPhotoReports,
  resetCreateTransaction,
  mutate1ObjectInPayments,
  getOrders,
  doResetCollector,
  //Redux
  reviewManage,
  autoscanControl,
  isTransactionItemLoading,
  isTransactionInventoryLoading,
  getItemsTransactionDocs,
  getInventoryToTransfer,
  mutate1ObjectInCollector,
  mutateDirectPropsInAutoscan,
}) {
  const location = useLocation();

  const { id, title } = React.useMemo(() => {
    let id, title;
    for (let path in ENV.ROUTES.PATHS) {
      if (location.pathname === ENV.ROUTES.PATHS[path]) {
        title = ENV.ROUTES.TITLES[path];
        id = ENV.ROUTES.IDS[path];
        return {
          id,
          title,
        };
      }
    }
  }, [location]);

  switch (id) {
    case ENV.ROUTES.IDS.ORDERS_MANAGE:
      return orders.control.searchInData.renderToolbar ? (
        <SearchOrder
          mutate1ObjectInOrders={mutate1ObjectInOrders}
          orders={orders}
          history={history}
          getOrders={getOrders}
          mutateDirectPropsInDashboard={mutateDirectProps}
        />
      ) : (
        <Orders
          title={title}
          showNavBar={showNavBar}
          //Orders
          orders={orders}
          mutate1ObjectInOrders={mutate1ObjectInOrders}
          autoSyncPhotoReports={autoSyncPhotoReports}
          //Notifications
          selectNotification={selectNotification}
        />
      );
    case ENV.ROUTES.IDS.ORDERS_COMPLETE:
      return reviewManage.searchInData.renderToolbar ? (
        <SearchInCompleteOrder
          reviewManage={reviewManage}
          mutate1ObjectInCollector={mutate1ObjectInCollector}
        />
      ) : (
        <CompleteOrderToolbar
          title={title}
          reviewManage={reviewManage}
          autoscanControl={autoscanControl}
          onBackClick={() => {
            setTimeout(
              () => CollectorUtils.leftFromCollectorEcosystem(doResetCollector),
              200
            );
            history.goBack();
          }}
          mutate1ObjectInCollector={mutate1ObjectInCollector}
          mutateDirectPropsInAutoscan={mutateDirectPropsInAutoscan}
          //Notifications
          selectNotification={selectNotification}
        />
      );
    case ENV.ROUTES.IDS.PAYMENTS_MANAGE:
      return payments.paymentsManage.searchInData.renderToolbar ? (
        <SearchPays
          searchInData={payments.paymentsManage.searchInData}
          mutate1ObjectInPayments={mutate1ObjectInPayments}
        />
      ) : (
        <Pays
          title={title}
          showNavBar={showNavBar}
          searchInData={payments.paymentsManage.searchInData}
          mutate1ObjectInPayments={mutate1ObjectInPayments}
          //Notifications
          selectNotification={selectNotification}
        />
      );
    case ENV.ROUTES.IDS.WAREHOUSE_STOCK:
      return warehouse.searchInData.renderToolbar ? (
        <SearchStock
          searchInData={warehouse.searchInData}
          mutate1ObjectInWarehouse={mutate1ObjectInWarehouse}
        />
      ) : (
        <Stock
          title={title}
          searchInData={warehouse.searchInData}
          showNavBar={showNavBar}
          mutate1ObjectInWarehouse={mutate1ObjectInWarehouse}
          //Notifications
          selectNotification={selectNotification}
        />
      );
    case ENV.ROUTES.IDS.WAREHOUSE_TRANSACTIONS_MANAGE:
      return warehouse.transactionsManage.searchInData.renderToolbar ? (
        <SearchTransactions
          searchInData={warehouse.transactionsManage.searchInData}
          mutate1ObjectInWarehouse={mutate1ObjectInWarehouse}
        />
      ) : (
        <Transactions
          title={title}
          showNavBar={showNavBar}
          transactionsManage={warehouse.transactionsManage}
          mutate1ObjectInWarehouse={mutate1ObjectInWarehouse}
          //Notifications
          selectNotification={selectNotification}
        />
      );
    case ENV.ROUTES.IDS.WAREHOUSE_TRANSACTIONS_INVENTORY:
      return warehouse.inventoryToTransferManage.searchInData.renderToolbar ? (
        <SearchTransactionsInventory
          searchInData={warehouse.inventoryToTransferManage.searchInData}
          mutate1ObjectInWarehouse={mutate1ObjectInWarehouse}
        />
      ) : (
        <TransactionsInventory
          title={title}
          isTransactionInventoryLoading={isTransactionInventoryLoading}
          inventoryToTransferManage={warehouse.inventoryToTransferManage}
          mutate1ObjectInWarehouse={mutate1ObjectInWarehouse}
          //Notifications
          selectNotification={selectNotification}
          getInventoryToTransfer={getInventoryToTransfer}
          handleOnClick={() => {
            resetCreateTransaction();
            mutate1ObjectInWarehouse("transactions", {
              inventory: { loading: false, data: [] },
            });
            history.goBack();
          }}
        />
      );
    case ENV.ROUTES.IDS.WAREHOUSE_TRANSACTIONS_ITEMS:
      return warehouse.itemsTransactionDoc.searchInData.renderToolbar ? (
        <SearchTransactionsItems
          searchInData={warehouse.itemsTransactionDoc.searchInData}
          mutate1ObjectInWarehouse={mutate1ObjectInWarehouse}
        />
      ) : (
        <TransactionsItems
          title={title}
          isTransactionItemLoading={isTransactionItemLoading}
          transactionsManage={warehouse.transactionsManage}
          itemsTransactionDoc={warehouse.itemsTransactionDoc}
          mutate1ObjectInWarehouse={mutate1ObjectInWarehouse}
          getItemsTransactionDocs={getItemsTransactionDocs}
          //Notifications
          selectNotification={selectNotification}
          handleOnClick={() => {
            mutate1ObjectInWarehouse("transactionsManage", {
              docId: undefined,
            });
            mutate1ObjectInWarehouse("transactions", {
              items: { loading: false, data: [] },
            });
            history.goBack();
          }}
        />
      );
    default:
      return (
        <MainToolbar
          showNavBar={showNavBar}
          title={title}
          selectNotification={selectNotification}
        />
      );
  }
}

const mapStateToProps = (state) => ({
  reviewManage: selectReviewManage(state),
  autoscanControl: selectAutoscanControl(state),
  isTransactionItemLoading: selectTransactionItemLoading(state),
  isTransactionInventoryLoading: selectTransactionInventoryLoading(state),
});

const actions = {
  doResetCollector,
  getItemsTransactionDocs,
  getInventoryToTransfer,
  mutate1ObjectInCollector,
  mutateDirectPropsInAutoscan,
};
export default connect(mapStateToProps, actions)(RenderToolbar);
