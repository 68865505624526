//Libs
import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
//Utils
import GENERAL from "utils/constants/general";
//Selectors
import {
  selectSegmentedTransferedItems,
  selectSelectedTransaction,
  selectTransactionItemLoading,
  selectTransactionLoading,
} from "../../selectors";
import { selectProfile } from "components/containers/Login/selectors";
//Components
import { SummaryInventory } from "components/components";

const { ENV } = GENERAL;

const TransactionsItems = ({
  //State
  isTransactionItemLoading,
  isTransactionLoading,
  segmentedTransferedItems,
  selectedTransaction,
  profile,
  //Actions
  signWTD,
}) => {
  const { articles, serialized, uninstallSerialized } =
    segmentedTransferedItems;

  if (!selectedTransaction) {
    return <Redirect to={ENV.ROUTES.PATHS.WAREHOUSE_TRANSACTIONS_MANAGE} />;
  }
  const { id, dwh_owner_id, completed } = selectedTransaction;
  const canComplete = dwh_owner_id === profile.user?.id;

  return (
    <SummaryInventory
      minHeight={`${document.documentElement.clientHeight - 58}px`}
      articles={articles}
      serialized={serialized}
      uninstallSerialized={uninstallSerialized}
      canSign={!isTransactionItemLoading && !completed}
      isSigning={isTransactionLoading}
      confirmTitle="Recibiendo transacción"
      confirmMessage="¿Desea firmar y recibir la transacción?"
      signButtonTitle="Recibir"
      onConfirm={() => {
        signWTD(id, dwh_owner_id, canComplete);
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  isTransactionItemLoading: selectTransactionItemLoading(state),
  isTransactionLoading: selectTransactionLoading(state),
  segmentedTransferedItems: selectSegmentedTransferedItems(state),
  selectedTransaction: selectSelectedTransaction(state),
  profile: selectProfile(state),
});

const actions = {};

export default connect(mapStateToProps, actions)(TransactionsItems);
