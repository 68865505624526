// Services
import { ApiServiceV2 } from "services";

const { SOURCE, UTC } = {
  SOURCE: "SOURCE",
  UTC: "UTC",
};

const getDate = async () => {
  const osDatetime = {
    [SOURCE]: "os",
    [UTC]: new Date().toISOString(),
  };
  return ApiServiceV2.get({ url: `/utils/utc` })
    .then(({ time } = {}) =>
      !time
        ? osDatetime
        : {
            [SOURCE]: "online",
            [UTC]: time,
          }
    )
    .catch(() => osDatetime);
};

export default getDate;
