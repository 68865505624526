//Libs
import React from "react";
//Services
import MQTTService, { STATUS, TOPICS, EVENTS } from "services/mqttService";
//Hooks
import { useNavigatorInstance } from "hooks";

const QOS = 2;

const RealtimeContext = React.createContext({});
const { Provider, Consumer: RealtimeConsumer } = RealtimeContext;

//PROVIDER
const RealtimeProvider = ({ children }) => {
  const { navigatorInstance } = useNavigatorInstance();
  const [client, setClient] = React.useState(null);
  const [status, setStatus] = React.useState(STATUS.DISCONNECTED);
  const [payload, setPayload] = React.useState(null);

  //Connect
  const mqttConnect = () => {
    setStatus(STATUS.CONNECTING);
    setClient(MQTTService.connect(navigatorInstance));
  };

  // Events
  const onConnect = () => {
    console.log(STATUS.CONNECTED);
    setStatus(STATUS.CONNECTED);
  };
  const onReceiveMessage = (topic, _message) => {
    // console.log("Message receive: ", topic);
    const { action } = MQTTService.getDecodedTopic(topic);

    const message = MQTTService.getMessage(_message);
    if (!message) return;

    // Block my own message:
    // if (message.clientId === MQTTService.getClientId()) return;

    setPayload({ action, message });
  };
  const onReconnect = () => {
    console.log(STATUS.RECONNECTING);
    setStatus(STATUS.RECONNECTING);
  };
  const onError = (err) => {
    console.error("Connection error: ", err);
    client.end();
  };

  React.useEffect(() => {
    let isMounted = true;

    if (!client) {
      if (isMounted && navigatorInstance) mqttConnect();
    } else {
      const { CONNECT, RECONNECT, ERROR, MESSAGE } = EVENTS;
      client.on(CONNECT, () => isMounted && onConnect());
      client.on(RECONNECT, () => isMounted && onReconnect());
      client.on(ERROR, onError);
      client.on(
        MESSAGE,
        (topic, message) => isMounted && onReceiveMessage(topic, message)
      );
    }

    return () => {
      isMounted = false;
    };
  }, [client, navigatorInstance]);

  //Subscribe
  const mqttSub = React.useCallback(
    (topic, subscriber, { qos = QOS } = {}) => {
      if (!client || !topic) return;
      if (!MQTTService.validateSubscriber(subscriber)) return;

      const _topic = MQTTService.setTopic(topic, subscriber);
      if (!_topic) return;

      client.subscribe(_topic, { qos }, (error) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        }
        console.log(`Subscribed to topic: ${_topic}`);
      });
    },
    [client]
  );

  //Unsubscribe
  const mqttUnSub = React.useCallback(
    (topic, subscriber) => {
      if (!client || !topic) return;
      if (!MQTTService.validateSubscriber(subscriber)) return;

      const _topic = MQTTService.setTopic(topic, subscriber);
      if (!_topic) return;

      client.unsubscribe(_topic, (error) => {
        if (error) {
          console.log("Unsubscribe error", error);
          return;
        }
        console.log(`Unsubscribed from topic: ${_topic}`);
      });
    },
    [client]
  );

  //Publish
  const mqttPublish = React.useCallback(
    (topic, subscriber, payload, { qos = QOS, retain } = {}) => {
      if (!client || !topic) return;
      if (!MQTTService.validateSubscriber(subscriber)) return;
      if (!MQTTService.validatePayload(payload)) return;

      const _payload = {
        ...payload,
        clientId: MQTTService.getClientId(),
      };

      //Topic absolute path
      const _topic = MQTTService.setTopic(topic, subscriber);
      if (!_topic) return;

      //Stringify _payload
      const stringifyPayload = MQTTService.setPublisherPayload(_payload);

      client.publish(_topic, stringifyPayload, { qos, retain }, (error) => {
        if (error) {
          console.log("Publish error: ", error);
        }
      });
    },
    [client]
  );

  //Disconnect
  const mqttDisconnect = React.useCallback(() => {
    if (!client) return;
    client.end(() => setStatus(STATUS.DISCONNECTED));
  }, [client]);

  const value = React.useMemo(
    () => ({
      client,
      isConnected: status === STATUS.CONNECTED,
      payload,
      mqttSub,
      mqttUnSub,
      mqttPublish,
      mqttDisconnect,
    }),
    [client, status, payload]
  );

  return <Provider value={value}>{children}</Provider>;
};

export { RealtimeContext, RealtimeProvider, RealtimeConsumer, TOPICS };
