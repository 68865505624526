//Libs
import { takeLatest, put, call, all, select } from "redux-saga/effects";
import axios from "axios";
import _ from "underscore";
//Utils
import asyncErrorsHandler from "store/asyncErrorsHandler";
import AuthService from "utils/libs/auth/AuthService";
import GENERAL from "utils/constants/general";
import { formatData } from "utils/libs";
import { setFifoFlag } from "./utils";
//Selectors
import { selectStockSeries } from "./selectors";

const { ENV, DASHBOARD, WAREHOUSE } = GENERAL;
const auth = new AuthService();
const getProfile = (state) => state.login.profile;
const getTransactionsModal = (state) => state.warehouse.getTransactionsModal;
const getSerializedInTransaction = (state) =>
  state.warehouse.createTransaction.serialized;
const getSearchInDataStockSeries = (state) =>
  state.warehouse.stockSeriesManage.searchInData;

//Watchers
function* getStockArticlesWatcher() {
  yield takeLatest(WAREHOUSE.GET_STOCK_ARTICLES, getStockArticlesWorker);
}

function* getStockSeriesWatcher() {
  yield takeLatest(WAREHOUSE.GET_STOCK_SERIES, getStockSeriesWorker);
}

function* getTransactionDocsWatcher() {
  yield takeLatest(WAREHOUSE.GET_TRANSACTION_DOCS, getTransactionDocsWorker);
}

function* getItemsTransactionDocsWatcher() {
  yield takeLatest(
    WAREHOUSE.GET_ITEMS_TRANSACTION_DOCS,
    getItemsTransactionDocsWorker
  );
}

function* signWTDWatcher() {
  yield takeLatest(WAREHOUSE.SIGN_WTD, signWTDWorker);
}

function* getInventoryToTransferWatcher() {
  yield takeLatest(
    WAREHOUSE.GET_INVENTORY_TO_TRANSFER,
    getInventoryToTransferWorker
  );
}

function* sendTransferTransactionWatcher() {
  yield takeLatest(
    WAREHOUSE.SEND_TRANSFER_TRANSACTION,
    sendTransferTransactionWorker
  );
}

function* getSearchingSeriesWatcher() {
  yield takeLatest(WAREHOUSE.GET_SEARCHING_SERIES, getSearchingSeriesWorker);
}

function* deleteTransactionWatcher() {
  yield takeLatest(WAREHOUSE.DELETE_TRANSACTION, deleteTransactionWorker);
}

function* getMyFamiliesWatcher() {
  yield takeLatest(WAREHOUSE.GET_MY_FAMILIES, getMyFamiliesWorker);
}

//Workers
function* getStockArticlesWorker() {
  const profile = yield select(getProfile);

  try {
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "stockArticlesManage",
        keyValuePairs: { articles: { data: [], loading: true } },
      },
    });
    const ids = Buffer.from(JSON.stringify({ level: "warehouse" })).toString(
      "base64"
    );
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(`/inventoryManager/v1/stock/${ids}`),
      auth.sendToken()
    );
    const data = response.data.map((row) =>
      formatData(
        row,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    );
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "stockArticlesManage",
        keyValuePairs: { articles: { data, loading: false } },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getStockArticlesWorker();
    });
  }
}

function* getStockSeriesWorker(action) {
  const stockSeries = yield select(selectStockSeries);
  const profile = yield select(getProfile);
  const {
    get,
    itemIds,
    stateId,
    familyId,
    lastRowId = 0,
    limit,
  } = action.payload;

  try {
    yield put({
      type: WAREHOUSE.MUTATE_STOCK_SERIES_MANAGE,
      payload: {
        obj1Name: "series",
        keyValuePairs: {
          data: !lastRowId ? [] : stockSeries,
          loading: !lastRowId ? true : false,
        },
      },
    });
    const response = yield call(
      axios.post,
      process.env.REACT_APP_API_URL.concat(`/warehouses_items/v2/series/exp`),
      { get, itemIds, stateId, familyId, lastRowId, limit },
      auth.sendToken()
    );

    let data = response.data.map((row) =>
      formatData(
        row,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    );

    if (get === ENV.INVENTORY.SERIALIZED.GET.PEPS) data = setFifoFlag(data);

    yield put({
      type: WAREHOUSE.MUTATE_STOCK_SERIES_MANAGE,
      payload: {
        obj1Name: "series",
        keyValuePairs: {
          data: !lastRowId ? data : [...stockSeries, ...data],
          loading: false,
        },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: WAREHOUSE.MUTATE_STOCK_SERIES_MANAGE,
          payload: {
            obj1Name: "series",
            keyValuePairs: { loading: false },
          },
        });
      },
      function* () {
        yield getStockSeriesWorker(action);
      }
    );
  }
}

function* getTransactionDocsWorker(action) {
  const profile = yield select(getProfile);
  const { mode, docNumber, fromDate, toDate } = action.payload;

  try {
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "transactions",
        keyValuePairs: { data: [], loading: true },
      },
    });
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(
        `/warehouses_transactions_docs/v1/getDocs/exp/${mode}/${docNumber}/${fromDate.format(
          "YYYY-MM-DD"
        )}/${toDate.format("YYYY-MM-DD")}`
      ),
      auth.sendToken()
    );
    const data = response.data.map((row) =>
      formatData(
        row,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    );
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "transactions",
        keyValuePairs: { data, loading: false },
      },
    });
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "getTransactionsModal",
        keyValuePairs: { isOpen: false },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getTransactionDocsWorker(action);
    });
  }
}

function* getItemsTransactionDocsWorker(action) {
  const profile = yield select(getProfile);
  const wtdId = action.payload;

  try {
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "itemsTransactionDoc",
        keyValuePairs: { operation: undefined },
      },
    });
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "transactions",
        keyValuePairs: { items: { data: [], loading: true } },
      },
    });
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(
        `/warehouses_transactions_docs/getItems/${wtdId}`
      ),
      auth.sendToken()
    );
    const data = response.data.map((row) =>
      formatData(
        row,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    );
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "transactions",
        keyValuePairs: { items: { data, loading: false } },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getItemsTransactionDocsWorker(action);
    });
  }
}

function* signWTDWorker(action) {
  const { mode, docNumber, fromDate, toDate } = yield select(
    getTransactionsModal
  );
  const { document_id, nextSigner, complete } = action.payload;

  try {
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: { obj1Name: "transactions", keyValuePairs: { loading: true } },
    });
    let response = yield call(
      axios.put,
      process.env.REACT_APP_API_URL.concat(
        "/warehouses_transactions_docs/sign"
      ),
      { document_id, nextSigner, complete },
      auth.sendToken()
    );
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: { obj1Name: "transactions", keyValuePairs: { loading: false } },
    });
    yield put({
      type: DASHBOARD.TOAST_MESSAGE,
      payload: {
        message: `Documento [${response.data}] firmado!`,
        autoClose: 2000,
        type: "success",
      },
    });
    yield put({
      type: WAREHOUSE.GET_TRANSACTION_DOCS,
      payload: { mode, docNumber, fromDate, toDate },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: WAREHOUSE.MUTATE_1OBJECT,
          payload: {
            obj1Name: "transactions",
            keyValuePairs: { loading: false },
          },
        });
      },
      function* () {
        yield signWTDWorker(action);
      }
    );
  }
}

function* getInventoryToTransferWorker() {
  const profile = yield select(getProfile);
  const serialized = yield select(getSerializedInTransaction);

  try {
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "transactions",
        keyValuePairs: { inventory: { data: [], loading: true } },
      },
    });
    const ids = Buffer.from(JSON.stringify({ level: "transferable" })).toString(
      "base64"
    );
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(`/inventoryManager/v1/stock/${ids}`),
      auth.sendToken()
    );
    const data = response.data.map((row) => {
      row = formatData(
        row,
        profile.organization.tz,
        profile.user.settings.date_format
      );
      row.serialized = row.is_serialized ? "Si" : "No";
      row.allowed_to_use = row.allowed_to_use ? "Si" : "No";
      return row;
    });
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "transactions",
        keyValuePairs: { inventory: { data, loading: false } },
      },
    });

    //Verifica si hay alguna serie añadida a la transacción que ya no es parte del inventario disponible y la elimina
    if (serialized.length > 0) {
      yield put({
        type: WAREHOUSE.MUTATE_1OBJECT,
        payload: {
          obj1Name: "createTransaction",
          keyValuePairs: {
            serialized: serialized.filter((serie) => {
              let idx = _.findIndex(data, (row) => row.serie === serie.serie);
              return idx !== -1;
            }),
          },
        },
      });
    }
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getInventoryToTransferWorker();
    });
  }
}

function* sendTransferTransactionWorker(action) {
  const transaction = action.payload;
  const { mode, docNumber, fromDate, toDate } = yield select(
    getTransactionsModal
  );

  try {
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "createTransaction",
        keyValuePairs: { loading: true },
      },
    });
    const response = yield call(
      axios.post,
      process.env.REACT_APP_API_URL.concat(
        "/warehouses_transactions_docs/manual"
      ),
      transaction,
      auth.sendToken()
    );
    yield put({ type: WAREHOUSE.RESET_CREATE_TRANSACTION });
    yield put({
      type: DASHBOARD.TOAST_MESSAGE,
      payload: {
        message: `Documento creado: [${response.data}]`,
        autoClose: 4000,
      },
    });
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "transactions",
        keyValuePairs: { inventory: { loading: false, data: [] } },
      },
    });
    yield put({
      type: WAREHOUSE.GET_TRANSACTION_DOCS,
      payload: { mode, docNumber, fromDate, toDate },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: WAREHOUSE.MUTATE_1OBJECT,
          payload: {
            obj1Name: "createTransaction",
            keyValuePairs: { loading: false },
          },
        });
      },
      function* () {
        yield sendTransferTransactionWorker(action);
      }
    );
  }
}

function* getSearchingSeriesWorker(action) {
  const profile = yield select(getProfile);
  const searchInData = yield select(getSearchInDataStockSeries);
  const series = action.payload;

  try {
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "stockSeriesManage",
        keyValuePairs: { series: { data: [], loading: true } },
      },
    });
    const response = yield call(
      axios.post,
      process.env.REACT_APP_API_URL.concat(
        `/warehouses_items/series/searching-series`
      ),
      series,
      auth.sendToken()
    );
    const data = response.data.map((row) =>
      formatData(
        row,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    );
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "stockSeriesManage",
        keyValuePairs: {
          searchInData: { ...searchInData, data: "" },
          series: { data, loading: false },
        },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getSearchingSeriesWorker(action);
    });
  }
}

function* deleteTransactionWorker(action) {
  const document_id = action.payload;
  const { mode, docNumber, fromDate, toDate } = yield select(
    getTransactionsModal
  );

  try {
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "transactionsManage",
        keyValuePairs: { deleting: true },
      },
    });
    yield call(
      axios.delete,
      process.env.REACT_APP_API_URL.concat(
        `/warehouses_transactions_docs/${document_id}`
      ),
      auth.sendToken()
    );
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "transactionsManage",
        keyValuePairs: { deleting: false },
      },
    });
    yield put({
      type: WAREHOUSE.GET_TRANSACTION_DOCS,
      payload: { mode, docNumber, fromDate, toDate },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, function* () {
      yield put({
        type: WAREHOUSE.MUTATE_1OBJECT,
        payload: {
          obj1Name: "transactionsManage",
          keyValuePairs: { deleting: false },
        },
      });
    });
  }
}

function* getMyFamiliesWorker() {
  try {
    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "stockSeriesManage",
        keyValuePairs: { families: { data: [], loading: true } },
      },
    });
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(`/item_families/v1/myFamilies/exp`),
      auth.sendToken()
    );

    yield put({
      type: WAREHOUSE.MUTATE_1OBJECT,
      payload: {
        obj1Name: "stockSeriesManage",
        keyValuePairs: { families: { data, loading: false } },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getMyFamiliesWorker();
    });
  }
}

/****** EXPORT DEFAULT ROOT SAGA *******/
export default function* rootSaga() {
  yield all([
    getStockArticlesWatcher(),
    getStockSeriesWatcher(),
    getTransactionDocsWatcher(),
    getItemsTransactionDocsWatcher(),
    signWTDWatcher(),
    getInventoryToTransferWatcher(),
    sendTransferTransactionWatcher(),
    getSearchingSeriesWatcher(),
    deleteTransactionWatcher(),
    getMyFamiliesWatcher(),
  ]);
}
