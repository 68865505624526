//Libs
import React from "react";
import { useTheme } from "styled-components";
//Components
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Wrapper, Span, ErrorIcon } from "components/components";

export default function ShowErrorCard({ error, counter }) {
  const theme = useTheme();
  return (
    <Wrapper height="65px" padding="5px">
      <ErrorIcon button />
      <CopyToClipboard text={error}>
        <Span
          cursor="pointer"
          maxHeight="65px"
          fontWeight="bold"
          fontSize="xl"
          color={theme.colors.text.medium}
          overflow="auto"
        >
          {error}
        </Span>
      </CopyToClipboard>
    </Wrapper>
  );
}
