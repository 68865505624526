//Libs
import styled from "styled-components";
//Components
import icon from "./logout.svg";

const Icon = styled.div`
  background-image: url(${icon});
  width: 24px;
  height: 24px;
`;

export default Icon;
