//Libs
import {
	takeLatest,
	takeEvery,
	put,
	call,
	all,
	select,
} from "redux-saga/effects";
import axios from "axios";
//Utils
import asyncErrorsHandler from "store/asyncErrorsHandler";
import AuthService from "utils/libs/auth/AuthService";
import GENERAL from "utils/constants/general";
import { lsHandler, formatData } from "utils/libs";
//Actions
import { mutate1Object } from "./actions";

const { DASHBOARD } = GENERAL;
const getProfile = (state) => state.login.profile;

// const getSocket = state => state.dashboard.socket;
const auth = new AuthService();

//******** WATCHER SAGAS ***********/
function* setLinkWatcher() {
	yield takeLatest(DASHBOARD.SET_LINK, setLinkWorker);
}

function* reportMeWatcher() {
	yield takeLatest(DASHBOARD.REPORT_ME, reportMeWorker);
}

function* getNotificationsWatcher() {
	yield takeLatest(DASHBOARD.GET_NOTIFICATIONS, getNotificationsWorker);
}

function* markNotificationSeenWatcher() {
	yield takeLatest(DASHBOARD.MARK_NOTIFICATION_SEEN, markNotificationSeenWorker);
}

//New unified structure
function* getRegionsWatcher() {
	yield takeLatest(DASHBOARD.GET_REGIONS, getRegionsWorker);
}
function* getAreasWatcher() {
	yield takeLatest(DASHBOARD.GET_AREAS, getAreasWorker);
}
function* getCentralssWatcher() {
	yield takeLatest(DASHBOARD.GET_CENTRALS, getCentralsWorker);
}
function* getDealersWatcher() {
	yield takeLatest(DASHBOARD.GET_DEALERS, getDealersWorker);
}
function* getOrdersWatcher() {
	yield takeLatest(DASHBOARD.GET_ORDERS, getOrdersWorker);
}
function* getProjectsWatcher() {
	yield takeLatest(DASHBOARD.GET_PROJECTS, getProjectsWorker);
}
function* getServicesWatcher() {
	yield takeLatest(DASHBOARD.GET_SERVICES, getServicesWorker);
}
function* markNotificationWatcher() {
	yield takeEvery(DASHBOARD.MARK_NOTIFICATION, markNotificationWorker);
}

//******** WORKERS SAGAS ***********/
function* setLinkWorker(action) {
	const { linkValue, coords } = action.payload,
		setState = {
			value: linkValue ? "connect" : "disconnect",
			coords,
		};

	try {
		//Update linked state in api server
		const response = yield call(
			axios.put,
			process.env.REACT_APP_API_URL.concat("/users_connected_records/set_link"),
			setState,
			auth.sendToken()
		);
		//Update linked state in local Storage
		yield lsHandler.saveLinked(response.data);
		//Update linked state in Store
		yield put({ type: DASHBOARD.SET_LINK_SUCCESS, payload: response.data });
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield setLinkWorker(action);
		});
	}
}

function* reportMeWorker(action) {
	let { id, name } = action.payload;
	name = name.split(" ", 1);

	try {
		yield call(
			axios.put,
			process.env.REACT_APP_API_URL.concat("/users/report_me"),
			id,
			auth.sendToken()
		);
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield reportMeWorker(action);
		});
	}
}

function* getNotificationsWorker() {
	const profile = yield select(getProfile);

	try {
		yield put({
			type: DASHBOARD.MUTATE_1OBJECT,
			payload: {
				obj1Name: "notificationControl",
				keyValuePairs: { isFetching: true },
			},
		});
		const response = yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat("/user_notifications/my"),
			auth.sendToken()
		);
		const notifications = response.data.map((record) =>
			formatData(
				record,
				profile.organization.tz,
				profile.user.settings.date_format
			)
		);
		yield put({
			type: DASHBOARD.MUTATE_1OBJECT,
			payload: {
				obj1Name: "notificationControl",
				keyValuePairs: { isFetching: false, notifications },
			},
		});
		//  Unread notifications?
		// if (_.findIndex(response.data, (notification) => !notification.seen) !== -1)
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getNotificationsWorker();
		});
	}
}

function* markNotificationSeenWorker(action) {
	const user_notification_id = action.payload;
	try {
		yield call(
			axios.put,
			process.env.REACT_APP_API_URL.concat(
				`/user_notifications/seen/${user_notification_id}`
			),
			{},
			auth.sendToken()
		);
		yield put({
			type: DASHBOARD.MARK_NOTIFICATION_SEEN_SUCCESS,
			payload: user_notification_id,
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield markNotificationSeenWorker(action);
		});
	}
}
function* markNotificationWorker(action) {
	const { userNotificationId, seen, read } = action.payload;
	try {
		yield call(
			axios.put,
			process.env.REACT_APP_API_URL.concat(
				`/user_notifications/mark/${userNotificationId}`
			),
			{ seen, read },
			auth.sendToken()
		);
		yield put({
			type: DASHBOARD.MARK_NOTIFICATION_SUCCESS,
			payload: { userNotificationId, seen, read },
		});
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield markNotificationWorker(action);
		});
	}
}

//New unified structure
function* getRegionsWorker(action) {
	const { projectId } = action.payload;

	try {
		yield put(mutate1Object("regions", { isFetching: true }));
		const { data } = yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat(
				`/command_center/v1/regions/${projectId}`
			),
			auth.sendToken()
		);
		yield put(mutate1Object("regions", { isFetching: false, data }));
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getRegionsWorker(action);
		});
	}
}
function* getAreasWorker(action) {
	const { projectId } = action.payload;

	try {
		yield put(mutate1Object("areas", { isFetching: true }));
		const { data } = yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat(
				`/command_center/v1/areas/${projectId}`
			),
			auth.sendToken()
		);
		yield put(mutate1Object("areas", { isFetching: false, data }));
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getAreasWorker(action);
		});
	}
}
function* getCentralsWorker(action) {
	const { projectId } = action.payload;

	try {
		yield put(mutate1Object("centrals", { isFetching: true }));
		const { data } = yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat(
				`/command_center/v1/centrals/${projectId}`
			),
			auth.sendToken()
		);
		yield put(mutate1Object("centrals", { isFetching: false, data }));
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getCentralsWorker(action);
		});
	}
}
function* getDealersWorker(action) {
	const { projectId, centralId } = action.payload;

	try {
		yield put(mutate1Object("dealers", { isFetching: true }));
		const { data } = yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat(
				`/command_center/v1/dealers/${projectId}/${centralId}`
			),
			auth.sendToken()
		);
		yield put(mutate1Object("dealers", { isFetching: false, data }));
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getDealersWorker(action);
		});
	}
}
function* getOrdersWorker(action) {
	const { projectId, serviceId } = action.payload;

	try {
		yield put(mutate1Object("orders", { isFetching: true }));
		const { data } = yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat(
				`/command_center/v1/orders/${projectId}/${serviceId}`
			),
			auth.sendToken()
		);
		yield put(mutate1Object("orders", { isFetching: false, data }));
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getOrdersWorker(action);
		});
	}
}
function* getProjectsWorker() {
	try {
		yield put(mutate1Object("projects", { isFetching: true }));
		const { data } = yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat(`/departments/v1/projects`),
			auth.sendToken()
		);
		yield put(mutate1Object("projects", { isFetching: false, data }));
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getProjectsWorker();
		});
	}
}
function* getServicesWorker(action) {
	const { projectId } = action.payload;

	try {
		yield put(
			mutate1Object("services", {
				isFetching: true,
				selectedServiceId: undefined,
				data: [],
			})
		);
		const { data } = yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat(`/services/v1/${projectId}`),
			auth.sendToken()
		);
		yield put(mutate1Object("services", { isFetching: false, data }));
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getServicesWorker(action);
		});
	}
}
function* getChartsWorker() {
	try {
		yield put(mutate1Object("charts", { isFetching: true }));
		const { data } = yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat(`/command_center/v1/charts`),
			auth.sendToken()
		);
		yield put(mutate1Object("charts", { isFetching: false, data }));
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getChartsWorker();
		});
	}
}
function* getExpertsWorker(action) {
	const { projectId } = action.payload;

	try {
		yield put(mutate1Object("experts", { isFetching: true }));
		const { data } = yield call(
			axios.get,
			process.env.REACT_APP_API_URL.concat(
				`/command_center/v1/experts/${projectId}`
			),
			auth.sendToken()
		);
		yield put(mutate1Object("experts", { isFetching: false, data }));
	} catch (err) {
		yield asyncErrorsHandler(err, undefined, function* () {
			yield getExpertsWorker(action);
		});
	}
}

//  Export default Root Saga
export default function* rootSaga() {
	yield all([
		setLinkWatcher(),
		reportMeWatcher(),
		getNotificationsWatcher(),
		markNotificationSeenWatcher(),
		//New unified structure
		getRegionsWatcher(),
		getAreasWatcher(),
		getCentralssWatcher(),
		getDealersWatcher(),
		getOrdersWatcher(),
		getProjectsWatcher(),
		getServicesWatcher(),
		markNotificationWatcher(),
	]);
}
