//Libs
import React from "react";
//Hooks
import { useNetwork } from "hooks";
//Components
import ToolbarNotifications from "../Notifications";
import { Wrapper, SearchIcon } from "components/components";
import {
  CustomToolbar,
  CustomSearchToolbar,
  BackComponent,
} from "components/containers/Dashboard/subs/Toolbar";
import { SettingsIcon } from "components/components/Icons";
import { AtomSpinner } from "react-epic-spinners";
import { IconButton } from "@material-ui/core";

export function CompleteOrderToolbar({
  title,
  reviewManage,
  onBackClick,
  selectNotification,
  autoscanControl,
  mutate1ObjectInCollector,
  mutateDirectPropsInAutoscan,
}) {
  const { searchInData } = reviewManage;
  const isOnline = useNetwork();

  return (
    <CustomToolbar
      title={title}
      left={<BackComponent handleOnClick={onBackClick} />}
      right={
        <Wrapper padding="0">
          {/* SERIE SCAN */}
          {autoscanControl.show && isOnline && (
            <IconButton>
              <AtomSpinner
                style={{ width: "24px", height: "24px" }}
                color="black"
                onClick={() =>
                  mutateDirectPropsInAutoscan({
                    open: true,
                  })
                }
              />
            </IconButton>
          )}
          {/* SEARCH ITEM */}
          <SearchIcon
            onClick={() =>
              mutate1ObjectInCollector("reviewManage", {
                searchInData: {
                  ...searchInData,
                  renderToolbar: true,
                },
              })
            }
          />

          {/* Settings */}
          <SettingsIcon
            button
            onClick={() =>
              mutate1ObjectInCollector("reviewManage", {
                isControlPanelOpen: true,
              })
            }
          />

          {/* Notifications */}
          <ToolbarNotifications selectNotification={selectNotification} />
        </Wrapper>
      }
    />
  );
}

export function SearchInCompleteOrder({
  reviewManage,
  mutate1ObjectInCollector,
}) {
  const { searchInData } = reviewManage;
  return (
    <CustomSearchToolbar
      value={searchInData.data}
      onChange={(e) =>
        mutate1ObjectInCollector("reviewManage", {
          searchInData: {
            ...searchInData,
            data: e.target.value,
          },
        })
      }
      onClose={() =>
        mutate1ObjectInCollector("reviewManage", {
          searchInData: {
            ...searchInData,
            renderToolbar: false,
            data: "",
          },
        })
      }
    />
  );
}
