//Libs
import Immutable from "immutable";
import { createSelector } from "reselect";
//Utils
import CollectorUtils from "./CollectorUtils";

const selectCollector = ({ collector }) => collector;
const selectLogin = ({ login }) => login;
const selectOrders = ({ orders }) => orders;
const selectApi = ({ api }) => api;
const selectUploadResource = (state) => state.uploadResource;

export const selectReviewManage = createSelector(
  [selectCollector],
  ({ reviewManage }) => reviewManage
);
export const selectCompleteOrderId = createSelector(
  [selectReviewManage],
  ({ orderId }) => orderId
);
export const selectIsControlPanelOpen = createSelector(
  [selectReviewManage],
  ({ isControlPanelOpen }) => isControlPanelOpen
);
export const selectWorkflow = createSelector(
  [selectReviewManage],
  ({ workflow }) => workflow
);
export const selectTransactionDoc = createSelector(
  [selectReviewManage],
  ({ transactionDocs }) => ({
    ...transactionDocs,
    data: Immutable.List(transactionDocs.data).toJS(),
  })
);
export const selectSelectedTransactionDocId = createSelector(
  [selectTransactionDoc],
  ({ docId }) => docId
);
export const selectTransactionDocs = createSelector(
  [selectTransactionDoc],
  ({ data }) => data
);
export const selectCollectorValues = createSelector(
  [selectReviewManage],
  ({ collectorValues }) => Immutable.List(collectorValues).toJS()
);
export const selectCollectorLayout = createSelector(
  [selectReviewManage],
  ({ collectorLayout }) => Immutable.List(collectorLayout).toJS()
);
export const selectDuplicatedCollectorLayout = createSelector(
  [selectReviewManage],
  ({ duplicatedCollectorLayout }) =>
    Immutable.List(duplicatedCollectorLayout).toJS()
);
export const selectPendingCollectorCountFromOrder = createSelector(
  [selectCompleteOrderId, selectCollectorValues],
  (orderId, collectorValues) =>
    CollectorUtils.getUnsuccessCollectorCountFromOrder(orderId, collectorValues)
);
export const selectReadOnlyCollector = createSelector(
  [
    selectReviewManage,
    selectSelectedTransactionDocId,
    selectTransactionDocs,
    selectLogin,
  ],
  ({ forceReadOnlyCollector }, docId, transactionDocs, login) =>
    forceReadOnlyCollector ||
    CollectorUtils.checkReadOnlyCollector(docId, transactionDocs, login.profile)
);
export const selectAvailableReports = createSelector(
  [selectSelectedTransactionDocId, selectTransactionDocs],
  (docId, transactionDocs) =>
    CollectorUtils.getAvailableReports(docId, transactionDocs)
);
export const selectGeneratedReports = createSelector(
  [selectTransactionDoc],
  ({ generatedReports }) => generatedReports
);
export const selectCollectorEcosystemAutoSync = createSelector(
  [selectReviewManage, selectUploadResource],
  ({ isFetching, sendingCollectorValues, autoSync }, uploadResource) => ({
    active: autoSync.active,
    duplicatedLayoutIdsActive: autoSync.duplicatedLayoutIdsActive,
    resource: uploadResource.autoSync.active,
    running: autoSync.isFetching || isFetching || sendingCollectorValues,
  })
);
export const selectSearchInData = createSelector(
  [selectReviewManage],
  ({ searchInData }) => searchInData
);
export const selectCollectorLayouts = createSelector(
  [
    selectApi,
    selectOrders,
    selectSelectedTransactionDocId,
    selectTransactionDocs,
    selectCollectorLayout,
    selectDuplicatedCollectorLayout,
    selectCompleteOrderId,
  ],
  (
    { templates },
    { control },
    docId,
    transactionDocs,
    collectorLayout,
    duplicatedCollectorLayout,
    orderId
  ) => {
    const _templates = Immutable.List(templates).toJS();
    //Get Selected Order
    const order = CollectorUtils.getSelectedOrderFromReviewManage(
      orderId,
      control.orders
    );
    if (!order) return [];

    const _collectorLayout = (() => {
      //Get collector layout from reviewManage
      if (collectorLayout.length > 0) return collectorLayout;

      //Get collector layout by otd id
      let clayout = CollectorUtils.getCollectorLayoutByOtdId(
        order,
        docId,
        transactionDocs,
        _templates
      );
      if (clayout.length > 0) return clayout;

      //Get default collector layout by type id = 4
      return CollectorUtils.getDefaultCollectorLayoutByType(order, _templates);
    })();

    const totalCollectorLayout = CollectorUtils.addDuplicatedCollectorLayout(
      order.order_id,
      docId,
      _collectorLayout,
      duplicatedCollectorLayout
    );

    return totalCollectorLayout;
  }
);
