//Libs
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
//Components
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import UserIcon from "@material-ui/icons/AccountCircle";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
	Wrapper,
	Imagotype,
	Subtitle,
	OnboardMainButton,
	FromAv4cusText,
} from "components/components";
//Utils
import AuthService from "../../../utils/libs/auth/AuthService";
import GENERAL from "../../../utils/constants/general";
//Actions
import * as actions from "./actions";
//Styles
import "./index.css";

const styles = (theme) => ({
	button: {
		top: "10px",
		fontFamily: "Raleway",
	},
	textField: {
		width: "200px",
		minHeight: "48px",
		height: "48px",
		paddingLeft: "15px",
		backgroundColor: "#E8E8E8",
		color: "rgba(0, 0, 0, 0.6)",
		marginBottom: "10px",
		fontFamily: "Raleway",
	},
	icon: {
		color: "rgba(0, 0, 0, 0.6)",
	},
});

const { ENV } = GENERAL;

class Login extends Component {
	constructor(...props) {
		super(...props);

		this.Auth = new AuthService();
	}

	/******************** LIFECYCLES ********************/
	componentWillMount() {
		if (this.Auth.loggedIn()) {
			return <Redirect to="/" />;
		}
	}

	componentWillUnmount() {
		this.props.doReset();
	}

	/******************** JSX RENDER ********************/
	render() {
		const {
			classes,
			user,
			password,
			showPassword,
			logged,
			handleOnChangeInput,
			doShowPassword,
			doLogin,
			showNewPasswordInput,
			showNewPassword,
			loading,
		} = this.props;

		return (
			//  IS LOGGED?
			this.Auth.loggedIn() ? (
				<Redirect to="/" />
			) : (
				<Wrapper
					className="animated fadeIn"
					padding="0"
					position="relative"
					display="flex"
					flexDirection="column"
				>
					<Wrapper
						position="relative"
						display="flex"
						flexDirection="column"
						padding="4em 1.5em 1.5em 1.5em"
						maxWidth="400px"
						height="300px"
						borderRadius="25px"
						boxShadow="0 8px 16px 0 rgb(0 0 0)"
						margin="0 10%"
					>
						{/* LOGO */}
						<Imagotype position="absolute" top="-55px" opacity=".8" />

						{/* USER */}
						<Input
							required
							name="user"
							placeholder="Usuario*"
							className={classes.textField}
							onChange={handleOnChangeInput}
							value={user}
							type="text"
							endAdornment={
								<InputAdornment position="end">
									<IconButton>
										<UserIcon className={classes.icon} />
									</IconButton>
								</InputAdornment>
							}
						/>

						{/* PASSWORD */}
						<Input
							required
							placeholder="Contraseña*"
							id="adornment-password"
							name="password"
							className={classes.textField}
							type={showPassword ? "text" : "password"}
							value={password}
							onChange={handleOnChangeInput}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										onClick={() => doShowPassword("showPassword")}
										onMouseDown={(event) => event.preventDefault()}
									>
										{showPassword ? (
											<VisibilityOff className={classes.icon} />
										) : (
											<Visibility className={classes.icon} />
										)}
									</IconButton>
								</InputAdornment>
							}
						/>

						{/* LOGIN-BUTTON */}
						{loading ? (
							<div style={{ minWidth: 24, textAlign: "center" }}>
								<CircularProgress
									variant="indeterminate"
									size={24}
									thickness={4}
								/>
							</div>
						) : (
							<div className="container-login-form-action-buttons">
								{/* BUTTON */}
								<OnboardMainButton
									variant={
										logged !== undefined && !logged ? "contained" : "outlined"
									}
									color={
										logged !== undefined && !logged ? "secondary" : "primary"
									}
									onClick={() => doLogin(user, password)}
								>
									Iniciar Sesión
								</OnboardMainButton>

								{/* LABEL */}
								<Wrapper
									padding="0"
									flexDirection="column"
									width="240px"
									margin="10px 0 0 0"
									justifyContent="space-around"
								>
									<Wrapper gap="5px">
										<Subtitle>¿No tiene cuenta?</Subtitle>
										<Link to={ENV.ROUTES.PATHS.SIGNUP}>Registrarme</Link>
									</Wrapper>
									<Wrapper gap="5px">
										<Subtitle>¿Olvidaste la contraseña?</Subtitle>
										<Link to={ENV.ROUTES.PATHS.RECOVERY_PASSWORD}>
											Recuperar
										</Link>
									</Wrapper>
								</Wrapper>
							</div>
						)}
					</Wrapper>
					<FromAv4cusText />
				</Wrapper>
			)
		);
	}
}

/******************** PROPTYPES ********************/
Login.propTypes = {
	//Props
	classes: PropTypes.object.isRequired,
	user: PropTypes.string.isRequired,
	password: PropTypes.string.isRequired,
	showPassword: PropTypes.bool,
	logged: PropTypes.bool,
	showNewPasswordInput: PropTypes.bool,
	showNewPassword: PropTypes.bool,
	//Functions
	handleOnChangeInput: PropTypes.func.isRequired,
	doShowPassword: PropTypes.func.isRequired,
	doLogin: PropTypes.func.isRequired,
	doReset: PropTypes.func.isRequired,
};

/******************** EXPORT ********************/
export default connect(
	(state) => ({
		user: state.login.user,
		password: state.login.password,
		logged: state.login.logged,
		loading: state.login.loading,
		showPassword: state.login.showPassword,
		showNewPasswordInput: state.login.showNewPasswordInput,
		showNewPassword: state.login.showNewPassword,
	}),
	actions
)(withStyles(styles)(Login));
