//Libs
import _ from 'underscore';
import GENERAL from '../constants/general';

const { DIVISION_ID } = GENERAL;

function getActiveUsers(users) {
  return users.filter(user => user.active);
}

export function getNameByUserId(userId, users) {
  let user = users.filter(usr => usr.id === userId)[0];
  if (user) return user.name;
  return 'Desconocido';
}

export function getSigners(users, userId) {
  const activeUsers = getActiveUsers(users),
    idx = _.findIndex(activeUsers, user => user.id === userId);

  if (idx === -1) return [];

  const user = activeUsers[idx];
  if (user.division_id === DIVISION_ID.ENTITY) return activeUsers.filter(usr => usr.entity_id === user.id && usr.division_id === DIVISION_ID.ADM);
  else if (user.division_id === DIVISION_ID.EXP) return activeUsers.filter(usr => usr.id === user.id);
}
