//Libs
import React from "react";
//Components
import ilustration from "./illustration.svg";

const EmptyIllustration = ({ width = "200px" }) => {
  return <img src={ilustration} style={{ width }} />;
};

export default EmptyIllustration;
