// Utils
import dayjs from "dayjs";

class MapUtils {
	static getMapStyle() {
		const now = dayjs();
		const startTimeDay = dayjs(`${now.format("YYYY-MM-DD")} 05:59`);
		const endTimeDay = dayjs(`${now.format("YYYY-MM-DD")} 17:59`);

		return now.isAfter(endTimeDay) || now.isBefore(startTimeDay)
			? "mapbox://styles/mapbox/dark-v10"
			: "mapbox://styles/mapbox/streets-v11";
	}
	static calculateDistance({ lat1, lon1, lat2, lon2 }) {
		// NOTA: φ = Latitud, λ = Longitud, Δφ, Δλ = Diference

		const R = 6371e3; // radius of Earth in meters
		const φ1 = lat1 * (Math.PI / 180);
		const φ2 = lat2 * (Math.PI / 180);
		const Δφ = (lat2 - lat1) * (Math.PI / 180);
		const Δλ = (lon2 - lon1) * (Math.PI / 180);

		const a =
			Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
			Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

		const distance = R * c;
		return distance;
	}
}

export default MapUtils;
