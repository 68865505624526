import styled, { css } from "styled-components";

const FlagStyled = styled.div`
  min-width: ${(props) => props.width ?? props.theme.flag.minWidth};
  height: ${(props) => props.height ?? props.theme.flag.height};
  ${(props) =>
    props.success &&
    css`
      background-color: ${props.theme.flag.success};
    `}
  ${(props) =>
    props.unsuccess &&
    css`
      background-color: ${props.theme.flag.unsuccess};
    `}
  ${(props) =>
    props.absolute &&
    css`
      position: ${props.theme.flag.absolute.position};
      left: ${props.theme.flag.absolute.left};
    `}
  ${(props) =>
    props.circle &&
    css`
      border-radius: ${props.theme.flag.borderRadius};
    `}
`;

export default FlagStyled;
