//Libs
import { Icon } from "antd";
import styled from "styled-components";

const IconButtonStyled = styled(Icon)`
  height: ${(props) =>
    props.size
      ? props.theme.iconButton.size[props.size].height
      : props.theme.iconButton.size.default.height};
  width: ${(props) =>
    props.size
      ? props.theme.iconButton.size[props.size].width
      : props.theme.iconButton.size.default.width};
  font-size: ${(props) =>
    props.size
      ? props.theme.iconButton.size[props.size].fontSize
      : props.theme.iconButton.size.default.fontSize};
  padding: ${(props) =>
    props.size
      ? props.theme.iconButton.size[props.size].padding
      : props.theme.iconButton.size.default.padding};
  margin: ${(props) =>
    props.margin ? props.margin : props.theme.iconButton.margin};
  color: ${(props) =>
    props.color ? props.color : props.theme.iconButton.color};
  cursor: ${(props) => props.theme.iconButton.cursor};
  border-radius: ${(props) => props.theme.iconButton.borderRadius};
  &:hover {
    background-color: ${(props) =>
      props.theme.iconButton.hover.backgroundColor};
  }
  &:active {
    background-color: ${(props) =>
      props.theme.iconButton.active.backgroundColor};
  }
  transition: ${(props) => props.theme.iconButton.transition};
  touch-action: ${(props) => props.theme.iconButton.touchAction};
`;

export default IconButtonStyled;
