//Libs
import { takeLatest, put, call, all, select } from "redux-saga/effects";
import axios from "axios";

//Utils
import asyncErrorsHandler from "store/asyncErrorsHandler";
import AuthService from "utils/libs/auth/AuthService";
import GENERAL from "utils/constants/general";
import { lsHandler, idbHandler, formatData } from "utils/libs";

const { APIDATA, PAYMENTS } = GENERAL;
const auth = new AuthService();

//Selectors
const getWarehouseApiData = (state) => state.api.warehouse;
const getProfile = (state) => state.login.profile;

/****** WATCHER SAGAS *******/

//USERS
function* getOrganizationUsersWatcher() {
  yield takeLatest(APIDATA.GET_ORGANIZATION_USERS, getOrganizationUsersWorker);
}

//ORDERS
function* getOrderStatesWatcher() {
  yield takeLatest(APIDATA.GET_ORDER_STATES, getOrderStatesWorker);
}

function* getOrderEventsWatcher() {
  yield takeLatest(APIDATA.GET_ORDER_EVENTS, getOrderEventsWorker);
}

function* getCompletedCodesWatcher() {
  yield takeLatest(APIDATA.GET_COMPLETED_CODES, getCompletedCodesWorker);
}

//DEPARTMENTS
function* getDepartmentsWatcher() {
  yield takeLatest(APIDATA.GET_DEPARTMENTS, getDepartmentsWorker);
}

//CONTRACT TYPES
function* getContractTypesWatcher() {
  yield takeLatest(APIDATA.GET_CONTRACT_TYPES, getContractTypesWorker);
}

//PAYMENTS
function* getPaymentsWatcher() {
  yield takeLatest(APIDATA.GET_PAYMENTS, getPaymentsWorker);
}

function* getPaymentResumeWatcher() {
  yield takeLatest(APIDATA.GET_PAYMENT_RESUME, getPaymentResumeWorker);
}

function* getGainsWatcher() {
  yield takeLatest(APIDATA.GET_GAINS, getGainsWorker);
}

function* getCoinsWatcher() {
  yield takeLatest(APIDATA.GET_COINS, getCoinsWorker);
}

//TEMPLATES
function* getMyTemplatesWatcher() {
  yield takeLatest(APIDATA.GET_MY_TEMPLATES, getMyTemplatesWorker);
}

//WAREHOUSE
function* getInventoryToInvoice() {
  yield takeLatest(
    APIDATA.GET_INVENTORY_TO_INVOICE,
    getInventoryToInvoiceWorker
  );
}

function* getInventoryToUninstallWatcher() {
  yield takeLatest(
    APIDATA.GET_INVENTORY_TO_UNINSTALL,
    getInventoryToUninstallWorker
  );
}

function* getIncomeMethodsWatcher() {
  yield takeLatest(APIDATA.GET_INCOME_METHODS, getIncomeMethodsWorker);
}

function* getWarehousesWatcher() {
  yield takeLatest(APIDATA.GET_WAREHOUSES, getWarehousesWorker);
}

function* getSerieHistoryWatcher() {
  yield takeLatest(APIDATA.GET_SERIE_HISTORY, getSerieHistoryWorker);
}

//CITIES
function* getCitiesWatcher() {
  yield takeLatest(APIDATA.GET_CITIES, getCitiesWorker);
}

//TOWNSHIPS
function* getTownshipsWatcher() {
  yield takeLatest(APIDATA.GET_TOWNSHIPS, getTownshipsWorker);
}

//ZONES
function* getZonesWatcher() {
  yield takeLatest(APIDATA.GET_ZONES, getZonesWorker);
}

/****** WORKER SAGAS *******/

//USERS
function* getOrganizationUsersWorker() {
  const profile = yield select(getProfile);

  try {
    const response = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL.concat(`/users/getOrganizationUsers`)}`,
      auth.sendToken()
    );
    const users = response.data.map((row) =>
      formatData(
        row,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    );
    yield put({ type: APIDATA.MUTATE_DIRECT_PROPS, payload: { users } });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getOrganizationUsersWorker();
    });
  }
}

//ORDERS
function* getOrderStatesWorker() {
  try {
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat("/order_states"),
      auth.sendToken()
    );
    yield put({
      type: APIDATA.MUTATE_DIRECT_PROPS,
      payload: { states: response.data },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getOrderStatesWorker();
    });
  }
}

function* getOrderEventsWorker() {
  try {
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat("/order_events/exp"),
      auth.sendToken()
    );
    yield put({
      type: APIDATA.MUTATE_DIRECT_PROPS,
      payload: { events: response.data },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getOrderEventsWorker();
    });
  }
}

function* getCompletedCodesWorker() {
  try {
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(
        "/order_completed_codes/getCodesByDepartmentsAssignedToUser/"
      ),
      auth.sendToken()
    );
    const completedCodes = response.data;
    yield put({
      type: APIDATA.MUTATE_DIRECT_PROPS,
      payload: { completedCodes },
    });
    idbHandler.setCompletedCodes(completedCodes);
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getCompletedCodesWorker();
    });
  }
}

//DEPARTMENTS
function* getDepartmentsWorker() {
  try {
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: { obj1Name: "departments", keyValuePairs: { loading: true } },
    });
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat("/departments"),
      auth.sendToken()
    );
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: {
        obj1Name: "departments",
        keyValuePairs: { data: response.data, loading: false },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: APIDATA.MUTATE_1OBJECT,
          payload: {
            obj1Name: "departments",
            keyValuePairs: { loading: false },
          },
        });
      },
      function* () {
        yield getDepartmentsWorker();
      }
    );
  }
}

//CONTRACT TYPES
function* getContractTypesWorker(action) {
  const departmentId = action.payload;

  try {
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: { obj1Name: "contractTypes", keyValuePairs: { loading: true } },
    });
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(`/contract_types/${departmentId}`),
      auth.sendToken()
    );
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: {
        obj1Name: "contractTypes",
        keyValuePairs: { data: response.data, loading: false },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: APIDATA.MUTATE_1OBJECT,
          payload: {
            obj1Name: "contractTypes",
            keyValuePairs: { loading: false },
          },
        });
      },
      function* () {
        yield getContractTypesWorker(action);
      }
    );
  }
}

//PAYMENTS
function* getPaymentsWorker(action) {
  const profile = yield select(getProfile);

  try {
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: {
        obj1Name: "payments",
        keyValuePairs: { pays: { data: [], loading: true } },
      },
    });
    const response = yield call(
      axios.post,
      process.env.REACT_APP_API_URL.concat(`/payments/invoices`),
      { paycutIds: [] },
      auth.sendToken()
    );
    const data = response.data.map((row) =>
      formatData(
        row,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    );
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: {
        obj1Name: "payments",
        keyValuePairs: { pays: { data, loading: false } },
      },
    });
    yield put({
      type: PAYMENTS.MUTATE_1OBJECT,
      payload: {
        obj1Name: "paymentsManage",
        keyValuePairs: {
          selected: { items: [] },
        },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: APIDATA.MUTATE_1OBJECT,
          payload: {
            obj1Name: "payments",
            keyValuePairs: { pays: { data: [], loading: false } },
          },
        });
      },
      function* () {
        yield getPaymentsWorker(action);
      }
    );
  }
}

function* getPaymentResumeWorker(action) {
  const payIds = action.payload;
  const profile = yield select(getProfile);

  try {
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: {
        obj1Name: "payments",
        keyValuePairs: {
          resume: { payments: [], orders: [], lines: [], loading: true },
        },
      },
    });
    const response = yield call(
      axios.post,
      process.env.REACT_APP_API_URL.concat(`/payments/resume`),
      { payIds },
      auth.sendToken()
    );
    const resume = {
      loading: false,
      payments: response.data.payments.map((payment) => {
        payment.signed =
          Array.isArray(payment.signed) &&
          payment.signed.map((sign) => ({
            ...formatData(
              sign,
              profile.organization.tz,
              profile.user.settings.date_format
            ),
            payment_id: payment.id,
          }));
        return payment;
      }),
      orders: response.data.orders.map((row) =>
        formatData(
          row,
          profile.organization.tz,
          profile.user.settings.date_format
        )
      ),
      lines: response.data.lines.map((row) =>
        formatData(
          row,
          profile.organization.tz,
          profile.user.settings.date_format
        )
      ),
    };
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: { obj1Name: "payments", keyValuePairs: { resume } },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: APIDATA.MUTATE_1OBJECT,
          payload: {
            obj1Name: "payments",
            keyValuePairs: {
              resume: { payments: [], orders: [], lines: [], loading: false },
            },
          },
        });
      },
      function* () {
        yield getPaymentResumeWorker(action);
      }
    );
  }
}

function* getGainsWorker() {
  try {
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat("/users/gainsExpert"),
      auth.sendToken()
    );
    const gains = response.data;

    yield put({ type: APIDATA.MUTATE_DIRECT_PROPS, payload: { gains } });
    lsHandler.save("synapse_gains", gains);
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getGainsWorker();
    });
  }
}

function* getCoinsWorker() {
  try {
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat("/coins"),
      auth.sendToken()
    );
    yield put({
      type: APIDATA.MUTATE_DIRECT_PROPS,
      payload: { coins: response.data },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getCoinsWorker();
    });
  }
}

//TEMPLATES
function* getMyTemplatesWorker() {
  try {
    const { data: templates } = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat("/templates/myassignments/All"),
      // { types: ["photoWorkedOrder", "importOrdersValidation"] },//TODO: Se debe mejorar el procedimiento a la hora de obtener los templates precisos que el usuario va a ocupar, quitar All
      auth.sendToken()
    );
    yield put({ type: APIDATA.MUTATE_DIRECT_PROPS, payload: { templates } });
    idbHandler.setTemplates(templates);
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getMyTemplatesWorker();
    });
  }
}

//WAREHOUSE
function* getInventoryToInvoiceWorker() {
  const warehouse = yield select(getWarehouseApiData);

  try {
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: {
        obj1Name: "warehouse",
        keyValuePairs: { toInvoice: { ...warehouse.toInvoice, loading: true } },
      },
    });
    const ids = Buffer.from(JSON.stringify({ level: "consumable" })).toString(
      "base64"
    );
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(`/inventoryManager/v1/stock/${ids}`),
      auth.sendToken()
    );
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: {
        obj1Name: "warehouse",
        keyValuePairs: { toInvoice: { loading: false, data } },
      },
    });
    idbHandler.setToInvoiceInventory(data);
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: APIDATA.MUTATE_1OBJECT,
          payload: {
            obj1Name: "warehouse",
            keyValuePairs: {
              toInvoice: { ...warehouse.toInvoice, loading: false },
            },
          },
        });
      },
      function* () {
        yield getInventoryToInvoiceWorker();
      }
    );
  }
}

function* getInventoryToUninstallWorker() {
  try {
    const { data: toUninstall } = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(
        "/warehouses/getToUninstallInventory"
      ),
      auth.sendToken()
    );
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: { obj1Name: "warehouse", keyValuePairs: { toUninstall } },
    });
    idbHandler.setToUninstallInventory(toUninstall);
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getInventoryToUninstallWorker();
    });
  }
}

function* getIncomeMethodsWorker() {
  try {
    let { data: incomeMethods } = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat("/warehouses_inv_income_methods"),
      auth.sendToken()
    );
    yield put({
      type: APIDATA.MUTATE_DIRECT_PROPS,
      payload: { incomeMethods },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getIncomeMethodsWorker();
    });
  }
}

function* getWarehousesWorker() {
  const profile = yield select(getProfile);

  try {
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat("/warehouses"),
      auth.sendToken()
    );
    const warehouses = response.data.map((warehouse) =>
      formatData(
        warehouse,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    );
    yield put({
      type: APIDATA.MUTATE_DIRECT_PROPS,
      payload: { warehouses },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getWarehousesWorker();
    });
  }
}

function* getSerieHistoryWorker(action) {
  const profile = yield select(getProfile);
  const serie = action.payload;

  try {
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: {
        obj1Name: "serieHistory",
        keyValuePairs: { serie, loading: true },
      },
    });
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(
        `/warehouses_transactions_docs_items/v1/history/serie/${serie}`
      ),
      auth.sendToken()
    );
    const data = response.data.map((row) =>
      formatData(
        row,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    );
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: {
        obj1Name: "serieHistory",
        keyValuePairs: { data, loading: false },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getSerieHistoryWorker(action);
    });
  }
}

//CITIES
function* getCitiesWorker() {
  const profile = yield select(getProfile);

  try {
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: {
        obj1Name: "cities",
        keyValuePairs: { data: [], loading: true },
      },
    });
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(`/cities`),
      auth.sendToken()
    );
    const data = response.data.map((row) =>
      formatData(
        row,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    );
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: { obj1Name: "cities", keyValuePairs: { data, loading: false } },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: APIDATA.MUTATE_1OBJECT,
          payload: { obj1Name: "cities", keyValuePairs: { loading: false } },
        });
      },
      function* () {
        yield getCitiesWorker();
      }
    );
  }
}

//TOWNSHIPS
function* getTownshipsWorker(action) {
  const profile = yield select(getProfile);
  const cityId = action.payload;

  try {
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: {
        obj1Name: "townships",
        keyValuePairs: { data: [], loading: true },
      },
    });
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(`/townships/${cityId}`),
      auth.sendToken()
    );
    const data = response.data.map((row) =>
      formatData(
        row,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    );
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: {
        obj1Name: "townships",
        keyValuePairs: { data, loading: false },
      },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: APIDATA.MUTATE_1OBJECT,
          payload: { obj1Name: "townships", keyValuePairs: { loading: false } },
        });
      },
      function* () {
        yield getTownshipsWorker(action);
      }
    );
  }
}
//ZONES
function* getZonesWorker(action) {
  const profile = yield select(getProfile);
  const townshipId = action.payload;

  try {
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: {
        obj1Name: "zones",
        keyValuePairs: { data: [], loading: true },
      },
    });
    const response = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(`/zones/${townshipId}`),
      auth.sendToken()
    );
    const data = response.data.map((row) =>
      formatData(
        row,
        profile.organization.tz,
        profile.user.settings.date_format
      )
    );
    yield put({
      type: APIDATA.MUTATE_1OBJECT,
      payload: { obj1Name: "zones", keyValuePairs: { data, loading: false } },
    });
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        yield put({
          type: APIDATA.MUTATE_1OBJECT,
          payload: { obj1Name: "zones", keyValuePairs: { loading: false } },
        });
      },
      function* () {
        yield getZonesWorker(action);
      }
    );
  }
}

/****** EXPORT DEFAULT ROOT SAGA *******/
export default function* rootSaga() {
  yield all([
    //USERS
    getOrganizationUsersWatcher(),
    //ORDERS
    getOrderStatesWatcher(),
    getOrderEventsWatcher(),
    getCompletedCodesWatcher(),
    //DEPARTMENTS
    getDepartmentsWatcher(),
    //CONTRACT TYPES
    getContractTypesWatcher(),
    //PAYMENTS
    getGainsWatcher(),
    getPaymentsWatcher(),
    getPaymentResumeWatcher(),
    getCoinsWatcher(),
    //TEMPLATES
    getMyTemplatesWatcher(),
    //WAREHOUSE
    getInventoryToInvoice(),
    getInventoryToUninstallWatcher(),
    getIncomeMethodsWatcher(),
    getWarehousesWatcher(),
    getSerieHistoryWatcher(),
    //CITIES
    getCitiesWatcher(),
    //TOWNSHIPS
    getTownshipsWatcher(),
    //ZONES
    getZonesWatcher(),
  ]);
}
