//Libs
import styled from "styled-components";
import { Link } from "react-router-dom";

const LinkStyled = styled(Link)`
  margin: ${(props) => (props.margin ? props.margin : props.theme.link.margin)};
  padding: ${(props) =>
    props.padding ? props.padding : props.theme.link.padding};
  color: ${(props) => (props.color ? props.color : props.theme.link.color)};
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.link.backgroundColor};
  &:hover {
    color: ${(props) =>
      props.hoverColor ? props.hoverColor : props.theme.link.hover.color};
  }
  &:active {
    color: ${(props) =>
      props.hoverColor ? props.hoverColor : props.theme.link.active.color};
  }
`;

export default LinkStyled;
