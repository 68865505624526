//Libs
import { takeLatest, put, call, all, takeEvery } from "redux-saga/effects";
import axios from "axios";
//Utils
import asyncErrorsHandler from "store/asyncErrorsHandler";
import AuthService from "utils/libs/auth/AuthService";
import KEYWORDS from "./keywords";
//Actions
import { mutate1Object, mutateDirectProps } from "./actions";
const { ACTIONS } = KEYWORDS;

const auth = new AuthService();

/****** WATCHER SAGAS *******/
function* getProjectsWatcher() {
  yield takeLatest(ACTIONS.GET_PROJECTS, getProjectsWorker);
}
function* getServicesWatcher() {
  yield takeLatest(ACTIONS.GET_SERVICES, getServicesWorker);
}
function* getExpertsWatcher() {
  yield takeLatest(ACTIONS.GET_EXPERTS, getExpertsWorker);
}
function* getDataFromProcessedSQLWatcher() {
  yield takeEvery(
    ACTIONS.GET_DATA_FROM_PROCESSED_SQL,
    getDataFromProcessedSQLWorker
  );
}

//******** WORKERS SAGAS ***********/
function* getProjectsWorker() {
  try {
    yield put(mutate1Object("projects", { isFetching: true }));
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(`/departments/v1/projects`),
      auth.sendToken()
    );
    yield put(
      mutate1Object("projects", {
        isFetching: false,
        data,
        selectedProjectId: data[0]?.id,
      })
    );
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getProjectsWorker();
    });
  }
}
function* getServicesWorker(action) {
  const { moduleSettedId, projectId } = action.payload;

  try {
    yield put(
      mutate1Object("services", {
        isFetching: true,
        selectedServiceIds: [],
        data: [],
      })
    );
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(
        `/services/v1/${moduleSettedId}/${projectId}`
      ),
      auth.sendToken()
    );
    yield put(
      mutate1Object("services", {
        isFetching: false,
        data,
        selectedServiceIds: data.map((s) => s.id),
      })
    );
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getServicesWorker(action);
    });
  }
}
function* getExpertsWorker(action) {
  const { projectId } = action.payload;

  try {
    yield put(mutate1Object("experts", { isFetching: true }));
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_API_URL.concat(
        `/command_center/v1/experts/${projectId}`
      ),
      auth.sendToken()
    );
    yield put(mutate1Object("experts", { isFetching: false, data }));
  } catch (err) {
    yield asyncErrorsHandler(err, undefined, function* () {
      yield getExpertsWorker(action);
    });
  }
}
function* getDataFromProcessedSQLWorker(action) {
  const {
    selectedDataFilterId,
    subDataFilterId,
    searchValue,
    userParams,
    lastRowId = 0,
    limit = 15,
    onLoading,
    onError,
    onSuccess,
  } = action.payload;
  try {
    if (onLoading) yield onLoading();
    const { data: response } = yield call(
      axios.post,
      process.env.REACT_APP_API_URL.concat(
        `/modules/v1/getDataFromProcessedSQL/${selectedDataFilterId}/${subDataFilterId}/${lastRowId}/${limit}`
      ),
      {
        searchValue,
        userParams,
      },
      auth.sendToken()
    );
    if (onSuccess) yield onSuccess(response);
  } catch (err) {
    yield asyncErrorsHandler(
      err,
      function* () {
        if (onError) yield onError();
      },
      function* () {
        yield getDataFromProcessedSQLWorker(action);
      }
    );
  }
}

// todo: use this file for something
/****** EXPORT DEFAULT ROOT SAGA *******/
export default function* rootSaga() {
  yield all([
    getProjectsWatcher(),
    getServicesWatcher(),
    getExpertsWatcher(),
    getDataFromProcessedSQLWatcher(),
  ]);
}
