//Libs
import React from "react";
import { connect } from "react-redux";
//Hooks
import { useNetwork } from "hooks";
//Actions
import { mutate1Object, autoSyncCollectorValues, setAutoSync } from "./actions";
//Selectors
import {
  selectCollectorEcosystemAutoSync,
  selectReviewManage,
  selectSelectedTransactionDocId,
} from "./selectors";
//Utils
import CollectorUtils from "./CollectorUtils";
//Components
import { Progress } from "components/components";
import { ApiServiceV2 } from "services";

const AutoSyncCollectors = ({
  //Redux
  collectorEcosystemAutoSync,
  reviewManage,
  mutate1Object,
  setAutoSync,
  autoSyncCollectorValues,
  docId,
}) => {
  //Use Network to know isOnline
  const isOnline = useNetwork();
  //Validating isOnline
  React.useEffect(() => {
    if (!isOnline && collectorEcosystemAutoSync.active) {
      setTimeout(() => setAutoSync({ active: false }), 500);
    } else if (isOnline && !collectorEcosystemAutoSync.active) {
      setTimeout(() => setAutoSync({ active: true }), 500);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  //Start collectorEcosystemAutoSync if Active
  React.useEffect(() => {
    if (!collectorEcosystemAutoSync.active) return;
    CollectorUtils.reloadOfflineCollectorValues().then(
      (offlineCollectorValues) => {
        const unsuccessCollectors = CollectorUtils.getUnsuccessCollectors({
          collectors: offlineCollectorValues,
        });
        if (unsuccessCollectors.length === 0) {
          setTimeout(() => setAutoSync({ active: false }), 500);
        } else {
          setTimeout(() => autoSyncCollectorValues(unsuccessCollectors), 500);
        }
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectorEcosystemAutoSync.active]);

  //Delete success collector values
  React.useEffect(() => {
    if (reviewManage.orderId) return;
    CollectorUtils.reloadOfflineCollectorValues().then(
      (offlineCollectorValues) => {
        const nonRemovableCollectors = CollectorUtils.getNonRemovableCollectors(
          offlineCollectorValues
        );
        CollectorUtils.mutateOfflineCollectorValuesV2(nonRemovableCollectors);
      }
    );
  }, [reviewManage.orderId]);

  //Update duplicated collector layout ids
  React.useEffect(() => {
    if (!reviewManage.orderId && !docId) {
      mutate1Object("reviewManage", {
        duplicatedCollectorLayout: [],
      });
      return;
    }
    if (!collectorEcosystemAutoSync.duplicatedLayoutIdsActive) return;
    setAutoSync({ duplicatedLayoutIdsActive: false });

    CollectorUtils.reloadOfflineDuplicatedCollectorLayout().then(
      (duplicatedCollectorLayout) =>
        ApiServiceV2.post({
          url: "/collectorManager/v1/syncDuplicatedCollectorLayoutIds",
          data: CollectorUtils.formatToUpdateDuplicatedCollectorLayoutIds(
            reviewManage.orderId,
            docId,
            duplicatedCollectorLayout
          ),
        })
          .then((duplicatedCollectorLayoutIds) =>
            CollectorUtils.updateOfflineDuplicatedCollectorLayout(
              docId,
              reviewManage.orderId,
              duplicatedCollectorLayoutIds
            )
          )
          .then((duplicatedCollectorLayout) =>
            mutate1Object("reviewManage", { duplicatedCollectorLayout })
          )
          .catch(() =>
            CollectorUtils.reloadOfflineDuplicatedCollectorLayout().then(
              (duplicatedCollectorLayout) =>
                mutate1Object("reviewManage", { duplicatedCollectorLayout })
            )
          )
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    collectorEcosystemAutoSync.duplicatedLayoutIdsActive,
    reviewManage.orderId,
    docId,
  ]);

  return (
    CollectorUtils.checkAutoSyncActive(
      collectorEcosystemAutoSync.active,
      reviewManage.sendingCollectorValues
    ) && (
      <Progress
        style={{ top: "-9px", opacity: 0.3 }}
        percent={100}
        status="active"
        showInfo={false}
        size="small"
      />
    )
  );
};

const mapStateToProps = (state) => ({
  docId: selectSelectedTransactionDocId(state),
  reviewManage: selectReviewManage(state),
  collectorEcosystemAutoSync: selectCollectorEcosystemAutoSync(state),
});

const actions = {
  mutate1Object,
  setAutoSync,
  autoSyncCollectorValues,
};

export default connect(mapStateToProps, actions)(AutoSyncCollectors);
